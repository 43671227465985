import React, { useEffect } from "react";
import styles from "./LoyaltyProgramSponsorTemplate.module.scss";
import { Row, Container, Stack, Spinner } from "react-bootstrap";
import { Agreement } from "../../organisms/Agreement/Agreement";
import { CompanyHeader } from "../../organisms/CompanyHeader/CompanyHeader";
import { useParams } from "react-router-dom";
import { WidgetCreator } from "../../molecules/WidgetCreator/WidgetCreator";
import { CompanyInfo } from "../../organisms/CompanyInfo/CompanyInfo";
import { CompanySocialMedia } from "../../organisms/CompanySocialMedia/CompanySocialMedia";
import { CompanyLocalization } from "../../organisms/CompanyLocalization/CompanyLocalization";
import { useGetCompanyQuery } from "../../../redux/services/companyApi";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { CompanyService } from "../../organisms/CompanyService/CompanyService";
import { CompanyUsers } from "../../organisms/CompanyUsers/CompanyUsers";

export const LoyaltyProgramSponsorTemplate = () => {
  const { id: companyId } = useParams();
  const {
    data: loyaltyProgramsCompanyData,
    isLoading,
    refetch,
  } = useGetCompanyQuery(companyId);
  const allService = 1;
  const {
    data: loyaltyProgramsServicesCompanyData,
    isLoading: isLoadingService,
  } = useGetLoyaltyProgramsServicesQuery({
    companyId: companyId,
    allService: allService,
    limit: 0,
  });

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoading, isLoadingService]);

  if (isLoading || isLoadingService) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true} className="bg-light">
      <section className={styles.section}>
        <Stack gap={5}>
          <Agreement typesAgreement={"sponsor"} />
          <Row>
            <CompanyHeader
              companyId={companyId}
              data={loyaltyProgramsCompanyData?.data}
            />
          </Row>
          {!loyaltyProgramsCompanyData.data.deleted && (
            <>
              <Row className={`${styles.wrapper}`} id={"services"}>
                <WidgetCreator
                  nameLabel={"Benefity"}
                  buttonName={"STWÓRZ PIERWSZY BENEFIT"}
                  buttonIcon={"plus"}
                  data={loyaltyProgramsServicesCompanyData?.data}
                  condition={
                    loyaltyProgramsServicesCompanyData?.data?.length > 0 || null
                  }
                  Child={CompanyService}
                  reFetchCompany={refetch}
                  disableBtn={
                    loyaltyProgramsCompanyData?.data?.companyDepartment
                      .length === 0
                  }
                  info={
                    loyaltyProgramsCompanyData?.data?.companyDepartment
                      .length === 0
                      ? "Tworzenie benefitów staje się aktywne dopiero po dodaniu lokalizacji"
                      : false
                  }
                />
              </Row>
              <Row className={`${styles.wrapper}`} id={"departments"}>
                <WidgetCreator
                  nameLabel={"Lokalizacje"}
                  buttonName={"DODAJ PIERWSZĄ LOKALIZACJE"}
                  buttonIcon={"plus"}
                  data={loyaltyProgramsCompanyData?.data}
                  condition={
                    loyaltyProgramsCompanyData?.data?.companyDepartment.length >
                      0 || null
                  }
                  Child={CompanyLocalization}
                  reFetchCompany={refetch}
                />
              </Row>
              <Row className={`${styles.wrapper}`}>
                <WidgetCreator
                  nameLabel={"O firmie"}
                  buttonName={"DODAJ OPIS FIRMY"}
                  buttonIcon={"pencil"}
                  data={loyaltyProgramsCompanyData?.data}
                  condition={loyaltyProgramsCompanyData?.data?.description}
                  Child={CompanyInfo}
                />
              </Row>
              <Row className={`${styles.wrapper}`}>
                <WidgetCreator
                  nameLabel={"Serwisy internetowe"}
                  buttonName={"DODAJ STRONĘ INTERNETOWĄ"}
                  buttonIcon={"plus"}
                  data={loyaltyProgramsCompanyData?.data}
                  condition={loyaltyProgramsCompanyData?.data.socialAddresses}
                  Child={CompanySocialMedia}
                  reFetchCompany={refetch}
                />
              </Row>
              <Row className={`${styles.wrapper}`} id={"users"}>
                <WidgetCreator
                  nameLabel={"Dostęp do profilu"}
                  buttonName={"DODAJ ADMINISTRATORA"}
                  buttonIcon={"plus"}
                  data={loyaltyProgramsCompanyData?.data?.companyUser}
                  condition={
                    loyaltyProgramsCompanyData.data.companyUser.length === 0
                      ? null
                      : true
                  }
                  Child={CompanyUsers}
                  reFetchCompany={refetch}
                />
              </Row>
            </>
          )}
        </Stack>
      </section>
    </Container>
  );
};
