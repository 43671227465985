import { Button, Col, Form, InputGroup } from "react-bootstrap";
import {
  UseFieldArrayRemove,
  UseFieldArrayMove,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";
import styles from "./PollMetaAnswer.module.scss";
import { ReactComponent as ArrowUp } from "../../../assets/Icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../assets/Icons/ArrowDown.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Icons/DeleteIcon.svg";

interface PollMetaAnswerProps {
  id: string;
  pageIndex: number;
  questionIndex: number;
  index: number;
  maxIndex: number;
  valuePlaceholder?: string;
  labelPlaceholder?: string;
  deleteVisibility: boolean;
  areLabelNeeded: boolean;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
}

const PollMetaAnswer = ({
  id,
  pageIndex,
  questionIndex,
  index,
  maxIndex,
  valuePlaceholder = "Podaj wartość",
  labelPlaceholder = "Podaj etykietę",
  deleteVisibility,
  areLabelNeeded,
  remove,
  move,
}: PollMetaAnswerProps) => {
  const {
    register,
    formState: { errors },
  }: UseFormReturn<PollSendingData> = useFormContext();

  const moveAnswerUp = () => move(index, index - 1);
  const moveAnswerDown = () => move(index, index + 1);

  const answersErrors =
    errors?.pages?.[pageIndex]?.questions?.[questionIndex]?.meta?.answers?.[
      index
    ];

  const isFirstAnswer = index === 0;
  const isLastAnswer = index === maxIndex;
  const buttonStyles = `d-inline-flex align-items-center justify-content-center lh-base text-light p-1 rounded-circle ${styles.button}`;
  const ARROW_ICON_SIZE = 16;
  const DELETE_ICON_SIZE = 20;

  return (
    <>
      <Col
        xs={areLabelNeeded ? 6 : 8}
        sm={12}
        md={areLabelNeeded ? 4 : 9}
        lg={areLabelNeeded ? 5 : 10}
        className="mb-sm-2 mb-md-0"
      >
        <Form.Group>
          <Form.Label
            htmlFor={`ma-${id}-${index + 1}-answerValue`}
            className="d-none"
          >
            Odpowiedź ${index + 1}
          </Form.Label>
          <InputGroup>
            <InputGroup.Text>{index + 1}</InputGroup.Text>
            <Form.Control
              id={`ma-${id}-${index + 1}-answerValue`}
              {...register(
                `pages.${pageIndex}.questions.${questionIndex}.meta.answers.${index}.value`,
                {
                  required: "Pole nie może być puste.",
                }
              )}
              type="text"
              placeholder={valuePlaceholder}
              isInvalid={!!answersErrors?.value}
            />
            {answersErrors?.value && (
              <Form.Control.Feedback as="p" type="invalid" className="mb-0">
                {answersErrors?.value?.message}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      </Col>
      {areLabelNeeded && (
        <Col xs={6} sm={12} md={4} lg={5} className="mb-sm-2 mb-md-0">
          <Form.Group>
            <Form.Label
              htmlFor={`ma-${id}-${index + 1}-answerLabel`}
              className="d-none"
            >
              Etykieta ${index + 1}
            </Form.Label>
            <Form.Control
              id={`ma-${id}-${index + 1}-answerLabel`}
              {...register(
                `pages.${pageIndex}.questions.${questionIndex}.meta.answers.${index}.label`,
                {
                  required: "Pole nie może być puste.",
                }
              )}
              type="text"
              placeholder={labelPlaceholder}
              isInvalid={!!answersErrors?.label}
            />
            {answersErrors?.label && (
              <Form.Control.Feedback as="p" type="invalid" className="mb-0">
                {answersErrors?.label?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      )}
      <Col
        xs={areLabelNeeded ? 12 : 4}
        sm={12}
        md={areLabelNeeded ? 4 : 3}
        lg={2}
        className="d-flex align-items-center justify-content-end"
      >
        {(!isFirstAnswer || !isLastAnswer) && (
          <div className="d-inline-flex">
            {!isFirstAnswer && (
              <Button
                variant="dark"
                className={`${buttonStyles} me-2 ${
                  answersErrors?.value && "mb-4"
                }`}
                onClick={moveAnswerUp}
              >
                <ArrowUp width={ARROW_ICON_SIZE} height={ARROW_ICON_SIZE} />
              </Button>
            )}

            {!isLastAnswer && (
              <Button
                variant="dark"
                className={`${buttonStyles} me-2 ${
                  answersErrors?.value && "mb-4"
                }`}
                onClick={moveAnswerDown}
              >
                <ArrowDown width={ARROW_ICON_SIZE} height={ARROW_ICON_SIZE} />
              </Button>
            )}
          </div>
        )}
        {deleteVisibility && (
          <Button
            variant="secondary"
            className={`${buttonStyles} ${answersErrors?.value && "mb-4"}`}
            onClick={() => remove(index)}
          >
            <DeleteIcon width={DELETE_ICON_SIZE} height={DELETE_ICON_SIZE} />
          </Button>
        )}
      </Col>
    </>
  );
};

export default PollMetaAnswer;
