import { Badge, Button, Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styles from "./CompanyLocalizationEntity.module.scss";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import { CompanyLocalizationForm } from "../CompanyLocalizationForm/CompanyLocalizationForm";
import {
  useDeleteCompanyDepartmentMutation,
  useGetAllServiceByCompanyDepartmentQuery,
} from "../../../redux/services/companyDepartmentApi";
import { toast } from "react-toastify";
import PopupModal from "../PopupModal/PopupModal";
import { CompanyDepartment } from "../LoyaltyProgramServiceArticle/types";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import TooltipIcon from "../../atoms/TooltipIcon";
import { LoyaltyProgramServiceCompanyDepartment } from "./types";

interface Props {
  data: CompanyDepartment;
  handleChange: (data: CompanyDepartment) => void;
  reFetchCompany: () => QueryActionCreatorResult<
    QueryDefinition<
      string | undefined,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
}

export const CompanyLocalizationEntity = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showValue, setShowValue] = useState(false);
  const [
    deleteCompanyDepartment,
    { isSuccess: deleteIsSuccess, error: deleteError },
  ] = useDeleteCompanyDepartmentMutation();

  const {
    data: companyDepartmentServiceData,
    isLoading: companyDepartmentServiceIsLoading,
  } = useGetAllServiceByCompanyDepartmentQuery(props.data.id);

  const [changes, setChanges] = useState<React.ReactNode | false>(false);

  const { t } = useTranslation();

  const hideModalHandler = () => setShowModal(false);

  useEffect(() => {
    if (props.data.changes === undefined || props.data.changes === null) {
      setChanges(false);
    } else {
      const newChanges = JSON.parse(props.data.changes);
      if (newChanges.comments !== undefined) {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            wymaga poprawy: {newChanges.comments}
          </Badge>,
        );
      } else {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            oczekuje na weryfikację
          </Badge>,
        );
      }
    }
  }, [props.data.changes]);

  useEffect(() => {
    if (deleteIsSuccess) {
      toast.success(t("toast_companyLocalizationEntity_success"));
      if (props.reFetchCompany) {
        props.reFetchCompany();
      }
    }
    if (deleteError) {
      toast.error(t("toast_companyLocalizationEntity_error"));
    }
    // eslint-disable-next-line
  }, [deleteError, deleteIsSuccess]);

  const handleDelete = async () => {
    await deleteCompanyDepartment({
      id: props.data.id,
    });
    hideModalHandler();
  };

  const handlerEdit = () => {
    props.handleChange(props.data);
  };

  return (
    <Container fluid={true} className={"d-flex flex-column"}>
      <div className="d-flex flex-row justify-content-between">
        <div className={`d-flex justify-content-start align-items-center`}>
          <div>
            {changes && changes}
            <div
              className={`fs-19 ${
                props.data.deleted === 1 ? "text-grey-2" : "text-dark-blue"
              }`}
            >
              {props.data.name}
            </div>
          </div>
        </div>
        <div
          className={`d-flex flex-row justify-content-start align-items-center gap-2 ${styles.button}`}
        >
          <Button
            className={`d-flex justify-content-center align-items-center p-0 m-0 ${
              showValue ? styles.arrowUp : styles.arrowDown
            } ${!showValue && "text-dark-blue"}`}
            variant={showValue ? "dark" : "transparent"}
            onClick={() => setShowValue((prevState) => !prevState)}
          >
            <Arrow rotate={90} />
          </Button>
          {props.data.deleted === 0 && (
            <Button
              className={`d-none d-lg-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
              variant={"transparent"}
              onClick={handlerEdit}
            >
              <Pencil />
            </Button>
          )}
          {props.data.isMain === 0 && props.data.deleted === 0 && (
            <TooltipIcon
              desc={
                !companyDepartmentServiceIsLoading &&
                !companyDepartmentServiceData?.meta?.canDelete
                  ? `${t(
                      "tooltip_companyLocalizationEntity_delete_button",
                    )}${companyDepartmentServiceData?.data
                      ?.map(
                        (
                          loyaltyProgramServiceCompanyDepartment: LoyaltyProgramServiceCompanyDepartment,
                        ) =>
                          loyaltyProgramServiceCompanyDepartment
                            .loyaltyProgramServiceId.name,
                      )
                      .join(", ")}
                      ${
                        companyDepartmentServiceData?.meta?.totalResults > 5
                          ? "..."
                          : ""
                      }`
                  : ""
              }
            >
              <Button
                disabled={
                  !!companyDepartmentServiceData?.error ||
                  (!companyDepartmentServiceIsLoading &&
                    !companyDepartmentServiceData?.meta?.canDelete)
                }
                className="d-flex justify-content-center align-items-center p-2 m-0 text-dark-blue"
                variant={"transparent"}
                onClick={() => setShowModal((prev: boolean) => !prev)}
              >
                <TrashBlank />
              </Button>
            </TooltipIcon>
          )}
          <PopupModal
            text="Czy na pewno chcesz usunąć lokalizacje?"
            type="confirm"
            show={showModal}
            setShow={hideModalHandler}
            confirmAction={() => handleDelete()}
          />
        </div>
      </div>
      <div
        className={`d-flex flex-row-reverse d-lg-none  justify-content-between py-3 ${styles.button}`}
      >
        {props.data.deleted === 0 && (
          <Button
            className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
            variant={"transparent"}
            onClick={handlerEdit}
          >
            <Pencil />
          </Button>
        )}
        {props.data.isMain === 0 && props.data.deleted === 0 && (
          <Button
            className="d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue"
            variant={"transparent"}
            onClick={() => setShowModal((prev: boolean) => !prev)}
          >
            <TrashBlank />
          </Button>
        )}
      </div>
      {showValue && (
        <CompanyLocalizationForm
          companyId={null}
          data={props.data}
          disabled={true}
          isFirst={false}
          reFetchCompany={() => false}
        />
      )}
    </Container>
  );
};
