import { pagesCategory } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["PagesCategory"],
});

interface GetPagesCategoryParams {
  titleLike?: string | null;
  offset?: string;
  limit?: string;
  type?: string | null;
  sortOrder?: string;
  orderBy?: string;
  deleted?: string;
  systemId?: string;
  url?: string | null;
  categoryId?: string | null;
  name?: string | null;
}

export const pagesCategoryApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPagesCategory: builder.query({
      query: ({
        titleLike,
        offset,
        limit,
        type,
        sortOrder,
        orderBy,
        deleted,
        systemId,
        url,
      }: GetPagesCategoryParams) => {
        const params = new URLSearchParams({
          ...(titleLike && { titleLike }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(type && { type }),
          ...(sortOrder && { sortOrder }),
          ...(orderBy && { orderBy }),
          ...(deleted && { deleted }),
          ...(systemId && { systemId }),
          ...(url && { url }),
        });
        return {
          url: `${pagesCategory}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PagesCategory"],
    }),

    getPageCategory: builder.query({
      query: (id) => {
        return {
          url: `${pagesCategory}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PagesCategory"],
    }),
    deletePagesCategory: builder.mutation({
      query: (id: string) => ({
        url: `${pagesCategory}/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PagesCategory"],
    }),

    patchPagesCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `${pagesCategory}/${id}/update`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PagesCategory"],
    }),

    postPagesCategory: builder.mutation({
      query: ({ data }) => ({
        url: `${pagesCategory}/create-page-category`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PagesCategory"],
    }),
  }),
});

export const {
  useGetPagesCategoryQuery,
  useLazyGetPagesCategoryQuery,
  useGetPageCategoryQuery,
  usePatchPagesCategoryMutation,
  usePostPagesCategoryMutation,
  useDeletePagesCategoryMutation,
} = pagesCategoryApi;
