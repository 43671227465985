export const ChangesLoyaltyProgramsServiceConfig: ConfigChanges[] = [
  {
    keyName: "name",
    type: "normal",
    name: "Nazwa benefitu",
  },
  {
    keyName: "imageId",
    type: "image",
    name: "Logo firmy",
    maxHeight: 850,
    maxWidth: 1500,
    rounded: false,
  },
  {
    keyName: "description",
    type: "html",
    name: "Informacje o rabacie",
  },
  {
    keyName: "conditions",
    type: "html",
    name: "Warunki promocji",
  },
];
