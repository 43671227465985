import { OffcanvasHeader, OffcanvasBody } from "react-bootstrap";
import { Row } from "react-bootstrap";
import styles from "./MessagesTopicView.module.scss";
import { useGetTopicCasesQuery } from "redux/services/topicApi";
import LeftArrow from "components/atoms/LeftArrow/LeftArrow";
import { useDispatch } from "react-redux";
import {
  setView,
  View,
  setSelectedChatTopic,
  setChatData,
} from "redux/Slice/messages-slice";
import aliviaAvatar from "../../../assets/Icons/aliviaA.svg";

type MessagesTopicViewProps = {
  chats?: ChatData[];
};

export const MessagesTopicView: React.FC<MessagesTopicViewProps> = ({
  chats,
}) => {
  const { data: topics } = useGetTopicCasesQuery({});
  const dispatch = useDispatch();

  const topicsData = topics?.data;

  const OPEN_CONVERSATION_STATUS = 2;
  const ACTIVE_CASE_STATUS = 1;

  return (
    <>
      <OffcanvasHeader className={`p-4 d-flex ${styles.header}`}>
        <div className="">
          <button
            onClick={() => dispatch(setView(View.MAIN))}
            className={`${styles.backBtn}`}
          >
            <LeftArrow />
          </button>
        </div>
        <div className="ms-12 p-0 col-12 text-dark fs-16 d-flex gap-3">
          <span>Do:</span>{" "}
          <span>
            <img src={aliviaAvatar} alt="alivia-logo" />
          </span>
          <span>Alivia</span>
        </div>
      </OffcanvasHeader>
      <OffcanvasBody className="p-4 m-0 d-flex flex-wrap justify-content-center col-12">
        <OffcanvasBody className=" d-flex flex-wrap justify-content-center col-12 overflow-auto">
          <Row className=" w-100">
            <p className="text-align-start p-0 fs-12 fw-600 text-grey-2">
              Wybierz powód kontaktu
            </p>
          </Row>
          <Row className="m-0 p-0 col-12 text-dark">
            {topicsData
              ?.filter(
                (topicsData: ChatCase) =>
                  topicsData?.status === ACTIVE_CASE_STATUS
              )
              .map((topicsData: ChatCase) => {
                const isDisabled = chats?.some(
                  (chat) =>
                    chat.case.id === topicsData.id &&
                    chat.status === OPEN_CONVERSATION_STATUS
                );

                return (
                  <button
                    key={topicsData.id}
                    onClick={() => {
                      dispatch(setView(View.CHAT));
                      dispatch(setSelectedChatTopic(topicsData));
                      dispatch(setChatData(null));
                    }}
                    className={`w-100 mb-12 mt-12 ${styles.topicBtn}  ${
                      isDisabled ? styles.disabled : ""
                    }`}
                    disabled={isDisabled}
                  >
                    {topicsData.case}
                  </button>
                );
              })}
          </Row>
        </OffcanvasBody>
      </OffcanvasBody>
    </>
  );
};
