const sortByNewestMessage = (chatA: ChatData, chatB: ChatData) => {
  const lastMessageA =
    chatA.topicMessage[chatA.topicMessage.length - 1]?.createdAt;
  const lastMessageB =
    chatB.topicMessage[chatB.topicMessage.length - 1]?.createdAt;

  return lastMessageB.localeCompare(lastMessageA);
};

export default sortByNewestMessage;
