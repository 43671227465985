import {
  Button,
  Card,
  Carousel,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import { LoyaltyProgramServiceCard } from "../../molecules/LoyaltyProgramServiceCard/LoyaltyProgramServiceCard";
import React, { useEffect, useState } from "react";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import styles from "./LatestServiceContent.module.scss";
import { Link } from "react-router-dom";
import { getWindowWidth } from "../../../Helpers/getWindowWidth";
import { LoyaltyProgramServiceData } from "../../molecules/LoyaltyProgramServiceArticle/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  name: string;
  maxCard?: number;
  latest?: boolean;
  limitGroup?: number;
  classNameContainer?: string;
  profiling?: boolean;
  currentServiceId?: string;
}

export const LatestServiceContent = ({
  name,
  maxCard = 3,
  limitGroup = 12,
  latest = false,
  classNameContainer = "",
  profiling = false,
  currentServiceId,
}: Props) => {
  const [index, setIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { authUser } = useSelector((state: RootState) => state.user);
  const [maxCardForLatest, setMaxCardForLatest] = useState<number>(
    latest && getWindowWidth() <= 991 ? 1 : maxCard
  );

  const { data: loyaltyProgramsData, isLoading } =
    useGetLoyaltyProgramsServicesQuery({
      offset: "0",
      limit: limitGroup,
      sortBy: "news,DESC",
      categorySlug:
        profiling && authUser?.settings?.Zgoda_na_profilowanie__c
          ? authUser?.settings?.Profilowanie__c
          : undefined,
      withoutServiceIds: currentServiceId,
    });

  useEffect(() => {
    function handleResize() {
      if (latest && getWindowWidth() <= 991) {
        setMaxCardForLatest(1);
      } else {
        setMaxCardForLatest(maxCard);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const onPrevClick = () => {
    const length = loyaltyProgramsData?.data?.length;
    const newIndex = length / maxCardForLatest;
    if (index < 1) {
      setIndex(
        Number(newIndex) === newIndex && newIndex % 1 === 0
          ? Math.floor(newIndex) - 1
          : Math.floor(newIndex)
      );
    } else {
      setIndex(index - 1);
    }
  };
  const onNextClick = () => {
    const length = loyaltyProgramsData?.data?.length;
    if (index !== 0 && index >= length / maxCardForLatest - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <Container fluid={true} className={classNameContainer}>
      <Row>
        <div
          className={`fs-22 fw-600 mb-4 text-dark-blue mx-0 col-9 d-md-flex align-items-center ${styles.title}`}
        >
          <span>{name}</span>
        </div>
        {loyaltyProgramsData?.data?.length > maxCardForLatest && (
          <div className={"d-flex justify-content-end col-3"}>
            <div className={styles.buttonCarousel} onClick={onPrevClick}>
              <Arrow
                style={{ transform: "rotate(180deg)" }}
                className={"text-dark-blue"}
              />
            </div>
            <div className={styles.buttonCarousel} onClick={onNextClick}>
              <Arrow className={"text-dark-blue"} />
            </div>
          </div>
        )}
      </Row>

      <Carousel
        indicators={false}
        controls={false}
        pause={"hover"}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={false}
        prevIcon={false}
      >
        {loyaltyProgramsData?.data?.map(
          // eslint-disable-next-line
          (item: LoyaltyProgramServiceData, i: number) => {
            if (i % maxCardForLatest === 0) {
              return (
                <Carousel.Item className={"border-danger"} key={i}>
                  <Row xs={1} md={maxCardForLatest} className="g-4 mb-4">
                    {Array.from(Array(maxCardForLatest), (e, j) => {
                      return (
                        <Col className="d-flex align-items-stretch" key={j}>
                          {i + j < loyaltyProgramsData?.data?.length ? (
                            <LoyaltyProgramServiceCard
                              isLatest
                              data={loyaltyProgramsData?.data[i + j]}
                            />
                          ) : (
                            <Card className={"border border-0"} />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                </Carousel.Item>
              );
            }
          }
        )}
      </Carousel>
      <Col className={"d-flex justify-content-end"}>
        <Button className={"col-12 col-md-auto"}>
          <Link
            to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}?sortBy=news,DESC`}
          >
            <span className={"text-white"}>
              {t("widget_loyalty_newest_benefits_link")}
            </span>
          </Link>
        </Button>
      </Col>
    </Container>
  );
};
