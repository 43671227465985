import { cities } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface CitiesProps {
  offset?: string;
  limit?: string;
  provinceId?: string;
  q?: string;
}

interface CitiesData {
  id: number;
  name: string;
  nameLocalized: string;
  provinceId: number;
}

interface MetaData {
  status: string;
  totalResults: number;
  currentLimit: number;
  currentOffset: number;
}

interface CitiesResponse {
  data: CitiesData[];
  meta: MetaData;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Cities"],
});

export const citiesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<CitiesResponse, CitiesProps>({
      query: ({ offset, limit, provinceId, q }) => {
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(provinceId && { provinceId }),
          ...(q && { q }),
        });
        return {
          url: `${cities}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Cities"],
    }),
  }),
});

export const { useGetCitiesQuery, useLazyGetCitiesQuery } = citiesApi;
