import { Container, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, Dispatch, SetStateAction } from "react";
import CustomPagination from "../CustomPagination/CustomPagination";
import ServicesTable from "../Tables/ServicesTable/ServicesTable";
import { LoyaltyProgramServiceDataInterface } from "components/templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { InitialSearchParamsType } from "components/templates/CompaniesAdminTemplate/CompaniesAdminTemplate";

interface Props {
  servicesData: {
    data: LoyaltyProgramServiceDataInterface[];
    meta: { totalResults: number };
  };
  currPage: number;
  setCurrPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  initialSearchParams: InitialSearchParamsType;
}

const ServicesSection = ({
  servicesData,
  isLoading,
  currPage,
  setCurrPage,
  initialSearchParams,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    //eslint-disable-next-line
  }, [currPage]);

  const handlePageChange = (page: number) => {
    searchParams.set("servicesPage", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  return (
    <Container fluid className="p-2" ref={tableRef}>
      <h2 className="px-0 fs-1 lh-base fw-600 text-dark me-4 mb-4">
        Zarządzanie benefitami
      </h2>
      {isLoading ? (
        <Container className={"d-flex justify-content-center"}>
          <Spinner animation="border" variant="primary" />
        </Container>
      ) : (
        <>
          <ServicesTable data={servicesData?.data} />
          <CustomPagination
            totalCount={
              servicesData?.meta?.totalResults ||
              initialSearchParams.TOTAL_RESULTS
            }
            pageSize={initialSearchParams.LIMIT}
            currentPage={currPage}
            onPageChange={handlePageChange}
            paginationClassName="text-nowrap"
          />
        </>
      )}
    </Container>
  );
};

export default ServicesSection;
