import NavigationLink from "components/molecules/NavigationLink/NavigationLink";
import { Dispatch, SetStateAction } from "react";
import { Button, Container, Image, Nav, Spinner } from "react-bootstrap";
import NavSet from "../NavSet/NavSet";
import style from "./Navigation.module.scss";
import {
  administrationLinks,
  dashboardLink,
  navigationLinks,
  onkoLinks,
  logOutLink,
  NavigationLinkType,
  NAV_PATH,
  doctorLinks,
  ourPartners,
} from "./navigationData";
import { useGetCompaniesQuery } from "../../../redux/services/companyApi";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { CompanyCreateModal } from "../CompanyCreateModal/CompanyCreateModal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/store/index";
import { AUTH_DATA } from "../../../Helpers/Roles/authData";
import { UserRole } from "redux/Slice/user-slice";
import { Company } from "../../molecules/LoyaltyProgramServiceArticle/types";
import { ReactComponent as DoctorIcon } from "../../../assets/Icons/Show/Doctor.svg";
import { ReactComponent as Briefcase } from "../../../assets/Icons/Show/Briefcase.svg";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import { closeModal, toggleModal } from "redux/Slice/company-modal-slice";
import { usePostLogOutImpersonateMutation } from "redux/services/usersApi";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface NavigationProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

const Navigation = ({ setShow }: NavigationProps) => {
  const { t } = useTranslation();
  const dashboardIsActive =
    window.location.pathname === dashboardLink.path ||
    window.location.pathname === dashboardLink?.path_en;
  const isActive = (path: string) => window.location.pathname === path;

  const { authUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const isModalOpen = useSelector(
    (state: RootState) => state.companyModal.isModalOpen
  );

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const { data: loyaltyProgramsCompanyData, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery({});
  const { data: sfData } = useGetSFContactQuery({});
  const [postLogOutImpersonate] = usePostLogOutImpersonateMutation();
  const navigate = useNavigate();
  const userRolesArray = authUser?.roles?.map(
    (userRole: UserRole) => userRole?.role?.role
  );

  const isDoctor: boolean = AUTH_DATA.DOCTOR.some((doctorRole: string) =>
    userRolesArray.includes(doctorRole)
  );

  const isSponsor: boolean = AUTH_DATA.SPONSOR.some((sponsorRole: string) =>
    userRolesArray.includes(sponsorRole)
  );

  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );
  // zamiana Messengera na inny system
  // https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76
  // const isCallCenter: boolean = userRolesArray.some(
  //   (role) =>
  //     AUTH_DATA.ADMIN_ROLES.includes(role?.toLowerCase()) ||
  //     role?.toLowerCase() === "callcenter",
  // );

  const isPatient: boolean = AUTH_DATA.PATIENT.some((patientRole: string) =>
    userRolesArray.includes(patientRole)
  );
  const isGuardian: boolean = AUTH_DATA.GUARDIAN.some((guardianRole: string) =>
    userRolesArray.includes(guardianRole)
  );
  const isUnauthorized = authUser.roles.length === 0;
  const isImpersonated = !!authUser?.impersonated;

  const logOutImpersonate = async () => {
    await postLogOutImpersonate({});
    window.scrollTo(0, 0);
    navigate(`/${NAV_PATH.DASHBOARD}`);
    window.location.reload();
  };

  const renderNavigationLink = (
    link: NavigationLinkType,
    idx: number,
    onClick?: () => void
  ) => (
    <NavigationLink
      key={idx}
      path={link?.path}
      setShow={setShow}
      className={`d-flex justify-content-start align-items-center px-4 ${
        style.navLink
      } ${
        link?.active === false
          ? style.notActive
          : isActive(link?.path) || isActive(link?.path_en ?? link?.path)
          ? style.active
          : ""
      }`}
      onClick={onClick}
    >
      {link?.Icon}
      {t(`navigation_${link.label}`)}
    </NavigationLink>
  );

  if (
    authUser?.status === 0 ||
    authUser?.status === 100 ||
    authUser?.status === 90 ||
    sfData?.data?.Dead__c ||
    sfData?.data?.Do_usuni_cia__c ||
    authUser?.status === 200
  ) {
    return (
      <Nav className={`flex-column flex-nowrap ${style.navigation} m-0`}>
        {isImpersonated &&
          renderNavigationLink(logOutLink, 999, logOutImpersonate)}
        <NavigationLink
          path={dashboardLink.path}
          className={`d-flex justify-content-start align-items-center px-4 ${
            style.navLink
          } ${dashboardIsActive ? style.active : ""}`}
          setShow={setShow}
        >
          <div className={`d-flex justify-content-center align-items-center`}>
            <div>{dashboardLink.Icon}</div>
            <div>{t(`navigation_${dashboardLink.label}`)}</div>
          </div>
        </NavigationLink>
      </Nav>
    );
  }

  return (
    <Nav className={`flex-column flex-nowrap ${style.navigation} m-0 bg-light`}>
      {isImpersonated &&
        renderNavigationLink(logOutLink, 999, logOutImpersonate)}
      <NavigationLink
        path={dashboardLink.path}
        className={`d-flex justify-content-start align-items-center px-4 ${
          style.navLink
        } ${dashboardIsActive ? style.active : ""}`}
        setShow={setShow}
      >
        <div className={`d-flex justify-content-center align-items-center`}>
          <div>{dashboardLink.Icon}</div>
          <div>{t(`navigation_${dashboardLink.label}`)}</div>
        </div>
      </NavigationLink>

      <NavSet title={t("navigation_navigation")}>
        {navigationLinks?.map((link, idx: number) => {
          switch (link.label) {
            case "articles":
              return renderNavigationLink(link, idx);
            case "loyaltyProgram":
              if (
                isUnauthorized ||
                isPatient ||
                isGuardian ||
                isSponsor ||
                isAdmin
              ) {
                return renderNavigationLink(link, idx);
              }
              return null;
            case "feed":
              if (isPatient || isGuardian || isAdmin) {
                return renderNavigationLink(link, idx);
              }
              return null;
            case "events":
              if (
                isUnauthorized ||
                isPatient ||
                isGuardian ||
                isSponsor ||
                isAdmin
              ) {
                return renderNavigationLink(link, idx);
              }
              return null;
            default:
              return null;
          }
        })}
      </NavSet>
      {isDoctor && (
        <NavSet title={t("navigation_doctorPanel")}>
          {doctorLinks.map((link, idx) => (
            <NavigationLink
              key={idx}
              path={link.path}
              setShow={setShow}
              className={`d-flex justify-content-start align-items-center px-4 ${
                style.navLink
              } ${isActive(link.path) ? style.active : ""}`}
            >
              <DoctorIcon className={`my-1 me-2 ${style.doctor}`} />
              {t(`navigation_${link.label}`)}
            </NavigationLink>
          ))}
        </NavSet>
      )}
      {isSponsor && (
        <NavSet title={t("navigation_companyPanel")}>
          {isLoadingCompanies ? (
            <Container className={"d-flex justify-content-center"}>
              <Spinner animation="border" variant="primary" />
            </Container>
          ) : (
            loyaltyProgramsCompanyData?.data?.map(
              (loyaltyProgramCompany: Company) => (
                <NavigationLink
                  key={loyaltyProgramCompany.id}
                  path={`/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${loyaltyProgramCompany.id}`}
                  setShow={setShow}
                  className={`d-flex justify-content-start align-items-center px-4 ${
                    style.navLinkPartner
                  } ${style.navLink} ${
                    isActive(
                      `/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${loyaltyProgramCompany.id}`
                    )
                      ? style.active
                      : ""
                  }`}
                >
                  <div
                    className={
                      loyaltyProgramCompany.deleted
                        ? style["navLinkPartner--deleted"]
                        : style["navLinkPartner--active"]
                    }
                  >
                    {convertImageIdToUrl(
                      loyaltyProgramCompany.imageIds,
                      false,
                      "normal.jpeg"
                    ) ? (
                      <Image
                        className={`p-0 ${style.image}`}
                        src={
                          convertImageIdToUrl(
                            loyaltyProgramCompany.imageIds,
                            false,
                            "normal.jpeg"
                          ) || undefined
                        }
                      />
                    ) : (
                      <Briefcase className={`p-0 ${style.briefcase}`} />
                    )}

                    <span className="ms-2">{loyaltyProgramCompany.name}</span>
                  </div>
                </NavigationLink>
              )
            )
          )}
          <Button
            className={
              "mx-3 my-2 py-1 d-flex justify-content-center align-items-center text-center btn-outline-grey text-capitalize"
            }
            onClick={() => dispatch(toggleModal())}
          >
            <PlusIcon style={{ height: 16 }} />
            <span className={"py-1 fs-16 fw-400 lf-150"}>
              {t("navigation_addCompanyButton")}
            </span>
          </Button>
          <CompanyCreateModal
            isFirst={true}
            show={isModalOpen}
            handleClose={() => dispatch(closeModal())}
            maxSteps={2}
            dataCompany={null}
          />
        </NavSet>
      )}
      <NavSet title={t("navigation_ourPortals")}>
        {onkoLinks.map((link, idx) => (
          <NavigationLink
            key={idx}
            path={link.path}
            setShow={setShow}
            openInNewTab={openInNewTab}
            className={`d-flex justify-content-start align-items-center px-4 ${style.navLink}`}
          >
            {link.Icon}
            {link.label}
          </NavigationLink>
        ))}
      </NavSet>
      {/*zamiana Messengera na inny system*/}
      {/*https://alivia.atlassian.net/jira/software/projects/MA/boards/1?selectedIssue=MA-76*/}
      {/*{isCallCenter && (*/}
      {/*  <NavSet title={t("navigation_chatPanel")}>*/}
      {/*    {callCenterLinks.map((link, idx) => (*/}
      {/*      <NavigationLink*/}
      {/*        key={idx}*/}
      {/*        path={link.path}*/}
      {/*        setShow={setShow}*/}
      {/*        className={`d-flex justify-content-start align-items-center px-4 ${*/}
      {/*          style.navLink*/}
      {/*        } ${isActive(link.path) ? style.active : ""}`}*/}
      {/*      >*/}
      {/*        <div className={`ps-4`}>{t(`navigation_${link.label}`)}</div>*/}
      {/*      </NavigationLink>*/}
      {/*    ))}*/}
      {/*  </NavSet>*/}
      {/*)}*/}
      {isAdmin && (
        <NavSet title={t("navigation_administration")}>
          {administrationLinks.map((link, idx) => (
            <NavigationLink
              key={idx}
              path={link.path}
              setShow={setShow}
              className={`d-flex justify-content-start align-items-center px-4 ${
                style.navLink
              } ${isActive(link.path) ? style.active : ""}`}
            >
              <div className={`ps-4`}>{t(`navigation_${link.label}`)}</div>
            </NavigationLink>
          ))}
        </NavSet>
      )}
      <NavSet title={t("navigation_ourPartners")}>
        {ourPartners.map((partner, idx) => (
          <div
            key={idx}
            className="d-flex justify-content-start align-items-center px-4 py-2 mb-2"
          >
            {partner.Logo}
          </div>
        ))}
      </NavSet>
    </Nav>
  );
};

export default Navigation;
