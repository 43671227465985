import { Container, Col, Image, Row, Stack } from "react-bootstrap";
import PlaceholderImgMedium from "../../../assets/images/benefitMedium.png";
import PlaceholderImg from "../../../assets/images/benefitNormal.png";
import styles from "./LoyaltyProgramServiceArticle.module.scss";
import ListOfSocialPage from "../ListOfSocialPage/ListOfSocialPage";
import { CompanyAccordion } from "../CompanyAccordion/CompanyAccordion";
import { LoyaltyProgramServiceCode } from "../LoyaltyProgramServiceCode/LoyaltyProgramServiceCode";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { LoyaltyProgramServiceData } from "./types";
import LoyaltyProgramServiceArticleDescriptions from "components/atoms/LoyaltyProgramServiceArticleDescriptions.tsx/LoyaltyProgramServiceArticleDescriptions";

interface Props {
  data: LoyaltyProgramServiceData;
}

export const LoyaltyProgramServiceArticle = ({ data }: Props) => {
  return (
    <Stack gap={5}>
      <Container className={`d-block d-lg-none ${styles.wrapper}`}>
        <Row>
          <Image
            className={`p-0 ${styles.image}`}
            fluid={true}
            src={
              convertImageIdToUrl(data?.imageIds, false, "medium.jpeg") ||
              PlaceholderImgMedium
            }
          />
        </Row>
      </Container>
      <Container className={`d-block d-lg-none ${styles.wrapper}`}>
        <Col className={`d-block d-lg-none`}>
          <div>
            <LoyaltyProgramServiceCode
              companyId={data?.company?.id}
              name={data?.name}
            />
          </div>
        </Col>
      </Container>
      <Container className={styles.wrapper}>
        <Row>
          <Image
            className={`p-0 d-none d-lg-block rounded-top ${styles.image}`}
            fluid={true}
            src={
              convertImageIdToUrl(data?.imageIds, false, "normal.jpeg") ||
              PlaceholderImg
            }
          />
        </Row>
        <Col>
          <Col className={`text-dark-blue ${styles.colPadding}`}>
            <LoyaltyProgramServiceArticleDescriptions
              label="O ofercie"
              description={data?.description}
            />
            <LoyaltyProgramServiceArticleDescriptions
              label="Warunki"
              description={data?.conditions}
            />
            <Col>
              <CompanyAccordion
                data={data?.loyaltyProgramServiceDepartment}
                defaultActiveKey={"0"}
              />
            </Col>
            {/* <Row className={`${styles.rowPadding}`}>
              <span
                className={`fw-bolder fs-19 col-12 text-dark-blue ${styles.infoPadding}`}
              >
                O ofercie
              </span>
              <div className={"text-dark-blue text-break"}>
                {data?.description}
              </div>
            </Row>
            <Row className={`${styles.rowPadding}`}>
              <span
                className={`fw-bolder fs-19 col-12 text-dark-blue ${styles.infoPadding}`}
              >
                Warunki
              </span>
              <div className={"text-dark-blue text-break"}>
                {data?.conditions}
              </div>
            </Row> */}
            <Row
              className={`${
                data?.company?.socialAddresses ? styles.rowPadding : ""
              }`}
            >
              <span
                className={`fw-bolder fs-19 col-12 text-dark-blue ${styles.infoPadding}`}
              >
                O firmie
              </span>
              <div className={"text-dark-blue text-break"}>
                {data?.company?.description}
              </div>
            </Row>
            {data?.company?.socialAddresses && (
              <Row>
                <span className={"fw-bolder text-dark-blue fs-19 col-12 pb-3"}>
                  Serwisy internetowe
                </span>
                <ListOfSocialPage
                  social={data?.company?.socialAddresses || ""}
                  size={40}
                />
              </Row>
            )}
          </Col>
        </Col>
      </Container>
    </Stack>
  );
};
