import { useState, useEffect } from "react";
import { Badge, Button } from "react-bootstrap";
import { Sliders } from "react-bootstrap-icons";
import WidgetsSettingsModal from "./../WidgetsSettingsModal/WidgetsSettingsModal";
import Avatar from "components/atoms/Avatar/Avatar";
import { ProfileHeaderProps } from "./types";
import { usePatchMeMutation } from "redux/services/meApi";
import styles from "./ProfileHeader.module.scss";
import { useOpenPrivateMessage } from "Helpers/openMessageHooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import PopupModal from "../PopupModal/PopupModal";

const ProfileHeader = ({
  isFirstVisit,
  type,
  userData,
  accountStatus,
  isAuthenticated,
  setPosition,
  setActive,
  widgetsData,
  guardian,
  handleChangeAvatar,
}: ProfileHeaderProps) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<{
    loading: boolean;
    message: string;
    status: "sending" | "sent" | "error" | "none";
  }>({ loading: false, message: "", status: "none" });
  const chatUser: ChatUser = {
    ...userData,
    updatedAt: userData.updateAt,
    role: "user",
    roles: userData.roles as ChatUserRole[],
    settings: JSON.stringify(userData.settings),
    avatar: userData.avatar || undefined,
  };
  const pMessage = useOpenPrivateMessage(chatUser);
  const [patchMe, { isLoading, isSuccess, isError }] = usePatchMeMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoading) {
      setModalContent({
        loading: true,
        message: "Trwa zmienianie ustawień. Proszę czekać.",
        status: "sending",
      });
    }
    if (isSuccess) {
      setModalContent({
        loading: false,
        message: "Ustawienia zostały zmienione.",
        status: "sent",
      });
    }
    if (isError) {
      setModalContent({
        loading: false,
        message: `Nie udało się zmienić ustawień.`,
        status: "error",
      });
    }
  }, [isLoading, isSuccess, isError]);

  const saveWidgetsSettings = async () => {
    setShowModal(true);
    setShow(false);
    widgetsData &&
      (await patchMe({
        data: {
          ...userData,
          localSettings: {
            ...userData.localSettings,
            widgetsSettings: widgetsData,
          },
        },
      }));
  };

  const badge = (() => {
    switch (accountStatus) {
      case "blocked":
        return "Konto zostało zablokowane";
      case "dead":
        return "Konto zostało zablokowane z powodu śmierci pacjenta";
      case "deleteProccess":
        return "Złożono wniosek o usunięcie konta, twoje konto zostanie usunięte w przecięgu 7 dniu.";
      case "anonymization":
        return "Konto zostało poddane anonimizacji";
      default:
        return "Konto aktywne";
    }
  })();

  const info = (() => {
    switch (accountStatus) {
      case "blocked":
        return t("dashboard_account_block_info");
      case "dead":
        return t("dashboard_account_dead_info");
      case "deleteProccess":
        return t("dashboard_account_deleteproccess_info");
      case "anonymization":
        return t("dashboard_account_anonymization_info");
      default:
        return "";
    }
  })();

  return (
    <>
      <div
        className={`d-flex flex-column mb-5 mb-sm-4 ${styles.profileHeaderContainer}`}
      >
        <div
          className={`d-flex flex-sm-row ${
            type === "account" ? "flex-column" : "flex-row"
          } align-items-center justify-content-start`}
        >
          <Avatar
            size={type === "account" ? "lg-fixed" : "lg"}
            isAuthenticated={isAuthenticated}
            url={userData?.avatar?.accessUrl}
            handleChangeAvatar={handleChangeAvatar}
            accountStatus={accountStatus}
          />
          <div
            className={`${
              type === "account" && styles.infoContainer
            } justify-content-center d-flex ${
              type === "account"
                ? "align-items-center"
                : "align-items-start ms-4"
            } align-items-sm-start flex-column ms-0 ms-sm-5`}
          >
            {accountStatus && (
              <Badge
                bg={accountStatus === "active" ? "success" : "danger"}
                className={`mb-1 mt-sm-0 mt-4 ls- ${styles.badge}`}
              >
                {badge}
              </Badge>
            )}
            <h1
              className={`fw-600 dark w-auto text-dark-blue mt-sm-2 ${
                type === "account"
                  ? styles.headerTextProfile
                  : styles.headerText
              } ${!isAuthenticated && "mb-2"}`}
            >
              {type === "dashboard" ? (
                <>
                  Witaj {!isFirstVisit && "ponownie"}{" "}
                  {accountStatus === "active" &&
                    (!userData?.name ? userData?.firstName : userData?.name)}
                  !
                </>
              ) : (
                <>{userData.name}</>
              )}
            </h1>
            {type === "dashboard" && guardian && guardian.status === 1 && (
              <div className="mb-0 mt-1 d-flex flex-row">
                <span className={styles.guardianMargin}>Opiekun:</span>
                <Avatar
                  size="xs"
                  url={guardian?.guardianId?.avatar?.accessUrl}
                />
                <span className="ms-1">{guardian?.guardianId?.name}</span>
              </div>
            )}

            {type === "account" && (
              <>
                {isAuthenticated ? (
                  <Link
                    to={`/${NAV_PATH.SETTINGS}`}
                    className="text-sm-start text-center"
                  >
                    <Button
                      variant="outline-primary"
                      className="text-uppercase mt-4"
                    >
                      USTAWIENIA KONTA
                    </Button>
                  </Link>
                ) : (
                  <div className="d-flex flex-row mt-4 w-100">
                    <div className="text-sm-start text-center w-100">
                      <Button
                        variant="outline-primary"
                        className="text-uppercase w-100"
                        onClick={pMessage}
                      >
                        Wyślij wiadomość
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {type === "dashboard" && accountStatus === "active" && (
          <div className="d-flex flex-row-reverse">
            <Button
              variant="outline-primary"
              className="pb-12 btn-alivia stroke-primary d-none d-sm-flex justify-content-center align-items-center"
              onClick={() => setShow(!show)}
            >
              <Sliders style={{ transform: "rotate(270deg)" }} size={20} />
            </Button>
            <Button
              variant="outline-primary"
              className="pb-12 mt-3 stroke-primary text-uppercase d-flex d-sm-none justify-content-center align-items-center w-100 m-0"
              onClick={() => setShow(!show)}
            >
              ZARZĄDZAJ WIDGETAMI
            </Button>
          </div>
        )}
      </div>
      {type === "dashboard" && setPosition && setActive && (
        <WidgetsSettingsModal
          show={show}
          setShow={setShow}
          widgetsData={widgetsData}
          setPosition={setPosition}
          setActive={setActive}
          onClose={saveWidgetsSettings}
        />
      )}
      {accountStatus !== "active" && <p className="text-center">{info}</p>}
      <PopupModal
        show={showModal}
        setShow={setShowModal}
        text={modalContent.message}
        loading={modalContent.loading}
        type="info"
        confirmAction={() => setShowModal(false)}
        titleClasses="fw-600 text-center"
        hideHeader={true}
      />
    </>
  );
};
export default ProfileHeader;
