import { attachments } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Attachments"],
});

export const attachmentsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAttachment: builder.query({
      query: ({ id }) => {
        return {
          url: `${attachments}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Attachments"],
    }),

    getAttachments: builder.query({
      query: ({ nameLike, offset, limit, ids }) => {
        const params = new URLSearchParams({
          ...(nameLike && { nameLike }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(ids && { ids }),
          sortOrder: "DESC",
        });

        return {
          url: `${attachments}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Attachments"],
    }),

    postAttachments: builder.mutation({
      query: ({ data, attachmentType }) => {
        let formdata = new FormData();
        formdata.append("file", data);
        formdata.append("fileName", `${data.name}`);
        formdata.append("attachmentType", `${attachmentType}`);
        return {
          url: `${attachments}`,
          method: "POST",
          headers: {},
          body: formdata,
        };
      },
      invalidatesTags: ["Attachments"],
    }),

    deleteAttachment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `${attachments}/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Attachments"],
    }),
  }),
});

export const {
  useGetAttachmentQuery,
  useGetAttachmentsQuery,
  usePostAttachmentsMutation,
  useDeleteAttachmentMutation,
} = attachmentsApi;
