import { Button, Col, Container, Spinner, Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styles from "./ChangesCompanyTemplate.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import { Changes } from "../../organisms/Changes/Changes";
import { ChangesImage } from "../../organisms/ChangesImage/ChangesImage";
import { ChangesCompanyConfig } from "./ChangesCompanyConfig";
import { ChangesSocialMedia } from "../../organisms/ChangesSocialMedia/ChangesSocialMedia";
import {
  useGetCompanyQuery,
  usePatchCompanyMutation,
} from "../../../redux/services/companyApi";
import { countMatchingKeys } from "../../../Helpers/isExistKey";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { ChangesInfo } from "../../organisms/ChangesInfo/ChangesInfo";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { domain } from "../../organisms/Landing/Landing";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const ChangesCompanyTemplate: React.FC = () => {
  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [changes, setChanges] = useState<any>(false);
  const { data: loyaltyProgramsCompanyData, isLoading } =
    useGetCompanyQuery(companyId);
  const [patchCompany] = usePatchCompanyMutation();
  const [notification] = usePostNotificationsMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (!loyaltyProgramsCompanyData?.data?.changes) {
      setChanges(false);
    } else {
      setChanges(JSON.parse(loyaltyProgramsCompanyData?.data?.changes));
    }
  }, [loyaltyProgramsCompanyData?.data?.changes, isLoading]);

  useEffect(() => {
    (async () => {
      if (!isLoading && changes) {
        await patchCompany({
          id: companyId,
          data: {
            changes: JSON.stringify({
              ...changes,
              startView: new Date().toISOString(),
            }),
          },
        });
      }
    })();
    // eslint-disable-next-line
  }, [isLoading]);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handleAcceptAll = async () => {
    const hasBeenUpdated = await patchCompany({
      id: companyId,
      data: { ...changes, status: 1, changes: null },
    });
    if ("data" in hasBeenUpdated) {
      toast.success(t("toast_changesCompanyTemplate_success"));
      if (companyId) {
        await notification({
          data: {
            title: "notification_ChangesCompanyTemplate_accept_title",
            type: NotificationTypeEnum["loyalty-program"],
            viewSender: 0,
            senderUser: null,
            url: `${domain}/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${companyId}`,
            manyUser: "COMPANY",
            idMany: Number(companyId),
          },
        });
      }
      navigate(-1);
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_changesCompanyTemplate_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_changesCompanyTemplate_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_changesCompanyTemplate_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };
  const handleStatus = async () => {
    await patchCompany({
      id: companyId,
      data: { status: !loyaltyProgramsCompanyData?.data.status ? 1 : 0 },
    });
  };
  const handleDeleted = async () => {
    await patchCompany({
      id: companyId,
      data: { deleted: !loyaltyProgramsCompanyData?.data.deleted ? 1 : 0 },
    });
  };

  const handleExit = async () => {
    const newChanges = { ...changes };
    delete newChanges.startView;
    await patchCompany({
      id: companyId,
      data: {
        changes: JSON.stringify(newChanges),
      },
    });
    navigate(-1);
  };

  const handlePatch = async (obj: any, keyName: string) => {
    const newChanges = { ...changes };
    delete newChanges[keyName];
    if (
      countMatchingKeys(
        changes,
        ChangesCompanyConfig.map((value: ConfigChanges) => value.keyName)
      ) === 1 &&
      changes[keyName]
    ) {
      await patchCompany({
        id: companyId,
        data: { ...obj, status: 1, changes: null },
      });

      if (companyId) {
        await notification({
          data: {
            title: "notification_ChangesCompanyTemplate_accept_title",
            type: NotificationTypeEnum["loyalty-program"],
            viewSender: 0,
            senderUser: null,
            url: `${domain}/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${companyId}`,
            manyUser: "COMPANY",
            idMany: Number(companyId),
          },
        });
      }
      navigate(-1);
    } else {
      const newOBJ = {
        id: companyId,
        data: {
          ...obj,
          changes: JSON.stringify(newChanges),
        },
      };
      await patchCompany(newOBJ);
    }
  };
  const handleComments = async (comment: string) => {
    const newChanges = {
      ...changes,
      comments: comment,
      commentsDate: new Date().toISOString(),
    };
    await patchCompany({
      id: companyId,
      data: {
        changes: JSON.stringify(newChanges),
      },
    });
    if (companyId) {
      await notification({
        data: {
          title: "notification_ChangesCompanyTemplate_comments_title",
          type: NotificationTypeEnum["loyalty-program"],
          viewSender: 0,
          senderUser: null,
          url: `${domain}/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${companyId}`,
          manyUser: "COMPANY",
          idMany: Number(companyId),
        },
      });
    }
  };

  return (
    <Container fluid={true} className="bg-light">
      <section className={`${styles.section}`}>
        <Stack className={"w-100 p-0 m-0"} gap={5}>
          <Col
            className={"d-flex align-items-center cursor-pointer pb-4"}
            onClick={handleExit}
          >
            <Arrow
              style={{ transform: "rotate(180deg)" }}
              className={"text-primary"}
            />
            <span className={"ps-2 text-primary"}>
              WRÓĆ DO PRZEGLĄDANIA ZMIAN
            </span>
          </Col>
          <Col className={`${styles.wrapper}`}>
            <ChangesInfo
              changes={changes}
              status={loyaltyProgramsCompanyData?.data.status}
              deleted={loyaltyProgramsCompanyData?.data.deleted}
              handleStatus={handleStatus}
              handleDeleted={handleDeleted}
              handleComments={handleComments}
              isAdmin={true}
            />
          </Col>

          {ChangesCompanyConfig.map((value: ConfigChanges, index: number) => {
            switch (value.type) {
              case "normal":
                return (
                  <Changes
                    key={index}
                    keyName={value.keyName}
                    changes={changes}
                    data={loyaltyProgramsCompanyData?.data}
                    name={value.name}
                    handlePatch={handlePatch}
                    handleComments={handleComments}
                    isAdmin={true}
                  />
                );
              case "image":
                return (
                  <ChangesImage
                    key={index}
                    keyName={value.keyName}
                    changes={changes}
                    data={loyaltyProgramsCompanyData?.data}
                    name={value.name}
                    maxHeight={value.maxHeight ?? 160}
                    rounded={!!value.rounded}
                    handlePatch={handlePatch}
                    handleComments={handleComments}
                    isAdmin={true}
                  />
                );
              case "social":
                return (
                  <ChangesSocialMedia
                    key={index}
                    keyName={value.keyName}
                    changes={changes}
                    data={loyaltyProgramsCompanyData?.data}
                    name={value.name}
                    handlePatch={handlePatch}
                    handleComments={handleComments}
                    isAdmin={true}
                  />
                );
              default:
                return <></>;
            }
          })}

          <div className={"d-flex gap-2 justify-content-end"}>
            <Button
              className={"fs-16 btn-outline-dark-blue"}
              onClick={handleExit}
            >
              WRÓĆ
            </Button>
            <Button
              className={"fs-16"}
              onClick={() => setShowModal((prev: boolean) => !prev)}
            >
              AKCEPTUJ CAŁOŚĆ
            </Button>
            <PopupModal
              text="Potwierdź całkowitą akceptację"
              smallText={"Po potwierdzeniu nie można powrócić"}
              type="confirm"
              show={showModal}
              setShow={() => setShowModal(false)}
              confirmAction={handleAcceptAll}
            />
          </div>
        </Stack>
      </section>
    </Container>
  );
};

export default ChangesCompanyTemplate;
