import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { usePostLoyaltyProgramServiceUploadBatchMutation } from "redux/services/loyaltyProgramApi";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TooltipIcon from "../../atoms/TooltipIcon";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";

interface PartnerCodesUploadProps {
  serviceId: string;
}

interface UploadResponse {
  skipped?: string[];
}

export default function PartnerCodesUpload({
  serviceId,
}: PartnerCodesUploadProps) {
  const [useManualCodes, setUseManualCodes] = useState(false);
  const [response, setResponse] = useState<UploadResponse | null>(null);
  const [postSponsorCodes, { isLoading, isSuccess, isError, error }] =
    usePostLoyaltyProgramServiceUploadBatchMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const toggleInputMethod = () => setUseManualCodes((prev) => !prev);

  const onSubmit = async (data: any) => {
    let payload = useManualCodes
      ? { serviceId, data: { codes: data?.codes } }
      : { serviceId, data: { file: data?.file?.[0] } };

    try {
      const result = await postSponsorCodes(payload).unwrap();
      setResponse(result);
    } catch (err) {
      toast.error(`Błąd: ${err}`);
    }
  };

  useEffect(() => {
    if (isSuccess && response) {
      const skipped = response?.skipped;
      if (skipped && skipped.length > 0) {
        toast.error(
          `${skipped.length} kodów zostało pominiętych, ponieważ już istnieją w systemie.`
        );
      } else {
        toast.success(t("toast_upload_batchPartnerCodes_success"));
      }
    }

    if (isError && error) {
      if ("message" in error) {
        toast.error(
          `${t("toast_upload_batchPartnerCodes_error")} ${error.message}`
        );
      } else {
        toast.error(t("toast_upload_batchPartnerCodes_generic_error"));
      }
    } // eslint-disable-next-line
  }, [isSuccess, isError, error, t]);

  return (
    <div className="mt-5">
      <h2 className="fs-22 fw-600 mb-2 text-dark-blue mx-0 col-9 d-md-flex align-items-center">
        Dodawanie kodów partnera
      </h2>
      <Form onSubmit={handleSubmit(onSubmit)} className="d-flex row gap-3">
        <label className="d-flex">
          <span className="me-2">Wgraj plik CSV z kodami</span>
          <Form.Check
            type="switch"
            id="manual-codes-switch"
            checked={useManualCodes}
            onChange={toggleInputMethod}
          />
          <span>Wpisz kody ręcznie. </span>
          <div className={"d-flex align-items-center text-primary ps-2"}>
            <TooltipIcon desc="Każdy kod musi być umieszczony w osobnej linii i zakończony przecinkiem. Jest to wymagane dla poprawnego przetwarzania kodów.">
              <div className={"cursor-pointer d-flex align-items-center"}>
                <Info />
              </div>
            </TooltipIcon>
          </div>
        </label>
        {!useManualCodes && (
          <Form.Control
            type="file"
            {...register("file", { required: !useManualCodes })}
          />
        )}
        {useManualCodes && (
          <Form.Control
            as="textarea"
            {...register("codes", { required: useManualCodes })}
          />
        )}
        {errors.file && <span className="text-danger">Proszę wybrać plik</span>}
        {errors.codes && (
          <span className="text-danger">Proszę wpisać kody</span>
        )}

        <Button type="submit" disabled={isLoading}>
          Wyślij
        </Button>
      </Form>
    </div>
  );
}
