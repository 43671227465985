import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import TinyEditor from "../../organisms/TinyEditor/TinyEditor";
import {
  AgreementType,
  useGetLoyaltyProgramsAgreementLastVersionQuery,
  useGetLoyaltyProgramsQuery,
  usePatchLoyaltyProgramAgreementMutation,
  usePostLoyaltyProgramAgreementSponsorMutation,
  usePostLoyaltyProgramAgreementUserMutation,
} from "../../../redux/services/loyaltyProgramApi";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { LoyaltyProgramAgreementDataForm } from "./types";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import styles from "../CompanyForm/CompanyForm.module.scss";
import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import Select, { SingleValue } from "react-select";
import { LoyaltyProgramDataInterface } from "../../templates/LoyaltyProgramServiceTemplate/LoyaltyProgramTemplate";
import { generateVersionOptions } from "../../../Helpers/generateVersionOptions";
import { toast } from "react-toastify";
import TooltipIcon from "../../atoms/TooltipIcon";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";
import { AGREEMENT_DATA } from "../../../Helpers/loyaltyProgramAgreementsData";
import { ModalDecision } from "../../atoms/ModalDecision/ModalDecision";
import { useTranslation } from "react-i18next";

interface Props {
  data: LoyaltyProgramsAgreementAllData | null;
  isActive: boolean;
  handleDeleteData: () => void;
  handleIsActive: (active: boolean) => void;
}

const INITIAL_ALL = "1";

export const AgreementLoyaltyProgramForm = (props: Props) => {
  const { t } = useTranslation();
  const [versionOptions, setVersionOptions] = useState<ReactSelectOption[]>([]);
  const [newAgreementId, setNewAgreementId] = useState<number | null>(null);
  const [isDraftVersion, setIsDraftVersion] = useState<boolean>(false);

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoyaltyProgramAgreementDataForm>({
    defaultValues: {
      id: props.data?.id ?? 0,
      name: props.data?.name ?? "",
      description: props.data?.description ?? null,
      version: props.data?.version ?? null,
      programId: props.data?.loyaltyProgramId.toString() ?? "",
      type: props.data?.type ?? "",
    },
  });

  useEffect(() => {
    if (props.data) {
      setValue("id", props.data?.id);
      setValue("name", props.data?.name);
      setValue("description", props.data?.description);
      setValue("version", props.data?.version);
      setValue("programId", props.data?.loyaltyProgramId.toString());
      setValue("type", props.data?.type);
    } else {
      setValue("id", 0);
      setValue("name", "");
      setValue("description", null);
      setValue("version", null);
      setValue("programId", "");
      setValue("type", "");
    }
    // eslint-disable-next-line
  }, [props.data]);

  const [postLoyaltyProgramAgreementSponsor] =
    usePostLoyaltyProgramAgreementSponsorMutation();
  const [postLoyaltyProgramAgreementUser] =
    usePostLoyaltyProgramAgreementUserMutation();
  const [patchLoyaltyProgramAgreement] =
    usePatchLoyaltyProgramAgreementMutation();
  const { data: loyaltyProgramsAgreementLastVersionData } =
    useGetLoyaltyProgramsAgreementLastVersionQuery(
      !!(watch("type") && watch("programId"))
        ? {
            type: watch("type") as AgreementType,
            programId: watch("programId"),
          }
        : skipToken
    );
  const { data: loyaltyProgramsData, isLoading: loyaltyProgramsIsLoading } =
    useGetLoyaltyProgramsQuery({ all: INITIAL_ALL });

  useEffect(() => {
    if (loyaltyProgramsAgreementLastVersionData) {
      setVersionOptions(
        generateVersionOptions(
          loyaltyProgramsAgreementLastVersionData?.data?.[0]?.version
        ) as ReactSelectOption[]
      );
    }
  }, [loyaltyProgramsAgreementLastVersionData]);

  if (loyaltyProgramsIsLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const loyaltyProgramsOptions: ReactSelectOption[] =
    loyaltyProgramsData?.data.map((program: LoyaltyProgramDataInterface) => ({
      value: program.id.toString(),
      label: t(program.name),
    }));

  const submitHandler: SubmitHandler<LoyaltyProgramAgreementDataForm> = async (
    data
  ): Promise<void> => {
    if (props.data === null || props.data?.status === 1) {
      const newData = {
        programId: data.programId,
        data: {
          name: data.name,
          description: data.description,
        },
      };
      let result;
      if (data.type === "sponsor") {
        result = await postLoyaltyProgramAgreementSponsor(newData);
      } else {
        result = await postLoyaltyProgramAgreementUser(newData);
      }
      if ("data" in result) {
        toast.success(t("toast_agreementLoyaltyProgramForm_add_success"));
        if (isDraftVersion) {
          props.handleIsActive(false);
        } else {
          setNewAgreementId(result?.data?.data?.id);
          setValue("type", result?.data?.data?.type);
          setValue("programId", result?.data?.data?.loyaltyProgram?.id);
        }
      } else if ("error" in result) {
        const error = result?.error as TranslationSubmitError;

        if (error.data.statusCode === 500)
          toast.error(t("toast_agreementLoyaltyProgramForm_add_error_500"));

        if (error.data.statusCode === 400) toast.error(error.data.message);
        toast.error(t("toast_agreementLoyaltyProgramForm_add_error"));
      }
    } else {
      if (data.id) {
        const result = await patchLoyaltyProgramAgreement({
          agreementId: data.id.toString(),
          data: {
            name: data.name,
            description: data.description,
          },
        });
        if ("data" in result) {
          toast.success(t("toast_agreementLoyaltyProgramForm_update_success"));
          if (isDraftVersion) {
            props.handleIsActive(false);
          } else {
            setNewAgreementId(data.id);
            setValue("type", result?.data?.data?.type);
            setValue("programId", result?.data?.data?.loyaltyProgram?.id);
          }
        } else if ("error" in result) {
          const error = result?.error as TranslationSubmitError;

          if (error.data.statusCode === 500)
            toast.error(
              t("toast_agreementLoyaltyProgramForm_update_error_500")
            );

          if (error.data.statusCode === 404)
            toast.error(
              t("toast_agreementLoyaltyProgramForm_update_error_404")
            );

          if (error.data.statusCode === 400) toast.error(error.data.message);
          toast.error(t("toast_agreementLoyaltyProgramForm_update_error"));
        }
      }
    }
  };

  const handleAccept = async () => {
    if (newAgreementId) {
      const result = await patchLoyaltyProgramAgreement({
        agreementId: newAgreementId.toString(),
        data: {
          status: 1,
          version: watch("version"),
        },
      });
      if ("data" in result) {
        toast.success(t("toast_agreementLoyaltyProgramForm_update_success"));
        setNewAgreementId(null);
        setValue("version", "");
        props.handleIsActive(false);
      } else if ("error" in result) {
        const error = result?.error as TranslationSubmitError;

        if (error.data.statusCode === 500)
          toast.error(t("toast_agreementLoyaltyProgramForm_update_error_500"));

        if (error.data.statusCode === 404)
          toast.error(t("toast_agreementLoyaltyProgramForm_update_error_404"));

        if (error.data.statusCode === 400) toast.error(error.data.message);
        toast.error(t("toast_agreementLoyaltyProgramForm_update_error"));
      }
    }
    setValue("version", "");
  };
  const handleExit = () => {
    setNewAgreementId(null);
    setValue("version", "");
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <ModalDecision
        show={!!newAgreementId}
        title={"Czy chcesz natychmiast go aktywować?"}
        onHide={handleExit}
        handleExit={handleExit}
        handleAccept={handleAccept}
        disabledAccept={!watch("version")}
        size={"lg"}
        buttonExitLabel={"zapisz wersje roboczą"}
        buttonAcceptLabel={"Zaakceptuj"}
        buttonExitClass={"btn-outline-dark-blue"}
        ExitVariant={"secondary"}
      >
        <Col className={"text-dark-blue"}>
          <p>Jeżeli chcesz aktywować, należy wybrać wersje regulaminu</p>
          <p>
            Możesz dokończyć później aktywację regulaminu. Regulamin będzie
            znajdował sie dopiero po pokazaniu wszystkich. Aby aktywować należy
            zmienić status oraz nadać wersję
          </p>
        </Col>
        <Controller
          name={"version"}
          control={control}
          rules={{
            required: false,
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              isDisabled={!versionOptions.length}
              ref={ref}
              classNamePrefix="select_"
              placeholder="Wszystkie"
              isClearable={false}
              isSearchable={false}
              id="version"
              options={versionOptions}
              onChange={(option: SingleValue<ReactSelectOption>) =>
                onChange(option?.value as string)
              }
              styles={customStyles(!!errors.version)}
              components={{ DropdownIndicator }}
            />
          )}
        />
      </ModalDecision>
      <Col>
        <div className={"d-flex justify-content-end"}>
          <Button
            variant={props.isActive ? "dark" : "transparent"}
            className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
              props.isActive
                ? "text-light"
                : "text-dark-blue bg-transparent border-0"
            }`}
            onClick={() => props.handleIsActive(!props.isActive)}
          >
            <PlusIcon />
          </Button>
        </div>
        {props.isActive && (
          <Row className={"border border-1 rounded-2 p-3 my-3 mx-0"}>
            <div className={"d-flex gap-2 text-grey-2 fs-14 mb-4"}>
              <span>
                {props.data === null || props.data?.status === 1
                  ? "Nowy regulamin"
                  : "Edytuj regulamin"}
              </span>
              <TooltipIcon
                desc={
                  props.data === null || props.data?.status === 1
                    ? t("loyalty_agreement_tooltip_create")
                    : t("loyalty_agreement_tooltip_update")
                }
              >
                <Info />
              </TooltipIcon>
            </div>
            <ComponentWithDescription
              label={"Nazwa Regulaminu*"}
              labelFs={`fs-16 ${
                errors.name ? "text-danger" : "text-dark-blue"
              }`}
              className="my-2 pb-3"
            >
              <Form.Control
                type="text"
                id="name"
                maxLength={100}
                aria-describedby="name"
                className={`w-100 text-dark-blue ${styles.input} `}
                {...register("name", {
                  required: { value: true, message: "Pole wymagane" },
                  maxLength: { value: 100, message: "Maksymalnie 100 znaków" },
                })}
                isInvalid={Boolean(errors.name)}
              />
              {errors.name && (
                <Form.Control.Feedback type={"invalid"}>
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </ComponentWithDescription>
            <ComponentWithDescription
              label={"Przeznaczenie*"}
              labelFs={`fs-16 ${
                errors.type ? "text-danger" : "text-dark-blue"
              }`}
              className="my-2 pb-3 col-12 col-lg-6"
            >
              <Controller
                name={"type"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={AGREEMENT_DATA.TYPE_OPTIONS.find(
                      (selectOption: ReactSelectOption) =>
                        selectOption.value === value
                    )}
                    classNamePrefix="select_"
                    placeholder="Wszystkie"
                    isClearable={false}
                    isSearchable={false}
                    id="type"
                    options={AGREEMENT_DATA.TYPE_OPTIONS}
                    onChange={(option: SingleValue<ReactSelectOption>) =>
                      onChange(option?.value as AgreementType)
                    }
                    styles={customStyles(!!errors.type)}
                    components={{ DropdownIndicator }}
                  />
                )}
              />
            </ComponentWithDescription>
            <ComponentWithDescription
              label={"Program benefitu*"}
              labelFs={`fs-16 ${
                errors.programId ? "text-danger" : "text-dark-blue"
              }`}
              className="my-2 pb-3 col-12 col-lg-6"
            >
              <Controller
                name={"programId"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={loyaltyProgramsOptions.find(
                      (selectOption: ReactSelectOption) =>
                        selectOption.value === value
                    )}
                    classNamePrefix="select_"
                    placeholder="Wybierz"
                    isClearable={false}
                    isSearchable={false}
                    id="programId"
                    options={loyaltyProgramsOptions}
                    onChange={(option: SingleValue<ReactSelectOption>) =>
                      onChange(option?.value as string)
                    }
                    styles={customStyles(!!errors.programId)}
                    components={{ DropdownIndicator }}
                  />
                )}
              />
            </ComponentWithDescription>
            <ComponentWithDescription
              label={"Treść regulaminu"}
              labelFs={`fs-16 ${
                errors.description ? "text-danger" : "text-dark-blue"
              }`}
              className="my-2 pb-3"
            >
              <TinyEditor
                setValue={setValue}
                name={"description"}
                data={props.data?.description || ""}
              />
            </ComponentWithDescription>
            <Col>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  className={"end-0 btn-outline-dark-blue"}
                  onClick={props.handleDeleteData}
                >
                  anuluj
                </Button>
                <Button
                  type="submit"
                  className={"end-0 btn-outline-dark-blue"}
                  onClick={() => setIsDraftVersion(true)}
                >
                  zapisz wersję roboczą
                </Button>
                <Button
                  type="submit"
                  className={"end-0"}
                  onClick={() => setIsDraftVersion(false)}
                >
                  opublikuj
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Col>
    </Form>
  );
};
