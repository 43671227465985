import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { slugifyWithUnderscore } from "Helpers/slugify";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function RadioGroupInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = useFormContext();

  const watchedValue = watch(question?.fieldName, "");

  const validationMeta = question?.meta?.find((m) => m.key === "validation");

  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const areAnswersAsSlug = validationMeta
    ? JSON.parse(validationMeta.value)?.areAnswersAsSlug ?? false
    : false;
  const options =
    question?.meta?.find((meta) => meta.key === "answers")?.value.split("||") ||
    [];
  const labels =
    question?.meta?.find((meta) => meta.key === "labels")?.value.split("||") ||
    [];

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      {options.map((option, index) => {
        const displayValue = areAnswersAsSlug
          ? slugifyWithUnderscore(option)
          : option;

        const label = labels.length > 0 ? labels[index] : option;

        const uniqueId = `${question?.fieldName}-option-${index}`;

        return (
          <div key={index} className="mt-2 mb-2 d-flex flex-row">
            <Form.Check
              {...register(question?.fieldName, {
                required: requiredMessage,
              })}
              type="radio"
              id={uniqueId}
              name={question?.fieldName}
              value={displayValue}
              className="me-2"
              checked={watchedValue === displayValue}
              isInvalid={!!errors[question?.fieldName]}
              disabled={readOnly}
            />
            <label htmlFor={uniqueId}>{label}</label>
          </div>
        );
      })}
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
