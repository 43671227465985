import React from "react";
import {ReactComponent as TriangleExclamation} from "assets/Icons/TriangleExclamation.svg";

interface Props {
    label: string;
    className?: string;
}

export const PopoverLine = (props: Props) => {
    return <div className={"d-flex justify-content-end"}>
        <span className={`pe-3 text-dark-blue ${props.className}`}>
            {props.label}
        </span>
        <TriangleExclamation/>
    </div>;
};