import { Button, Row, Stack } from "react-bootstrap";
import ShortestQueueResult from "./Result/ShortestQueueResult";
import styles from "../../../templates/FeedTemplate/FeedTemplate.module.scss";
import React from "react";

const ShortestQueueResults = ({
  detailedFacilitiesData,
  handleResetFilters,
}: ShortestQueueResultsProps) => (
  <Row>
    <Stack gap={3}>
      {detailedFacilitiesData?.length > 0 ? (
        detailedFacilitiesData?.map(
          (detailedFacility: DetailedFacility, idx) => (
            <ShortestQueueResult
              key={idx}
              detailedFacilityData={detailedFacility}
            />
          )
        )
      ) : (
        <Row
          xl={1}
          className={`rounded-2 border border-gray d-flex justify-content-center align-items-center ${styles.rowHeight}`}
        >
          <div className="col-12 d-flex justify-content-center flex-wrap">
            <p className="col-12 text-center fw-600 text-dark-blue">
              Brak placówek spełniających podane parametry lub kryteria filtrów
            </p>
            <Button
              className="bg-primary text-white"
              onClick={handleResetFilters}
            >
              WYCZYŚĆ FILTRY
            </Button>
          </div>
        </Row>
      )}
    </Stack>
  </Row>
);

export default ShortestQueueResults;
