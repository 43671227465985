import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styles from "./WidgetsSettingsElement.module.scss";

const WidgetsSettingsElement = ({
  name,
  isActive,
  position,
  setPosition,
  setActive,
  id,
  hidden,
  forRole
}: WidgetsDataElement) => {
  return (
    <div
      className={`d-flex flex-row justify-content-between p-2 align-items-center m-0 ${styles.WGElement}`}
    >
      <div className="d-flex justify-content-start align-items-center m-0">
        <div className={`form-check form-switch me-2 align-items-center m-0 ${hidden && "d-none"}`}>
          <input
            defaultChecked={isActive}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onClick={() => setActive(!isActive, id)}
          />
        </div>
        <p className="m-0 fs-16 fw-400 text-dark-blue">{name}</p>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <div onClick={() => setPosition("down", id)}>
          <ChevronUp size={16} className=" text-dark-blue me-3" />
        </div>
        <div onClick={() => setPosition("up", id)}>
          <ChevronDown size={16} className=" text-dark-blue" />
        </div>
      </div>
    </div>
  );
};

export default WidgetsSettingsElement;
