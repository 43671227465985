import React, { useCallback, useState, useEffect, useRef } from "react";
import { OffcanvasBody } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setCustomMessage } from "redux/Slice/messages-slice";
import { RootState } from "redux/store/index";
import { toast } from "react-toastify";
import styles from "./MessagesChatView.module.scss";
import moment from "moment";
import { AppDispatchType } from "redux/store";
import { useGetMeQuery } from "redux/services/meApi";
import {
  useCreateTopicMutation,
  useGetTopicQuery,
  useMarkTopicAsReadMutation,
} from "redux/services/topicApi";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import defaultAvatar from "../../../assets/images/userAvatarImage.png";
import aliviaAvatar from "../../../assets/Icons/aliviaA.svg";
import ChatHeader from "components/atoms/ChatViewComponents/ChatHeader/ChatHeader";
import ChatInput from "components/atoms/ChatViewComponents/ChatInput/ChatInput";
import TopicInfo from "components/atoms/ChatViewComponents/TopicInfo/TopicInfo";
import MessageContent from "components/atoms/ChatViewComponents/MessageContent/MessageContent";
import { useTranslation } from "react-i18next";
import { sendMessageAsync } from "redux/Slice/websocket-slice";

export const MessagesChatView = ({
  className,
  isOnMessenger,
}: {
  className?: string;
  isOnMessenger?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatchType>();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const selectedTopic = useSelector((state: RootState) =>
    !isOnMessenger
      ? state.messages.selectedChatTopic
      : state.messages.selectedAdminChatTopic
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [postAttachment] = usePostAttachmentsMutation();
  const [updateTopic] = useMarkTopicAsReadMutation();

  const { t } = useTranslation();

  const chatStateData = useSelector((state: RootState) =>
    !isOnMessenger ? state.messages.chatData : state.messages.adminChatData
  );
  const customMessageData = useSelector(
    (state: RootState) => state.messages.customMessage
  );
  const messagesRef = useRef<HTMLDivElement>(null);
  const { data: userData } = useGetMeQuery({});
  const userId = userData?.data?.id;
  const { handleSubmit, register, watch, reset, setValue } = useForm({
    defaultValues: { message: "" },
  });
  const onMessage = useSelector(
    (state: RootState) => state.webSocket.onMessage
  );
  const userChatData = useSelector(
    (state: RootState) => state.messages.chatUserData
  );
  const [topicId, setTopicId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (chatStateData?.id !== undefined && chatStateData?.id !== topicId) {
      setTopicId(chatStateData?.id);
    }
  }, [chatStateData?.id, topicId]);
  const CLOSED_CONVERSATION_STATUS = 4;
  const [createTopic] = useCreateTopicMutation();
  const { data: messageData, refetch } = useGetTopicQuery(topicId);
  const [chatData, setChatData] = useState<ChatData | null>(null);
  const message = watch("message");
  const onInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target;
    target.style.height = "0";
    target.style.overflowY = "hidden";
    target.style.height = `${target.scrollHeight}px`;
    target.style.overflowY = "auto";
  }, []);

  useEffect(() => {
    if (!chatData && chatStateData) {
      setChatData(chatStateData);
    }
  }, [chatStateData, chatData]);

  const getOtherUser = (
    chatData: ChatData | null,
    userId: number,
    selectedTopic: ChatCase | null,
    userChatData: ChatUser | null,
    isOnMessenger?: boolean
  ): ChatUser => {
    let otherUser: ChatUser = {
      deleted: 0,
      status: 0,
      role: "superadmin",
      id: null,
      name: "Alivia",
      email: "",
      authId: "",
      settings: null,
      createdAt: "",
      updatedAt: "",
    };

    if (selectedTopic?.id === 1) {
      if (chatData) {
        if (chatData?.senderUser?.id === userId) {
          otherUser = chatData?.receiverUser as ChatUser;
        } else if (chatData?.receiverUser?.id === userId) {
          otherUser = chatData?.senderUser as ChatUser;
        }
      } else if (userChatData) {
        otherUser = userChatData;
      }
    } else if (chatData?.receiverUser === null && isOnMessenger) {
      otherUser = chatData?.senderUser as ChatUser;
    }

    return otherUser;
  };

  const otherUser = getOtherUser(
    chatData,
    userId,
    selectedTopic,
    userChatData,
    isOnMessenger
  );

  const updateToRead = useCallback(async () => {
    if (chatData?.id) {
      await updateTopic({
        id: chatData?.id,
        data: {
          senderUser: otherUser?.id,
        },
      });
    }

    // eslint-disable-next-line
  }, [chatData]);

  let avatarSrc: string;
  if (otherUser && otherUser?.id !== null) {
    avatarSrc = otherUser?.avatar?.accessUrl || defaultAvatar;
  } else {
    avatarSrc = aliviaAvatar;
  }

  const handleMessage = useCallback(async () => {
    await refetch();
    setChatData(messageData?.data);
  }, [refetch, setChatData, messageData?.data]);

  useEffect(() => {
    if (onMessage) {
      handleMessage().then(() => {
        const unreadMessageExists = chatData?.topicMessage?.some(
          (message) =>
            (message?.senderUser?.id === otherUser?.id ||
              otherUser?.id === null) &&
            message?.isUnreadMessage === 1
        );

        if (unreadMessageExists) {
          updateToRead();
        }
      });
    }
  }, [onMessage, handleMessage, updateToRead, chatData, otherUser?.id]);

  useEffect(() => {
    if (customMessageData) {
      setValue("message", customMessageData);
    }
  }, [customMessageData, setValue]);

  useEffect(() => {
    const chatId = chatStateData?.id?.toString();
    const storedChatData = retrieveConversationFromSession(chatId);

    if (storedChatData) {
      setChatData(storedChatData);
    } else {
      refetch();
    }

    if (messageData?.data) {
      setChatData(messageData.data);
      storeConversationInSession(messageData.data);
    }
  }, [chatStateData?.id, messageData?.data, refetch]);

  useEffect(() => {
    const element = messagesRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }

    if (chatData?.status === CLOSED_CONVERSATION_STATUS) {
      setIsDisabled(true);
    }
  }, [chatData]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (
      (e.target as HTMLTextAreaElement).tagName.toLowerCase() === "textarea"
    ) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(sendMessage)();
      }
    }
  };

  const uploadFileData = async (): Promise<string[]> => {
    let attachmentArray: string[] = [];

    for (const file of selectedFiles) {
      try {
        const uploadResult = await postAttachment({
          data: file,
          attachmentType: "messages",
        }).unwrap();

        if (uploadResult?.data?.id) {
          attachmentArray?.push(uploadResult?.data?.id);
        } else {
          toast.error(t("toast_messagesChatView_format_error"));
        }
      } catch (error) {
        toast.error(
          `${t("toast_messagesChatView_send_file_error")} ${String(error)}`
        );
      }
    }

    setAttachments((prevAttachments) => [
      ...prevAttachments,
      ...attachmentArray,
    ]);

    return attachmentArray;
  };

  const sendMessage = async (data: SentMessage) => {
    try {
      const newAttachmentIds = await uploadFileData();

      if (newAttachmentIds.length === 0 && selectedFiles.length !== 0) {
        toast.error(t("toast_messagesChatView_send_files_error"));
        return;
      }

      const allAttachments = [...(attachments || []), ...newAttachmentIds];
      let dataToSend;

      if (chatData == null || chatData === undefined) {
        dataToSend = {
          senderUser: !isOnMessenger ? userId : null,
          receiverUser: otherUser?.id,
          caseId: selectedTopic?.id,
          isUnreadMessage: 1,
          isLastMessageAttachment: attachments?.length > 0 ? 1 : 0,
          lastMessage: data?.message,
          topicMessage: {
            message: data?.message,
            senderUser: userId,
            hasAttachments: attachments?.length > 0 ? 1 : 0,
            attachments: allAttachments,
          },
        };

        const newTopic = await createTopic(dataToSend).unwrap();

        setTopicId(newTopic?.data?.id);
        reset({ message: "" });
        dispatch(setCustomMessage(null));
        setSelectedFiles([]);
        refetch();
        setChatData(messageData?.data);
      } else {
        dataToSend = {
          event: "MESSAGE",
          data: {
            message: data?.message,
            hasAttachments: attachments?.length > 0 ? 1 : 0,
            topicId: chatData?.id,
            senderUser: !isOnMessenger ? userId : null,
            attachments: allAttachments,
            lastMessage: "",
          },
        };

        try {
          dispatch(sendMessageAsync(dataToSend));
          reset({ message: "" });
          dispatch(setCustomMessage(null));
          refetch();
          setSelectedFiles([]);
          setAttachments([]);
          setChatData(messageData?.data);
        } catch (error) {
          if (error instanceof Error) {
            toast.error(
              `${t(
                "toast_messagesChatView_send_message_error"
              )} ${error.toString()}`
            );
          }
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`${t("toast_messagesChatView_error")} ${error.message}`);
      } else {
        toast.error(t("toast_messagesChatView_undefined_error"));
      }
    }
  };

  const storeConversationInSession = (chatData: ChatData | null) => {
    if (chatData?.id) {
      sessionStorage.setItem(`chat-${chatData?.id}`, JSON.stringify(chatData));
    }
  };

  const retrieveConversationFromSession = (
    chatId?: string
  ): ChatData | null => {
    if (chatId) {
      const storedChat = sessionStorage.getItem(`chat-${chatId}`);
      return storedChat ? JSON.parse(storedChat) : null;
    }
    return null;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const supportedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/heic",
      ];

      const filteredFiles = fileArray.filter((file) => {
        if (!supportedTypes.includes(file.type)) {
          toast.error(
            `Plik musi mieć format PDF, JPG, PNG, DOC, DOCX lub HEIC`
          );
          return false;
        }
        return true;
      });

      setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  const removeFile = (indexToRemove: number) => {
    const newSelectedFiles = selectedFiles.filter(
      (_file, index) => index !== indexToRemove
    );
    setSelectedFiles(newSelectedFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const formatDate = (timestamp: string) => {
    return moment(timestamp).format("DD.MM.YYYY, HH:mm");
  };

  const isFirstMessageOfDay = (index: number, messages: TopicMessage[]) => {
    if (index === 0) return true;

    const prevMessageDate = moment(messages[index - 1].createdAt).format(
      "YYYY-MM-DD"
    );
    const currentMessageDate = moment(messages[index].createdAt).format(
      "YYYY-MM-DD"
    );

    return prevMessageDate !== currentMessageDate;
  };

  useEffect(() => {
    chatData?.status !== CLOSED_CONVERSATION_STATUS && setIsDisabled(false);
  }, [chatData]);

  return (
    <>
      <ChatHeader
        avatarSrc={avatarSrc}
        otherUser={otherUser}
        isOnMessenger={isOnMessenger}
      />
      <OffcanvasBody
        className={`p-0 m-0 d-flex flex-grow-1 flex-column justify-content-center col-12 ${
          styles.chatBody
        } ${isOnMessenger && styles.chatBodyMessenger} ${
          isOnMessenger && className
        }`}
      >
        {selectedTopic &&
        selectedTopic?.id !== 1 &&
        !(chatData?.topicMessage?.length ?? 0) ? (
          <TopicInfo selectedTopic={selectedTopic} />
        ) : (
          <div
            ref={messagesRef}
            className={`m-0 py-4 px-3 p-md-4 d-flex flex-column text-dark ${styles.messages}`}
          >
            {chatData?.topicMessage?.map((message, index) => (
              <React.Fragment key={index}>
                {isFirstMessageOfDay(index, chatData?.topicMessage) && (
                  <div
                    className={`${styles.readIndicator} text-center w-100 col-12`}
                  >
                    <p className={`${styles.readIndicatorText} p-0 m-0`}>
                      {formatDate(message.createdAt)}
                    </p>
                    <span className={`${styles.readIndicatorLine}`} />
                  </div>
                )}
                <MessageContent
                  message={message}
                  isSentMessage={
                    !isOnMessenger
                      ? message?.senderUser?.id === userId
                      : !message?.senderUser?.id
                      ? true
                      : false
                  }
                  avatarSrc={
                    isOnMessenger && !message?.senderUser?.id
                      ? aliviaAvatar
                      : avatarSrc
                  }
                  isOnMessenger={isOnMessenger}
                />
              </React.Fragment>
            )) ?? (
              <div
                className={`border d-flex justify-content-center align-items-center ${styles.noHistory}`}
              >
                <p className="text-grey-2">Brak historii wiadomości</p>
              </div>
            )}
          </div>
        )}
        {isDisabled && (
          <div className="px-3 mb-4 px-md-4">
            <p className="fs-16 fw-700 lh-130 text-grey-2 p-5 border d-flex justify-content-center align-items-center text-center">
              Administrator zakończył wątek
            </p>
          </div>
        )}

        <ChatInput
          sendMessage={sendMessage}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          selectedFiles={selectedFiles}
          removeFile={removeFile}
          handleKeyPress={handleKeyPress}
          onInput={onInput}
          message={message}
          register={register}
          handleSubmit={handleSubmit}
          isDisabled={isDisabled}
        />
      </OffcanvasBody>
    </>
  );
};
