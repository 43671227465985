import { handleNewField, handleNewFieldLanguage } from "Helpers/helpers";
import Select from "react-select";
import "./SingleSelect.scss";

const SingleSelect = ({
  data,
  value,
  menuIsOpen,
  setValue,
  name,
  placeholder,
  relativeoptions,
  width,
  isNotClearable,
  setHandleReset,
  language,
}: any) => {
  const handleCategoryInputChange = (newValue: any) => {
    setHandleReset && setHandleReset(true);
    setValue(name, newValue?.value || "");
    const languageId = data?.find((c: any) => c.id === newValue?.value)
      ?.language?.id;
    language && setValue(language, languageId || "");
  };

  return (
    <div style={{ width: width }}>
      <Select
        options={
          data && language ? handleNewFieldLanguage(data) : handleNewField(data)
        }
        components={{
          ...(menuIsOpen && { DropdownIndicator: () => null }),
          ...(menuIsOpen && { IndicatorSeparator: () => null }),
        }}
        defaultValue={value}
        onChange={handleCategoryInputChange}
        classNamePrefix={relativeoptions ? "relativeSelect" : "select"}
        noOptionsMessage={() => "Brak danych"}
        placeholder={placeholder || "Wybierz"}
        isClearable={isNotClearable ? false : true}
        menuIsOpen={menuIsOpen}
      />
    </div>
  );
};
export default SingleSelect;
