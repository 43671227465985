import { Container, Row, Spinner } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { OncocollectionsEntity } from "../../molecules/OncocollectionsEntity/OncocollectionsEntity";
import styles from "./ObservedOncocollections.module.scss";
import { useGetMeQuery } from "redux/services/meApi";
import { useGetSFContactQuery } from "redux/services/salesforceApi";

interface Props {
  name: string;
}

type ContactData = {
  name: string;
  value: string | number;
  max: string | number;
  link: string;
};

export const ObservedOncocollections = ({ name }: Props) => {
  const { t } = useTranslation();
  const companiesIsLoading = false;

  const { data: meData } = useGetMeQuery({});
  const { data: sfContactData } = useGetSFContactQuery({});

  const isEmptyObject = (obj: Record<string, any> | null | undefined) =>
    !obj || Object.keys(obj).length === 0;

  const contactData = isEmptyObject(sfContactData?.data)
    ? meData?.data?.settings
    : sfContactData?.data;

  const processedContactData = contactData && {
    name: contactData?.Tytu_zbi_rki_ostatnio_wspartej__c,
    value:
      contactData?.Dost_pne_rodki_ostatnio_wspartej__c +
      -contactData?.Wyplaty_ostatnio_wspartej__c,
    max: contactData?.Cel_zbi_rki_ostatnio_wspartej__c,
    link: contactData?.Link_do_zbi_rki_ostatnio_wspartej__c,
  };

  const hasValidData = (data: ContactData) => {
    return data && data.name && data.value && data.max && data.link;
  };

  return (
    <Container fluid={true} className={`p-4 containerWithShadow `}>
      <Row>
        <p
          className={`fs-22 fw-600 lh-130 mb-4 text-dark-blue d-md-flex justify-content-between align-items-center ${styles.title}`}
        >
          {name}
        </p>
        {companiesIsLoading ? (
          <Container className={"d-flex justify-content-center"}>
            <Spinner animation="border" variant="primary" />
          </Container>
        ) : hasValidData(processedContactData) ? (
          <div className={"d-flex flex-column gap-4"}>
            <OncocollectionsEntity data={processedContactData} />
          </div>
        ) : (
          <div className={"mt-3"}>
            <p className={"text-dark-blue fs-16 fw-normal"}>
              {t("widget_observed_oncocollections_p")}
            </p>
          </div>
        )}
      </Row>
    </Container>
  );
};
