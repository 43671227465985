import { apiSlice } from "./apiSlice";
import { polls } from "endpoints/endpoints";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Polls"],
});

export const pollsApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    postPoll: builder.mutation({
      query: ({ data }) => ({
        url: `${polls}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Polls"],
    }),

    getPolls: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        title,
        verificationType,
        language,
        userRole,
      }) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(title && { title }),
          ...(verificationType && { verificationType }),
          ...(language && { language }),
          ...(userRole && { userRole }),
        });

        return {
          url: `${polls}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Polls"],
    }),

    getPoll: builder.query({
      query: (id) => {
        return {
          url: `${polls}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Polls"],
    }),

    updatePoll: builder.mutation({
      query: ({ id, data }) => ({
        url: `${polls}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Polls"],
    }),

    updateWholePoll: builder.mutation({
      query: ({ id, data }) => ({
        url: `${polls}/${id}/full`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Polls"],
    }),
  }),
});

export const {
  usePostPollMutation,
  useGetPollsQuery,
  useGetPollQuery,
  useUpdatePollMutation,
  useUpdateWholePollMutation,
} = pollsApi;
