import { Col, Container, Stack } from "react-bootstrap";
import styles from "./ViewNotAuthorized.module.scss";
import React from "react";

interface Props {
  value: string;
  classValue?: string;
  info: string;
  classInfo?: string;
}

export const ViewNotAuthorized = (props: Props) => {
  return (
    <Container fluid={true} className="bg-light">
      <section className={styles.section}>
        <Stack>
          <Col className={`${styles.wrapper}`}>
            <h3
              className={
                props.classValue
                  ? props.classValue
                  : `text-center text-dark-blue mb-5`
              }
            >
              {props.value}
            </h3>
            <h6
              className={
                props.classInfo ? props.classInfo : `text-center text-dark-blue`
              }
            >
              {props.info}
            </h6>
          </Col>
        </Stack>
      </section>
    </Container>
  );
};
