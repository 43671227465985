import React from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

const SearchForm = ({ setCurrPage }: any) => {
  const { register, handleSubmit } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <form
      className="d-flex gap-3"
      onSubmit={handleSubmit((data: any) => {
        if (data.searchMedia && searchParams.get("page")) {
          searchParams.set("page", "1");
          setCurrPage(1);
        }
        searchParams.set("nameLike", data.searchMedia);
        setSearchParams(searchParams);
      })}
    >
      <div className="col-auto">
        <input
          type="text"
          className="form-control"
          {...register("searchMedia")}
        />
      </div>
      <div className="col-auto">
        <Button variant="outline-primary" type="submit" value="Submit">
          Szukaj
        </Button>
      </div>
    </form>
  );
};

export default SearchForm;
