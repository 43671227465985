import { emails } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Attachments", "Messages"],
});

interface EmailData {
  emailKey: string;
  email: string;
  sendToAlivia: boolean;
  languageCode: string;
  replaceArray: string[];
}

export const messagesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    sendSMS: builder.query({
      query: ({ messages, phone }: { messages: string; phone: string }) => {
        const params = new URLSearchParams({
          ...(messages && { messages }),
          ...(phone && { phone }),
        });

        return {
          url: `${emails}/sendSMS?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Messages"],
    }),

    sendMail: builder.query({
      query: ({
        body,
        subject,
        to,
      }: {
        body: string;
        subject: string;
        to: string;
      }) => {
        const params = new URLSearchParams({
          ...(body && { body }),
          ...(subject && { subject }),
          ...(to && { to }),
        });

        return {
          url: `${emails}/sendMail?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Messages"],
    }),

    sendMailTemplate: builder.mutation({
      query: (payload: EmailData) => {
        return {
          url: `${emails}/send-mail-template`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        };
      },
      invalidatesTags: ["Messages"],
    }),
  }),
});

export const {
  useSendSMSQuery,
  useSendMailQuery,
  useSendMailTemplateMutation,
} = messagesApi;
