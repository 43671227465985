import { ReactNode } from "react";
import styles from "./NavSet.module.scss";

interface NavSetProps {
  title: string;
  children: ReactNode;
}

const NavSet = ({ title, children }: NavSetProps) => {
  return (
    <>
      <span className={`py-2 text-dark-blue mx-4 ${styles.navHeader}`}>
        {title}
      </span>
      {children}
    </>
  );
};

export default NavSet;
