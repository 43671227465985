import {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  InputProps,
} from "react-select";
import React, { ReactNode } from "react";
import Spinner from "react-bootstrap/Spinner";
import { ReactComponent as Arrow } from "../assets/Icons/Arrow.svg";
import { ReactComponent as Search } from "../assets/Icons/Search.svg";
import style from "./reactSelectCustomStyles.module.scss";

export const customStyles = (isInvalid: boolean, isLabelBlue = true) => {
  return {
    control: (
      styles: CSSObjectWithLabel,
      { isDisabled, isFocused }: any,
    ): CSSObjectWithLabel => ({
      ...styles,
      backgroundColor: isDisabled ? "#e9ecef" : "#ffffff",
      boxShadow: "none",
      borderColor: isInvalid
        ? "#ed2369"
        : isDisabled
        ? "#ced4da"
        : isFocused
        ? "#11C0F2"
        : styles.borderColor,
      "&:hover": {
        borderColor: isFocused ? "#11C0F2" : styles.borderColor,
      },
      color: isDisabled ? "#6C757D" : "#003b50",
    }),
    singleValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#003b50",
    }),
    input: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#003b50",
    }),
    menu: (styles: CSSObjectWithLabel) => ({
      ...styles,
      zIndex: 20,
      border: "#ADB5BD solid 1px",
      boxShadow: "none",
      borderRadius: 4,
    }),
    menuList: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: 0,
      borderRadius: 4,
      "::-webkit-scrollbar": {
        width: "0.4375rem",
        marginRight: "0.625rem",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#11c0f2",
        borderRadius: "4px",
      },
    }),
    option: (styles: CSSObjectWithLabel, { isDisabled, isSelected }: any) => ({
      ...styles,
      color: isSelected ? "#fff" : "#003b50",
      fontFamily: "Poppins, sans-serif",
      backgroundColor: isSelected ? "#11C0F2" : "#fff",
      cursor: isDisabled ? "not-allowed" : "pointer",
      border: "none",
      zIndex: "999999",
      "&:hover": {
        backgroundColor: "rgba(17,192,242,0.8)",
        color: "#fff",
      },
    }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: "none",
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel, { selectProps }: any) => ({
      ...styles,
      display: selectProps.isDisabled ? "none" : styles.display,
    }),
    placeholder: (styles: CSSObjectWithLabel, { isDisabled }: any) => ({
      ...styles,
      color: isInvalid ? "#dc3545" : isDisabled ? "#6C757D" : "#003b50",
      fontSize: 16,
      fontFamily: "Poppins, sans-serif",
    }),
    multiValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      backgroundColor: isLabelBlue ? "#11C0F2" : "#e9ecef",
      color: isLabelBlue ? "white" : "#003b50",
    }),
    multiValueLabel: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: isLabelBlue ? "white" : "#003b50",
    }),
    multiValueRemove: (styles: CSSObjectWithLabel) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        background: isLabelBlue ? "#11C0F2" : "#e9ecef",
      },
    }),
  };
};

interface ExtendedDropdownIndicatorProps
  extends DropdownIndicatorProps<any, boolean> {
  children?: ReactNode;
  isDisabled: boolean;
  [key: string]: any;
}

export const DropdownIndicator = (props: ExtendedDropdownIndicatorProps) => {
  const isDisabled = props.isDisabled ?? false;
  return (
    <components.DropdownIndicator {...props}>
      <Arrow
        style={{
          transform: "rotate(90deg)",
          color: isDisabled ? "#6C757D" : "#003b50",
          marginRight: 12,
        }}
      />
    </components.DropdownIndicator>
  );
};

export const SearchDropdownIndicator = (
  props: ExtendedDropdownIndicatorProps,
) => {
  const isDisabled = props.isDisabled ?? false;
  return (
    <components.DropdownIndicator {...props}>
      <Search
        style={{
          stroke: isDisabled ? "#6C757D" : "#003b50",
          paddingRight: 0,
        }}
      />
    </components.DropdownIndicator>
  );
};

type GroupHeadingProps = {
  children: ReactNode;
};

export const GroupHeading: React.FC<GroupHeadingProps> = ({ children }) => {
  return (
    <div className="px-2 py-2 mx-1 text-grey-2 fw-600 lh-130 fs-12">
      {children}
    </div>
  );
};

type CustomGroupProps = {
  children: ReactNode;
  label: string;
  onShowMore: (label: string) => void;
  optionsCount: number;
  isLoadingMore: boolean;
};

export const CustomGroup = (props: CustomGroupProps) => {
  const { isLoadingMore, children, label, onShowMore, optionsCount } = props;

  const handleShowMore = () => {
    onShowMore(label);
  };

  return (
    <div>
      <GroupHeading {...props}>
        {label} ({optionsCount})
      </GroupHeading>
      {children}
      {optionsCount >= 5 && (
        <div className={style.divInOptions}>
          <span onClick={handleShowMore} className={style.buttonInOptions}>
            {!isLoadingMore && <p>Pokaż więcej</p>}
            {isLoadingMore && (
              <Spinner animation="border" variant="primary" size="sm" />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

interface EnhancedInputProps extends InputProps {
  innerProps: {
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
}

export const CustomInput: React.FC<EnhancedInputProps> = (
  props: EnhancedInputProps,
) => {
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.selectionStart = e.target.value.length;
    e.target.selectionEnd = e.target.value.length;
    if (props.onFocus) {
      props.onFocus(e);
    }
  };
  return <components.Input {...props} onFocus={handleFocus} />;
};
