import DetailItem from "components/atoms/DetailItem/DetailItem";
import TextArea from "components/atoms/TextArea/TextArea";
import TextInput from "components/atoms/TextInput/TextInput";
import ComponentWithDescription from "components/molecules/ComponentWithDescription/ComponentWithDescription";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styles from "./ImageModal.module.scss";

const ImageModal = ({
  show,
  setShow,
  data,
  setContent,
  setShowParentModal,
  setSelectedImage,
}: ImageModalChildrenProps) => {
  const { register, watch } = useForm({
    defaultValues: {
      alt: data?.alt || "",
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Szczegóły załączonego pliku
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gap-3">
          <div className="col-12 col-sm-8">
            <img
              className={styles.modalImage}
              src={data?.accessUrl}
              alt={data?.alt}
            />
          </div>

          <div className={`${styles.modalMenu} col-12 col-sm-4 `}>
            <DetailItem
              detailHeader="Nazwa pliku"
              detailValue={data?.filename}
            />
            <hr />

            <form className="d-flex flex-column gap-2">
              <ComponentWithDescription
                descFs="fs-12"
                desc="Proszę opisać cel obrazka. Proszę pozostawić puste, jeśli jest on czysto dekoracyjny."
              >
                <TextArea
                  className="fs-13 "
                  label="Tekst alternatywny"
                  row={2}
                  register={register("alt")}
                />
              </ComponentWithDescription>
              <TextInput className="fs-13 flex-column " label="Tytuł" />
              <TextArea className="fs-13 " label="Podpis" row={2} />
              <TextArea className="fs-13 " label="Opis" row={2} />

              <div className="col-auto">
                {setContent ? (
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShow(false);

                      setShowParentModal && setShowParentModal(false);
                      setSelectedImage && setSelectedImage(data);
                      setContent(
                        `<img
                          src=${data?.accessUrl}
                          alt=${watch("alt")}
            />`
                      );
                    }}
                  >
                    Dodaj obraz
                  </Button>
                ) : (
                  <Button
                    className="btn-outline-blue"
                    type="submit"
                    value="Submit"
                  >
                    Zapisz
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
