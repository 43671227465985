import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap";
import TooltipIcon from "../../../../assets/Icons/Tooltip.svg";
import { Form } from "react-bootstrap";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function TextInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const {
    register,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
  } = useFormContext();

  const validationMeta = question?.meta.find(
    (meta) => meta.key === "validation"
  );

  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const minLength = validationMeta
    ? JSON.parse(validationMeta.value).minLength
    : 0;
  const maxLength = validationMeta
    ? JSON.parse(validationMeta.value).maxLength
    : 0;
  const minLengthMessage = validationMeta
    ? JSON.parse(validationMeta.value).minLengthMessage
    : "";
  const maxLengthMessage = validationMeta
    ? JSON.parse(validationMeta.value).maxLengthMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const tooltip =
    question?.meta.find((meta) => meta.key === "html_tooltip")?.value || "";

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    </Tooltip>
  );

  useEffect(() => {
    const value = getValues(question?.fieldName);
    setValue(question?.fieldName, value, { shouldValidate: true });
  }, [getValues, setValue, question?.fieldName]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      <div className="d-flex align-items-center gap-2 mb-2">
        {question?.question && (
          <Form.Label className="fw-600 fs-16 mb-0">
            {question?.question}
            {validationMeta && JSON.parse(validationMeta.value).isRequired && (
              <span className="text-danger">*</span>
            )}
          </Form.Label>
        )}
        {tooltip && (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <img src={TooltipIcon} alt="tooltip" />
          </OverlayTrigger>
        )}
      </div>
      <Form.Control
        type="text"
        defaultValue={getValues(question?.fieldName)}
        {...register(question?.fieldName, {
          required: requiredMessage,
          minLength: minLength
            ? { value: minLength, message: minLengthMessage }
            : undefined,
          maxLength: maxLength
            ? { value: maxLength, message: maxLengthMessage }
            : undefined,
        })}
        isInvalid={isSubmitted && !!errors[question?.fieldName]}
        disabled={readOnly}
      />
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
