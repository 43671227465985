import RolesDisplay from "components/atoms/RolesDisplay/RolesDisplay";
import { Modal } from "react-bootstrap";

const RolesModal = ({ show, setShow, setRole }: RolesModalProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 d-flex flex-row justify-content-between px-4 pb-0 pt-4"
        >
          <p className="fw-600 fs-22 text-dark-blue m-0">Dodaj nową rolę</p>
        </Modal.Header>
        <Modal.Body>
          <div className="container p-0 mt-4 d-flex flex-column gap-4">
            <RolesDisplay setRole={setRole} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RolesModal;
