import { ServicesForm } from "../ServiceForm/ServicesForm";
import { Col, Container, Row } from "react-bootstrap";
import SortButton from "../SortButton/SortButton";
import styles from "../../templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate.module.scss";
import { LoyaltyProgramServiceCard } from "../../molecules/LoyaltyProgramServiceCard/LoyaltyProgramServiceCard";
import CustomPagination from "../CustomPagination/CustomPagination";
import { useEffect, useState, RefObject } from "react";
import { LoyaltyProgramServiceDataInterface } from "../../templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { ReactComponent as Tiles } from "../../../assets/Icons/Show/Tiles.svg";
import { ReactComponent as List } from "../../../assets/Icons/Show/List.svg";
import { useSearchParams } from "react-router-dom";
import { LoyaltyProgramServiceDataRes } from "./types";

interface Props {
  data: LoyaltyProgramServiceDataRes;
  label: string;
  limit: number;
  currPage: number;
  sort: string | null;
  handlePageChange: (page: number) => void;
  newsSortHandler: () => void;
  popularSortHandler: () => void;
  searchRef?: RefObject<HTMLDivElement>;
}

const getWindowWidth = () => window.innerWidth;

export const LoyaltyProgramServiceSearch = ({
  data,
  label,
  limit,
  currPage,
  sort,
  handlePageChange,
  newsSortHandler,
  popularSortHandler,
  searchRef,
}: Props) => {
  const [isTiles, setIsTiles] = useState(true);
  const [searchParams] = useSearchParams();
  const sortBy = searchParams.get("sortBy")?.split(",")[0];

  const handleResize = () => {
    if (getWindowWidth() <= 992) {
      setIsTiles(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePage = (page: number) => {
    handlePageChange(page);
  };

  return (
    <Container fluid={true} className="" ref={searchRef}>
      <ServicesForm nameLabel={label} isService={true} />
      <Col className=" mb-4 mx-0 g-4 mb-4 p-0 m-0 d-flex flex-column flex-lg-row justify-content-between">
        <div
          className={
            "d-flex flex-row justify-content-between justify-content-lg-between"
          }
        >
          <SortButton
            label="Najnowsze"
            onClick={newsSortHandler}
            isArrowRotated={sort === "news,ASC"}
            className={`pt-3 pb-0 pe-3 fs-16 ${
              sortBy === "news" ? "text-dark-blue" : ""
            }`}
          />
          <SortButton
            label="Najpopularniejsze"
            onClick={popularSortHandler}
            isArrowRotated={sort === "popular,ASC"}
            className={`pt-3 pb-0 pe-3 fs-16 ${
              sortBy === "popular" ? "text-dark-blue" : ""
            }`}
          />
        </div>
        <div
          className={
            "d-none d-lg-flex justify-content-start justify-content-lg-end align-items-center gap-2"
          }
        >
          <span
            className={"h-100 d-flex align-items-center text-dark-blue pe-2"}
          >
            Wyświetlanie
          </span>
          <div
            className={`cursor-pointer d-flex align-items-center justify-content-center ${
              styles.button
            } ${isTiles ? styles.active : ""}`}
            onClick={() => setIsTiles(true)}
          >
            <Tiles className={isTiles ? styles.active : ""} />
          </div>
          <div
            className={`cursor-pointer d-flex align-items-center justify-content-center ${
              styles.button
            } ${!isTiles ? styles.active : ""}`}
            onClick={() => setIsTiles(false)}
          >
            <List className={!isTiles ? styles.active : ""} />
          </div>
        </div>
        {/*<SortButton*/}
        {/*    label="Do końca"*/}
        {/*    onClick={endSortHandler}*/}
        {/*    isArrowRotated={sort === "end,ASC"}*/}
        {/*    className={"px-2"}*/}
        {/*/>*/}
      </Col>
      {data?.data.length !== 0 ? (
        <Row xs={1} md={isTiles ? 3 : 1} className="g-4 mb-4">
          {data?.data.map((value: LoyaltyProgramServiceDataInterface) => (
            <Col className="d-flex " key={value.id}>
              <LoyaltyProgramServiceCard data={value} isTiles={isTiles} />
            </Col>
          ))}
        </Row>
      ) : (
        <Row>
          <span className={"fw-bolder fs-19 text-center text-dark-blue"}>
            Brak wyników, zmień kryteria wyszukiwania
          </span>
        </Row>
      )}

      <Row className={"d-flex justify-content-end"}>
        <Container fluid={true}>
          <Row>
            <Col className="d-flex justify-content-end">
              <CustomPagination
                totalCount={(!!data && data?.meta?.totalResults) || 0}
                pageSize={limit}
                currentPage={currPage}
                onPageChange={handlePage}
                paginationClassName="text-nowrap"
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};
