import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col } from "react-bootstrap";
import { DoctorScientificDegreeForm } from "components/molecules/DoctorScientificDegreeForm/DoctorScientificDegreeForm";

interface DoctorScientificDegreeCreateModalProps {
  show: boolean;
  handleClose: () => void;
  isFirst: boolean;
  data: DoctorScientificDegreeData;
}

interface DoctorScientificDegreeData {
  id: number;
  firstName: string;
  lastName: string;
  degree: DoctorScientificDegree;
}

interface DoctorScientificDegree {
  id: number;
  name: string;
  shortcut: string;
}

export const DoctorScientificDegreeCreateModal: React.FC<
  DoctorScientificDegreeCreateModalProps
> = ({ show, handleClose, data }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size={"lg"}
      fullscreen={"sm-down"}
    >
      <Modal.Header closeButton className={"border-bottom-0 p-4"}>
        <Col>
          <Modal.Title className="fw-bold text-dark-blue">
            <span>{"Edycja danych"}</span>
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body className={"p-4"}>
        <DoctorScientificDegreeForm data={data} handleClose={handleClose} />
      </Modal.Body>
    </Modal>
  );
};
