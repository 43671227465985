import { Company } from "components/molecules/LoyaltyProgramServiceArticle/types";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import Breadcrumb from "components/molecules/Breadcrumb/Breadcrumb";
import { Row, Col, Button } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import styles from "../UserViewTableRow/UserViewTableRow.module.scss";
import { useState, useEffect } from "react";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import { usePatchCompanyMutation } from "redux/services/companyApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";
import { Social } from "components/molecules/ListOfSocialPage/ListOfSocialPage";

interface Props {
  data: Company;
}

const CompanyViewTableRow = ({ data }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [socials, setSocials] = useState<Social | null>(null);

  const [patchCompany] = usePatchCompanyMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (data?.socialAddresses) {
      setSocials(JSON.parse(data?.socialAddresses));
    }
  }, [data?.socialAddresses]);

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.COMPANIES}/${data?.id}/${NAV_PATH.COMPANY}/edit`,
    },
    {
      name: data?.deleted === 0 ? "Usuń firmę" : "Przywróć firmę",
      action: () => setShowModalDelete(true),
    },
  ];

  const modalDeleteHandler = async () => {
    const hasBeenDelete = await patchCompany({
      id: data?.id,
      data: {
        deleted: data?.deleted === 0 ? 1 : 0,
      },
    });

    if ("data" in hasBeenDelete) {
      toast.success(t("toast_companyBasicInformationForm_update_success"));
    } else if ("error" in hasBeenDelete) {
      toast.error(t("toast_companyBasicInformationForm_error"));
    }

    setShowModalDelete(false);
  };

  return (
    <>
      <tr
        aria-controls="example-collapse-text"
        className={`  ${styles.mainTr}`}
      >
        <td
          className={`container-fluid ${data?.deleted && styles.bg} ${
            styles.mainTd
          }`}
          colSpan={4}
        >
          <Row className="pb-2 bg-transpatent">
            <Col xs={2} className="text-dark-blue text-break">
              {data?.id}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {data?.name}
            </Col>
            <Col xs={4} className="text-dark-blue text-break">
              {data?.nipProvider}
            </Col>
            <Col xs={2} className="text-dark-blue text-break">
              {data?.status ? "Potwierdzone" : "Niepotwierdzone"}
            </Col>

            <Col className="d-flex justify-content-end align-content-start gap-3 text-dark-blue">
              <Button
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                  isActive
                    ? "text-light bg-dark"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prevState) => !prevState)}
              >
                <Arrow
                  className={isActive ? styles.arrowUp : styles.arrowDown}
                />
              </Button>
            </Col>
          </Row>
          {isActive && (
            <Row className="mb-2">
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Nazwa: </span>
                <span className={"fw-semibold"}>{data?.name}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Pełna nazwa: </span>
                <span className={"fw-semibold"}>{data?.fullName}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>NIP: </span>
                <span className={"fw-semibold"}>{data?.nipProvider}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Strona internetowa: </span>
                <span className={"fw-semibold"}>
                  {socials?.page ?? "brak strony internetowej"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Facebook: </span>
                <span className={"fw-semibold"}>
                  {socials?.facebook ?? "brak Facebook'a"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Instagram: </span>
                <span className={"fw-semibold"}>
                  {socials?.instagram ?? "brak Instagram'a"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Lokalizacje: </span>
                {data?.companyDepartment?.map(
                  ({ city, street, zipCode, phoneNumbers, emailAddresses }) => (
                    <span
                      key={`${city?.name}-${street}-${zipCode}`}
                      className={"fw-semibold"}
                    >
                      {`${zipCode} ${
                        city?.name
                      }, ${street}; nr telefonu: ${JSON.parse(
                        phoneNumbers
                      )}; email: ${
                        (JSON.parse(emailAddresses) as string[])
                          .filter((email) => email !== "")
                          .join(", ") || "brak"
                      }`}
                    </span>
                  )
                )}
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Opis firmy: </span>
                <span className={"fw-semibold"}>
                  {data?.description ?? "Brak opisu"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Utworzono: </span>
                <span className={"fw-semibold"}>
                  {dateEmbellishment(data.createdAt, "-")}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Ostatnia zmiana: </span>
                {data.updatedAt ? (
                  <span className={"fw-semibold"}>
                    {dateEmbellishment(data.updatedAt, "-")}
                  </span>
                ) : (
                  <span className={"fw-semibold"}>-</span>
                )}
              </Col>
            </Row>
          )}
          <Row className={`bg-transparent ${styles.hoverRow}`}>
            <Col>
              <Breadcrumb data={breadcrumbData} />
            </Col>
          </Row>
        </td>
      </tr>
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz ${
          data?.deleted === 0 ? "Usunąć" : "Przywrócić"
        } firmę?`}
        smallText={`firma: ${data.name}, NIP: ${data.nipProvider}`}
        type="confirm"
        show={showModalDelete}
        setShow={() => setShowModalDelete(false)}
        confirmAction={() => modalDeleteHandler()}
      />
    </>
  );
};

export default CompanyViewTableRow;
