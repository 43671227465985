import React from "react";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import styles from "./ListAddedAndDeleted.module.scss";
import {
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";

interface Props {
  handleContactList: (
    contactName: "mail" | "number",
    contactList: string[],
  ) => void;
  contactName: "mail" | "number";
  buttonLabel: string;
  isDisabled: boolean;
  required: boolean;
  pattern: {
    value: string;
    message: string;
  };
  errors: FieldErrors<CompanyDepartmentFormData>;
  name: "phNumbers" | "emAddresses";
  fields: FieldArrayWithId<
    CompanyDepartmentFormData,
    "phNumbers" | "emAddresses",
    "id"
  >[];
  append: UseFieldArrayAppend<CompanyDepartmentFormData, any>;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<CompanyDepartmentFormData>;
}

export const ListComponent = (props: Props) => {
  return (
    <>
      {props.fields.map(
        (
          field: FieldArrayWithId<
            CompanyDepartmentFormData,
            "phNumbers" | "emAddresses",
            "id"
          >,
          index: number,
        ) => (
          <div
            key={`${props.contactName}-${index}`}
            className={"d-flex flex-column  pb-3"}
          >
            <div className={"d-flex gap-3"}>
              <Form.Control
                key={field.id}
                disabled={props.isDisabled}
                className={"text-dark-blue"}
                type={"text"}
                {...props.register(`${props.name}.${index}.value` as const, {
                  required: { value: props.required, message: "Pole wymagane" },
                  pattern: {
                    value: RegExp(props.pattern.value),
                    message: props.pattern.message,
                  },
                })}
                isInvalid={!!props.errors?.[props.name]?.[index]?.value}
              />
              {index > 0 && !props.isDisabled && (
                <Button
                  className={`p-0 m-0 ${styles.button}`}
                  variant={"primary"}
                  onClick={() => props.remove(index)}
                >
                  <TrashBlank />
                </Button>
              )}
            </div>

            {!!props.errors?.[props.name]?.[index]?.value?.message && (
              <div className={"col-12 text-danger"}>
                {props.errors?.[props.name]?.[index]?.value?.message || ""}
              </div>
            )}
          </div>
        ),
      )}

      {!props.isDisabled && (
        <Button
          variant="outline-primary"
          className={"w-100 d-flex justify-content-center align-items-center"}
          onClick={() => props.append({ value: "" })}
        >
          <PlusIcon />
          <span className={"fs-16 ps-2"}>{props.buttonLabel}</span>
        </Button>
      )}
    </>
  );
};
