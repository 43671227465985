import { usePatchCompanyMutation } from "../../../redux/services/companyApi";
import { SubmitHandler, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
}

export const CompanyInfo = ({
  data,
  handleActive,
  handleFirst,
  isActive,
  isFirst,
  handleCondition,
}: Props) => {
  const [isAccept, setIsAccept] = useState<boolean>(false);

  const [patchCompany, { isSuccess, error }] = usePatchCompanyMutation();

  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<{ description: string }>({
    defaultValues: {
      description: data?.description,
    },
  });

  useEffect(() => {
    if (!isActive) {
      setValue("description", data?.description);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("toast_companyInfo_success"));
    } else if (error) {
      toast.error(t("toast_companyInfo_error"));
    }
    // eslint-disable-next-line
  }, [error, isSuccess]);

  const submitHandler: SubmitHandler<{
    description: string;
  }> = async (submitData) => {
    if (isAccept && submitData?.description !== null) {
      await patchCompany({ id: data?.id, data: submitData });
      setIsAccept(false);
      handleActive(false);
    } else {
      toast.error(t("toast_companyInfo_empty_error"));
    }
  };

  return isActive ? (
    <Form
      className={"d-flex flex-column w-100"}
      onSubmit={handleSubmit(submitHandler)}
    >
      <ComponentWithDescription
        className="my-2 w-100"
        desc="Maks 5000 znaków"
        label={"Opis firmy"}
        labelFs={`fs-16 mb-1 ${
          errors.description ? "text-danger" : "text-dark-blue"
        }`}
      >
        <Form.Control
          as="textarea"
          className={"text-dark-blue"}
          rows={3}
          {...register("description", {
            required: { value: true, message: "Pole wymagane" },
            maxLength: { value: 5000, message: "Maksymalnie 5000 znaków" },
          })}
          isInvalid={Boolean(errors.description)}
        />
        {errors.description && (
          <Form.Control.Feedback type={"invalid"}>
            {errors.description.message}
          </Form.Control.Feedback>
        )}
      </ComponentWithDescription>
      <div className="d-flex gap-2 mt-4 justify-content-end">
        <Button
          className="btn-outline-dark-blue"
          onClick={() => {
            handleActive(false);
            if (data?.description === null) {
              handleFirst(true);
            }
            setIsAccept(false);
          }}
        >
          ANULUJ
        </Button>
        <Button
          type="submit"
          value="Submit"
          onClick={() => setIsAccept((prevState) => !prevState)}
        >
          {isFirst ? "AKTUALIZUJ" : "ZAPISZ"}
        </Button>
      </div>
    </Form>
  ) : (
    <p className={"w-100 m-0"}>{data?.description}</p>
  );
};
