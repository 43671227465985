import { Container } from "react-bootstrap";
import { Loading } from "components/atoms/Loading/Loading";
import Select from "react-select";
import styles from "./TargetedTreatment.module.scss";
import {
  useGetPageQuery,
  useGetPagesByNameAndCategoryQuery,
} from "redux/services/pagesApi";
import { useEffect, useState } from "react";
import { customStyles } from "styles/reactSelectCustomStyles";
import { convertToSelectLabel } from "Helpers/convertToSelectLabel";
import type { SingleValue } from "react-select";
import TreatmentPurpose from "components/atoms/TreatmentPurpose/TreatmentPurpose";
import { getStructure } from "Helpers/getStructureTreatmentPath";
import { slugify } from "Helpers/slugify";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { setIsShowWidget } from "redux/Slice/targeted-treatment-widget-slice";

const TargetedTreatment = () => {
  const [currentPageId, setCurrentPageId] = useState<number | null>(null);
  const [pagesDataSelectOptions, setPagesDataSelectOptions] = useState<
    ILabel[] | null
  >(null);
  const [treatmentPathData, setTreatmentPathData] = useState<
    TreatmentPath[] | null
  >(null);

  const { data: pagesData } = useGetPagesByNameAndCategoryQuery({
    categoryIds: ["1", "4"],
  });
  const { data: pageData } = useGetPageQuery(currentPageId?.toString());

  const { t } = useTranslation();

  const { authUser } = useSelector((state: RootState) => state.user);
  const { isShowWidget } = useSelector(
    (state: RootState) => state.targetTreatmentWidget
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (pagesData && Array.isArray(pagesData)) {
      setPagesDataSelectOptions(convertToSelectLabel(pagesData, "title"));
    }
  }, [pagesData]);

  useEffect(() => {
    if (pageData?.data) setTreatmentPathData(getStructure(pageData?.data));
  }, [pageData]);

  useEffect(() => {
    const selectedPage = pagesData?.find(
      (page) =>
        page?.cancer?.[0]?.id ===
        Number(authUser?.settings?.ID_nowotworu_HUB__c)
    );

    if (selectedPage) {
      setCurrentPageId(selectedPage.id);
      dispatch(setIsShowWidget(true));
    } else {
      dispatch(setIsShowWidget(false));
    }
    //eslint-disable-next-line
  }, [authUser?.settings?.ID_nowotworu_HUB__c, pagesData]);

  const onChangeCaptureHandler = (value: SingleValue<ILabel>) => {
    if (value?.id) {
      const foundPage = pagesData?.find((page) => page?.id === value?.id);
      if (foundPage) setCurrentPageId(foundPage?.id);
    }
  };

  const ONKOSNAJPER_URL =
    process.env?.REACT_APP_EXTERNAL_LINK_ONKOSNAJPER ??
    (process.env?.REACT_APP_MODE === "dev"
      ? "https://dev.onkosnajper.pl"
      : "https://onkonsnajper.pl");

  if (!isShowWidget) {
    return null;
  }

  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
        {t("widget_targeted_treatment_title")}
      </h2>
      <p className="fw-400 fs-16 text-dark-blue">
        {t("widget_targeted_treatment_p")}
      </p>
      <div>
        <label className="col-12">
          <div className="fw-400 fs-16 text-dark-blue my-2">
            {t("widget_targeted_treatment_label1")}
          </div>
          {pagesData && pagesDataSelectOptions ? (
            <Select<ILabel>
              menuPlacement="top"
              placeholder={t("widget_targeted_treatment_select_placeholder")}
              onChange={onChangeCaptureHandler}
              options={pagesDataSelectOptions}
              styles={customStyles(false, false)}
              noOptionsMessage={() => (
                <span>{t("widget_targeted_treatment_select_no_options")}</span>
              )}
              value={pagesDataSelectOptions?.find(
                (selectOption) => selectOption?.id === currentPageId
              )}
            />
          ) : (
            <Loading />
          )}
        </label>
        {pageData?.data && treatmentPathData && (
          <>
            <TreatmentPurpose
              treatmentPathData={treatmentPathData}
              pageData={pageData?.data}
            />
            <div className="d-flex w-100 justify-content-xxl-end pt-2">
              <a
                href={`${ONKOSNAJPER_URL}/szukaj/${slugify(
                  pageData?.data?.title
                )}/${pageData?.data?.id}`}
                className={`btn btn-primary text-white ${styles.infoButton}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("widget_targeted_treatment_more_button")}
              </a>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default TargetedTreatment;
