import WidgetsSettingsElement from "components/atoms/WidgetsSettingsElement/WidgetsSettingsElement";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { UserRole } from "redux/Slice/user-slice";
import { RootState } from "redux/store/index";
import { useTranslation } from "react-i18next";
import { useGetSFContactQuery } from "redux/services/salesforceApi";

const WidgetsSettingsModal = ({
  show,
  setShow,
  widgetsData,
  setPosition,
  setActive,
  onClose,
}: WidgetsSettingsModalProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const { isShowWidget } = useSelector(
    (state: RootState) => state.targetTreatmentWidget
  );
  const { data: sfContactData } = useGetSFContactQuery({});
  const rolesArray = authUser?.roles?.map((roles: UserRole) => {
    return roles?.role?.role;
  });
  const { t } = useTranslation();
  const shouldRenderObservedOncocollections =
    sfContactData?.data?.Cel_zbi_rki_ostatnio_wspartej__c ||
    sfContactData?.data?.Tytu_zbi_rki_ostatnio_wspartej__c ||
    sfContactData?.data?.Dost_pne_rodki_ostatnio_wspartej__c ||
    sfContactData?.data?.Link_do_zbi_rki_ostatnio_wspartej__c;

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 d-flex flex-row justify-content-between px-4 pb-0 pt-4"
        >
          <p className="fw-600 fs-22 text-dark-blue m-0">Zarządzaj widgetami</p>
        </Modal.Header>
        <Modal.Body className="align-items-center justify-content-center p-4">
          <div className="w-100 p-4">
            <div className="d-flex flex-column gap-3 w-100">
              {widgetsData ? (
                [...widgetsData]
                  .sort((a, b) => (a.position > b.position ? 1 : -1))
                  .filter((wd: WidgetsData) =>
                    Array.isArray(wd.forRole)
                      ? wd.forRole.some((role) => rolesArray.includes(role))
                      : rolesArray.includes(wd.forRole)
                  )
                  // Usunięcie wyświetlania zarządzania widgetami dotyczącymi onkomapy
                  .filter(
                    (widgetData: WidgetsData) =>
                      ![
                        "Polecani lekarze",
                        "Polecane ośrodki leczenia",
                      ].includes(widgetData?.name)
                  )
                  .filter(
                    (widgetData: WidgetsData) =>
                      !(
                        widgetData?.name === "Leczenie celowane" &&
                        !isShowWidget
                      )
                  )
                  // eslint-disable-next-line
                  .map((widgetData: WidgetsData, key) => {
                    if (
                      (widgetData?.name === "Obserwowane onkozbiórki" &&
                        shouldRenderObservedOncocollections) ||
                      widgetData?.name !== "Obserwowane onkozbiórki"
                    ) {
                      return (
                        <WidgetsSettingsElement
                          setActive={setActive}
                          setPosition={setPosition}
                          id={widgetData.id}
                          name={t(widgetData.name)}
                          isActive={widgetData.isActive}
                          position={widgetData.position}
                          forRole={widgetData.forRole}
                          key={key}
                        />
                      );
                    }
                  })
              ) : (
                <p>Brak ustawień widgetów.</p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WidgetsSettingsModal;
