import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { User } from "redux/Slice/user-slice";
import { useUnassignDoctorMutation } from "redux/services/doctorApi";
import { usePatchMeMutation } from "redux/services/meApi";
import { useDeleteUserRoleMutation } from "redux/services/usersApi";

export const useRemoveRole = () => {
  const [deleteRole] = useDeleteUserRoleMutation();
  const [patchMe] = usePatchMeMutation();

  const [unassignDoctor] = useUnassignDoctorMutation();
  const { t } = useTranslation();

  const removeRoleHandler = async (
    authUser: User,
    role: Role,
    doctorId?: number | null,
    toastFree?: boolean
  ) => {
    try {
      await deleteRole({
        data: {
          userId: authUser.id,
          sendData: { roleId: role ? role.id.toString() : "" },
        },
      });
      if (role?.role !== "patient") {
        await patchMe({
          data: {
            ...authUser,
            localSettings: {
              ...authUser.localSettings,
              [(role?.role +
                "_poll_Id") as keyof typeof authUser.localSettings]: null,
              [role?.sfName]: false,
            },
            settings: {
              ...authUser?.settings,
              [(role?.role + "_poll_Id") as keyof typeof authUser.settings]:
                null,
              [role?.sfName]: false,
            },
          },
        });
      } else {
        await patchMe({
          data: {
            ...authUser,
            localSettings: {
              ...authUser.localSettings,
              [(role?.role +
                "_poll_Id") as keyof typeof authUser.localSettings]: null,
              [role?.sfName]: false,
            },
            settings: {
              ...authUser?.settings,
              pollID__c: null,
              [role?.sfName]: false,
            },
          },
        });
      }
      doctorId && (await unassignDoctor({ id: doctorId }));
      !toastFree && toast.success(t("toast_accountRoleWidget_success"));
      return true;
    } catch (err) {
      toast.error(t("toast_accountRoleWidget_error"));
      return false;
    }
  };
  return removeRoleHandler;
};
