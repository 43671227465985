import { Button, Col, Container, Stack } from "react-bootstrap";
import styles from "../../templates/ChangesCompanyTemplate/ChangesCompanyTemplate.module.scss";
import { RejectModal } from "../RejectModal/RejectModal";
import React, { useEffect, useState } from "react";
import { ReactComponent as Instagram } from "../../../assets/Icons/SocialMediaIcons/Instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/Icons/SocialMediaIcons/Facebook.svg";
import { ReactComponent as Page } from "../../../assets/Icons/SocialMediaIcons/Page.svg";
import { SeparationUpdate } from "../../atoms/SeparationUpdate/SeparationUpdate";
import { Social } from "../../molecules/ListOfSocialPage/ListOfSocialPage";
import {
  nameSocial,
  SocialSelect,
} from "../CompanySocialMedia/CompanySocialMedia";
import { ChangesSocialMediaEntity } from "../../atoms/ChangesSocialMediaEntity/ChangesSocialMediaEntity";

interface Props {
  name: string;
  data: any;
  keyName: string;
  changes: any;
  handlePatch?: (obj: { [key: string]: any }, keyName: string) => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

export const ChangesSocialMedia = (props: Props) => {
  const [showReject, setShowReject] = useState(false);
  const [socialAddress, setSocialAddress] = useState<Social | null>(null);
  const [socialAddressChanges, setSocialAddressChanges] =
    useState<Social | null>(null);
  const [selectList, setSelectList] = useState<SocialSelect[]>();
  const [selectListChanges, setSelectListChanges] = useState<SocialSelect[]>();

  useEffect(() => {
    setSelectList([
      {
        label: nameSocial.page,
        value: "page",
        data: socialAddress?.page || null,
        icon: <Page />,
      },
      {
        label: nameSocial.facebook,
        value: "facebook",
        data: socialAddress?.facebook || null,
        icon: <Facebook />,
      },
      {
        label: nameSocial.instagram,
        value: "instagram",
        data: socialAddress?.instagram || null,
        icon: <Instagram />,
      },
    ]);
  }, [socialAddress]);

  useEffect(() => {
    setSelectListChanges([
      {
        label: nameSocial.page,
        value: "page",
        data: socialAddressChanges?.page || null,
        icon: <Page />,
      },
      {
        label: nameSocial.facebook,
        value: "facebook",
        data: socialAddressChanges?.facebook || null,
        icon: <Facebook />,
      },
      {
        label: nameSocial.instagram,
        value: "instagram",
        data: socialAddressChanges?.instagram || null,
        icon: <Instagram />,
      },
    ]);
  }, [socialAddressChanges]);

  useEffect(() => {
    if (props.data?.socialAddresses) {
      setSocialAddress(JSON.parse(props.data?.socialAddresses) ?? null);
    } else {
      setSocialAddress(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.changes?.socialAddresses) {
      setSocialAddressChanges(props.changes.socialAddresses);
    } else {
      setSocialAddressChanges(null);
    }
  }, [props.changes]);

  const handlePatch = () => {
    const obj: any = {};
    obj[props.keyName] = JSON.stringify(props.changes?.[props.keyName]);
    if (props.handlePatch) {
      props.handlePatch(obj, props.keyName);
    }
  };

  const handleComments = (comment: string) => {
    if (props.handleComments) {
      props.handleComments(comment);
    }
  };

  return (
    <Stack gap={3}>
      <Col className={`${props.isAdmin && styles.wrapper}`}>
        <Container fluid={true}>
          <span className={"fs-22 fw-semibold m-0 p-0 text-dark-blue"}>
            {props.name}
          </span>

          <Col className={"text-dark-blue"}>
            {selectList?.map((value: SocialSelect, index: number) => {
              return <ChangesSocialMediaEntity key={index} data={value} />;
            })}
          </Col>
        </Container>
      </Col>
      {!!props.changes?.[props.keyName] && (
        <>
          <SeparationUpdate />
          <Col className={`${props.isAdmin && styles.wrapper} text-danger`}>
            <Container fluid={true}>
              <span className={"fs-22 fw-semibold"}>{props.name}</span>
              {selectListChanges?.map((value: SocialSelect, index: number) => (
                <ChangesSocialMediaEntity key={index} data={value} />
              ))}
            </Container>
          </Col>
        </>
      )}
      {props.isAdmin && !!props.changes?.[props.keyName] && (
        <Col className={"d-flex justify-content-end gap-2"}>
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowReject((prev) => !prev)}
          >
            ODRZUĆ
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev) => !prev)}
            handleComments={handleComments}
            comments={
              props.changes?.comments
                ? `${props.changes.comments}; ${props.name}: `
                : `${props.name}: `
            }
          />
          <Button className={" fs-16"} onClick={handlePatch}>
            AKCEPTUJ
          </Button>
        </Col>
      )}
    </Stack>
  );
};
