import { eventTypes } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetEventTypesSearchParams {
  name?: string;
  language?: string;
  offset?: string;
  limit?: string;
  orderBy?: string;
  sortOrder?: string;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Event Types"],
});

export const EventTypesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getEventTypes: builder.query({
      query: ({
        limit,
        offset,
        sortOrder,
        name,
        orderBy,
      }: GetEventTypesSearchParams) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(orderBy && { orderBy }),
          ...(sortOrder && { sortOrder }),
          ...(name && { name }),
        });

        return {
          url: `${eventTypes}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Event Types"],
    }),
  }),
});

export const { useGetEventTypesQuery, useLazyGetEventTypesQuery } = EventTypesApi;
