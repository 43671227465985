import { CardCodes } from "redux/services/cardCodesApi";
import { useState } from "react";
import Breadcrumb from "components/molecules/Breadcrumb/Breadcrumb";
import styles from "../TableRowWithCollapse/TableRowWithCollapse.module.scss";
import QuickEditCardCodes from "components/molecules/QuickEditCardCodes/QuickEditCardCodes";
import { formatISODate } from "Helpers/formatISODate";

interface Props {
  cardCode: CardCodes;
}

const CardCodesTableRowWithCollapse = ({ cardCode }: Props) => {
  const [openEdit, setOpenEdit] = useState(false);

  const getUserName = (cardCode: CardCodes) => {
    if (cardCode?.user?.name) {
      return cardCode.user.name;
    } else if (cardCode?.user?.firstName && cardCode?.user?.lastName) {
      return `${cardCode.user.firstName} ${cardCode.user.lastName}`;
    } else {
      return "";
    }
  };

  const breadcrumbData = [
    {
      name: "Dodaj użytkownika",
      action: () => setOpenEdit((prevState) => !prevState),
    },
  ];
  return (
    <>
      <tr
        aria-controls={`example-collapse-text`}
        aria-expanded={openEdit}
        className={styles.mainTr}
      >
        <td>{cardCode?.number}</td>
        <td>{formatISODate(cardCode?.createdAt)}</td>
        <td>{getUserName(cardCode)}</td>
      </tr>
      {!openEdit && (
        <tr className={styles.hoverTr}>
          <td colSpan={4}>
            <Breadcrumb data={breadcrumbData} />
          </td>
        </tr>
      )}
      {openEdit && (
        <tr className={styles.collapseTr} data-active={openEdit}>
          <td colSpan={4}>
            <QuickEditCardCodes
              data={cardCode}
              open={openEdit}
              setOpen={setOpenEdit}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default CardCodesTableRowWithCollapse;
