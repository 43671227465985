import { createSlice } from "@reduxjs/toolkit";

export interface CompanyModalState {
  isModalOpen: boolean;
}

export const initialState: CompanyModalState = {
  isModalOpen: false,
};

export const companyModalSlice = createSlice({
  name: "company-modal",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
});

export const { toggleModal, openModal, closeModal } = companyModalSlice.actions;

export default companyModalSlice;
