import { Middleware } from "redux";
import { setOnMessage, setOnNotification } from "redux/Slice/websocket-slice";
import { setUserToken } from "redux/Slice/token-slice";
import { NotificationData } from "../components/organisms/NotificationView/types";

let currentSocket: WebSocket | null = null;

export const sendMessage = (messageObject: any) => {
  if (currentSocket && currentSocket.readyState === WebSocket.OPEN) {
    currentSocket.send(JSON.stringify(messageObject));
  } else {
    console.error("WebSocket is not open. Cannot send the message.");
  }
};

const closeWebSocketConnection = () => {
  if (currentSocket?.readyState === WebSocket.OPEN) {
    currentSocket.close();
  }
};

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  closeWebSocketConnection();
  window.removeEventListener("beforeunload", handleBeforeUnload);
};

export const webSocketMiddleware: Middleware =
  (store) => (next) => (action) => {
    let uniqueNotificationEntries = "";
    let RECONNECTION_DELAY = 1000;
    const MAX_RECONNECTION_DELAY = 30000;

    function reconnect(token: string) {
      setTimeout(() => {
        initializeWebSocket(token);
      }, RECONNECTION_DELAY);
      RECONNECTION_DELAY = Math.min(
        MAX_RECONNECTION_DELAY,
        RECONNECTION_DELAY * 2
      );
    }

    const initializeWebSocket = (token: string) => {
      if (action.type === setUserToken.type) {
        const token = action.payload;
        let baseUrl = "api.alivia.org.pl";
        let url = "";

        if (
          window.location.href.includes("localhost") ||
          window.location.href.includes("dev.moja.alivia.org.pl")
        ) {
          url = `wss://dev-api.alivia.org.pl:2096/?token=${token}`;
        } else {
          url = `wss://${baseUrl}:2096/?token=${token}`;
        }

        const socket = new WebSocket(url);
        currentSocket = socket;

        socket.onopen = () => {
          console.log(
            `[${new Date().toISOString()}] Websocket: Connection established`
          );
          window.addEventListener("beforeunload", handleBeforeUnload);
        };

        const uniqueEntries = new Set();

        socket.onmessage = (event) => {
          const currentMessage = JSON.parse(event.data);
          if (currentMessage.data.eventType === "UNREAD_TOPIC_MESSAGE") {
            currentMessage.data.payload.topic.topicMessage.forEach(
              (message: TopicMessage) => {
                const messageId = message.id;
                if (!uniqueEntries.has(messageId)) {
                  uniqueEntries.add(messageId);
                  store.dispatch(setOnMessage(message));
                }
              }
            );
          } else if (currentMessage.data.eventType === "UNREAD_NOTIFICATIONS") {
            const { user, admin, count } = currentMessage.data.payload;
            const uniqueKeyObj = {
              user: user?.map((obj: NotificationData) => Number(obj.id)) ?? [],
              admin:
                admin?.map((obj: NotificationData) => Number(obj.id)) ?? [],
            };
            const uniqueKey = JSON.stringify(
              [...uniqueKeyObj.user, ...uniqueKeyObj?.admin].sort(
                (a: number, b: number) => a - b
              )
            );
            if (uniqueNotificationEntries !== uniqueKey) {
              uniqueNotificationEntries = uniqueKey;
              store.dispatch(
                setOnNotification({
                  data: {
                    ...currentMessage.data,
                    payload: { unreadMessage: count ?? 0 },
                  },
                })
              );
            }
          }
        };

        socket.onerror = (error) => {
          console.log("Websocket: An error occured. Error: ", error);
        };

        socket.onclose = (event) => {
          if (event.wasClean) {
            console.log(
              `Websocket: Closed without errors, code=${event.code}, reason=${event.reason}`
            );
          } else {
            console.log(
              `[${new Date().toISOString()}] Websocket: Connection lost`
            );
          }
          window.removeEventListener("beforeunload", handleBeforeUnload);
          reconnect(token);
        };
      }
    };

    if (action.type === setUserToken.type) {
      const token = action.payload as string;
      initializeWebSocket(token);
    }

    return next(action);
  };
