import { Button, Container } from "react-bootstrap";
import { ReactComponent as MicrosoftLogo } from "../../../assets/Icons/SocialMediaIcons/Microsoft.svg";
import { ReactComponent as GoogleLogo } from "../../../assets/Icons/SocialMediaIcons/Google.svg";
import styles from "./EventInfo.module.scss";
import { EventInfoProps } from "./types";
import { useEffect, useState } from "react";
import { MapModal } from "components/organisms/MapModal/MapModal";
import Avatar from "components/atoms/Avatar/Avatar";
import { Loading } from "../../atoms/Loading/Loading";
import { EventAdress } from "components/organisms/AddEventModal/types";
import { Link } from "react-router-dom";
import { Apple } from "react-bootstrap-icons";
import { replaceSpacesWithDashes } from "../../../Helpers/replaceSpacesWithDashes";
import { format } from "date-fns";
import * as ics from "ics";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

export const EventInfo = ({
  eventData,
  isPrivate,
  isUserSignedUp,
  signUpHandler,
  isGetResponseFetching,
}: EventInfoProps) => {
  const startDate = new Date(eventData?.startingDate);
  const endDate = new Date(eventData?.endingDate);
  const doctorName = eventData?.notes?.includes("doctorName")
    ? eventData?.notes?.split(";")?.[0]?.split(":")?.[1]
    : null;
  const placeName = eventData?.notes?.includes("facilityName")
    ? eventData?.notes?.split(";")?.[1]?.split(":")?.[1]
    : null;
  const [showMapModal, setShowMapModal] = useState(false);
  const isWebinarAlivia: boolean = Boolean(eventData?.type === "getresponse");
  const adressObject: EventAdress =
    eventData?.address?.[0] === "{"
      ? JSON.parse(eventData?.address)
      : undefined;
  const address = `${eventData?.city?.name}, ${
    adressObject
      ? (adressObject?.placeName ? adressObject?.placeName + ", " : "") +
        (adressObject?.street ? adressObject?.street + " " : "") +
        (adressObject?.apartmentNumber
          ? adressObject?.houseNumber + "/" + adressObject?.apartmentNumber
          : adressObject?.houseNumber)
      : eventData?.address
  }`;
  const GoogleLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${format(
    new Date(eventData?.startingDate),
    "yyyyMMdd'T'HHmmSS"
  )}/${format(new Date(eventData?.endingDate), "yyyyMMdd'T'HHmmSS")}&details=${
    eventData?.description
  }&location=${address}&text=${eventData?.name}`;
  const OutlookLink = `https://outlook.office.com/calendar/deeplink/compose?path=/calendar/action/compose&rru=addevent&body=${
    eventData?.description
  }&enddt=${eventData?.endingDate}&location=${address}&startdt=${
    eventData?.startingDate
  }&subject=${eventData?.name}${
    eventData?.type === "online" ? "&online=1" : ""
  }`;
  const eventDateStartObject = new Date(eventData?.startingDate);
  const eventDateEndObject = new Date(eventData?.endingDate);

  const event: ics.EventAttributes = {
    start: [
      eventDateStartObject?.getFullYear(),
      eventDateStartObject?.getMonth() + 1,
      eventDateStartObject?.getDate(),
      eventDateStartObject?.getHours(),
      eventDateStartObject?.getMinutes(),
    ],
    end: [
      eventDateEndObject?.getFullYear(),
      eventDateEndObject?.getMonth() + 1,
      eventDateEndObject?.getDate(),
      eventDateEndObject?.getHours(),
      eventDateEndObject?.getMinutes(),
    ],
    title: eventData?.name,
    description: eventData?.description ?? "",
    location: address,
    url:
      eventData?.eventUrl ??
      `${process.env.REACT_APP_SITE_URL}/${NAV_PATH.EVENTS}/${eventData?.id}`,
    categories: eventData?.categories?.map(
      (category: EventCategory) => category?.title
    ),
  };

  const handleDownload = async () => {
    const filename = "iCalendarEvent.ics";
    const file: File = await new Promise((resolve, reject) => {
      ics.createEvent(event, (error, value) => {
        if (error) {
          reject(error);
        }
        resolve(new File([value], filename, { type: "text/calendar" }));
      });
    });
    const url = URL.createObjectURL(file);

    // trying to assign the file URL to a window could cause cross-site
    // issues so this is a workaround using HTML5
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(url);
  };

  const removeSecondsFromTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  };

  const ONKO_URL =
    process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
    (process.env?.REACT_APP_MODE === "dev"
      ? "https://dev.onkomapa.pl"
      : "https://onkomapa.pl");
  const ONKO_URL_DOCTOR = `${ONKO_URL}/szukaj/lekarz`;
  const scrollToTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    window.addEventListener("beforeunload", scrollToTop);
    return () => {
      window.removeEventListener("beforeunload", scrollToTop);
    };
  }, []);

  const startTimeOnly = removeSecondsFromTime(
    startDate.toUTCString().split(" ")[4]
  );
  const endTimeOnly = removeSecondsFromTime(
    endDate.toUTCString().split(" ")[4]
  );

  return (
    <Container className="containerWithShadow me-xl-2 me-0 p-4 mb-5">
      <h3 className="my-1 fw-600 fs-5 text-dark-blue">Informacje</h3>
      <p className={`${styles.InfoBarHeaderSmall} mt-5 mb-4`}>Data</p>
      <p className="d-flex flex-row justify-content-between fs-6 text-dark-blue">
        {startDate.toLocaleDateString() === endDate.toLocaleDateString() ? (
          <>
            <span> {startDate.toLocaleDateString()}</span>
            <span>
              {startTimeOnly} - {endTimeOnly}
            </span>
          </>
        ) : (
          <>
            <span>
              {startDate.toLocaleDateString()} {startTimeOnly}
            </span>
            <span>
              {endDate.toLocaleDateString()} {endTimeOnly}
            </span>
          </>
        )}
      </p>
      {eventData?.city && eventData?.address && (
        <>
          <p className={`${styles.InfoBarHeaderSmall} my-4`}>Lokalizacja</p>
          <p className="d-flex flex-column fs-6 text-dark-blue">
            <span>
              {eventData?.facility?.name
                ? eventData?.facility?.name
                : adressObject?.placeName
                ? adressObject?.placeName
                : placeName}
            </span>
            <span>
              {adressObject
                ? (adressObject?.street ? adressObject?.street + " " : "") +
                  (adressObject?.apartmentNumber
                    ? adressObject?.houseNumber +
                      " / " +
                      adressObject?.apartmentNumber
                    : adressObject?.houseNumber)
                : eventData?.address}
            </span>
            <span>{`${eventData?.zipCode ?? ""} ${
              eventData?.city?.name
            }`}</span>
          </p>
          <Button
            className="text-transform-none"
            variant="link"
            onClick={() => setShowMapModal(true)}
          >
            Pokaż miejsce na mapie
          </Button>
        </>
      )}
      {!isPrivate && (isWebinarAlivia || eventData?.eventUrl) && (
        <Button
          variant={!isUserSignedUp ? "primary" : "dark"}
          className="w-100 mt-5"
          onClick={isWebinarAlivia ? signUpHandler : undefined}
        >
          {!isWebinarAlivia ? (
            <Link
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
              to={eventData?.eventUrl ?? ""}
            >
              Szczegóły
            </Link>
          ) : !isGetResponseFetching ? (
            !isUserSignedUp ? (
              "Zapisz się"
            ) : (
              "Wypisz się"
            )
          ) : (
            <Loading />
          )}{" "}
        </Button>
      )}
      {!isPrivate && (
        <div>
          <p className={`${styles.InfoBarHeaderSmall} my-4`}>
            Dodaj wydarzenie do
          </p>
          <Button
            variant="outline-dark"
            rel="noopener"
            target="_blank"
            href={OutlookLink}
            className={`${styles.eventLink} text-capitalize border-gray w-100 fs-6 align-items-center d-flex flex-row justify-content-center mb-2`}
          >
            <MicrosoftLogo />
            <span className="ms-2">Outlook Calendar</span>
          </Button>
          <Button
            variant="outline-dark"
            rel="noopener"
            target="_blank"
            href={GoogleLink}
            className={`${styles.eventLink} text-capitalize border-gray w-100 fs-6 align-items-center d-flex flex-row justify-content-center mt-1 mb-2`}
          >
            <GoogleLogo />
            <span className="ms-2">Kalendarz Google</span>
          </Button>
          <Button
            onClick={handleDownload}
            variant="outline-dark"
            className=" text-capitalize border-gray w-100 fs-6 align-items-center d-flex flex-row justify-content-center mt-1 mb-2"
          >
            <Apple />
            <span className="ms-2">iCalendar</span>
          </Button>
        </div>
      )}
      <MapModal
        show={showMapModal}
        handleClose={() => setShowMapModal(false)}
        address={address}
      />
      {eventData?.doctor && (
        <div>
          <p className={`${styles.InfoBarHeaderSmall} my-4`}></p>
          <div className="d-flex flex-row align-items-center">
            <Avatar size="md" url="" />
            <div className="d-flex flex-column align-items-start ms-2">
              <h3 className="fw-600 fs-6 text-dark m-0">
                {eventData?.doctor?.firstName} {eventData?.doctor?.lastName}{" "}
              </h3>
              <a
                href={`${ONKO_URL_DOCTOR}/${replaceSpacesWithDashes(
                  String(eventData?.doctor?.degree?.shortcut)
                )}-${replaceSpacesWithDashes(
                  String(eventData?.doctor?.firstName)
                )}-${replaceSpacesWithDashes(
                  String(eventData?.doctor?.lastName)
                )}/${String(eventData?.doctor?.id)}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="link" className="p-0 mt-1 fs-14">
                  Pokaż profil lekarza
                </Button>
              </a>
            </div>
          </div>
        </div>
      )}
      {eventData?.doctor && (
        <div>
          <p className={`${styles.InfoBarHeaderSmall} my-4`}>Lekarz</p>
          <div className="d-flex flex-row align-items-center">
            <Avatar size="md" url="" />
            <div className="d-flex flex-column align-items-start ms-2">
              <h3 className="fw-600 fs-6 text-dark m-0">{doctorName}</h3>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};