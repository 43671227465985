let result: string;

export const changeDateFormatNames = (date: string, before = true) => {
  if (date) {
    let minutesAgo: number;
    if (before) {
      minutesAgo = Math.floor(
        (Date.now() - new Date(date).getTime()) / (1000 * 60),
      );
    } else {
      minutesAgo = Math.floor(
        (new Date(date).getTime() - Date.now()) / (1000 * 60),
      );
    }

    const hoursAgo = minutesAgo / 60;
    const daysAgo = hoursAgo / 24;
    const weeksAgo = daysAgo / 7;
    const monthsAgo = daysAgo / 31;
    const yearsAgo = monthsAgo / 12;
    switch (true) {
      case minutesAgo <= 1:
        result = `chwilę`;
        break;
      case minutesAgo > 1 && minutesAgo < 2:
        result = `${minutesAgo} minutę`;
        break;
      case minutesAgo >= 2 && minutesAgo < 5:
        result = `${minutesAgo} minuty`;
        break;
      case minutesAgo >= 5 && minutesAgo < 60:
        result = `${minutesAgo} minut`;
        break;
      case minutesAgo >= 60 && hoursAgo < 2:
        result = `${Math.floor(hoursAgo)} godzinę`;
        break;
      case hoursAgo >= 2 && hoursAgo < 4:
        result = `${Math.floor(hoursAgo)} godziny`;
        break;
      case hoursAgo >= 4 && hoursAgo < 24:
        result = `${Math.floor(hoursAgo)} godzin`;
        break;
      case hoursAgo >= 24 && weeksAgo < 1:
        result = `${Math.floor(daysAgo)} dni`;
        break;
      case weeksAgo >= 1 && weeksAgo < 2:
        result = `${Math.floor(weeksAgo)} tydzień`;
        break;
      case weeksAgo >= 2 && weeksAgo < 5:
        result = `${Math.floor(weeksAgo)} tygodnie`;
        break;
      case daysAgo > 31 && daysAgo < 61:
        result = `${Math.floor(monthsAgo)} miesiąc`;
        break;
      case daysAgo > 31 && monthsAgo < 12:
        result = `${Math.floor(minutesAgo / 60 / 24 / 31)} miesięcy`;
        break;
      case monthsAgo > 12 && monthsAgo < 24:
        result = `${Math.floor(yearsAgo)} rok`;
        break;
      case yearsAgo > 2 && yearsAgo < 5:
        result = `${Math.floor(yearsAgo)} lata`;
        break;
      case yearsAgo >= 5:
        result = `${Math.floor(yearsAgo)} lat`;
        break;
    }

    return before ? result + " temu" : result;
  }
  return;
};
