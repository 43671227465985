import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

const NoAccessPage = () => {
  return (
    <Container className={"text-dark-blue p-4"}>
      <h1>Nie masz uprawnień do tego modułu</h1>
      <p>
        Wróć na <Link to={`/${NAV_PATH.DASHBOARD}`}>pulpit</Link> i wybierz
        odpowiednie role.
      </p>
    </Container>
  );
};

export default NoAccessPage;
