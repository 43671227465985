import { SubstanceResponse } from "redux/services/substanceApi";

export const sortSubstanceByName = (obj?: SubstanceResponse) => {
  if (obj?.data) {
    const sortedData = [...obj.data];
    sortedData.sort((a, b) => a?.name?.localeCompare(b?.name));
    return { ...obj, data: sortedData };
  }
  return obj;
};
