import { Button, Col, Container, ProgressBar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styles from "./Onkorally.module.scss";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

interface Props {
  data: Oncocollections;
}

export const Onkorally = ({ data }: Props) => {
  const [showValue, setShowValue] = useState(false);
  const [percentValue, setPercentValue] = useState<number>(0);
  const { t } = useTranslation();

  const getPercentClass = (percent: number) => {
    if (percent <= 100 && percent >= 75) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-success`;
    } else if (percent < 75 && percent >= 50) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-warning`;
    } else if (percent < 50 && percent !== 0) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-secondary`;
    }

    return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-gray`;
  };

  const percentClass = getPercentClass(percentValue);

  useEffect(() => {
    if (data?.value && data?.max) {
      setPercentValue(
        Math.round((Number(data?.value) / Number(data?.max)) * 100)
      );
    } else {
      setPercentValue(0);
    }
  }, [data?.value, data?.max]);

  return (
    <Col className={`border border-1 ps-3 pe-2 py-3 alivia-radius`}>
      <Container fluid={true} className={"d-flex flex-column ps-0"}>
        <p
          className={`p-0 mb-1 text-dark-blue fw-400 fs-12 ${styles.paragraph}`}
        >
          {t("widget_patients_oncocollections_onkorally_p1")}
          <span className="ms-1 fw-600">{data?.user}</span>
        </p>
        <div className="d-flex flex-column flex-lg-row justify-content-between mb-4">
          <div className={`d-flex justify-content-between align-items-center`}>
            <p
              className={`fs-22 fw-600 lh-130 text-dark-blue m-0 my-2 my-lg-0 ${styles.collectionName}`}
            >
              {data?.name}
            </p>
            <Button
              className={`d-flex d-lg-none justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                showValue ? styles.arrowUp : styles.arrowDown
              } ${!showValue && "text-dark-blue"}`}
              variant={showValue ? "dark" : "transparent"}
              onClick={() => setShowValue((prevState) => !prevState)}
            >
              <Arrow rotate={90} />
            </Button>
          </div>

          <div
            className={`d-flex flex-row justify-content-between justify-content-lg-center align-items-center gap-2`}
          >
            <Button
              className={`d-none d-lg-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                showValue ? styles.arrowUp : styles.arrowDown
              } ${!showValue && "text-dark-blue"}`}
              variant={showValue ? "dark" : "transparent"}
              onClick={() => setShowValue((prevState) => !prevState)}
            >
              <Arrow rotate={90} />
            </Button>
            <a
              className="btn btn-transparent d-none d-lg-flex justify-content-center align-items-center p-0 m-0 text-dark-blue btn-square-alivia"
              href={data?.link}
              target="_blank"
              rel="noreferrer"
            >
              <ArrowUpRightFromSquare />
            </a>
          </div>
        </div>
        {showValue && (
          <>
            <div
              className={
                "d-lg-flex justify-content-between flex-column flex-lg-row"
              }
            >
              <p
                className={`fs-12 text-grey-2 fw-600 lh-130 mx-0 mb-1 mb-lg-1 ${styles.analiticsTitle}`}
              >
                {t("widget_patients_oncocollections_onkorally_p2")}
              </p>
              <p
                className={`fs-12 text-grey-2 fw-600 lh-130 mx-0 ${styles.analiticsTitle}`}
              >
                {t("widget_patients_oncocollections_onkorally_p3")}{" "}
                {format(new Date(), "HH:mm, dd.MM.yyyy")}
              </p>
            </div>
            <div
              className={
                "d-flex flex-lg-row justify-content-between fs-16 border-bottom py-2 text-dark-blue"
              }
            ></div>
            <div
              className={
                "d-flex flex-lg-row justify-content-between fs-16 border-bottom py-2 text-dark-blue"
              }
            >
              <p className={"m-0 text-dark-blue"}>
                {t("widget_patients_oncocollections_onkorally_p4")}
              </p>
              <p className={`fw-600 lh-130 m-0 text-dark-blue ${styles.dataNumbers}`}>
                {Number(data?.value).toLocaleString("pl-PL")}
              </p>
            </div>
            <div
              className={
                "d-flex flex-lg-row justify-content-between fs-16 border-bottom py-2 text-dark-blue mb-3"
              }
            >
              <p className={"m-0 text-dark-blue"}>
                {t("widget_patients_oncocollections_onkorally_p5")}
              </p>
              <p className={`fw-600 lh-130 m-0 text-dark-blue ${styles.dataNumbers}`}>
                {(
                  Math.round((Number(data?.max) - Number(data?.value)) * 100) /
                  100
                ).toLocaleString("pl-PL")}
              </p>
            </div>
          </>
        )}
        <Col
          className={`d-flex w-100 flex-column flex-lg-row gap-2 border border-1 p-3 alivia-radius bg-light text-dark-blue`}
        >
          <p className={`m-0 p-0 text-dark-blue ${styles.nowrapLg}`}>
            {t("widget_patients_oncocollections_onkorally_p6")}{" "}
            <span
              className={`d-flex d-lg-inline-flex fw-600 lh-130 m-0 text-dark-blue ${styles.dataNumbers}`}
            >
              {Number(data?.value).toLocaleString("pl-PL")}{" "}
              {t("widget_patients_oncocollections_onkorally_currency")}/{" "}
              {Number(data?.max).toLocaleString("pl-PL")}{" "}
              {t("widget_patients_oncocollections_onkorally_currency")}
            </span>
          </p>
          <div
            className={`position-relative m-0 py-3 p-lg-0 w-100 ${styles.OpinionsCounter}`}
          >
            <ProgressBar
              variant={`dark`}
              now={percentValue}
              className={`${styles.progressBar}`}
              label={
                <div
                  style={{
                    left: `${percentValue}%`,
                    transform: `translate(-${percentValue}%, 0)`,
                  }}
                  className={percentClass}
                >
                  {`${percentValue}%`}
                </div>
              }
            />
          </div>
        </Col>
      </Container>
    </Col>
  );
};
