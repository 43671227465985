import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import styles from "./AgreementModal.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePostAcceptedAgreementMutation } from "../../../redux/services/loyaltyProgramApi";
import { toast } from "react-toastify";

import { ReactComponent as TriangleExclamation } from "../../../assets/Icons/TriangleExclamation.svg";
import { useTranslation } from "react-i18next";

export const AgreementModal = (props: PropsAgreement) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [sendAgreementData] = usePostAcceptedAgreementMutation();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { handleSubmit } = useForm({
    values: {
      agreementId: props.data.id,
    },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const submitHandler: SubmitHandler<AgreementFormData> = async (data) => {
    if (isChecked) {
      const hasBeenAgreement = await sendAgreementData(data);
      if ("data" in hasBeenAgreement) {
        toast.success(
          `Zaakceptowano regulamin ${t(props.data.loyaltyProgram.name)}`,
        );
        props.handleClose();
      } else if ("error" in hasBeenAgreement) {
        const error = hasBeenAgreement.error as TranslationSubmitError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_agreementModal_error_500"));

        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_agreementModal_error_400")} ${error.data.message[0]}`,
          );
      }
    }
  };

  const handleButton = () => {
    if (isChecked) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  return (
    <Modal
      className="results-title map-modal-container"
      show={props.show}
      onHide={props.handleClose}
      centered
      scrollable
      size="xl"
      fullscreen={"md-down"}
    >
      <Modal.Header
        closeButton
        className={"border-bottom-0 m-4 p-0 align-items-start"}
      >
        <Col>
          <Modal.Title className="fw-bold text-dark-blue pb-2 pb-md-5">
            <span>
              {t(`company_agreement_modal_title_${props.typesAgreement}`)}
            </span>
          </Modal.Title>
          <div>
            <span className="fs-16 text-dark-blue">
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    `company_agreement_modal_header_${props.typesAgreement}`,
                  ).replace(
                    /%agreementName%/g,
                    t(props.data.loyaltyProgram.name),
                  ),
                }}
              />
            </span>
          </div>
        </Col>
      </Modal.Header>
      <Modal.Body className={`mx-4 ${styles.modalBody}`}>
        {props.data ? (
          <Container>
            <Row>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.description }}
              />
            </Row>
          </Container>
        ) : (
          <Container className={"d-flex justify-content-center"}>
            <Spinner animation="border" variant="primary" />
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer className={"border-top-0 m-4 p-0 pb-5 pb-md-0"}>
        <Container className="p-0 d-flex w-100 gap-3 w-100">
          <Form
            className={"d-flex flex-column w-100"}
            onSubmit={handleSubmit(submitHandler)}
          >
            <Form.Group className={"d-flex justify-content-start"}>
              <Form.Check
                label={t(
                  `company_agreement_modal_check_${props.typesAgreement}`,
                ).replace(
                  /%agreementName%/g,
                  t(props.data.loyaltyProgram.name),
                )}
                inline
                name="isAccepted"
                type={"checkbox"}
                id={`isAccepted`}
                className={
                  isChecked || buttonDisabled
                    ? "text-dark-blue"
                    : "text-secondary"
                }
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
            </Form.Group>
            <div className={"d-flex justify-content-end gap-3"}>
              <div onClick={handleButton}>
                <OverlayTrigger
                  placement={"bottom-end"}
                  trigger="click"
                  overlay={
                    !isChecked ? (
                      <Popover id="popover-basic" style={{ maxWidth: 600 }}>
                        <Popover.Body>
                          <div className={"d-inline-block"}>
                            <span className={"pe-3 text-dark-blue"}>
                              {t("company_agreement_modal_tooltip")}
                            </span>
                            <TriangleExclamation />
                          </div>
                        </Popover.Body>
                      </Popover>
                    ) : (
                      <></>
                    )
                  }
                  defaultShow={false}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    value="Submit"
                    className={`${!isChecked && "btn-disabled"}`}
                    disabled={!isChecked}
                  >
                    {t("company_agreement_modal_accept")}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </Form>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
