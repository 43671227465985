import { Button, Row } from "react-bootstrap";
import { Person } from "react-bootstrap-icons";
import { ReactComponent as Pencil } from "assets/Icons/Pencil.svg";
import styles from "components/molecules/DoctorHeader/DoctorHeader.module.scss";
import { useState } from "react";
import { DoctorScientificDegreeCreateModal } from "components/organisms/DoctorScientificDegreeCreateModal/DoctorScientificDegreeCreateModal";
import BadgeAwaitingReview from "../../atoms/BadgeAwaitingReview/BadgeAwaitingReview";

export const DoctorHeader = ({
  isFirstVisit,
  type,
  data,
  handleChangeAvatar,
  isReadOnly,
}: DoctorHeaderProps) => {
  const [
    showDoctorScientificDegreeEditor,
    setShowDoctorScientificDegreeEditor,
  ] = useState<boolean>(false);
  const handleShow = () => {
    setShowDoctorScientificDegreeEditor(true);
  };

  const PERSON_IMG_SIZE = 100;
  const AVATAR_IMG_SIZE = 160;

  return (
    <>
      <div className="container d-flex column mb-4">
        <Row className="m-0 mt-3">
          <div
            className={`text-grey-2 col-12 col-md-3 mx-auto d-flex align-items-center justify-content-center position-sticky ${styles.avatar}`}
          >
            {data?.avatar === null ? (
              <Person size={PERSON_IMG_SIZE} />
            ) : (
              <img
                className={"rounded-circle"}
                src={data?.avatar?.accessUrl}
                alt="Avatar"
                width={AVATAR_IMG_SIZE}
                height={AVATAR_IMG_SIZE}
              />
            )}

            {type === "account" && !isReadOnly && (
              <Button
                disabled={isReadOnly}
                variant="outline-primary"
                onClick={handleChangeAvatar}
                className={`btn-outline-primary border border-2 d-flex justify-content-center align-items-center position-absolute bottom-0 end-0 p-0 ${styles.editAvatar}`}
              >
                <Pencil />
              </Button>
            )}
          </div>

          <div className="container ms-lg-5 w-auto">
            {type === "account" && (
              <>
                <div className="py-md-1 pt-2 mt-md-3 d-md-flex text-center text-md-start">
                  <p className={`p-0 m-2 m-md-0 fs-16`}>Konto lekarza</p>
                  {isReadOnly && (
                    <>
                      <BadgeAwaitingReview text="Oczekuje na weryfikację" />
                    </>
                  )}
                </div>
              </>
            )}
            <h1
              className={`fw-600 dark w-auto text-dark-blue fs-39 text-center text-md-start mb-4 ${styles.doctorHeaderName}`}
            >
              {type === "dashboard" ? (
                <>
                  Witaj {!isFirstVisit && "ponownie"} {data?.firstName}!
                </>
              ) : (
                <>{`${data.degree?.name} ${data.firstName} ${data.lastName}`}</>
              )}
            </h1>

            {type === "account" && !isReadOnly && (
              <Button
                disabled={isReadOnly}
                className={`form-control text-uppercase btn btn-outline-primary fw-medium border border-2 ${styles.button}`}
                onClick={handleShow}
              >
                edytuj dane
              </Button>
            )}
          </div>
        </Row>
        <DoctorScientificDegreeCreateModal
          isFirst={false}
          show={showDoctorScientificDegreeEditor}
          handleClose={() =>
            setShowDoctorScientificDegreeEditor((prev: boolean) => !prev)
          }
          data={data}
        />
      </div>
    </>
  );
};
export default DoctorHeader;
