import React, { useEffect, useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Button } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import styles from "./AccordionToogle.module.scss";
interface Props {
  children?: React.JSX.Element;
  eventKey: string;
  transparent?: boolean;
  isActiveProps?: boolean;
}

export const AccordionToggle = ({
  children,
  eventKey,
  transparent = false,
  isActiveProps = false,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>(isActiveProps);
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setIsActive((prevState) => !prevState)
  );

  useEffect(() => {
    setIsActive(isActiveProps);
  }, [isActiveProps]);
  return (
    <>
      <Button
        variant={
          transparent ? "transparent" : isActive ? "dark" : "transparent"
        }
        className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
          transparent
            ? "text-dark-blue bg-transparent border-0"
            : isActive
            ? "text-light bg-dark"
            : "text-dark-blue bg-transparent border-0"
        }`}
        onClick={decoratedOnClick}
      >
        {children ? (
          children
        ) : (
          <Arrow className={isActive ? styles.arrowUp : styles.arrowDown} />
        )}
      </Button>
    </>
  );
};
