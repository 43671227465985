import React, { JSX, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePatchLoyaltyProgramServiceCodeMutation } from "../../../redux/services/loyaltyProgramApi";
import { useParams } from "react-router-dom";
import TooltipIcon from "../TooltipIcon";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import { toast } from "react-toastify";
import styles from "./QRScanner.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  handleClose: () => void;
}

export const QRScanner = ({ handleClose }: Props) => {
  const { id: companyId } = useParams();
  const [scanEnabled, setScanEnabled] = useState<boolean>(true);
  const [returnDate, setReturnDate] = useState<JSX.Element>(<></>);
  const [cameraResolution, setCameraResolution] = useState<string>("100%");
  const qrReader = React.createRef();

  const { t } = useTranslation();

  const [
    patchLoyaltyProgramServiceCode,
    {
      isSuccess: loyaltyProgramServiceCodeIsSuccess,
      data: loyaltyProgramServiceCodeData,
    },
  ] = usePatchLoyaltyProgramServiceCodeMutation();

  useEffect(() => {
    (async () => {
      try {
        const mediaDevices: MediaDevices = navigator.mediaDevices;
        if (!mediaDevices || !mediaDevices.enumerateDevices) {
          return;
        }

        const devices = await mediaDevices.getUserMedia({
          video: true,
        });
        const track = devices.getVideoTracks()[0];
        const settings = track.getSettings();

        if (
          settings?.height !== undefined &&
          settings?.width !== undefined &&
          settings.width !== 0
        ) {
          setCameraResolution(
            `${(settings.height / settings.width).toFixed(2)} %`
          );
        } else {
          setCameraResolution("100%");
        }
        devices.getVideoTracks()[0].stop();
      } catch (e) {
        setCameraResolution("100%");
      }
    })();
  }, [scanEnabled]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<LoyaltyProgramServiceCodeForm>({
    defaultValues: {
      code: "",
    },
  });

  useEffect(() => {
    if (
      loyaltyProgramServiceCodeIsSuccess &&
      loyaltyProgramServiceCodeData.data
    ) {
      if (loyaltyProgramServiceCodeData.data?.message === "Success") {
        setReturnDate(
          <h2 className={"text-center text-success mb-4"}>
            Kod zaakceptowany!
          </h2>
        );
      } else if (
        loyaltyProgramServiceCodeData.data?.message === "Not correct code"
      ) {
        setReturnDate(
          <h2 className={"text-center text-danger mb-4"}>Kod niepoprawny</h2>
        );
      } else if (
        loyaltyProgramServiceCodeData.data?.message === "The code has expired"
      ) {
        setReturnDate(
          <h2 className={"text-center text-dark-blue mb-4"}>
            Kod wygasł:{" "}
            {dateEmbellishment(
              loyaltyProgramServiceCodeData.data?.expireAt,
              "-"
            )}
          </h2>
        );
      } else if (
        loyaltyProgramServiceCodeData.data?.message === "The code has been used"
      ) {
        setReturnDate(
          <h2 className={"text-center text-dark-blue mb-4"}>
            Kod już użyto:{" "}
            {dateEmbellishment(loyaltyProgramServiceCodeData.data?.usedAt, "-")}
          </h2>
        );
      } else {
        setReturnDate(<></>);
      }
    }
    // eslint-disable-next-line
  }, [loyaltyProgramServiceCodeIsSuccess]);

  const handleScan = async (result: any, error: any) => {
    if (result) {
      if (!getValues().code && scanEnabled) {
        setValue("code", String(result.text));
        setScanEnabled((prevState) => !prevState);
        const hasBeenUpdated = await patchLoyaltyProgramServiceCode({
          id: companyId,
          data: { code: result.text },
        });
        if ("data" in hasBeenUpdated) {
          if (hasBeenUpdated.data?.data?.message === "Success") {
            toast.success(t("toast_QRScanner_success"));
          } else {
            toast.error(t("toast_QRScanner_error"));
          }
        } else if ("error" in hasBeenUpdated) {
          const error = hasBeenUpdated.error as TranslationSubmitError;
          if (error.data.statusCode === 500)
            toast.error(t("toast_QRScanner_error_500"));

          if (error.data.statusCode === 409)
            toast.error(t("toast_QRScanner_error_409"));

          if (error.data.statusCode === 404)
            toast.error(t("toast_QRScanner_error_404"));

          if (error.data.statusCode === 400)
            toast.error(
              `${t("toast_QRScanner_error_400")} ${error.data.message[0]}`
            );
        }
      }
    }
  };

  const toggleScan = () => {
    if (scanEnabled) {
      setScanEnabled(false);
    } else {
      setValue("code", "");
      setReturnDate(<></>);
      setScanEnabled(true);
    }
  };

  const submitHandler: SubmitHandler<LoyaltyProgramServiceCodeForm> = async (
    data
  ) => {
    const hasBeenUpdated = await patchLoyaltyProgramServiceCode({
      id: companyId,
      data: data,
    });

    if ("data" in hasBeenUpdated) {
      if (hasBeenUpdated.data?.data?.message === "Success") {
        toast.success(t("toast_QRScanner_success"));
      } else {
        hasBeenUpdated?.data?.translationKey
          ? toast.error(t(hasBeenUpdated?.data?.translationKey))
          : toast.error(t("toast_QRScanner_error"));
      }
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_QRScanner_error_500"));

      if (error.data.statusCode === 409)
        toast.error(t("toast_QRScanner_error_409"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_QRScanner_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_QRScanner_error_400")} ${error.data.message[0]}`
        );
    }
  };

  return (
    <Form className={"py-4"} onSubmit={handleSubmit(submitHandler)}>
      {scanEnabled && (
        <div className={`pb-4 ${styles.videoContainer}`}>
          {cameraResolution && (
            <QrReader
              // @ts-ignore
              ref={qrReader || null}
              constraints={{ facingMode: "environment" }}
              scanDelay={500}
              onResult={handleScan}
              videoContainerStyle={{ paddingTop: cameraResolution }}
            />
          )}
        </div>
      )}
      <div className={`text-dark-blue ${scanEnabled ? "" : "py-4"}`}>
        {returnDate}
        {!scanEnabled && (
          <>
            <div className={"d-flex align-items-center mb-2"}>
              <div
                className={`fs-16 ${
                  !!errors.code ? "text-danger" : " text-dark-blue"
                }`}
              >
                Wprowadź ręcznie kod rabatowy
              </div>
              <div className={"d-flex  text-primary ps-2"}>
                <TooltipIcon desc="Wielkość liter jest ważna">
                  <Info />
                </TooltipIcon>
              </div>
            </div>
            <div className={"d-flex gap-3"}>
              <div className={`w-100`}>
                <Form.Control
                  type="text"
                  id="code"
                  aria-describedby="code"
                  className={`w-100 text-dark-blue bg-white`}
                  {...register("code", {
                    required: { value: true, message: "Pole wymagane" },
                  })}
                  isInvalid={!!errors.code}
                />
                {errors.code && (
                  <Form.Control.Feedback type={"invalid"}>
                    {errors.code.message}
                  </Form.Control.Feedback>
                )}
              </div>
            </div>{" "}
          </>
        )}
      </div>
      <div
        className={`d-flex justify-content-${scanEnabled ? "center" : "end"}`}
      >
        {scanEnabled ? (
          <Button variant={"primary"} onClick={toggleScan}>
            wprowadź ręcznie
          </Button>
        ) : (
          <div className={"d-flex flex-wrap gap-3 justify-content-end"}>
            <Button variant={"outline-primary"} onClick={toggleScan}>
              Rozpocznij{" "}
              {loyaltyProgramServiceCodeData?.data?.message === "Success" &&
                "nowe"}{" "}
              skanowanie
            </Button>
            {loyaltyProgramServiceCodeData?.data?.message === "Success" ? (
              <Button variant={"primary"} onClick={() => handleClose()}>
                zamknij
              </Button>
            ) : (
              <Button variant={"primary"} type="submit" value="Submit">
                Wyślij
              </Button>
            )}
          </div>
        )}
      </div>
    </Form>
  );
};
