import styles from "./OptionSeparator.module.scss";

interface Props {
  value?: string;
}
export const OptionSeparator = ({ value }: Props) => {
  return (
    <div
      aria-disabled={true}
      className={`d-flex align-items-center ${styles.optionSeparator}`}
    >
      {value && (
        <>
          <div className={`flex-grow-1 ${styles.separator}`} />
          <div className={`flex-grow-0 fs-12 m-0 p-0 px-3`}>{value}</div>
        </>
      )}
      <div className={`flex-grow-1 ${styles.separator}`} />
    </div>
  );
};
