import styles from "./ComponentWithDescription.module.scss";

interface childrenProps {
  children?: React.ReactNode;
  label?: string;
  desc?: string;
  className?: string;
  labelFs?: string;
  descFs?: string;
}

const ComponentWithDescription = ({
  children,
  label,
  labelFs,
  descFs,
  desc,
  className,
}: childrenProps) => {
  return (
    <div className={`${styles.componentWithDescription} ${className}`}>
      {label && <span className={`${labelFs || "fs-13"}`}>{label}</span>}
      {children}
      {desc && <span className={`${descFs || "fs-13"}`}>{desc}</span>}
    </div>
  );
};

export default ComponentWithDescription;
