import CancerEditTemplate from "components/templates/CancerEditTemplate/CancerEditTemplate";
import { useParams } from "react-router-dom";
import { useGetCancerQuery } from "redux/services/cancerApi";
import { useGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";

const CancerEditPage = () => {
  const linkParams = useParams();
  const { data: cancerData } = useGetCancerQuery({ id: linkParams?.id });
  const { data: cancerCategories } = useGetPagesCategoryQuery({
    type: "cancer",
  });

  return (
    <CancerEditTemplate
      data={cancerData?.data}
      cancerCategories={cancerCategories}
    />
  );
};

export default CancerEditPage;
