import { Calendar4, GeoAlt } from "react-bootstrap-icons";
import styles from "./CalendarEventCard.module.scss";
import { useNavigate } from "react-router";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

export const CalendarEventCard = ({
  type,
  title,
  date,
  location,
  id,
  className,
}: CalendarEventCardProps) => {
  const navigate = useNavigate();
  const openEventPage = () => {
    window.scrollTo(0, 0);
    navigate(`/${NAV_PATH.EVENTS}/${id}`);
  };
  return (
    <div
      role="button"
      className={`${styles.CECardContainer} ${className}`}
      onClick={openEventPage}
    >
      <h6 className={styles.typeHeader}>{type}</h6>
      <h3 className={styles.titleHeader}>{title}</h3>
      <div className={styles.datesContainer}>
        <p style={{ marginRight: "10px" }}>
          <Calendar4 color="#ADB5BD" />
        </p>{" "}
        <p>{date}</p>
      </div>
      {location && !location?.every((element: any) => element === "") ? (
        <div className={styles.datesContainer}>
          <p style={{ marginRight: "10px" }}>
            <GeoAlt color="#ADB5BD" />
          </p>{" "}
          <p>{location.join(", ")}</p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};