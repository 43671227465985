import CompaniesSection from "components/organisms/CompaniesSection/CompaniesSection";
import ServicesSection from "components/organisms/ServicesSection/ServicesSection";
import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetCompaniesQuery } from "redux/services/companyApi";
import { useGetLoyaltyProgramsServicesQuery } from "redux/services/loyaltyProgramApi";

export interface InitialSearchParamsType {
  PAGE: number;
  LIMIT: number;
  TOTAL_RESULTS: number;
  SKIP_USER: boolean;
}

const INITIAL_SEARCH_PARAMS: InitialSearchParamsType = {
  PAGE: 1,
  LIMIT: 20,
  TOTAL_RESULTS: 0,
  SKIP_USER: true,
};

const CompaniesAdminTemplate = () => {
  const [searchParams] = useSearchParams();

  const [currCompaniesPage, setCurrCompaniesPage] = useState(
    Number(searchParams.get("companiesPage")) || INITIAL_SEARCH_PARAMS.PAGE
  );
  const [currServicesPage, setCurrServicesPage] = useState(
    Number(searchParams.get("servicesPage")) || INITIAL_SEARCH_PARAMS.PAGE
  );

  const {
    data: companiesData,
    isLoading: isCompaniesLoading,
    isFetching: isCompaniesFetching,
  } = useGetCompaniesQuery({
    limit: INITIAL_SEARCH_PARAMS.LIMIT,
    offset: currCompaniesPage
      ? ((currCompaniesPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : "0",
    skipUser: INITIAL_SEARCH_PARAMS.SKIP_USER,
  });
  const {
    data: servicesData,
    isLoading: isServicesLoading,
    refetch: refetchServices,
  } = useGetLoyaltyProgramsServicesQuery({
    limit: INITIAL_SEARCH_PARAMS.LIMIT,
    offset: currServicesPage
      ? ((currServicesPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : "0",
  });

  useEffect(() => {
    refetchServices();
    //eslint-disable-next-line
  }, [isCompaniesFetching]);

  return (
    <Container fluid className="bg-light">
      <CompaniesSection
        companiesData={companiesData}
        isLoading={isCompaniesLoading}
        currPage={currCompaniesPage}
        setCurrPage={setCurrCompaniesPage}
        initialSearchParams={INITIAL_SEARCH_PARAMS}
      />
      <ServicesSection
        servicesData={servicesData}
        isLoading={isServicesLoading}
        currPage={currServicesPage}
        setCurrPage={setCurrServicesPage}
        initialSearchParams={INITIAL_SEARCH_PARAMS}
      />
    </Container>
  );
};

export default CompaniesAdminTemplate;
