import { Eye } from "react-bootstrap-icons";
import { useEffect, useState } from "react";

const StatuteBar = ({
  title,
  type,
  showStatuteModal,
  defaultChecked,
  register,
  text,
  isTextFromHTML,
}: StatuteBarProps) => {
  const [show, setShow] = useState(defaultChecked);

  useEffect(() => setShow(defaultChecked), [defaultChecked]);

  const toggleShow = () => setShow((prevState) => !prevState);
  const openModal = () => showStatuteModal(title, text, isTextFromHTML);

  return (
    <div>
      <div
        style={{
          border: "1px solid #E9ECEF",
          borderRadius: "4px",
        }}
        className="p-3 d-flex flex-row justify-content-between align-items-center mb-3"
      >
        <div className="d-flex flex-row align-items-center">
          {type === "agreement" && (
            <div className="form-check form-switch me-2">
              <input
                checked={show}
                className="form-check-input"
                type="checkbox"
                role="switch"
                onClick={toggleShow}
                {...register}
              />
            </div>
          )}
          <p className="fs-16 fw-600 m-0 text-dark-blue">{title}</p>
        </div>
        <div>
          {type !== "agreement" && (
            <Eye
              size={20}
              className="text-dark-blue stroke-dark-blue"
              style={{ cursor: "pointer" }}
              onClick={openModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default StatuteBar;
