import { apiSlice } from "./apiSlice";
import { systemSettings } from "../../endpoints/endpoints";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["SystemSettings"],
});

export const systemSettingsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSystemSettings: builder.query({
      query: () => ({
        url: `${systemSettings}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["SystemSettings"],
    }),

    updateSystemSettings: builder.mutation({
      query: ({ id, data }) => ({
        url: `${systemSettings}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["SystemSettings"],
    }),
  }),
});

export const { useGetSystemSettingsQuery, useUpdateSystemSettingsMutation } =
  systemSettingsApi;
