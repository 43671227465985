import React, { useContext } from "react";
import { Button, Collapse, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TypeContext } from "../../../templates/FeedCategoryTemplate/FeedCategoryTemplate";
import {
  usePatchFeedTagMutation,
  usePatchFeedTopicMutation,
} from "../../../../redux/services/feedApi";

interface QuickEditArticleProps {
  open: boolean;
  data: IFiltersSource;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuickEditFeed = ({ data, open, setOpen }: QuickEditArticleProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data.name,
    },
  });

  const type = useContext(TypeContext);

  const { t } = useTranslation();

  const [editFeedTag] = usePatchFeedTagMutation();
  const [editFeedTopic] = usePatchFeedTopicMutation();

  const submitHandler: SubmitHandler<{ name: string }> = async (submitData) => {
    const payload = {
      id: data.id,
      data: {
        ...submitData,
      },
    };

    const hasBeenEdited =
      type === "tags"
        ? await editFeedTag(payload)
        : await editFeedTopic(payload);

    if ("data" in hasBeenEdited) {
      toast.success(t("toast_quickEditArticle_success"));
    } else if ("error" in hasBeenEdited) {
      const error = hasBeenEdited?.error as TranslationSubmitError;

      switch (error.data.statusCode) {
        case 500:
          toast.error(t("toast_quickEditArticle_error_500"));
          break;
        case 400:
          toast.error(t("toast_quickEditArticle_error_400"));
          break;
        default:
          toast.error(t("toast_quickEditArticle_error"));
          break;
      }
    }
  };

  const formGroupStyles = "mb-2";
  const labelStyles = "mb-1 fs-12 lh-base";
  const inputStyles = "fs-14";

  return (
    <Collapse in={open}>
      <>
        <span>Szybka akcja</span>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="d-flex w-100 gap-5">
            <div className="w-100 d-flex flex-column gap-2">
              <Form.Group className={formGroupStyles}>
                <Form.Label className={labelStyles} htmlFor="feedFilterName">
                  Nazwa
                </Form.Label>
                <Form.Control
                  className={inputStyles}
                  id="feedFilterName"
                  {...register("name", {
                    required: "Pole nie może być puste.",
                    pattern: {
                      value: /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/i,
                      message: "Nazwa nie może zawierać znaków specjalnych.",
                    },
                  })}
                  type="text"
                  isInvalid={Boolean(errors?.name)}
                />
                {errors?.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors?.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="d-flex gap-2 mt-4">
            <Button type="submit" value="Submit">
              Aktualizuj
            </Button>
            <Button className="btn-outline-blue" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
          </div>
        </form>
      </>
    </Collapse>
  );
};

export default QuickEditFeed;
