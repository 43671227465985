import React, { useState } from "react";
import { useGetLoyaltyProgramsAgreementAllQuery } from "../../../redux/services/loyaltyProgramApi";
import { Container, Form, Spinner } from "react-bootstrap";
import { AgreementLoyaltyProgramTable } from "../Tables/AgreementLoyaltyProgramTable/AgreementLoyaltyProgramTable";
import { AgreementLoyaltyProgramForm } from "../../molecules/AgreementLoyaltyProgramForm/AgreementLoyaltyProgramForm";
import { useSearchParams } from "react-router-dom";
import CustomPagination from "../CustomPagination/CustomPagination";
const INITIAL_SEARCH = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  ALL: "",
  TOTAL_RESULTS: 0,
};

export const AgreementLoyaltyProgram = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH.PAGE,
  );
  const [all, setAll] = useState<string>(
    searchParams.get("all") || INITIAL_SEARCH.ALL,
  );
  const [dataEdit, setDataEdit] =
    useState<LoyaltyProgramsAgreementAllData | null>(null);

  const {
    data: loyaltyProgramsAgreementAllData,
    isLoading: loyaltyProgramsAgreementAllIsLoading,
  } = useGetLoyaltyProgramsAgreementAllQuery({
    all: all || INITIAL_SEARCH.ALL,
    limit: INITIAL_SEARCH.LIMIT.toString(),
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH.LIMIT).toString()
      : INITIAL_SEARCH.OFFSET,
  });

  if (loyaltyProgramsAgreementAllIsLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set("all", event.target.checked ? "1" : "");
    setSearchParams(searchParams);
    setAll(event.target.checked ? "1" : "");
  };

  const handleEditData = (data: LoyaltyProgramsAgreementAllData) => {
    setIsActive(true);
    setDataEdit(data);
  };
  const handleDeleteData = () => {
    setIsActive(false);
    setDataEdit(null);
  };

  const handleIsActive = (active: boolean) => {
    setIsActive(active);
  };

  return (
    <>
      <AgreementLoyaltyProgramForm
        data={dataEdit}
        isActive={isActive}
        handleIsActive={handleIsActive}
        handleDeleteData={handleDeleteData}
      />
      <AgreementLoyaltyProgramTable
        data={loyaltyProgramsAgreementAllData.data}
        handleEditData={handleEditData}
      />
      <div className={"d-flex justify-content-between"}>
        <CustomPagination
          totalCount={
            loyaltyProgramsAgreementAllData?.meta?.totalResults ||
            INITIAL_SEARCH.TOTAL_RESULTS
          }
          pageSize={INITIAL_SEARCH.LIMIT}
          currentPage={currPage}
          onPageChange={handlePageChange}
          paginationClassName="text-nowrap"
        />
        <Form.Check
          className={"mb-4 text-dark-blue ms-auto"}
          type="switch"
          id="all"
          label="Pokaż wszystkie"
          checked={!!all}
          onChange={handleAllChange}
        />
      </div>
    </>
  );
};
