import { Button, Modal } from "react-bootstrap";

const PopupModal = ({
  show,
  setShow,
  text,
  type = "confirm",
  confirmAction,
  titleClasses,
  smallText,
  size = "sm",
}: PopupModalProps) => {
  const buttons = (() => {
    switch (type) {
      case "info":
        return (
          <Button onClick={() => setShow(false)} variant="primary">
            ZAMKNIJ
          </Button>
        );

      case "confirm":
        return (
          <div className="d-flex flex-row justify-content-end">
            <Button
              className="btn-outline-dark-blue me-3"
              onClick={() => setShow(!show)}
            >
              ANULUJ
            </Button>

            <Button
              variant="primary"
              className="text-uppercase"
              onClick={confirmAction}
            >
              POTWIERDŹ
            </Button>
          </div>
        );
    }
  })();

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="align-items-center justify-content-center p-4">
        <p
          id="example-custom-modal-styling-title"
          className={
            titleClasses ? titleClasses : `fs-16 ls-3 fw-semibold text-center`
          }
        >
          {text}
        </p>
        <p className="fs-16 fw-400 text-center text-dark-blue">{smallText}</p>
        <div className="d-flex justify-content-center mt-4 pt-2">{buttons}</div>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
