import { useState, ChangeEvent } from "react";
interface SwitchState {
  [name: string]: boolean;
}

let switchState: SwitchState = {};

const setSwitchState = (name: string, value: boolean) => {
  switchState = {
    ...switchState,
    [name]: value,
  };
};

const getSwitchState = (name: string) => {
  return switchState[name] || false;
};

const switchChangeHandler = (
  name: string,
  event: ChangeEvent<HTMLInputElement>
) => {
  setSwitchState(name, event.target.checked);
};

export const useSwitch = (name: string) => {
  const [isSwitched, setIsSwitched] = useState<boolean>(getSwitchState(name));

  const localSwitchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSwitched(event.target.checked);
    switchChangeHandler(name, event);
  };

  return { isSwitched, switchChangeHandler: localSwitchChangeHandler };
};
