export const replacePolishLetters = (text: string) => {
  return text
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\u0142/g, "l")
    .replace(/[^\w\s-]/g, "")
    .split(" ")
    .join("-");
};
