import React from "react";
import {SocialSelect} from "../../organisms/CompanySocialMedia/CompanySocialMedia";
import {ReactComponent as ArrowUpRightFromSquare} from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import {Button} from "react-bootstrap";
interface Props {
    data: SocialSelect
}

export const ChangesSocialMediaEntity = (props: Props) => {

    return <div className={"d-flex flex-wrap justify-content-between pt-3"}>
        <div className={"d-flex align-items-center order-0 col-8 col-lg-3"}>
            <div className={"d-flex justify-content-center align-items-center me-2"} style={{width:30}}>
                {props.data.icon}
            </div>
            <span>{props.data.label}: </span>
        </div>
        <span className={`d-flex text-wrap text-break justify-content-center align-items-center order-3 order-lg-2 col-12 col-lg-8 mb-4 mt-2`}>
            {props.data.data || "-" }
        </span>
        <Button
            style={{width: 38, height: 38}}
            disabled={!props.data.data}
            variant={'transparent'}
            className="d-flex justify-content-center align-items-center p-0 m-0 order-2 order-lg-3"
            onClick={() => {
                if (props.data.data) {
                    window.open(props.data.data, '_blank');
                }
            }}
        >
            <ArrowUpRightFromSquare />
        </Button>
    </div>;
};
