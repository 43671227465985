import { Button, Collapse, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TextInput from "../../../atoms/TextInput/TextInput";
import { toast } from "react-toastify";
import RadioInput from "components/atoms/RadioInput/RadioInput";
import { usePatchCancerUpdateMutation } from "redux/services/cancerApi";
import { enums } from "Helpers/enums";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface QuickEditCancerData {
  name?: string;
  slug?: string;
  code?: string | null;
  displayOrder?: number;
  status?: string;
  deleted?: number;
  categoryIds?: number[];
}

const QuickEditCancer = ({ data, open, setOpen }: any) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: data?.name ?? "",
      displayOrder: data?.displayOrder || 999999999,
      status: data?.status?.toString() || "1",
    },
  });

  const { t } = useTranslation();

  const [patchCancer] = usePatchCancerUpdateMutation();

  const submitHandler = async (submitData: QuickEditCancerData) => {
    const postData = {
      ...submitData,
      displayOrder: +(submitData?.displayOrder ?? 999999999),
      status: +(submitData?.status ?? "1"),
    };

    const pagesCategory = await patchCancer({
      id: data.id,
      data: postData,
    });

    setOpen(false);

    if ("data" in pagesCategory) {
      toast.success(t("toast_quickEditCancer_success"));
    } else if ("error" in pagesCategory) {
      toast.error(t("toast_quickEditCancer_error"));
    }
  };

  useEffect(() => {
    if (watch("displayOrder") > 999999999) {
      setValue("displayOrder", 999999999);
    }
    // eslint-disable-next-line
  }, [watch("displayOrder")]);

  return (
    <Collapse in={open}>
      <>
        <span>Szybka edycja</span>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="d-flex w-100 gap-5">
            <div className="w-100 d-flex flex-column gap-2">
              <TextInput
                className="align-items-center"
                label={"Nazwa"}
                register={register("name")}
              />
            </div>
            <div className="w-100 d-flex flex-column gap-2">
              <TextInput
                className="align-items-center"
                type="number"
                min={1}
                max={999999999}
                label={"Kolejność wyświetlania"}
                register={register("displayOrder")}
              />
            </div>
            <div className="w-100 d-flex flex-column gap-2  justify-content-center">
              <div className="pe-5 d-flex" style={{ maxWidth: "450px" }}>
                <span style={{ width: 120 }}>Status</span>
                <Container className="d-inline-flex row ">
                  {enums.cancerStatus.map((status) => (
                    <RadioInput
                      key={status.id}
                      register={register("status", {
                        required: true,
                      })}
                      label={status.name}
                      value={status.id}
                    />
                  ))}
                </Container>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 mt-4">
            <Button type="submit">Aktualizuj</Button>
            <Button className="btn-outline-blue" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
          </div>
        </form>
      </>
    </Collapse>
  );
};

export default QuickEditCancer;
