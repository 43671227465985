import { Spinner } from "react-bootstrap";
import styles from "./LoadingScreen.module.scss";

interface LoadingScreenProps {
  isPoll?: boolean;
}

export default function LoadingScreen({ isPoll }: LoadingScreenProps) {
  return (
    <div
      className={`w-100 d-flex flex-column ${
        !isPoll && "justify-content-center"
      } align-items-center ${styles.loading}`}
    >
      <h2 className="fw-600 text-dark-blue fs39 p-5 text-center">
        {isPoll
          ? "Wczytywanie ankiety, proszę czekać"
          : "Wczytywanie, proszę czekać"}
      </h2>

      <Spinner animation="border" variant="primary" role="Wczytywanie">
        <span className="visually-hidden">Wczytywanie...</span>
      </Spinner>
    </div>
  );
}
