import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import i18n from "i18next";
export const AppMeta = () => {
  const [systemSettings, setSystemSettings] = useState<LandingContextProps[]>(
    []
  );
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${process.env?.REACT_APP_EXTERNAL_LINK_STATIC}/system/settings.json`
      );

      if (!response) {
        throw new Error("Failed to fetch landing cover.");
      }

      const data: LandingContextProps[] = await response.json();

      setSystemSettings(data);
    })();
  }, []);

  const meta_title =
    systemSettings?.find(
      (obj: LandingContextProps) => obj.context === "general_title_content"
    )?.value || "";
  const meta_image =
    systemSettings?.find(
      (obj: LandingContextProps) => obj.context === "landing_cover"
    )?.value || "";
  const meta_description =
    systemSettings
      ?.find((obj: LandingContextProps) => obj.context === "landing_content")
      ?.value.replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">") || "";

  const meta_image_parsed =
    meta_image && meta_image.length > 0 && JSON.parse(meta_image);
  const meta_description_text_content = new DOMParser().parseFromString(
    meta_description,
    "text/html"
  ).body.textContent;
  const meta_description_parsed = meta_description_text_content?.replace(
    /\n/,
    " "
  );

  const meta_language = i18n?.language?.split("-")?.[0];
  const meta_keywords =
    systemSettings?.find(
      (obj: LandingContextProps) => obj.context === "landing_keywords"
    )?.value || "";

  const isContainMetaNoFollowAndNoIndex = process.env?.REACT_APP_MODE === "dev";

  const MOJA_ALIVIA_METADATA: Metadata<string> = {
    TITLE: meta_title ?? "Moja Alivia tytuł",
    IMAGE: meta_image_parsed?.accessUrl ?? "",
    DESCRIPTION: meta_description_parsed ?? "Moja Alivia opis",
    LANGUAGE: meta_language ?? "pl",
    KEYWORDS: meta_keywords ?? "Portal Moja Alivia",
  };

  return (
    <Helmet>
      <html lang={MOJA_ALIVIA_METADATA.LANGUAGE} />
      <meta property="og:title" content={MOJA_ALIVIA_METADATA.TITLE} />
      <meta
        property="og:description"
        content={MOJA_ALIVIA_METADATA.DESCRIPTION}
      />
      <meta property="og:image" content={MOJA_ALIVIA_METADATA.IMAGE} />
      <meta name="keywords" content={MOJA_ALIVIA_METADATA.KEYWORDS} />
      <meta name="description" content={MOJA_ALIVIA_METADATA.DESCRIPTION} />
      {isContainMetaNoFollowAndNoIndex && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <title>Moja Alivia - {MOJA_ALIVIA_METADATA.TITLE}</title>
    </Helmet>
  );
};
