import { Button, Container, Dropdown } from "react-bootstrap";
import { useState } from "react";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import styles from "./AdminUserRoleEntity.module.scss";
import PopupModal from "../PopupModal/PopupModal";
import { ReactComponent as TripleDot } from "../../../assets/Icons/TripleDot.svg";
import {
  useDeleteUserRoleMutation,
  usePatchUserSFContactMutation,
} from "../../../redux/services/usersApi";
import { useGetUserRolesQuery } from "redux/services/userRolesApi";

interface UserRoleEntityProps {
  userId: number;
  data: Roles;
}

export const AdminUserRoleEntity = ({ data, userId }: UserRoleEntityProps) => {
  const [deleteRoleFromUser] = useDeleteUserRoleMutation();
  const [patchUserSF] = usePatchUserSFContactMutation();
  const { data: roleUsersData } = useGetUserRolesQuery({});
  const [showModal, setShowModal] = useState<boolean>(false);

  const modalAcceptDeleteHandler = async () => {
    const currentRole = roleUsersData?.userRoles?.filter(
      (role: ShortUserRoles) => role?.id === data?.role?.id
    );
    const roleKey = currentRole?.[0]?.sfName;

    await deleteRoleFromUser({
      data: {
        userId: userId,
        sendData: {
          roleId: String(data?.role?.id),
        },
      },
    });
    await patchUserSF({
      data: {
        [roleKey]: false,
      },
      userId: userId.toString(),
    });
    setShowModal(false);
  };

  return (
    <Container
      fluid={true}
      key={data.id}
      className={"d-flex justify-content-between m-0 p-2"}
    >
      <p
        className={
          "d-flex align-items-center fs-16 fw-semibold text-dark-blue ps-3 mb-0"
        }
      >
        <span>{data.role.name}</span>
        <span className={"text-grey-2 ms-2"}>
          ({data.status === 0 && "nie "}aktywna)
        </span>
      </p>

      <Dropdown className={"d-flex d-lg-none"}>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-basic"
          className={styles.dropdownToggle}
        >
          <TripleDot />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowModal((prev: boolean) => !prev)}>
            Usuń role
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className={"d-none d-lg-flex gap-3"}>
        <Button
          className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
          variant={"transparent"}
          onClick={() => setShowModal((prev: boolean) => !prev)}
        >
          <TrashBlank />
        </Button>
        <PopupModal
          titleClasses="fw-600 text-center"
          text="Czy na pewno chcesz usunąć rolę dla użytkownika?"
          type="confirm"
          show={showModal}
          setShow={() => setShowModal(false)}
          confirmAction={() => modalAcceptDeleteHandler()}
        />
      </div>
    </Container>
  );
};
