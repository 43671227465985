import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
import CancersTable from "components/organisms/Tables/CancersTable/CancersTable";
import React, { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useGetCancersQuery } from "redux/services/cancerApi";
import {
  useGetPagesCategoryQuery,
  useLazyGetPagesCategoryQuery,
} from "../../../redux/services/pagesCategoryApi";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import { CancerDateModal } from "../../organisms/CancerDateModal/CancerDateModal";
import { IndexTable } from "../../organisms/Tables/IndexTable/IndexTable";
import { useGetIndexesQuery } from "../../../redux/services/substanceApi";
import { useGetCountriesQuery } from "../../../redux/services/countriesApi";
import Select, { SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { Container } from "react-bootstrap";

const INITIAL_SEARCH_PARAMS: InitialSearchParamsUser = {
  PAGE: 1,
  LIMIT: 10,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
};
const INITIAL_SEARCH_PARAMS_INDEX: InitialSearchParamsUser = {
  PAGE: 1,
  LIMIT: 10,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
};

const CancersTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.PAGE
  );
  const [currPageIndex, setCurrPageIndex] = useState(
    Number(searchParams.get("pageIndex")) || INITIAL_SEARCH_PARAMS.PAGE
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const { data: cancersData } = useGetCancersQuery({
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS.OFFSET,
    limit: INITIAL_SEARCH_PARAMS.LIMIT.toString(),
    sortBy: searchParams.get("sortBy") ?? undefined,
    categoryIds: searchParams.get("category") ?? undefined,
    nameLike: searchParams.get("nameLike") ?? undefined,
    code: searchParams.get("code") ?? "",
  });
  const { data: indexData } = useGetIndexesQuery({
    offset: currPageIndex
      ? ((currPageIndex - 1) * INITIAL_SEARCH_PARAMS_INDEX.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS_INDEX.OFFSET,
    limit: INITIAL_SEARCH_PARAMS_INDEX.LIMIT.toString(),
    countryId: searchParams.get("countryId") || "",
  });

  const { data: pagesCategoryData } = useGetPagesCategoryQuery({
    type: "cancer",
  });

  const { data: countriesData } = useGetCountriesQuery({});
  const countriesOptions = countriesData?.data.map((item: Country) => ({
    value: item.id,
    label: item.name,
  }));

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      category: "",
      nameLike: "",
      codeLike: "",
    },
  });

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };
  const handlePageIndexChange = (page: number) => {
    searchParams.set("pageIndex", page.toString());
    setSearchParams(searchParams);
    setCurrPageIndex(page);
  };

  return (
    <Container as="section" fluid className="pt-2 px-4 mb-5">
      <div className="d-flex my-3 gap-3">
        <h1 className="fs-2">Nowotwory</h1>
        <Link to={`/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}/new`}>
          <Button className="btn-outline-blue d-flex align-items-center">
            Dodaj nowy
          </Button>
        </Link>
      </div>
      <div className="row justify-content-between g-3 align-items-center">
        <form
          className="d-flex align-items-center"
          onSubmit={handleSubmit((data) => {
            searchParams.set("category", data.category);
            setSearchParams(searchParams);
          })}
        >
          <AsyncSelectLoading
            wrapperClasses="col me-3"
            labelClasses=""
            data={pagesCategoryData}
            placeholder="Wszystkie kategorie"
            setValue={setValue}
            name="category"
            asyncQuery={useLazyGetPagesCategoryQuery}
          />

          <div className="col-3 col-lg-2">
            <Button
              className="btn-outline-blue w-100"
              type="submit"
              value="Submit"
            >
              Przefiltruj
            </Button>
          </div>
        </form>
        <form
          className="d-flex align-items-center"
          onSubmit={handleSubmit((data) => {
            if (data.nameLike) {
              searchParams.set("page", "1");
              setCurrPage(1);
            }
            searchParams.set("nameLike", data.nameLike);
            setSearchParams(searchParams);
          })}
        >
          <div className="col me-3">
            <input
              type="text"
              className="form-control"
              {...register("nameLike")}
              placeholder="Nazwa"
            />
          </div>
          <div className="col-3 col-lg-2">
            <Button
              className="btn-outline-blue w-100"
              type="submit"
              value="Submit"
            >
              Szukaj
            </Button>
          </div>
        </form>
        <form
          className="d-flex align-items-center"
          onSubmit={handleSubmit((data) => {
            if (data.codeLike) {
              searchParams.set("page", "1");
              setCurrPage(1);
            }
            searchParams.set("code", data.codeLike);
            setSearchParams(searchParams);
          })}
        >
          <div className="col me-3">
            <input
              type="text"
              className="form-control"
              {...register("codeLike")}
              placeholder="Kod"
            />
          </div>
          <div className="col-3 col-lg-2">
            <Button
              className="btn-outline-blue w-100"
              type="submit"
              value="Submit"
            >
              Szukaj
            </Button>
          </div>
        </form>
      </div>

      <CancersTable data={cancersData?.data} />
      <CustomPagination
        totalCount={
          (cancersData && cancersData?.meta?.totalResults) ||
          INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
        }
        pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
        currentPage={currPage}
        onPageChange={(page: number) => handlePageChange(page)}
      />

      <div className="text-dark-blue mt-5 fs-2">Index</div>
      <div className={"col-12 col-lg-4"}>
        {countriesOptions && (
          <ComponentWithDescription
            className="my-2 text-dark-blue"
            label="kraj"
          >
            <Select
              value={countriesOptions.find(
                //eslint-disable-next-line
                (selectOption: ReactSelectOption) => {
                  if (searchParams.get("countryId")) {
                    return selectOption.value === searchParams.get("countryId");
                  }
                }
              )}
              classNamePrefix="select"
              placeholder="Wybierz"
              isClearable={true}
              isSearchable={true}
              id="roleId"
              options={countriesOptions}
              onChange={(option: SingleValue<ReactSelectOption>) => {
                searchParams.set(
                  "countryId",
                  option?.value && option !== null ? option?.value : ""
                );
                setSearchParams(searchParams);
              }}
              styles={customStyles(false)}
              components={{ DropdownIndicator }}
            />
          </ComponentWithDescription>
        )}
      </div>
      <IndexTable data={indexData?.data} />
      <div className={"d-flex justify-content-between"}>
        <CustomPagination
          totalCount={
            indexData?.meta?.totalResults ||
            INITIAL_SEARCH_PARAMS_INDEX.TOTAL_RESULTS
          }
          pageSize={INITIAL_SEARCH_PARAMS_INDEX.LIMIT}
          currentPage={currPageIndex}
          onPageChange={handlePageIndexChange}
        />
        <div>
          <Button
            className="btn-outline-blue"
            onClick={() => setShowModal((prevState) => !prevState)}
          >
            Wgraj plik
          </Button>
        </div>
        <CancerDateModal
          show={showModal}
          handleClose={() => setShowModal((prevState) => !prevState)}
          headerTitle={"Dodaj nowe statystyki"}
        />
      </div>
    </Container>
  );
};

export default CancersTemplate;
