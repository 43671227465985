import { Badge, Button, Form, Row, Stack } from "react-bootstrap";
import SortButton from "../SortButton/SortButton";
import CustomPagination from "../CustomPagination/CustomPagination";
import React, { useEffect, useState } from "react";
import { ReactComponent as Bell } from "../../../assets/Icons/Bellv2.svg";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import { useSearchParams } from "react-router-dom";
import { ChangesStatus } from "../../molecules/ChangesStatus/ChangesStatus";
import Select from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { useGetSystemsQuery } from "../../../redux/services/systemsApi";
import styles from "./ChangesAcceptedContentAccordion.module.scss";

interface Props {
  nameLabel: string;
  type: AcceptableChanges;
  link?: string;
  data: any;
  statusOptions: OptionsChanges[];
  object: any;
  acceptedList: number[];
  handlePatchReview: (
    type: AcceptableChanges,
    id: number | string,
    data: DoctorReviewPatchData,
  ) => void;
  handleReFetch: (type: AcceptableChanges) => void;
  handlePage: (pageNumber: number, type: AcceptableChanges) => void;
  handleChangeObject: (
    newObj: AcceptedObjSend,
    type: AcceptableChanges,
  ) => void;
  sortedArray?: SortInterface[];
}

const optionsLimit = [
  { value: 5, label: "5", type: "limit" },
  { value: 10, label: "10", type: "limit" },
  { value: 20, label: "20", type: "limit" },
  { value: 50, label: "50", type: "limit" },
  { value: 100, label: "100", type: "limit" },
];

export const ChangesAcceptedContentAccordion = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(
    !!searchParams.get(`${props.type}Action`) || false,
  );
  const [objForm, setObjForm] = useState<any>(props.object);
  const [shouldExecute, setShouldExecute] = useState(true);

  const { data, isLoading } = useGetSystemsQuery({});

  const systemOptions = data?.data
    .filter((value: any) => value.name !== null)
    .map((value: any) => ({
      value: value.id,
      label: value.name,
      type: "system",
    }));

  useEffect(() => {
    myFunction();
    // eslint-disable-next-line
  }, [shouldExecute]);

  useEffect(() => {
    if (
      props.data?.data?.length === props.acceptedList.length &&
      (props.data?.meta?.totalResults || props.data?.meta?.count)
    ) {
      setShouldExecute(true);
    }
    // eslint-disable-next-line
  }, [props.acceptedList]);
  const myFunction = () => {
    if (shouldExecute) {
      props.handleReFetch(props.type);
      setShouldExecute(false);
    }
  };

  const handleSort = (orderBy: OrderByChanges) => {
    const [order, sort] = objForm.sortBy.split(",");
    if (order === orderBy) {
      const newObjForm = {
        ...objForm,
        sortBy: sort === "ASC" ? `${orderBy},DESC` : `${orderBy},ASC`,
      };
      setObjForm(newObjForm);
      props.handleChangeObject(newObjForm, props.type);
      setShouldExecute(true);
    } else {
      const newObjForm = {
        ...objForm,
        sortBy: `${orderBy},ASC`,
      };
      setObjForm(newObjForm);
      props.handleChangeObject(newObjForm, props.type);
      setShouldExecute(true);
    }
  };

  const handleSelect = (e: any) => {
    switch (e.type) {
      case "system":
        setObjForm({ ...objForm, systemId: e.value });
        break;
      case "limit":
        setObjForm({ ...objForm, limit: e.value });
        break;
      case "status":
        setObjForm({ ...objForm, status: e.value });
        break;
    }
  };

  const handleNameLike = (e: any) => {
    setObjForm({ ...objForm, nameLike: e.target.value });
  };

  const handleAction = () => {
    setIsActive((prev: boolean) => !prev);
    searchParams.set(`${props.type}Action`, isActive ? "" : "1");
    setSearchParams(searchParams);
  };

  const handlePatchDataDoctorReview = (
    id: number | string,
    data: DoctorReviewPatchData,
  ) => {
    props.handlePatchReview(props.type, id, data);
  };

  const handlePage = (e: any) => {
    props.handlePage(e, props.type);
    setShouldExecute(true);
  };

  return (
    <Row className={`${styles.wrapper}`}>
      <div className={"d-flex justify-content-between"}>
        <div className={"fs-22 fw-600 text-dark-blue"}>{props.nameLabel}</div>
        <div className={"d-flex align-items-center"}>
          <Badge
            className={`${
              props.data?.meta?.totalResults || props.data?.meta?.count
                ? "bg-danger"
                : "bg-transparent text-grey-2"
            } d-flex px-2 mx-3`}
          >
            <div className={`me-2 ${styles.bell}`}>
              <Bell className={"h-100 w-100"} />
            </div>
            {props.data?.meta?.totalResults || props.data?.meta?.count || 0}
          </Badge>
          <Button
            style={{ width: 38, height: 38 }}
            variant={isActive ? "dark" : "transparent"}
            className={`d-flex justify-content-center align-items-center p-0 m-0 ${
              isActive ? "text-light" : "text-dark-blue bg-transparent border-0"
            }`}
            onClick={handleAction}
          >
            <Arrow
              style={{
                transition: "transform 200ms",
                transform: isActive ? "rotate(-90deg)" : "rotate(90deg)",
              }}
            />
          </Button>
        </div>
      </div>
      {isActive && (
        <Stack id={`main-${props.type}`} className={"mt-5 mb-3"} gap={4}>
          {props.object.hasOwnProperty("nameLike") && (
            <div className={"col-12"}>
              <label className={"fs-16 pb-1 text-dark-blue"}>Wyszukaj</label>
              <Form.Control
                type="text"
                id="nameLike"
                aria-describedby="nipProvider"
                className={`text-dark-blue`}
                onChange={handleNameLike}
              />
            </div>
          )}
          <div className={"d-flex flex-column flex-lg-row gap-3"}>
            <div className={"col-12 col-lg-4"}>
              <label className={"fs-16 pb-1 text-dark-blue"}>
                Status opinii
              </label>
              <Select
                required={true}
                value={props.statusOptions.find(
                  (option: OptionsChanges) => option.value === objForm?.status,
                )}
                classNamePrefix="select_"
                isClearable={false}
                isSearchable={false}
                id="status"
                options={props.statusOptions}
                onChange={handleSelect}
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            </div>
            <div className={"col-12 col-lg-2"}>
              <label className={"fs-16 pb-1 text-dark-blue"}>Pozycji</label>
              <Select
                required={true}
                value={optionsLimit.find(
                  (v) => v.value.toString() === objForm?.limit.toString(),
                )}
                classNamePrefix="select_"
                isClearable={false}
                isSearchable={false}
                id="limit"
                options={optionsLimit}
                onChange={handleSelect}
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            </div>
            {props.object.hasOwnProperty("systemId") && (
              <div className={"col-12 col-lg-2"}>
                <label className={"fs-16 pb-1 text-dark-blue"}>System</label>
                <Select
                  required={true}
                  isDisabled={isLoading}
                  placeholder={"Wybierz"}
                  classNamePrefix="select_"
                  isClearable={false}
                  isSearchable={false}
                  id="systemId"
                  options={systemOptions}
                  onChange={handleSelect}
                  styles={customStyles(false)}
                  components={{ DropdownIndicator }}
                />
              </div>
            )}

            <div className={"d-flex align-items-end w-100"}>
              <Button
                className={"w-100"}
                onClick={() => {
                  props.handleChangeObject(objForm, props.type);
                  setShouldExecute(true);
                }}
              >
                wyszukaj
              </Button>
            </div>
          </div>
          <div className={"d-flex mb-5 flex-column flex-lg-row"}>
            {props.sortedArray?.map((value: SortInterface, index: number) => {
              const sort = objForm.sortBy.split(",");
              return (
                <SortButton
                  key={index}
                  label={value.label}
                  onClick={() => handleSort(value.type)}
                  isArrowRotated={sort[0] === value.type && sort[1] === "ASC"}
                  className={`pt-3 pb-0 pe-3 fs-16 ${
                    sort[0] === value.type ? "text-dark-blue" : ""
                  }`}
                />
              );
            })}
          </div>
          {props.data?.meta?.totalResults || props.data?.meta?.count ? (
            <>
              {props.data?.data
                .filter((value: any) => !props.acceptedList.includes(value.id))
                .map((value: any) => (
                  <ChangesStatus
                    key={value.id}
                    link={props.link}
                    id={value.id}
                    content={value.content}
                    data={value}
                    handlePatchDataDoctorReview={handlePatchDataDoctorReview}
                    type={props.type}
                  />
                ))}
              <div className="mt-3 align-items-end d-flex flex-column gap-3">
                <a href={`#main-${props.type}`}>
                  <Button
                    variant={"primary"}
                    onClick={() => setShouldExecute(true)}
                  >
                    Odśwież
                  </Button>
                </a>
                <CustomPagination
                  totalCount={
                    props.data?.meta?.totalResults ||
                    props.data?.meta?.count ||
                    0
                  }
                  pageSize={props.object.limit}
                  currentPage={props.object.page}
                  onPageChange={handlePage}
                  paginationClassName="text-nowrap"
                />
              </div>
            </>
          ) : (
            <div
              className={`d-flex flex-column text-center py-3 ${styles.emptyData} text-grey`}
            >
              <span className={"mb-4"}>Brak nowych zmian w tej kategorii</span>
              <div>
                <Button
                  variant={"primary"}
                  onClick={() => setShouldExecute(true)}
                >
                  Odśwież
                </Button>
              </div>
            </div>
          )}
        </Stack>
      )}
    </Row>
  );
};
