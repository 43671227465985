import { Table } from "react-bootstrap";
import "./TopicCaseTable.module.scss";
import { EditObj } from "../../../templates/MessagesTemplate/MessagesTemplate";
import { TableRowTopicCaseWithCollapse } from "../../TableRowTopicCaseWithCollapse/TableRowTopicCaseWithCollapse";

export interface TopicCaseData {
  case: string;
  createdAt: string;
  id: number;
  status: number;
  sfSynchro: number;
  updatedAt: string;
}

interface Props {
  data: TopicCaseData[];
  handleEdit: (data: EditObj, now: boolean) => void;
}

export const TopicCaseTable = ({ data, handleEdit }: Props) => {
  return (
    <Table>
      <thead>
        <tr>
          <th className="pb-4 text-dark-blue">
            <span>Nazwa</span>
          </th>
          <th className="pb-4 text-dark-blue">
            <span>Status</span>
          </th>
          <th className="pb-4 text-dark-blue">
            <span>Contact center</span>
          </th>
        </tr>
      </thead>

      <tbody className="tableBody">
        {data?.length > 0 ? (
          data?.map((topicCaseData: TopicCaseData, index: number) => (
            <TableRowTopicCaseWithCollapse
              key={index}
              data={topicCaseData}
              handleEdit={handleEdit}
            />
          ))
        ) : (
          <tr>
            <td colSpan={3} className="text-center">
              Nie znaleziono żadnych stron.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
