import { CardCodes } from "redux/services/cardCodesApi";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetUsersQuery } from "redux/services/usersApi";
import { usePatchCardCodesAssignUserToCardMutation } from "redux/services/cardCodesApi";
import { Collapse, Form, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { useEffect, useState } from "react";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { toast } from "react-toastify";

interface Props {
  open: boolean;
  data: CardCodes;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Options {
  value: string;
  label: string;
}

const QuickEditCardCodes = ({ open, data, setOpen }: Props) => {
  const [usersOptions, setUsersOptions] = useState<Options[]>([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      authId: "",
    },
  });

  const { t } = useTranslation();

  const { data: usersData } = useGetUsersQuery({
    limit: "0",
    deleted: "0",
  });
  const [patchCardCodesAssignUserToCard] =
    usePatchCardCodesAssignUserToCardMutation();

  useEffect(() => {
    const options = usersData?.data?.map(
      ({
        authId,
        name,
        firstName,
        lastName,
      }: {
        authId: string;
        name: string;
        firstName: string;
        lastName: string;
      }) => ({
        value: authId,
        label: name || `${firstName} ${lastName}`,
      })
    );
    setUsersOptions(options);
  }, [usersData?.data]);

  const submitHandler: SubmitHandler<{ authId: string }> = async (
    submitData
  ) => {
    const payload = {
      cardCodeId: data?.number,
      data: {
        authId: submitData?.authId,
      },
    };

    const hasBeenEdited = await patchCardCodesAssignUserToCard(payload);

    if ("data" in hasBeenEdited) {
      const error = hasBeenEdited as TranslationSubmitError;
      if (hasBeenEdited?.data?.error) {
        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_quickEditCardCodes_error_500"));
            break;
          case 400:
            toast.error(t("toast_quickEditCardCodes_error_400"));
            break;
          default:
            toast.error(t("toast_quickEditCardCodes_error"));
            break;
        }
      } else {
        toast.success(t("toast_quickEditCardCodes_success"));
      }
    }
    setOpen(false);
  };

  const formGroupStyles = "mb-2";
  const labelStyles = "mb-1 fs-12 lh-base";
  const inputStyles = "fs-14";

  return (
    <Collapse in={open}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Form.Group className={formGroupStyles}>
          <Form.Label className={labelStyles}>Użytkownik</Form.Label>
          <Controller
            name={"authId"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Pole wymagane",
              },
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                options={usersOptions}
                className={inputStyles}
                ref={ref}
                value={usersOptions?.find(
                  (selectOptions: Options) => selectOptions?.value === value
                )}
                placeholder="Wybierz"
                id="authId"
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value || "")
                }
                styles={customStyles(!!errors.authId?.message)}
                components={{ DropdownIndicator }}
              />
            )}
          />
          {errors.authId && (
            <div className="text-danger">{errors.authId?.message}</div>
          )}
        </Form.Group>
        <div className="d-flex gap-2 mt-4">
          <Button type="submit" value="Submit">
            Aktualizuj
          </Button>
          <Button className="btn-outline-blue" onClick={() => setOpen(false)}>
            Anuluj
          </Button>
        </div>
      </form>
    </Collapse>
  );
};

export default QuickEditCardCodes;
