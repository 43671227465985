/**
 * @param obj
 * @param keys
 *
 * sprawdza czy obiekt posiada klucze z tablicy
 */

export const isKeysExist: (obj: any, klucze: any[]) => (boolean) = (obj: any, keys: any[]) => {
    if (keys.length === 1) {
        return obj.hasOwnProperty(keys[0]);
    }

    for (let i = 0; i < keys.length; i++) {
        if (obj.hasOwnProperty(keys[i])) {
            return true;
        }
    }

    return false;
};

export const countMatchingKeys = (obj: any, keys: any[]): number => {
    let count = 0;

    for (let i = 0; i < keys.length; i++) {
        if (obj.hasOwnProperty(keys[i])) {
            count++;
        }
    }

    return count;
};