import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
import styles from "./CancerDateModal.module.scss";
import { useDropzone } from "react-dropzone";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { useGetCountriesQuery } from "../../../redux/services/countriesApi";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { bytesToMegaOrKiloBytes } from "components/molecules/LogoCreator/LogoCreator";
import { ModalIsLoading } from "../../atoms/ModalIsLoading/ModalIsLoading";
import { toast } from "react-toastify";
import {
  PostSubstanceUploadFileForm,
  usePostSubstanceUploadFileMutation,
} from "../../../redux/services/substanceApi";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  handleClose: () => void;
  headerTitle: string;
}

const CONST_DROPZONE = {
  MAX_FILES: 1,
  MIN_SIZE: 0,
  MAX_SIZE: 41943040,
};

export const CancerDateModal = (props: Props) => {
  const [errorFileInfo, setErrorFileInfo] = useState<string[] | null>(null);
  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PostSubstanceUploadFileForm>({
    defaultValues: {
      file: [],
    },
  });

  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    setValue("file", acceptedFiles);
  };

  const {
    acceptedFiles,
    fileRejections,
    isDragActive,
    open,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: CONST_DROPZONE.MAX_FILES,
    minSize: CONST_DROPZONE.MIN_SIZE,
    maxSize: CONST_DROPZONE.MAX_SIZE,
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    onDrop,
  });
  const [postSubstanceUploadFile, { isLoading }] =
    usePostSubstanceUploadFileMutation();
  const { data: countriesData } = useGetCountriesQuery({});
  const categoryOptions = countriesData?.data.map((item: Country) => ({
    value: item.id,
    label: item.name,
  }));

  const handleDelete = () => {
    setValue("file", []);
    acceptedFiles.splice(0, acceptedFiles.length);
    fileRejections.splice(0, fileRejections.length);
    getRootProps({ onClick: (event) => event.stopPropagation() });
    getInputProps();
    setErrorFileInfo(null);
  };

  const submitHandler: SubmitHandler<PostSubstanceUploadFileForm> = async (
    data
  ) => {
    const hasBeenAdded = await postSubstanceUploadFile(data);
    if ("data" in hasBeenAdded) {
      if (hasBeenAdded.data?.meta?.status === "failure") {
        setErrorFileInfo(hasBeenAdded.data?.data || null);
        toast.error(t("toast_cancerDateModal_file_error"));
      } else if (hasBeenAdded.data?.meta?.status === "success") {
        toast.success(t("toast_cancerDateModal_success"));
        props.handleClose();
      } else {
        switch (hasBeenAdded?.data?.statusCode) {
          case 500:
            toast.error(t("toast_cancerDateModal_error_500"));
            break;
          case 400:
            toast.error(t("toast_cancerDateModal_error_400"));
            break;
          default:
            toast.error(t("toast_cancerDateModal_error"));
            break;
        }
      }
    }
  };

  return (
    <Modal
      className={`p-0 ${styles.modalBody}`}
      show={props.show}
      onHide={props.handleClose}
      centered
      size={"lg"}
      fullscreen={"sm-down"}
    >
      <ModalIsLoading show={isLoading} label={"Wgrywanie pliku"} />
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Modal.Header closeButton className={"border-bottom-0 "}>
          <Col>
            <Modal.Title className="fw-bold text-dark-blue">
              {props.headerTitle}
            </Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <ComponentWithDescription
            className={`my-2 w-100 text-dark-blue mb-3`}
            descFs={`fs-12`}
            label={"Kraj*"}
            labelFs={`fs-16 mb-1 ${
              errors.countryId?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            <Controller
              name={"countryId"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Pole wymagane",
                },
              }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  ref={ref}
                  value={categoryOptions.find(
                    (selectOption: ReactSelectOption) =>
                      selectOption.value === value
                  )}
                  classNamePrefix="select"
                  placeholder="Wybierz"
                  isClearable={true}
                  isSearchable={true}
                  id="roleId"
                  options={categoryOptions}
                  onChange={(option: SingleValue<ReactSelectOption>) =>
                    onChange(option?.value || "")
                  }
                  styles={customStyles(!!errors.countryId?.message)}
                  components={{ DropdownIndicator }}
                />
              )}
            />
            {errors.countryId && (
              <div className={"text-danger"}>{errors.countryId.message}</div>
            )}
          </ComponentWithDescription>
          {watch("file").length > 0 ? (
            <Row className={"w-100 text-dark-blue gap-2 my-2 m-0 p-0"}>
              <div
                className={
                  "d-flex justify-content-between word-wrap text-center fs-22 fw-semibold ls-3 p-0"
                }
              >
                <span>DODANO PLIK:</span>
                <div>
                  <Button onClick={handleDelete}>USUŃ</Button>
                </div>
              </div>
              <span className={"p-0 text-wrap text-break"}>
                Nazwa: {getValues().file?.[0]?.name}
              </span>
              <span className={"p-0 text-wrap text-break"}>
                Type: {getValues().file?.[0]?.type}
              </span>
              <span className={"p-0 text-wrap text-break"}>
                Rozmiar:{" "}
                {bytesToMegaOrKiloBytes(getValues().file?.[0]?.size || 0)}
              </span>
              {errorFileInfo && (
                <Row className={"p-0 text-wrap text-break m-0 p-0"}>
                  <span className={"p-0 fw-semibold"}>Błedy w pliku:</span>
                  {errorFileInfo.map((info: string) => (
                    <span className={"p-0"}>{info}</span>
                  ))}
                </Row>
              )}
            </Row>
          ) : (
            <div className={"d-flex justify-content-center"}>
              <div className={`${styles.dropzoneWrapper}`}>
                <div
                  className={`${styles.label} d-flex flex-column`}
                  {...getRootProps()}
                  data-dragactive={isDragActive}
                >
                  <span>Upuść tutaj pliki, aby je przesłać</span>
                  <span className="fs-13 text-muted my-2">lub</span>
                  <Button variant="outline-primary" onClick={open}>
                    Wybierz plik
                  </Button>
                  <span className="fs-13 text-muted mt-4">
                    Maksymalna wielkość dodawanych plików: 40 MB.
                  </span>
                  <input {...getInputProps()} multiple />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={"border-top-0 "}>
          <Container className="d-flex p-0">
            <Button
              className="btn-outline-dark-blue me-2"
              type="submit"
              value="Submit"
              onClick={props.handleClose}
            >
              ANULUJ
            </Button>
            <Button
              className={`flex-fill ${
                watch("file").length > 0 ? "btn-primary" : "btn-disabled"
              }`}
              disabled={watch("file").length === 0}
              type="submit"
              value="Submit"
            >
              WYŚLIJ PLIK
            </Button>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
