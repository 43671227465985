export function transformJSONArrayObjectsToString(input: ValueField[]): string {
  try {
    const transformedOutput = input.map((itemValueField) => {
      return itemValueField.value;
    });

    return JSON.stringify(transformedOutput);
  } catch (error) {
    return '[""]';
  }
}
