import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SelectState {
  selectValues: Record<string, string | number>;
  doctorId: string | number | null;
  facilityId: string | number | null;
}

const initialState: SelectState = {
  selectValues: {},
  doctorId: null,
  facilityId: null,
};

const selectSlice = createSlice({
  name: "select",
  initialState,
  reducers: {
    updateSelectValue: (
      state,
      action: PayloadAction<{ fieldName: string; value: string | number }>
    ) => {
      if (action.payload.fieldName) {
        state.selectValues[action.payload.fieldName] = action.payload.value;
      }
    },
    updateDoctorId: (state, action: PayloadAction<string | number | null>) => {
      state.doctorId = action.payload;
    },
    updateFacilityId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.facilityId = action.payload;
    },
  },
});

export const { updateSelectValue, updateDoctorId, updateFacilityId } =
  selectSlice.actions;

export default selectSlice.reducer;
