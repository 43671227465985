import { useMediaQuery } from "@mantine/hooks";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

interface TooltipIconProps {
  desc: string;
  children: any;
  placement?: Placement;
}

const TooltipIcon = ({ desc, children, placement }: TooltipIconProps) => {
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  if (desc === "") {
    return children;
  }

  return (
    <OverlayTrigger
      placement={placement ? placement : "top"}
      trigger={["hover", "hover"]}
      overlay={
        <Popover
          id={`popover-positioned-top`}
          className={
            isMobile ? "popoverAlivia mobileTooltip" : "popoverAlivia"
          }
        >
          <Popover.Body className={`fs-14 text-dark-blue`}>{desc}</Popover.Body>
        </Popover>
      }
    >
      <div className={"cursor-pointer d-flex align-items-center"}>
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default TooltipIcon;
