import { convertToSelectLabel } from "Helpers/convertToSelectLabel";
import { UserAvatarAndName } from "components/atoms/UserAvatarAndName/UserAvatarAndName";
import { useEffect, useState, useRef } from "react";
import { Button, Image, Modal, Row, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import {
  useGetFeedTagsQuery,
  useGetFeedTopicsQuery,
  usePatchPostMutation,
  usePostPostsMutation,
} from "redux/services/feedApi";
import { ReactComponent as Attachment } from "../../../assets/Icons/Attachment.svg";
import { ReactComponent as Close } from "../../../assets/Icons/Close.svg";
import { ReactComponent as Tooltip } from "../../../assets/Icons/Tooltip.svg";
import styles from "./AddPostModal.module.scss";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import TooltipIcon from "components/atoms/TooltipIcon/TooltipIcon";
import { toast } from "react-toastify";
import { useDeferredValue } from "react";
import { LinkPreview } from "components/atoms/LinkPreview/LinkPreview";
import { customStyles } from "../../../styles/reactSelectCustomStyles";
import { useTranslation } from "react-i18next";
import { usePostNotificationsMutation } from "../../../redux/services/notificationApi";
import { domain } from "../Landing/Landing";
import { NAV_PATH } from "../Navigation/navigationData";

const MAX_POST_CONTENT_CHARACTERS = 700;

export const AddPostModal = ({
  show,
  setShow,
  user,
  isEdit,
  data,
}: AddPostModalProps) => {
  const [inputImage, setInputImage] = useState<string | null>(
    isEdit && data?.image?.accessUrl ? data?.image?.accessUrl : null
  );
  const [imageFile, setImageFile] = useState<Blob | null>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [tags, setTags] = useState<
    MultiValue<ILabel | FeedCollapseGroupedOption>
  >([]);
  const [topics, setTopics] = useState<MultiValue<ILabel>>([]);
  const [tagsSource, setTagsSource] = useState<ILabel[]>();
  const [topicsSource, setTopicsSource] = useState<ILabel[]>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [charactersLeft, setCharactersLeft] = useState<number>(
    MAX_POST_CONTENT_CHARACTERS
  );

  const { data: allTags, isSuccess: tagsOk } = useGetFeedTagsQuery({});
  const { data: allTopics, isSuccess: topicsOk } = useGetFeedTopicsQuery({});
  const [addNewPost, { data: newPostRes }] = usePostPostsMutation();
  const [postAttachments, { data: attachmentsRes }] =
    usePostAttachmentsMutation();
  const [patchPost, { data: editedPostRes }] = usePatchPostMutation();

  const [notification] = usePostNotificationsMutation();

  const { t } = useTranslation();

  const selectRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
    resetField,
  } = useForm<IFormInputs>({
    defaultValues: {
      postContentText: data?.content,
      postContentImage: null,
      postContentTags: [],
      postContentTopics: [],
    },
  });

  const postContent = watch("postContentText");

  const defferedPostContent = useDeferredValue(postContent);

  const groupedOptions: ILabel[] = [
    {
      label: "Najpopularniejsze",
      options: tagsSource ?? [],
    },
    {
      label: "Wszystkie",
      options: tagsSource ?? [],
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  });

  useEffect(() => {
    if (isEdit) {
      setValue("postContentText", data?.content ?? "");
      if (data?.image && !inputImage) {
        setInputImage(data?.image?.accessUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.content, data?.image, isEdit]);

  useEffect(() => {
    if (allTags && allTopics && tagsOk && topicsOk) {
      setTagsSource(convertToSelectLabel(allTags.data, "name"));
      setTopicsSource(convertToSelectLabel(allTopics.data, "name"));
    }
    if (isEdit && data) {
      setTags(convertToSelectLabel(data.tag, "name"));
      setTopics(convertToSelectLabel(data.topic, "name"));
    }
  }, [allTags, allTopics, tagsOk, topicsOk, data, isEdit]);

  useEffect(() => {
    if (postContent) {
      let result = postContent.replace(/(\n){4,}/g, "\n\n\n");

      setValue("postContentText", result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postContent]);

  const handleFileChange = (e: any) => {
    const selectedFile = e?.target?.files[0];
    clearErrors("postContentImage");

    if (selectedFile) {
      const ACCEPTED_EXTENSIONS = ["png", "jpeg", "jpg"];

      let isFileValid = false;

      const fileSize = selectedFile.size / 1024 / 1024;

      const fileExtension = selectedFile?.name?.substring(
        selectedFile?.name?.lastIndexOf(".") + 1
      );
      const MAX_SIZE = 5;
      if (fileSize > MAX_SIZE) {
        setError("postContentImage", {
          type: "maxSize",
          message: "Plik przekracza maksymalny dopuszczalny rozmiar (5MB)",
        });
        isFileValid = false;
      } else {
        isFileValid = true;
        clearErrors("postContentImage");
      }

      ACCEPTED_EXTENSIONS?.forEach((extension) => {
        if (extension === fileExtension) {
          isFileValid = true;
          clearErrors("postContentImage");
        } else if (!ACCEPTED_EXTENSIONS?.includes(fileExtension)) {
          isFileValid = false;
          setError("postContentImage", {
            message:
              "Plik posiada nieprawidłowe rozszerzenie pliku. Akceptowane rozszerzenia: .png, .jpg.",
          });
        }
      });

      if (isFileValid) {
        setValue("postContentImage", selectedFile);
      }
    }
  };

  const imageWatched: Blob | null = watch("postContentImage");

  useEffect(() => {
    if (!errors.postContentImage) {
      let image = imageWatched;
      setImageFile(image);
      let imageURL = image && URL.createObjectURL(image);
      setInputImage(imageURL);
    }
  }, [imageWatched, errors.postContentImage]);

  const onSubmit: SubmitHandler<IFormInputs> = async () => {
    if (step === 1) {
      setStep(2);
    } else {
      if (tags?.length > 0) {
        if (isEdit) {
          let payload;
          if (inputImage) {
            payload = {
              content: postContent,
              tagsId: tags.map((tag: ILabel) => tag?.id?.toString()),
              topicsId: topics.map((topic: ILabel) => topic?.id?.toString()),
            };
          } else {
            payload = {
              content: postContent,
              tagsId: tags.map((tag: ILabel) => tag?.id?.toString()),
              topicsId: topics.map((topic: ILabel) => topic?.id?.toString()),
              attachmentIds: [],
            };
          }
          const patchPostAction = await patchPost({
            id: data?.id,
            data: payload,
          });

          if ("data" in patchPostAction) {
            if (!patchPostAction?.data?.statusCode) {
              toast.success("Edycja postu przebiegła pomyślnie");
            } else {
              switch (patchPostAction?.data?.statusCode) {
                case 500:
                  toast.error(t("toast_addPostModal_edit_error_500"));
                  break;
                case 400:
                  toast.error(t("toast_addPostModal_edit_error_400"));
                  break;
                default:
                  toast.error(t("toast_addPostModal_edit_error"));
                  break;
              }
            }
          }
        } else {
          const addNewPostAction = await addNewPost({
            data: {
              likesAmount: 0,
              commentsAmount: 0,
              content: postContent ?? "",
              status: 1,
              lastCommentsCache: "",
              urlPreviewCache: "",
              userId: user?.id,
              tagsId: tags.map((tag: ILabel) => tag?.id?.toString()),
              topicsId: topics.map((topic: ILabel) => topic?.id?.toString()),
              attachmentIds: [],
            },
          });
          if ("data" in addNewPostAction) {
            if (!addNewPostAction?.data?.statusCode) {
              toast.success(t("toast_addPostModal_post_success"));
              await notification({
                data: {
                  user: user?.authId,
                  title: t("notification_AddPostModal_newPost_title"),
                  viewSender: 1,
                  type: 3,
                  content: `${t(
                    "notification_AddPostModal_newPost_content"
                  )} "${postContent}"`,
                  url: `${domain}/${NAV_PATH.FEED}/${addNewPostAction?.data?.data?.id}`,
                },
              });
            } else {
              switch (addNewPostAction?.data?.statusCode) {
                case 500:
                  toast.error(t("toast_addPostModal_post_error_500"));
                  break;
                case 429:
                  toast.error(t("toast_addPostModal_post_error_429"));
                  break;
                case 400:
                  toast.error(t("toast_addPostModal_post_error_400"));
                  break;
                default:
                  toast.error(t("toast_addPostModal_post_error"));
                  break;
              }
            }
          }
        }

        if (imageFile) {
          const addPostImage = await postAttachments({
            data: imageFile,
            attachmentType: "feed",
          });

          if ("data" in addPostImage) {
            if (!addPostImage?.data?.statusCode) {
              toast.success("Twój post został opublikowany");
            } else {
              switch (addPostImage?.data?.statusCode) {
                case 500:
                  toast.error(t("toast_addPostModal_post_error_500"));
                  break;
                case 400:
                  toast.error(t("toast_addPostModal_post_error_400"));
                  break;
                default:
                  toast.error(t("toast_addPostModal_post_error"));
                  break;
              }
            }
          }
        }

        setShow && setShow(false);
        setTags([]);
        setTopics([]);
        setInputImage("");
        setImageFile(null);
        setStep(1);
        resetField("postContentText");
        resetField("postContentImage");
        clearErrors("postContentTags");
        clearErrors("postContentTopics");
      } else {
        tags?.length === 0
          ? setError("postContentTags", {
              type: "required",
              message: "Pole jest wymagane",
            })
          : clearErrors("postContentTags");
      }
    }
  };

  useEffect(() => {
    if (step === 1) {
      watch("postContentText")?.length > 0
        ? setIsSubmitDisabled(false)
        : setIsSubmitDisabled(true);
    }

    tags?.length > 0 && clearErrors("postContentTags");
    topics?.length > 0 && clearErrors("postContentTopics");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, topics, watch("postContentText"), step]);

  useEffect(() => {
    if (imageFile && attachmentsRes) {
      patchPost({
        id: !isEdit ? newPostRes?.data?.id : editedPostRes?.data?.id,
        data: { attachmentIds: [attachmentsRes?.data?.id] },
      });
    }
  }, [
    imageFile,
    attachmentsRes,
    newPostRes,
    patchPost,
    editedPostRes?.data?.id,
    isEdit,
  ]);

  useEffect(() => {
    if (watch("postContentText")?.length > 0) {
      let charDifference =
        MAX_POST_CONTENT_CHARACTERS - watch("postContentText")?.length;
      setCharactersLeft(charDifference);
    } else {
      setCharactersLeft(MAX_POST_CONTENT_CHARACTERS);
    }
    // eslint-disable-next-line
  }, [watch("postContentText")]);

  const resetPhoto = () => {
    setInputImage(null);
    setValue("postContentImage", null);
  };

  const SelectCheckboxValue = (
    props: ValueContainerProps<ILabel, true, GroupBase<ILabel>>
  ) => {
    let topicsLength = topics?.length;
    let wordCase = "filtrów";
    if (topicsLength === 1) {
      wordCase = "filtr";
    } else if (topicsLength > 1 && topicsLength < 5) {
      wordCase = "filtry";
    }

    return (
      <components.ValueContainer {...props} className={"col-12"}>
        <span
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          onTouchStart={() => setIsMenuOpen(!isMenuOpen)}
          className={"col-12"}
        >
          Wybrano: {topicsLength} {wordCase}
        </span>
      </components.ValueContainer>
    );
  };

  const CustomDropdownIndicator = (
    props: DropdownIndicatorProps<ILabel, true, GroupBase<ILabel>>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <span
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          onTouchStart={() => setIsMenuOpen(!isMenuOpen)}
          className="col-12"
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </span>
      </components.DropdownIndicator>
    );
  };

  const SelectCheckbox = ({
    children,
    isSelected,
    ...rest
  }: OptionProps<ILabel, true, GroupBase<ILabel>>) => {
    return (
      <components.Option
        {...rest}
        isSelected={isSelected}
        className={`cursor-pointer d-flex flex-wrap ${styles.customOption}`}
      >
        <span
          onClick={() => setIsMenuOpen(false)}
          className={`w-100 d-flex flex-wrap`}
        >
          <Form.Check
            type="checkbox"
            defaultChecked={isSelected}
            checked={isSelected}
            className="col-auto p-0 m-0"
            id="topics-checkbox"
          />
          <span className="mx-4 col-8 col-md-auto">{children}</span>
        </span>
      </components.Option>
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow && setShow(false)}
      centered
      className="p-4"
      size="lg"
    >
      <Modal.Header closeButton className="p-4">
        <Row className="p-0 m-0 col-8">
          <UserAvatarAndName
            className="col-12 mx-0 px-0"
            userName={user?.name}
            userAvatar={user?.avatar?.accessUrl}
          />
        </Row>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {step === 2 ? (
            <>
              <Row className="px-0 mx-0 col-12 d-flex align-items-center flex-wrap">
                <p className="px-0 mx-0 d-flex flex-wrap col-12">
                  Dodaj wątek*
                  <span className="col-auto d-block">
                    <TooltipIcon desc="Dodaj do postu jeden lub kilka wątków, ułatwi to innym użytkownikom dotarcie do ważnych dla nich informacji.">
                      <span className="mx-1">
                        <Tooltip />
                      </span>
                    </TooltipIcon>
                  </span>
                </p>
                <Select
                  value={tags}
                  isMulti
                  className={`w-100 col-12 px-0 mx-0 border-primary`}
                  {...register("postContentTags")}
                  onChange={(value: MultiValue<ILabel>) => setTags(value)}
                  options={groupedOptions}
                  placeholder=""
                  styles={customStyles(false, true)}
                  isClearable={false}
                  noOptionsMessage={() => (
                    <span>Brak tagów o podanej frazie</span>
                  )}
                />
                {errors?.postContentTags && (
                  <p className={`px-0 m-0 fs-12 ${styles.postControlError}`}>
                    {errors?.postContentTags?.message}
                  </p>
                )}
              </Row>
              <Row className="p-0 my-4 mx-0 col-12 d-flex align-items-center justify-content-center">
                <p className="px-0 mx-0 d-flex flex-wrap col-12">
                  Typ nowotworu
                  <span className="col-auto d-block">
                    <TooltipIcon desc="Jeśli Twój post nie dotyczy konkretnego nowotworu, wybierz tag “Wszystkie nowotwory” - ułatwi to odnalezienie postu innym osobom.">
                      <span className="mx-1">
                        <Tooltip />
                      </span>
                    </TooltipIcon>
                  </span>
                </p>
                <div ref={selectRef} className="px-0 mx-0 col-12">
                  <Select
                    value={topics}
                    {...register("postContentTopics")}
                    menuIsOpen={isMenuOpen}
                    placeholder="Wybierz z listy"
                    components={{
                      Option: SelectCheckbox,
                      ValueContainer: SelectCheckboxValue,
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(value: MultiValue<ILabel>) => setTopics(value)}
                    name="postTopic"
                    className="w-100 col-12 px-0 mx-0"
                    isMulti
                    options={topicsSource}
                    noOptionsMessage={() => (
                      <span>Brak tematów o podanej frazie</span>
                    )}
                  />
                  {errors?.postContentTopics && (
                    <p className={`px-0 m-0 fs-12 ${styles.postControlError}`}>
                      {errors?.postContentTopics?.message}
                    </p>
                  )}
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row className="p-0 m-0 col-12">
                <label className={`m-0 p-0 col-12`}>
                  <span>Treść posta*</span>
                  <textarea
                    className={`form-control mt-2 py-3 ${styles.postContentInput}`}
                    maxLength={MAX_POST_CONTENT_CHARACTERS}
                    {...register("postContentText", {
                      required: { value: true, message: "Pole jest wymagane" },
                      maxLength: {
                        value: MAX_POST_CONTENT_CHARACTERS,
                        message: "Przekroczono limit znaków",
                      },
                      pattern: {
                        value: /^(?![\s\n]).*/,
                        message:
                          "Tekst nie może również zaczynać się od spacji lub nowej linii.",
                      },
                    })}
                  ></textarea>
                  {errors?.postContentText && (
                    <p
                      className={`col-12 pb-2 px-0 m-0 fs-12 ${styles.postControlError}`}
                    >
                      {errors?.postContentText?.message}
                    </p>
                  )}
                </label>
                <p className={`m-0 p-0 fs-12`}>
                  Pozostało {charactersLeft} znaków
                </p>
                <label className="col-12 my-3 mx-0 p-1 border rounded-2 border-primary border-2">
                  <div className="col-12 mx-0 px-0 d-flex justify-content-center align-items-center">
                    <Attachment />
                    <span className={`ms-2 text-primary fw-medium`}>
                      DODAJ ZDJĘCIA
                    </span>
                  </div>
                  <input
                    {...register("postContentImage")}
                    type="file"
                    className="d-none"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                  ></input>
                </label>
                {errors?.postContentImage && (
                  <p className="text-warning col-12 px-0 m-0 py-1 fs-12">
                    {errors?.postContentImage?.message}
                  </p>
                )}
              </Row>
              {inputImage ? (
                <Row className="p-0 m-0 col-12 d-flex align-items-center">
                  <div
                    className={`${styles.postContentImageWrapper} col-12 px-0 mx-0 my-3`}
                  >
                    <div className={`${styles.postContentImage}`}>
                      <Button
                        className={`${styles.postContentImageCloseButton} border border-primary border-2 d-flex justify-content-center align-items-center`}
                        onClick={resetPhoto}
                      >
                        <Close />
                      </Button>
                      <Image fluid src={inputImage} alt="uploaded-file"></Image>
                    </div>
                  </div>
                </Row>
              ) : (
                <LinkPreview content={defferedPostContent ?? ""} isAddingPost />
              )}
            </>
          )}
          <Row className="p-0 m-0 col-12 d-flex flex-wrap align-items-center">
            <p className="col-6 col-lg-2 fw-600 px-0 m-0 text-nowrap">
              Krok {step} z 2
            </p>
            {step === 2 && (
              <span className="col-6 col-lg-2 fw-600 px-0 px-lg-2 d-flex justify-content-end">
                <button
                  onClick={() => setStep(1)}
                  className={`fw-medium form-control btn-outline-dark-blue m-0 ${styles.backButton}`}
                >
                  COFNIJ
                </button>
              </span>
            )}
            <span
              className={`${
                step === 2 ? "col-12 mt-3 my-lg-0 col-lg-8" : "col-6 col-lg-10"
              } px-0`}
            >
              <input
                type="submit"
                className={`form-control fw-medium bg-primary border-0 text-white ${styles.formSubmit}`}
                value={
                  step === 1 ? "DALEJ" : !isEdit ? "OPUBLIKUJ" : "EDYTUJ POST"
                }
                disabled={isSubmitDisabled}
              />
            </span>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
