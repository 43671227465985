import "./CheckboxInput.scss";

const CheckboxInput = ({
  label,
  register,
  disabled,
  checked,
  className,
}: CheckboxInputProps) => {
  return (
    <div className={`form-check form-check-inline  ${className} `}>
      <label className="form-check-label ">
        <input
          className="form-check-input custom-checkbox-pink"
          type="checkbox"
          name="inlineRadioOptions"
          disabled={disabled}
          onChange={() => {}}
          checked={checked}
          {...register}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default CheckboxInput;
