import { Button, Container, Row, Spinner } from "react-bootstrap";
import { useGetLoyaltyProgramsServiceAgreementsQuery } from "../../../redux/services/loyaltyProgramApi";
import React, { useEffect, useState } from "react";
import { AgreementModal } from "../AgreementModal/AgreementModal";
import styles from "./Agreement.module.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { isAuthPermission } from "../../../Helpers/Roles/isAuthPermission";
interface Props {
  serviceId?: string;
  programId?: string;
  typesAgreement?: "sponsor" | "user";
}

export interface loyaltyProgramAgreement {
  id: number;
  type: "sponsor" | "user";
  status: number;
  name: string;
  description: string;
  version: string;
  loyaltyProgram: {
    id: number;
    name: string;
    status: number;
  };
}

export const Agreement = (props: Props) => {
  const { t } = useTranslation();
  const { authUser } = useSelector((state: RootState) => state.user);
  const [showAgreement, setShowAgreement] = useState<number>(0);

  const {
    data: loyaltyProgramsData,
    isLoading,
    isError,
  } = useGetLoyaltyProgramsServiceAgreementsQuery({
    serviceId: props.serviceId,
    programId: props.programId,
    typesAgreement: props.typesAgreement,
  });

  useEffect(() => {
    if (isError) {
      toast.error(t("toast_agreement_error"));
    }
    // eslint-disable-next-line
  }, [isError]);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (isError || !isAuthPermission("loyalty-program", authUser)) {
    return <></>;
  }

  return (
    !loyaltyProgramsData?.meta?.accepted &&
    loyaltyProgramsData?.data?.map(
      (value: loyaltyProgramAgreement, index: number) => {
        return (
          <Row
            key={index}
            className={`border border-2 border-secondary p-4 text-secondary ${styles.body}`}
          >
            <span className={"fw-bolder fs-22 pb-3"}>
              {t(
                `company_agreement_alert_title_${value.type}_${value.loyaltyProgram.id}`,
              )
                .replace(/%agreementName%/g, value.name)
                .replace(/%agreementVersion%/g, value.version)}
            </span>
            <div
              className={"fs-19 pb-3"}
              dangerouslySetInnerHTML={{
                __html: `<div>${t(
                  `company_agreement_alert_info_${value.type}_${value.loyaltyProgram.id}`,
                )
                  .replace(/%agreementName%/g, value.name)
                  .replace(/%agreementVersion%/g, value.version)}</div>`,
              }}
            />
            <div className={"d-flex justify-content-end"}>
              <Button
                className={"text-white"}
                variant={"secondary"}
                onClick={() => {
                  setShowAgreement((prev) => (prev === 0 ? value.id : 0));
                }}
              >
                {t("company_agreement_modal_open")}
              </Button>
            </div>
            <AgreementModal
              show={showAgreement === value.id}
              handleClose={() => setShowAgreement(0)}
              data={value}
              typesAgreement={props.typesAgreement ?? "user"}
            />
          </Row>
        );
      },
    )
  );
};
