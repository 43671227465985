import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders(headers) {
      // fixme: poprawić po testach
      // const token = process.env.REACT_APP_TOKEN;
      // headers.set("Authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
});
