import { Button, Col, Container, Row } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import styles from "../WidgetCreator/WidgetCreator.module.scss";
import { useEffect, useState } from "react";

interface Props {
  nameLabel: string;
  buttonName?: string;
  buttonIcon: "plus" | "pencil" | null;
  data: any;
  condition?: boolean | null;
  Child: any;
  reFetchCompany?: any;
  disableBtn?: boolean;
  info?: string | false;
  isReadOnly?: boolean;
}

export const WidgetCreator = ({
  nameLabel,
  buttonName,
  buttonIcon,
  data,
  Child,
  condition,
  reFetchCompany,
  disableBtn,
  info,
  isReadOnly,
}: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isNewBenefit, setIsNewBenefit] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(false);
    if (condition === null) {
      setIsFirst(true);
      setIsNewBenefit(true);
    } else {
      setIsFirst(false);
    }
    // eslint-disable-next-line
  }, [data, Child]);

  const handleActive = (status: boolean) => {
    setIsActive(status);
  };
  const handleFirst = (status: boolean) => {
    setIsFirst(status);
  };

  const handleClick = () => {
    setIsActive((prev: boolean) => !prev);
    setIsNewBenefit((prev) => !prev);
  };

  return (
    <>
      <Col
        className={
          "fs-22 fw-semibold mb-4 text-dark-blue mx-0 d-flex justify-content-between"
        }
      >
        <p className={`fs-22 mb-4 ${styles.nameLabel}`}>{nameLabel}</p>
        {buttonIcon !== null &&
          condition !== null &&
          !isReadOnly &&
          !isEdit && (
            <Button
              disabled={disableBtn || isReadOnly}
              style={{ width: 38, height: 38 }}
              variant={isActive ? "dark" : "transparent"}
              className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                isActive
                  ? "text-light"
                  : "text-dark-blue bg-transparent border-0"
              }`}
              onClick={handleClick}
              // onClick={() => setIsBodyOpen(true)}
            >
              {buttonIcon === "plus" && <PlusIcon />}
              {buttonIcon === "pencil" && <Pencil />}
            </Button>
          )}
      </Col>
      {isFirst && info && (
        <Row className={"pb-2 text-dark-blue"}>
          <p>{info}</p>
        </Row>
      )}
      {isFirst ? (
        <Row className={"mt-3 mx-0"}>
          <Container
            fluid={true}
            className="p-0 m-0 d-flex flex-column justify-content-between align-items-lg-end gap-4"
          >
            <Button
              className={`${
                disableBtn || isReadOnly ? "btn-disabled" : "btn-primary"
              } w-100`}
              // variant=""
              type={"submit"}
              value="Submit"
              onClick={() => {
                setIsActive(true);
                setIsFirst(false);
              }}
              disabled={disableBtn || isReadOnly}
            >
              {buttonName}
            </Button>
          </Container>
        </Row>
      ) : (
        <Row className={"mb-4 mx-0"}>
          <Container
            fluid={true}
            className="p-0 m-0 d-flex flex-column justify-content-between align-items-lg-end gap-4 text-dark-blue"
          >
            <Child
              isActive={isActive}
              handleActive={handleActive}
              handleFirst={handleFirst}
              data={data}
              isFirst={condition === null}
              reFetchCompany={reFetchCompany}
              isReadOnly={isReadOnly}
              isNewBenefit={isNewBenefit}
              setIsNewBenefit={setIsNewBenefit}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </Container>
        </Row>
      )}
    </>
  );
};
