import { OffcanvasHeader } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LeftArrow from "components/atoms/LeftArrow/LeftArrow";
import styles from "./ChatHeader.module.scss";
import {
  setChatData,
  setShowMessages,
  setView,
  View,
} from "redux/Slice/messages-slice";
import { Link } from "react-router-dom";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface ChatHeaderProps {
  avatarSrc: string;
  otherUser: ChatUser;
  isOnMessenger?: boolean;
}

export default function ChatHeader({
  avatarSrc,
  otherUser,
  isOnMessenger,
}: ChatHeaderProps) {
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(setView(View.MAIN));
    dispatch(setChatData(null));
  };

  const handleProfileClick = () => {
    dispatch(setShowMessages(false));
  };

  const content = (
    <>
      <p className="m-0">Do:</p>
      <img
        src={avatarSrc}
        alt="Awatar użytkownika"
        className={`ms-12 me-12 ${styles.avatarImg}`}
      />
      <p className="m-0">{otherUser?.name}</p>
    </>
  );

  return (
    <OffcanvasHeader
      className={`p-3 p-md-4 d-flex flex-shrink-0 ${styles.header}`}
    >
      {!isOnMessenger && (
        <div>
          <button onClick={handleBackClick} className={`${styles.backBtn}`}>
            <LeftArrow />
          </button>
        </div>
      )}
      {otherUser?.id ? (
        <Link
          className="ms-12 p-0 col-12 text-dark fs-16 lh-150 d-flex"
          to={`/${NAV_PATH.PROFILE}/${otherUser?.authId}`}
          onClick={handleProfileClick}
        >
          {content}
        </Link>
      ) : (
        <div className="ms-12 p-0 col-12 text-dark fs-16 lh-150 d-flex">
          {content}
        </div>
      )}
    </OffcanvasHeader>
  );
}
