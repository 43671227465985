import { Chat } from "components/molecules/Chat/Chat";
import { Stack, Row } from "react-bootstrap";

import styles from "./ChatArchive.module.scss";

type ChatArchiveProps = {
  messages: ChatData[];
  isOnMessenger?: boolean;
};

const ChatArchive = ({ messages, isOnMessenger }: ChatArchiveProps) => {
  return (
    <>
      {messages?.length > 0 && (
        <>
          <Row
            className={`col-12 m-0 d-flex justify-content-center align-items-center mb-4 mt-4 ${styles.fitContent}`}
          >
            <span
              className={`${styles.readIndicator} text-center w-100 col-12`}
            >
              <span className={`${styles.readIndicatorText}`}>Archiwum</span>
              <span className={`${styles.readIndicatorLine}`} />
            </span>
          </Row>
          <Stack
            gap={2}
            className={`d-flex px-0 mx-0 mb-4 ${styles.fitContent}`}
          >
            {messages?.map((message: ChatData, index: number) => (
              <Chat data={message} key={index} isOnMessenger={isOnMessenger} />
            ))}
          </Stack>
        </>
      )}
    </>
  );
};
export default ChatArchive;
