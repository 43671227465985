import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import { CancelModal } from "components/molecules/CancelModal/CancelModal";
import PollForm from "components/organisms/PollForm/PollForm";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import PollPages from "components/organisms/PollPages/PollPages";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  usePostPollMutation,
  useUpdateWholePollMutation,
} from "redux/services/pollsApi";
import {
  createPollMetaArray,
  isSfSynchroAvailable,
} from "Helpers/pollMetaHandlers";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface PollSendingTemplateProps {
  data: PollSendingData;
  action: "POST" | "PATCH";
}

const PollSendingTemplate = ({ data, action }: PollSendingTemplateProps) => {
  const navigate = useNavigate();
  const linkParams = useParams();

  const [postPoll] = usePostPollMutation();
  const [updatePoll] = useUpdateWholePollMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [visibleForm, setVisibleForm] = useState<string>("poll");

  const { t } = useTranslation();

  const methods = useForm<PollSendingData>({
    defaultValues: data,
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const pagesFieldArray = useFieldArray({
    control: methods.control,
    name: "pages",
  });

  const hideModalHandler = () => setShowModal(false);
  const showModalHandler = () => setShowModal(true);

  const onSubmit = async (data: PollPageFormValues) => {
    if (visibleForm === "poll") {
      setVisibleForm("poll_pages");
      return;
    }

    const pollPostData: PollPostData = {
      title: data.title,
      description: data.description,
      verificationType: data.verificationType,
      adminVerification: +data.adminVerification,
      isReusable: +data.isReusable,
      isActive: +data.isActive,
      sfConnection: +data.sfConnection,
      languageId: +data.languageId,
      userRoleId: +data.userRoleId,
      pages: data.pages.map((page, pageIndex) => ({
        lp: pageIndex + 1,
        title: page.title,
        description: page.description,
        isActive: +page.isActive,
        questions: page.questions.map((question, questionIndex) => {
          let sfSynchro: number = 0;
          let fieldName: string = `field_${uuidv4()}`;
          if (isSfSynchroAvailable(question.fieldType) && question.sfSynchro) {
            sfSynchro = 1;
            fieldName = question.fieldName;
          }

          return {
            lp: questionIndex + 1,
            question: question.question,
            description: question.description,
            sfSynchro,
            type: question.type,
            target: question.target,
            fieldName,
            fieldType: question.fieldType,
            isActive: +question.isActive,
            meta: createPollMetaArray(question),
          };
        }),
      })),
    };

    const payload: PollPayload = {
      data: pollPostData,
    };

    const hasBeenSent =
      action === "POST"
        ? await postPoll(payload)
        : await updatePoll({ ...payload, id: linkParams?.id });

    if ("data" in hasBeenSent) {
      if (!hasBeenSent?.data?.statusCode) {
        action === "POST"
          ? toast.success(t("toast_pollSendingTemplate_add_success"))
          : toast.success(t("toast_pollSendingTemplate_update_success"));
        navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.POLLS}`, { replace: true });
      } else {
        switch (hasBeenSent?.data?.statusCode) {
          case 500:
            toast.error(t("toast_pollSendingTemplate_error_500"));
            break;
          case 404:
            toast.error(t("toast_pollSendingTemplate_error_404"));
            break;
          case 400:
            toast.error(
              `${t("toast_pollSendingTemplate_error_400")} ${
                hasBeenSent?.data?.message
              }`
            );
            break;
          default:
            toast.error(t("toast_pollSendingTemplate_edit_error"));
            break;
        }
      }
    }
  };

  const onInvalid = (errors: any) => {
    if (visibleForm === "poll_pages" && errors?.pages) {
      const messages = errors?.pages?.reduce(
        (acc: string[], page: any, pageIndex: number) => {
          const questionsWithErrors = page.questions
            ?.map(
              (question: any, questionIndex: number) => `${questionIndex + 1}`
            )
            .filter((question: any) => question !== "");

          const message = ` strona ${pageIndex + 1} ${
            questionsWithErrors?.length > 1 ? "pytania" : ""
          }${questionsWithErrors?.length === 1 ? "pytanie" : ""} ${
            questionsWithErrors?.length === 0 ? "" : questionsWithErrors ?? ""
          }`;

          return [...acc, message];
        },
        []
      );

      toast.error(`${t("toast_pollSendingTemplate_error")} ${messages}`);
    }
  };

  return (
    <>
      <Container as="section" fluid className="px-4 pt-2 bg-light">
        <Form onSubmit={methods.handleSubmit(onSubmit, onInvalid)}>
          <FormProvider {...methods}>
            <div className="d-flex align-items-center">
              <SectionTitle className="me-4">Ankiety</SectionTitle>
              <Button
                className="d-flex justify-content-center align-items-center"
                type="button"
                onClick={showModalHandler}
              >
                <span className="text-light lh-base">Panel administratora</span>
              </Button>
            </div>
            {visibleForm === "poll" && <PollForm />}
            {visibleForm === "poll_pages" && (
              <PollPages
                pagesController={pagesFieldArray}
                setVisibleForm={setVisibleForm}
              />
            )}
          </FormProvider>
        </Form>
      </Container>

      <CancelModal
        title="Komunikat"
        content="Czy na pewno chcesz przerwać tworzenie ankiety?"
        cancelText="Nie"
        confirmText="Tak"
        show={showModal}
        onHide={hideModalHandler}
        onConfirm={() =>
          navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.POLLS}`, { replace: true })
        }
      />
    </>
  );
};

export default PollSendingTemplate;
