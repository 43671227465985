import { WidgetSetting } from "redux/Slice/user-slice";

export const widgetsSettingsDefault: WidgetSetting[] = [
  {
    name: "Onkozbiórka",
    position: 14,
    isActive: true,
    id: 1,
    forRole: "patient",
  },
  {
    name: "Ważne informacje",
    position: 1,
    isActive: true,
    id: 2,
    forRole: "patient",
  },
  {
    name: "Panel podopiecznych",
    position: 4,
    isActive: true,
    id: 3,
    forRole: "guardian",
  },
  {
    name: "Panel opiekuna",
    position: 5,
    isActive: true,
    id: 4,
    forRole: "patient",
  },
  {
    name: "Onkozbiórki podopiecznych",
    position: 6,
    isActive: true,
    id: 5,
    forRole: "guardian",
  },
  {
    name: "Oceny",
    position: 18,
    isActive: true,
    id: 6,
    forRole: "doctor",
  },
  {
    name: "Recenzje doktora",
    position: 17,
    isActive: true,
    id: 7,
    forRole: "doctor",
  },
  {
    name: "Twoje wybrane benefity",
    position: 10,
    isActive: true,
    id: 8,
    forRole: "patient",
  },
  {
    name: "Polecani lekarze",
    position: 8,
    isActive: true,
    id: 9,
    forRole: "patient",
  },
  {
    name: "Polecane ośrodki leczenia",
    position: 9,
    isActive: true,
    id: 10,
    forRole: "patient",
  },
  {
    name: "Najnowsze benefity",
    position: 3,
    isActive: true,
    id: 11,
    forRole: "patient",
  },
  {
    name: "Najkrótsza kolejka",
    position: 12,
    isActive: true,
    id: 12,
    forRole: "patient",
  },
  {
    name: "Twoje firmy",
    position: 16,
    isActive: true,
    id: 13,
    forRole: "sponsor",
  },
  {
    name: "Źródło ocen i opinii",
    position: 19,
    isActive: true,
    id: 14,
    forRole: "doctor",
  },
  {
    name: "Obserwowane onkozbiórki",
    position: 15,
    isActive: true,
    id: 15,
    forRole: "patient",
  },
  {
    name: "Polecane wydarzenia",
    position: 7,
    isActive: true,
    id: 16,
    forRole: "patient",
  },
  // {
  //   name: "Polecane artykuły",
  //   position: 11,
  //   isActive: true,
  //   id: 17,
  //   forRole: ["patient", "guardian", "doctor", "sponsor"],
  // },
  {
    name: "Leczenie celowane",
    position: 2,
    isActive: true,
    id: 18,
    forRole: "patient",
  },
  {
    name: "Najnowszy post",
    position: 13,
    isActive: true,
    id: 19,
    forRole: "patient",
  },
];
