import { Form } from "react-bootstrap";

interface Props {
  data: {
    name: string;
    value: number | string;
    label: string;
  }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  id: string;
}

const SelectInputForAdmin = ({ data, id, label, onChange }: Props) => {
  const labelStyles = "mb-1 fs-14 lh-base";

  return (
    <Form.Group>
      <Form.Label className={labelStyles} htmlFor={id}>
        {label}
      </Form.Label>
      <Form.Select id={id} onChange={onChange}>
        <option value="">Wszystkie</option>
        {data.map((item) => (
          <option key={item.name} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default SelectInputForAdmin;
