import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./RoleTile.module.scss";
import { useTranslation } from "react-i18next";
import TextInput from "../TextInput/TextInput";
import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
import { useLazyGetDoctorsQuery } from "redux/services/doctorApi";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useGetDoctorDegreesQuery,
  useLazyGetDoctorDegreesQuery,
} from "redux/services/doctorDegreeApi";
import AsyncMultiSelect from "components/molecules/AsyncMultiSelect/AsyncMultiSelect";
import {
  useGetDoctorSpecializationsQuery,
  useLazyGetDoctorSpecializationsQuery,
} from "redux/services/doctorSpecializationApi";
import { useEffectOnce } from "Helpers/useEffectOnce";
import { useRoleImage } from "Helpers/Roles/useRoleImage";
import { customStyles } from "styles/reactSelectCustomStyles";

const UPPER_SNAKE_CASE = 128;

const RoleTile = ({ role, className, setRole, disabled }: RoleTileProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RoleTileData>({
    defaultValues: {
      roleId: role.id,
      role: role,
      telephone: "",
      doctorId: undefined,
      nameLike: "",
      firstName: "",
      lastName: "",
      specializationIds: [],
      degreeId: 0,
    },
  });
  const roleImage = useRoleImage(role);
  const { data: doctorsDegrees } = useGetDoctorDegreesQuery({});
  const { data: doctorsSpecializationsData } = useGetDoctorSpecializationsQuery(
    {}
  );
  const [lazyGetDoctorsDegrees] = useLazyGetDoctorDegreesQuery();
  const [lazyGetDoctors, { data: lazyDoctorsData }] = useLazyGetDoctorsQuery();
  const [showDoctorModal, setShowDoctorModal] = useState(false);
  // eslint-disable-next-line
  const [sendData, setSendData] = useState({});

  const [step, setStep] = useState(1);
  const [error, setError] = useState<string>();
  const [showNewDoctorForm, setShowNewDoctorForm] = useState(false);
  const { t } = useTranslation();
  const [isOnHover, setIsOnHover] = useState(false);
  const submitHandler: SubmitHandler<RoleTileData> = async (data) => {
    if (data?.role?.role?.toString() === "doctor") {
      if (step === 1) {
        if (!showDoctorModal) {
          setShowDoctorModal(true);
        } else {
          if (data.telephone.length === 0) {
            setError("Pole wymagane");
          } else {
            setStep(step + 1);
          }
        }
      } else {
        if (!data?.doctorId) {
          setShowNewDoctorForm(true);
        }
        if (!data?.doctorId && showNewDoctorForm) {
          setSendData(data);
          setStep(1);
          setShowDoctorModal && setShowDoctorModal(false);
          setRole && setRole(data);
          reset();
        }
        if (data?.doctorId) {
          setSendData(data);
          setStep(1);
          setShowDoctorModal && setShowDoctorModal(false);
          setRole && setRole(data);
          reset();
        }
      }
    } else {
      setRole && setRole(data);
      reset();
    }
  };

  const cancelDoctorForm = () => {
    setShowDoctorModal(false);
    setStep(1);
    reset();
  };

  useEffectOnce(() => {
    lazyGetDoctors({ sortBy: "name,ASC" });
  });

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div
        className={`p-4 d-flex justify-content-center align-items-center ${className} ${styles.RoleTileContainer} border border-gray`}
        onMouseEnter={() => setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
      >
        <div className="container">
          {isOnHover ? (
            <div className="d-flex justify-content-between flex-column">
              <p className="text-center text-dark-blue">
                {t("moja_roles_" + role.role + "_short")}
              </p>
              <Button
                variant="primary"
                className="w-100"
                type="submit"
                disabled={disabled}
              >
                WYBIERZ
              </Button>
            </div>
          ) : (
            <>
              <div className="row">
                       {roleImage()}

              </div>
              <div className="row">
                <p className="fw-600 fs-19 text-dark-blue text-center mt-3">
                  {role.name}
                </p>
              </div>
            </>
          )}
        </div>
        <Modal
          size="xl"
          show={showDoctorModal}
          onHide={cancelDoctorForm}
          centered
          className="ps-0"
        >
          <Modal.Header closeButton className="p-4 border-0">
            <Modal.Title className="fw-600 text-dark-blue">
              Uzupełnij dane
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            {step === 1 ? (
              <>
                <p className="text-dark-blue">Twój numer telefonu jest niezbędny, abyśmy mogli zweryfikować Twoje konto.</p>
                <p className="fw-600 text-grey-2 my-4 fs-12">Dane kontaktowe</p>
                <TextInput
                  type="EventInput"
                  register={register("telephone", {
                    maxLength: { value: 9, message: "Maksymalnie 9 znaków" },
                    onChange: () => setError(""),
                    pattern: {
                      value: /^\d{9}$/,
                      message: "Podaj numer w formacie XXXXXXXXX",
                    },
                  })}
                  className="flex-column w-100 text-dark-blue"
                  label="Numer telefonu"
                  error={errors?.telephone?.message || error}
                />
              </>
            ) : (
              <>
                {!showNewDoctorForm ? (
                  <>
                    <p className="text-dark-blue">
                      Sprawdź, czy jesteś już w bazie naszych lekarzy - aby to
                      zrobić, podaj swoje imię i nazwisko.
                    </p>
                    <AsyncSelectLoading
                      data={lazyDoctorsData}
                      placeholder="Wpisz imię i nazwisko"
                      setValue={setValue}
                      name="doctorId"
                      width={"100%"}
                      asyncQuery={lazyGetDoctors}
                      styles={customStyles(false)}
                      label="Imię i nazwisko lekarza"
                      noOptionsComponent={
                        <p className="p-0 m-0 fs-16 text-dark-blue">
                          Brak dopasowania.{" "}
                          <Button
                            variant="link-primary"
                            className="text-primary fs-16"
                            onClick={() => setShowNewDoctorForm(true)}
                          >
                            Dodaj nowy profil lekarza.
                          </Button>
                        </p>
                      }
                    />
                  </>
                ) : (
                  <>
                    <p className="text-dark-blue">
                      Podaj podstawowe dane identyfikujące Cię, jako lekarza
                    </p>
                    <AsyncSelectLoading
                      data={doctorsDegrees}
                      placeholder=""
                      setValue={setValue}
                      name="degreeId"
                      width={"100%"}
                      asyncQuery={lazyGetDoctorsDegrees}
                      label="Twój tytuł medyczny"
                    />
                    <div className="d-flex flex-row col-12 mt-4 mb-4">
                      <div className="col-12 col-sm-6">
                        <TextInput
                          type="EventInput"
                          register={register("firstName", {
                            maxLength: {
                              value: UPPER_SNAKE_CASE,
                              message: `Maksymalnie ${UPPER_SNAKE_CASE} znaków`,
                            },
                            required: { value: true, message: "Pole wymagane" },
                          })}
                          className={`flex-column text-dark-blue ${styles.firstAndLastNameMarginRight}`}
                          label="Imię"
                          error={errors?.firstName?.message}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <TextInput
                          type="EventInput"
                          register={register("lastName", {
                            maxLength: {
                              value: UPPER_SNAKE_CASE,
                              message: `Maksymalnie ${UPPER_SNAKE_CASE} znaków`,
                            },
                            required: { value: true, message: "Pole wymagane" },
                          })}
                          className={`flex-column text-dark-blue ${styles.firstAndLastNameMarginLeft}`}
                          label="Nazwisko"
                          error={errors?.lastName?.message}
                        />
                      </div>
                    </div>
                    <AsyncMultiSelect
                      data={doctorsSpecializationsData}
                      setValue={setValue}
                      name="specializationIds"
                      // value={handleNewField(pageData?.data.category)}
                      relativeoptions
                      asyncQuery={useLazyGetDoctorSpecializationsQuery}
                      type="specialization"
                      label="Wybierz specjalności"
                      inputStyles={customStyles(false)}
                    />
                  </>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0 p-4">
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <span className="col-3 col-lg-2 fw-600 px-0 text-dark-blue">
                Krok {step} z 2
              </span>
              <div>
                <Button
                  className="btn-outline-dark-blue me-2"
                  onClick={cancelDoctorForm}
                >
                  ANULUJ
                </Button>
                <Button
                  className="text-uppercase"
                  type="submit"
                  disabled={getValues("doctorId") === null && step === 2}
                  onClick={handleSubmit(submitHandler)}
                >
                  {step === 1 ? "DALEJ" : "Stwórz profil lekarza"}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

export default RoleTile;
