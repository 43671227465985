import ServiceViewTableRow from "components/organisms/ServiceViewTableRow/ServiceViewTableRow";
import { LoyaltyProgramServiceDataInterface } from "components/templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { Col, Row, Table } from "react-bootstrap";

interface Props {
  data: LoyaltyProgramServiceDataInterface[];
}

const ServicesTable = ({ data }: Props) => {
  const thStyles = "container-fluid pb-4 border border-0 text-dark-blue";

  return (
    <Table>
      <thead>
        <tr>
          <th className={thStyles}>
            <Row>
              <Col xs={2}>ID</Col>
              <Col xs={3}>Nazwa</Col>
              <Col xs={4}>Firma</Col>
              <Col xs={2}>Status benefitu</Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((service) => (
          <ServiceViewTableRow key={service?.id} data={service} />
        ))}
      </tbody>
    </Table>
  );
};

export default ServicesTable;
