import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { usePostAttachmentsMutation } from "../../../redux/services/attachmentsApi";
import { toast } from "react-toastify";
import PictureDropzone from "../../atoms/PictureDropzone/PictureDropzone";
import { usePatchLoyaltyProgramMutation } from "../../../redux/services/loyaltyProgramApi";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { useTranslation } from "react-i18next";

interface Props {
  loyaltyProgramId: number;
  show: boolean;
  handleClose: () => void;
  headerTitle: string;
  imageUrl: string;
}

export const LoyaltyProgramImage = (props: Props) => {
  const [file, setFile] = useState<File | null>(null);

  const [postAttachments] = usePostAttachmentsMutation();
  const [patchLoyaltyProgram] = usePatchLoyaltyProgramMutation();

  const { t } = useTranslation();

  const handleSave = async () => {
    const hasBeenAccepted = await postAttachments({
      data: file,
      attachmentType: "loyalty-program-programs",
    });
    if ("data" in hasBeenAccepted) {
      const hasBeenLoyaltyProgram = await patchLoyaltyProgram({
        id: props.loyaltyProgramId.toString(),
        data: {
          imageId: hasBeenAccepted?.data?.data?.id,
        },
      });

      if ("data" in hasBeenLoyaltyProgram) {
        toast.success(t("toast_loyaltyProgramImage_success"));
        props.handleClose();
      } else if ("error" in hasBeenLoyaltyProgram) {
        const error = hasBeenLoyaltyProgram?.error as DoctorFacilityDeleteError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_loyaltyProgramImage_error_500"));

        if (error.data.statusCode === 404)
          toast.error(t("toast_loyaltyProgramImage_error_404"));

        if (error.data.statusCode === 400) {
          toast.error(
            `${t("toast_loyaltyProgramImage_error_400")} ${
              error.data.message[0]
            }`
          );
        }
      }
    } else if ("error" in hasBeenAccepted) {
      const error = hasBeenAccepted?.error as DoctorFacilityDeleteError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_loyaltyProgramImage_error_500"));

      if (error.data.statusCode === 400) {
        toast.error(
          `${t("toast_loyaltyProgramImage_error_400")} ${error.data.message[0]}`
        );
      }
    }
  };

  const handlePicture = async (fileData: File) => {
    setFile(fileData);
  };

  return (
    <Modal
      className={`p-0`}
      show={props.show}
      onHide={props.handleClose}
      centered
      fullscreen={"sm-down"}
      size={"xl"}
    >
      <Modal.Header closeButton className={"border-bottom-0 "}>
        <Col>
          <Modal.Title className="fw-bold text-dark-blue">
            {props.headerTitle}
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <div className={"d-flex justify-content-center"}>
          <ComponentWithDescription
            className="my-2 w-100"
            desc="Preferowane grafiki to 2064x400. Akceptowane formaty plików: jpg, png, tiff."
          >
            <PictureDropzone
              handlePicture={handlePicture}
              imageUrl={props.imageUrl}
            />
          </ComponentWithDescription>
        </div>
      </Modal.Body>
      <Modal.Footer className={"border-top-0 "}>
        <Container className="d-flex justify-content-end p-0 gap-2">
          <Button className="btn-outline-dark-blue" onClick={props.handleClose}>
            ANULUJ
          </Button>
          <Button
            className={` ${file ? "btn-primary" : "btn-disabled"}`}
            disabled={file === null}
            onClick={handleSave}
          >
            AKTUALIZUJ
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
