import React from "react";

const DetailItem = ({ detailHeader, detailValue }: PropsTypes) => {
  return (
    <span>
      <strong>{detailHeader}:</strong> {detailValue}
    </span>
  );
};

export default DetailItem;
