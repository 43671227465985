import { Button, Container, Form } from "react-bootstrap";
import TextInput from "components/atoms/TextInput/TextInput";
import AsyncSelectLoading from "../AsyncSelect/AsyncSelectLoading";
import { usePatchMeMutation } from "redux/services/meApi";
import { SubmitHandler, useForm } from "react-hook-form";
import { MeUser } from "redux/Slice/user-slice";
import { toast } from "react-toastify";
import { useLazyGetCitiesQuery } from "redux/services/citiesApi";
import { useEffectOnce } from "Helpers/useEffectOnce";
import {
  useGetProvincesQuery,
  useLazyGetProvincesQuery,
} from "redux/services/provincesApi";
import { useState } from "react";
import { Pencil } from "react-bootstrap-icons";
import { customStyles } from "styles/reactSelectCustomStyles";
import { useTranslation } from "react-i18next";
import styles from "./ContactInfoSettingsCard.module.scss";

export const ContactInfoSettingsCard = ({
  authUser,
  className,
  setShow,
  show,
}: SettingsCardProps) => {
  const [patchMe] = usePatchMeMutation();
  const [lazyGetCities, { data: citiesData }] = useLazyGetCitiesQuery();
  const { data: provincesData } = useGetProvincesQuery({});
  const [provinceFilter, setProvinceFilter] = useState<number>();

  const { t } = useTranslation();

  const onChange = (value: number) => {
    lazyGetCities({ provinceId: value?.toString() });
    setProvinceFilter(value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<MeUser>({
    defaultValues: {
      ...authUser,
    },
  });

  const submitHandler: SubmitHandler<MeUser> = async (data) => {
     const meUpdated = await patchMe({
      data: { ...data},
    });
    if ("data" in meUpdated) {
      toast.success(t("toast_contactInfoSettingsCard_success"));
    } else if ("error" in meUpdated) {
      const error = meUpdated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_contactInfoSettingsCard_error_500"));

      if (error.data.statusCode === 400) toast.error(error.data.message);
      toast.error(t("toast_contactInfoSettingsCard_error"));
    }

    setShow(false);
  };

  useEffectOnce(() => {
    lazyGetCities({ limit: "50" });
  });

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container fluid className={className}>
        <div className="w-100 p-4">
          <div className="justify-content-between d-flex flex-row align-items-center mb-5">
            <p className={`fw-600 fs-22 text-dark-blue m-0 ${styles.title}`}>
              Dane kontaktowe
            </p>
            {authUser?.status === 1 && (
              <Button
                onClick={() => setShow(!show)}
                className={`btn-alivia ${
                  !show ? "text-dark-blue stroke-dark-blue" : "stroke-white"
                }`}
                variant={show ? "dark" : "link"}
              >
                <Pencil size={20} />
              </Button>
            )}
          </div>
          <div className="d-flex flex-column gap-2 w-100">
            <TextInput
              label="Adres e-mail"
              className="fs-16 fw-400 flex-column w-100 mb-3 text-dark-blue"
              disabled={true}
              type="EventInput"
              register={register("settings.Email", {
                required: { value: true, message: "Pole wymagane" },
                maxLength: {
                  value: 320,
                  message: "Maksymalnie 320 znaków",
                },
              })}
              labelClassName="fw-400 fs-16 text-dark-blue"
              error={errors?.settings?.Email?.message}
            />
            <TextInput
              label="Telefon"
              className="fs-16 fw-400 flex-column w-100 mb-3 text-dark-blue"
              disabled={!show ? true : false}
              type="EventInput"
              register={register("settings.MobilePhone", {
                maxLength: { value: 12, message: "Maksymalnie 12 znaków" },
                pattern: {
                  value: /^\+48\d{9}$/,
                  message: "Podaj numer w formacie +48XXXXXXXXX",
                },
              })}
              labelClassName="fw-400 fs-16 text-dark-blue"
              error={errors?.settings?.MobilePhone?.message}
            />
            <TextInput
              label="Ulica"
              className="fs-16 fw-400 flex-column w-100 mb-3 text-dark-blue"
              disabled={!show ? true : false}
              type="EventInput"
              register={register("settings.MailingStreet")}
              labelClassName="fw-400 fs-16 text-dark-blue"
              error={errors?.settings?.MailingStreet?.message}
            />
            <TextInput
              label="Kod pocztowy"
              className="fs-16 fw-400 flex-column w-100 mb-0 text-dark-blue"
              disabled={!show ? true : false}
              type="EventInput"
              register={register("settings.MailingPostalCode", {
                pattern: {
                  value: /^\d{2}-\d{3}$/,
                  message: "Format XX-XXX, tylko cyfry",
                },
              })}
              labelClassName="fw-400 fs-16 text-dark-blue"
              error={errors?.settings?.MailingPostalCode?.message}
            />
            <AsyncSelectLoading
              data={provincesData}
              placeholder={authUser?.settings?.MailingState}
              setValue={setValue}
              disabled={!show ? true : false}
              name="settings.MailingState"
              width={"100%"}
              asyncQuery={useLazyGetProvincesQuery}
              label="Województwo"
              onChange={onChange}
              styles={customStyles(!!errors?.settings?.MailingState?.message)}
            />
            <AsyncSelectLoading
              data={citiesData}
              placeholder={authUser?.settings?.MailingCity}
              setValue={setValue}
              disabled={!show ? true : false}
              name="settings.MailingCity"
              width={"100%"}
              asyncQuery={lazyGetCities}
              label="Miejscowość"
              filter={provinceFilter}
              styles={customStyles(
                !!errors?.localSettings?.mailingCity?.message
              )}
            />
          </div>
          {show && (
            <div className="d-flex flex-row justify-content-end mt-4">
              <Button
                variant="outline-dark"
                className="text-uppercase me-3"
                onClick={() => setShow(!show)}
              >
                Anuluj
              </Button>
              <Button
                variant="primary"
                className="text-uppercase"
                type="submit"
              >
                Aktualizuj
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Form>
  );
};
