import { Container } from "react-bootstrap";
import { ReactComponent as Instagram } from "../../../assets/Icons/SocialMediaIcons/Instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/Icons/SocialMediaIcons/Facebook.svg";
import { ReactComponent as Page } from "../../../assets/Icons/SocialMediaIcons/Page2.svg";

import TooltipIcon from "../../atoms/TooltipIcon";
import React from "react";

interface Props {
  social: string;
  size: number;
}

export interface Social {
  page?: string | null;
  facebook?: string | null;
  instagram?: string | null;
}

const ListOfSocialPage = (props: Props) => {
  const social: Social = JSON.parse(props.social);

  return (
    <Container className="d-flex gap-3">
      {social.page && (
        <TooltipIcon desc="Strona partnera">
          <a
            className={"text-primary"}
            href={
              social?.page?.startsWith("http://") ||
              social?.page?.startsWith("https://")
                ? social.page
                : `https://${social.page}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ width: props.size, height: props.size }}>
              <Page className={"h-100 w-100"} />
            </div>
          </a>
        </TooltipIcon>
      )}
      {social.facebook && (
        <TooltipIcon desc="Facebook partnera">
          <a
            className={"text-primary"}
            href={social.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ width: props.size, height: props.size }}>
              <Facebook className={"h-100 w-100"} />
            </div>
          </a>
        </TooltipIcon>
      )}
      {social.instagram && (
        <TooltipIcon desc="Instagram partnera">
          <a
            className={"text-primary"}
            href={social.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <div style={{ width: props.size, height: props.size }}>
              <Instagram className={"h-100 w-100"} />
            </div>
          </a>
        </TooltipIcon>
      )}
    </Container>
  );
};

export default ListOfSocialPage;
