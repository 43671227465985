import TextInput from "components/atoms/TextInput/TextInput";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styles from "./AddEventModal.module.scss";
import DateTimePicker from "components/atoms/DateTimePicker/DateTimePicker";
import { todayTimeIsoString } from "Helpers/dateHelpers";
import { PostEventProps, usePostEventMutation } from "redux/services/eventsApi";
import { useGetProvincesQuery } from "redux/services/provincesApi";
import { useGetCitiesQuery } from "redux/services/citiesApi";
import { useState } from "react";
// import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
// import { useLazyGetDoctorsQuery } from "redux/services/doctorApi";
// import { useLazyGetFacilitiesQuery } from "redux/services/facilitiesApi";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { toast } from "react-toastify";
import { AddEventModalProps, AddEventModalResponse, EventsType } from "./types";
import { useGetCancersQuery } from "redux/services/cancerApi";
import {
  City,
  Province,
} from "components/molecules/LoyaltyProgramServiceArticle/types";
import PictureDropzone from "components/atoms/PictureDropzone/PictureDropzone";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/index";
import { useGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";
import Select, { components, OptionProps, SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { UserRole } from "redux/Slice/user-slice";
import { AUTH_DATA } from "Helpers/Roles/authData";
import { useTranslation } from "react-i18next";
import { InfoCircle } from "react-bootstrap-icons";
import { useGetEventTypesQuery } from "redux/services/eventTypesApi";
import { useGetWebinarsQuery } from "../../../redux/services/getResponseApi";
// import { useEffectOnce } from "Helpers/useEffectOnce";
import { useMediaQuery } from "@mantine/hooks";
import TinyEditor from "../TinyEditor/TinyEditor";

const AddEventModal = ({ show, setShow }: AddEventModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [postCreateEvent] = usePostEventMutation();
  const [postAttachments] = usePostAttachmentsMutation();
  const [text, setText] = useState("Wydarzenie zostało utworzone.");
  const [type, setType] = useState<"info" | "confirm">("info");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { authUser } = useSelector((state: RootState) => state.user);
  // const [lazyGetDoctors, { data: lazyDoctorsData }] = useLazyGetDoctorsQuery();
  // const [lazyGetFacilities, { data: lazyFacilitiesData }] =
  //   useLazyGetFacilitiesQuery();
  const { data: eventTypesData } = useGetEventTypesQuery({});
  const { data: categoriesData } = useGetPagesCategoryQuery({ type: "event" });
  const { data: webinarsData } = useGetWebinarsQuery({
    status: "upcoming",
    page: "0",
    perPage: "0",
  });
  const { data: profilingDCategoriesData } = useGetPagesCategoryQuery({
    type: "profiling",
  });

  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: 768px)`);

  const userRolesArray = authUser.roles.map(
    (userRole: UserRole) => userRole.role.role
  );
  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );

  const renderTooltip = (lead?: string) => (
    <Tooltip id="button-tooltip" className="ms-2">
      {lead?.replaceAll("&oacute;", "ó")}
    </Tooltip>
  );

  const { data: provincesData, isSuccess: provincesSuccess } =
    useGetProvincesQuery({ limit: "0" });
  const { data: citiesData, isSuccess: citiesSuccess } = useGetCitiesQuery({
    limit: "0",
  });
  const { data: cancersData } = useGetCancersQuery({
    limit: "0",
    status: "1",
  });
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    reset,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<PostEventProps>({
    defaultValues: {
      name: "",
      type: undefined,
      description: "",
      categoryIds: [],
      startingDate: todayTimeIsoString,
      endingDate: todayTimeIsoString,
      zipCode: "",
      address: "",
      eventUrl: "",
      notes: "",
      languageId: 1,
      provinceId: undefined,
      cityId: undefined,
      doctorId: undefined,
      facilityId: undefined,
      cancerId: undefined,
      coverId: undefined,
      userId: authUser?.id,
      profilingIds: [],
      houseNumber: "",
      apartmentNumber: "",
      placeName: "",
    },
  });

  const cancelForm = () => {
    setShowInfoModal(false);
    setStep(1);
    reset();
    setShow(false);
  };

  const SelectCheckbox = ({
    children,
    isSelected,
    ...rest
  }: OptionProps<ReactSelectOption, boolean>) => {
    return (
      <components.Option
        {...rest}
        isSelected={isSelected}
        className={`cursor-pointer d-flex flex-wrap fs-16 ${styles.customOption}`}
      >
        <span className={`w-100 d-flex flex-wrap`}>
          <Form.Check
            type="checkbox"
            checked={isSelected}
            className="col-auto p-0 m-0"
            id="topics-checkbox"
          />
          <span className="mx-4 col-8 col-md-auto">{children}</span>
        </span>
      </components.Option>
    );
  };

  const [step, setStep] = useState(1);

  const validatePage = async () => {
    const result = await trigger();
    if (result) {
      if (step < 3) {
        setStep(step + 1);
      }
    }
  };

  const handlePicture = async (fileData: File) => {
    setFile(fileData);
  };

  // useEffectOnce(() => {
  //   (async () => {
  //     await lazyGetDoctors({ sortBy: "name,ASC" });
  //     await lazyGetFacilities({ sortBy: "name,ASC" });
  //   })();
  // });

  const renderOnline = () => {
    return (
      <>
        <div>
          <p className={`${styles.modalDescription} mt-0`}>
            Ustawienia wydarzenia
          </p>
          <TextInput
            register={register("eventUrl", {
              pattern: {
                value: /^(http|https):/,
                message: "Link powinien zaczynać się od http:// bądź https://",
              },
            })}
            className="fs-16 fw-400 flex-column w-100 mt-4 text-dark-blue"
            label="Link do platformy z wydarzeniem"
            inputClassName="text-dark-blue"
            type="EventInput"
            error={errors?.eventUrl?.message}
          />
        </div>
        <div>
          <label
            className={`fw-400 fs-16 d-flex flex-row justify-contenc-center align-items-center ${
              styles.labelMargin
            } ${
              errors?.profilingIds?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            Profilowanie wydarzenia
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                "Wybierz, dla których grup wydarzenie ma być dostępne"
              )}
            >
              <i className="mx-2">
                <InfoCircle size={18} color="#00b1e7" />
              </i>
            </OverlayTrigger>
          </label>
          <Controller
            name={"profilingIds"}
            control={control}
            rules={{
              onChange: () => clearErrors("profilingIds"),
            }}
            render={({ field: { value, ref } }) => (
              <Select<ReactSelectOption, boolean>
                ref={ref}
                value={profilingOptionsArray?.find(
                  (selectOption: ReactSelectOption) =>
                    selectOption.value === value
                )}
                classNamePrefix="select"
                placeholder="Wybierz z listy"
                isClearable={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                name="profilingIds"
                options={profilingOptionsArray}
                defaultValue={profilingOptionsArray?.filter(
                  (profile: ReactSelectOption) =>
                    getValues("profilingIds")?.includes(profile?.value)
                )}
                isMulti
                onChange={(value) => {
                  setValue(
                    "profilingIds",
                    Array.isArray(value)
                      ? value.map(
                          (singleValue: ReactSelectOption) => singleValue.value
                        )
                      : [value]
                  );
                  clearErrors("profilingIds");
                }}
                components={{
                  Option: SelectCheckbox,
                  DropdownIndicator: DropdownIndicator,
                }}
                styles={customStyles(!!errors?.profilingIds?.message)}
              />
            )}
          />
          {errors?.profilingIds && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.profilingIds?.message}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderDescription = () => {
    return (
      <div>
        <p className={`${styles.modalDescription} mb-4`}>Dodatkowe szczegóły</p>
        <TinyEditor
          setValue={setValue}
          data={getValues("description")}
          name="description"
          targetBlank
          label="Opis wydarzenia"
          labelClassName={
            errors?.description?.message ? "text-danger" : "text-dark-blue"
          }
        />
      </div>
    );
  };

  const renderWebinarAlivia = () => {
    return (
      <>
        <div>
          <p className={`${styles.modalDescription} mt-0`}>
            Ustawienia wydarzenia
          </p>
        </div>
        <div>
          <label
            className={`fw-400 fs-16 d-flex flex-row justify-contenc-center align-items-center ${
              styles.labelMargin
            } ${
              errors?.profilingIds?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            Identyfikator GetResponse
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                "Wybierz webinar, który chcesz, aby się pojawił w Twoim wydarzeniu."
              )}
            >
              <i className="mx-2">
                <InfoCircle size={18} color="#00b1e7" />
              </i>
            </OverlayTrigger>
          </label>
          {isGetResponseActive ? (
            webinarsArray.length > 0 ? (
              <>
                <Controller
                  name={"eventUrl"}
                  control={control}
                  rules={{
                    onChange: () => clearErrors("eventUrl"),
                  }}
                  render={({ field: { value, ref } }) => (
                    <Select
                      classNamePrefix="select"
                      placeholder="Wybierz z listy"
                      isClearable={false}
                      isSearchable={true}
                      name="eventUrl"
                      options={webinarsArray}
                      onChange={(option: SingleValue<ReactSelectOption>) =>
                        setValue("eventUrl", option?.value)
                      }
                      components={{ DropdownIndicator }}
                      styles={customStyles(!!errors?.type?.message)}
                    />
                  )}
                />
                {errors?.eventUrl && (
                  <div className={"text-danger fs-12 fw-400 mt-1"}>
                    {errors?.eventUrl?.message}
                  </div>
                )}
              </>
            ) : (
              <p className={"text-danger fs-12 fw-400 mt-1"}>
                Nie znaleziono aktywnych webinarów w GetResponse.
              </p>
            )
          ) : (
            <p className={"text-danger fs-12 fw-400 mt-1"}>
              Funkcja GetResponse tymczasowo nieaktywna.
            </p>
          )}
        </div>{" "}
        <div>
          <label
            className={`fw-400 fs-16 d-flex flex-row justify-contenc-center align-items-center ${
              styles.labelMargin
            } ${
              errors?.profilingIds?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            Profilowanie wydarzenia
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                "Wybierz, dla których grup wydarzenie ma być dostępne"
              )}
            >
              <i className="mx-2">
                <InfoCircle size={18} color="#00b1e7" />
              </i>
            </OverlayTrigger>
          </label>
          <Controller
            name={"profilingIds"}
            control={control}
            rules={{
              onChange: () => clearErrors("profilingIds"),
            }}
            render={({ field: { value, ref } }) => (
              <Select<ReactSelectOption, boolean>
                ref={ref}
                value={profilingOptionsArray?.find(
                  (selectOption: ReactSelectOption) =>
                    selectOption.value === value
                )}
                classNamePrefix="select"
                placeholder="Wybierz z listy"
                isClearable={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                name="profilingIds"
                options={profilingOptionsArray}
                defaultValue={profilingOptionsArray?.filter(
                  (profile: ReactSelectOption) =>
                    getValues("profilingIds")?.includes(profile?.value)
                )}
                isMulti
                onChange={(value) => {
                  setValue(
                    "profilingIds",
                    Array.isArray(value)
                      ? value.map(
                          (singleValue: ReactSelectOption) => singleValue.value
                        )
                      : [value]
                  );
                  clearErrors("profilingIds");
                }}
                components={{
                  Option: SelectCheckbox,
                  DropdownIndicator: DropdownIndicator,
                }}
                styles={customStyles(!!errors?.profilingIds?.message)}
              />
            )}
          />
          {errors?.profilingIds && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.profilingIds?.message}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderLocally = () => {
    return (
      <>
        <div>
          <p className={`${styles.modalDescription} mb-4`}>Lokalizacja</p>
          <TextInput
            register={register("placeName")}
            className="fs-16 fw-400 flex-column w-100 mt-3 text-darek-blue"
            label="Nazwa miejsca"
            labelClassName="text-dark-blue"
            type="EventInput"
            inputClassName="text-dark-blue"
          />
        </div>
        <div className="col-12 row m-0 p-0">
          <div className="col-sm-6 col-12 ps-0">
            <div>
              <label
                className={`fw-400 fs-16 ${styles.labelMargin} ${
                  errors.provinceId?.message ? "text-danger" : "text-dark-blue"
                }`}
              >
                Województwo
              </label>
              <Controller
                name={"provinceId"}
                control={control}
                rules={{
                  onChange: () => clearErrors("provinceId"),
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={provincesDataArray.find(
                      (selectOption: ReactSelectOption) =>
                        selectOption.value === value
                    )}
                    classNamePrefix="select"
                    placeholder="Wybierz z listy"
                    isClearable={false}
                    isSearchable={true}
                    name="provinceId"
                    options={provincesDataArray}
                    onChange={(option: SingleValue<ReactSelectOption>) =>
                      onChange(option?.value)
                    }
                    components={{ DropdownIndicator }}
                    styles={customStyles(!!errors?.provinceId?.message)}
                  />
                )}
              />
              {errors?.provinceId && (
                <div className={"text-danger fs-12 fw-400 mt-1"}>
                  {errors?.provinceId?.message}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6 pe-0">
            <div>
              <label
                className={`fw-400 fs-16 ${styles.labelMargin} ${
                  errors.cityId?.message ? "text-danger" : "text-dark-blue"
                }`}
              >
                Miasto
              </label>
              <Controller
                name={"cityId"}
                control={control}
                rules={{
                  onChange: () => clearErrors("cityId"),
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={citiesDataArray.find(
                      (selectOption: ReactSelectOption) =>
                        selectOption.value === value
                    )}
                    classNamePrefix="select"
                    placeholder="Wybierz z listy"
                    isClearable={false}
                    isSearchable={true}
                    name="cityId"
                    options={citiesDataArray}
                    onChange={(option: SingleValue<ReactSelectOption>) =>
                      onChange(option?.value)
                    }
                    components={{ DropdownIndicator }}
                    styles={customStyles(!!errors?.cityId?.message)}
                  />
                )}
              />
              {errors?.cityId && (
                <div className={"text-danger fs-12 fw-400 mt-1"}>
                  {errors?.cityId?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 row p-0 m-0">
          <div className="col-sm-6 col-12 ps-0">
            <TextInput
              inputClassName="text-dark-blue"
              register={register("street", {
                onChange: () => clearErrors("street"),
              })}
              className="fs-16 fw-400 flex-column w-100"
              label="Ulica"
              labelClassName={
                errors?.street?.message ? "text-danger" : "text-dark-blue"
              }
              type="EventInput"
              error={errors?.street?.message}
            />
          </div>
          <div className="col-12 col-sm-3">
            <TextInput
              inputClassName="text-dark-blue"
              register={register("houseNumber", {
                onChange: () => clearErrors("houseNumber"),
              })}
              className="fs-16 fw-400 flex-column w-100"
              label="Nr budynku"
              labelClassName={
                errors?.houseNumber?.message ? "text-danger" : "text-dark-blue"
              }
              type="EventInput"
              error={errors?.houseNumber?.message}
            />
          </div>
          <div className="col-12 col-sm-3 pe-0">
            <TextInput
              inputClassName="text-dark-blue"
              register={register("apartmentNumber")}
              className="fs-16 fw-400 flex-column w-100"
              label="Nr lokalu"
              labelClassName="text-dark-blue"
              type="EventInput"
            />
          </div>
        </div>
        <div>
          <p className={`${styles.modalDescription} mb-4`}>
            Ustawienia wydarzenia
          </p>
          <label
            className={`fw-400 fs-16 d-flex flex-row justify-contenc-center align-items-center ${
              styles.labelMargin
            } ${
              errors?.profilingIds?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            Profilowanie wydarzenia
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(
                "Wybierz, dla których grup wydarzenie ma być dostępne"
              )}
            >
              <i className="mx-2">
                <InfoCircle size={18} color="#00b1e7" />
              </i>
            </OverlayTrigger>
          </label>
          <Controller
            name={"profilingIds"}
            control={control}
            rules={{
              onChange: () => clearErrors("profilingIds"),
            }}
            render={({ field: { value, ref } }) => (
              <Select<ReactSelectOption, boolean>
                ref={ref}
                value={profilingOptionsArray?.find(
                  (selectOption: ReactSelectOption) =>
                    selectOption.value === value
                )}
                classNamePrefix="select"
                placeholder="Wybierz z listy"
                isClearable={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                defaultValue={profilingOptionsArray?.filter(
                  (profile: ReactSelectOption) =>
                    getValues("profilingIds")?.includes(profile?.value)
                )}
                name="profilingIds"
                options={profilingOptionsArray}
                isMulti
                onChange={(value) => {
                  setValue(
                    "profilingIds",
                    Array.isArray(value)
                      ? value.map(
                          (singleValue: ReactSelectOption) => singleValue.value
                        )
                      : [value]
                  );
                  clearErrors("profilingIds");
                }}
                components={{
                  Option: SelectCheckbox,
                  DropdownIndicator: DropdownIndicator,
                }}
                styles={customStyles(!!errors?.profilingIds?.message)}
              />
            )}
          />
          {errors?.profilingIds && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.profilingIds?.message}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderMedical = () => {
    return (
      <div className="gap-4 row col-12">
        <div className={`${styles.modalDescription}`}>
          Informacje na temat lekarza
        </div>
        <div>
          <Controller
            name={"doctorId"}
            control={control}
            rules={{
              onChange: () => clearErrors("doctorId"),
            }}
            render={({ field: { onChange } }) => (
              <TextInput
                register={register("doctorId")}
                className="fs-16 fw-400 flex-column w-100"
                label="Imię i nazwisko lekarza"
                labelClassName={
                  errors?.doctorId?.message ? "text-danger" : "text-dark-blue"
                }
                inputClassName="text-dark-blue"
                type="EventInput"
                error={errors?.doctorId?.message}
              />

              //     <AsyncSelectLoading
              //       data={lazyDoctorsData}
              //       placeholder="Wybierz z listy"
              //       label="Imię i nazwisko lekarza"
              //       labelClasses={
              //         errors?.doctorId?.message ? "text-danger" : "text-dark-blue"
              //       }
              //       setValue={setValue}
              //       name="doctorId"
              //       width={"100%"}
              //       onChange={onChange}
              //       asyncQuery={lazyGetDoctors}
              //       inputClasses="text-dark-blue"
              //       styles={customStyles(!!errors?.doctorId?.message)}
              //     />
              //   )}
              // />
            )}
          />
          {errors?.doctorId && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.doctorId?.message}
            </div>
          )}
        </div>
        <div className={`${styles.modalDescription}`}>
          Miejsce odbywania wizyty
        </div>
        <div>
          <Controller
            name={"facilityId"}
            control={control}
            rules={{
              onChange: () => clearErrors("facilityId"),
            }}
            render={({ field: { onChange } }) => (
              // <AsyncSelectLoading
              //   data={lazyFacilitiesData}
              //   placeholder="Wybierz z listy"
              //   inputClasses="text-dark-blue"
              //   label="Nazwa placówki"
              //   labelClasses={
              //     errors?.facilityId?.message ? "text-danger" : "text-dark-blue"
              //   }
              //   setValue={setValue}
              //   name="facilityId"
              //   width={"100%"}
              //   onChange={onChange}
              //   asyncQuery={lazyGetFacilities}
              //   styles={customStyles(!!errors?.facilityId?.message)}
              // />
              <TextInput
                register={register("facilityId")}
                className="fs-16 fw-400 flex-column w-100"
                label="Nazwa placówki"
                labelClassName={
                  errors?.facilityId?.message ? "text-danger" : "text-dark-blue"
                }
                inputClassName="text-dark-blue"
                type="EventInput"
                error={errors?.facilityId?.message}
              />
            )}
          />
          {errors?.facilityId && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.facilityId?.message}
            </div>
          )}
        </div>
        <div className="col-12 row p-0 m-0">
          <div className="col-sm-6 col-12">
            <TextInput
              register={register("street")}
              className="fs-16 fw-400 flex-column w-100"
              inputClassName="text-dark-blue"
              labelClassName={
                errors?.street?.message ? "text-danger" : "text-dark-blue"
              }
              label="Ulica i nr"
              type="EventInput"
            />
          </div>
          <div className="col-sm-6 col-12">
            <label
              className={`fw-400 fs-16 ${styles.labelMargin} ${
                errors?.cityId?.message ? "text-danger" : "text-dark-blue"
              }`}
            >
              Miasto
            </label>
            <Controller
              name={"cityId"}
              control={control}
              rules={{
                onChange: () => clearErrors("cityId"),
              }}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  ref={ref}
                  value={citiesDataArray.find(
                    (selectOption: ReactSelectOption) =>
                      selectOption.value === value
                  )}
                  classNamePrefix="select"
                  placeholder="Wybierz z listy"
                  isClearable={false}
                  isSearchable={true}
                  name="cityId"
                  options={citiesDataArray}
                  onChange={(option: SingleValue<ReactSelectOption>) =>
                    onChange(option?.value)
                  }
                  components={{ DropdownIndicator }}
                  styles={customStyles(!!errors?.cityId?.message)}
                />
              )}
            />
            {errors?.cityId && (
              <div className={"text-danger fs-12 fw-400 mt-1"}>
                {errors?.cityId?.message}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBasic = () => {
    return (
      <>
        <div>
          <div className={`${styles.modalDescription} mb-3`}>
            Dodawanie wydarzenia
          </div>
          <TextInput
            register={register("name", {
              required: {
                value: true,
                message: "Pole wymagane",
              },
              onChange: () => clearErrors("name"),
            })}
            className="fs-16 fw-400 flex-column w-100"
            label="Nazwa wydarzenia*"
            labelClassName={
              errors?.name?.message ? "text-danger" : "text-dark-blue"
            }
            inputClassName="text-dark-blue"
            type="EventInput"
            error={errors?.name?.message}
          />
        </div>
        <div>
          <label
            className={`fw-400 fs-16 ${styles.labelMargin} ${
              errors?.type?.message ? "text-danger" : "text-dark-blue"
            }`}
          >
            Typ wydarzenia*
          </label>
          <Controller
            name={"type"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Pole wymagane",
              },
              onChange: () => clearErrors("type"),
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={eventTypesArray?.find(
                  (selectOption: ReactSelectOption) =>
                    selectOption.value === value
                )}
                classNamePrefix="select"
                placeholder="Wybierz z listy"
                isClearable={false}
                isSearchable={true}
                name="type"
                options={
                  isAdmin
                    ? eventTypesArray
                    : eventTypesArray?.filter(
                        (type: ReactSelectOption) =>
                          type?.value === "medical" ||
                          type?.value === "consulting"
                      )
                }
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value)
                }
                components={{ DropdownIndicator }}
                styles={customStyles(!!errors?.type?.message)}
              />
            )}
          />
          {errors?.type && (
            <div className={"text-danger fs-12 fw-400 mt-1"}>
              {errors?.type?.message}
            </div>
          )}
        </div>
        {isAdmin && (
          <>
            {getValues("type") !== "medical" &&
              getValues("type") !== "consulting" && (
                <div>
                  <label
                    className={`fw-400 fs-16 ${styles.labelMargin} ${
                      errors?.categoryIds?.message
                        ? "text-danger"
                        : "text-dark-blue"
                    }`}
                  >
                    Kategoria wydarzenia
                  </label>
                  <Controller
                    name={"categoryIds"}
                    control={control}
                    rules={{
                      onChange: () => clearErrors("categoryIds"),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select<ReactSelectOption, boolean>
                        onChange={(value) => {
                          setValue(
                            "categoryIds",
                            Array.isArray(value)
                              ? value.map(
                                  (singleValue: ReactSelectOption) =>
                                    singleValue.value
                                )
                              : [value]
                          );
                          clearErrors("categoryIds");
                        }}
                        closeMenuOnSelect={false}
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Wybierz z listy"
                        hideSelectedOptions={false}
                        defaultValue={categoriesArray?.filter(
                          (category: ReactSelectOption) =>
                            getValues("categoryIds").includes(category?.value)
                        )}
                        classNamePrefix="select"
                        name="categoryIds"
                        components={{
                          Option: SelectCheckbox,
                          DropdownIndicator: DropdownIndicator,
                        }}
                        isMulti={true}
                        options={categoriesArray}
                        styles={customStyles(!!errors?.categoryIds?.message)}
                        noOptionsMessage={() => (
                          <span>Brak kategorii o podanej frazie</span>
                        )}
                      />
                    )}
                  />
                  {errors?.categoryIds && (
                    <div className={"text-danger fs-12 fw-400 mt-1"}>
                      {errors?.categoryIds?.message}
                    </div>
                  )}
                </div>
              )}
            <div>
              <label
                className={`fw-400 fs-16 ${styles.labelMargin} ${
                  errors?.cancerId?.message ? "text-danger" : "text-dark-blue"
                }`}
              >
                Tematyka wydarzenia
              </label>
              <Controller
                name={"cancerId"}
                control={control}
                rules={{
                  onChange: () => clearErrors("cancerId"),
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={cancersArray?.find(
                      (selectOption: ReactSelectOption) =>
                        selectOption.value === value
                    )}
                    classNamePrefix="select"
                    placeholder="Wybierz z listy"
                    isClearable={false}
                    isSearchable={true}
                    name="cancerId"
                    options={cancersArray}
                    onChange={(option: SingleValue<ReactSelectOption>) =>
                      onChange(option?.value)
                    }
                    components={{ DropdownIndicator }}
                    styles={customStyles(!!errors?.cancerId?.message)}
                  />
                )}
              />
              {errors?.cancerId && (
                <div className={"text-danger fs-12 fw-400 mt-1"}>
                  {errors?.cancerId?.message}
                </div>
              )}
            </div>
            <div>
              <p className="m-0 fs-6 text-dark-blue">Grafika wydarzenia</p>
              <PictureDropzone
                handlePicture={handlePicture}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl ?? null}
              />
              <p className="m-0 w-400 fs-12 text-grey">
                Preferowany grafiki to 720x400. Akceptowane formaty plików: jpg,
                png, webp.
              </p>
            </div>
          </>
        )}
        <Row>
          <DateTimePicker
            className="col-12 col-md-6"
            value={watch("startingDate")}
            setValue={setValue}
            type="EventInput"
            label="Data rozpoczęcia"
            name="startingDate"
          />
          <DateTimePicker
            className="col-12 col-md-6"
            value={watch("endingDate")}
            setValue={setValue}
            type="EventInput"
            label="Data zakończenia"
            name="endingDate"
          />
        </Row>
      </>
    );
  };

  const provincesDataArray: ReactSelectOption[] = Array.from(
    new Set(
      provincesData && provincesSuccess
        ? provincesData?.data?.map((province: Province) => {
            return { value: province.id.toString(), label: province.name };
          })
        : []
    )
  ) as ReactSelectOption[];

  const citiesDataArray: ReactSelectOption[] = Array.from(
    new Set(
      citiesData && citiesSuccess
        ? citiesData?.data?.map((city: City) => ({
            value: city?.id.toString(),
            label: city?.name,
          }))
        : []
    )
  ) as ReactSelectOption[];

  const cancersArray: ReactSelectOption[] = Array.from(
    new Set(
      cancersData?.data?.map((cancer: Cancer) => ({
        value: cancer?.id,
        label: cancer?.name,
      })) ?? []
    )
  ) as ReactSelectOption[];

  const profilingOptionsArray: ReactSelectOption[] =
    profilingDCategoriesData?.data?.map((category: PageCategory) => ({
      value: category?.id?.toString(),
      label: category?.title,
    }));

  const categoriesArray: ReactSelectOption[] = categoriesData?.data?.map(
    (category: PageCategory) => ({
      value: category?.id?.toString(),
      label: category?.title,
    })
  );

  const eventTypesArray: ReactSelectOption[] =
    eventTypesData?.eventCategories?.map((eventType: EventsType) => ({
      value: eventType?.template?.toString(),
      label: eventType?.name,
    }));

  const isGetResponseActive = webinarsData?.httpStatus !== 401;

  const webinarsArray: ReactSelectOption[] =
    isGetResponseActive && Array.isArray(webinarsData)
      ? webinarsData?.map((webinar: Webinar) => ({
          value: webinar?.campaigns?.[0]?.campaignId,
          label: webinar?.name,
        }))
      : [];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setStep(1);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus={false}
      >
        <Modal.Header
          closeButton
          className={`border-0 px-4 ${styles.modalHeader}`}
        >
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="fw-600 fs-22 text-dark-blue"
          >
            Nowe wydarzenie
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row gap-3">
            <div className={`${styles.modalMenu} col-12  `}>
              <form className="d-flex flex-column gap-4 w-100">
                {step === 1 && renderBasic()}
                {step === 2 &&
                  (getValues("type") === "online" ||
                    getValues("type") === "webinar") &&
                  renderOnline()}
                {step === 2 &&
                  (getValues("type") === "locally" ||
                    getValues("type") === "conference") &&
                  renderLocally()}
                {step === 2 &&
                  (getValues("type") === "consulting" ||
                    getValues("type") === "medical") &&
                  renderMedical()}
                {step === 2 &&
                  getValues("type") === "getresponse" &&
                  renderWebinarAlivia()}
                {step === 3 && renderDescription()}
                <div className="col-auto mt-3 justify-content-between align-content-center align-items-center d-flex">
                  <p className="my-0 fw-600 fs-16 text-dark-blue">
                    Krok {step} z 3
                  </p>
                  <div>
                    {step > 1 && (
                      <Button
                        variant="outline-light"
                        className="me-1 me-sm-2 fw-500 fs-6"
                        onClick={() => setStep(step - 1)}
                      >
                        {step === 3 && isMobile ? "<" : "Cofnij"}
                      </Button>
                    )}
                    <Button
                      variant="outline-light"
                      className="me-1 me-sm-2 fw-500 fs-6"
                      onClick={() => {
                        setText("Czy na pewno chcesz anulować?");
                        setType("confirm");
                        setShowInfoModal(true);
                        setImageUrl(null);
                      }}
                    >
                      Anuluj
                    </Button>
                    {step === 3 ? (
                      <Button
                        className="btn-primary"
                        onClick={handleSubmit(async (data) => {
                          let imageId = 0;
                          const hasBeenAccepted = await postAttachments({
                            data: file,
                            attachmentType: "event-cover",
                          });
                          if ("data" in hasBeenAccepted) {
                            imageId = hasBeenAccepted?.data?.data?.id;
                          }
                          const postData: PostEventProps = {
                            ...data,
                            startingDate: `${data.startingDate}:00.000Z`,
                            endingDate: `${data.endingDate}:00.000Z`,
                            coverId: imageId.toString(),
                            notes:
                              (typeof data?.doctorId === "string"
                                ? "doctorName:" + data?.doctorId
                                : data?.name) +
                              (typeof data?.facilityId === "string"
                                ? ";facilityName:" + data?.facilityId
                                : ""),
                            provinceId: +data?.provinceId,
                            cityId: +data?.cityId,
                            doctorId:
                              typeof data?.doctorId === "number"
                                ? data?.doctorId
                                : undefined,
                            facilityId:
                              typeof data?.facilityId === "number"
                                ? data?.facilityId
                                : undefined,
                            address: JSON.stringify({
                              placeName: data?.placeName,
                              street: data?.street,
                              apartmentNumber: data?.apartmentNumber,
                              houseNumber: data?.houseNumber,
                            }),
                            categoryIds:
                              typeof data?.categoryIds === "string"
                                ? JSON.parse(data?.categoryIds)
                                : data?.categoryIds,
                            cancerId: +data?.cancerId,
                          };

                          const createEvent = await postCreateEvent({
                            data: postData,
                          });

                          if ("data" in createEvent) {
                            const responseData =
                              createEvent?.data as AddEventModalResponse;
                            if (responseData.data) {
                              setText("Wydarzenie zostało utworzone.");
                              setShowInfoModal(true);
                              setShow(false);
                              setStep(1);
                              reset();
                            } else {
                              if (responseData.statusCode === 500)
                                toast.error(t("toast_addEventModal_error_500"));
                              if (responseData.statusCode === 400)
                                toast.error(responseData.error);
                              if (
                                responseData.statusCode !== 400 &&
                                responseData.statusCode !== 500
                              )
                                toast.error(t("toast_addEventModal_error"));
                              setShow(false);
                              setStep(1);
                              reset();
                            }
                          }
                        })}
                      >
                        Utwórz
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => {
                          validatePage();
                        }}
                      >
                        {isMobile ? ">" : "Dalej"}
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PopupModal
        setShow={setShowInfoModal}
        show={showInfoModal}
        text={text}
        type={type}
        confirmAction={cancelForm}
      />
    </>
  );
};

export default AddEventModal;
