import styles from "./LoyaltyProgramServiceCardMyCode.module.scss";
import { Button, Card, Row } from "react-bootstrap";
import { CodeModal } from "../../organisms/CodeModal/CodeModal";
import QRCode from "react-qr-code";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import React, { useState } from "react";
import { changeDateFormatNames } from "../../../Helpers/changeDateFormatNames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: LoyaltyProgramServiceMyCode;
}

export const LoyaltyProgramServiceCardMyCode = ({ data }: Props) => {
  const [showCode, setShowCode] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Card as="article" className={`h-100 w-100 alivia-radius`}>
      <Card.Header
        className={`d-flex flex-column justify-content-between fs-19 alivia-radius
          fw-semibold lh-130 ls-3 text-dark-blue pb-3 bg-white ${styles.card}`}
      >
        <Link
          className={"h-100"}
          to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${data.loyaltyProgramService.id}`}
          target={"_blank"}
        >
          <Card.Title
            className={`${styles.title} ${
              data.expireAt ? styles.titleHeight : " h-100 "
            }`}
          >
            {data.loyaltyProgramService.name}
          </Card.Title>
        </Link>
        {data.expireAt && (
          <Card.Subtitle
            className={"d-flex justify-content-between fs-12 fw-semibold mt-1"}
          >
            <span className={"text-grey-2"}>
              {t("widget_loyalty_your_benefits_expired_at_span")}
            </span>
            <span className={""}>
              {changeDateFormatNames(data.expireAt, false)}
            </span>
          </Card.Subtitle>
        )}
      </Card.Header>
      <Card.Body className={"p-0"}>
        <Card.Text as="div" className={"text-dark-blue fs-14 p-3"}>
          <Row className={"px-3"}>
            <Button
              variant={"outline-primary"}
              className={"fs-14 w-100 fw-semibold p-1"}
              onClick={() => setShowCode((prev) => !prev)}
            >
              {t("widget_loyalty_your_benefits_show_code_button")}
            </Button>
            <CodeModal
              show={showCode}
              handleClose={() => setShowCode((prev) => !prev)}
              label={"Kod"}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={data.code}
                viewBox={`0 0 256 256`}
              />
              <div
                className={`border border-1 text-center p-2 mt-4 alivia-radius`}
              >
                <span className={"fs-24 fw-semibold"}>{data.code}</span>
              </div>
              {data.usedAt && (
                <h5 className={"fw-semibold text-danger text-center mt-3"}>
                  {t("widget_loyalty_your_benefits_used_at_span")}{" "}
                  {dateEmbellishment(data.usedAt, "-")}
                </h5>
              )}
            </CodeModal>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
