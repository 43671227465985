import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TokenState {
  userToken: string | null;
}

const initialState: TokenState = {
  userToken: null,
};

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
    clearUserToken: (state) => {
      state.userToken = null;
    },
  },
});

export const { setUserToken, clearUserToken } = tokenSlice.actions;

export default tokenSlice.reducer;
