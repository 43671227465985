import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import React, { useState } from "react";
import styles from "./ChangesDoctorTemplate.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import {
  useGetDoctorQuery,
  usePatchDoctorMutation,
} from "../../../redux/services/doctorApi";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import AvatarPlaceholder from "../../../assets/images/user normal.png";
import { useGetUserRolesQuery } from "../../../redux/services/userRolesApi";
import { useDeleteUserRoleMutation } from "../../../redux/services/usersApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { domain } from "../../organisms/Landing/Landing";
import { replaceSpacesWithDashes } from "../../../Helpers/replaceSpacesWithDashes";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

export const ChangesDoctorTemplate: React.FC = () => {
  const { id: doctorId } = useParams();
  const navigate = useNavigate();
  const [showModalAccepted, setShowModalAccepted] = useState(false);
  const [showModalRejected, setShowModalRejected] = useState(false);

  const { t } = useTranslation();

  const { data: doctorData, isLoading } = useGetDoctorQuery(Number(doctorId));
  const { data: UserRolesData, isLoading: UserRolesIsLoading } =
    useGetUserRolesQuery({
      role: "doctor",
    });
  const [patchDoctor] = usePatchDoctorMutation();
  const [deleteUserRole] = useDeleteUserRoleMutation();
  const [notification] = usePostNotificationsMutation();

  const ONKO_URL =
    process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
    (process.env?.REACT_APP_MODE === "dev"
      ? "https://dev.onkomapa.pl"
      : "https://onkomapa.pl");
  const ONKO_URL_DOCTOR = `${ONKO_URL}/szukaj/lekarz/`;

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handleAccept = async () => {
    const hasBeenAccepted = await patchDoctor({
      id: Number(doctorId),
      data: { status: "ACCEPTED" },
    });
    if ("data" in hasBeenAccepted) {
      toast.success(t("toast_changesDoctorTemplate_assign_success"));

      if (doctorData.data?.user?.authId) {
        await notification({
          data: {
            user: doctorData.data?.user?.authId,
            title: t("notification_ChangesDoctorTemplate_accept_title"),
            content: t("notification_ChangesDoctorTemplate_accept_content"),
            viewSender: 0,
            senderUser: null,
            type: NotificationTypeEnum["doctor"],
            url: `${domain}/${NAV_PATH.DOCTOR}/${NAV_PATH.ME}`,
          },
        });
      }

      navigate(-1);
    } else if ("error" in hasBeenAccepted) {
      const error = hasBeenAccepted?.error as DoctorFacilityDeleteError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_changesDoctorTemplate_assign_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_changesDoctorTemplate_assign_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_changesDoctorTemplate_assign_error_400")} ${
            error.data.message[0]
          }`,
        );
    }
  };

  const handleReject = async () => {
    const hasBeenRejected = await patchDoctor({
      id: Number(doctorId),
      data: { userId: null },
    });
    if ("data" in hasBeenRejected) {
      const hasBeenDeletedRole = await deleteUserRole({
        data: {
          userId: doctorData.data?.user?.id || "",
          sendData: {
            roleId: UserRolesData.userRoles?.[0]?.id.toString(),
          },
        },
      });
      if ("data" in hasBeenDeletedRole) {
        toast.success(t("toast_changesDoctorTemplate_delete_success"));
        navigate(-1);
      } else if ("error" in hasBeenDeletedRole) {
        const error = hasBeenDeletedRole?.error as DoctorFacilityDeleteError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_changesDoctorTemplate_delete_error_500"));

        if (error.data.statusCode === 404)
          toast.error(t("toast_changesDoctorTemplate_delete_error_404"));

        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_changesDoctorTemplate_delete_error_400")} ${
              error.data.message[0]
            }`,
          );
      }
    } else if ("error" in hasBeenRejected) {
      const error = hasBeenRejected?.error as DoctorFacilityDeleteError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_changesDoctorTemplate_reject_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_changesDoctorTemplate_reject_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_changesDoctorTemplate_reject_error_400")} ${
            error.data.message[0]
          }`,
        );
    }
  };

  return (
    <Container fluid={true} className="bg-light">
      <section className={`${styles.section}`}>
        <Stack className={"w-100 p-0 m-0"} gap={5}>
          <Col
            className={"d-flex align-items-center cursor-pointer pb-4"}
            onClick={() => navigate(-1)}
          >
            <Arrow
              style={{ transform: "rotate(180deg)" }}
              className={"text-primary"}
            />
            <span className={"ps-2 text-primary"}>
              WRÓĆ DO PRZEGLĄDANIA ZMIAN
            </span>
          </Col>
          <Col className={`${styles.wrapper}`}>
            <div
              className={
                "d-flex justify-content-between align-items-center flex-column flex-lg-row gap-3 mb-3 text-dark-blue "
              }
            >
              <Image
                className={`rounded-circle ${styles.image}`}
                src={doctorData?.data?.avatar?.accessUrl ?? AvatarPlaceholder}
              />
              <Row className={"w-100 gap-3"}>
                <span className="fw-semibold ls-3  fs-25">
                  {doctorData?.data?.degree?.name} {doctorData?.data?.lastName}{" "}
                  {doctorData?.data?.firstName}
                </span>
                <div>
                  <a
                    className={"btn btn-outline-dark-blue fs-16"}
                    href={`${ONKO_URL_DOCTOR}/${replaceSpacesWithDashes(
                      doctorData?.data?.degree?.shortcut,
                    )}-${replaceSpacesWithDashes(
                      doctorData?.data?.firstName,
                    )}-${replaceSpacesWithDashes(
                      doctorData?.data?.lastName,
                    )}/${doctorData?.data?.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    pokaż profil
                  </a>
                </div>
              </Row>
            </div>
            <hr
              className={`d-md-block d-lg-none mt-0 mb-3 my-1 p-0 ${styles.hrColor}`}
            />
            <div
              className={
                "d-flex justify-content-between flex-column flex-lg-row gap-2 mb-3 text-dark-blue"
              }
            >
              <span>Nr telefonu:</span>
              <Link
                className={
                  "d-flex align-items-center fw-semibold pe-2 pb-2 fs-25 ls-3"
                }
                to={`tel:${doctorData?.data?.phone}`}
              >
                {doctorData?.data?.phone}
              </Link>
            </div>
            <hr
              className={`d-md-block d-lg-none mt-0 mb-3 my-1 p-0 ${styles.hrColor}`}
            />
            <div
              className={
                "d-flex justify-content-between flex-column flex-lg-row gap-2 mb-0 text-dark-blue"
              }
            >
              <span>Email:</span>
              <span className="fw-semibold ls-3">
                {doctorData?.data?.email || ""}
              </span>
            </div>
            <hr
              className={`d-md-block d-lg-none mt-0 mb-3 my-1 p-0 ${styles.hrColor}`}
            />
            {doctorData?.data?.description && (
              <div
                className={
                  "d-flex justify-content-between flex-column gap-2 mb-3 text-dark-blue"
                }
              >
                <span>Opis:</span>
                <span className="">{doctorData?.data?.description}</span>
              </div>
            )}
          </Col>
          <div className={"d-flex gap-2 justify-content-end"}>
            <Button
              disabled={UserRolesIsLoading}
              className={"btn-outline-dark-blue fs-16"}
              onClick={() => setShowModalRejected((prev: boolean) => !prev)}
            >
              odrzuć
            </Button>
            <PopupModal
              text="Potwierdź odrzucenie"
              smallText={"Po potwierdzeniu nie można powrócić"}
              type="confirm"
              show={showModalRejected}
              setShow={() => setShowModalRejected(false)}
              confirmAction={handleReject}
            />
            <Button
              className={"fs-16"}
              onClick={() => setShowModalAccepted((prev: boolean) => !prev)}
            >
              AKCEPTUJ
            </Button>
            <PopupModal
              text="Potwierdź akceptację"
              smallText={"Po potwierdzeniu nie można powrócić"}
              type="confirm"
              show={showModalAccepted}
              setShow={() => setShowModalAccepted(false)}
              confirmAction={handleAccept}
            />
          </div>
        </Stack>
      </section>
    </Container>
  );
};
