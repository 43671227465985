import { LoyaltyProgramSponsorTemplate } from "../../templates/LoyaltyProgramSponsorTemplate/LoyaltyProgramSponsorTemplate";
import { useParams } from "react-router-dom";
import { useGetCompanyCheckQuery } from "../../../redux/services/companyApi";
import { Container, Spinner } from "react-bootstrap";
import React from "react";
import { ViewNotAuthorized } from "../../atoms/ViewNotAuthorized/ViewNotAuthorized";
import { useTranslation } from "react-i18next";

const LoyaltyProgramSponsorPage = () => {
  const { t } = useTranslation();
  const { id: companyId } = useParams();

  const { data, isLoading } = useGetCompanyCheckQuery(companyId);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (data?.data?.accept) {
    if (data?.data?.status) {
      return <LoyaltyProgramSponsorTemplate />;
    }
    return (
      <ViewNotAuthorized
        value={t("company_no_verify_view_header")}
        info={t("company_no_verify_view_info")}
      />
    );
  } else {
    return (
      <ViewNotAuthorized
        value={t("company_no_accept_view_header")}
        info={t("company_no_accept_view_info")}
      />
    );
  }
};

export default LoyaltyProgramSponsorPage;
