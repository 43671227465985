import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./ChangesEntity.module.scss";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { ReactComponent as Eye } from "../../../assets/Icons/changes/Eye.svg";
import { ReactComponent as PlusInCircle } from "../../../assets/Icons/changes/PlusInCircle.svg";
import { ReactComponent as Message } from "../../../assets/Icons/changes/Message.svg";
import { ReactComponent as Alert } from "../../../assets/Icons/changes/Alert.svg";
import TooltipIcon from "../../atoms/TooltipIcon";
import { Button } from "react-bootstrap";
import { findLastDate, isWithinMinutes } from "../../../Helpers/dateHelpers";
import {
  Company,
  CompanyDepartment,
  LoyaltyProgramServiceData,
} from "../LoyaltyProgramServiceArticle/types";

interface Props {
  link: string;
  data: Company | CompanyDepartment | LoyaltyProgramServiceData;
}

function formatString(input: string) {
  if (input.length <= 40) {
    return input;
  } else {
    return input.slice(0, 37) + "...";
  }
}

export const ChangesEntity = (props: Props) => {
  const navigate = useNavigate();
  const [iconAndPriority, setIconAndPriority] = useState({
    icon: (
      <TooltipIcon desc={"Nowa zmiana do akceptacji"}>
        <Alert />
      </TooltipIcon>
    ),
    priority: true,
  });
  const [changes, setChanges] = useState<ChangesTypes>();

  useEffect(() => {
    if (props.data?.changes) {
      const newChanges = JSON.parse(props.data?.changes);
      setChanges(newChanges);

      if (newChanges?.startView && isWithinMinutes(newChanges.startView, 20)) {
        setIconAndPriority({
          icon: (
            <TooltipIcon desc={"Zmiana w trakcie rozpatrywania"}>
              <Eye />
            </TooltipIcon>
          ),
          priority: false,
        });
      } else if (newChanges?.create) {
        setIconAndPriority({
          icon: (
            <TooltipIcon desc={"Nowa pozycja do akceptacji"}>
              <PlusInCircle />
            </TooltipIcon>
          ),
          priority: true,
        });
      } else {
        if (newChanges?.lastUserUpdateDate && newChanges?.commentsDate) {
          if (
            new Date(newChanges?.commentsDate).getTime() -
              new Date(newChanges?.lastUserUpdateDate).getTime() >
            0
          ) {
            setIconAndPriority({
              icon: (
                <TooltipIcon desc={"Oczekiwanie na odpowiedź użytkownika"}>
                  <Message />
                </TooltipIcon>
              ),
              priority: false,
            });
          } else {
            setIconAndPriority({
              icon: (
                <TooltipIcon desc={"Nowa zmiana do akceptacji"}>
                  <Alert />
                </TooltipIcon>
              ),
              priority: true,
            });
          }
        } else {
          setIconAndPriority({
            icon: (
              <TooltipIcon desc={"Nowa zmiana do akceptacji"}>
                <Alert />
              </TooltipIcon>
            ),
            priority: true,
          });
        }
      }
    }
  }, [props.data]);

  return (
    <div
      className={`fs-14 p-2 px-4 d-flex flex-wrap justify-content-between  ${
        iconAndPriority.priority ? styles.view : ""
      }`}
      key={props.data.id}
    >
      <div className={`d-flex align-items-center order-0 gap-3`}>
        <div>{iconAndPriority.icon}</div>
        {changes && (
          <div className={"text-dark-blue fs-14 text-nowrap"}>
            {findLastDate([
              props.data.updatedAt,
              changes?.lastUserUpdateDate,
              changes?.update,
              changes?.create,
            ])}
          </div>
        )}
      </div>
      <div
        className={` d-flex align-items-center text-wrap text-break order-3 order-lg-2 mt-2 mt-lg-0 justify-content-center justify-content-lg-start text-grey fs-14 ms-lg-3 me-lg-auto mb-2 mb-lg-0`}
      >
        {formatString(props.data.name)}
      </div>

      <Button
        style={{ width: 38, height: 38 }}
        className={`d-flex justify-content-center align-items-center p-0 m-0 order-2 order-lg-3 text-dark-blue ms-auto`}
        variant={"transparent"}
        onClick={() => navigate(`${props.link}/${props.data.id}`)}
      >
        <ArrowUpRightFromSquare />
      </Button>
    </div>
  );
};
