import { Button, Container, Form, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { useGetProvincesQuery } from "../../../redux/services/provincesApi";
import React, { useEffect, useState } from "react";
import { useGetCitiesQuery } from "../../../redux/services/citiesApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetLoyaltyProgramsServiceCategoryQuery } from "../../../redux/services/loyaltyProgramApi";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { SystemSetting } from "../../../redux/Slice/system-settings-slice";
import {
  City,
  LoyaltyProgramServiceCategory,
  Province,
} from "../../molecules/LoyaltyProgramServiceArticle/types";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  nameLabel: string;
  isService: boolean;
}

export const ServicesForm = (props: Props) => {
  const [disableLocation, setDisableLocation] = useState<boolean>(false);
  const { systemSettings } = useSelector(
    (state: RootState) => state.systemSettings
  );

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [valueCity, setValueCity] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const { getValues, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      category: searchParams.get("category") || "",
      province: searchParams.get("province") || "",
      city: searchParams.get("city") || "",
    },
  });

  const { data: provincesData } = useGetProvincesQuery({});
  const { data: citiesData } = useGetCitiesQuery({
    provinceId: watch("province"),
  });
  const { data: categoriesData } = useGetLoyaltyProgramsServiceCategoryQuery({
    allService: 1,
  });

  const provincesOptions = provincesData?.data.map((province: Province) => ({
    value: province.id.toString(),
    label: province.name,
  }));

  const citiesOptions = citiesData?.data.map((city: City) => ({
    value: city.id.toString(),
    label: city.name,
  }));

  const categoriesOptions = categoriesData?.data?.map(
    (category: LoyaltyProgramServiceCategory) => ({
      value: category.id,
      label: category.name,
    })
  );
  const selectAllObject = {
    value: "",
    label: "Wszystkie",
  };

  categoriesOptions?.unshift(selectAllObject);
  citiesOptions?.unshift(selectAllObject);
  provincesOptions?.unshift(selectAllObject);

  const allProvinceByCategory: string[] = systemSettings
    .filter(
      (systemSetting: SystemSetting) =>
        systemSetting.type === "online_category_all_province"
    )
    .map((v: SystemSetting) => v.value);

  useEffect(() => {
    if (citiesOptions && getValues().city) {
      setValueCity(
        citiesOptions.find(
          (city) => String(city.value) === String(getValues().city)
        ) ?? null
      );
    } else {
      setValueCity(null);
    }
    // eslint-disable-next-line
  }, [watch("city"), citiesData]);

  useEffect(() => {
    const isAllProvince = allProvinceByCategory.includes(
      String(watch("category"))
    );
    setDisableLocation(isAllProvince);
    if (isAllProvince) {
      setValue("province", "");
      setValue("city", "");
    }
    // eslint-disable-next-line
  }, [watch("category"), categoriesData]);

  const handleCategoryChange = (
    newCategory: SingleValue<{
      value: string;
      label: string;
    } | null>
  ) => {
    setValue("category", newCategory?.value || "");
  };
  const handleProvinceChange = (
    newProvince: SingleValue<{
      value: string;
      label: string;
    } | null>
  ) => {
    setValue("province", newProvince?.value || "");
    setValue("city", "");
    setValueCity(null);
  };
  const handleCityChange = (
    newCity: SingleValue<{
      value: string;
      label: string;
    } | null>
  ) => {
    setValue("city", newCity?.value || "");
    setValueCity(newCity);
  };

  const handleCategoryFilters: SubmitHandler<SubmitLoyaltyData> = (data) => {
    searchParams.set("category", data.category);
    searchParams.set("province", data.province);
    searchParams.set("city", data.city);
    setSearchParams(searchParams);

    !props.isService &&
      navigate(
        `/${NAV_PATH.LOYALTY_PROGRAM}/${
          NAV_PATH.SERVICE
        }?${searchParams.toString()}`
      );
  };

  return (
    <>
      <Row className={"fs-22 fw-bolder mb-5 text-dark-blue mx-0"}>
        {props.nameLabel}
      </Row>
      <Row className={"mb-4 mx-0"}>
        <Form className="px-0" onSubmit={handleSubmit(handleCategoryFilters)}>
          <Container
            fluid={true}
            className="p-0 m-0 d-flex flex-column flex-lg-row justify-content-between align-items-lg-end gap-4"
          >
            {categoriesOptions && (
              <div className={"w-100"}>
                <label className={"fs-16 pb-1 text-dark-blue"}>Kategoria</label>
                <Select
                  classNamePrefix="select"
                  defaultValue={
                    getValues().category === ""
                      ? null
                      : categoriesOptions.find(
                          (category: any) =>
                            String(category.value) ===
                            String(getValues().category)
                        )
                  }
                  placeholder="Wszystkie"
                  isClearable={false}
                  isSearchable={true}
                  name="category"
                  options={categoriesOptions}
                  onChange={handleCategoryChange}
                  components={{ DropdownIndicator }}
                  styles={customStyles(false)}
                />
              </div>
            )}
            {provincesOptions && (
              <div className={"w-100"}>
                <label className={"fs-16 pb-1 text-dark-blue"}>
                  Województwo
                </label>
                <Select
                  classNamePrefix="select"
                  isDisabled={disableLocation}
                  value={
                    watch("province") === "" || disableLocation
                      ? null
                      : provincesOptions.find(
                          (province: any) =>
                            String(province.value) ===
                            String(getValues().province)
                        )
                  }
                  placeholder="Wszystkie"
                  isClearable={false}
                  isSearchable={true}
                  name="province"
                  options={provincesOptions}
                  onChange={handleProvinceChange}
                  styles={customStyles(false)}
                  components={{ DropdownIndicator }}
                />
              </div>
            )}
            {provincesOptions && (
              <div className={"w-100"}>
                <label className={"fs-16 pb-1 text-dark-blue"}>Miasto</label>
                <Select
                  classNamePrefix="select"
                  value={valueCity}
                  placeholder="Wszystkie"
                  isClearable={false}
                  isSearchable={true}
                  isDisabled={watch("province") === ""}
                  name="city"
                  options={citiesOptions}
                  onChange={handleCityChange}
                  styles={customStyles(false)}
                  components={{ DropdownIndicator }}
                />
              </div>
            )}
            <Button
              className={"h-50 text-white"}
              variant="primary"
              style={{ minWidth: 100 }}
              type={"submit"}
              value="Submit"
            >
              SZUKAJ
            </Button>
          </Container>
        </Form>
      </Row>
    </>
  );
};
