import { useState } from "react";
import style from "./ImageGrid.module.scss";
import ImageModal from "components/organisms/ImageModal/ImageModal";

const ImageGrid = ({
  children,
  data,
  setContent,
  setShowParentModal,
  setSelectedImage,
}: childrenProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={`${style.imageWrapper}`} onClick={() => setShow(true)}>
        {children}
      </div>
      <ImageModal
        setContent={setContent}
        show={show}
        setShow={setShow}
        data={data}
        setSelectedImage={setSelectedImage}
        setShowParentModal={setShowParentModal}
      />
    </>
  );
};

export default ImageGrid;
