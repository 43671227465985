import { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import styles from "./LinkPreview.module.scss";
import { ReactComponent as Close } from "../../../assets/Icons/Close.svg";
import { YoutubeWidget } from "./OtherPlatformWidgets/YoutubeWidget";
import { TwitterWidget } from "./OtherPlatformWidgets/TwitterWidget";
import { usePostFeedURLMutation } from "../../../redux/services/feedApi";

export const LinkPreview = ({ content, isAddingPost }: LinkPreviewProps) => {
  const [contentLink, setContentLink] = useState<string | null>();
  const [URLContent, setURLContent] = useState<IURLContent>();
  const [otherPlatformId, setOtherPlatformId] = useState<{
    youtube: string | null;
    twitter: string | null;
  }>({ youtube: null, twitter: null });

  const [postFeedURL, { data: feedURLRes, isSuccess: feedURLResOk }] =
    usePostFeedURLMutation();

  useEffect(() => {
    if (contentLink && contentLink.length > 0) {
      postFeedURL({ data: { url: contentLink } });
    }
    // eslint-disable-next-line
  }, [contentLink]);

  useEffect(() => {
    const handleDisplayingLinks = async (url: string) => {
      if (url && url?.length > 0 && new URL(url)) {
        const ytId = new URL(url)?.searchParams?.get("v");

        const splittedURL = url.split("/");
        const twtId = splittedURL[splittedURL.length - 1];

        if (feedURLResOk && feedURLResOk) {
          if (ytId && url.includes("youtube")) {
            setOtherPlatformId({
              youtube: ytId,
              twitter: null,
            });
          } else if (twtId && url.includes("twitter")) {
            setOtherPlatformId({
              youtube: null,
              twitter: twtId,
            });
          } else {
            const urlWithoutProtocol = contentLink
              ?.replace("https://", "")
              .replace("http://", "")
              .replace("www.", "");
            const shortenedURL = urlWithoutProtocol
              ?.split("/")[0]
              .toUpperCase();
            setURLContent({
              title: feedURLRes?.data?.ogTitle ?? "",
              image: feedURLRes?.data?.ogImage?.[0]?.url ?? "",
              ready: feedURLRes?.data?.ogImage?.length > 0,
              url: shortenedURL ?? "",
            });
          }
        }
      }
    };

    const postContentWords = content?.replace(/\n/g, " ")?.split(" ");

    postContentWords?.forEach((word: string) => {
      if (
        word.match(
          /^(?:(?:https?:\/\/)?(?:www\.)?|www\.)[\w.-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/,
        )
      ) {
        if (word.split(".")[0] === "www") {
          const link = "http://" + word;
          setContentLink(link);
          handleDisplayingLinks(link);
        } else {
          setContentLink(word);
          handleDisplayingLinks(word);
        }
      }
    });
    // eslint-disable-next-line
  }, [
    otherPlatformId.youtube,
    otherPlatformId.twitter,
    feedURLResOk,
    feedURLRes,
    postFeedURL,
    content,
  ]);

  const renderLinkPreview = () => (
    <div className={`${styles.contentImage} ${styles.urlImage}`}>
      {isAddingPost && (
        <Button
          className={`border border-primary border-2 d-flex justify-content-center align-items-center ${styles.contentImageCloseButton}`}
          onClick={() =>
            setURLContent({
              title: "",
              image: "",
              ready: false,
              url: "",
            })
          }
        >
          <Close />
        </Button>
      )}
      <div
        className={`d-flex flex-wrap fw-600 cursor-pointer col-auto ${
          !isAddingPost && "mx-4"
        }`}
        onClick={() => contentLink && window.open(contentLink, "_blank")}
      >
        {URLContent?.ready && (
          <>
            <Image fluid src={URLContent?.image} alt="uploaded-file"></Image>
          </>
        )}
        <div
          className={`d-flex flex-wrap fw-600 py-2 cursor-pointer px-4 rounded-2 col-12 ${styles.urlContentWrapper}`}
        >
          <span
            className={`col-12 text-grey-2 py-1 fw-600 fs-12 ${styles.urlContentTitle}`}
          >
            {URLContent?.url}
          </span>
          <span className="col-12 text-dark-blue fw-600 fs-19">
            {URLContent?.title}
          </span>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {URLContent &&
      URLContent?.title.length > 0 &&
      !otherPlatformId.youtube &&
      !otherPlatformId.twitter ? (
        renderLinkPreview()
      ) : otherPlatformId.youtube ? (
        <YoutubeWidget id={otherPlatformId.youtube} />
      ) : (
        otherPlatformId.twitter && (
          <TwitterWidget id={otherPlatformId.twitter} />
        )
      )}
    </>
  );
};
