import CardCodesTable from "components/organisms/CardCodesTable/CardCodesTable";
import { Container } from "react-bootstrap";
import { useGetCardCodesQuery } from "redux/services/cardCodesApi";
import CustomPagination from "components/organisms/CustomPagination/CustomPagination";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SelectInputForAdmin from "components/atoms/SelectInputForAdmin/SelectInputForAdmin";
import { FILTER_CARD_CODES } from "./CardCodesFilter.consts";
import ExportCSV from "components/molecules/ExportCSV/ExportCSV";
import { HEADER_TABLE_NAMES } from "components/organisms/CardCodesTable/CardCodesTable.consts";
import CreateCardCodesManually from "components/atoms/CreateCardCodesManually/CreateCardCodesManually";
import CreateCardCodes from "components/atoms/CreateCardCodes/CreateCardCodes";

const CardCodesTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get(`page`)) || 1
  );

  const LIMIT = 20;

  const { data: cardCodesData } = useGetCardCodesQuery({
    offset: currPage ? ((currPage - 1) * LIMIT).toString() : "0",
    limit: LIMIT.toString(),
    view: searchParams.get("assignedType") || "",
  });
  const { data: cardCodesDataToCSV } = useGetCardCodesQuery({
    offset: "0",
    limit: "0",
    view: searchParams.get("assignedType") || "",
  });

  const handlePageChange = (page: number) => {
    searchParams.set(`page`, page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const onChangeAssignedFiltr = (e: React.ChangeEvent<HTMLSelectElement>) => {
    searchParams.set("assignedType", e.target.value);
    setSearchParams(searchParams);
  };

  return (
    <Container as="section" fluid className="px-4 pt-2 bg-light pe-5">
      <div className="mt-2 mx-4 w-100 pb-5">
        <h2>Karty kodów</h2>
        <div className="me-5 my-4 d-flex flex-column gap-2">
          <SelectInputForAdmin
            data={FILTER_CARD_CODES.ASSIGNED_TYPES}
            id="assignedTypeFilter"
            label="Filtruj wg przypisania"
            onChange={onChangeAssignedFiltr}
          />
        </div>
        <div className="col bd-highlight my-4">
          <CardCodesTable data={cardCodesData?.data} />
          <div className="d-flex justify-content-between">
            <CustomPagination
              totalCount={
                (!!cardCodesData && cardCodesData?.meta?.totalResults) || 0
              }
              pageSize={LIMIT}
              currentPage={currPage}
              onPageChange={(e: number) => handlePageChange(e)}
            />
            <ExportCSV
              fileName="KARTY_KODÓW"
              headerTable={HEADER_TABLE_NAMES}
              data={cardCodesDataToCSV?.data}
            />
          </div>
        </div>
        <h2 className="mt-5">Generowanie kart kodów</h2>
        <div className="d-flex gap-3 flex-column">
          <CreateCardCodes />
          <CreateCardCodesManually />
        </div>
      </div>
    </Container>
  );
};

export default CardCodesTemplate;
