import RadioInput from "components/atoms/RadioInput/RadioInput";
import TextInput from "components/atoms/TextInput/TextInput";
import AsyncMultiSelect from "components/molecules/AsyncMultiSelect/AsyncMultiSelect";
import ComponentWithDescription from "components/molecules/ComponentWithDescription/ComponentWithDescription";
import { enums } from "Helpers/enums";
import { replacePolishLetters } from "Helpers/replacePolishLetters";
import { Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CSSObjectWithLabel } from "react-select";
import { toast } from "react-toastify";
import {
  usePatchCancerUpdateMutation,
  usePostCancerMutation,
} from "redux/services/cancerApi";
import { useLazyGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { useEffect } from "react";

interface CancerEditTemplateProps {
  data: CancerData;
  cancerCategories: {
    data: CancerCategory[];
  };
  newCancer?: boolean;
}

const CancerEditTemplate = ({
  data,
  cancerCategories,
  newCancer,
}: CancerEditTemplateProps) => {
  const navigate = useNavigate();

  const [patchCancer] = usePatchCancerUpdateMutation();
  const [postCancer] = usePostCancerMutation();

  const { t } = useTranslation();

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: data?.name ?? "",
      slug: data?.slug ?? "",
      code: data?.code ?? "",
      displayOrder: data?.displayOrder || 999999999,
      status: data?.status?.toString() || "1",
      deleted: data?.deleted ?? 0,
      categoryIds: data?.categories?.map((category) => category.id),
    },
    values: data && {
      name: data.name,
      slug: data.slug,
      code: data.code,
      displayOrder: data.displayOrder,
      status: data.status.toString(),
      deleted: data.deleted,
      categoryIds: data.categories?.map((category) => category.id),
    },
  });

  const submitHandler = async (submitData: CancerSubmitFormData) => {
    const postData = {
      ...submitData,
      code: submitData.code || null,
      displayOrder: +submitData.displayOrder,
      status: +submitData.status,
    };

    const cancer = newCancer
      ? await postCancer({
          data: postData,
        })
      : await patchCancer({
          id: data.id,
          data: postData,
        });

    if ("data" in cancer) {
      toast.success(
        newCancer ? "Nowotwór dodany pomyślnie" : "Nowotwór edytowany pomyślnie"
      );
      navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}`);
    } else if ("error" in cancer) {
      toast.error(t("toast_cancerEditTemplate_error"));
    }
  };

  useEffect(() => {
    if (watch("displayOrder") > 999999999) {
      setValue("displayOrder", 999999999);
    }
    // eslint-disable-next-line
  }, [watch("displayOrder")]);

  return (
    <Container
      as="section"
      fluid
      className="pt-2 px-4 m-0 d-flex flex-column w-100 gap-3"
    >
      <h1 className="pe-3 fs-2 my-3">
        {newCancer ? "Dodaj nowotwór" : "Edytuj nowotwór"}
      </h1>
      <form onSubmit={handleSubmit(submitHandler)}>
        <ComponentWithDescription
          className="my-2 w-100"
          label="Nazwa"
          desc="Nazwa jak pojawia się w witrynie."
        >
          <TextInput
            inputWrapperClassname="w-100"
            placeholder="Nazwa"
            register={register("name", {
              onChange: (e) => {
                newCancer &&
                  setValue("slug", replacePolishLetters(e.target.value));
              },
            })}
          />
        </ComponentWithDescription>

        <ComponentWithDescription
          className="my-2"
          label="Uproszczona nazwa"
          desc="„Uproszczona nazwa” jest przyjazną dla adresu URL wersją nazwy. Zwykle składa się wyłącznie z małych liter, cyfr i myślników."
        >
          <TextInput
            inputWrapperClassname="w-100"
            placeholder="slug"
            register={register("slug")}
          />
        </ComponentWithDescription>

        <ComponentWithDescription
          className="my-2"
          label="Kod"
          desc="Oznakowanie nowotworu pozwalające na jego szybką identyfikację."
        >
          <TextInput
            inputWrapperClassname="w-100"
            placeholder="Kod"
            register={register("code")}
          />
        </ComponentWithDescription>

        <ComponentWithDescription
          className="my-2"
          label="Kolejność wyświetlania"
          desc="Kolejność nowotworu w listach rozwijalnych (select)."
        >
          <TextInput
            inputWrapperClassname="w-100"
            type="number"
            min={1}
            max={999999999}
            placeholder="Kolejność wyświetlania"
            register={register("displayOrder")}
          />
        </ComponentWithDescription>

        <div className="w-100 d-flex flex-column gap-2  justify-content-center">
          <div className="pe-5 d-flex">
            <span style={{ width: 80 }}>Status</span>
            <Container className="d-inline-flex">
              {enums.cancerStatus.map((item) => (
                <RadioInput
                  key={item.id}
                  register={register("status", {
                    required: true,
                  })}
                  label={item.name}
                  value={item.id}
                  customClasses="me-md-5"
                />
              ))}
            </Container>
          </div>
        </div>

        <div className="accordion-item mt-3">
          <h2 className="accordion-header" id="headingTwo">
            <Button
              className="btn-outline-blue mb-2"
              data-bs-toggle="collapse"
              data-bs-target="#collapse3"
              aria-expanded="false"
              aria-controls="collapse3"
            >
              Kategorie
            </Button>
          </h2>
          <div
            id="collapse3"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {cancerCategories && (
                <AsyncMultiSelect
                  labelStyles={{ width: "100%" }}
                  inputStyles={{
                    container: (provided: CSSObjectWithLabel) => ({
                      ...provided,
                      width: "100%",
                    }),
                  }}
                  data={cancerCategories}
                  setValue={setValue}
                  name="categoryIds"
                  value={data?.categories.map((category) => ({
                    label: category.title,
                    value: category.id,
                  }))}
                  relativeoptions
                  menuIsOpen
                  asyncQuery={useLazyGetPagesCategoryQuery}
                  type="page"
                />
              )}
            </div>
          </div>
        </div>
        <Button type="submit" value="Submit" className="mt-4">
          Zapisz zmiany
        </Button>
      </form>
    </Container>
  );
};

export default CancerEditTemplate;
