import { useState, useMemo } from "react";
import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import { useGetPollUsersQuery } from "redux/services/pollUsersApi";
import CustomPagination from "../CustomPagination/CustomPagination";
import PollAnswersTable from "../Tables/PollAnswerTable/PollAnswerTable";
import Select from "react-select";
import styles from "./PollAnswersDisplay.module.scss";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";

export default function PollAnswersDisplay() {
  const [currentPage, setCurrentPage] = useState(1),
    PAGE_SIZE = 10,
    offset = (currentPage - 1) * PAGE_SIZE;
  const [sortField, setSortField] = useState<string | null>(null),
    [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");
  const [selectedPollId, setSelectedPollId] = useState<number | null>(null),
    [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [emailFilter, setEmailFilter] = useState<string>(""),
    [startDate, setStartDate] = useState<string>(""),
    [endDate, setEndDate] = useState<string>(""),
    [ipAddressFilter, setIpAddressFilter] = useState<string>(""),
    [specificIdFilter, setSpecificIdFilter] = useState<number | null>(null);

  const { data: allPollUsers } = useGetPollUsersQuery({
    limit: "0",
    sortOrder: "DESC",
  });

  const pollIds = useMemo(() => {
    const uniquePolls =
      allPollUsers?.pollUsers?.reduce(
        (acc: OptionType[], user: PollDisplayData) => {
          const pollId = user.poll?.id;
          const pollTitle = user.poll?.title;
          if (
            pollId != null &&
            pollTitle != null &&
            !acc.some((option) => option.value === pollId)
          ) {
            acc.push({ label: `${pollId} - ${pollTitle}`, value: pollId });
          }
          return acc;
        },
        []
      ) || [];

    return uniquePolls;
  }, [allPollUsers]);

  const userIds = useMemo(
    () =>
      Array.from(
        new Set(
          allPollUsers?.pollUsers?.map(
            (user: PollDisplayData) => user.user?.id
          ) || []
        )
      ).map((id) => ({ label: id?.toString() as string, value: id as number })),
    [allPollUsers]
  );

  const filteredPollUsers =
    allPollUsers?.pollUsers?.filter((user: PollDisplayData) => {
      const userDate = new Date(user?.createdAt),
        filterStartDate = startDate ? new Date(startDate) : null,
        filterEndDate = endDate ? new Date(endDate) : null;
      return (
        (selectedPollId ? user.poll?.id === selectedPollId : true) &&
        (selectedUserId ? user.user?.id === selectedUserId : true) &&
        (nameFilter
          ? user.user?.name?.toLowerCase().includes(nameFilter?.toLowerCase())
          : true) &&
        (emailFilter
          ? user.user?.email?.toLowerCase().includes(emailFilter?.toLowerCase())
          : true) &&
        (filterStartDate ? userDate >= filterStartDate : true) &&
        (filterEndDate ? userDate <= filterEndDate : true) &&
        (ipAddressFilter
          ? user.ip?.toLowerCase().includes(ipAddressFilter?.toLowerCase())
          : true) &&
        (specificIdFilter
          ? user.id.toString().includes(specificIdFilter.toString())
          : true)
      );
    }) || [];

  const paginatedPollUsers = filteredPollUsers.slice(
    offset,
    offset + PAGE_SIZE
  );

  const clearDates = () => {
    setStartDate("");
    setEndDate("");
    setCurrentPage(1);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <SectionTitle className="me-4">Wyniki ankiet</SectionTitle>
      <Container fluid className="px-0 mb-2">
        <Row className="g-2">
          <Col className="col-9">
            <Row className="g-2 row-cols-3">
              <Col>
                <Form.Control
                  type="number"
                  className="form-control"
                  placeholder="ID"
                  onChange={(e) => {
                    setSpecificIdFilter(Number(e.target.value) || null);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Adres IP"
                  value={ipAddressFilter}
                  onChange={(e) => {
                    setIpAddressFilter(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Select
                  options={pollIds}
                  onChange={(selectedOption: OptionType | null) => {
                    setSelectedPollId(
                      selectedOption ? selectedOption.value : null
                    );
                    setCurrentPage(1);
                  }}
                  placeholder="ID Ankiety"
                  isClearable={true}
                />
              </Col>

              <Col>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Nazwa użytkownika"
                  value={nameFilter}
                  onChange={(e) => {
                    setNameFilter(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="E-mail użytkownika"
                  value={emailFilter}
                  onChange={(e) => {
                    setEmailFilter(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Select
                  options={userIds}
                  onChange={(selectedOption: OptionType | null) => {
                    setSelectedUserId(
                      selectedOption ? selectedOption.value : null
                    );
                    setCurrentPage(1);
                  }}
                  placeholder="ID Użytkownika"
                  isClearable={true}
                />
              </Col>
            </Row>
          </Col>
          <Col className={`col-3 ${styles.dateFilter}`}>
            <Row className="g-2 row-cols-1">
              <Col>
                <Form.Control
                  type="datetime-local"
                  className="form-control"
                  placeholder="Start Date & Time"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="datetime-local"
                  className="form-control"
                  placeholder="End Date & Time"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </Col>
              <Col>
                <Button className="w-100" onClick={clearDates}>
                  Wyczyść daty
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PollAnswersTable
        pollUsers={paginatedPollUsers}
        sortField={sortField}
        sortOrder={sortOrder}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
      />
      <div
        className={`mt-2 d-flex justify-content-center align-items-center flex-wrap ${styles.marginReset}`}
      >
        <CustomPagination
          totalCount={filteredPollUsers.length}
          pageSize={PAGE_SIZE}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          paginationClassName="text-nowrap"
        />
        <p className="ms-2 text-grey mb-0">
          Znaleziono ankiet: {filteredPollUsers.length}
        </p>
      </div>
    </div>
  );
}
