import TranslationSubmissionForm from "components/organisms/TranslationSubmissionForm/TranslationSubmissionForm";
import { useState, ReactNode } from "react";
import { Button, Card, CloseButton } from "react-bootstrap";
import { useGetLanguagesQuery } from "redux/services/languagesApi";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { useGetSystemsQuery } from "redux/services/systemsApi";

interface Props {
  className?: string;
  children?: ReactNode;
}

const NewTranslationCreationTab: React.FC<Props> = ({
  className,
  children,
}) => {
  const [isBodyOpen, setIsBodyOpen] = useState<boolean>(false);

  const { data: languagesOptions } = useGetLanguagesQuery({
    offset: 0,
    limit: null,
  });

  const { data: systemsData } = useGetSystemsQuery({});

  return (
    <Card className={className}>
      <Card.Header className="container-fluid p-3 d-flex justify-content-center">
        {!isBodyOpen && (
          <Button
            className="col col-md-8 col-lg-6 d-flex justify-content-center align-items-center"
            onClick={() => setIsBodyOpen(true)}
          >
            <PlusIcon className="me-2 text-white" />
            <span className="text-uppercase text-light lh-base">
              Dodaj tłumaczenie
            </span>
          </Button>
        )}
        {isBodyOpen && (
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <h3 className="m-0 lh-sm">Utwórz tłumaczenie</h3>
            <CloseButton onClick={() => setIsBodyOpen(false)} />
          </div>
        )}
      </Card.Header>
      {isBodyOpen && (
        <Card.Body>
          <TranslationSubmissionForm
            languageOptions={languagesOptions?.data}
            systemOptions={systemsData?.data}
          />
        </Card.Body>
      )}
    </Card>
  );
};

export default NewTranslationCreationTab;
