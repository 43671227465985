import { Button, Col, Container, Form, Spinner, Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styles from "./ChangesLoyaltyProgramServiceTemplate.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import {
  useGetLoyaltyProgramsServiceCategoryQuery,
  useGetLoyaltyProgramsServiceQuery,
  usePatchLoyaltyProgramServiceMutation,
} from "../../../redux/services/loyaltyProgramApi";
import { ChangesLoyaltyProgramsServiceConfig } from "./ChangesLoyaltyProgramsServiceConfig";
import { Changes } from "../../organisms/Changes/Changes";
import { ChangesImage } from "../../organisms/ChangesImage/ChangesImage";
import { countMatchingKeys } from "../../../Helpers/isExistKey";
import { ChangesInfo } from "../../organisms/ChangesInfo/ChangesInfo";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { domain } from "../../organisms/Landing/Landing";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select, { MultiValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { OptionSeparator } from "../../atoms/OptionSeparator/OptionSeparator";
import { LoyaltyProgramServiceCategory } from "../../molecules/LoyaltyProgramServiceArticle/types";
import { addObjectsBetweenKey } from "../../../Helpers/addObjectsBetweenKey";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import ChangesHtml from "components/organisms/ChangesHtml/ChangesHtml";

const ChangesLoyaltyProgramServiceTemplate: React.FC = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const navigate = useNavigate();

  const { data: loyaltyProgramsCompanyData, isLoading } =
    useGetLoyaltyProgramsServiceQuery({ id: Number(serviceId), status: 1 });
  const [patchLoyaltyProgramsService, { isSuccess, error }] =
    usePatchLoyaltyProgramServiceMutation();
  const [notification] = usePostNotificationsMutation();

  const [showModal, setShowModal] = useState(false);

  const { data: categoryData } = useGetLoyaltyProgramsServiceCategoryQuery({
    allService: 1,
    hidden: "1",
  });

  const categoryOptionsTest = categoryData?.data?.map(
    (loyaltyProgramServiceCategory: LoyaltyProgramServiceCategory) => ({
      value: loyaltyProgramServiceCategory?.id,
      label: loyaltyProgramServiceCategory?.name,
      priority: loyaltyProgramServiceCategory?.priority,
      slug: loyaltyProgramServiceCategory?.slug,
    })
  );

  const changes = isLoading
    ? false
    : JSON.parse(loyaltyProgramsCompanyData?.data?.changes);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyServiceFormData>({
    defaultValues: {
      category:
        loyaltyProgramsCompanyData?.data?.loyaltyProgramServiceCategory
          ?.map(({ id }: { id: number }) => id)
          ?.join(",") || "",
    },
  });

  useEffect(() => {
    if (loyaltyProgramsCompanyData) {
      setValue(
        "category",
        loyaltyProgramsCompanyData?.data?.loyaltyProgramServiceCategory
          ?.map(({ id }: { id: number }) => id)
          ?.join(",") || ""
      );
    }
    // eslint-disable-next-line
  }, [loyaltyProgramsCompanyData, categoryData]);

  useEffect(() => {
    (async () => {
      if (!isLoading && changes) {
        await patchLoyaltyProgramsService({
          id: serviceId,
          data: {
            changes: JSON.stringify({
              ...changes,
              startView: new Date().toISOString(),
            }),
          },
        });
      }
    })();
    // eslint-disable-next-line
  }, [isLoading]);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const categorySlug = categoryOptionsTest?.filter(
    (category: CategoryOption) => category.slug
  );

  const categoryOptions = addObjectsBetweenKey(
    categoryOptionsTest
      ?.filter((category: CategoryOption) => category.slug === null)
      ?.sort((a: CategoryOption, b: CategoryOption) => {
        if (a.priority === b.priority) {
          return a.label.localeCompare(b.label);
        }
        return a.priority - b.priority;
      }),
    "priority",
    <OptionSeparator value={"Kategorie premium"} />
  );

  categoryOptions.push(<OptionSeparator value={"Kategorie profilowania"} />);
  if (categorySlug) {
    for (const element of categorySlug) {
      categoryOptions.push(element);
    }
  }

  const handleAcceptAll = async () => {
    const obj: any = {};
    ChangesLoyaltyProgramsServiceConfig.forEach(({ keyName }) => {
      if (changes?.hasOwnProperty(keyName)) {
        obj[keyName] = changes[keyName];
      }
    });
    const hasBeenUpdated = await patchLoyaltyProgramsService({
      id: serviceId,
      data: { ...obj, status: 1, changes: null },
    });
    if ("data" in hasBeenUpdated) {
      toast.success(t("toast_changesLoyaltyProgramServiceTemplate_success"));
      if (serviceId) {
        await notification({
          data: {
            title: t(
              "notification_ChangesLoyaltyProgramServiceTemplate_accept_title"
            ).slice(0, 50),
            type: NotificationTypeEnum["loyalty-program"],
            url: `${domain}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${serviceId}`,
            viewSender: 0,
            senderUser: null,
            manyUser: "SERVICE",
            idMany: Number(serviceId),
          },
        });
        if (loyaltyProgramsCompanyData?.data?.company?.id) {
          await notification({
            data: {
              title: t(
                "notification_ChangesLoyaltyProgramServiceTemplate_accept_title"
              ).slice(0, 50),
              type: NotificationTypeEnum["loyalty-program"],
              url: `${domain}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${serviceId}`,
              viewSender: 0,
              senderUser: null,
              manyUser: "COMPANY",
              idMany: Number(loyaltyProgramsCompanyData?.data?.company?.id),
            },
          });
        }
      }
      navigate(-1);
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_changesLoyaltyProgramServiceTemplate_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_changesLoyaltyProgramServiceTemplate_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_changesLoyaltyProgramServiceTemplate_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };

  const handleStatus = async () => {
    await patchLoyaltyProgramsService({
      id: serviceId,
      data: { status: !loyaltyProgramsCompanyData?.data?.status ? 1 : 0 },
    });
  };

  const handleExit = async () => {
    const newChanges = { ...changes };
    delete newChanges.startView;
    await patchLoyaltyProgramsService({
      id: serviceId,
      data: {
        changes: JSON.stringify(newChanges),
      },
    });
    navigate(-1);
  };

  const handlePatch = async (obj: any, keyName: string) => {
    const newChanges = { ...changes };
    delete newChanges[keyName];
    if (
      countMatchingKeys(
        changes,
        ChangesLoyaltyProgramsServiceConfig.map(({ keyName }) => keyName)
      ) === 1
    ) {
      await patchLoyaltyProgramsService({
        id: serviceId,
        data: { ...obj, status: 1, changes: null },
      });
      if (serviceId) {
        await notification({
          data: {
            title: t(
              "notification_ChangesLoyaltyProgramServiceTemplate_accept_title"
            ).slice(0, 50),
            type: NotificationTypeEnum["loyalty-program"],
            url: `${domain}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${serviceId}`,
            viewSender: 0,
            senderUser: null,
            manyUser: "SERVICE",
            idMany: Number(serviceId),
          },
        });
        if (loyaltyProgramsCompanyData?.data?.company?.id) {
          await notification({
            data: {
              title: t(
                "notification_ChangesLoyaltyProgramServiceTemplate_accept_title"
              ).slice(0, 50),
              type: NotificationTypeEnum["loyalty-program"],
              url: `${domain}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${serviceId}`,
              viewSender: 0,
              senderUser: null,
              manyUser: "COMPANY",
              idMany: Number(loyaltyProgramsCompanyData?.data?.company?.id),
            },
          });
        }
      }
      navigate(-1);
    } else {
      const newOBJ = {
        id: serviceId,
        data: {
          ...obj,
          changes: JSON.stringify(newChanges),
        },
      };
      await patchLoyaltyProgramsService(newOBJ);
    }
  };
  const handleComments = async (comments: string) => {
    const newChanges = {
      ...changes,
      comments,
      commentsDate: new Date().toISOString(),
    };
    await patchLoyaltyProgramsService({
      id: serviceId,
      data: {
        changes: JSON.stringify(newChanges),
      },
    });
    if (loyaltyProgramsCompanyData?.data?.company?.id) {
      await notification({
        data: {
          title: t(
            "notification_ChangesLoyaltyProgramServiceTemplate_comments_title"
          ).slice(0, 50),
          type: NotificationTypeEnum["loyalty-program"],
          url: `${domain}/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${loyaltyProgramsCompanyData?.data?.company?.id}`,
          viewSender: 0,
          senderUser: null,
          manyUser: "COMPANY",
          idMany: Number(loyaltyProgramsCompanyData?.data?.company?.id),
        },
      });
    }
  };

  const submitHandler: SubmitHandler<CompanyServiceFormData | any> = async (
    data
  ) => {
    await patchLoyaltyProgramsService({
      id: serviceId,
      data,
    });
    if (isSuccess) {
      toast.success(t("toast_companyServiceForm_benefit_update_success"));
    } else if (error) {
      toast.error(t("toast_companyServiceForm_benefit_error"));
    }
  };

  return (
    <Container fluid={true} className="bg-light">
      <section className={`${styles.section}`}>
        <Stack className={"w-100 p-0 m-0"} gap={5}>
          <Col
            className={"d-flex align-items-center cursor-pointer pb-4"}
            onClick={() => navigate(-1)}
          >
            <Arrow
              style={{ transform: "rotate(180deg)" }}
              className={"text-primary"}
            />
            <span className={"ps-2 text-primary"}>
              WRÓĆ DO PRZEGLĄDANIA ZMIAN
            </span>
          </Col>
          <Col className={`${styles.wrapper}`}>
            <ChangesInfo
              changes={changes}
              status={loyaltyProgramsCompanyData?.data.status}
              handleStatus={handleStatus}
              handleComments={handleComments}
              isAdmin={true}
            />
          </Col>
          <Col className={`${styles.wrapper}`}>
            <Form className={"py-4"} onSubmit={handleSubmit(submitHandler)}>
              <Container fluid={true} className="">
                <p
                  className={
                    "w-100 text-dark-blue fs-22 fw-semibold text-center m-0 mb-3"
                  }
                >
                  Kategoria benefitu
                </p>

                <div
                  className={
                    "d-flex justify-content-between flex-column flex-lg-row gap-2 my-3 text-dark-blue"
                  }
                >
                  <Controller
                    name={"category"}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Pole wymagane",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={categoryOptions?.filter(
                          (obj: ReactSelectOption) =>
                            value?.split(",")?.includes(obj?.value?.toString())
                        )}
                        isMulti={true}
                        className={"w-100"}
                        classNamePrefix="select"
                        placeholder="Wybierz z listy"
                        isClearable={false}
                        isSearchable={true}
                        id="categoryService"
                        name="categoryService"
                        options={categoryOptions}
                        onChange={(options: MultiValue<ReactSelectOption>) => {
                          if (options?.length) {
                            const category = options
                              ?.map(({ value }: ReactSelectOption) =>
                                value !== 0 ? value : null
                              )
                              ?.filter(
                                (selectOption: ReactSelectOption) =>
                                  selectOption
                              )
                              ?.join(",");
                            return onChange(category);
                          }
                          return onChange("");
                        }}
                        styles={customStyles(!!errors?.category)}
                        components={{ DropdownIndicator }}
                        formatOptionLabel={(option, { context }) => {
                          if (context === "menu") {
                            if (option?.type === OptionSeparator) {
                              return option;
                            }
                            return option?.label;
                          }
                          return option?.label;
                        }}
                      />
                    )}
                  />
                  {errors?.category && (
                    <div className={"text-danger"}>
                      {errors?.category?.message}
                    </div>
                  )}
                </div>
                <div className={"d-flex gap-2 justify-content-end"}>
                  <Button
                    className={"fs-16 btn-outline-dark-blue"}
                    type="submit"
                    value="Submit"
                  >
                    Zapisz kategorie
                  </Button>
                </div>
              </Container>
            </Form>
          </Col>

          {ChangesLoyaltyProgramsServiceConfig.map(
            (value: ConfigChanges, index: number) => {
              switch (value.type) {
                case "normal":
                  return (
                    <Changes
                      key={index}
                      keyName={value?.keyName}
                      changes={changes}
                      data={loyaltyProgramsCompanyData?.data}
                      name={value?.name}
                      handlePatch={handlePatch}
                      handleComments={handleComments}
                      isAdmin={true}
                    />
                  );

                case "image":
                  return (
                    <ChangesImage
                      key={index}
                      keyName={value?.keyName}
                      changes={changes}
                      data={loyaltyProgramsCompanyData?.data}
                      name={value?.name}
                      maxHeight={value?.maxHeight || 0}
                      rounded={value?.rounded || false}
                      handlePatch={handlePatch}
                      handleComments={handleComments}
                      isAdmin={true}
                    />
                  );

                case "html":
                  return (
                    <ChangesHtml
                      key={index}
                      keyName={value?.keyName}
                      changes={changes}
                      data={loyaltyProgramsCompanyData?.data}
                      name={value?.name}
                      handlePatch={handlePatch}
                      handleComments={handleComments}
                      isAdmin={true}
                    />
                  );

                default:
                  return <></>;
              }
            }
          )}
          <div className={"d-flex gap-2 justify-content-end"}>
            <Button
              className={"fs-16 btn-outline-dark-blue"}
              onClick={handleExit}
            >
              WRÓĆ
            </Button>
            <Button
              className={"fs-16"}
              onClick={() => setShowModal((prev: boolean) => !prev)}
            >
              AKCEPTUJ CAŁOŚĆ
            </Button>
            <PopupModal
              text="Potwierdź całkowitą akceptację"
              smallText={"Po potwierdzeniu nie można powrócić"}
              type="confirm"
              show={showModal}
              setShow={() => setShowModal(false)}
              confirmAction={handleAcceptAll}
            />
          </div>
        </Stack>
      </section>
    </Container>
  );
};

export default ChangesLoyaltyProgramServiceTemplate;
