import { CommentInput } from "components/atoms/CommentInput/CommentInput";
import { ReplyIcon } from "components/atoms/ReplyIcon/ReplyIcon";
import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { ReactComponent as Clock } from "../../../assets/Icons/Clock.svg";
import { ReactComponent as ReportIcon } from "../../../assets/Icons/Report.svg";
import { Reply } from "../Reply/Reply";
import styles from "./Comment.module.scss";
import { CommentProps } from "./types";
import PopupModal from "../PopupModal/PopupModal";
import { toast } from "react-toastify";
import {
  useDeleteCommentMutation,
  usePostPostsCommentAbuseMutation,
} from "redux/services/feedApi";
import userAvatarImage from "../../../assets/images/userAvatarImage.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { ReactComponent as Trash } from "../../../assets/Icons/FeedTrash.svg";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { RejectModal } from "../../organisms/RejectModal/RejectModal";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import Linkify from "react-linkify";

export const Comment = ({
  id,
  postId,
  author,
  content,
  replies,
  isReply,
  className,
  commentWhenAdded,
  rerenderer,
  status,
}: CommentProps) => {
  const [replyStatus, setReplyStatus] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [displayedReplies, setDisplayedReplies] = useState<IReply[]>();
  const { authUser } = useSelector((state: RootState) => state.user);

  const commentRef = useRef<HTMLElement>(null);
  const { commentId } = useParams();

  const { t } = useTranslation();
  const STATUS_LABEL = t(`status_${status}`);

  const [deleteComment] = useDeleteCommentMutation();
  const [postCommentAbuse] = usePostPostsCommentAbuseMutation();

  const createdAt = commentWhenAdded ? new Date(commentWhenAdded) : 0;

  const transformedDate =
    commentWhenAdded &&
    formatDistanceToNow(Number(createdAt), {
      addSuffix: true,
      locale: pl,
    });

  useEffect(() => {
    if (commentId && commentRef) {
      const targetElement = document.getElementById(`commentId${commentId}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [commentId]);

  useEffect(() => {
    if (replies) {
      setDisplayedReplies(replies);
    }
  }, [replies, authUser.id, authUser.roles]);

  const handleChangeIconActionType = (type: string) => {
    if (type === "trash") {
      setPopup(true);
    } else if (type === "report") {
      setShowReject(true);
    }
  };

  const handleDeleteComment = async () => {
    if (id) {
      setPopup(false);
      const deleteCommentData = await deleteComment({ id: id.toString() });
      if ("data" in deleteCommentData) {
        toast.success(t("toast_comment_delete_success"));
        rerenderer();
      } else if ("error" in deleteCommentData) {
        const error = deleteCommentData?.error as TranslationSubmitError;

        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_comment_delete_error_500"));
            break;
          default:
            toast.error(t("toast_comment_delete_error"));
            break;
        }
      }
    }
  };

  const handleReport = async (content: string) => {
    if (id) {
      setPopup(false);
      if (author?.id !== authUser.id) {
        const commentAbuse = await postCommentAbuse({
          data: {
            reviewId: id,
            content,
          },
        });
        if ("data" in commentAbuse) {
          toast.success(`${t("toast_comment_report_success")} ${author.name}`);
        } else if ("error" in commentAbuse) {
          const error = commentAbuse?.error as TranslationSubmitError;

          switch (error.data.statusCode) {
            case 500:
              toast.error(t("toast_comment_report_error_500"));
              break;
            case 400:
              toast.error(t("toast_comment_report_error_400"));
              break;
            default:
              toast.error(t("toast_comment_report_error"));
              break;
          }
        }
      } else {
        toast.error(t("toast_comment_report_myself_error"));
      }
    }
  };

  return (
    <>
      <Row
        className={`px-4 mx-0 d-flex flex-wrap align-items-start ${
          styles.commentWrapper
        } ${className} ${commentId === id?.toString() && styles.scale}`}
        id={`commentId${id}`}
        ref={commentRef}
      >
        <div className={`${styles.commentAuthorAvatar} col-2 col-md-1`}>
          <img
            src={author?.avatar?.accessUrl ?? userAvatarImage}
            alt="author"
          ></img>
        </div>
        <div
          className={`${styles.commentAuthorNameAndContent} px-0 mx-0 col-9 col-md-10 d-flex flex-wrap`}
        >
          <Link
            to={`/${NAV_PATH.PROFILE}/${author?.authId}`}
            target={"_blank"}
            className={`${styles.commentAuthorName} p-0 m-0 w-auto fs-14 text-dark-blue`}
          >
            {author?.name ?? "Użytkownik Alivii"}
          </Link>
          <span className="mx-2 text-grey-2 fs-12 d-flex flex-wrap d-md-block">
            <span className="me-2">
              <Clock />
            </span>
            {commentWhenAdded && transformedDate}
          </span>
          <span>
            {status !== "ACCEPTED" && (
              <Row className="col-12 mx-0 px-0 my-1 fs-12">
                <span className="bg-secondary text-white rounded-2 fw-600 px-2">
                  {STATUS_LABEL}
                </span>
              </Row>
            )}
          </span>
          <p
            className={`fs-16 fw-400 m-0 w-100 pb-auto text-wrap text-grey text-break ${styles.commentReplyContent}`}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  href={decoratedHref}
                  key={key}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {decoratedText}
                </a>
              )}
            >
              {content}
            </Linkify>
          </p>
        </div>
        <div
          className={`${styles.commentReportIcon} col-1 px-0 d-flex justify-content-center align-items-start`}
        >
          <div>
            {author?.id === authUser.id ? (
              <Trash
                className="cursor-pointer"
                onClick={() => handleChangeIconActionType("trash")}
              />
            ) : (
              <ReportIcon
                className="cursor-pointer"
                onClick={() => handleChangeIconActionType("report")}
              />
            )}
          </div>
        </div>
        {!isReply && (
          <Row
            className={`${styles.commentReplyActionContainer} col-12 d-flex flex-wrap justify-content-end align-items-center my-2 mx-0 px-0`}
          >
            <div
              className={`col-10 col-md-11 mx-0 px-0`}
              onClick={() => setReplyStatus(!replyStatus)}
            >
              <div
                className={`${
                  replyStatus && "bg-primary"
                } cursor-pointer d-flex flex-wrap align-items-center rounded-1 justify-content-start mx-0 p-1 col-auto ${
                  styles.commentReplyAction
                }`}
              >
                <span className="w-auto d-flex justify-content-start px-0 mx-0">
                  <ReplyIcon fill={!replyStatus ? "#6C757D" : "#fff"} />
                </span>
                <span
                  className="px-0 mx-2 fw-medium"
                  style={
                    !replyStatus ? { color: "#6C757D" } : { color: "#fff" }
                  }
                >
                  Odpowiedz
                </span>
              </div>
            </div>
          </Row>
        )}
        {replyStatus && (
          <Row className="my-3 d-flex justify-content-end col-12 px-0 mx-0">
            <CommentInput
              placeholder="Napisz odpowiedź..."
              className={`${styles.replyInput} col-11`}
              commentId={id}
              postId={postId}
              rerenderer={rerenderer}
              isInputVisible={setReplyStatus}
              reply
              author={author}
            />
          </Row>
        )}
      </Row>
      <div className="px-0 mx-0 d-flex justify-content-end flex-wrap">
        {displayedReplies?.map((reply: IReply) => {
          return (
            <Reply
              className="col-sm-11 col-10 my-2"
              content={reply.content}
              author={reply.user}
              status={reply.status}
              commentWhenAdded={reply.createdAt}
              key={reply.id}
              id={reply.id}
              rerenderer={rerenderer}
            />
          );
        })}
      </div>
      <RejectModal
        label={"Zgłoszenie"}
        desc={"Podaj powód zgłoszenia"}
        show={showReject}
        handleClose={() => setShowReject((prev) => !prev)}
        handleComments={handleReport}
        comments={""}
      />
      <PopupModal
        show={popup}
        setShow={setPopup}
        text={"Czy na pewno chcesz usunąć Twój komentarz?"}
        type="confirm"
        confirmAction={handleDeleteComment}
      />
    </>
  );
};
