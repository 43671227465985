import { Container } from "react-bootstrap";
import { CompanyServiceForm } from "components/molecules/CompanyServiceForm/CompanyServiceForm";
import { useParams } from "react-router-dom";
import { useGetLoyaltyProgramsServiceQuery } from "redux/services/loyaltyProgramApi";
import { useNavigate } from "react-router-dom";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const ServiceEditTemplate = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const { data: serviceData } = useGetLoyaltyProgramsServiceQuery({
    id: +id!,
  });

  const handleClose = () => {
    navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.COMPANIES}`);
  };

  return (
    <Container fluid className="bg-light">
      <h2 className="px-2 fs-1 lh-base fw-600 text-dark me-4 pt-2">
        Edycja benefitu
      </h2>
      <div className="p-5">
        <CompanyServiceForm
          data={serviceData?.data}
          isUpdate
          handleClose={handleClose}
        />
      </div>
    </Container>
  );
};

export default ServiceEditTemplate;
