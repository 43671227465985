import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import CancerEditTemplate from "components/templates/CancerEditTemplate/CancerEditTemplate";
import { useLocation, useParams } from "react-router-dom";
import { useGetCancerQuery } from "redux/services/cancerApi";
import { useGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";

const CancerNewPage = () => {
  const location = useLocation();
  const linkParams = useParams();

  const { data: cancerData } = useGetCancerQuery(
    { id: linkParams.id },
    {
      skip: location.pathname === `/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}/new`,
    }
  );
  const { data: cancerCategories } = useGetPagesCategoryQuery({
    type: "cancer",
  });

  return (
    <CancerEditTemplate
      newCancer={
        location.pathname === `/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}/new`
      }
      data={cancerData?.data}
      cancerCategories={cancerCategories}
    />
  );
};

export default CancerNewPage;
