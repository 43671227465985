import { facilities } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface FacilitiesProps {
  limit?: string;
  offset?: string;
  cityId?: string;
  provinceId?: string;
  status?: string;
  sortBy?: string;
  nfzBufforAssigned?: boolean;
  nfzBufforNotAssigned?: boolean;
  nfzBufforId?: string;
  nfzLocalityLike?: string;
  nfzAdressLike?: string;
  nfzTerytPlace?: string;
  nfzNipProvider?: string;
  nameLike?: string;
  nfzProviderLike?: string;
}

interface GetFacilityPayload {
  id: number;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Facilities"],
});

export const facilitiesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getFacilities: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        cityId,
        provinceId,
        nfzProviderLike,
        nameLike,
        nfzNipProvider,
        nfzTerytPlace,
        nfzAdressLike,
        nfzLocalityLike,
        nfzBufforId,
        nfzBufforAssigned,
        nfzBufforNotAssigned,
        status,
      }: FacilitiesProps) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(cityId && { cityId }),
          ...(provinceId && { provinceId }),
          ...(nfzProviderLike && { nfzProviderLike }),
          ...(nameLike && { nameLike }),
          ...(nfzNipProvider && { nfzNipProvider }),
          ...(nfzTerytPlace && { nfzTerytPlace }),
          ...(nfzAdressLike && { nfzAdressLike }),
          ...(nfzLocalityLike && { nfzLocalityLike }),
          ...(nfzBufforId && { nfzBufforId }),
          ...(nfzBufforAssigned && {
            nfzBufforAssigned: nfzBufforAssigned.toString(),
          }),
          ...(nfzBufforNotAssigned && {
            nfzBufforNotAssigned: nfzBufforNotAssigned.toString(),
          }),
          ...(status && { status }),
        });

        return {
          url: `${facilities}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Facilities"],
    }),

    getFacility: builder.query({
      query: ({ id }: GetFacilityPayload) => {
        return {
          url: `${facilities}/${id.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Facilities"],
    }),

    getFacilityReview: builder.query({
      query: ({ id }: GetFacilityPayload) => {
        return {
          url: `${facilities}/${id}/reviews?status=ACCEPTED&source=2`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Facilities"],
    }),
  }),
});

export const {
  useGetFacilitiesQuery,
  useLazyGetFacilitiesQuery,
  useGetFacilityQuery,
  useGetFacilityReviewQuery,
} = facilitiesApi;
