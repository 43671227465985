import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { CompanyServiceEntity } from "../../molecules/CompanyServiceEntity/CompanyServiceEntity";
import { LoyaltyProgramServiceDataInterface } from "../../templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import CustomPagination from "../CustomPagination/CustomPagination";
import { CompanyServiceForm } from "../../molecules/CompanyServiceForm/CompanyServiceForm";
import { useGetLoyaltyProgramsServiceQuery } from "../../../redux/services/loyaltyProgramApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import styles from "./CompanyService.module.scss";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";

interface CompanyServiceProps {
  data: any;
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  reFetchCompany: () => QueryActionCreatorResult<
    QueryDefinition<
      string | undefined,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
  isNewBenefit: boolean;
  setIsNewBenefit: Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
}

const LIMIT = 5;

export const CompanyService = ({
  data,
  handleActive,
  handleFirst,
  isActive,
  isFirst,
  isNewBenefit,
  reFetchCompany,
  setIsNewBenefit,
  isEdit,
  setIsEdit,
}: CompanyServiceProps) => {
  const [dataService, setDataService] = useState<any>();

  const [currPage, setCurrPage] = useState(1);
  const [isActiveOnly, setIsActiveOnly] = useState(false);
  const [arrayService, setArrayService] = useState([]);
  const [serviceId, setServiceId] = useState<number | false>();
  const [isCopy, setIsCopy] = useState(false);

  const {
    refetch,
    data: loyaltyProgramsServiceCompanyData,
    isLoading: isLoadingService,
    isFetching,
  } = useGetLoyaltyProgramsServiceQuery(
    serviceId ? { id: serviceId, status: 1 } : skipToken
  );

  useEffect(() => {
    if (loyaltyProgramsServiceCompanyData) {
      setDataService(loyaltyProgramsServiceCompanyData?.data);
    }
    // eslint-disable-next-line
  }, [isLoadingService, isFetching, serviceId]);

  useEffect(() => {
    if (serviceId) {
      refetch();
    }
    // eslint-disable-next-line
  }, [serviceId]);

  useEffect(() => {
    if (data) {
      setArrayService(
        data
          .map((value: LoyaltyProgramServiceDataInterface) => ({
            ...value,

            isActive: !!(
              (value.daysToEnd === null || value.daysToEnd >= 0) &&
              (value.promoCodesEndAt === null ||
                (value.promoCodesEndAt &&
                  new Date() < new Date(value.promoCodesEndAt))) &&
              value.deleted === 0 &&
              value.departmentCount > 0 &&
              (value?.multipleUseCodesAllowed === 1 ||
                value.promoCodesAmount === null ||
                (typeof value.promoCodesAmount === "number" &&
                  value.promoCodesAmount > value.codeUsageCount))
            )
              ? value.status === 1
                ? 2
                : 1
              : 0,
          }))
          .filter((value: LoyaltyProgramServiceDataInterface) => {
            if (!isActiveOnly) {
              return !!value.isActive;
            } else {
              return true;
            }
          })
          .sort(
            (
              a: LoyaltyProgramServiceDataInterface,
              b: LoyaltyProgramServiceDataInterface
              // eslint-disable-next-line
            ) => {
              if (
                typeof b.isActive === "number" &&
                typeof a.isActive === "number"
              ) {
                return b.isActive - a.isActive;
              }
            }
          )
      );
    }
  }, [data, isActiveOnly]);

  const handleClose = () => {
    handleActive(false);
    if (data.length === 0 && isFirst) {
      handleFirst(true);
    } else {
      handleFirst(false);
    }
    setIsEdit(false);
    setDataService(null);
    setServiceId(false);
    setIsCopy(false);
    setIsNewBenefit(false);
  };

  const handleChange = (e: any) => {
    handleActive(true);
    setServiceId(e.id);
    setIsEdit(true);
  };

  const handlePage = (e: number) => {
    setCurrPage(e);
  };

  const handleEdit = () => {
    setIsEdit(false);
  };

  return (
    <Container fluid={true} className={" d-flex flex-column gap-4 p-0"}>
      {isActive && (
        <Col className={`col-12 border border-1 p-3 gap-3 ${styles.radius}`}>
          <CompanyServiceForm
            reFetchCompany={reFetchCompany}
            data={isEdit ? dataService : null}
            isFirst={isFirst}
            isCopy={isCopy}
            handleEdit={handleEdit}
            handleClose={handleClose}
            isNewBenefit={isNewBenefit}
          />
        </Col>
      )}
      <Form.Check
        type="switch"
        checked={isActiveOnly}
        id="active-switch"
        label="Pokaż nieaktywne"
        onChange={() => setIsActiveOnly((prev) => !prev)}
      />

      {arrayService
        .slice((currPage - 1) * LIMIT, currPage * LIMIT)
        .map((value: LoyaltyProgramServiceDataInterface, index: number) => (
          <Col
            key={value.id}
            className={`col-12 border border-1 ps-3 pe-2 py-3 ${styles.radius}`}
          >
            <CompanyServiceEntity
              data={value}
              handleChange={handleChange}
              index={arrayService.length - ((currPage - 1) * LIMIT + index)}
              handleCopy={() => setIsCopy(true)}
            />
          </Col>
        ))}
      <Row>
        <Col className="d-flex justify-content-start">
          <CustomPagination
            totalCount={arrayService.length || 0}
            pageSize={LIMIT}
            currentPage={currPage}
            onPageChange={handlePage}
            paginationClassName="text-nowrap"
          />
        </Col>
      </Row>
    </Container>
  );
};
