import { POLL_META_DATA } from "./pollsData";

export const isFieldRequired = (fieldType: string) =>
  !["range", "header"].includes(fieldType);

export const isFieldPatternAvailable = (fieldType: string) =>
  ["text", "textarea", "tel"].includes(fieldType);

export const areCharLengthFieldsAvailable = (fieldType: string) =>
  ["text", "textarea"].includes(fieldType);

export const areQuantityFieldsAvailable = (fieldType: string) =>
  ["number", "range"].includes(fieldType);

export const isPlaceholderAvailable = (fieldType: string) =>
  ["text", "textarea", "email", "datalist"].includes(fieldType);

export const isFieldTypeChoice = (fieldType: string) =>
  ["radio", "checkbox"].includes(fieldType);

export const isFieldTypeList = (fieldType: string) =>
  ["select", "datalist"].includes(fieldType);

export const isAgreement = (fieldType: string) => fieldType === "agreement";

export const isSfSynchroAvailable = (fieldType: string) =>
  !["header"].includes(fieldType);

export const isOutputTableNameMetaAvailable = (
  fieldType: string,
  tableName?: string
) =>
  ["text", "textarea", "rating-radio"].includes(fieldType) ||
  (isFieldTypeList(fieldType) && tableName === "oi_cancer");

export const areSlugSwitchNeeded = (
  fieldType: string,
  metaTableName?: string
) =>
  isFieldTypeChoice(fieldType) ||
  (isFieldTypeList(fieldType) && !metaTableName);

export const areLabelsSwitchNeeded = areSlugSwitchNeeded;

export const areFieldOptionsNeeded = (
  fieldType: string,
  metaTableName?: string
) => areSlugSwitchNeeded(fieldType, metaTableName) || isAgreement(fieldType);

export const createPollMetaArray = ({
  meta,
  fieldType,
  isOutputTableName,
  areLabelsNeeded,
}: PollQuestionValues): { key: string; value: string }[] => {
  meta.requiredMessage =
    isFieldRequired(fieldType) && meta.isRequired
      ? "Pole nie może być puste."
      : "";

  if (!isFieldPatternAvailable(fieldType)) {
    meta.pattern = "";
  }

  meta.patternMessage =
    isFieldPatternAvailable(fieldType) && meta.pattern
      ? POLL_META_DATA.PATTERNS.find((p) => p.value === meta.pattern)?.message
      : "";

  if (fieldType === "email") {
    meta.pattern = "/^[^s@]+@[^s@]+.[^s@]+$/";
    meta.patternMessage = "Podaj prawidłowy adres e-mail.";
  }

  if (!isPlaceholderAvailable(fieldType)) {
    meta.placeholder = "";
  }

  if (!areCharLengthFieldsAvailable(fieldType)) {
    meta.minLength = undefined;
    meta.maxLength = undefined;
  }

  meta.minLengthMessage =
    areCharLengthFieldsAvailable(fieldType) && meta.minLength
      ? `Ilość znaków nie może być mniejsza niż ${meta.minLength}.`
      : "";

  meta.maxLengthMessage =
    areCharLengthFieldsAvailable(fieldType) && meta.maxLength
      ? `Ilość znaków nie może być większa niż ${meta.maxLength}.`
      : "";

  if (!areQuantityFieldsAvailable(fieldType)) {
    meta.min = undefined;
    meta.max = undefined;
    meta.step = undefined;
  }

  meta.minMessage =
    areQuantityFieldsAvailable(fieldType) && meta.min
      ? `Wartość nie może być mniejsza niż ${meta.min}.`
      : "";

  meta.maxMessage =
    areQuantityFieldsAvailable(fieldType) && meta.max
      ? `Wartość nie może być większa niż ${meta.max}.`
      : "";

  if (!areSlugSwitchNeeded(fieldType, meta?.tableName)) {
    meta.areAnswersAsSlug = undefined;
  }

  if (!isFieldTypeList(fieldType) || !meta?.tableName) {
    meta.tableName = undefined;
  }

  if (
    !isOutputTableName &&
    !isOutputTableNameMetaAvailable(fieldType, meta?.tableName)
  ) {
    meta.outputTableName = undefined;
  }

  const metaArray: { key: string; value: string }[] = [];
  const validationKeys: (keyof PollMetaValues)[] = [
    "isRequired",
    "requiredMessage",
    "pattern",
    "patternMessage",
    "placeholder",
    "minLength",
    "minLengthMessage",
    "maxLength",
    "maxLengthMessage",
    "min",
    "minMessage",
    "max",
    "maxMessage",
    "step",
    "areAnswersAsSlug",
  ];

  const validation: Partial<PollMetaValues> = {};
  validationKeys.forEach((key) => {
    if (
      typeof meta[key] !== "undefined" &&
      meta[key] !== "" &&
      meta[key] !== false
    ) {
      if (typeof meta[key] === "string" || typeof meta[key] === "boolean") {
        validation[key] = meta[key] as any;
      } else if (Array.isArray(meta[key])) {
        validation[key] = meta[key] as any;
      }
    }
  });

  if (Object.keys(validation).length > 0 && fieldType !== "header") {
    metaArray.push({
      key: "validation",
      value: JSON.stringify(validation),
    });
  }

  const fieldSizeKeys: (keyof PollFieldSize)[] = ["lg"];
  const fieldSize: Partial<PollFieldSize> = {};
  fieldSizeKeys.forEach((key) => {
    if (
      typeof meta?.fieldSize?.[key] !== "undefined" &&
      typeof meta?.fieldSize?.[key] !== "number"
    ) {
      fieldSize[key] = meta?.fieldSize?.[key] as any;
    }
  });

  if (Object.keys(fieldSize).length > 0) {
    metaArray.push({
      key: "field_size",
      value: JSON.stringify(fieldSize),
    });
  }

  if (isFieldTypeList(fieldType) && meta?.tableName) {
    metaArray.push({ key: "table_name", value: meta.tableName });
  }

  if (meta?.isHtmlTooltip && meta?.htmlTooltipContent) {
    metaArray.push({ key: "html_tooltip", value: meta.htmlTooltipContent });
  }

  if (
    areFieldOptionsNeeded(fieldType, meta?.tableName) &&
    meta.answers &&
    meta.answers.length > 0
  ) {
    let answersValue: string;
    isAgreement(fieldType)
      ? (answersValue = meta.answers[0].value)
      : (answersValue = meta.answers.map((answer) => answer.value).join("||"));
    if (answersValue !== "||") {
      metaArray.push({ key: "answers", value: answersValue });
    }

    if (!isAgreement(fieldType) && areLabelsNeeded) {
      const labelsValue = meta.answers.map((answer) => answer.label).join("||");
      metaArray.push({ key: "labels", value: labelsValue });
    }
  }

  if (fieldType === "rating-radio") {
    metaArray.push({ key: "answers", value: "1||2||3||4||5" });
  }

  if (fieldType === "boolean-radio") {
    metaArray.push({ key: "answers", value: "tak||nie" });
  }

  if (
    isOutputTableName &&
    isOutputTableNameMetaAvailable(fieldType, meta?.tableName) &&
    meta?.outputTableName
  ) {
    metaArray.push({ key: "output_table_name", value: meta.outputTableName });
  }

  return metaArray;
};

interface MetaValue {
  isRequired?: boolean;
  requiredMessage?: string;
  pattern?: string;
  patternMessage?: string;
  placeholder?: string;
  minLength?: number;
  minLengthMessage?: string;
  maxLength?: number;
  maxLengthMessage?: string;
  min?: number;
  minMessage?: string;
  max?: number;
  maxMessage?: string;
  step?: number;
  areAnswersAsSlug?: boolean;
}

export const createPollMetaFormData = (
  meta: PollMetaEditData[],
  fieldType: string
) => {
  let metaFormData: PollMetaValues = {};

  meta.forEach((m) => {
    switch (m.key) {
      case "validation":
        const validationValue: MetaValue = JSON.parse(m.value);
        return (metaFormData = {
          ...metaFormData,
          ...validationValue,
        });

      case "field_size":
        const fieldSizeValue: PollFieldSize = JSON.parse(m.value);
        return (metaFormData = {
          ...metaFormData,
          fieldSize: {
            ...metaFormData.fieldSize,
            lg: fieldSizeValue.lg,
          },
        });

      case "table_name":
        return (metaFormData = {
          ...metaFormData,
          tableName: m.value,
        });

      case "output_table_name":
        return (metaFormData = {
          ...metaFormData,
          outputTableName: m.value,
        });

      case "html_tooltip":
        return (metaFormData = {
          ...metaFormData,
          isHtmlTooltip: true,
          htmlTooltipContent: m.value,
        });

      case "answers":
        const areAnswersNeeded =
          ["radio", "checkbox", "agreement"].includes(fieldType) ||
          (["select", "datalist"].includes(fieldType) &&
            meta.every((m) => m.key !== "table_name"));

        if (areAnswersNeeded) {
          return (metaFormData.answers = m.value
            .split("||")
            .map((value, idx) => ({
              value,
              label: meta?.some((m) => m.key === "labels")
                ? meta?.find((m) => m.key === "labels")?.value.split("||")[
                    idx
                  ] ?? ""
                : "",
            })));
        }
        break;
    }
  });

  return metaFormData;
};
