interface PollPaginationProps {
  currentPage: number;
  totalSteps: number;
  prevPage: () => void;
  nextPage: () => void;
  isLastPage: boolean;
  handleFormSubmit: () => void;
  isSubmitting: boolean;
  isVerified?: boolean;
  checkVerification: () => void;
  verificationType: "none" | "phone" | "emial";
}

const PollPagination: React.FC<PollPaginationProps> = ({
  currentPage,
  totalSteps,
  prevPage,
  nextPage,
  isLastPage,
  handleFormSubmit,
  isSubmitting,
  isVerified,
  checkVerification,
  verificationType,
}) => {
  return (
    <div className="d-flex justify-content-between mt-2">
      <div className="d-flex justify-content-center align">
        {totalSteps > 1 && (
          <p className="fw-600 fs-16">
            Krok {currentPage + 1} z {totalSteps}
          </p>
        )}
      </div>
      <div>
        {currentPage > 0 && (
          <button
            type="button"
            onClick={prevPage}
            className={`btn btn-outline-primary me-2`}
          >
            Cofnij
          </button>
        )}
        {!isLastPage && (
          <button
            type="button"
            onClick={nextPage}
            className={`btn btn-outline-primary`}
          >
            Dalej
          </button>
        )}
        {isLastPage && isVerified && (
          <button
            type="submit"
            onClick={handleFormSubmit}
            className="btn-outline-primary btn"
            disabled={isSubmitting}
          >
            Wyślij
          </button>
        )}
        {isLastPage && !isVerified && verificationType !== "none" && (
          <button
            type="button"
            onClick={checkVerification}
            className="btn-outline-primary btn ms-3 me-3"
          >
            Zapisz
          </button>
        )}
      </div>
    </div>
  );
};

export default PollPagination;
