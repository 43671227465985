import { Container } from "react-bootstrap";

const AdminPage = () => {
  return (
    <Container fluid>
      <h2 className="text-center">Witaj w panelu administratora!</h2>
    </Container>
  );
};

export default AdminPage;
