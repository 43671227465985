import { useState, useEffect } from "react";
import { useGetLanguageByIdQuery } from "redux/services/languagesApi";

interface Language {
  id: number;
  active: boolean;
  default: boolean;
  name: string;
  engName: string;
  code: string;
  browserCode: string;
}

export const useLanguageById = (languageId: number | undefined) => {
  const [languageData, setLanguageData] = useState<Language>();
  const { data: languagesData } = useGetLanguageByIdQuery({ languageId });

  useEffect(() => {
    setLanguageData({ ...languagesData?.data });
  }, [languagesData]);

  return languageData;
};
