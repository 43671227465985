import { Button, Form, Stack } from "react-bootstrap";
import styles from "./FilterPanel.module.scss";
import Select, {
  OptionProps,
  SingleValue,
  ValueContainerProps,
  components,
} from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";

export const FilterPanel = ({
  label,
  categories,
  selectedCategories,
  onCategoryClick,
  type,
  labelType,
}: FilteringWithButtonsProps) => {
  const buttonsStyles = `border btn border-1 fw-400`;
  const unclickedButtonStyles = `${buttonsStyles} bg-white text-dark-blue ${styles.filterButton__unclicked}`;
  const clickedButtonStyles = `${buttonsStyles} border-dark-blue bg-dark-blue text-white ${styles.filterButton__clicked}`;
  const onChangeCaptureHandler = (e: SingleValue<ReactSelectOption>) => {
    e ? onCategoryClick(e?.value, e?.label) : onCategoryClick();
  };
  const categoriesRS = categories as ReactSelectOption[];
  const SelectCheckboxValue = (
    props: ValueContainerProps<ReactSelectOption>
  ) => {
    return (
      <components.ValueContainer {...props}>
        <div className="position-absolute">
          {Array.isArray(props.children) && props.children[1]}
        </div>
        <span>
          Wybrano: {props.getValue()?.length}{" "}
          {props.getValue()?.length === 1
            ? "filtr"
            : props.getValue()?.length > 1 && props.getValue()?.length < 5
            ? "filtry"
            : "filtrów"}
        </span>
      </components.ValueContainer>
    );
  };

  const SelectCheckbox = ({
    children,
    isSelected,
    data,
    ...rest
  }: OptionProps<ReactSelectOption, boolean>) => {
    return (
      <components.Option
        {...rest}
        isSelected={isSelected}
        data={data}
        className={`cursor-pointer d-flex flex-wrap fs-16 ${styles.customOption}`}
      >
        <span className={`w-100 d-flex flex-wrap`}>
          <Form.Check
            type="checkbox"
            checked={isSelected}
            readOnly
            onChange={() => onCategoryClick(data?.value?.toString())}
            className="col-auto p-0 m-0"
            id="topics-checkbox"
          />
          <span className="mx-4 col-8 col-md-auto">{children}</span>
        </span>
      </components.Option>
    );
  };
  return (
    <div>
      <p
        className={
          labelType === "normal" || !labelType
            ? `fs-16 fw-400 text-dark-blue ${styles.labelMargin} ${styles.label}`
            : `fw-600 fs-12 mb-4 ${styles.label}`
        }
      >
        {label}
      </p>
      {(type === "button" || !type) && (
        <Stack direction="horizontal" className="d-flex flex-wrap" gap={2}>
          <Button
            variant="transparent"
            className={
              (selectedCategories as string[])?.length === 0
                ? clickedButtonStyles
                : unclickedButtonStyles
            }
            onClick={() => onCategoryClick()}
          >
            Wszystkie
          </Button>

          {categories.map(
            (category: string | ReactSelectOption, index: number) => (
              <div key={index}>
                <Button
                  variant="transparent"
                  className={
                    Array.isArray(selectedCategories) &&
                    selectedCategories.indexOf(
                      typeof category === "string" ? category : category?.value
                    ) !== -1
                      ? `${clickedButtonStyles}`
                      : `${unclickedButtonStyles}`
                  }
                  onClick={() =>
                    onCategoryClick(
                      typeof category === "string" ? category : category?.value
                    )
                  }
                >
                  {typeof category === "string" ? category : category?.label}
                </Button>
              </div>
            )
          )}
        </Stack>
      )}
      {type === "select" && Array.isArray(selectedCategories) && (
        <div className="w-100">
          <Select
            classNamePrefix="select"
            placeholder="Wszystkie"
            isClearable={true}
            isSearchable={true}
            name="category"
            value={
              categoriesRS?.find(
                (selectOption: ReactSelectOption) =>
                  selectOption.value === selectedCategories[0]
              ) || null
            }
            options={categories as ReactSelectOption[]}
            onChange={onChangeCaptureHandler}
            components={{ DropdownIndicator }}
            styles={customStyles(false)}
          />
        </div>
      )}
      {type === "multi-select" && Array.isArray(selectedCategories) && (
        <div className="w-100">
          <Select<ReactSelectOption, boolean>
            value={
              selectedCategories?.length !== 0
                ? categoriesRS?.find(
                    (selectOption: ReactSelectOption) =>
                      selectOption.value === selectedCategories
                  )
                : null
            }
            classNamePrefix="select"
            placeholder="Wszystkie"
            isClearable={true}
            isSearchable={false}
            name="category"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti
            options={categories as ReactSelectOption[]}
            components={{
              Option: SelectCheckbox,
              DropdownIndicator: DropdownIndicator,
              ValueContainer: SelectCheckboxValue,
            }}
            styles={customStyles(false)}
          />
        </div>
      )}
      {type === "React-Select" && (
        <div className="w-100">
          <Select
            classNamePrefix="select"
            isClearable={false}
            isSearchable={true}
            name="category"
            options={categories as ReactSelectOption[]}
            onChange={onChangeCaptureHandler}
            components={{ DropdownIndicator }}
            styles={customStyles(false)}
            value={selectedCategories as ReactSelectOption}
          />
        </div>
      )}
    </div>
  );
};
