import { Company } from "components/molecules/LoyaltyProgramServiceArticle/types";
import { Container, Col } from "react-bootstrap";
import styles from "./CompanyBasicInformaion.module.scss";
import CompanyBasicInformationForm from "components/molecules/CompanyBasicInformationForm/CompanyBasicInformationForm";

interface Props {
  data: Company;
  isActive: boolean;
  handleActive: (b: boolean) => void;
}

export const CompanyBasicInformation = ({
  data,
  isActive,
  handleActive,
}: Props) => {
  const handleClose = () => {
    handleActive(false);
  };

  return (
    <Container fluid className={" d-flex flex-column gap-4 p-0"}>
      {data && isActive && (
        <Col className={`col-12 border border-1 p-3 gap-3 ${styles.radius}`}>
          <CompanyBasicInformationForm
            data={data}
            disabled={false}
            handleClose={handleClose}
          />
        </Col>
      )}
      {data && !isActive && (
        <Col
          className={`col-12 border border-1 ps-3 pe-2 py-3 ${styles.radius}`}
        >
          <CompanyBasicInformationForm
            data={data}
            disabled={true}
            handleClose={handleClose}
          />
        </Col>
      )}
    </Container>
  );
};
