import { useState } from "react";

interface FilterState {
  [name: string]: string[];
}

export const useFilter = (name: string) => {
  const [filters, setFilters] = useState<FilterState>({});

  const filterClickHandler = (value: string | undefined) => {
    setFilters((prevFilters) => {
      if (!value) {
        return {
          ...prevFilters,
          [name]: [],
        };
      }

      const prevValues = prevFilters[name] || [];

      if (prevValues.includes(value)) {
        return {
          ...prevFilters,
          [name]: prevValues.filter((cat) => cat !== value),
        };
      } else {
        return {
          ...prevFilters,
          [name]: [...prevValues, value],
        };
      }
    });
  };

  const selectedValues = filters[name] || [];

  return { filterClickHandler, selectedValues };
};
