import React from "react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePatchDoctorMutation } from "../../../redux/services/doctorApi";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { useTranslation } from "react-i18next";

interface DoctorBiogramProps {
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  data: DoctorBiogramData;
  reFetchCompany?: any;
}

interface DoctorBiogramData {
  id: number;
  description: string;
}

interface DoctorBiogramUpdateError {
  data: {
    statusCode: number;
    message: string;
    error: string;
  };
}

export const DoctorBiogram: React.FC<DoctorBiogramProps> = ({
  data,
  isActive,
  isFirst,
  handleActive,
  handleFirst,
  reFetchCompany,
}) => {
  const [patchDoctor] = usePatchDoctorMutation();

  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<DoctorBiogramData>({
    defaultValues: {
      id: data.id,
      description: data.description,
    },
  });

  const descriptionValue = watch("description");

  const submitHandler: SubmitHandler<{
    id: number;
    description: string;
  }> = async (formData) => {
    const payload = {
      id: formData.id,
      data: formData,
    };

    const hasBeenUpdated = await patchDoctor(payload);

    if ("data" in hasBeenUpdated) {
      toast.success(t("toast_doctorBiogram_success"));
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated.error as DoctorBiogramUpdateError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorBiogram_error_500"));

      if (error.data.statusCode === 409)
        toast.error(t("toast_doctorBiogram_error_409"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_doctorBiogram_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorBiogram_error_400")} ${error.data.message[0]}`
        );
    }
  };

  return isActive ? (
    <Form
      className={"d-flex flex-column w-100"}
      onSubmit={handleSubmit(submitHandler)}
    >
      <p className="text-grey-2 fs-12 fw-semibold ls-6">Dodawanie biogramu</p>
      <ComponentWithDescription
        className="my-2 w-100"
        desc="Max 500 znaków"
        descFs={`fs-12`}
        label={"Kilka zdań o Tobie*"}
        labelFs={`fs-16 mb-1 ${
          !!errors.description && descriptionValue.length <= 0
            ? "text-danger"
            : "text-dark-blue"
        }`}
      >
        <Form.Control
          as="textarea"
          rows={3}
          {...register("description", {
            required: "Pole nie może być puste",
            maxLength: {
              value: 500,
              message: "Opis nie może posiadać więcej niż 500 znaków",
            },
          })}
          isInvalid={!!errors.description && descriptionValue.length <= 0}
        />
        {errors?.description && (
          <Form.Control.Feedback type={"invalid"}>
            {errors.description.message}
          </Form.Control.Feedback>
        )}
      </ComponentWithDescription>
      <div className="d-flex gap-2 mt-4 justify-content-end">
        <Button
          className="btn-outline-dark-blue"
          onClick={() => {
            handleActive(false);
            if (data.description === null) {
              handleFirst(true);
            }
            reFetchCompany();
            setValue("description", data.description);
          }}
        >
          ANULUJ
        </Button>
        <Button type="submit">{isFirst ? "AKTUALIZUJ" : "ZAPISZ"}</Button>
      </div>
    </Form>
  ) : (
    <div className={"w-100"}>{data.description}</div>
  );
};
