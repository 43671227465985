import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function NumberInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = useFormContext();

  const watchedValue = watch(question?.fieldName, "");
  const validationMeta = question?.meta?.find((m) => m.key === "validation");

  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const validationRules = question?.meta.find(
    (meta) => meta.key === "validation"
  )?.value;
  const validation = validationRules ? JSON.parse(validationRules) : null;
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <Form.Control
        {...register(question?.fieldName, {
          required: requiredMessage,
          valueAsNumber: true,
          min: validation?.min
            ? { value: validation.min, message: validation.minMessage }
            : undefined,
          max: validation?.max
            ? { value: validation.max, message: validation.maxMessage }
            : undefined,
        })}
        className={`form-control ${
          errors[question?.fieldName] ? "input-error" : ""
        }`}
        type="number"
        value={watchedValue || ""}
        min={validation?.min || ""}
        max={validation?.max || ""}
        step={validation?.step || ""}
        isInvalid={!!errors[question?.fieldName]}
        disabled={readOnly}
      />
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
