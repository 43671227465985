import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import styles from "./BirthDateInput.module.scss";
import { useEffect } from "react";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function BirthDateInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = useFormContext();

  const watchedValue = watch(question?.fieldName, "");

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  const today = new Date();
  const maxDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      <Form.Label className="fw-600 fs-16 mb-0">
        {question?.question}
        {validationMeta && JSON.parse(validationMeta.value).isRequired && (
          <span className="text-validation">*</span>
        )}
      </Form.Label>
      <div className={`${styles.inputContainer}`}>
        <Form.Control
          {...register(question?.fieldName, {
            required: requiredMessage,
          })}
          className={`form-control`}
          type="date"
          value={watchedValue}
          max={maxDate}
          isInvalid={!!errors[question?.fieldName]}
          disabled={readOnly}
        />
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
    </div>
  );
}
