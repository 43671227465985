import { Button, Col, Container, Form, Stack } from "react-bootstrap";
import styles from "../../templates/ChangesCompanyTemplate/ChangesCompanyTemplate.module.scss";
import { RejectModal } from "../RejectModal/RejectModal";
import React, { ChangeEvent, useEffect, useState } from "react";
import { SeparationUpdate } from "../../atoms/SeparationUpdate/SeparationUpdate";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import TooltipIcon from "../../atoms/TooltipIcon";

interface Props {
  name: string;
  data: any;
  keyName: string;
  changes: any;
  handlePatch?: (obj: { [key: string]: any }, keyName: string) => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

export const Changes = (props: Props) => {
  const [showReject, setShowReject] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState(
    props.changes?.[props.keyName]
      ? props.changes?.[props.keyName]
      : props.data
      ? props.data[props.keyName]
      : "",
  );

  useEffect(() => {
    if (props.changes?.[props.keyName]) {
      setValue(props.changes?.[props.keyName] ?? "");
    } else {
      setValue(props.data?.[props.keyName] ?? "");
    }
    // eslint-disable-next-line
  }, [props.changes?.[props.keyName], props.data?.[props.keyName]]);
  const handlePatch = () => {
    const obj: any = {};
    obj[props.keyName] = value;
    setIsActive(false);
    if (props.handlePatch) {
      props.handlePatch(obj, props.keyName);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleComments = (comment: string) => {
    if (props.handleComments) {
      props.handleComments(comment);
    }
  };

  return (
    <Stack gap={3}>
      <Col className={`${props.isAdmin ? styles.wrapper : ""}`}>
        <Container fluid={true}>
          <div className={"d-flex justify-content-between mb-3"}>
            <span className={"fs-22 fw-semibold text-dark-blue"}>
              {props.name}
            </span>
            {props.isAdmin && !props.changes?.[props.keyName] && (
              <Button
                style={{ width: 38, height: 38 }}
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                  isActive
                    ? "text-light"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prev: boolean) => !prev)}
              >
                <Pencil />
              </Button>
            )}
          </div>
          {props.isAdmin && isActive && !props.changes?.[props.keyName] ? (
            <div className={"w-100"}>
              <Form.Control
                type="text"
                className={`w-100 text-dark-blue  ${styles.input} `}
                value={value}
                onChange={handleChange}
              />
              <Button
                className={"btn-outline-dark-blue fs-16 mt-2 end-0 mt-3"}
                onClick={() => setValue(props.data[props.keyName])}
              >
                cofnij
              </Button>
              <Button
                className={"btn-outline-dark-blue fs-16 mt-2 end-0 mt-3 ms-3"}
                onClick={handlePatch}
              >
                AKCEPTUJ
              </Button>
            </div>
          ) : (
            <p className={`text-dark-blue m-0`}>
              {props.changes?.[props.keyName]
                ? props.data[props.keyName]
                : value}
            </p>
          )}
        </Container>
      </Col>
      {!!props.changes?.[props.keyName] && (
        <>
          <SeparationUpdate />
          <Col className={`${props.isAdmin && styles.wrapper} text-danger`}>
            <Container fluid={true}>
              <div className={"d-flex justify-content-between mb-3"}>
                <span className={"fs-22 fw-semibold"}>{props.name}</span>
                {props.isAdmin && (
                  <Button
                    style={{ width: 38, height: 38 }}
                    variant={isActive ? "dark" : "transparent"}
                    className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                      isActive
                        ? "text-light"
                        : "text-dark-blue bg-transparent border-0"
                    }`}
                    onClick={() => setIsActive((prev: boolean) => !prev)}
                  >
                    <Pencil />
                  </Button>
                )}
              </div>
              {props.isAdmin && isActive ? (
                <div className={"w-100"}>
                  <Form.Control
                    type="text"
                    className={`w-100 text-dark-blue`}
                    value={value}
                    onChange={handleChange}
                  />
                  <Button
                    className=" btn-outline-dark-blue fs-16 mt-2 end-0 mt-3"
                    onClick={() => setValue(props.changes?.[props.keyName])}
                  >
                    cofnij
                  </Button>
                  <Button
                    className={
                      "btn-outline-dark-blue fs-16 mt-2 end-0 mt-3 ms-3"
                    }
                    onClick={handlePatch}
                  >
                    AKCEPTUJ
                  </Button>
                </div>
              ) : (
                <p className={"m-0"}>{value}</p>
              )}
            </Container>
          </Col>
        </>
      )}
      {props.isAdmin && !!props.changes?.[props.keyName] && (
        <Col className={"d-flex justify-content-end gap-2"}>
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowReject((prev) => !prev)}
          >
            ODRZUĆ
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev) => !prev)}
            handleComments={handleComments}
            comments={
              props.changes?.comments
                ? `${props.changes.comments}; ${props.name}: `
                : `${props.name}: `
            }
          />
          <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
            <Button
              disabled={isActive}
              className={"fs-16"}
              onClick={handlePatch}
            >
              AKCEPTUJ
            </Button>
          </TooltipIcon>
        </Col>
      )}
    </Stack>
  );
};
