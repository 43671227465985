interface Props {
  phoneNumber: string;
}

export const PhoneNumberLink = (props: Props) => {
  return (
    <div className={"d-flex "}>
      <a
        className={"d-flex align-items-center pe-2 pb-2 linkAlivia"}
        href={`tel:${props.phoneNumber}`}
      >
        {props.phoneNumber}
      </a>
    </div>
  );
};
