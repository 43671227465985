import ArticlesSendingTemplate from "components/templates/ArticlesSendingTemplate/ArticlesSendingTemplate";

const INITIAL_DATA: ArticleData = {
  name: "",
  slug: "",
  url: "",
  type: "xml",
  categoryNames: "",
  countryId: 0,
  status: 1,
};

const ArticlesNewPage = () => {
  return <ArticlesSendingTemplate data={INITIAL_DATA} />;
};

export default ArticlesNewPage;
