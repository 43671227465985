import { Button, Collapse } from "react-bootstrap";
import { useState } from "react";

interface SelectInputProps {
  label?: string;
  title?: string;
  CollapseComponent?: any;
  labelClassName?: string;
}

const CollapseWithIcon = ({
  title,
  label,
  CollapseComponent,
  labelClassName
}: SelectInputProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-100 d-flex flex-column">
      <div
        className=" d-flex align-items-center justify-content-between"
        style={{ fontSize: "13px" }}
      >
        {title && <span className={labelClassName}>{title}</span>}
        <Button
          variant="link"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          style={{ fontSize: "14px" }}
        >
          {label}
        </Button>
      </div>
      <Collapse in={open}>
        <div>
          <CollapseComponent />
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseWithIcon;
