import { TwitterTweetEmbed } from "react-twitter-embed";
import styles from "../LinkPreview.module.scss";

export const TwitterWidget = ({ id }: OtherPlatformWidgetProps) => (
  <div
    className={`d-flex justify-content-center align-items-center col-12 ${styles.twitterWrapper}`}
  >
    <TwitterTweetEmbed tweetId={id} />
  </div>
);
