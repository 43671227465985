import { services } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface ServicesParams {
  displayed?: string;
  active?: string;
  limit?: string;
  offset?: string;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Services"],
});
export const servicesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: ({ offset, limit, displayed, active }: ServicesParams) => {
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(displayed && { displayed }),
          ...(active && { active }),
        });
        return {
          url: `${services}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Services"],
    }),
  }),
});

export const { useGetServicesQuery } = servicesApi;
