import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TargetTreatmentWidgetState {
  isShowWidget: boolean;
}

export const initialState: TargetTreatmentWidgetState = {
  isShowWidget: true,
};

export const targetTreatmentWidgetSlice = createSlice({
  name: "target-treatment-widget",
  initialState,
  reducers: {
    setIsShowWidget: (state, action: PayloadAction<boolean>) => {
      state.isShowWidget = action.payload;
    },
  },
});

export const { setIsShowWidget } = targetTreatmentWidgetSlice.actions;

export default targetTreatmentWidgetSlice.reducer;
