import { Container, Row, Spinner } from "react-bootstrap";
import { useGetPageQuery } from "../../../redux/services/pagesApi";
import { AccordionCard } from "../../molecules/AccordionCard/AccordionCard";
import { capitalizeFirstLetter } from "../../../Helpers/capitalizeFirstLetter";
import styles from "./HelpContent.module.scss";

interface Props {
  pageId: string;
}

export const HelpContent = (props: Props) => {
  const { data: pagesData, isLoading } = useGetPageQuery(props.pageId);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true} className={`p-4 border containerWithShadow mb-5`}>
      <Row>
        <div
          className={`fs-22 fw-600 lh-130 mb-5 text-dark-blue mx-0 ${styles.title}`}
        >
          <span>{pagesData.data.title}</span>
        </div>
      </Row>
      {pagesData.data.childPage.map((value: FaqValue, index: number) => {
        return (
          <AccordionCard
            key={index}
            content={value.content}
            title={capitalizeFirstLetter(value?.title || null)}
          />
        );
      })}
    </Container>
  );
};
