import { salesForce } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";
import Cookies from "universal-cookie";

const apiWithContactTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["SFContact"],
});

export const sfContactApi = apiWithContactTags.injectEndpoints({
  endpoints: (builder) => ({
    getSFContact: builder.query({
      query: () => {
        return {
          url: `${salesForce}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${new Cookies().get("MOJA_TKN")}`,
          },
        };
      },
      providesTags: ["SFContact"],
    }),
    patchSFContact: builder.mutation({
      query: ({ data }) => ({
        url: `${salesForce}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["SFContact"],
    }),
  }),
});

export const {
  useGetSFContactQuery,
  usePatchSFContactMutation
} = sfContactApi;
