import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import ArticleFormCard from "components/organisms/ArticleFormCard/ArticleFormCard";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ArticlesSendingTemplateProps {
  data: ArticleData;
}

const ArticlesSendingTemplate = ({ data }: ArticlesSendingTemplateProps) => {
  const navigate = useNavigate();

  return (
    <Container as="section" fluid className="px-4 pt-2 bg-light">
      <div className="d-flex align-items-center mb-3">
        <SectionTitle className="me-4">Artykuły</SectionTitle>
        <Button
          className="btn-outline-blue"
          type="button"
          onClick={() =>
            navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.ARTICLES}`, {
              replace: true,
            })
          }
        >
          Panel administratora
        </Button>
      </div>
      <ArticleFormCard data={data} />
    </Container>
  );
};

export default ArticlesSendingTemplate;
