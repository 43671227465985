import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {font} from "../assets/Fonts/Poppins-Regular-normal";
import logo from "../assets/images/AliviaPlaceholderLogo.png"

export const downloadPdf = async (
    qrRef: { current: any },
    barcodeRef: { current: any },
    code: string,
    name: string,
    dateEnd: string | null,
) => {
    const dataQR = qrRef.current;
    const dataBarcode = barcodeRef.current;
    await html2canvas(dataQR).then(async (canvas: any) => {
        const doc = new jsPDF("p", "mm", [297, 210]);

        doc.addFileToVFS("Poppins-Regular.ttf", font);
        doc.addFont("Poppins-Regular.ttf", "Poppins-Regular", "normal");
        doc.setFont("Poppins-Regular");

        doc.addImage(logo, "PNG", 30, 0, 50, 50);

        doc.addImage(canvas, "PNG", 150, 10, 50, 50);

        doc.setTextColor("#003b50");
        doc.setFontSize(20);
        doc.text("KOD RABATOWY", 105, 105, {align: "center"});
        doc.setFontSize(16);
        doc.text(name, 105, 75, {align: "center", maxWidth: 200});
        doc.text(code, 105, 115, {align: "center"});

        if (dateEnd) {
            doc.text("Data utraty ważności kodu:", 105, 140, {align: "center", maxWidth: 200});
            doc.text(dateEnd.slice(0,10), 105, 148, {align: "center", maxWidth: 200});
        }
        doc.setFontSize(10);
        doc.text(
            `dokument wygenerowany elektronicznie: ${new Date().toISOString().split("T")[0]} ${new Date().toISOString().split("T")[1].slice(0, 8)}`,
            105,
            290,
            {align: "center"}
        );

        await html2canvas(dataBarcode).then(async (canvas2: any) => {
            await doc.addImage(canvas2, "SVG", 0, 232, Math.min(canvas2.width/4, 480), 40, "","FAST");

            doc.autoPrint();
            doc.output("dataurlnewwindow");
            // doc.save("Report.pdf");
        });
    });
};
