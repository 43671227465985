import React from "react";
import {Col} from "react-bootstrap";

export const SeparationUpdate: React.FC = () => {
    return (
        <Col className={'d-flex p-0 m-0 text-danger'}><div className={"col-4 p-0"}>
            <div className="h-50 border-bottom border-1 border-danger "></div>
        </div>
            <div className={"col-4 text-center p-0 fs-14"}>Aktualizacja</div>
            <div className={"col-4 p-0"}>
                <div className="h-50 border-bottom border-1 border-danger "></div>
            </div>
        </Col>
    );
};
