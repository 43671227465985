import FileDropzone from "components/atoms/FileDropzone/FileDropzone";
import SearchForm from "components/molecules/SearchForm/SearchForm";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useGetAttachmentsQuery } from "redux/services/attachmentsApi";
import CustomPagination from "../CustomPagination/CustomPagination";
import MediaGallery from "../MediaGallery/MediaGallery";

const MediaModal = ({
  show,
  setShow,
  data,
  setContent,
  setSelectedImage
}: MediaModalChildrenProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1
  );

  const [dropzoneIsActive, setDropzoneIsActive] = useState(false);
  const { data: attachment } = useGetAttachmentsQuery({
    offset: currPage ? ((currPage - 1) * 18).toString() : "0",
    limit: "18",
    nameLike: searchParams.get("nameLike"),
  });

  const handlePageChange = (e: number) => {
    searchParams.set("page", e.toString());
    setSearchParams(searchParams);
    setCurrPage(e);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Szczegóły załączonego pliku
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-2 mx-4 w-80">
          <div className=" d-flex my-3">
            <h1 className="pe-3 fs-2 ">Biblioteka mediów</h1>
            <Button
              variant="outline-primary"
              onClick={() => setDropzoneIsActive((prev) => !prev)}
            >
              Dodaj
            </Button>
          </div>

          {dropzoneIsActive && (
            <FileDropzone setDropzoneIsActive={setDropzoneIsActive}  attachmentType={"pages"}/>
          )}
          <div className="d-flex justify-content-end mb-4">
            <SearchForm setCurrPage={setCurrPage} />
          </div>
          <MediaGallery
            setShowParentModal={setShow}
            setContent={setContent}
            data={attachment?.data}
            setSelectedImage={setSelectedImage}
          />
          <CustomPagination
            totalCount={(!!attachment && attachment.meta.totalResults) || 0}
            pageSize={18}
            currentPage={currPage}
            onPageChange={(e: number) => handlePageChange(e)}
            paginationClassName="text-nowrap"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MediaModal;
