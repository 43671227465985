import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { slugify } from "Helpers/slugify";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

const CheckboxInput: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}) => {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = useFormContext();

  let watchedAnswer = Array.isArray(watch(question?.fieldName, []))
    ? watch(question?.fieldName, [])
    : [];

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const options =
    question?.meta?.find((m) => m.key === "answers")?.value.split("||") || [];
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const areAnswersAsSlug = validationMeta
    ? JSON.parse(validationMeta.value)?.areAnswersAsSlug ?? false
    : false;
  const labelsMeta = question?.meta?.find((m) => m.key === "answers");
  const labels = labelsMeta ? labelsMeta.value.split("||") : [];

  useEffect(() => {
    if (defaultValue) {
      const defaultCheckedValues = defaultValue.split(", ");
      setValue(question?.fieldName, defaultCheckedValues);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <div className="d-flex flex-column">
        {options.map((option, i) => (
          <Form.Label key={i} className="mt-2 mb-2 d-flex ">
            <Form.Check
              className={`me-2 ${
                errors[question?.fieldName] ? "input-error" : ""
              }`}
              value={areAnswersAsSlug ? slugify(option) : option}
              checked={watchedAnswer.includes(
                areAnswersAsSlug ? slugify(option) : option
              )}
              {...register(question?.fieldName, {
                required: requiredMessage,
              })}
              type="checkbox"
              isInvalid={!!errors[question?.fieldName]}
              label={labels ? labels[i] : option}
              disabled={readOnly}
            />
          </Form.Label>
        ))}
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default CheckboxInput;
