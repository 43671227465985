import { Row } from "react-bootstrap";
import { ReactComponent as Clock } from "../../../assets/Icons/Clock.svg";
import { ReactComponent as ReportIcon } from "../../../assets/Icons/Report.svg";
import styles from "../Comment/Comment.module.scss";
import { CommentProps } from "../Comment/types";
import PopupModal from "../PopupModal/PopupModal";
import { toast } from "react-toastify";
import React, { useState } from "react";
import {
  useDeleteCommentMutation,
  usePostPostsCommentAbuseMutation,
} from "redux/services/feedApi";
import userAvatarImage from "../../../assets/images/userAvatarImage.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { ReactComponent as Trash } from "../../../assets/Icons/FeedTrash.svg";
import { useTranslation } from "react-i18next";
import { RejectModal } from "../../organisms/RejectModal/RejectModal";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import Linkify from "react-linkify";

export const Reply = ({
  id,
  postId,
  author,
  content,
  replies,
  isReply,
  className,
  commentWhenAdded,
  status,
  rerenderer,
}: CommentProps) => {
  const [popup, setPopup] = useState<boolean>(false);
  const { authUser } = useSelector((state: RootState) => state.user);

  const [postCommentAbuse] = usePostPostsCommentAbuseMutation();
  const [showReject, setShowReject] = useState<boolean>(false);

  const createdAt = commentWhenAdded ? new Date(commentWhenAdded) : 0;

  const transformedDate =
    commentWhenAdded &&
    formatDistanceToNow(Number(createdAt), {
      addSuffix: true,
      locale: pl,
    });

  const [deleteComment] = useDeleteCommentMutation();
  const { t } = useTranslation();
  const STATUS_LABEL = t(`status_${status}`);
  const handleChangeIconActionType = (type: string) => {
    if (type === "trash") {
      setPopup(true);
    } else if (type === "report") {
      setShowReject(true);
    }
  };

  const handleDeleteComment = async () => {
    if (id) {
      setPopup(false);
      const deleteCommentData = await deleteComment({ id: id.toString() });
      rerenderer();
      if ("data" in deleteCommentData) {
        toast.success(t("toast_reply_delete_success"));
      } else if ("error" in deleteCommentData) {
        const error = deleteCommentData?.error as TranslationSubmitError;

        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_reply_delete_error_500"));
            break;
          default:
            toast.error(t("toast_reply_delete_error"));
            break;
        }
      }
    }
  };

  const handleReport = async () => {
    if (id) {
      setPopup(false);
      if (author?.id !== authUser.id) {
        const commentAbuse = await postCommentAbuse({
          data: {
            reviewId: id,
            content,
          },
        });
        if ("data" in commentAbuse) {
          toast.success(`${t("toast_reply_report_success")} ${author.name}`);
        } else if ("error" in commentAbuse) {
          const error = commentAbuse?.error as TranslationSubmitError;

          switch (error.data.statusCode) {
            case 500:
              toast.error(t("toast_reply_report_error_500"));
              break;
            case 400:
              toast.error(t("toast_reply_report_error_400"));
              break;
            default:
              toast.error(t("toast_reply_report_error"));
              break;
          }
        }
      } else {
        toast.error(t("toast_reply_report_myself_error"));
      }
    }
  };
  return (
    <>
      <Row
        className={`${styles.commentWrapper} ${className} px-4 mx-0 d-flex flex-wrap align-items-start`}
      >
        <div className={`${styles.commentAuthorAvatar} col-2 col-md-1`}>
          <img
            src={author?.avatar?.accessUrl ?? userAvatarImage}
            alt="author"
          ></img>
        </div>
        <div
          className={`${styles.commentAuthorNameAndContent} px-0 col-9 col-md-10 d-flex flex-wrap`}
        >
          <Link
            to={`/${NAV_PATH.PROFILE}/${author?.authId}`}
            target={"_blank"}
            className={`${styles.commentAuthorName} p-0 m-0 w-auto fs-14`}
          >
            {author?.name ?? "Użytkownik Alivii"}
          </Link>
          <span className="mx-2 text-grey-2 fs-12 d-flex flex-wrap d-md-block">
            <span className="me-2">
              <Clock />
            </span>
            {commentWhenAdded && transformedDate}
          </span>
          <span>
            {status !== "ACCEPTED" && (
              <Row className="col-12 mx-0 px-0 my-1 fs-12">
                <span className="bg-secondary text-white rounded-2 fw-600 px-2">
                  {STATUS_LABEL}
                </span>
              </Row>
            )}
          </span>
          <p
            className={`fs-16 fw-400 m-0 w-100 pb-auto text-wrap text-grey text-break ${styles.commentReplyContent}`}
          >
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  href={decoratedHref}
                  key={key}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {decoratedText}
                </a>
              )}
            >
              {content}
            </Linkify>
          </p>
        </div>
        <div
          className={`${styles.commentReportIcon} col-1 px-0 d-flex justify-content-center align-items-start`}
        >
          <div>
            {author?.id === authUser.id ? (
              <Trash
                className="cursor-pointer"
                onClick={() => handleChangeIconActionType("trash")}
              />
            ) : (
              <ReportIcon
                className="cursor-pointer"
                onClick={() => handleChangeIconActionType("report")}
              />
            )}
          </div>
        </div>
      </Row>
      <RejectModal
        label={"Zgłoszenie"}
        desc={"Podaj powód zgłoszenia"}
        show={showReject}
        handleClose={() => setShowReject((prev) => !prev)}
        handleComments={handleReport}
        comments={""}
      />
      <PopupModal
        show={popup}
        setShow={setPopup}
        text={"Czy na pewno chcesz usunąć Twoją odpowiedź do komentarza?"}
        type="confirm"
        confirmAction={handleDeleteComment}
      />
    </>
  );
};
