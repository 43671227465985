import { countries } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Countries"],
});

export const countriesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: ({ offset, limit }) => {
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
        });

        return {
          url: `${countries}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Countries"],
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
