import { useEffect, useState } from "react";
import Select from "react-select";
import { useGetFacilitiesQuery } from "redux/services/facilitiesApi";
import { useFormContext } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import {
  customStyles,
  DropdownIndicator,
} from "../../../../styles/reactSelectCustomStyles";
import { X } from "react-bootstrap-icons";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

type SelectedValue = {
  value: string;
  label: string;
} | null;

export default function FacilitiesSelect({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
  setPollDefaultValueLoadingState,
}: InputComponentProps) {
  const {
    formState: { errors, isSubmitted },
    setValue,
  } = useFormContext();
  const [options, setOptions] = useState<FacilityData[]>([]);
  const [numberOfSelects, setNumberOfSelects] = useState<number>(1);
  const [selectedRadio, setSelectedRadio] = useState<number>(
    defaultValue === "Brak / jeszcze nie wybrałem(am)" ? 0 : 2,
  );
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { data: facilitiesData } = useGetFacilitiesQuery({
    limit: "0",
    offset: "0",
  });

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  useEffect(() => {
    if (defaultValue === "Brak / jeszcze nie wybrałem(am)") {
      setSelectedRadio(0);
    } else if (typeof defaultValue === "string") {
      setSelectedRadio(2);
      const defaultValues = defaultValue.split(", ");
      setNumberOfSelects(defaultValues.length);
      setSelectedValues(defaultValues);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (facilitiesData && Array.isArray(facilitiesData.data)) {
      setOptions(facilitiesData.data.map((facility: FacilityData) => facility));
    }
  }, [facilitiesData]);

  useEffect(() => {
    if (options.length > 1) {
      setPollDefaultValueLoadingState((prevState) => ({
        ...prevState,
        isFacilitiesSelectLoading: false,
      }));
    }
    //eslint-disable-next-line
  }, [options]);

  const selectOptions = options.map((option: FacilityData) => ({
    value: option.id,
    label: option.name,
  }));

  const handleChangeRadio = (value: number) => {
    setSelectedRadio(value);
    if (value === 0) {
      setValue(question?.fieldName, "Brak / jeszcze nie wybrałem(am)");
    } else if (value === 1) {
      setValue(question?.fieldName, "Inne");
    } else {
      handleConcatenateValues();
    }
  };

  const handleSelectChange = (selectedValue: SelectedValue, index: number) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = selectedValue?.value || "";
    setSelectedValues(newSelectedValues);
    handleConcatenateValues();
  };

  const handleConcatenateValues = () => {
    const concatenatedValues = selectedValues
      .filter((value) => value)
      .join(", ");
    setValue(question?.fieldName, concatenatedValues);
  };

  const handleAddPlace = () => {
    setNumberOfSelects(numberOfSelects + 1);
  };

  const handleRemoveSelect = (indexToRemove: number) => {
    const newSelectedValues = selectedValues.filter(
      (_, index) => index !== indexToRemove,
    );
    setSelectedValues(newSelectedValues);

    setNumberOfSelects((prevCount) => prevCount - 1);
  };

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <div className="d-flex flex-column">
        <Form.Check
          type="radio"
          className="me-2"
          checked={selectedRadio === 0}
          onChange={() => handleChangeRadio(0)}
          label="Brak / jeszcze nie wybrałem(am)"
          disabled={readOnly}
        />
        <Form.Check
          type="radio"
          className="me-2"
          checked={selectedRadio === 1}
          onChange={() => handleChangeRadio(1)}
          label="Inne"
          disabled={readOnly}
        />
        <div className="d-flex">
          <Form.Check
            type="radio"
            className="me-2 mt-3"
            checked={selectedRadio === 2}
            onChange={() => handleChangeRadio(2)}
            disabled={readOnly}
          />
          <div className="flex-grow-1 d-flex align-items-center flex-column">
            {Array.from({ length: numberOfSelects }, (_, index) => (
              <div key={index} className="d-flex w-100">
                <Select
                  value={selectOptions.find(
                    (option) => option?.value === Number(selectedValues[index]),
                  )}
                  className="basic-single mt-2 mb-2 w-100"
                  classNamePrefix="select"
                  styles={customStyles(false)}
                  components={{ DropdownIndicator }}
                  isClearable
                  isSearchable
                  id={String(index)}
                  options={selectOptions}
                  placeholder="Wybierz z listy"
                  isDisabled={readOnly || selectedRadio !== 2}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, index)
                  }
                />
                {numberOfSelects > 1 && (
                  <Button
                    className="remove-select-button ms-4 mt-2 mb-2 bg-secondary"
                    onClick={() => handleRemoveSelect(index)}
                    variant="danger"
                  >
                    <X />
                  </Button>
                )}
              </div>
            ))}
            <Button
              onClick={handleAddPlace}
              disabled={readOnly}
              className="btn-outline-primary w-100 fw-medium"
            >
              Dodaj kolejne miejsce
            </Button>
          </div>
        </div>
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
