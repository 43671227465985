import { companyDepartment } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["CompanyDepartments", "LoyaltyPrograms"],
});

export interface GetCompanyDepartmentsProps {
  changes?: string;
  sortBy?: string;
  limit?: string;
  offset?: string;
}

interface PatchCompanyDepartmentProps {
  id?: number | string;
  data: CompanyDepartmentFormData;
}

interface PostCompanyDepartmentProps {
  data: CompanyDepartmentFormData;
}

export const loyaltyProgramsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyDepartments: builder.query({
      query: ({
        changes,
        limit,
        offset,
        sortBy,
      }: GetCompanyDepartmentsProps) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(changes && { changes }),
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { sortBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });

        return {
          url: `${companyDepartment}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["CompanyDepartments"],
    }),

    getCompanyDepartment: builder.query({
      query: (id?: string) => {
        return {
          url: `${companyDepartment}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["CompanyDepartments"],
    }),

    getCompanyDepartmentsByCompany: builder.query({
      query: (id?: string) => {
        return {
          url: `${companyDepartment}/company/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["CompanyDepartments"],
    }),

    getAllServiceByCompanyDepartment: builder.query({
      query: (id: number) => {
        return {
          url: `${companyDepartment}/service/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["CompanyDepartments", "LoyaltyPrograms"],
    }),

    patchCompanyDepartment: builder.mutation({
      query: ({ id, data }: PatchCompanyDepartmentProps) => ({
        url: `${companyDepartment}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CompanyDepartments"],
    }),

    postCompanyDepartment: builder.mutation({
      query: ({ data }: PostCompanyDepartmentProps) => ({
        url: `${companyDepartment}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CompanyDepartments"],
    }),

    deleteCompanyDepartment: builder.mutation({
      query: ({ id }: { id?: number }) => ({
        url: `${companyDepartment}/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["CompanyDepartments"],
    }),
  }),
});

export const {
  useGetCompanyDepartmentsQuery,
  useGetCompanyDepartmentQuery,
  useGetCompanyDepartmentsByCompanyQuery,
  usePatchCompanyDepartmentMutation,
  usePostCompanyDepartmentMutation,
  useDeleteCompanyDepartmentMutation,
  useGetAllServiceByCompanyDepartmentQuery,
} = loyaltyProgramsApi;
