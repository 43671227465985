import { Button } from "react-bootstrap";
import { ReactComponent as Bubble } from "../../../assets/Icons/message-circle.svg";
import styles from "./QuickHelpButton.module.scss";
import { useState } from "react";
import ChatSupportFormModal from "components/organisms/ChatSupportFormModal/ChatSupportFormModal";

export default function QuickHelpButton() {
  // TODO: do zastapienia pozniej
  // const openHelpMessage = useOpenHelpMessage();

  const [isChatSupportFormModalVisible, setIsChatSupportFormModalVisible] =
    useState<boolean>(false);

  const handleShowingChatSupportFormModal = () => {
    setIsChatSupportFormModalVisible(true);
  };

  return (
    <>
      <Button
        onClick={handleShowingChatSupportFormModal}
        className={`${styles.button} ${styles.active}`}
      >
        <Bubble />
        NAPISZ DO NAS
      </Button>
      <ChatSupportFormModal
        show={isChatSupportFormModalVisible}
        onHide={() => setIsChatSupportFormModalVisible(false)}
        setShow={setIsChatSupportFormModalVisible}
      />
    </>
  );
}
