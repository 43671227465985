import ImageGrid from "components/atoms/ImageGrid/ImageGrid";
import { Col, Container, Row } from "react-bootstrap";

const MediaGallery = ({ data, setContent, setShowParentModal, setSelectedImage }: MediaGalleryProps) => {
  return (
    <Container fluid>
      <Row className="">
        {data?.map((item: Image) => (
          <Col key={item.id} className="py-2 p-2" xs="auto">
            <ImageGrid
              data={item}
              setContent={setContent}
              setSelectedImage={setSelectedImage}
              setShowParentModal={setShowParentModal}
            >
              <img
                src={item.accessUrl}
                className="mx-auto d-block"
                alt={item.alt}
              ></img>
            </ImageGrid>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MediaGallery;
