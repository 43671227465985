import React from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";

export const CodeModal = (props: PropsCode) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
      size={props.size}
      fullscreen={"sm-down"}
    >
      <Modal.Header closeButton className={"border-bottom-0 "}>
        <Col>
          <Modal.Title className="fw-bold text-dark-blue">
            <span>{props.label}</span>
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
        {props.children}
      </Modal.Body>
      <Modal.Footer className={"border-top-0"}>
        <Container className="d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit"
            value="Submit"
            onClick={props.handleClose}
          >
            ZAMKNIJ
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
