import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { usePostCardCodesMutation } from "redux/services/cardCodesApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface CardCodesFormData {
  many: string;
}

const CreateCardCodes = () => {
  const [postCardCodes] = usePostCardCodesMutation();
  const { t } = useTranslation();
  const { register, handleSubmit, setError, getValues } =
    useForm<CardCodesFormData>({
      defaultValues: {
        many: "1",
      },
    });

  const labelStyles = "mb-1 fs-14 lh-base";

  const onSubmit = async () => {
    const formData = { ...getValues(), many: parseInt(getValues("many"), 10) };

    if (formData?.many >= 1) {
      try {
        await postCardCodes({ data: formData });
        toast.success(t("toast_createCardCodes_success"));
      } catch (error) {
        toast.error(t("toast_createCardCodes_error"));
      }
    } else {
      setError("many", {
        type: "Minimalna liczba to 1",
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex gap-4 align-items-end"
    >
      <Form.Group>
        <Form.Label className={labelStyles} htmlFor="createCardCodes">
          Wpisz liczbę kodów do stworzenia
        </Form.Label>
        <Form.Control
          type="number"
          id="createCardCodes"
          min="1"
          {...register("many")}
        />
      </Form.Group>
      <Button
        className="btn-outline-blue mt-2 h-50"
        type="submit"
        value="Submit"
      >
        Stwórz kody
      </Button>
    </Form>
  );
};

export default CreateCardCodes;
