import { useState, useEffect } from "react";
import { Eye, InfoCircle } from "react-bootstrap-icons";
import styles from "./TextInput.module.scss";
import TooltipIcon from "../TooltipIcon";

const TextInput = ({
  label,
  register,
  hintText,
  placeholder,
  type,
  className,
  disabled,
  error,
  limitSeo,
  withTooltip,
  tooltipText,
  inputClassName,
  inputWrapperClassname,
  labelClassName,
  minLength,
  maxLength,
  min,
  max,
}: TextInputProps) => {
  const [limitCounter, setLimitCounter] = useState(0);
  const [getType, setType] = useState(type);
  const changePassword = () => {
    getType === "password" ? setType("text") : setType("password");
  };

  useEffect(() => {
    if (!limitSeo) return;
    if (limitSeo > 0) {
      setLimitCounter(limitSeo);
    }
  }, [limitSeo]);

  return (
    <label className={`form-check-label col-auto d-flex ${className}`}>
      {label && (
        <div className="d-flex flex-row">
          <span
            className={`me-2 ${styles.labelMargin} ${labelClassName}`}
            style={type === "EventInput" ? { width: "auto" } : { width: 120 }}
          >
            {label}
          </span>
          {withTooltip && (
            <TooltipIcon
              placement="right-start"
              desc={tooltipText ? tooltipText.replaceAll("&oacute;", "ó") : ""}
            >
              <InfoCircle size={16} color="#00b1e7" />
            </TooltipIcon>
          )}
        </div>
      )}
      <div className={`position-relative ${inputWrapperClassname}`}>
        <input
          minLength={minLength}
          maxLength={maxLength}
          min={min}
          max={max}
          type={getType}
          placeholder={placeholder}
          className={`form-control position-relative ${styles.InputStyle} ${inputClassName}`}
          disabled={disabled}
          key="password"
          style={{ border: error && "1px solid red" }}
          onChange={(e) =>
            !!limitSeo?.toString().length &&
            setLimitCounter(e.target.value.length)
          }
          {...register}
        />
        {type === "password" && (
          <Eye
            size={25}
            onClick={changePassword}
            className="position-absolute"
            style={{ top: "6px", right: "15px" }}
          />
        )}
      </div>
      {hintText && <p className="fs-12 fw-400 text-grey mt-1">{hintText}</p>}
      {error && <p className="text-danger fs-12">{error}</p>}
      {!!limitSeo?.toString().length &&
        (80 - limitCounter > -1 ? (
          <p className="text-primary fs-12">limit seo [{80 - limitCounter}]</p>
        ) : (
          <p className="text-danger fs-12">limit seo [{80 - limitCounter}]</p>
        ))}
    </label>
  );
};

export default TextInput;
