import styles from "../../templates/ChangesTemplate/ChangesTemplate.module.scss";
import { Badge, Button, Col, Row, Stack } from "react-bootstrap";
import SortButton from "../SortButton/SortButton";
import CustomPagination from "../CustomPagination/CustomPagination";
import React, { useState } from "react";
import { ReactComponent as Bell } from "../../../assets/Icons/Bellv2.svg";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import { useSearchParams } from "react-router-dom";

interface Props {
  nameLabel: string;
  type: AcceptableChanges;
  limit: number;
  currPage: number;
  sortBy: string;
  handlePage: (e: number, type: AcceptableChanges) => void;
  changesSortHandler: (
    type: AcceptableChanges,
    orderBy: OrderByChanges,
  ) => void;
  sortedArray: SortInterface[];
  totalResults: number | null;
  children: React.ReactNode;
}
export const ChangesAcceptedAccordion = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActive, setIsActive] = useState(
    !!searchParams.get(`${props.type}Action`) || false,
  );
  const handlePage = (e: number) => {
    props.handlePage(e, props.type);
  };

  const handleSort = (orderBy: OrderByChanges) => {
    props.changesSortHandler(props.type, orderBy);
  };

  const handleAction = () => {
    setIsActive((prev: boolean) => !prev);
    searchParams.set(`${props.type}Action`, isActive ? "" : "1");
    setSearchParams(searchParams);
  };

  return (
    <Row className={`${styles.wrapper}`}>
      <div className={"d-flex justify-content-between"}>
        <div className={"fs-22 fw-600 text-dark-blue"}>{props.nameLabel}</div>
        <div className={"d-flex align-items-center"}>
          <Badge
            className={`${
              props.totalResults ? "bg-danger" : "bg-transparent text-grey-2"
            } d-flex px-2 mx-3`}
          >
            <div className={"me-2"} style={{ width: 12, height: 12 }}>
              <Bell className={"h-100 w-100"} />
            </div>
            {props.totalResults}
          </Badge>
          <Button
            style={{ width: 38, height: 38 }}
            variant={isActive ? "dark" : "transparent"}
            className={`d-flex justify-content-center align-items-center p-0 m-0 ${
              isActive ? "text-light" : "text-dark-blue bg-transparent border-0"
            }`}
            onClick={handleAction}
          >
            <Arrow
              style={{
                transition: "transform 200ms",
                transform: isActive ? "rotate(-90deg)" : "rotate(90deg)",
              }}
            />
          </Button>
        </div>
      </div>
      {isActive &&
        (props.totalResults ? (
          <>
            <Stack className={"mt-3"} gap={3}>
              <div className={"d-flex mb-5"}>
                {props.sortedArray?.map(
                  (value: SortInterface, index: number) => {
                    const sort = props.sortBy.split(",");
                    return (
                      <SortButton
                        key={index}
                        label={value.label}
                        onClick={() => handleSort(value.type)}
                        isArrowRotated={
                          sort[0] === value.type && sort[1] === "ASC"
                        }
                        className={`pt-3 pb-0 pe-3 fs-16 ${
                          sort[0] === value.type ? "text-dark-blue" : ""
                        }`}
                      />
                    );
                  },
                )}
              </div>
              {props.children}
            </Stack>
            <Col className="d-flex justify-content-end mt-3">
              <CustomPagination
                totalCount={props.totalResults}
                pageSize={props.limit}
                currentPage={props.currPage}
                onPageChange={handlePage}
                paginationClassName="text-nowrap"
              />
            </Col>
          </>
        ) : (
          <div
            className={`text-center py-5 mt-5 ${styles.emptyData} text-grey`}
          >
            Brak nowych zmian w tej kategorii
          </div>
        ))}
    </Row>
  );
};
