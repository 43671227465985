import { substance } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

export interface PostSubstanceUploadFileForm {
  file: File[];
  countryId: string;
}

interface GetSubstanceIndexes {
  limit?: string;
  offset?: string;
  countryId?: string;
}

interface SubstanceData {
  id: number;
  name: string;
  slug: string;
}

export interface SubstanceResponse {
  data: SubstanceData[];
  meta: { status: string; totalResults: number };
}

interface GetSubstance {
  limit?: string;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Substance"],
});

export const substanceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getActiveSubstanceShort: builder.query({
      query: ({ cancerId, indexId, countryId }) => {
        const params = new URLSearchParams({
          ...(cancerId && { cancerId }),
          ...(indexId && { indexId }),
          ...(countryId && { countryId }),
        });

        return {
          url: `${substance}/active-substance-short?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Substance"],
    }),
    getSubstance: builder.query<SubstanceResponse, GetSubstance>({
      query: ({ limit }) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
        });

        return {
          url: `${substance}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    getIndexes: builder.query({
      query: ({ limit, offset, countryId }: GetSubstanceIndexes) => {
        const params = new URLSearchParams({
          ...(countryId && { countryId }),
          ...(limit && { limit }),
          ...(offset && { offset }),
        });

        return {
          url: `${substance}/indexes?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Substance"],
    }),

    postSubstanceUploadFile: builder.mutation({
      query: ({ file, countryId }: PostSubstanceUploadFileForm) => {
        let formdata = new FormData();
        formdata.append("file", file[0]);
        formdata.append("countryId", countryId);
        return {
          url: `${substance}/upload_file`,
          method: "POST",
          headers: {},
          body: formdata,
        };
      },
      invalidatesTags: ["Substance"],
    }),
  }),
});

export const {
  useGetActiveSubstanceShortQuery,
  useLazyGetActiveSubstanceShortQuery,
  usePostSubstanceUploadFileMutation,
  useGetIndexesQuery,
  useGetSubstanceQuery,
  useLazyGetSubstanceQuery,
} = substanceApi;
