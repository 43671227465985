import { InfoCircle } from "react-bootstrap-icons";
import TooltipIcon from "../TooltipIcon";
import styles from "./SelectInput.module.scss";

const SelectInput = ({
  label,
  data,
  register,
  disabled,
  onChange,
  onChangeEventFilter,
  interval,
  defaultValue,
  style,
  error,
  type,
  className,
  withTooltip,
  tooltipText,
  labelClassName,
}: SelectInputProps) => {
  return (
    <label
      className={`form-check-label col-auto d-flex ${
        type === "EventInput"
          ? `fs-16 fw-400 mt-3 flex-column ${className}`
          : "align-items-center"
      }`}
    >
      {label && (
        <div className="d-flex flex-row">
          <span
            className={`me-2 ${styles.labelMargin} ${labelClassName}`}
            style={type === "EventInput" ? { width: "auto" } : { width: 120 }}
          >
            {label}
          </span>
          {withTooltip && (
            <TooltipIcon
              placement="right-start"
              desc={tooltipText ? tooltipText.replaceAll("&oacute;", "ó") : ""}
            >
              <InfoCircle size={16} color="#00b1e7" />
            </TooltipIcon>
          )}
        </div>
      )}
      <select
        className="form-select"
        style={style}
        aria-label="Default select example"
        disabled={disabled}
        onChangeCapture={type === "EventInput" ? onChangeEventFilter : onChange}
        {...register}
      >
        {interval ? null : (
          <option value={type === "EventInput" ? defaultValue : ""}>
            {defaultValue}
          </option>
        )}
        {data?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
      {error && <p className="text-danger fs-13">{error}</p>}
    </label>
  );
};

export default SelectInput;
