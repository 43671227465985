import { userRoles } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetUserRolesProps {
  limit?: string;
  offset?: string;
  sortBy?: string;
  role?: string;
  name?: string;
  filterBy?: "display" | "polls";
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["UserRoles"],
});

export const userRolesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserRoles: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        role,
        name,
        filterBy,
      }: GetUserRolesProps) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(role && { role }),
          ...(name && { name }),
          ...(filterBy && { filterBy }),
        });

        return {
          url: `${userRoles}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["UserRoles"],
    }),

    getUserRole: builder.query({
      query: (id) => {
        return {
          url: `${userRoles}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["UserRoles"],
    }),
  }),
});

export const { useGetUserRolesQuery, useLazyGetUserRolesQuery, useGetUserRoleQuery } = userRolesApi;
