import { Col, Container, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Context,
  useGetDoctorReviewsQuery,
} from "../../../redux/services/doctorApi";
import SortButton from "../SortButton/SortButton";
import { ReactComponent as FilterAlt } from "../../../assets/Icons/FilterAlt.svg";
import { DoctorReviewFiltrModal } from "../DoctorReviewFiltrModal/DoctorReviewFiltrModal";
import { SearchFiltering } from "../DoctorReviewFiltrModal/types";
import { DoctorReview } from "../../molecules/DoctorReview/DoctorReview";
import { DoctorReviewData } from "../../molecules/DoctorReview/types";
import CustomPagination from "../CustomPagination/CustomPagination";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import styles from "./DoctorReviewDashboard.module.scss";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

const INITIAL_SEARCH_PARAMS: InitialDoctorReview = {
  PAGE: 1,
  LIMIT: 3,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
  SORT: "createAt,DESC",
  STATUS: "",
  ALL: "1",
};

const sortArray: SortInterface[] = [
  { type: "createAt", label: "Data wystawienia" },
  { type: "rating", label: "Ocena" },
];

interface Props {
  doctorId: number;
  userId: number;
  profileAccept: boolean;
}

export const DoctorReviewDashboard = ({
  doctorId,
  userId,
  profileAccept,
}: Props) => {
  const [currPage, setCurrPage] = useState<number>(INITIAL_SEARCH_PARAMS.PAGE);
  const [context, setContext] = useState<Context>();
  const [sortBy, setSortBy] = useState<string>(INITIAL_SEARCH_PARAMS.SORT);
  const [status, setStatus] = useState<StatusChangeType>(
    INITIAL_SEARCH_PARAMS.STATUS
  );
  const { t } = useTranslation();
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
  const {
    data: doctorReviewData,
    isLoading,
    refetch,
  } = useGetDoctorReviewsQuery(
    doctorId
      ? {
          doctorId: doctorId?.toString(),
          limit: INITIAL_SEARCH_PARAMS.LIMIT.toString(),
          offset: currPage
            ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
            : INITIAL_SEARCH_PARAMS.OFFSET,
          userId: userId.toString(),
          context: context,
          status: status,
          sortBy: sortBy,
          all: INITIAL_SEARCH_PARAMS.ALL,
        }
      : skipToken
  );

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isLoading]);

  if (isLoading || !doctorId) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handleSort = (orderBy: OrderByChanges) => {
    const [order, sort] = sortBy.split(",");
    if (order === orderBy) {
      setSortBy(sort === "ASC" ? `${orderBy},DESC` : `${orderBy},ASC`);
    } else {
      setSortBy(`${orderBy},ASC`);
    }
  };

  const filterHandler = (changeOptions: SearchFiltering) => {
    setCurrPage(0);
    if (changeOptions?.status) {
      const value = changeOptions?.status?.value as StatusChangeType;
      setStatus(value);
    }
    if (changeOptions?.context) {
      const value = changeOptions?.context?.value as Context;
      setContext(value);
    }
  };
  return (
    <Container fluid className={`p-4 containerWithShadow`} id={"doctorReview"}>
      <Col className={"d-flex"}>
        <h2
          className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.containerTitle}`}
        >
          {t("widget_doctor_reviews_title")}
        </h2>
        <Link
          to={`/${NAV_PATH.DOCTOR}/${doctorId}`}
          target={"_blank"}
          className="px-1 ms-2 mt-1 text-primary d-none d-lg-block fs-16 fw-400"
        >
          {t("widget_doctor_reviews_title_link")}
        </Link>
      </Col>
      <Col>
        <div
          className={
            "d-flex flex-column flex-lg-row justify-content-between align-items-start gap-3 mb-4"
          }
        >
          <div className={"d-flex flex-row gap-4"}>
            {sortArray.map((value: SortInterface) => {
              const sort = sortBy.split(",");
              return (
                <SortButton
                  key={value.type}
                  label={value.label}
                  onClick={() => handleSort(value.type)}
                  isArrowRotated={sort[0] === value.type && sort[1] === "ASC"}
                  className={`fs-16 fw-400 ${
                    sort[0] === value.type ? "text-dark-blue" : ""
                  }`}
                />
              );
            })}
          </div>
          <div
            className={
              "cursor-pointer d-flex align-items-center text-dark-blue"
            }
            onClick={() => setShowModalFilter(true)}
          >
            <span className={"me-2 fs-16"}>
              {t("widget_doctor_reviews_filter_button")}
            </span>
            <FilterAlt />
          </div>
          <DoctorReviewFiltrModal
            show={showModalFilter}
            handleClose={() => setShowModalFilter(false)}
            handleFiltr={filterHandler}
            context={context}
            status={status}
          />
        </div>
      </Col>
      <Col>
        {doctorReviewData?.data?.map((reviewData: DoctorReviewData) => (
          <DoctorReview
            key={reviewData.id}
            data={reviewData}
            refetch={refetch}
            parent={true}
            profileAccept={profileAccept}
          />
        ))}
      </Col>
      <Col className="d-flex justify-content-end mt-2">
        <CustomPagination
          totalCount={
            doctorReviewData.meta.totalResults ||
            INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
          }
          pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
          currentPage={currPage}
          onPageChange={(page: number) => setCurrPage(page)}
          paginationClassName="text-nowrap"
        />
      </Col>
    </Container>
  );
};
