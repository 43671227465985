import { pages } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Pages"],
});

export const pagesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query({
      query: ({
        titleLike,
        offset,
        limit,
        categoryIds,
        sortBy,
        parentNotAssigned,
        cancerId,
        categorySlugs,
      }) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(titleLike && { titleLike }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(cancerId && { cancerId }),
          ...(categoryIds && { categoryIds }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(parentNotAssigned && { parentNotAssigned: 1 }),
          ...(categorySlugs && { categorySlugs }),
        });

        return {
          url: `${pages}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Pages"],
    }),

    getPagesByNameAndCategory: builder.query<
      PageDataTreatment[],
      GetPagesByNameAndCategorySearchParams
    >({
      query: ({ titleLike, categoryIds }) => {
        const params = new URLSearchParams({
          ...(titleLike && { titleLike }),
          ...(categoryIds && { categoryIds: categoryIds.join(",") }),
        });

        return {
          url: `${pages}/findByNameAndCategory?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Pages"],
    }),

    getPage: builder.query({
      query: (id) => {
        return {
          url: `${pages}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Pages"],
    }),

    patchPageUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url: `${pages}/${id}/update`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Pages"],
    }),

    postCreatePage: builder.mutation({
      query: ({ data }) => ({
        url: `${pages}/create-page`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Pages"],
    }),

    deletePage: builder.mutation({
      query: (id: string) => {
        return {
          url: `${pages}/${id}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Pages"],
    }),
  }),
});

export const {
  useGetPagesQuery,
  useLazyGetPagesQuery,
  usePatchPageUpdateMutation,
  usePostCreatePageMutation,
  useGetPageQuery,
  useGetPagesByNameAndCategoryQuery,
  useDeletePageMutation,
} = pagesApi;
