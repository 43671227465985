import { useState } from "react";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./TableRowWithCollapse.module.scss";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { useDeletePageMutation } from "redux/services/pagesApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

const TableRowWithCollapse = ({
  data,
  CollapseItem,
  child,
}: tableRowWithCollapseInterface) => {
  const [isQuickEditOpen, setIsQuickEditOpen] = useState(false);
  const [isBinOpen, setIsBinOpen] = useState(false);

  const [deletePage] = useDeletePageMutation();

  const { t } = useTranslation();

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}/${data?.id}/edit`,
    },
    {
      name: "Szybka edycja",
      action: () => setIsQuickEditOpen((prevState) => !prevState),
    },
    {
      name: "Kosz",
      action: () => setIsBinOpen(true),
    },
  ];
  const deletePageAction = async (id: number) => {
    const hasBeenDeleted = await deletePage(id.toString());
    if ("data" in hasBeenDeleted) {
      if (hasBeenDeleted?.data?.meta?.status === "success") {
        toast.success(t("toast_page_delete_success"));
      } else if (hasBeenDeleted?.data?.statusCode === 400) {
        toast.error(t("toast_page_delete_error_400"));
      } else {
        toast.error(t("toast_page_delete_error"));
      }
    }

    setIsBinOpen(false);
  };

  return (
    <>
      <tr
        aria-controls={`example-collapse-text`}
        aria-expanded={isQuickEditOpen}
        className={`${styles.mainTr}  ${child ? styles.childTr : ""} ${
          child === 2 ? styles.innerChildTr : ""
        }`}
      >
        <td>
          {child === 1 && "— "}
          {child === 2 && "— — "}
          {child === 3 && "— — — "}
          {child === 4 && "— — — — "}

          {data?.title}
        </td>
        <td>{data?.createdBy?.name}</td>
        <td>{data?.category?.map((item) => item?.title)?.join(", ")}</td>
        <td>{data?.createdAt?.slice(0, 10)}</td>
      </tr>
      {!isQuickEditOpen && (
        <tr
          className={`${styles.hoverTr} ${child ? styles.childTr : ""} ${
            child === 2 ? styles.innerChildTr : ""
          }`}
        >
          <td colSpan={4}>
            <Breadcrumb data={breadcrumbData} />
          </td>
        </tr>
      )}

      {isQuickEditOpen && (
        <tr
          className={`${styles.collapseTr} ${child ? styles.childTr : ""} ${
            child === 2 ? styles.innerChildTr : ""
          }`}
          data-active={isQuickEditOpen}
        >
          <td colSpan={4}>
            <CollapseItem
              open={isQuickEditOpen}
              data={data}
              setOpen={setIsQuickEditOpen}
            />
          </td>
        </tr>
      )}
      {isBinOpen && (
        <PopupModal
          titleClasses={"fs-16 ls-3 fw-semibold text-center text-dark-blue"}
          show={isBinOpen}
          setShow={() => setIsBinOpen(false)}
          text={"Czy chcesz usunąć stronę?"}
          confirmAction={() => deletePageAction(data?.id)}
        />
      )}
    </>
  );
};

export default TableRowWithCollapse;
