import styles from "./DisplayFile.module.scss";

const DisplayFile: React.FC<{ file: FileData }> = ({ file }) => {
  const isImageFile = (url: string): boolean => {
    const ext = url.split(".").pop()?.toLowerCase() || "";
    return ["jpg", "jpeg", "png", "heic"].includes(ext);
  };

  if (isImageFile(file.accessUrl)) {
    return (
      <div className={`${styles.previewCard}`}>
        <img src={file.accessUrl} alt={file.filename} />
        <p>{file.filename}</p>
      </div>
    );
  } else {
    return <p>{file.filename}</p>;
  }
};
export default DisplayFile;
