import React from "react";

interface Props {
  promoCodesAmount: number;
  codeUsageCount: number;
}

export const CodesAvailable = ({ promoCodesAmount, codeUsageCount }: Props) => {
  if (promoCodesAmount !== null && promoCodesAmount !== undefined) {
    return (
      <div>
        <div className={"fs-12 pb-2 text-grey-2"}>
          <span>Dostępnych kodów pozostało:</span>
        </div>
        <div className={"fs-25 pb-2 text-dark-blue fw-bolder"}>
          <span>{promoCodesAmount - codeUsageCount}</span>
        </div>
      </div>
    );
  }
  return <></>;
};
