import Container from "react-bootstrap/Container";
import {ReactComponent as StarEmpty} from "../../../assets/Icons/starEmpty.svg";
import {ReactComponent as StarHalf} from "../../../assets/Icons/starHalf.svg";
import {ReactComponent as StarFilled} from "../../../assets/Icons/starFilled.svg";
import styles from "./StarRating.module.scss";

interface StarsRatingProps {
    rating?: number;
}

const StarsRating = ({rating = 0}: StarsRatingProps) => {
    const renderStars = () => {
        let currentRating = rating;
        let stars = [];

        for (let i = 0; i < 5; i++) {
            if (currentRating >= 0.9) {
                stars.push(1);
            } else if (currentRating > 0) {
                stars.push(2);
            } else {
                stars.push(0);
            }
            currentRating--;
        }

        return stars.map((star, index) => {
            if (star === 1) {
                return <StarFilled key={index} width={16} height={16}/>;
            } else if (star === 2) {
                return <StarHalf key={index} width={16} height={16}/>;
            } else {
                return <StarEmpty key={index} width={16} height={16}/>;
            }
        });
    };

    return (
        <Container
            className={`col-4 d-flex flex-row align-items-center justify-content-center w-auto m-0 p-0 gap-1 ${
                rating <= 0 ? `${styles.starGray}` : `${styles.starInfo}`
            }`}
        >
            {renderStars()}
        </Container>
    );
};

export default StarsRating;