import { Container, Spinner, Stack } from "react-bootstrap";
import { useGetCompaniesQuery } from "../../../redux/services/companyApi";
import React, { useEffect, useState } from "react";
import styles from "./ChangesTemplate.module.scss";
import { useGetCompanyDepartmentsQuery } from "../../../redux/services/companyDepartmentApi";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { useSearchParams } from "react-router-dom";
import { ChangesAcceptedContentAccordion } from "../../organisms/ChangesAcceptedContentAccordion/ChangesAcceptedContentAccordion";
import {
  useGetDoctorsQuery,
  useLazyGetDoctorsReviewsQuery,
  usePatchDoctorReviewMutation,
} from "../../../redux/services/doctorApi";
import {
  useLazyGetFacilityReviewsQuery,
  usePatchFacilityReviewsMutation,
} from "../../../redux/services/facilityReviewsApi";
import { ChangesAcceptedAccordion } from "../../organisms/ChangesAcceptedAccordion/ChangesAcceptedAccordion";
import {
  useLazyGetCommentsByIdQuery,
  useLazyGetPostsQuery,
  usePatchCommentMutation,
  usePatchPostMutation,
} from "../../../redux/services/feedApi";
import { useTranslation } from "react-i18next";
import {
  Company,
  CompanyDepartment,
  LoyaltyProgramServiceData,
} from "../../molecules/LoyaltyProgramServiceArticle/types";
import { ChangesEntity } from "../../molecules/ChangesEntity/ChangesEntity";
import { DoctorEntity } from "../../molecules/DoctorEntity/DoctorEntity";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW: Initial = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  STATUS: "AWAITING_REVIEW",
  SORT: "createAt,ASC",
};
const INITIAL_SEARCH_PARAMS_FACILITY_REVIEW: Initial = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  STATUS: "AWAITING_REVIEW",
  SORT: "createdAt,ASC",
};
const INITIAL_SEARCH_PARAMS_FEED: Initial = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  STATUS: "ABUSED",
  SORT: "created_at,ASC",
};
const INITIAL_SEARCH_PARAMS_FEED_COMMENTS: Initial = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  STATUS: "ABUSED",
  SORT: "created_at,ASC",
};
const INITIAL_SEARCH_PARAMS_DOCTOR = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  SORT: "name,ASC",
  STATUS: "AWAITING_REVIEW",
};
const INITIAL_SEARCH_PARAMS_COMPANY = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  SORT: "changes,ASC",
};
const INITIAL_SEARCH_PARAMS_DEPARTMENT = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  SORT: "changes,ASC",
};
const INITIAL_SEARCH_PARAMS_SERVICE = {
  LIMIT: 5,
  OFFSET: "0",
  PAGE: 1,
  SORT: "changes,ASC",
};
const INITIAL_TOTAL_RESULTS = 0;

export const ChangesTemplate: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPageCompany, setCurrPageCompany] = useState(
    Number(searchParams.get("pageCompany")) ||
      INITIAL_SEARCH_PARAMS_COMPANY.PAGE
  );
  const [currPageDoctor, setCurrPageDoctor] = useState(
    Number(searchParams.get("pageDoctor")) || INITIAL_SEARCH_PARAMS_DOCTOR.PAGE
  );
  const [currPageCompanyDepartment, setCurrPageCompanyDepartment] = useState(
    Number(searchParams.get("pageCompanyDepartment")) ||
      INITIAL_SEARCH_PARAMS_DEPARTMENT.PAGE
  );
  const [currPageService, setCurrPageService] = useState(
    Number(searchParams.get("pageService")) ||
      INITIAL_SEARCH_PARAMS_SERVICE.PAGE
  );
  const [sortByDoctor, setSortByDoctor] = useState<string>(
    searchParams.get("sortByDoctor") || INITIAL_SEARCH_PARAMS_DOCTOR.SORT
  );
  const [sortByCompany, setSortByCompany] = useState<string>(
    searchParams.get("sortByCompany") || INITIAL_SEARCH_PARAMS_COMPANY.SORT
  );
  const [sortByCompanyDepartment, setSortByCompanyDepartment] =
    useState<string>(
      searchParams.get("sortByCompanyDepartment") ||
        INITIAL_SEARCH_PARAMS_DEPARTMENT.SORT
    );

  const [sortByServices, setSortByServices] = useState<string>(
    searchParams.get("sortByServices") || INITIAL_SEARCH_PARAMS_SERVICE.SORT
  );
  const [acceptedDoctor, setAcceptedDoctor] = useState<number[]>([]);
  const [acceptedFacility, setAcceptedFacility] = useState<number[]>([]);
  const [acceptedFeed, setAcceptedFeed] = useState<number[]>([]);
  const [acceptedFeedComments, setAcceptedFeedComments] = useState<number[]>(
    []
  );

  const options: OptionsChanges[] = [
    {
      value: "AWAITING_REVIEW",
      label: t("status_AWAITING_REVIEW"),
      type: "status",
    },
    { value: "ACCEPTED", label: t("status_ACCEPTED"), type: "status" },
    { value: "REMOVED", label: t("status_REMOVED"), type: "status" },
    { value: "REJECTED", label: t("status_REJECTED"), type: "status" },
    { value: "ABUSED", label: t("status_ABUSED"), type: "status" },
  ];

  const [doctorObj, setDoctorObj] = useState<AcceptedObjSend>({
    limit: INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW.LIMIT.toString(),
    offset: INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW.OFFSET,
    sortBy: INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW.SORT,
    status: INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW.STATUS,
    page: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.PAGE,
  });
  const [facilityObj, setFacilityObj] = useState<AcceptedObjSend>({
    nameLike: "",
    status: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.STATUS,
    limit: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.LIMIT.toString(),
    offset: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.OFFSET,
    page: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.PAGE,
    sortBy: INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.SORT,
  });
  const [feedObj, setFeedObj] = useState<AcceptedObjSend>({
    status: INITIAL_SEARCH_PARAMS_FEED.STATUS,
    limit: INITIAL_SEARCH_PARAMS_FEED.LIMIT.toString(),
    offset: INITIAL_SEARCH_PARAMS_FEED.OFFSET,
    page: INITIAL_SEARCH_PARAMS_FEED.PAGE,
    sortBy: INITIAL_SEARCH_PARAMS_FEED.SORT,
  });
  const [feedCommentsObj, setFeedCommentsObj] = useState<AcceptedObjSend>({
    status: INITIAL_SEARCH_PARAMS_FEED_COMMENTS.STATUS,
    limit: INITIAL_SEARCH_PARAMS_FEED_COMMENTS.LIMIT.toString(),
    offset: INITIAL_SEARCH_PARAMS_FEED_COMMENTS.OFFSET,
    page: INITIAL_SEARCH_PARAMS_FEED_COMMENTS.PAGE,
    sortBy: INITIAL_SEARCH_PARAMS_FEED_COMMENTS.SORT,
  });

  const {
    data: doctorsData,
    isLoading: isLoadingDoctors,
    isFetching: isFetchingDoctors,
  } = useGetDoctorsQuery({
    limit: INITIAL_SEARCH_PARAMS_DOCTOR.LIMIT.toString(),
    offset: currPageDoctor
      ? ((currPageDoctor - 1) * INITIAL_SEARCH_PARAMS_DOCTOR.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS_DOCTOR.OFFSET,
    status: INITIAL_SEARCH_PARAMS_DOCTOR.STATUS as StatusChangeType,
    sortBy: sortByDoctor,
  });

  const {
    data: companyData,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompany,
  } = useGetCompaniesQuery({
    changes: 1,
    limit: INITIAL_SEARCH_PARAMS_COMPANY.LIMIT,
    offset: currPageCompany
      ? ((currPageCompany - 1) * INITIAL_SEARCH_PARAMS_COMPANY.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS_COMPANY.OFFSET,
    sortBy: sortByCompany,
  });

  const {
    data: companyDepartmentsData,
    isLoading: isLoadingCompanyDepartments,
    isFetching: isFetchingCompanyDepartment,
  } = useGetCompanyDepartmentsQuery({
    changes: "1",
    limit: INITIAL_SEARCH_PARAMS_DEPARTMENT.LIMIT.toString(),
    offset: currPageCompanyDepartment
      ? (
          (currPageCompanyDepartment - 1) *
          INITIAL_SEARCH_PARAMS_DEPARTMENT.LIMIT
        ).toString()
      : "0",
    sortBy: sortByCompanyDepartment,
  });

  const {
    data: loyaltyProgramsServicesData,
    isLoading: isLoadingLoyaltyProgramsServices,
    isFetching: isFetchingLoyaltyProgramsServices,
  } = useGetLoyaltyProgramsServicesQuery({
    changes: 1,
    limit: INITIAL_SEARCH_PARAMS_SERVICE.LIMIT,
    offset: currPageService
      ? ((currPageService - 1) * INITIAL_SEARCH_PARAMS_SERVICE.LIMIT).toString()
      : "0",
    sortBy: sortByServices,
  });

  const [
    patchDoctorReviewMutation,
    {
      isSuccess: patchDoctorReviewIsSuccess,
      data: dataPatchDoctorReviewMutation,
    },
  ] = usePatchDoctorReviewMutation();

  const [
    fetchDataDoctorsReviews,
    { isLoading: isLoadingDoctorsReview, data: dataDoctorsReview },
  ] = useLazyGetDoctorsReviewsQuery();

  const [
    patchFacilityReviewMutation,
    {
      isSuccess: patchFacilityReviewIsSuccess,
      data: dataPatchFacilityReviewMutation,
    },
  ] = usePatchFacilityReviewsMutation();

  const [
    fetchDataFacilityReview,
    { isLoading: isLoadingFacilityReview, data: dataFacilityReview },
  ] = useLazyGetFacilityReviewsQuery();

  const [
    patchFeedMutation,
    { isSuccess: patchFeedIsSuccess, data: dataPatchFeedMutation },
  ] = usePatchPostMutation();

  const [fetchDataFeed, { isLoading: isLoadingFeed, data: dataFeed }] =
    useLazyGetPostsQuery();

  const [
    patchFeedCommentsMutation,
    {
      isSuccess: patchFeedCommentsIsSuccess,
      data: dataPatchFeedCommentsMutation,
    },
  ] = usePatchCommentMutation();

  const [
    fetchDataFeedComments,
    { isLoading: isLoadingFeedComments, data: dataFeedComments },
  ] = useLazyGetCommentsByIdQuery();

  useEffect(() => {
    if (dataPatchDoctorReviewMutation) {
      setAcceptedDoctor([...acceptedDoctor, dataPatchDoctorReviewMutation.id]);
    }
    if (dataPatchFacilityReviewMutation) {
      setAcceptedFacility([
        ...acceptedFacility,
        dataPatchFacilityReviewMutation.data.id,
      ]);
    }
    if (dataPatchFeedMutation) {
      setAcceptedFeed([...acceptedFeed, dataPatchFeedMutation.data.id]);
    }
    if (dataPatchFeedCommentsMutation) {
      setAcceptedFeedComments([
        ...acceptedFeedComments,
        dataPatchFeedCommentsMutation.data.id,
      ]);
    }
    // eslint-disable-next-line
  }, [
    patchDoctorReviewIsSuccess,
    patchFacilityReviewIsSuccess,
    patchFeedIsSuccess,
    patchFeedCommentsIsSuccess,
  ]);

  if (
    isLoadingCompanies ||
    isLoadingCompanyDepartments ||
    isLoadingLoyaltyProgramsServices ||
    isLoadingDoctorsReview ||
    isLoadingFacilityReview ||
    isLoadingFeed ||
    isLoadingFeedComments ||
    isLoadingDoctors
  ) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handleReFetch = (type: AcceptableChanges) => {
    switch (type) {
      case "doctorsReview":
        setAcceptedDoctor([]);
        fetchDataDoctorsReviews(doctorObj);
        break;
      case "facilityReview":
        setAcceptedFacility([]);
        fetchDataFacilityReview(facilityObj);
        break;
      case "feed":
        setAcceptedFeed([]);
        fetchDataFeed(feedObj);
        break;
      case "feedComments":
        setAcceptedFeedComments([]);
        fetchDataFeedComments(feedCommentsObj);
        break;
    }
  };

  const handlePatchReview = async (
    type: AcceptableChanges,
    id: number | string,
    data: DoctorReviewPatchData
  ) => {
    switch (type) {
      case "doctorsReview":
        await patchDoctorReviewMutation({
          id: +id,
          data: data,
        });
        break;
      case "facilityReview":
        await patchFacilityReviewMutation({
          id: id,
          data: { ...data, approvedBy: 1, rejectedBy: 1 },
        });
        break;
      case "feed":
        await patchFeedMutation({
          id: id,
          data: data,
        });
        break;
      case "feedComments":
        await patchFeedCommentsMutation({
          id: id,
          data: data,
        });
        break;
    }
  };

  const handlePage = (page: number, type: AcceptableChanges) => {
    switch (type) {
      case "doctor":
        setCurrPageDoctor(page);
        searchParams.set("pageDoctor", page.toString());
        setSearchParams(searchParams);
        break;
      case "company":
        setCurrPageCompany(page);
        searchParams.set("pageCompany", page.toString());
        setSearchParams(searchParams);
        break;
      case "companyDepartment":
        setCurrPageCompanyDepartment(page);
        searchParams.set("pageCompanyDepartment", page.toString());
        setSearchParams(searchParams);
        break;
      case "services":
        setCurrPageService(page);
        searchParams.set("pageService", page.toString());
        setSearchParams(searchParams);
        break;
      case "doctorsReview":
        setDoctorObj({
          ...doctorObj,
          page: page,
          offset: page
            ? ((page - 1) * +doctorObj.limit).toString()
            : INITIAL_SEARCH_PARAMS_DOCTOR_REVIEW.OFFSET,
        });
        break;
      case "facilityReview":
        setFacilityObj({
          ...facilityObj,
          page: page,
          offset: page
            ? ((page - 1) * +facilityObj.limit).toString()
            : INITIAL_SEARCH_PARAMS_FACILITY_REVIEW.OFFSET,
        });
        break;
      case "feed":
        setFeedObj({
          ...feedObj,
          page: page,
          offset: page
            ? ((page - 1) * +feedObj.limit).toString()
            : INITIAL_SEARCH_PARAMS_FEED.OFFSET,
        });
        break;
      case "feedComments":
        setFeedCommentsObj({
          ...feedObj,
          page: page,
          offset: page
            ? ((page - 1) * +feedCommentsObj.limit).toString()
            : INITIAL_SEARCH_PARAMS_FEED_COMMENTS.OFFSET,
        });
        break;
    }
  };

  const changesSortHandler = (
    type: AcceptableChanges,
    orderBy: OrderByChanges
  ) => {
    switch (type) {
      case "services":
        if (sortByServices === `${orderBy},ASC`) {
          searchParams.set("sortByServices", `${orderBy},DESC`);
          setSearchParams(searchParams);
          setSortByServices(`${orderBy},DESC`);
        } else {
          searchParams.set("sortByServices", `${orderBy},ASC`);
          setSearchParams(searchParams);
          setSortByServices(`${orderBy},ASC`);
        }
        break;
      case "company":
        if (sortByCompany === `${orderBy},ASC`) {
          searchParams.set("sortByCompany", `${orderBy},DESC`);
          setSearchParams(searchParams);
          setSortByCompany(`${orderBy},DESC`);
        } else {
          searchParams.set("sortByCompany", `${orderBy},ASC`);
          setSearchParams(searchParams);
          setSortByCompany(`${orderBy},ASC`);
        }
        break;
      case "companyDepartment":
        if (sortByCompanyDepartment === `${orderBy},ASC`) {
          searchParams.set("sortByCompanyDepartment", `${orderBy},DESC`);
          setSearchParams(searchParams);
          setSortByCompanyDepartment(`${orderBy},DESC`);
        } else {
          searchParams.set("sortByCompanyDepartment", `${orderBy},ASC`);
          setSearchParams(searchParams);
          setSortByCompanyDepartment(`${orderBy},ASC`);
        }
        break;
      case "doctor":
        if (sortByDoctor === `${orderBy},ASC`) {
          searchParams.set("sortByDoctor", `${orderBy},DESC`);
          setSearchParams(searchParams);
          setSortByDoctor(`${orderBy},DESC`);
        } else {
          searchParams.set("sortByDoctor", `${orderBy},ASC`);
          setSearchParams(searchParams);
          setSortByDoctor(`${orderBy},ASC`);
        }
        break;
    }
  };

  const handleChangeObject = (
    newObj: AcceptedObjSend,
    type: AcceptableChanges
  ) => {
    switch (type) {
      case "doctorsReview":
        setDoctorObj(newObj);
        break;
      case "facilityReview":
        setFacilityObj(newObj);
        break;
      case "feed":
        setFeedObj(newObj);
        break;
      case "feedComments":
        setFeedCommentsObj(newObj);
        break;
    }
  };

  return (
    <Container fluid={true} className="bg-light">
      <section className={styles.section}>
        <Stack gap={5}>
          <ChangesAcceptedContentAccordion
            handlePage={handlePage}
            nameLabel={"Posty"}
            type={"feed"}
            data={dataFeed}
            handlePatchReview={handlePatchReview}
            handleReFetch={handleReFetch}
            acceptedList={acceptedFeed}
            object={feedObj}
            handleChangeObject={handleChangeObject}
            sortedArray={[
              { type: "created_at", label: "Data utworzenia" },
              { type: "updated_at", label: "Data aktualizacji" },
              { type: "likes_amount", label: "Ilość polubień" },
              { type: "comments_amount", label: "Ilość komentarzy" },
            ]}
            statusOptions={options}
          />
          <ChangesAcceptedContentAccordion
            handlePage={handlePage}
            nameLabel={"Komentarze postów"}
            type={"feedComments"}
            data={dataFeedComments}
            handlePatchReview={handlePatchReview}
            handleReFetch={handleReFetch}
            acceptedList={acceptedFeedComments}
            object={feedCommentsObj}
            handleChangeObject={handleChangeObject}
            statusOptions={options}
          />
          <ChangesAcceptedContentAccordion
            handlePage={handlePage}
            nameLabel={"Opinie doktorów"}
            type={"doctorsReview"}
            data={dataDoctorsReview}
            handlePatchReview={handlePatchReview}
            handleReFetch={handleReFetch}
            acceptedList={acceptedDoctor}
            object={doctorObj}
            handleChangeObject={handleChangeObject}
            sortedArray={[{ type: "createAt", label: "Data aktualizacji" }]}
            link={"https://dev.onkomapa.pl/app/details/doctor/"}
            statusOptions={options}
          />
          <ChangesAcceptedContentAccordion
            nameLabel={"Opinie placówek"}
            type={"facilityReview"}
            data={dataFacilityReview}
            acceptedList={acceptedFacility}
            object={facilityObj}
            handlePatchReview={handlePatchReview}
            handleReFetch={handleReFetch}
            handleChangeObject={handleChangeObject}
            handlePage={handlePage}
            sortedArray={[{ type: "createdAt", label: "Data aktualizacji" }]}
            link={"https://dev.onkomapa.pl/app/details/facility/"}
            statusOptions={options}
          />
          <ChangesAcceptedAccordion
            nameLabel={"Akceptacja doktorów"}
            type={"doctor"}
            limit={INITIAL_SEARCH_PARAMS_DOCTOR.LIMIT}
            currPage={currPageDoctor}
            sortBy={sortByDoctor}
            handlePage={handlePage}
            changesSortHandler={changesSortHandler}
            sortedArray={[
              { type: "name", label: "Nazwa" },
              { type: "totalReviews", label: "Ogółem recenzji" },
              { type: "countReviews", label: "Ilość recenzji" },
            ]}
            totalResults={doctorsData?.meta?.count ?? INITIAL_TOTAL_RESULTS}
          >
            {isFetchingDoctors ? (
              <Container className={"d-flex justify-content-center"}>
                <Spinner animation="border" variant="primary" />
              </Container>
            ) : (
              doctorsData.data.map((value: DoctorHeaderData) => (
                <DoctorEntity
                  key={value.id}
                  link={`/${NAV_PATH.ADMIN}/${NAV_PATH.CHANGES}/${NAV_PATH.DOCTOR}`}
                  data={value}
                />
              ))
            )}
          </ChangesAcceptedAccordion>
          <ChangesAcceptedAccordion
            nameLabel={"Akceptacja profili firmowych"}
            type={"company"}
            limit={INITIAL_SEARCH_PARAMS_COMPANY.LIMIT}
            currPage={currPageCompany}
            sortBy={sortByCompany}
            handlePage={handlePage}
            changesSortHandler={changesSortHandler}
            sortedArray={[
              { type: "updatedAt", label: "Data aktualizacji" },
              { type: "changes", label: "Typ" },
            ]}
            totalResults={
              companyData?.meta?.totalResults ?? INITIAL_TOTAL_RESULTS
            }
          >
            {isFetchingCompany ? (
              <Container className={"d-flex justify-content-center"}>
                <Spinner animation="border" variant="primary" />
              </Container>
            ) : (
              companyData.data.map((value: Company) => (
                <ChangesEntity
                  key={value.id}
                  link={`/${NAV_PATH.ADMIN}/${NAV_PATH.CHANGES}/${NAV_PATH.COMPANY}`}
                  data={value}
                />
              ))
            )}
          </ChangesAcceptedAccordion>
          <ChangesAcceptedAccordion
            nameLabel={"Akceptacja profili placówek firmowych"}
            type={"companyDepartment"}
            limit={INITIAL_SEARCH_PARAMS_DEPARTMENT.LIMIT}
            currPage={currPageCompanyDepartment}
            sortBy={sortByCompanyDepartment}
            handlePage={handlePage}
            changesSortHandler={changesSortHandler}
            totalResults={
              companyDepartmentsData.meta?.totalResults ?? INITIAL_TOTAL_RESULTS
            }
            sortedArray={[
              { type: "updatedAt", label: "Data aktualizacji" },
              { type: "changes", label: "Typ" },
            ]}
          >
            {isFetchingCompanyDepartment ? (
              <Container className={"d-flex justify-content-center"}>
                <Spinner animation="border" variant="primary" />
              </Container>
            ) : (
              companyDepartmentsData.data.map((value: CompanyDepartment) => (
                <ChangesEntity
                  key={value.id}
                  link={`/${NAV_PATH.ADMIN}/${NAV_PATH.CHANGES}/${NAV_PATH.DEPARTMENT}`}
                  data={value}
                />
              ))
            )}
          </ChangesAcceptedAccordion>
          <ChangesAcceptedAccordion
            nameLabel={"Akceptacja benefitów"}
            type={"services"}
            limit={INITIAL_SEARCH_PARAMS_SERVICE.LIMIT}
            currPage={currPageService}
            sortBy={sortByServices}
            handlePage={handlePage}
            changesSortHandler={changesSortHandler}
            totalResults={
              loyaltyProgramsServicesData.meta?.totalResults ??
              INITIAL_TOTAL_RESULTS
            }
            sortedArray={[
              { type: "updatedAt", label: "Data aktualizacji" },
              { type: "changes", label: "Typ" },
            ]}
          >
            {isFetchingLoyaltyProgramsServices ? (
              <Container className={"d-flex justify-content-center"}>
                <Spinner animation="border" variant="primary" />
              </Container>
            ) : (
              loyaltyProgramsServicesData.data.map(
                (value: LoyaltyProgramServiceData) => (
                  <ChangesEntity
                    key={value.id}
                    link={`/${NAV_PATH.ADMIN}/${NAV_PATH.CHANGES}/${NAV_PATH.SERVICE}`}
                    data={value}
                  />
                )
              )
            )}
          </ChangesAcceptedAccordion>
        </Stack>
      </section>
    </Container>
  );
};
