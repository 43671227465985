import { Pagination } from "react-bootstrap";
import { usePagination } from "../../../Helpers/usePagination";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";

import "./CustomPagination.scss";

interface paginationProps {
  onPageChange: any;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize?: number;
  paginationClassName?: string;
}

const CustomPagination = (props: paginationProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 8,
  } = props;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <Pagination className={props.paginationClassName ?? ""}>
        <Pagination.Prev onClick={onPrevious} disabled={currentPage === 1}>
          <Arrow style={{ transform: "rotate(180deg)" }} />
        </Pagination.Prev>
        {paginationRange.map((pageNumber: number | string, idx: number) => {
          if (pageNumber === "DOTS") {
            return (
              <Pagination.Item key={`${pageNumber} ${idx}`}>
                &#8230;
              </Pagination.Item>
            );
          }

          return (
            <Pagination.Item
              key={`${pageNumber} ${idx}`}
              active={pageNumber === currentPage}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </Pagination.Item>
          );
        })}

        <Pagination.Next onClick={onNext} disabled={currentPage === lastPage}>
          <Arrow />
        </Pagination.Next>
      </Pagination>
    </>
  );
};

export default CustomPagination;
