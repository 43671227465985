import { Dispatch, ReactNode, SetStateAction } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import style from "./NavigationLink.module.scss";

interface NavigationLinkProps {
  className?: string;
  children?: ReactNode;
  path: string;
  setShow?: Dispatch<SetStateAction<boolean>>;
  openInNewTab?: (url: string) => void;
  onClick?: () => void;
}

const NavigationLink = ({
  className,
  children,
  path,
  setShow,
  openInNewTab,
  onClick,
}: NavigationLinkProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const clickHandler = (event: React.MouseEvent) => {
    if (openInNewTab) {
      event.preventDefault();
      openInNewTab(path);
    }
    if (setShow) {
      setShow(false);
    }

    location.pathname === path && navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <NavLink
      className={`nav-link fs-16 fw-400 mb-2 lh-150 ${className} ${style.navLink}`}
      to={path}
      onClick={onClick || clickHandler}
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
