import React, { useState } from "react";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import { Button, Container, Form } from "react-bootstrap";
import { RejectModal } from "../RejectModal/RejectModal";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import styles from "../CompanyHeader/CompanyHeader.module.scss";
interface Props {
  changes: { [key: string]: any };
  status: number;
  deleted?: number;
  handleStatus?: () => void;
  handleDeleted?: () => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

export const ChangesInfo = (props: Props) => {
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);

  const handleStatus = () => {
    if (props.handleStatus) {
      props.handleStatus();
    }
    setShowStatusModal(false);
  };
  const handleDeleted = () => {
    if (props.handleDeleted) {
      props.handleDeleted();
    }
    setShowDeletedModal(false);
  };
  const handleComments = (comment: string) => {
    if (props.handleComments) {
      props.handleComments(comment);
    }
    setShowStatusModal(false);
  };

  return (
    <Container fluid={true} className="">
      <p
        className={
          "w-100 text-dark-blue fs-22 fw-semibold text-center m-0 mb-3"
        }
      >
        INFORMACJE
      </p>
      {props.isAdmin && (
        <div
          className={
            "d-flex justify-content-between flex-column flex-lg-row gap-2 my-3 text-dark-blue"
          }
        >
          <p className={"m-0"}>Ostatnia zmiana komentarza:</p>
          <p className="fw-semibold m-0">
            {dateEmbellishment(props.changes?.commentsDate, "-")}
          </p>
        </div>
      )}
      <hr
        className={`d-md-block d-lg-none mt-0 mb-3 my-1 p-0 ${styles.hrColor}`}
      />
      <div
        className={
          "d-flex justify-content-between flex-column flex-lg-row gap-2 mb-3 text-dark-blue"
        }
      >
        <p className={"m-0"}>Ostatnia zmiana przez użytkownika:</p>
        <p className="fw-semibold m-0">
          {dateEmbellishment(props.changes?.lastUserUpdateDate, "-")}
        </p>
      </div>
      <hr
        className={`d-md-block d-lg-none mt-0 mb-3 my-1 p-0 ${styles.hrColor}`}
      />
      <div
        className={
          "d-flex justify-content-between flex-column flex-lg-row gap-2 mb-3 text-dark-blue"
        }
      >
        <p className={"m-0"}>Treść komentarza:</p>
        <div
          className={
            "d-flex align-items-center justify-content-between justify-content-lg-start  gap-3 text-dark-blue"
          }
        >
          <p className="fw-semibold m-0">{props.changes?.comments || "-"}</p>
          {props.isAdmin && (
            <>
              <Button
                style={{ width: 38, height: 38 }}
                variant={"transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
                onClick={() => setShowReject((prev) => !prev)}
              >
                <Pencil />
              </Button>

              <RejectModal
                show={showReject}
                handleClose={() => setShowReject((prev) => !prev)}
                comments={props.changes?.comments || ""}
                handleComments={handleComments}
              />
            </>
          )}
        </div>
      </div>
      <hr className={`mt-0 mb-3 my-1 p-0 ${styles.hrColor}`} />
      <p className={" text-dark-blue fs-22 fw-semibold m-0"}>PROFIL</p>
      <div
        className={
          "d-flex justify-content-between flex-column flex-lg-row text-dark-blue pt-3"
        }
      >
        <p className={"m-0"}>Status:</p>
        {props.isAdmin ? (
          <>
            <div>
              <Form.Check
                className={""}
                type="switch"
                checked={!!props.status}
                id="active-switch"
                label={
                  <span className="fw-semibold cursor-pointer">
                    {props.status ? "aktywna" : "nie aktywna"}
                  </span>
                }
                onChange={() => setShowStatusModal((prev) => !prev)}
              />
            </div>
            <PopupModal
              text="Potwierdź zmianę statusu"
              type="confirm"
              show={showStatusModal}
              setShow={() => setShowStatusModal(false)}
              confirmAction={handleStatus}
            />
          </>
        ) : (
          <p className={"m-0"}>{props.status ? "aktywna" : "nie aktywna"}</p>
        )}
      </div>
      {typeof props.deleted !== "undefined" && (
        <div
          className={
            "d-flex justify-content-between flex-column flex-lg-row text-dark-blue pt-3"
          }
        >
          <p className={"m-0"}>Firma usunięta:</p>
          {props.isAdmin ? (
            <>
              <div>
                <Form.Check
                  className={""}
                  type="switch"
                  checked={!!props.deleted}
                  id="active-switch"
                  label={
                    <span className="fw-semibold cursor-pointer">
                      {props.deleted ? "usunięta" : "aktywna"}
                    </span>
                  }
                  onChange={() => setShowDeletedModal((prev) => !prev)}
                />
              </div>
              <PopupModal
                text="Potwierdź zmianę usunięcia firmy"
                type="confirm"
                show={showDeletedModal}
                setShow={() => setShowDeletedModal((prev) => !prev)}
                confirmAction={handleDeleted}
              />
            </>
          ) : (
            <p className={"m-0"}>{props.status ? "usunięta" : "aktywna"}</p>
          )}
        </div>
      )}
    </Container>
  );
};
