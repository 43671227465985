export const enums = {
  pagesStatusType: {
    0: "draft",
    1: "Publiczne",
    2: "Zabezpieczono hasłem",
    3: "Prywatne",
  },

  categoryType: {
    page: "strona",
    cancer: "nowotwór",
    event: "wydarzenie",
    profiling: "profilowanie"
  },

  cancerStatus: [
    {
      name: "aktywny",
      id: "1",
    },
    {
      name: "nieaktywny",
      id: "0",
    },
  ],
};
