import { Accordion, Card } from "react-bootstrap";
import React, { useState } from "react";
import styles from "./CompanyAccordion.module.scss";
import { CompanyInfo } from "../CompanyInfo/CompanyInfo";
import { AccordionToggle } from "../../atoms/AccordionToggle/AccordionToggle";
import { LoyaltyProgramServiceDepartment } from "../LoyaltyProgramServiceArticle/types";

interface Props {
  data: LoyaltyProgramServiceDepartment[];
  defaultActiveKey: string | null;
}

export const CompanyAccordion = (props: Props) => {
  const [isActive, setIsActive] = useState<boolean>(true);
  return (
    <Accordion
      defaultActiveKey={props.defaultActiveKey}
      className={styles.accordion}
    >
      <Card className={`${styles.accordionItem}`}>
        <Card.Header
          className={`${styles.accordionHeader}  px-0 py-3 m-0 coursor-pointer`}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <span className={"fw-bolder fs-19 text-dark-blue"}>
            Dostępne lokalizacje
          </span>
          <div className={"btn-square-alivia"}>
            <AccordionToggle
              eventKey="0"
              transparent={true}
              isActiveProps={isActive}
            />
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0" in={isActive}>
          <Card.Body className={`p-0 ${styles.accordionBody}`}>
            <div className={"p-0"}>
              {props.data.map(
                (
                  serviceDepartment: LoyaltyProgramServiceDepartment,
                  index: number
                ) => {
                  return <CompanyInfo key={index} data={serviceDepartment} />;
                }
              )}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
