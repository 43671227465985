import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import styles from "./CategoriesTemplate.module.scss";
import TextInput from "../../atoms/TextInput/TextInput";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import CategoryTable from "../../organisms/Tables/CategoryTable/CategoryTable";
import { Button } from "react-bootstrap";
import {
  useGetPageCategoryQuery,
  useGetPagesCategoryQuery,
  usePatchPagesCategoryMutation,
  usePostPagesCategoryMutation,
} from "../../../redux/services/pagesCategoryApi";
import { toast } from "react-toastify";
import { replacePolishLetters } from "Helpers/replacePolishLetters";
import { enums } from "Helpers/enums";
import TextArea from "components/atoms/TextArea/TextArea";
import { useTranslation } from "react-i18next";

const CategoriesTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1
  );

  const { data: pagesCategoryData, refetch } = useGetPagesCategoryQuery({
    offset: currPage ? ((currPage - 1) * 9).toString() : "0",
    limit: "9",
    titleLike: searchParams.get("titleLike"),
    categoryId: searchParams.get("category"),
    type: searchParams.get("type"),
    name: searchParams.get("name"),
    url: searchParams.get("url"),
    orderBy: searchParams.get("orderBy") || "title",
    sortOrder: searchParams.get("sortOrder") || "ASC",
  });

  const { data: pageCategory } = useGetPageCategoryQuery(
    searchParams.get("edit"),
    {
      skip: !searchParams.get("edit"),
    }
  );

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      title: "",
      content: "",
      url: "",
      status: 0,
      created_by: 2,
      searchPages: "",
      type: "",
    },
  });

  useEffect(() => {
    if (searchParams.get("edit")) {
      setValue("title", pageCategory?.data.title);
      setValue("content", pageCategory?.data.content);
      setValue("url", pageCategory?.data.url);
      setValue("type", pageCategory?.data.type);
      setValue("created_by", pageCategory?.data.created_by);
      setValue("status", pageCategory?.data.status);
    }
  }, [pageCategory, setValue, searchParams]);

  const [postPagesCategory, { isSuccess: postSuccess }] =
    usePostPagesCategoryMutation();

  const [patchPagesCategory, { isSuccess: patchSuccess }] =
    usePatchPagesCategoryMutation();

  useEffect(() => {
    if (postSuccess) {
      toast.success(t("toast_categoriesTemplate_add_success"));
    } else if (patchSuccess) {
      toast.success(t("toast_categoriesTemplate_update_success"));
    }
    // eslint-disable-next-line
  }, [postSuccess, patchSuccess]);

  useEffect(() => {
    if (isSubmitSuccessful || postSuccess) {
      searchParams.delete("edit");
      setSearchParams(searchParams);
      reset();
    }
  }, [isSubmitSuccessful, postSuccess, reset, searchParams, setSearchParams]);

  const handlePageChange = (e: number) => {
    searchParams.set("page", e.toString());
    setSearchParams(searchParams);
    setCurrPage(e);
  };

  const arrayOfObjects = Object.keys(enums.categoryType).map((key) => ({
    id: key,
    title: enums.categoryType[key as keyof typeof enums.categoryType],
  }));

  const handleSort = (orderBy: string, sortOrder: string) => {
    searchParams.set("orderBy", orderBy);
    searchParams.set("sortOrder", sortOrder);
    setSearchParams(searchParams);
    refetch();
  };

  return (
    <div className="mt-2 px-4 w-100">
      <h1 className="pe-3 fs-2 my-3">Kategorie</h1>

      <div className="row w-100 ">
        <form
          className={`col ${styles.categoriesForm}`}
          onSubmit={handleSubmit(async (data) => {
            const postData = {
              title: data.title,
              content: data.content,
              url: data.url,
              status: data.status,
              created_by: data.created_by,
              type: data.type,
            };

            if (searchParams.get("edit")) {
              patchPagesCategory({
                id: searchParams.get("edit"),
                data: postData,
              });
            } else postPagesCategory({ data: postData });
          })}
        >
          <p className="fw-bold">
            {searchParams.get("edit") ? "Zapisz zamiany" : "Utwórz kategorię"}
          </p>
          <ComponentWithDescription
            className="my-2"
            label="Nazwa"
            desc="Nazwa jak pojawia się w witrynie."
          >
            <TextInput
              register={register("title", {
                onChange: (e) => {
                  setValue("url", replacePolishLetters(e.target.value));
                },
              })}
            />
          </ComponentWithDescription>
          <ComponentWithDescription
            className="my-2"
            label="Uproszczona nazwa"
            desc="„Uproszczona nazwa” jest przyjazną dla adresu URL wersją nazwy. Zwykle składa się wyłącznie z małych liter, cyfr i myślników."
          >
            <TextInput
              style={{ width: "1500px" }}
              className="mb-1"
              placeholder=""
              register={register("url", {
                onChange: (e) => {
                  setValue("url", replacePolishLetters(e.target.value));
                },
              })}
            />
          </ComponentWithDescription>

          <ComponentWithDescription
            className="my-2"
            label="Typ kategorii"
            desc="Kategorie, w przeciwieństwie do tagów, mogą zostać uporządkowane w hierarchię. Możesz, na przykład, utworzyć kategorię „Jazz”, a jako jej podkategorie oznaczyć kategorie „Bebop” i „Big Band”. Jest to opcjonalne."
          >
            <SelectInput data={arrayOfObjects} register={register("type")} />
          </ComponentWithDescription>

          {/* <ComponentWithDescription
            className="my-2"
            label="Kategoria nadrzędna"
            desc="Kategorie, w przeciwieństwie do tagów, mogą zostać uporządkowane w hierarchię. Możesz, na przykład, utworzyć kategorię „Jazz”, a jako jej podkategorie oznaczyć kategorie „Bebop” i „Big Band”. Jest to opcjonalne."
          >
            <SelectInput
              defaultValue="— Kategoria nadrzędna —"
              data={allPagesCategoryData?.data}
              register={register("parent_id")}
            />
          </ComponentWithDescription> */}
          <ComponentWithDescription
            className="my-2"
            label="Opis"
            desc="Opis zwykle nie jest domyślnie eksponowany, jednak niektóre motywy mogą go wyświetlać."
          >
            <TextArea row={3} register={register("content")} />
          </ComponentWithDescription>
          <div className="col-auto">
            <Button type="submit" value="Submit">
              {searchParams.get("edit") ? "Zapisz zamiany" : "Utwórz kategorię"}
            </Button>
            {searchParams.get("edit") && (
              <Button
                className="btn-outline-blue ms-2"
                onClick={() => {
                  searchParams.delete("edit");
                  setSearchParams(searchParams);
                  reset();
                }}
              >
                Anuluj
              </Button>
            )}
          </div>
        </form>
        <div className="col  bd-highlight">
          <div className="d-flex justify-content-end mb-4">
            <form
              className="row g-3"
              onSubmit={handleSubmit((data) => {
                if (data.searchPages) {
                  searchParams.set("page", "1");
                  setCurrPage(1);
                }
                searchParams.set("titleLike", data.searchPages);
                setSearchParams(searchParams);
              })}
            >
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control"
                  {...register("searchPages")}
                />
              </div>
              <div className="col-auto">
                <Button
                  className="btn-outline-blue"
                  type="submit"
                  value="Submit"
                >
                  Szukaj kategorii
                </Button>
              </div>
            </form>
          </div>
          <CategoryTable
            data={pagesCategoryData?.data}
            handleSort={handleSort}
          />
          <CustomPagination
            totalCount={
              (!!pagesCategoryData && pagesCategoryData.meta.totalResults) || 0
            }
            pageSize={9}
            currentPage={currPage}
            onPageChange={(e: number) => handlePageChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesTemplate;
