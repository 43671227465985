import { cardCodes } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["CardCodes"],
});

interface GetCardCodesProps {
  view?: string;
  limit?: string;
  offset?: string;
}

interface UserCardCodes {
  deleted: number;
  status: number;
  role: string;
  id: number;
  name: string | null;
  firstName: string;
  lastName: string;
  email: string;
  authId: number | null;
  salesforceId: number | null;
  settings: string | null;
  enabled: number;
  dead: number;
  createdAt: string;
  updateAt: string;
}

export interface CardCodes {
  id: number;
  number: string;
  createdAt: string;
  userAt: string | null;
  user: UserCardCodes | null;
}

interface CardCodesResponse {
  data: CardCodes[];
  meta: Meta;
}

interface PostCardCodesProps {
  data: {
    many?: number;
    number?: string;
  };
}

interface PatchCardCodesAssignUserToCardProps {
  data: {
    authId: string;
  };
  cardCodeId: string;
}

export const cardCodesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCardCodes: builder.query<CardCodesResponse, GetCardCodesProps>({
      query: ({ view, offset, limit }) => {
        const params = new URLSearchParams({
          ...(view && { view }),
          ...(limit && { limit }),
          ...(offset && { offset }),
        });
        return {
          url: `${cardCodes}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["CardCodes"],
    }),
    postCardCodes: builder.mutation({
      query: ({ data }: PostCardCodesProps) => ({
        url: `${cardCodes}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CardCodes"],
    }),
    patchCardCodesAssignUserToCard: builder.mutation({
      query: ({ data, cardCodeId }: PatchCardCodesAssignUserToCardProps) => ({
        url: `${cardCodes}/${cardCodeId}/assign`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CardCodes"],
    }),
  }),
});

export const {
  useGetCardCodesQuery,
  usePostCardCodesMutation,
  usePatchCardCodesAssignUserToCardMutation,
} = cardCodesApi;
