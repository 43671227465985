import { useNavigate } from "react-router-dom";
import React from "react";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";

import { Button } from "react-bootstrap";

interface Props {
  link: string;
  data: DoctorHeaderData;
}

export const DoctorEntity = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`fs-14 p-2 px-4 d-flex flex-wrap justify-content-between alivia-radius border`}
      key={props.data.id}
    >
      <div
        className={`d-flex align-items-center text-wrap text-break mt-2 mt-lg-0 justify-content-center justify-content-lg-start text-grey fs-14 ms-lg-3 me-lg-auto mb-2 mb-lg-0`}
      >
        {props.data?.degree?.name} {props.data?.lastName}{" "}
        {props.data?.firstName}
      </div>
      <Button
        className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ms-auto btn-square-alivia`}
        variant={"transparent"}
        onClick={() => navigate(`${props.link}/${props.data.id}`)}
      >
        <ArrowUpRightFromSquare />
      </Button>
    </div>
  );
};
