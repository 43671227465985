import styles from "./LoyaltyProgramTemplate.module.scss";
import { Container, Row, Spinner, Stack } from "react-bootstrap";
import { ServicesForm } from "../../organisms/ServiceForm/ServicesForm";
import React, { useEffect, useState } from "react";
import { LatestServiceContent } from "../../organisms/LatestServiceContent/LatestServiceContent";
import { FaqContent } from "../../organisms/FaqContent/FaqContent";
import { Agreement } from "../../organisms/Agreement/Agreement";
import { useGetLoyaltyProgramsQuery } from "../../../redux/services/loyaltyProgramApi";
import { LoyaltyProgramContent } from "../../organisms/LoyaltyProgramContent/LoyaltyProgramContent";
import SectionTitle from "../../atoms/SectionTitle/SectionTitle";
import { useGetSystemSettingsQuery } from "../../../redux/services/systemSettingsApi";
import { ImageIds } from "../../../Helpers/convertImageIdToUrl";

export interface LoyaltyProgramDataInterface {
  id: number;
  name: string;
  description: string;
  image: string;
  status: number;
  otherCodes: number;
  countAllCode: number;
  countAllService: number;
  imageIds?: ImageIds[];
}

const LoyaltyProgramTemplate = () => {
  const [pageId, setPageId] = useState<string>("1346206625");
  const { data: systemSettings, isLoading: systemSettingsIsLoading } =
    useGetSystemSettingsQuery({});
  const { data: loyaltyProgramsData, isLoading } = useGetLoyaltyProgramsQuery(
    {},
  );

  useEffect(() => {
    if (systemSettings) {
      const faqSystemItem = systemSettings?.data?.find(
        (item: { context: string; value: string }) =>
          item.context === "loyalty",
      );
      setPageId(faqSystemItem ? faqSystemItem.value : "1346206625");
    }
  }, [systemSettings]);

  if (isLoading || systemSettingsIsLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true} className={`bg-light`}>
      <section className={styles.section}>
        <Stack gap={5}>
          <Agreement />
          <Row>
            <SectionTitle className={`text-dark-blue ${styles.title}`}>
              Korzystaj z benefitów
            </SectionTitle>
          </Row>
          <Row className={`${styles.wrapper}`}>
            <ServicesForm nameLabel={"Szukaj benefitów"} isService={false} />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <LatestServiceContent name={"Najnowsze benefity"} latest={true} />
          </Row>
          {loyaltyProgramsData?.data?.map(
            (value: LoyaltyProgramDataInterface, index: number) => (
              <Row key={index} className={`${styles.wrapper}`}>
                <LoyaltyProgramContent data={value} />
              </Row>
            ),
          )}
          <Row className={`${styles.wrapper}`}>
            <FaqContent pageId={pageId} />
          </Row>
        </Stack>
      </section>
    </Container>
  );
};

export default LoyaltyProgramTemplate;
