import { RSS_TYPES, STATUSES } from "Helpers/articlesEnumData";
import { capitalizeString } from "Helpers/capitalizeString";
import React from "react";
import { Button, Collapse, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEditArticleMutation } from "redux/services/articlesApi";
import { useTranslation } from "react-i18next";

interface QuickEditArticleProps {
  open: boolean;
  data: ArticleRss;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuickEditArticle = ({ data, open, setOpen }: QuickEditArticleProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data.name,
      slug: data.slug,
      url: data.url,
      type: data.type,
      categoryNames: data.categoryNames,
      countryId: data.countryId,
      status: 1,
    },
  });

  const { t } = useTranslation();

  const [editArticleRssData] = useEditArticleMutation();

  const submitHandler: SubmitHandler<ArticleFormData> = async (submitData) => {
    const payload = {
      id: data.id,
      data: {
        ...submitData,
        countryId: +submitData.countryId,
        status: +submitData.status,
      },
    };

    const hasBeenEdited = await editArticleRssData(payload);

    if ("data" in hasBeenEdited) {
      toast.success(t("toast_quickEditArticle_success"));
    } else if ("error" in hasBeenEdited) {
      const error = hasBeenEdited?.error as TranslationSubmitError;

      switch (error.data.statusCode) {
        case 500:
          toast.error(t("toast_quickEditArticle_error_500"));
          break;
        case 400:
          toast.error(t("toast_quickEditArticle_error_400"));
          break;
        default:
          toast.error(t("toast_quickEditArticle_error"));
          break;
      }
    }
  };

  const formGroupStyles = "mb-2";
  const labelStyles = "mb-1 fs-12 lh-base";
  const inputStyles = "fs-14";
  const formCheckStyles = "mb-0";

  return (
    <Collapse in={open}>
      <>
        <span>Szybka akcja</span>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="d-flex w-100 gap-5">
            <div className="w-100 d-flex flex-column gap-2">
              <Form.Group className={formGroupStyles}>
                <Form.Label className={labelStyles} htmlFor="articleRssName">
                  Nazwa
                </Form.Label>
                <Form.Control
                  className={inputStyles}
                  id="articleRssName"
                  {...register("name", {
                    required: "Pole nie może być puste.",
                    pattern: {
                      value: /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/i,
                      message: "Nazwa nie może zawierać znaków specjalnych.",
                    },
                  })}
                  type="text"
                  isInvalid={Boolean(errors.name)}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className={formGroupStyles}>
                <Form.Label className={labelStyles} htmlFor="articleRssUrl">
                  Adres URL
                </Form.Label>
                <Form.Control
                  className={inputStyles}
                  id="articleRssUrl"
                  {...register("url", {
                    required: "Pole nie może być puste.",
                    pattern: {
                      value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                      message: "Wprowadź poprawny adres URL.",
                    },
                  })}
                  type="text"
                  isInvalid={Boolean(errors.url)}
                />
                {errors.url && (
                  <Form.Control.Feedback type="invalid">
                    {errors.url.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="w-100 d-flex flex-column gap-2 justify-content-center">
              <Form.Group className={formGroupStyles}>
                <div className={labelStyles}>Typ kanału RSS</div>
                {RSS_TYPES.map((t, idx) => (
                  <Form.Check
                    key={idx}
                    className={formCheckStyles}
                    inline
                    id={`article${capitalizeString(t.name)}Type`}
                    {...register("type")}
                    type="radio"
                    label={t.label}
                    value={t.value}
                  />
                ))}
              </Form.Group>
              <Form.Group className={formGroupStyles}>
                <div className={labelStyles}>Status</div>
                {STATUSES.map((s, idx) => (
                  <Form.Check
                    key={idx}
                    className={formCheckStyles}
                    inline
                    id={`article${capitalizeString(s.name)}Status`}
                    {...register("status")}
                    type="radio"
                    label={s.label}
                    value={s.value}
                    checked={s.checked}
                  />
                ))}
              </Form.Group>
            </div>
          </div>
          <div className="d-flex gap-2 mt-4">
            <Button type="submit" value="Submit">
              Aktualizuj
            </Button>
            <Button className="btn-outline-blue" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
          </div>
        </form>
      </>
    </Collapse>
  );
};

export default QuickEditArticle;
