import PollSelection from "../PollSelection/PollSelection";
import DynamicPatientPoll from "../DynamicPatientPoll/DynamicPatientPoll";
import { useState } from "react";
import { Container } from "react-bootstrap";
import styles from "./PollDisplaySection.module.scss";

function PollDisplaySection() {
  const [selectedPollId, setSelectedPollId] = useState<number | null>(null);

  return (
    <>
      <Container className={`p-4 w-100 m-0 mb-4 ${styles.pollDisplay}`}>
        <PollSelection onSelect={setSelectedPollId} />
      </Container>
      {selectedPollId && (
        <DynamicPatientPoll key={selectedPollId} pollId={selectedPollId} />
      )}
    </>
  );
}

export default PollDisplaySection;
