import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { stripHtml } from "./stripHtml";

interface ArticleData {
  id: string;
  articleSrc?: string;
  categories?: string;
  description: string;
  imgSrc?: string;
  link: string;
  title: string;
}

interface Params {
  topicId?: string;
  cancerId?: string;
  perPage?: string;
}

const getImgSrc = (html: string) => {
  const regex = /src="([^"]+)"/;
  const result = html.match(regex);
  return result !== null ? result[1] : "";
};

export const fetchArticlesData = async (
  articleRss: ArticleRss,
  { topicId, cancerId, perPage }: Params
): Promise<ArticleData[]> => {
  const url = new URL(articleRss?.url);
  const params = new URLSearchParams();

  if (topicId) params.append("topic", topicId);
  if (cancerId) params.append("cancer_type", cancerId);
  if (perPage) params.append("per_page", perPage);

  url.search = params.toString();

  const response = await axios.get(url?.toString(), {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  });

  if (articleRss?.type === "xml" || articleRss?.type === "rss-atom") {
    const parser = new DOMParser();
    const xmlDoc = await parser.parseFromString(response?.data, "text/xml");
    const itemsXml = xmlDoc.getElementsByTagName("item");

    return Array.from(itemsXml).map((item) => ({
      id: uuidv4(),
      articleSrc: articleRss?.name,
      categories:
        item?.getElementsByTagName("category")?.[0]?.textContent ?? "",
      description:
        stripHtml(
          item?.getElementsByTagName("description")?.[0]?.textContent ?? ""
        ) || "",
      imgSrc: getImgSrc(
        item?.getElementsByTagName("content:encoded")?.[0]?.textContent ?? ""
      ),
      link: item?.getElementsByTagName("link")?.[0]?.textContent ?? "",
      title: item?.getElementsByTagName("title")?.[0]?.textContent ?? "",
    }));
  } else if (articleRss?.type === "wp-json") {
    return response?.data?.map((article: any) => ({
      id: article?.id?.toString() ?? uuidv4(),
      articleSrc: articleRss?.name,
      description: stripHtml(article?.content?.rendered) ?? "",
      imgSrc: article?.yoast_head_json?.og_image?.[0]?.url ?? "",
      link: article?.link ?? "",
      title: article?.title?.rendered ?? "",
    }));
  } else {
    return [];
  }
};
