import { differenceInMinutes, format, parseISO } from "date-fns";

export const handleDateChange = (dates: any) => {
  const offset = dates.getTimezoneOffset();
  return new Date(dates.getTime() - offset * 60 * 1000);
};

export const today = handleDateChange(new Date());

export const todayIsoString = today.toISOString().split("T")[0];

export const todayTimeIsoString = today
  .toISOString()
  .split("T")
  .join(" ")
  .slice(0, 16);

export const dateEmbellishment = (
  date: string | null,
  isDateUndefined: string
): string | boolean => {
  if (date) {
    return format(new Date(date), "dd.MM.yyyy HH:mm");
  }
  return isDateUndefined;
};

export const findLastDate = (date: string[]) => {
  const test = date
    .filter((element) => {
      // eslint-disable-next-line
      return typeof element !== undefined;
    })
    .filter((element) => typeof element !== "boolean")
    .filter((n) => n);
  if (test.length === 0) {
    return "-";
  }

  if (test.length === 1) {
    return format(new Date(test[0]), "dd.MM.yyyy HH:mm");
  }
  return format(
    new Date(
      test
        .filter(String)
        .reduce((latest: string, actual: string): string =>
          new Date(actual) > new Date(latest) ? actual : latest
        )
    ),
    "dd.MM.yyyy HH:mm"
  );
};

export const isWithinMinutes = (dateString: string, minutes: number) => {
  const dateToCheck = parseISO(dateString);
  const currentDate = new Date();
  const difference = differenceInMinutes(currentDate, dateToCheck);
  return difference >= 0 && difference <= minutes;
};
