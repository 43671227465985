import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useGetPagesQuery,
  useLazyGetPagesQuery,
  usePatchPageUpdateMutation,
  usePostCreatePageMutation,
} from "../../../redux/services/pagesApi";
import TextInput from "../../atoms/TextInput/TextInput";
import TinyEditor from "../../organisms/TinyEditor/TinyEditor";
import CollapseWithIcon from "../../organisms/CollapseWithIcon/CollapseWithIcon";
import {
  useGetPagesCategoryQuery,
  useLazyGetPagesCategoryQuery,
} from "../../../redux/services/pagesCategoryApi";
import CategoryCollapse from "../../molecules/CollapseComponents/CategoryCollapse/CategoryCollapse";
import { enums } from "../../../Helpers/enums";
import RadioInput from "../../atoms/RadioInput/RadioInput";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { useEffect, useState } from "react";
import DateTimePicker from "../../atoms/DateTimePicker/DateTimePicker";
import { todayTimeIsoString } from "../../../Helpers/dateHelpers";
import { useNavigate } from "react-router-dom";
import {
  useGetSubstanceQuery,
  useLazyGetSubstanceQuery,
  useGetActiveSubstanceShortQuery,
  useLazyGetActiveSubstanceShortQuery,
} from "redux/services/substanceApi";
import { replacePolishLetters } from "Helpers/replacePolishLetters";
import CheckboxInput from "components/atoms/CheckboxInput/CheckboxInput";
import SingleSelect from "components/molecules/SingleSelect/SingleSelect";
import { useGetCountriesQuery } from "redux/services/countriesApi";
import {
  useGetCancersQuery,
  useLazyGetCancersQuery,
} from "redux/services/cancerApi";
import TextArea from "components/atoms/TextArea/TextArea";
import AsyncMultiSelect from "components/molecules/AsyncMultiSelect/AsyncMultiSelect";
import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
import {
  handleActiveSubstance,
  handleActiveSubstanceOffline,
} from "Helpers/helpers";
import { toast } from "react-toastify";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import MediaPicker from "components/atoms/MediaPicker/MediaPicker";
import { useTranslation } from "react-i18next";
import { sortSubstanceByName } from "Helpers/sortSubstaceByName";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { Loading } from "../../atoms/Loading/Loading";

interface NewPageResult {
  data: data;
  meta: { status: string };
}
interface data {
  data: PageDataGet;
}

const PageNewTemplate = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parentIds: [""],
      title: "",
      url: "",
      metaTitle: "",
      metaDescription: "",
      content: "",
      slug: "",
      lead: "",
      password: "",
      categoryIds: [""],
      sortOrder: 0,
      type: "0",
      createdBy: 2,
      substanceIds: [],
      substanceOfflineIds: [],
      date: todayTimeIsoString,
      addActiveSubstance: false,
      activeSubstance: "",
      country: 1,
      cancerIds: [],
      language: 1,
      attachments: [""],
    },
  });
  const setAttachment = (id: string) => {
    setValue("attachments", [id]);
  };

  const [urlParam, setUrlParam] = useState("");

  const [handleReset, setHandleReset] = useState(false);
  const [dataSend, setDataSend] = useState<PageDataGet>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [patchPageUpdate, { isSuccess: patchIsSuccess, isError }] =
    usePatchPageUpdateMutation();

  const { t } = useTranslation();

  const [postCreatePage, { isSuccess: postSuccess }] =
    usePostCreatePageMutation();

  const { data: pagesData } = useGetPagesQuery({ limit: "0" });

  const { data: pagesCategoryData } = useGetPagesCategoryQuery({
    type: "page",
  });

  const { data: countriesData } = useGetCountriesQuery({});
  const availableCountriesData = countriesData?.data?.filter(
    (c: any) => c.active,
  );

  const { data: cancersData } = useGetCancersQuery({ status: "1" });

  const { data: activeSubstanceData } = useGetActiveSubstanceShortQuery(
    {
      cancerId: watch("cancerIds"),
      countryId: watch("country"),
    },
    { skip: !watch("cancerIds") || !watch("country") },
  );
  const { data: substanceData } = useGetSubstanceQuery({ limit: "0" });

  const sortSubstanceData = sortSubstanceByName(substanceData);

  const pageStatus = [
    { name: "draft", value: "0", description: "" },
    { name: "Publiczne", value: "1", description: "Widoczne dla wszystkich." },
    {
      name: "Prywatne",
      value: "3",
      description: "Widoczne tylko dla administratorów i redaktorów witryny.",
    },
    {
      name: "Zabezpieczono hasłem",
      value: "2",
      description: "Tylko osoby znające hasło mogą zobaczyć ten wpis.",
    },
  ];

  useEffect(() => {
    if (dataSend && postSuccess) {
      const newDataSend = {
        ...dataSend,
        language: dataSend.language.id,
        attachments: getValues("attachments"),
      };
      patchPageUpdate({ id: dataSend.id, data: newDataSend });
    }
    if (isError) {
      toast.error(t("toast_pageTemplate_add_image_error"));
    }
    // eslint-disable-next-line
  }, [isError, dataSend, patchPageUpdate, postSuccess, getValues]);

  useEffect(() => {
    if (postSuccess && dataSend) {
      return navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}`);
    }
  }, [navigate, postSuccess, dataSend]);

  useEffect(() => {
    if (patchIsSuccess) {
      return navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}`);
    }
  }, [navigate, patchIsSuccess]);

  return (
    <div className="mt-2 mx-4 m-0 d-flex w-100 gap-3 ">
      <div className="w-100">
        <h1 className="pe-3 fs-3 mb-4">Dodaj stronę</h1>
        <TextInput
          className="mb-4 flex-column w-100"
          placeholder="Dodaj tytuł"
          register={register("title")}
        />
        <TinyEditor setValue={setValue} name="content" image targetBlank />

        <ComponentWithDescription
          className="my-2"
          labelFs="fs-16"
          label="Zajawka"
        >
          <TinyEditor setValue={setValue} name="lead" targetBlank />
        </ComponentWithDescription>
        <h3 className="mt-5">Ustawienia seo</h3>
        <TextInput
          style={{ width: "1500px" }}
          className="mb-4 flex-column "
          placeholder="Dodaj tytuł"
          register={register("metaTitle", {
            maxLength: {
              value: 250,
              message: "Za dużo znaków [limit 250]",
            },
          })}
          label="Meta title"
          error={errors.metaTitle?.message}
          limitSeo={0}
        />

        <TextArea
          row={3}
          register={register("metaDescription", {
            maxLength: {
              value: 250,
              message: "Za dużo znaków [limit 250]",
            },
          })}
          error={errors.metaDescription?.message}
          label="Meta description"
          limitSeo={0}
        />
      </div>
      <div className="d-flex flex-column gap-3 w-auto">
        <div
          className="accordion"
          id="accordionExample"
          style={{ width: "18rem" }}
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-expanded="false"
                aria-controls="collapse1"
              >
                Podsumowanie
              </button>
            </h2>
            <div
              id="collapse1"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body ">
                <CollapseWithIcon
                  title="Widoczność"
                  label={
                    enums.pagesStatusType[
                      Number(
                        watch("type"),
                      ) as keyof typeof enums.pagesStatusType
                    ]
                  }
                  CollapseComponent={() => (
                    <>
                      <span className="text-muted">
                        Kontroluj sposób wyświetlania wpisu.
                      </span>
                      {pageStatus.map((status) => {
                        return (
                          <RadioInput
                            key={status.value}
                            register={register("type", {
                              required: true,
                            })}
                            label={status.name}
                            value={status.value}
                            checked={watch("type")?.toString() === status.value}
                            description={status.description}
                          />
                        );
                      })}

                      {watch("type") === "2" && (
                        <TextInput
                          style={{ width: "1500px" }}
                          className="mb-4"
                          placeholder="Wprowadź bezpieczne hasło"
                          register={register("password", {
                            required: watch("type") === "2",
                          })}
                        />
                      )}
                    </>
                  )}
                />
                <CollapseWithIcon
                  title="Opublikuj"
                  label={watch("date").split("T").join(" ")}
                  CollapseComponent={() => (
                    <div className="d-flex gap-3 align-items-center">
                      <DateTimePicker
                        setValue={setValue}
                        name="date"
                        value={watch("date")}
                      />
                    </div>
                  )}
                />
                <CollapseWithIcon
                  title="Adres URL"
                  label={urlParam || "Dodaj url"}
                  CollapseComponent={() => (
                    <>
                      <TextInput
                        style={{ width: "1500px" }}
                        className="mb-1"
                        placeholder=""
                        register={register("url", {
                          onChange: (e) => {
                            setValue(
                              "url",
                              replacePolishLetters(e.target.value),
                            );
                          },
                        })}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => setUrlParam(getValues("url"))}
                      >
                        Utwórz url
                      </Button>
                    </>
                  )}
                />
                <ComponentWithDescription className="my-2" label="Autor">
                  <TextInput
                    disabled
                    register={register("createdBy", {
                      required: true,
                    })}
                  />
                </ComponentWithDescription>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
              >
                Strona nadrzędna
              </button>
            </h2>
            <div
              id="collapse2"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-1">
                <>
                  {
                    <AsyncMultiSelect
                      data={pagesData}
                      setValue={setValue}
                      name="parentIds"
                      relativeoptions
                      menuIsOpen
                      asyncQuery={useLazyGetPagesQuery}
                    />
                  }
                </>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-expanded="false"
                aria-controls="collapse5"
              >
                Wybierz kraj
              </button>
            </h2>
            <div
              id="collapse5"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-1">
                <SingleSelect
                  value={[
                    {
                      label: "Polska [polski]",
                      value: 1,
                      languageId: 1,
                    },
                  ]}
                  data={availableCountriesData}
                  menuIsOpen
                  setValue={setValue}
                  name="country"
                  relativeoptions
                  isNotClearable
                  setHandleReset={setHandleReset}
                  language={"language"}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                Kategorie
              </button>
            </h2>
            <div
              id="collapse3"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-1">
                {
                  <AsyncMultiSelect
                    data={pagesCategoryData}
                    setValue={setValue}
                    name="categoryIds"
                    relativeoptions
                    menuIsOpen
                    asyncQuery={useLazyGetPagesCategoryQuery}
                    type="page"
                  />
                }
                <CollapseWithIcon
                  label="Utwórz kategorię"
                  CollapseComponent={CategoryCollapse}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
              >
                Obrazek wyróżniający
              </button>
            </h2>
            <div
              id="collapse7"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-3">
                <MediaPicker setAttachment={setAttachment} />
              </div>
            </div>
          </div>
        </div>

        <CheckboxInput
          register={register("addActiveSubstance")}
          label="Dodaj substancje aktywne"
        />

        {!!watch("addActiveSubstance") && (
          <div
            className="accordion"
            id="accordionExample2"
            style={{ width: "18rem" }}
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse4"
                >
                  Nowotwór
                </button>
              </h2>
              <div
                id="collapse4"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-1">
                  <AsyncSelectLoading
                    data={cancersData}
                    setValue={setValue}
                    name="cancerIds"
                    menuIsOpen
                    relativeoptions
                    asyncQuery={useLazyGetCancersQuery}
                    setHandleReset={setHandleReset}
                  />
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  aria-expanded="false"
                  aria-controls="collapse6"
                  disabled={!watch("cancerIds") || !watch("country")}
                  style={{
                    background:
                      !watch("cancerIds") || !watch("country") ? "#cccccc" : "",
                  }}
                >
                  Substancje Onkoindex
                </button>
              </h2>
              <div
                id="collapse6"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-1">
                  <AsyncMultiSelect
                    data={activeSubstanceData}
                    setValue={setValue}
                    name="substanceIds"
                    relativeoptions
                    menuIsOpen
                    asyncQuery={useLazyGetActiveSubstanceShortQuery}
                    watch={watch("substanceIds")}
                    reset={handleReset}
                    setHandleReset={setHandleReset}
                    handleField={handleActiveSubstance}
                  />
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                  disabled={!watch("cancerIds") || !watch("country")}
                  style={{
                    background:
                      !watch("cancerIds") || !watch("country") ? "#cccccc" : "",
                  }}
                >
                  Substancje Offline
                </button>
              </h2>
              <div
                id="collapse7"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-3">
                  <AsyncMultiSelect
                    data={sortSubstanceData}
                    setValue={setValue}
                    name="substanceOfflineIds"
                    relativeoptions
                    menuIsOpen
                    asyncQuery={useLazyGetSubstanceQuery}
                    watch={watch("substanceOfflineIds")}
                    reset={handleReset}
                    setHandleReset={setHandleReset}
                    handleField={handleActiveSubstanceOffline}
                  />
                </div>
              </div>
            </div>

            {watch("cancerIds") &&
              watch("country") &&
              !watch("substanceIds").length && (
                <p className="text-danger fs-13">
                  Możesz dodać aktywne substancje
                </p>
              )}
          </div>
        )}
        <div className="card-footer text-muted">
          <Button
            onClick={handleSubmit(async (data) => {
              if (!isSubmitLoading) {
                setIsSubmitLoading(true);
                let cancerIdsArray: string[] = [];
                if (data?.cancerIds) {
                  if (typeof data?.cancerIds === "number") {
                    cancerIdsArray.push(data?.cancerIds);
                  } else {
                    cancerIdsArray.push(...data?.cancerIds);
                  }
                }
                const postData = {
                  parentIds: data.parentIds,
                  title: data.title,
                  url: data.url,
                  metaTitle: data.metaTitle,
                  metaDescription: data.metaDescription,
                  content: data.content,
                  slug: data.slug,
                  lead: data.lead,
                  password: data.password,
                  categoryIds: data.categoryIds,
                  sortOrder: data.sortOrder,
                  type: Number(data.type),
                  createdBy: data.createdBy,
                  cancerIds: data.addActiveSubstance
                    ? !!data.cancerIds
                      ? cancerIdsArray
                      : []
                    : [],
                  country: data.addActiveSubstance ? data.country : null,
                  substanceIds: data.addActiveSubstance
                    ? data.substanceIds
                    : [],
                  substanceOfflineIds: data.addActiveSubstance
                    ? data.substanceOfflineIds
                    : [],
                  language: data.language,
                };

                const createPage = (await postCreatePage({
                  data: postData,
                }).then(
                  (
                    response:
                      | { data: PageDataTypes }
                      | { error: FetchBaseQueryError | SerializedError },
                  ) => {
                    return response;
                  },
                )) as NewPageResult;
                setDataSend(createPage.data.data);
                if ("data" in createPage) {
                  toast.success(t("toast_pageTemplate_add_success"));
                } else if ("error" in createPage) {
                  toast.error(t("toast_pageTemplate_add_error"));
                }
              }
              setIsSubmitLoading(false);
            })}
          >
            {isSubmitLoading ? <Loading /> : "Utwórz"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNewTemplate;
