import "./InformationWithLinks.css";

const InformationWithLinks = ({
  content,
  facebookLink,
  twitterLink,
  linkedinLink,
  mailLink,
  instagramLink,
  youtubeLink,
}: InformationWithLinksProps) => {
  return (
    <div className="infWrapper">
      <div className="infContent">{content}</div>
      <div className="infLinks">
        {facebookLink && (
          <a
            href={facebookLink}
            rel="noreferrer"
            target="_blank"
            style={{ paddingRight: "8px" }}
          >
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/09/2e07c9a0-a891-11ed-9101-7d4ed09b8a0a.png"
              alt=""
            />
          </a>
        )}
        {twitterLink && (
          <a
            href={twitterLink}
            rel="noreferrer"
            target="_blank"
            style={{ paddingRight: "8px" }}
          >
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/09/33d9a7e0-a891-11ed-9101-7d4ed09b8a0a.png"
              alt=""
            />
          </a>
        )}
        {linkedinLink && (
          <a
            href={linkedinLink}
            rel="noreferrer"
            target="_blank"
            style={{ paddingRight: "8px" }}
          >
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/09/35158340-a891-11ed-9101-7d4ed09b8a0a.png"
              alt=""
            />
          </a>
        )}
        {mailLink && (
          <a
            href={mailLink}
            rel="noreferrer"
            target="_blank"
            style={{ paddingRight: "8px" }}
          >
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/09/365dbab0-a891-11ed-9101-7d4ed09b8a0a.png"
              alt=""
            />
          </a>
        )}
        {instagramLink && (
          <a
            href={instagramLink}
            rel="noreferrer"
            target="_blank"
            style={{ paddingRight: "8px" }}
          >
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/28/02949060-b754-11ed-81ea-21076e8c64b2.png"
              alt=""
            />
          </a>
        )}
        {youtubeLink && (
          <a href={youtubeLink} rel="noreferrer" target="_blank">
            <img
              src="https://static.dev.api.alivia.org.pl/2023/02/28/017ae300-b754-11ed-81ea-21076e8c64b2.png"
              alt=""
            />
          </a>
        )}
      </div>
    </div>
  );
};
export default InformationWithLinks;
