import { ReactNode } from "react";

interface ResultCounterProps {
  icon?: ReactNode;
  resultCount: number;
  label: string;
  className?: string;
}

export const ResultCounter = ({
  icon,
  resultCount,
  label,
  className,
}: ResultCounterProps) => (
  <div className={`d-flex align-items-center px-0 ${className}`}>
    {icon}
    <span
      className={`fs-14 fw-600 lh-130 ls-3 text-light-emphasis me-1 ${
        icon && "ms-2"
      }`}
    >
      {resultCount}
    </span>
    <span className="fs-14 lh-130 fw-400 text-light-emphasis">{label}</span>
  </div>
);
