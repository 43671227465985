import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AccordionToggle } from "../../atoms/AccordionToggle/AccordionToggle";
import styles from "./AccordionCard.module.scss";

interface Props {
  title: string;
  content?: string;
  child?: React.ReactNode;
  classAccordion?: string;
  classAccordionHeader?: string;
  classAccordionItem?: string;
  classAccordionBody?: string;
  classTitle?: string;
  isActiveStars?: boolean;
}

export const AccordionCard = (props: Props) => {
  const [isActive, setIsActive] = useState<boolean>(!!props.isActiveStars);

  return (
    <Accordion
      className={`mb-4 ${styles.accordion} ${props.classAccordion || ""}`}
      alwaysOpen
    >
      <Card
        className={`${styles.accordionItem} ${props.classAccordionItem || ""}`}
      >
        <Card.Header
          className={`${styles.accordionHeader} gap-4 ${
            props.classAccordionHeader || ""
          }`}
          onClick={() => setIsActive((prevState) => !prevState)}
        >
          <span
            className={`fw-600 fs-16 ls-3 text-dark-blue ${
              props.classTitle || ""
            }`}
          >
            {props.title}
          </span>
          <div style={{ width: 38 }}>
            <AccordionToggle eventKey="0" isActiveProps={isActive} />
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0" in={isActive}>
          <Card.Body className={`${props.classAccordionBody || ""}`}>
            {props.content && (
              <div
                className={`fs-16 text-dark-blue`}
                dangerouslySetInnerHTML={{ __html: props.content }}
              />
            )}

            {props.child && (
              <div className={"fs-16 text-dark-blue"}>{props.child}</div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
