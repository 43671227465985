import { Suspense, ReactNode, useEffect } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useGetLanguagesQuery } from "redux/services/languagesApi";
import { INITIAL_SEARCH_PARAMS } from "../../pages/TranslationsLanguagePage/TranslationsLanguagePage";
import { useForm } from "react-hook-form";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  className?: string;
  children?: ReactNode;
}

const TranslationCard: React.FC<Props> = ({ className, children }) => {
  const navigate = useNavigate();
  const params = useParams<{ languageId?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: languagesData } = useGetLanguagesQuery({
    offset: 0,
    limit: null,
  });

  const { register, watch } = useForm({
    defaultValues: {
      searchValue: searchParams?.get("filter") ?? "",
      searchMode:
        searchParams?.get("filterMode") &&
        searchParams?.get("filterMode") === "value",
      languageFilter: params?.languageId || "1",
    },
  });

  const changeLanguage = (languageId: string) => {
    const queryParams: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      return (queryParams[key] = value);
    });

    const urlSearchParams = new URLSearchParams({
      ...queryParams,
      page: INITIAL_SEARCH_PARAMS.page.toString(),
    });

    navigate(
      `/${NAV_PATH.ADMIN}/${
        NAV_PATH.TRANSLATIONS
      }/${languageId}?${urlSearchParams.toString()}`
    );
  };

  useEffect(() => {
    changeLanguage(watch("languageFilter"));
    // eslint-disable-next-line
  }, [watch("languageFilter")]);

  useEffect(() => {
    const queryParams: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      return (queryParams[key] = value);
    });

    setSearchParams({
      ...queryParams,
      filter: watch("searchValue"),
      filterMode: watch("searchMode") ? "value" : "key",
      page: INITIAL_SEARCH_PARAMS.page.toString(),
      limit:
        searchParams.get("limit") || INITIAL_SEARCH_PARAMS.limit.toString(),
    });
    // eslint-disable-next-line
  }, [watch("searchValue"), watch("searchMode")]);

  return (
    <Card className={className}>
      <Card.Header>
        <div className="container-fluid">
          <Row className="d-lg-flex justify-content-between align-items-center mb-lg-2">
            <Col className="col-12 col-lg-6 mb-2 mb-lg-0">
              <Form.Control
                id="searchTranslation"
                {...register("searchValue")}
                value={watch("searchValue")}
                placeholder="Szukaj"
              />
            </Col>
            <Col className="col-12 col-lg-6 col-xxl-4 mb-2 mb-lg-0">
              <label
                className="d-flex w-100 justify-content-end py-1"
                htmlFor="searchMode"
              >
                <span className="me-2">Klucz</span>
                <Form.Check
                  className="mb-0"
                  type="switch"
                  id="searchMode"
                  {...register("searchMode")}
                />
                <span>Wartość</span>
              </label>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col className="col-12 col-lg-6">
              <div className="container-fluid d-flex justify-content-end align-items-center p-0">
                <label
                  className="col me-3 text-lg-end"
                  htmlFor="languageFilter"
                >
                  Język
                </label>
                <Col className="col-9">
                  <Form.Select
                    id="languageFilter"
                    {...register("languageFilter")}
                    value={watch("languageFilter")}
                  >
                    {languagesData?.data?.map((language: LanguageData) => (
                      <option key={language.id} value={language.id}>
                        {language.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Header>
      <Card.Body>
        <Suspense fallback={<Spinner animation="border" variant="primary" />}>
          <Outlet />
        </Suspense>
      </Card.Body>
    </Card>
  );
};

export default TranslationCard;
