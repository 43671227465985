import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import { Button, Form, Row } from "react-bootstrap";
import styles from "./CompanyForm.module.scss";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  usePatchCompanyMutation,
  usePostCompanyMutation,
} from "../../../redux/services/companyApi";
import { useNavigate } from "react-router-dom";
import { Company } from "../LoyaltyProgramServiceArticle/types";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: Company | null;
  handleEdit?: () => void;
  handleClose?: () => void;
  handleNextStep?: () => void;
  steps?: { step: number; maxStep: number } | false;
}

export const CompanyForm = (props: Props) => {
  const navigate = useNavigate();

  const [postCompanyMutation] = usePostCompanyMutation();

  const [patchCompanyMutation] = usePatchCompanyMutation();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CompanyForm>({
    defaultValues: {
      name: props.data?.name || "",
      fullName: props.data?.fullName || "",
      nipProvider: props.data?.nipProvider || "",
    },
  });

  useEffect(() => {
    if (props.data) {
      setValue("name", props.data?.name);
      setValue("fullName", props.data?.fullName);
      setValue("nipProvider", props.data?.nipProvider);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const submitHandler: SubmitHandler<CompanyForm> = async (
    data: CompanyForm
  ) => {
    if (props.data !== null && props.data?.id) {
      const hasBeenUpdate = await patchCompanyMutation({
        id: props.data.id,
        data: data,
      });
      if ("data" in hasBeenUpdate) {
        toast.success(t("toast_companyForm_success"));
        if (props.handleClose) {
          props.handleClose();
        }
      } else if ("error" in hasBeenUpdate) {
        const error = hasBeenUpdate.error as TranslationSubmitError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_companyForm_error_500"));
        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_companyForm_error_400")} ${error.data.message[0]}`
          );
        if (error.data.statusCode === 404)
          toast.error(t("toast_companyForm_error_404"));
      }
    } else {
      const hasBeenCreate = await postCompanyMutation({ data: data });
      if ("data" in hasBeenCreate) {
        if (props.handleNextStep) {
          props.handleNextStep();
        }
        navigate(
          `/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${
            NAV_PATH.COMPANY
          }/${hasBeenCreate.data?.data?.id || ""}`,
          { replace: true }
        );
      } else if ("error" in hasBeenCreate) {
        const error = hasBeenCreate.error as TranslationSubmitError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_companyForm_error_500"));
        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_companyForm_error_400")} ${error.data.message[0]}`
          );
      }
    }
  };

  return (
    <Form
      className={"d-flex flex-column w-100 "}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Row className={"text-dark-blue"}>
        <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Podstawowe dane
        </span>
        <ComponentWithDescription
          label={"Nazwa firmy*"}
          labelFs={`fs-16 ${errors.name ? "text-danger" : "text-dark-blue"}`}
          className="my-2 pb-3"
        >
          <Form.Control
            type="text"
            id="name"
            maxLength={100}
            aria-describedby="name"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("name", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: { value: 100, message: "Max. 100 znaków" },
            })}
            isInvalid={Boolean(errors.name)}
          />
          {errors.name && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.name.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Pełna nazwa firmy*"}
          labelFs={`fs-16 ${
            errors.fullName ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 pb-3"
        >
          <Form.Control
            type="text"
            id="fullName"
            maxLength={200}
            aria-describedby="fullName"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("fullName", {
              required: { value: true, message: "Pole wymagane" },
              maxLength: { value: 200, message: "Max. 200 znaków" },
            })}
            isInvalid={Boolean(errors.fullName)}
          />
          {errors.fullName && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.fullName.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"NIP*"}
          labelFs={`fs-16 ${
            errors.nipProvider ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 pb-3"
        >
          <Form.Control
            type="text"
            id="nipProvider"
            aria-describedby="nipProvider"
            className={`w-100 text-dark-blue ${styles.input} `}
            maxLength={10}
            {...register("nipProvider", {
              required: { value: true, message: "Pole wymagane" },
              minLength: { value: 10, message: "Minimum 10 znaków" },
              maxLength: { value: 10, message: "Max. 10 znaków" },
              pattern: { value: /^\d{10}$/, message: " Tylko liczby" },
            })}
            isInvalid={Boolean(errors.nipProvider)}
          />
          {errors.nipProvider && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.nipProvider.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
      </Row>

      <div className="d-flex gap-2 mt-2 justify-content-between align-items-center">
        <div className={"text-dark-blue fs-16 fw-semibold"}>
          {props.steps && (
            <span>
              Krok {props.steps.step} z {props.steps.maxStep}
            </span>
          )}
        </div>
        <div className={"d-flex gap-2"}>
          <Button
            className="btn-outline-dark-blue"
            onClick={() => {
              if (props.handleClose) {
                props.handleClose();
              }
            }}
          >
            ANULUJ
          </Button>
          <Button type="submit" value="Submit">
            {props.data !== null ? "AKTUALIZUJ" : "UTWÓRZ"}
          </Button>
        </div>
      </div>
    </Form>
  );
};
