export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const slugifyWithUnderscore = (str: string) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/[\s_-]+/g, "_")
    .replace(/^-+|-+$/g, "");
};
