import React, { useState, useEffect } from "react";
import { polishPlural } from "../../../Helpers/polishPlural";

interface Props {
  endDate: string;
  label: string;
}

export const CountdownTimer = ({ endDate, label }: Props) => {
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  function getTimeRemaining() {
    const total = Date.parse(endDate) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  const formatTime = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };

  if (endDate === null) {
    return <></>;
  }

  return (
    <div>
      <div className={"fs-12 pb-2 text-grey-2"}>
        <span>{endDate ? label : "Rabaty tylko"}</span>
      </div>
      <div className={"fs-25 text-dark-blue fw-bolder"}>
        <div>
          {timeRemaining.total >= 0 ? (
            timeRemaining.days >= 1 ? (
              <p className={"mb-0"}>
                {timeRemaining.days}{" "}
                {polishPlural("dzień", "dni", "dni", timeRemaining.days)}
              </p>
            ) : (
              <p className={"mb-0"}>
                {`${formatTime(timeRemaining.hours)}:${formatTime(
                  timeRemaining.minutes
                )}:${formatTime(timeRemaining.seconds)}`}
              </p>
            )
          ) : (
            <p className={"mb-0"}>
              {endDate ? "Zakończona" : "Dla zalogowanych"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
