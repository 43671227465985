export const convertToSelectLabel = (array: any[], name: string) => {
  const arrayLabeled: ILabel[] = [];

  array?.forEach((item: any) => {
    item[name] &&
      arrayLabeled.push({
        value: item[name],
        label: item[name],
        id: item["id"],
      });
  });

  return arrayLabeled;
};
