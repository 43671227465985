import { Button, OverlayTrigger } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { renderTooltip } from "Helpers/renderTooltip";
import { removeHtmlTags } from "Helpers/removeHtmlTags";
import styles from "./TreatmentPurpose.module.scss";

interface Props {
  treatmentPathData: TreatmentPath[];
  pageData: PageDataTreatment;
}

const TreatmentPurpose = ({ treatmentPathData, pageData }: Props) => (
  <div className="py-2 w-100 pt-xxl-3">
    <div className="d-flex align-items-center">
      <p className="fw-400 fs-16 text-dark-blue my-2">Cel leczenia</p>

      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip({}, removeHtmlTags(pageData?.lead))}
      >
        <i className={styles.iconInfo}>
          <InfoCircle size={17} color="#11C0F2" />
        </i>
      </OverlayTrigger>
    </div>
    <div className="d-flex gap-2 flex-xxl-row flex-column">
      {treatmentPathData?.map(({ id, title }) => (
        <Button
          key={id}
          className={`btn-outline-primary p-2 ${styles.treatmentPathButton}`}
        >
          {title}
        </Button>
      ))}
    </div>
  </div>
);

export default TreatmentPurpose;
