import { toast } from "react-toastify";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Form, Badge, Container } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "../../../assets/Icons/DeleteIcon.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { ReactComponent as LocationPin } from "../../../assets/Icons/LocationPin.svg";
import StarRating from "components/atoms/StarRating/StarRating";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import styles from "../../organisms/DoctorWorkPlace/DoctorWorkPlace.module.scss";
import RatingBadge from "components/molecules/RatingBadge/RatingBadge";
import { useGetFacilitiesQuery } from "../../../redux/services/facilitiesApi";
import Select from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import {
  usePatchDoctorFacilityMutation,
  usePostDoctorFacilityMutation,
  useDeleteDoctorFacilityMutation,
} from "../../../redux/services/doctorApi";
import React, { useEffect, useState } from "react";
import { MapModal } from "../MapModal/MapModal";
import { useTranslation } from "react-i18next";
import {replaceSpacesWithDashes} from "../../../Helpers/replaceSpacesWithDashes";

interface DoctorWorkPlaceProps {
  data: DoctorWorkPlaceData;
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  isReadOnly?: boolean;
}

interface DoctorWorkPlaceData {
  id: number;
  facility: null | Facility[];
}

interface Facility {
  id: number;
  name: string;
  status: number;
  rating?: number;
  totalReviews?: number;
  province: {
    name: string;
  };
  city: {
    name: string;
  };
  street: string;
  nfzLocality: string;
  zipCode: string;
}

interface DoctorWorkPlaceForm {
  facilityId?: number;
  name?: string;
  province?: string;
  city?: string;
  street?: string;
}

interface FacilityOption {
  value: number;
  label: string;
  province: {
    name: string;
  };
  city: {
    name: string;
  };
  street: string;
}

export const DoctorWorkPlace = (props: DoctorWorkPlaceProps) => {
  const {
    watch,
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DoctorWorkPlaceForm>({
    defaultValues: {
      facilityId: 0,
      name: "",
      province: "",
      city: "",
      street: "",
    },
  });

  const [showMapModal, setShowMapModal] = useState(false);
  const [activeFacilityId, setActiveFacilityId] = useState<number | null>(null);

  const { t } = useTranslation();

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const ONKO_URL =
      process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
      (process.env?.REACT_APP_MODE === "dev"
          ? "https://dev.onkomapa.pl"
          : "https://onkomapa.pl");
  const ONKO_URL_FACILITY = `${ONKO_URL}/szukaj/placowka/`;

  const { data: facilityData } = useGetFacilitiesQuery({});

  const facilities = facilityData?.data || [];

  const [deleteDoctorFacility] = useDeleteDoctorFacilityMutation();

  const [postDoctorFacility] = usePostDoctorFacilityMutation();

  const [patchDoctorFacility] = usePatchDoctorFacilityMutation();

  const options = facilities.map(
    (facility: Facility): FacilityOption => ({
      value: facility.id,
      label: facility.name,
      province: { name: facility.province.name },
      city: { name: facility.city.name },
      street: facility.street,
    })
  );

  useEffect(() => {
    if (activeFacilityId !== null) {
      setShowMapModal(true);
    }
  }, [activeFacilityId]);

  const handleFacilityDelete = async (facilityId: number) => {
    const payload = {
      id: props.data.id,
      facilityId,
    };

    const hasBeenDeleted = await deleteDoctorFacility(payload);

    if ("data" in hasBeenDeleted) {
      toast.success(t("toast_doctorWorkPlace_delete_success"));
      reset();
    } else if ("error" in hasBeenDeleted) {
      const error = hasBeenDeleted?.error as DoctorFacilityDeleteError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorWorkPlace_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_doctorWorkPlace_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorWorkPlace_error_400")} ${error.data.message[0]}`
        );
    }
  };

  const submitHandler: SubmitHandler<DoctorWorkPlaceForm> = async (data) => {
    const payload = {
      id: props.data.id,
      data: {
        facilityId: Number(data?.facilityId),
        hash: "",
      },
    };

    const hasBeenCreated = await postDoctorFacility(payload);

    if ("data" in hasBeenCreated) {
      toast.success(t("toast_doctorWorkPlace_update_success"));
      await patchDoctorFacility({
        id: props.data.id,
        facilityId: String(data.facilityId),
        data: { status: 1 },
      });
      reset();
    } else if ("error" in hasBeenCreated) {
      const error = hasBeenCreated?.error as DoctorFacilitySubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorWorkPlace_error_500"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorWorkPlace_error_400")} ${error.data.message[0]}`
        );
    }
  };

  return props.isActive ? (
    <Form
      className={"d-flex flex-column w-100"}
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className={"row g-4"}>
        <p className="text-grey-2 fs-12 fw-semibold ls-6">
          Dodawanie miejsca pracy
        </p>
        <ComponentWithDescription
          className="my-2 w-100"
          descFs={`fs-12`}
          label={"Nazwa placówki*"}
          labelFs={`fs-16 mb-1`}
        >
          <Controller
            name={"facilityId"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Pole wymagane",
              },
            }}
            render={({ field }) => (
              <Select
                isClearable={true}
                id="facilityId"
                isSearchable={true}
                options={options}
                placeholder={"Wybierz z listy"}
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
                onChange={(option: FacilityOption | null) => {
                  field.onChange(option ? option.value : undefined);
                  setValue("province", option ? option.province.name : "");
                  setValue("city", option ? option.city.name : "");
                  setValue("street", option ? option.street : "");
                }}
                value={options.find(
                  (option: FacilityOption) => option.value === field.value
                )}
              />
            )}
          />
          {errors?.facilityId && (
            <Form.Control.Feedback type={"invalid"}>
              {errors?.facilityId?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          className="my-2 col-12 col-lg-6"
          descFs={`fs-12`}
          label={"Województwo"}
          labelFs={`fs-16 mb-1`}
        >
          <Form.Control
            type="text"
            disabled={props.isReadOnly || !watch("facilityId")}
            {...register("province")}
          />
          {errors?.province && (
            <Form.Control.Feedback type={"invalid"}>
              {errors?.province?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          className="my-2 col-12 col-lg-6"
          descFs={`fs-12`}
          label={"Miasto"}
          labelFs={`fs-16 mb-1`}
        >
          <Form.Control
            type="text"
            disabled={!watch("facilityId")}
            {...register("city")}
          />
          {errors?.city && (
            <Form.Control.Feedback type={"invalid"}>
              {errors?.city?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          className="my-2 col-12 col-lg-12"
          descFs={`fs-12`}
          label={"Ulica"}
          labelFs={`fs-16 mb-1`}
        >
          <Form.Control
            type="text"
            disabled={!watch("facilityId")}
            {...register("street")}
          />
          {errors?.street && (
            <Form.Control.Feedback type={"invalid"}>
              {errors?.street?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <div className="d-flex gap-2 mt-4 justify-content-end">
          <Button
            className="btn-outline-dark-blue"
            onClick={() => {
              props.handleActive(false);
              if (props.data.facility === null) {
                props.handleFirst(true);
              }
            }}
          >
            ANULUJ
          </Button>
          <Button type="submit" value="Submit">
            {props.isFirst ? "AKTUALIZUJ" : "ZAPISZ"}
          </Button>
        </div>
      </div>
    </Form>
  ) : (
    <>
      {props.data.facility?.map((facility: Facility) => (
        <Container fluid key={facility.id}>
          <Container fluid className={`${styles.wrapper} mb-3`}>
            <div className="p-1 d-md-flex justify-content-between align-items-center">
              <div>
                {facility.status === 1 ? (
                  <Badge className="mt-3 mb-3 mb-md-2 fs-12 fw-semibold badge-secondary bg-secondary">
                    Oczekuje na weryfikacje
                  </Badge>
                ) : (
                  <Badge className="d-none" />
                )}
                <p className="mb-3 fs-19 m-0 text-capitalize">
                  {facility.name}
                </p>
              </div>

              <div className="d-md-flex justify-content-end">
                <div
                  className={`ms-lg-3 me-lg-3 pt-3 pb-3 d-flex flex-md-column justify-content-between text-align-center align-items-center ${styles.underWrapper}`}
                >
                  <div className="d-flex mb-md-1">
                    <RatingBadge
                      rating={Number(
                        parseFloat(`${facility?.rating}` ?? "0.0").toFixed(1)
                      )}
                    />
                    <StarRating rating={facility.rating} />
                  </div>
                  <span
                    className={`fs-12 ${
                      facility.totalReviews === 0
                        ? "text-grey-2"
                        : "text-dark-blue"
                    }`}
                  >
                    {facility.totalReviews === 0
                      ? "(brak ocen)"
                      : `(na bazie ${facility.totalReviews} ${
                          facility.totalReviews === 1 ? "oceny" : "ocen"
                        })`}
                  </span>
                </div>
                {!props.isReadOnly && (
                  <div className="pt-3 pb-3 d-flex justify-content-between align-items-center">
                    <Button
                      onClick={() => {
                        if (facility?.id) {
                          setActiveFacilityId(facility?.id);
                        }
                      }}
                      className={`me-2 d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
                      variant={"transparent"}
                    >
                      <LocationPin />
                    </Button>
                    <MapModal
                      show={showMapModal && activeFacilityId === facility?.id}
                      handleClose={() => setShowMapModal(false)}
                      address={`${facility?.nfzLocality}, ${facility?.street} ${facility?.zipCode}`}
                    />
                    <Button
                      onClick={() =>
                        openInNewTab(`${ONKO_URL_FACILITY}${replaceSpacesWithDashes(facility?.name)}/${facility?.id}`)
                      }
                      className={`me-2 d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
                      variant={"transparent"}
                    >
                      <ArrowUpRightFromSquare />
                    </Button>
                    <Button
                      className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
                      variant={"transparent"}
                      onClick={() => handleFacilityDelete(facility.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </Container>
      ))}
    </>
  );
};
