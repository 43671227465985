import { useState } from "react";

interface FilterState {
  [name: string]: string[];
}

export const useSingleFilter = (name: string) => {

  const [filters, setFilters] = useState<FilterState>({});

  const filterClickHandler = (value: string | undefined) => {
    setFilters(() => {
      if(value === "")
      {
        return { [name]: [], }
      }
      if (value) {
        return {
          [name]: [value],
        };
      }
      else 
      {
        return { [name]: [], }
      }
    });
  };
  const selectedValues = filters[name] || [];
  return { filterClickHandler, selectedValues };
};
