import { Table } from "react-bootstrap";
import { ReactComponent as SortArrow } from "../../../../assets/Icons/SortArrow.svg";
import QuickEditCategory from "../../../molecules/QuickEditCollapse/QuickEditCategory/QuickEditCategory";
import TableRowCategoryWithCollapse from "../../TableRowCategoryWithCollapse/TableRowCategoryWithCollapse";
import { useState } from "react";

import "./CategoryTable.scss";

const CategoryTable = ({ data, handleSort }: any) => {
  const [sortDirection, setSortDirection] = useState<{
    [key: string]: "ASC" | "DESC";
  }>({});

  const handleToggleSort = (column: string) => {
    const newDirection = sortDirection[column] === "ASC" ? "DESC" : "ASC";
    setSortDirection({ ...sortDirection, [column]: newDirection });
    handleSort(column, newDirection);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              onClick={() => handleToggleSort("title")}
            >
              <span>Nazwa</span> <SortArrow className="sortArrow" />
            </button>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              onClick={() => handleToggleSort("type")}
            >
              <span>Typ</span> <SortArrow className="sortArrow" />
            </button>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              onClick={() => handleToggleSort("url")}
            >
              <span>Uproszczona nazwa</span>
              <SortArrow className="sortArrow" />
            </button>
          </th>
        </tr>
      </thead>

      <tbody className="tableBody">
        {data?.map((item: any, i: any) => (
          <TableRowCategoryWithCollapse
            key={i}
            data={item}
            CollapseItem={QuickEditCategory}
          />
        ))}
        {!data?.length && (
          <tr>
            <td colSpan={4} className="text-center">
              Nie znaleziono żadnych stron.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CategoryTable;
