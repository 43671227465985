import { notifications } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Notifications"],
});

export interface GetNotificationsSearchParams {
  limit?: string;
  offset?: string;
  unread?: string;
  includeAdminMessages?: string;
  adminMessagesOnly?: string;
}

type GetNotificationsSubscriptionSearchParams = Omit<
  GetNotificationsSearchParams,
  "unready"
>;

// Don't change order
export enum NotificationTypeEnum {
  "other",
  "articles",
  "loyalty-program",
  "feed",
  "events",
  "user-settings",
  "guardian-patient",
  "oncocollection",
  "company",
  "doctor",
  "admin",
}

interface PostNotificationsData {
  user?: string;
  senderUser?: null;
  viewSender?: number;
  title: string;
  type: number;
  content?: string | null;
  url?: string | null;
  manyUser?: "COMPANY" | "SERVICE" | "DOCTOR";
  idMany?: number;
}

interface PostNotificationsPayload {
  data: PostNotificationsData;
}

interface PatchNotificationsData {
  read?: number;
  trash?: number;
}

interface PatchNotificationsPayload {
  id: number;
  data: PatchNotificationsData;
}

export enum NotificationSubscriptionChannelEnum {
  PUSH = "PUSH",
  EMAIL = "EMAIL",
}

interface PostNotificationsSubscriptionsData {
  user?: string | null;
  notifyKeys: string;
  channel?: NotificationSubscriptionChannelEnum;
  status: number;
}

interface PostNotificationsSubscriptionsPayload {
  data: PostNotificationsSubscriptionsData;
}

interface PatchNotificationsSubscriptionsData {
  status?: number;
}

interface PatchNotificationsSubscriptionsPayload {
  id: number;
  data: PatchNotificationsSubscriptionsData;
}

export const notificationsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({
        limit,
        offset,
        unread,
        includeAdminMessages,
        adminMessagesOnly,
      }: GetNotificationsSearchParams) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(unread && { unread }),
          ...(includeAdminMessages && { includeAdminMessages }),
          ...(adminMessagesOnly && { adminMessagesOnly }),
        });

        return {
          url: `${notifications}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Notifications"],
    }),

    postNotifications: builder.mutation({
      query: ({ data }: PostNotificationsPayload) => ({
        url: `${notifications}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          ...data,
          title: data.title.slice(0, 50),
        },
      }),
      invalidatesTags: ["Notifications"],
    }),

    patchNotifications: builder.mutation({
      query: ({ id, data }: PatchNotificationsPayload) => ({
        url: `${notifications}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Notifications"],
    }),

    patchNotificationsRead: builder.mutation({
      query: () => ({
        url: `${notifications}/read`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Notifications"],
    }),

    getNotificationsSubscriptions: builder.query({
      query: ({ limit, offset }: GetNotificationsSubscriptionSearchParams) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
        });

        return {
          url: `${notifications}/subscription?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Notifications"],
    }),

    postNotificationsSubscriptions: builder.mutation({
      query: ({ data }: PostNotificationsSubscriptionsPayload) => ({
        url: `${notifications}/subscription`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Notifications"],
    }),

    patchNotificationsSubscriptions: builder.mutation({
      query: ({ id, data }: PatchNotificationsSubscriptionsPayload) => ({
        url: `${notifications}/subscription/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Notifications"],
    }),

    deleteAllNotificationsSubscriptions: builder.mutation({
      query: () => ({
        url: `${notifications}/subscription`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Notifications"],
    }),

    deleteOneNotificationsSubscriptions: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `${notifications}/subscription/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  usePostNotificationsMutation,
  usePatchNotificationsMutation,
  useGetNotificationsSubscriptionsQuery,
  usePostNotificationsSubscriptionsMutation,
  usePatchNotificationsSubscriptionsMutation,
  usePatchNotificationsReadMutation,
  useDeleteAllNotificationsSubscriptionsMutation,
  useDeleteOneNotificationsSubscriptionsMutation,
} = notificationsApi;
