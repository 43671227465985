/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import pl from "date-fns/locale/pl";
import Calendar from "react-calendar";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import { Calendar4, ChevronLeft, ChevronRight } from "react-bootstrap-icons";

import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import { ResultCounter } from "components/molecules/ResultCounter/ResultCounter";
import { EventCard } from "components/molecules/EventCard/EventCard";
import EventsCollapse from "components/molecules/CollapseComponents/EventsCollapse/EventsCollapse";
import { CalendarEventCard } from "components/molecules/CalendarEventCard/CalendarEventCard";
import AddEventModal from "components/organisms/AddEventModal/AddEventModal";

import { useGetEventsQuery } from "redux/services/eventsApi";
import {
  useGetEventTypesQuery,
  useLazyGetEventTypesQuery,
} from "redux/services/eventTypesApi";
import { useLazyGetCancersQuery } from "redux/services/cancerApi";
import { useLazyGetCitiesQuery } from "redux/services/citiesApi";
import { useLazyGetProvincesQuery } from "redux/services/provincesApi";
import { useLazyGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";

import { useFilter } from "Helpers/useFilter";
import { useSingleFilter } from "Helpers/useSingleFilter";
import { useEffectOnce } from "Helpers/useEffectOnce";
import { getEventsForDay } from "Helpers/getEventsForDay";
import { getEventTypeName } from "Helpers/getEventTypeName";
import { getDateRange, DateRange } from "Helpers/getDateRange";
import formatEventDateForRange from "Helpers/formatEventDateForRange";
import sortByEventTime from "Helpers/sortByEventTime";

import { RootState } from "redux/store/index";
import { OnArgs, Value } from "react-calendar/dist/cjs/shared/types";

import styles from "./EventsTemplate.module.scss";
import "./Calendar.scss";

import {
  EventData,
  EventAdress,
} from "components/organisms/AddEventModal/types";
import {
  City,
  Province,
} from "components/molecules/LoyaltyProgramServiceArticle/types";

const EventsTemplate = () => {
  const visibleCount = 9;
  const limit = 10;
  const { authUser } = useSelector((state: RootState) => state.user);

  const [events, setEvents] = useState<EventData[]>([]);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState<Date>(new Date());
  const getFirstDayOfPreviousMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month - 1, 1);
  };
  const [calendarDateFrom, setCalendarDateFrom] = useState<Date>(
    getFirstDayOfPreviousMonth(dateFrom)
  );
  const [lazyLoaded, setLazyLoaded] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [calendarDates, setCalendarDates] = useState<DateRange[]>([]);
  const [dayEvents, setDayEvents] = useState<EventData[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [cities, setCities] = useState<ReactSelectOption[]>([]);
  const [topics, setTopics] = useState<ReactSelectOption[]>([]);
  const [categories, setCategories] = useState<ReactSelectOption[]>([]);
  const [provinces, setProvinces] = useState<ReactSelectOption[]>([]);
  const [types, setTypes] = useState<ReactSelectOption[]>([]);
  const [eventsFromDate, setEventsFromDate] = useState<EventData[]>([]);
  const [displayedEvents, setDisplayedEvents] = useState<EventData[]>([]);
  const [applyFilters, setApplyFilters] = useState(false);

  const { data: eventsData, isSuccess } = useGetEventsQuery({
    dateFrom: calendarDateFrom.toString(),
    offset,
    limit,
    orderBy: "startingDate",
    sortBy: "DESC",
    type: ["online", "getresponse", "webinar", "locally", "conference"],
    includeOtherUserTypes: "yes",
    user: authUser?.id,
  });

  const { data: eventTypesData } = useGetEventTypesQuery({});

  const { data: calendarEventsData } = useGetEventsQuery({
    dateFrom: calendarDateFrom.toString(),
    limit: "0",
    type: ["online", "getresponse", "webinar", "locally", "conference"],
    includeOtherUserTypes: "yes",
    user: authUser?.id,
  });

  const [getCancers] = useLazyGetCancersQuery();
  const [getTypes] = useLazyGetEventTypesQuery();
  const [getCities] = useLazyGetCitiesQuery();
  const [getProvinces] = useLazyGetProvincesQuery();
  const [getCategories] = useLazyGetPagesCategoryQuery();

  const {
    selectedValues: selectedCategories,
    filterClickHandler: filterCategoryHandler,
  } = useFilter("categories");

  const {
    selectedValues: selectedTypes,
    filterClickHandler: filterTypesHandler,
  } = useFilter("types");

  const {
    selectedValues: selectedCity,
    filterClickHandler: filterCityHandler,
  } = useSingleFilter("cities");

  const {
    selectedValues: selectedProvince,
    filterClickHandler: filterProvinceHandler,
  } = useSingleFilter("province");

  const {
    selectedValues: selectedTopic,
    filterClickHandler: filterTopicHandler,
  } = useSingleFilter("cancer");

  const clearFilters = () => {
    filterCategoryHandler("");
    filterTypesHandler("");
    filterCityHandler("");
    filterProvinceHandler("");
    filterTopicHandler("");

    const selectInputs = document.getElementsByName(
      "category"
    ) as NodeListOf<HTMLSelectElement>;
    selectInputs.forEach((input) => (input.value = ""));

    if (eventsFromDate) {
      const sortedEvents = sortEventsByPremium(eventsFromDate);
      setDisplayedEvents(sortedEvents);
      setApplyFilters(false);
    }
  };

  const contains = (target: string[], pattern: DateRange[]) => {
    return pattern.some((event: DateRange) => {
      const dateRange = getDateRange({
        startingDate: event.startingDate,
        endingDate: event.endingDate,
      });
      return dateRange?.some((date: Date) =>
        target.includes(format(date, "dd.MM.yyyy"))
      );
    });
  };

  const sortEventsByPremium = (events: EventData[]) => {
    const premiumEvents = events.filter((event: EventData) =>
      event?.profiles?.some(
        (profile: PageCategory) => profile.url === "premium"
      )
    );
    const nonPremiumEvents = events.filter(
      (event: EventData) =>
        !event?.profiles?.some(
          (profile: PageCategory) => profile.url === "premium"
        )
    );
    return [...premiumEvents, ...nonPremiumEvents];
  };

  const getFutureEvents = (
    from: "now" | "selected",
    source?: EventData[]
  ): EventData[] => {
    const showError = (content: string) => console.error(content);

    const eventsToFilter: EventData[] = source ?? eventsData?.events;

    if (!eventsToFilter || eventsToFilter.length === 0) {
      showError(
        `There was an error while executing getFutureEvents function. Please check if source or eventsData exists.`
      );
      return [];
    }

    const eventsFurtherThanSelectedDate = eventsToFilter.filter(
      (event: EventData) => {
        if (event.startingDate && event.endingDate) {
          const dateRange = getDateRange({
            startingDate: new Date(event.startingDate),
            endingDate: new Date(event.endingDate),
          });

          return dateRange?.some((date) => {
            const dateNow = new Date();
            return from === "now" ? date >= dateNow : date >= dateFrom;
          });
        }
        return false;
      }
    );

    const sortedEvents = sortByEventTime(eventsFurtherThanSelectedDate);

    if (sortedEvents) {
      return sortedEvents;
    }

    showError(
      `There was an error while sorting events. Please check if source or eventsData exists.`
    );

    return [];
  };

  const applyEventsFilters = () => {
    if (eventsFromDate) {
      const filteredEvents = eventsFromDate
        .filter((event: EventData) =>
          selectedTypes.length === 0
            ? types
            : selectedTypes.some((type: string) => type === event.type)
        )
        .filter((event: EventData) =>
          !selectedCity[0] || selectedCity[0] === "Wszystkie"
            ? cities
            : event.city?.id === parseInt(selectedCity[0])
        )
        .filter((event: EventData) =>
          !selectedProvince[0] || selectedProvince[0] === "Wszystkie"
            ? provinces
            : event.province?.id === parseInt(selectedProvince[0])
        )
        .filter((event: EventData) =>
          selectedTopic.length === 0
            ? topics
            : selectedTopic[0] === "0"
            ? event.cancer === null
            : event.cancer?.id === parseInt(selectedTopic[0])
        )
        .filter((event: EventData) =>
          selectedCategories.length === 0
            ? categories
            : selectedCategories.some((category: string) =>
                event.categories
                  ?.map((cat: EventCategory) => cat.id.toString())
                  .includes(category)
              )
        );

      const sortedEvents = sortEventsByPremium(filteredEvents);
      setDisplayedEvents(sortedEvents);
      setApplyFilters(true);
    }
  };

  const LazyLoadEvents = () => {
    setLazyLoaded(true);
    setOffset(offset + limit);
  };

  const loadPreviousMonth = ({ activeStartDate }: OnArgs) => {
    if (activeStartDate) {
      setCalendarDateFrom(getFirstDayOfPreviousMonth(activeStartDate));
    }
  };

  const showDayEvents = (value: Value) => {
    if (value) {
      const selectedDate = new Date(value.toString());
      setDateFrom(selectedDate);
      if (calendarEventsData) {
        const eventsForDay = getEventsForDay(selectedDate, calendarEventsData);
        setDayEvents(eventsForDay || []);
      }
    }
  };

  useMemo(() => {
    if (calendarEventsData) {
      const futureEvents = getFutureEvents("selected", calendarEventsData.events);
      setEventsFromDate(futureEvents);
    }
  }, [eventsData, dateFrom]);

  useEffectOnce(() => {
    (async () => {
      try {
        const [
          cancersResult,
          typesResult,
          citiesResult,
          provincesResult,
          categoriesResult,
        ] = await Promise.all([
          getCancers({ limit: "0", status: "1" }),
          getTypes({}),
          getCities({ limit: "0" }),
          getProvinces({ limit: "0" }),
          getCategories({ type: "event" }),
        ]);
  
        const topicsArray = cancersResult?.data?.data?.map((cancer: Cancer) => ({
          value: cancer.id,
          label: cancer.name,
        })) as ReactSelectOption[];
        topicsArray?.unshift({ value: "0", label: "Ogólnie o raku" });
        setTopics(topicsArray);
  
        const typesArray = typesResult?.data?.eventCategories?.map(
          (type: EventType) => ({
            value: type.template,
            label: type.name,
          })
        ) as ReactSelectOption[];
        setTypes(typesArray);
  
        const citiesArray = citiesResult?.data?.data?.map((city: City) => ({
          value: city.id.toString(),
          label: city.name,
        })) as ReactSelectOption[];
        setCities(citiesArray);
  
        const provincesArray = provincesResult?.data?.data?.map(
          (province: Province) => ({
            value: province.id.toString(),
            label: province.name,
          })
        ) as ReactSelectOption[];
        setProvinces(provincesArray);
  
        const categoriesArray = categoriesResult?.data?.data?.map(
          (category: PageCategory) => ({
            value: category.id.toString(),
            label: category.title,
          })
        ) as ReactSelectOption[];
        setCategories(categoriesArray);
  
        if (dayEvents.length === 0) {
          showDayEvents(new Date());
        }
      } catch (error) {
        console.error('Error fetching filter data:', error);
      }
    })();
  });
  
useEffect(() => {
  if (isSuccess && eventsData) {
    try {
      if (!lazyLoaded && offset === 0) {
        const premiumEvents = eventsData.events.filter((event: EventData) =>
          event?.profiles?.some(
            (profile: PageCategory) => profile.url === "premium"
          )
        );
        setEvents(premiumEvents);
      } else if (lazyLoaded && eventsData.currentOffset === offset) {
        const newEvents = [...events, ...eventsData.events];
        setEvents(newEvents);
        setLazyLoaded(false);
      }
    } catch (error) {
      console.error('Error processing events data:', error);
    }
  }
}, [isSuccess, eventsData, lazyLoaded, offset]);


  useEffect(() => {
    if (!applyFilters && eventsFromDate) {
      const sortedEvents = sortEventsByPremium(eventsFromDate);
      setDisplayedEvents(sortedEvents);
    }
  }, [events, eventsFromDate]);

  useEffect(() => {
    if (calendarEventsData) {
      const calendarDatesArray = Array.from(
        new Set(
          calendarEventsData.events.map((event: EventData) => ({
            startingDate: new Date(event.startingDate),
            endingDate: new Date(event.endingDate),
          }))
        )
      ) as DateRange[];
      setCalendarDates(calendarDatesArray);
    }
  }, [JSON.stringify(calendarEventsData)]);

  useEffect(() => {
    const startingDatesArray = Array.from(
      new Set(
        displayedEvents.map((event: EventData) =>
          format(new Date(event.startingDate), "dd.MM.yyyy")
        )
      )
    );
    setDates(startingDatesArray);
  }, [JSON.stringify(displayedEvents)]);

  if (eventsData) {
    return (
      <>
        <Container fluid className="bg-light px-0">
          <section className={styles.section}>
            <Stack>
              <Row className="mx-0">
                <SectionTitle className={`${styles.title} w-100`}>
                  Wydarzenia
                </SectionTitle>
                <Row
                  className={`mb-5 px-0 mx-0 d-flex flex-wrap ${styles.resultsContainer}`}
                >
                  <ResultCounter
                    icon={<Calendar4 color="#11c0f2" />}
                    resultCount={
                      getFutureEvents("now", calendarEventsData?.events)?.length ?? 0
                    }
                    className="w-auto py-1 pe-4"
                    label="nadchodzących wydarzeń"
                  />
                </Row>
                <Row className={`mb-5 mx-0 px-0 ${styles.calendarContainer}`}>
                  <Calendar
                    className="col-xl-8 px-0"
                    selectRange={false}
                    value={dateFrom}
                    onChange={showDayEvents}
                    minDetail="month"
                    next2Label=""
                    prev2Label=""
                    defaultActiveStartDate={new Date()}
                    formatShortWeekday={(locale, date) =>
                      format(date, "EE.", { locale: pl })
                    }
                    onActiveStartDateChange={loadPreviousMonth}
                    nextLabel={<ChevronRight />}
                    prevLabel={<ChevronLeft />}
                    tileClassName={({ date, view }) =>
                      view === "month" &&
                      contains([format(date, "dd.MM.yyyy")], calendarDates)
                        ? "contains-events"
                        : null
                    }
                  />
                  <div className="col-xl-4 event-holder">
                    <h3 className="react-calendar__navigation__label__labelText mb-5">
                      Wydarzenia
                    </h3>
                    <div className={styles.calendarEventsContainer}>
                      {dayEvents.length > 0 ? (
                        dayEvents.map((event: EventData, index: number) => {
                          console.log(event)
                          const adressObject: EventAdress =
                            event.address?.length > 0 &&
                            event.address[0] === "{"
                              ? JSON.parse(event.address)
                              : undefined;
                          return (
                            <CalendarEventCard
                              key={index}
                              type={getEventTypeName(
                                event.type,
                                eventTypesData?.eventCategories
                              )}
                              title={event.name}
                              location={
                                event.city
                                  ? [
                                      event.province ? event.province.name : "",
                                      event.city.name,
                                      adressObject
                                        ? `${
                                            (adressObject.street
                                              ? adressObject.street + " "
                                              : "") +
                                            (adressObject.apartmentNumber
                                              ? adressObject.houseNumber +
                                                " / " +
                                                adressObject.apartmentNumber
                                              : adressObject.houseNumber)
                                          }`
                                        : event.address,
                                    ]
                                  : undefined
                              }
                              date={formatEventDateForRange(event)}
                              id={event.id}
                              className={
                                index === dayEvents.length - 1 ? "mb-0" : "mb-3"
                              }
                            />
                          );
                        })
                      ) : (
                        <p>Brak wydarzeń dla wybranego dnia.</p>
                      )}
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => setShowAddEventModal(!showAddEventModal)}
                      className={`w-100 mb-3 mt-4 ${styles.addEventButton}`}
                    >
                      DODAJ SWOJE WYDARZENIE
                    </Button>
                  </div>
                </Row>
                <Row className="px-0 mx-0">
                  <Container fluid className="px-0">
                    <Row className="px-0 mx-0">
                      <EventsCollapse
                        types={types}
                        provinces={provinces}
                        cities={cities}
                        topics={topics}
                        selectedCategories={selectedCategories}
                        categories={categories}
                        selectedTopic={selectedTopic}
                        selectedTypes={selectedTypes}
                        onCategoryChange={filterCategoryHandler}
                        onTypeChange={filterTypesHandler}
                        onCityChange={filterCityHandler}
                        onProvinceChange={filterProvinceHandler}
                        onTopicChange={filterTopicHandler}
                        onFiltersReset={clearFilters}
                        selectedCity={selectedCity}
                        selectedProvince={selectedProvince}
                        onApplyFilters={applyEventsFilters}
                      />
                    </Row>
                    <Row xs={1} lg={1} xl={1} className="mx-0">
                      {dates.length > 0 ? (
                        dates.map((date: string, index: number) => (
                          <div key={index} className="mt-5 px-0">
                            <h3
                              className={`text-center fw-400 m-0 ${styles.dateContainer}`}
                            >
                              {date}
                            </h3>
                            {displayedEvents
                              .filter(
                                (event: EventData) =>
                                  format(new Date(event.startingDate), "dd.MM.yyyy") ===
                                  date
                              )
                              .map((event: EventData, index: number) => (
                                <Col
                                  className="d-flex align-items-stretch mt-5"
                                  key={index}
                                >
                                  <EventCard
                                    data={event}
                                    typesArray={eventTypesData?.eventCategories}
                                  />
                                </Col>
                              ))}
                          </div>
                        ))
                      ) : (
                        <div
                          className={`${styles.noEventsContainer} d-flex flex-column align-items-center py-5`}
                        >
                          <h3
                            className={`${styles.noEventsHeader} mb-4 d-flex align-items-center`}
                          >
                            Brak wydarzeń spełniających kryteria filtrów
                          </h3>
                          <Button variant="primary" onClick={clearFilters}>
                            WYCZYŚĆ FILTRY
                          </Button>
                        </div>
                      )}
                      {visibleCount < displayedEvents.length && (
                        <div className="d-flex justify-content-center align-content-center">
                          <Button
                            variant="primary"
                            className={`mt-5 ${styles.showMoreButton}`}
                            onClick={LazyLoadEvents}
                          >
                            POKAŻ WIĘCEJ
                          </Button>
                        </div>
                      )}
                    </Row>
                  </Container>
                </Row>
              </Row>
            </Stack>
          </section>
        </Container>
        <AddEventModal show={showAddEventModal} setShow={setShowAddEventModal} />
      </>
    );
  } else {
    return (
      <Container fluid className="bg-light">
        <section className={styles.section}>
          <Stack gap={5}>
            <Row>
              <SectionTitle className={`${styles.title} w-100`}>
                Wydarzenia
              </SectionTitle>
            </Row>
          </Stack>
        </section>
      </Container>
    );
  }
};

export default EventsTemplate;