import React from 'react';
import styles from './RatingsAndReviewsSource.module.scss';
import {Button, Container} from 'react-bootstrap';
import {ReactComponent as ArrowUpRightFromSquare} from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import {replaceSpacesWithDashes} from "../../../Helpers/replaceSpacesWithDashes";
import {useTranslation} from "react-i18next";

interface RatingsAndReviewsSourceProps {
    doctorId: number;
    doctorDegree: string;
    doctorFirstName: string;
    doctorLastName: string;
}

const RatingsAndReviewsSource = ({doctorId, doctorDegree, doctorFirstName, doctorLastName}: RatingsAndReviewsSourceProps) => {
    const ONKO_URL = process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ?? (process.env?.REACT_APP_MODE === "dev" ? "https://dev.onkomapa.pl" : "https://dev.onkomapa.pl");
    const ONKO_URL_DOCTOR = `${ONKO_URL}/szukaj/lekarz/${replaceSpacesWithDashes(doctorDegree)}-${replaceSpacesWithDashes(doctorFirstName)}-${replaceSpacesWithDashes(doctorLastName)}/${doctorId}`
    const { t } = useTranslation();

    return (
        <Container
            fluid
            className={`p-4 containerWithShadow`}>
            <p className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
                {t("widget_ratings_and_reviews_source_title")}
            </p>
            <div
                className={`p-4 d-flex justify-content-between align-items-center ${styles.wrapper}`}
            >
                <p className={`fs-19 fw-400 m-0 p-0 text-dark-blue`}>{t("widget_doctor_ratings_link")}</p>
                <a href={ONKO_URL_DOCTOR} target="_blank" rel="noreferrer">
                <Button
                    variant={"transparent"}
                    className={`d-flex justify-content-center align-items-center p-2 text-dark-blue ${styles.buttonSize}`}
                >
                    <ArrowUpRightFromSquare/>
                </Button>
                </a>
            </div>
        </Container>
    )
}

export default RatingsAndReviewsSource;
