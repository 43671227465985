export const RSS_TYPES: RssType[] = [
  { name: "xml", label: "XML", value: "xml" },
  { name: "wpJson", label: "WordPress JSON", value: "wp-json" },
  { name: "rssAtom", label: "RSS/Atom", value: "rss-atom" },
];

export const STATUSES: Status[] = [
  { name: "active", label: "Aktywny", value: 1, checked: true },
  { name: "inactive", label: "Nieaktywny", value: 0 },
];
