import { Col, Container, Stack } from "react-bootstrap";
import React from "react";
import { Changes } from "../../organisms/Changes/Changes";
import { ChangesImage } from "../../organisms/ChangesImage/ChangesImage";
import { ChangesSocialMedia } from "../../organisms/ChangesSocialMedia/ChangesSocialMedia";
import { ChangesInfo } from "../../organisms/ChangesInfo/ChangesInfo";
import { ChangesContact } from "../../organisms/ChangesContact/ChangesContact";
import {
  Company,
  CompanyDepartment,
  LoyaltyProgramServiceData,
} from "../../molecules/LoyaltyProgramServiceArticle/types";
import styles from "./ChangesSponsorCompanyTemplate.module.scss";

interface Props {
  config: ConfigChanges[];
  changes: { [key: string]: any };
  data: Company | CompanyDepartment | LoyaltyProgramServiceData;
}
export const ChangesSponsorCompanyTemplate = (props: Props) => {
  return (
    <Container fluid={true}>
      <Stack className={"w-100 p-0 m-0"} gap={5}>
        <Col>
          <ChangesInfo
            changes={props.changes}
            status={props.data?.status}
            isAdmin={false}
          />
        </Col>

        {props.config
          .filter(
            (configChanges: ConfigChanges) =>
              configChanges.keyName in props.changes,
          )
          .map((ConfigChanges: ConfigChanges, index: number) => {
            switch (ConfigChanges.type) {
              case "normal":
                return (
                  <>
                    <hr className={`mt-0 mb-3 my-1 p-0 ${styles.hrColor}`} />{" "}
                    <Changes
                      key={index}
                      keyName={ConfigChanges.keyName}
                      changes={props.changes}
                      data={props.data}
                      name={ConfigChanges.name}
                      isAdmin={false}
                    />
                  </>
                );
              case "image":
                return (
                  <>
                    <hr className={`mt-0 mb-3 my-1 p-0 ${styles.hrColor}`} />{" "}
                    <ChangesImage
                      key={index}
                      keyName={ConfigChanges.keyName}
                      changes={props.changes}
                      data={props.data as Company | LoyaltyProgramServiceData}
                      name={ConfigChanges.name}
                      maxHeight={ConfigChanges.maxHeight ?? 160}
                      rounded={!!ConfigChanges.rounded}
                      isAdmin={false}
                    />
                  </>
                );
              case "social":
                return (
                  <>
                    <hr className={`mt-0 mb-3 my-1 p-0 ${styles.hrColor}`} />{" "}
                    <ChangesSocialMedia
                      key={index}
                      keyName={ConfigChanges.keyName}
                      changes={props.changes}
                      data={props.data}
                      name={ConfigChanges.name}
                      isAdmin={false}
                    />
                  </>
                );
              case "contact":
                return (
                  <>
                    <hr className={`mt-0 mb-3 my-1 p-0 ${styles.hrColor}`} />{" "}
                    <ChangesContact
                      key={index}
                      keyName={ConfigChanges.keyName}
                      changes={props.changes}
                      data={props.data}
                      name={ConfigChanges.name}
                      isAdmin={false}
                    />
                  </>
                );
              default:
                return <></>;
            }
          })}
      </Stack>
    </Container>
  );
};
