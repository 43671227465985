export const AGREEMENT_DATA: {
  TYPE_OPTIONS: ReactSelectOption[];
} = {
  TYPE_OPTIONS: [
    {
      label: "Użytkownik",
      value: "user",
    },
    {
      label: "Partner",
      value: "sponsor",
    },
  ],
};
