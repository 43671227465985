import { languages } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Languages"],
});

export const languagesApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: ({ offset, limit }) => {
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
        });

        return {
          url: `${languages}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Languages"],
    }),

    getLanguageById: builder.query({
      query: ({ languageId }) => ({
        url: `${languages}/${languageId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Languages"],
    }),
  }),
});

export const { useGetLanguagesQuery, useGetLanguageByIdQuery } = languagesApi;
