import { useEffect, useState, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetPollsQuery } from "redux/services/pollsApi";
import { useGetPagesCategoryQuery } from "redux/services/pagesCategoryApi";
import { useGetPagesQuery } from "redux/services/pagesApi";
import { Form } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useGetTranslationsByLangIdQuery } from "redux/services/translationsApi";
import { useGetLoyaltyProgramsServiceCategoryQuery } from "redux/services/loyaltyProgramApi";
import TinyEditor from "components/organisms/TinyEditor/TinyEditor";
import Select from "react-select";
import styles from "./RenderSettingsOptions.module.scss";

interface RenderSettingsOptionProps {
  settings: SystemSettingsData;
}
type OptionType = { value: number; label: string };

const RenderSettingsOption: React.FC<RenderSettingsOptionProps> = ({
  settings,
}) => {
  const { control, watch, setValue, register } = useFormContext();
  const [options, setOptions] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [isInputVisible, setInputVisible] = useState(false);

  const { data: allPollsData } = useGetPollsQuery({ offset: "0", limit: "0" });
  const { data: allPagesCategoryData } = useGetPagesCategoryQuery({
    offset: "0",
    limit: "0",
  });

  const { data: allLoyaltyProgramServiceCategory } =
    useGetLoyaltyProgramsServiceCategoryQuery({ allService: 1 });

  const { data: allPagesData } = useGetPagesQuery({
    offset: "0",
    limit: "0",
    parentNotAssigned: 1,
  });

  const { data: translationsData } = useGetTranslationsByLangIdQuery({
    languageId: settings?.language?.id,
  });

  const [translatedContext, setTranslatedContext] = useState<string>("");

  useEffect(() => {
    if (translationsData && translationsData.data) {
      const translationEntry = translationsData.data[settings.context];
      if (translationEntry) {
        setTranslatedContext(translationEntry);
      }
    }
  }, [translationsData, settings.context]);

  const generateOptions = useCallback(() => {
    let newOptions = [];
    switch (settings?.tableName) {
      case "poll":
        newOptions =
          allPollsData?.data?.map(
            ({ id, title }: { id: number; title: string }) => ({
              value: id,
              label: `${title} (${id})`,
            })
          ) || [];
        break;
      case "pages_category":
        newOptions =
          allPagesCategoryData?.data?.map(
            ({ id, title }: { id: number; title: string }) => ({
              value: id,
              label: `${title} (${id})`,
            })
          ) || [];
        break;
      case "pages":
        newOptions =
          allPagesData?.data?.map(
            ({ id, title }: { id: number; title: string }) => ({
              value: id,
              label: `${title} (${id})`,
            })
          ) || [];
        break;
      case "loyalty_program_service_category":
        newOptions =
          allLoyaltyProgramServiceCategory?.data?.map(
            ({ id, name }: { id: number; name: string }) => ({
              value: id,
              label: `${name} (${id})`,
            })
          ) || [];
        break;
      default:
        newOptions = [];
        break;
    }
    setOptions(newOptions);
  }, [
    settings?.tableName,
    allPollsData,
    allPagesCategoryData,
    allPagesData,
    allLoyaltyProgramServiceCategory,
  ]);

  useEffect(() => {
    generateOptions();
  }, [generateOptions]);

  const isChecked = watch(`${settings?.id}_status`, settings?.status === 1);

  useEffect(() => {
    if (!isChecked) {
      setValue(`${settings.id}_value`, "");
    }
  }, [isChecked, setValue, settings.id]);

  useEffect(() => {
    if (settings.inputType === "select" && settings.value !== "") {
      setValue(`${settings.id}_value`, Number(settings.value));
    }
  }, [settings, setValue]);

  const handleSelectChange = (
    option: OptionType | OptionType[] | null,
    fieldOnChange: (value: number | number[] | "") => void
  ) => {
    if (settings?.tableName === "pages_category") {
      const selectedOptions = option as OptionType[];
      fieldOnChange(selectedOptions ? selectedOptions.map((o) => o.value) : []);
    } else {
      fieldOnChange(option ? (option as OptionType).value : "");
    }
  };

  return (
    <div
      className={`row mb-2 ${
        settings.inputType === "select" ||
        settings.inputType === "input" ||
        settings.inputType === "file"
          ? "align-items-center"
          : "align-items-start"
      }`}
    >
      <div className="d-flex flex-column flex-md-row gap-1 gap-md-2 justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center w-100">
          <div
            className={` border text-uppercase  fw-500 fs-16 ps-1 pe-1 me-2 ${styles.lang}`}
          >
            {settings.language.code}
          </div>
          <div className="d-flex align-items-center ">
            <Form.Label className="m-0">
              <Form.Check
                {...register(`${settings.id}_status`)}
                type="switch"
                defaultChecked={settings.status === 1}
              />
            </Form.Label>
            <p className="m-0 fs-16 fw-400">
              {translatedContext || settings.context}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100">
          <div className={`col ${styles.inputWidth}`}>
            {settings.inputType === "textarea" ? (
              <div className="col d-flex justify-content-center justify-content-md-end">
                <button
                  onClick={() => setInputVisible(!isInputVisible)}
                  className={` ${styles.accordionButton}`}
                >
                  {isInputVisible ? (
                    <ChevronUp className={styles.chevronColor} />
                  ) : (
                    <ChevronDown className={styles.chevronColor} />
                  )}
                </button>
              </div>
            ) : settings.inputType === "input" ? (
              <Form.Control
                type="text"
                placeholder={settings.value}
                disabled={!isChecked}
                {...register(`${settings.id}_value`)}
                defaultValue={settings.value || ""}
              />
            ) : settings.inputType === "file" ? (
              <Form.Control
                type="file"
                disabled={!isChecked}
                {...register(`${settings.id}_value`)}
              />
            ) : (
              <Controller
                name={`${settings.id}_value`}
                control={control}
                defaultValue={
                  settings?.tableName === "pages_category"
                    ? Array.isArray(settings.value)
                      ? settings.value
                      : [Number(settings.value)]
                    : Number(settings.value) || ""
                }
                render={({ field }) => (
                  <Select
                    id={settings.context}
                    value={
                      settings?.tableName === "pages_category" &&
                      Array.isArray(field.value)
                        ? (field?.value as number[])
                            .map((value) =>
                              options?.find((option) => option?.value === value)
                            )
                            .filter((option) => option !== undefined)
                        : options?.find(
                            (option) => option?.value === field?.value
                          ) || null
                    }
                    options={options}
                    isDisabled={!isChecked}
                    isMulti={settings?.tableName === "pages_category"}
                    onChange={(option) =>
                      handleSelectChange(
                        option as OptionType | OptionType[] | null,
                        field?.onChange
                      )
                    }
                    placeholder={"Brak ankiety"}
                  />
                )}
              />
            )}
          </div>
        </div>
      </div>
      {settings.inputType === "textarea" &&
        settings.tinymce === 1 &&
        isInputVisible && (
          <div className="w-100">
            {settings.type === "emailTemplate" && (
              <div className="row">
                <p className="fs-16 mt-2 mb-1">Kod HTML</p>
              </div>
            )}
            <div className="row mt-3">
              <TinyEditor
                setValue={setValue}
                name={`${settings.id}_value`}
                data={settings.value}
              />
            </div>
            {settings.type === "emailTemplate" && (
              <div className="row">
                <p className="fs-12 fw-700 mt-1 text-grey">
                  Pamiętaj, aby zachować standardy pisania szablonów HTML.
                  Zmienne w kodzie należy oznaczać jako %zmienna%
                </p>
              </div>
            )}
          </div>
        )}
      {settings.inputType === "textarea" &&
        settings.tinymce !== 1 &&
        isInputVisible && (
          <div className="row ps-2 pe-2">
            <Form.Control
              as="textarea"
              rows={10}
              defaultValue={settings.value}
              disabled={!isChecked}
              {...register(`${settings.id}_value`)}
            />
          </div>
        )}
    </div>
  );
};

export default RenderSettingsOption;
