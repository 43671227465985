import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const loadResources = async (locale: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_EXTERNAL_LINK_STATIC}/translation/moja_pl.json`,
    {
      method: "GET",
    },
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      allowMultiLoading: false,
      crossDomain: false,
      withCredentials: false,
      request: (
        options: any,
        url: any,
        payload: any,
        callback: (
          param: null,
          status: { data?: TranslationData; status: number },
        ) => void,
      ) => {
        try {
          loadResources("pl").then((response) => {
            callback(null, {
              data: response,
              status: 200,
            });
          });
        } catch (e) {
          callback(null, {
            status: 500,
          });
        }
      },
      requestOptions: {
        cache: "default",
      },
    },
    react: {
      useSuspense: true,
    },
    debug: false,
    load: "languageOnly",
    fallbackLng: ["pl"],
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
