import { convertToSelectLabel } from "Helpers/convertToSelectLabel";
import { FormSwitchWithLabel } from "components/molecules/FormSwitchWithLabel/FormSwitchWithLabel";
import React, { useEffect, useState, useRef } from "react";
import { Accordion, Button, Form, Row } from "react-bootstrap";
import Select, { components, OptionProps } from "react-select";
import {
  useGetFeedTagsQuery,
  useGetFeedTopicsQuery,
} from "redux/services/feedApi";
import styles from "./FeedCollapse.module.scss";
import { customStyles } from "styles/reactSelectCustomStyles";

const FeedCollapse = ({
  showSaved,
  onSwitchSaved,
  myPosts,
  onSwitchMyPosts,
  filterPostsHandler,
  resetFiltersHandler,
  tags,
  topics,
  setTags,
  setTopics,
}: FeedCollapseProps) => {
  const [tagsSource, setTagsSource] = useState<ILabel[]>();
  const [topicsSource, setTopicsSource] = useState<ILabel[]>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
  const { data: allTags, isSuccess: tagsOk } = useGetFeedTagsQuery({});
  const { data: allTopics, isSuccess: topicsOk } = useGetFeedTopicsQuery({});

  const selectRef = useRef<HTMLDivElement>(null);

  const groupedOptions: FeedCollapseGroupedOption[] = [
    {
      label: "Najpopularniejsze",
      options: tagsSource ?? [],
    },
    {
      label: "Wszystkie",
      options: tagsSource ?? [],
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (allTags && allTopics && tagsOk && topicsOk) {
      setTagsSource(convertToSelectLabel(allTags.data, "name"));
      setTopicsSource(convertToSelectLabel(allTopics.data, "name"));
    }
  }, [allTags, allTopics, tagsOk, topicsOk]);

  const handleFilterPosts = () => {
    filterPostsHandler();
    setIsAccordionOpen(false);
  };

  const SelectCheckboxValue = (props: any) => {
    return (
      <components.ValueContainer {...props} className={"col-12"}>
        <span
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          onTouchStart={() => setIsMenuOpen(!isMenuOpen)}
          className={"col-12"}
        >
          Wybrano: {topics.length}{" "}
          {topics.length === 1
            ? "filtr"
            : topics.length > 1 && topics.length < 5
            ? "filtry"
            : "filtrów"}
        </span>
      </components.ValueContainer>
    );
  };

  const CustomDropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <span
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="col-12"
          onTouchStart={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </span>
      </components.DropdownIndicator>
    );
  };

  const SelectCheckbox = ({
    children,
    isSelected,
    ...rest
  }: OptionProps<ILabel, boolean>) => {
    return (
      <components.Option
        {...rest}
        isSelected={isSelected}
        className={`cursor-pointer d-flex flex-wrap fs-16 ${styles.customOption}`}
      >
        <span onClick={() => setIsMenuOpen(false)} className={`d-flex`}>
          <Form.Check
            type="checkbox"
            defaultChecked={isSelected}
            checked={isSelected}
            className="col-auto p-0 m-0"
            id="topics-checkbox"
          />
          <span className="mx-4 col-8 col-md-10 col-xl-auto">{children}</span>
        </span>
      </components.Option>
    );
  };

  return (
    <Accordion
      className={`${styles.accordion} p-0`}
      activeKey={isAccordionOpen ? "feed" : ""}
    >
      <Accordion.Item eventKey="feed">
        <Accordion.Header
          className={`fw-600 text-dark-blue ${styles.header}`}
          onClick={() => setIsAccordionOpen(!isAccordionOpen)}
        >
          Filtruj posty
        </Accordion.Header>
        <Accordion.Body>
          <Row xl={1} className={`px-0 mx-0 w-100`}>
            <span className="col-xl-6 px-0 mx-0">
              <FormSwitchWithLabel
                label="Pokaż tylko zapisane posty"
                isSwitched={showSaved}
                onSwitchChange={onSwitchSaved}
              />
            </span>
            <span className="col-xl-6 px-0 mx-0">
              <FormSwitchWithLabel
                label="Pokaż tylko moje posty"
                isSwitched={myPosts}
                onSwitchChange={onSwitchMyPosts}
              />
            </span>
          </Row>
          <Row className="py-4">
            <p>Szukaj wątków</p>
            <div>
              <Select<any, true>
                value={tags}
                onChange={(value) => setTags(value)}
                isMulti
                name="tags"
                components={{
                  DropdownIndicator: null,
                }}
                className={`w-100 col-12`}
                options={groupedOptions}
                placeholder=""
                styles={customStyles(false, true)}
                noOptionsMessage={() => (
                  <span>Brak tagów o podanej frazie</span>
                )}
              />
            </div>
          </Row>
          <Row className="py-4">
            <p>Typ nowotworu</p>
            <div ref={selectRef}>
              <Select<ILabel, boolean>
                menuIsOpen={isMenuOpen}
                onChange={(value) => setTopics(value)}
                components={{
                  Option: SelectCheckbox,
                  ValueContainer: SelectCheckboxValue,
                  DropdownIndicator: CustomDropdownIndicator,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                name="postTopic"
                className="w-100 col-12"
                isMulti
                styles={{
                  menuList: (styles) => ({
                    ...styles,
                    overflowX: "hidden",
                    overflowY: "auto",
                  }),
                }}
                options={topicsSource}
                noOptionsMessage={() => (
                  <span>Brak tematów o podanej frazie</span>
                )}
              />
            </div>
          </Row>
          <Row className="d-flex justify-content-end">
            <div className="d-flex justify-content-end flex-wrap">
              <Button
                className={`${styles.filterButton} mx-lg-3 my-lg-0 my-2 fw-500 btn-outline-dark-blue`}
                onClick={resetFiltersHandler}
              >
                RESETUJ FILTRY
              </Button>
              <Button
                className={`${styles.filterButton} bg-primary fw-500`}
                onClick={handleFilterPosts}
              >
                FILTRUJ
              </Button>
            </div>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FeedCollapse;
