import React from "react";
import Select from "react-select";
import { useGetPollsQuery } from "redux/services/pollsApi";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";

interface Poll {
  id: number;
  title: string;
}

interface SelectOption {
  value: number;
  label: string;
}

const PollSelection: React.FC<{ onSelect: (id: number | null) => void }> = ({
  onSelect,
}) => {
  const { data: allPollsData } = useGetPollsQuery({ offset: "0", limit: "0" });

  const pollOptions = allPollsData?.data?.map((poll: Poll) => ({
    value: poll.id,
    label: poll.title,
  }));

  return (
    <>
      <h2 className="fw-600 fs-22">Wybór ankiety</h2>
      <Select
        options={pollOptions}
        className=" mt-3"
        placeholder="Proszę wybrać ankietę"
        styles={customStyles(false)}
        components={{ DropdownIndicator }}
        onChange={(selectedOption) =>
          onSelect(
            selectedOption ? (selectedOption as SelectOption).value : null
          )
        }
      />
    </>
  );
};

export default PollSelection;
