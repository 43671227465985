const INITIAL_VERSION_0 = "0.0.0";
const INITIAL_VERSION_1 = "1.0.0";

export const generateVersionOptions = (
  version: string | undefined | null,
): ReactSelectOption[] => {
  if (typeof version !== "string") {
    return [
      { value: INITIAL_VERSION_0, label: INITIAL_VERSION_0 },
      { value: INITIAL_VERSION_1, label: INITIAL_VERSION_1 },
    ];
  }

  const [major, minor, patch]: number[] = version.split(".").map(Number);

  const options: ReactSelectOption[] = [];
  for (let i = 0; i < 3; i++) {
    const nextPatch = patch + i + 1;
    if (nextPatch <= 99) {
      options.push({
        value: `${major}.${minor}.${nextPatch}`,
        label: `${major}.${minor}.${nextPatch}`,
      });
    }
  }

  const nextMinor = minor + 1;
  if (nextMinor <= 99) {
    options.push({
      value: `${major}.${nextMinor}.0`,
      label: `${major}.${nextMinor}.0`,
    });
  }

  const nextMajor = major + 1;
  if (nextMajor <= 99) {
    options.push({
      value: `${nextMajor}.0.0`,
      label: `${nextMajor}.0.0`,
    });
  }

  return options;
};
