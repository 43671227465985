import { createSlice } from "@reduxjs/toolkit";

export interface SystemSetting {
  page: number;
  languageId: number;
  type: string;
  context: string;
  value: string;
  tableName: string;
  status: number;
  inputType: string;
}

export interface SystemSettingsState {
  systemSettings: SystemSetting[];
}

export const initialState: SystemSettingsState = {
  systemSettings: [],
};

interface SetSystemSettings {
  payload: SystemSetting[];
}

export const systemSettingsSlice = createSlice({
  name: "system-settings",
  initialState,
  reducers: {
    setSystemSettings: (state, action: SetSystemSettings) => {
      state.systemSettings = action.payload;
    },
  },
});

export const { setSystemSettings } = systemSettingsSlice.actions;

export default systemSettingsSlice.reducer;
