export const getStructure = (
  data: PageDataTreatment | ChildPage
): TreatmentPath[] => {
  return (data?.childPage || []).map((childPage) => {
    const {
      title,
      id,
      category,
      activeSubstance,
      activeSubstanceOffline,
      country,
      childPage: nestedChildPage,
      lead,
    } = childPage;
    const child = nestedChildPage?.length ? getStructure(childPage) : undefined;

    return {
      title,
      id,
      category,
      activeSubstance,
      activeSubstanceOffline,
      country,
      child,
      lead,
    };
  });
};
