import Modal from "react-bootstrap/Modal";
import { QRScanner } from "../../atoms/QRScanner/QRScanner";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  handleClose: () => void;
}

export const CompanySkanerQRModal = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      centered
      size={"lg"}
      fullscreen={"sm-down"}
    >
      <Modal.Header closeButton className={"border-bottom-0 px-4 pt-4 pb-1"}>
        <Modal.Title className="fw-bold text-dark-blue pe-4">
          {t("company_skaner_QRModal_title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={"px-4 py-1"}>
        <QRScanner handleClose={props.handleClose} />
      </Modal.Body>
    </Modal>
  );
};
