import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EventPollState {
  pollId: number | null;
}

export const initialState: EventPollState = {
  pollId: null,
};

export const eventPollSlice = createSlice({
  name: "event-poll",
  initialState,
  reducers: {
    setPollId: (state, action: PayloadAction<number>) => {
      state.pollId = action.payload;
    },
    clearPollId: (state) => {
      state.pollId = null;
    },
  },
});

export const { setPollId, clearPollId } = eventPollSlice.actions;

export default eventPollSlice;
