import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CompanyUserEntity } from "../../molecules/CompanyUserEntity/CompanyUserEntity";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  usePostUsersVerifyMutation,
  UserVerifyTypeEnum,
  VerificationData,
} from "../../../redux/services/usersApi";
import { toast } from "react-toastify";
import { dateEmbellishment } from "../../../Helpers/dateHelpers";
import { User } from "../../../redux/Slice/user-slice";
import { useTranslation } from "react-i18next";

interface CompanyUsersProps {
  data: User[];
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  reFetchCompany: any;
}

export const CompanyUsers = ({
  data,
  handleActive,
  handleFirst,
  isActive,
  isFirst,
  reFetchCompany,
  handleCondition,
}: CompanyUsersProps) => {
  const { id: companyId } = useParams();
  const [showPopupModal, setShowPopupModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const [text, setText] = useState<string>(
    "Aby dokończyć proces osoba otrzymująca dostęp musi potwierdzić zaproszenie klikając w link wysłany e-mailem"
  );
  const [
    postUsersVerify,
    {
      data: postUsersVerifyData,
      error: postUsersVerifyError,
      isSuccess: postUsersVerifyIsSuccess,
    },
  ] = usePostUsersVerifyMutation();

  useEffect(() => {
    if (postUsersVerifyError) {
      toast.error(t("toast_companyUsers_error"));
    }

    if (postUsersVerifyIsSuccess) {
      switch (postUsersVerifyData.data?.messageStatus) {
        case 1:
          setText(
            "Aby dokończyć proces, właściciel konta mailowego musi potwierdzić poprzez kliknięcie w wysłany link"
          );
          setShowPopupModal(true);
          handleActive(false);
          setValue("email", "");
          break;

        case 2:
          setText(
            `Mail już został wysłany ${dateEmbellishment(
              postUsersVerifyData.data.createdAt,
              "-"
            )}`
          );
          setShowPopupModal(true);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [postUsersVerifyError, postUsersVerifyIsSuccess]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VerificationData>({
    defaultValues: {
      id: companyId,
      email: "",
      type: UserVerifyTypeEnum.COMPANY_USER,
    },
  });

  useEffect(() => {
    setValue("id", companyId);
    // eslint-disable-next-line
  }, [companyId]);

  const submitHandler: SubmitHandler<VerificationData> = async (data) => {
    await postUsersVerify({
      data,
    });
  };

  return (
    <Container fluid={true} className={" d-flex flex-column gap-4 p-0"}>
      {isActive ? (
        <Col className={"col-12 border border-1 rounded-2 p-3 gap-3"}>
          <Form
            className={"d-flex flex-column w-100 "}
            onSubmit={handleSubmit(submitHandler)}
          >
            <Row className={""}>
              <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
                Dodawanie dostępu
              </span>
              <ComponentWithDescription
                label={"Mail właściciela konta Alivia*"}
                labelFs={`fs-16 ${
                  errors.email ? "text-danger" : "text-dark-blue"
                }`}
                className="my-2"
              >
                <Form.Control
                  type="text"
                  id="email"
                  aria-describedby="email"
                  className={`w-100 text-dark-blue`}
                  {...register("email", {
                    required: { value: true, message: "Pole wymagane" },
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "Proszę wprowadzić poprawny adres e-mail",
                    },
                  })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </ComponentWithDescription>
            </Row>
            <div className="d-flex gap-2 mt-4 justify-content-end">
              <Button
                className="btn-outline-dark-blue"
                onClick={() => {
                  handleActive(false);
                  if (data === null && isFirst) {
                    handleFirst(true);
                  } else {
                    handleFirst(false);
                  }
                }}
              >
                ANULUJ
              </Button>
              <Button type="submit" value="Submit">
                Dodaj
              </Button>
            </div>
          </Form>
        </Col>
      ) : (
        data?.length > 0 &&
        data.map((value: User, index: number) => (
          <Col key={index} className={"col-12 border border-1 rounded-2 p-3"}>
            <CompanyUserEntity data={value} />
          </Col>
        ))
      )}
      <PopupModal
        type="info"
        text={text}
        show={showPopupModal}
        setShow={setShowPopupModal}
        titleClasses="text-center fs-16 fw-600"
      />
    </Container>
  );
};
