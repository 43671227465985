import { Col, Row, Table } from "react-bootstrap";
import { AgreementLoyaltyProgramTableRow } from "../../AgreementLoyaltyProgramTableRow/AgreementLoyaltyProgramTableRow";
import React from "react";

interface Props {
  data: LoyaltyProgramsAgreementAllData[];
  handleEditData: (data: LoyaltyProgramsAgreementAllData) => void;
}

export const AgreementLoyaltyProgramTable = ({
  data,
  handleEditData,
}: Props) => {
  const thStyles = "container-fluid pb-4 border border-0 text-dark-blue";
  return (
    <>
      <span className={"text-grey-2 fs-14"}>Regulaminy</span>{" "}
      <Table>
        <thead>
          <tr>
            <th className={thStyles}>
              <Row>
                <Col xs={1}>id</Col>
                <Col xs={3}>program</Col>
                <Col xs={3}>type</Col>
                <Col xs={1}>wer.</Col>
                <Col xs={3}>nazwa</Col>
              </Row>
            </th>
          </tr>
        </thead>

        <tbody className="fs-13">
          {data.map((user, idx) => (
            <AgreementLoyaltyProgramTableRow
              key={idx}
              data={user}
              handleEditData={handleEditData}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};
