import styles from "../CategoriesTemplate/CategoriesTemplate.module.scss";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import TextInput from "../../atoms/TextInput/TextInput";
import { Button } from "react-bootstrap";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import FeedTable from "../../organisms/Tables/FeedTable/FeedTable";
import {
  useGetFeedTagsQuery,
  useGetFeedTopicsQuery,
  usePostFeedTagsMutation,
  usePostFeedTopicsMutation,
} from "../../../redux/services/feedApi";
import { createContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export const TypeContext = createContext("");

const FeedCategoryTemplate = ({ type }: FeedCategoryTemplateProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get(`${type}Page`)) || 1
  );

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      name: "",
      searchFilters: "",
    },
  });

  const [postFeedTags] = usePostFeedTagsMutation();
  const [postFeedTopics] = usePostFeedTopicsMutation();

  const { data: feedTagsData, refetch: refetchTags } = useGetFeedTagsQuery({
    offset: currPage ? ((currPage - 1) * 9).toString() : "0",
    limit: "9",
    sortBy: searchParams.get("tagsSortBy"),
    nameLike: searchParams.get("tagsNameLike"),
    delete: 0,
  });
  const { data: feedTopicsData, refetch: refetchTopics } =
    useGetFeedTopicsQuery({
      offset: currPage ? ((currPage - 1) * 9).toString() : "0",
      limit: "9",
      sortBy: searchParams.get("topicsSortBy"),
      nameLike: searchParams.get("topicsNameLike"),
      delete: 0,
    });

  const [tableData, setTableData] = useState<{
    data: IFiltersSource[];
    meta: { count: number };
  }>();
  const handlePageChange = (e: number) => {
    searchParams.set(`${type}Page`, e.toString());
    setSearchParams(searchParams);
    setCurrPage(e);
  };

  useEffect(() => {
    switch (type) {
      case "tags":
        setTableData(feedTagsData);
        break;
      case "topics":
        setTableData(feedTopicsData);
        break;
    }
    // eslint-disable-next-line
  }, [feedTopicsData?.data, feedTagsData?.data]);

  const onSubmit = async () => {
    if (type === "tags") {
      await postFeedTags({
        data: {
          tag: watch("name"),
          status: 1,
        },
      });
    } else if (type === "topics") {
      await postFeedTopics({
        data: {
          topic: watch("name"),
          status: 1,
        },
      });
    }

    refetchTags();
    refetchTopics();
  };

  return (
    <TypeContext.Provider value={type}>
      <div className="mt-2 px-4 w-100">
        <h1 className="pe-3 fs-2 my-3">
          {type === "tags" && "Tagi"}
          {type === "topics" && "Tematy"} w społeczności
        </h1>
        <div className="row w-100 ">
          <form
            className={`my-4 my-md-0 ${styles.categoriesForm}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ComponentWithDescription
              className="my-2"
              label="Wyświetlana nazwa"
              desc="Nazwa jak pojawia się w witrynie."
            >
              <TextInput register={register("name")} />
            </ComponentWithDescription>
            <div className="col-auto">
              <Button type="submit" value="Submit">
                DODAJ
              </Button>
            </div>
          </form>
          <div className="col  bd-highlight">
            <div className="d-flex justify-content-end mb-4">
              <form
                className="row g-3"
                onSubmit={handleSubmit((data) => {
                  searchParams.set(`${type}NameLike`, data.searchFilters);
                  setSearchParams(searchParams);
                })}
              >
                <div className="col-auto">
                  <input
                    type="text"
                    className="form-control"
                    {...register("searchFilters")}
                  />
                </div>
                <div className="col-auto">
                  <Button
                    className="btn-outline-blue"
                    type="submit"
                    value="Submit"
                  >
                    Szukaj filtrów
                  </Button>
                </div>
              </form>
            </div>
            <FeedTable data={tableData?.data} type={type} />
            <CustomPagination
              totalCount={(!!tableData && tableData.meta.count) || 0}
              pageSize={9}
              currentPage={currPage}
              onPageChange={(e: number) => handlePageChange(e)}
            />
          </div>
        </div>
      </div>
    </TypeContext.Provider>
  );
};

export default FeedCategoryTemplate;
