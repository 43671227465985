import { LoyaltyProgramServiceDataInterface } from "components/templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../UserViewTableRow/UserViewTableRow.module.scss";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import Breadcrumb from "components/molecules/Breadcrumb/Breadcrumb";
import { useState } from "react";
import { useGetCompanyQuery } from "redux/services/companyApi";
import { Company } from "components/molecules/LoyaltyProgramServiceArticle/types";
import { dateEmbellishment } from "Helpers/dateHelpers";
import { isHTML } from "Helpers/isHTML";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  data: LoyaltyProgramServiceDataInterface;
}

interface CompanyData {
  data: {
    data: Company;
  };
}

const ServiceViewTableRow = ({ data }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const { data: companyData } = useGetCompanyQuery<CompanyData>(
    data?.company?.id?.toString()
  );

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.COMPANIES}/${data?.id}/${NAV_PATH.SERVICE}/edit`,
    },
    {
      name: "Zobacz benefit",
      path: `/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${data?.id}`,
    },
  ];

  return (
    <>
      <tr
        aria-controls="example-collapse-text"
        className={`  ${styles.mainTr}`}
      >
        <td
          className={`container-fluid ${data?.deleted && styles.bg} ${
            styles.mainTd
          }`}
          colSpan={4}
        >
          <Row className="pb-2 bg-transpatent">
            <Col xs={2} className="text-dark-blue text-break">
              {data?.id}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {data?.name}
            </Col>
            <Col xs={4} className="text-dark-blue text-break">
              {data?.company?.name}
            </Col>
            <Col xs={2} className="text-dark-blue text-break">
              {data?.status ? "Potwierdzone" : "Niepotwierdzone"}
            </Col>

            <Col className="d-flex justify-content-end align-content-start gap-3 text-dark-blue">
              <Button
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                  isActive
                    ? "text-light bg-dark"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prevState) => !prevState)}
              >
                <Arrow
                  className={isActive ? styles.arrowUp : styles.arrowDown}
                />
              </Button>
            </Col>
          </Row>
          {isActive && (
            <Row className="mb-2">
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Nazwa: </span>
                <span className={"fw-semibold"}>{data?.name}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Kategoria benefitu: </span>
                {data?.priorityCategory?.length > 0
                  ? data?.priorityCategory?.map(({ name }) => (
                      <span
                        key={name}
                        className={"fw-semibold"}
                      >{`${name}, `}</span>
                    ))
                  : "Brak kategorii"}
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Lokalizacja: </span>
                {companyData?.data?.companyDepartment?.map(
                  ({ city, street, zipCode }) => (
                    <span
                      key={`${city?.name}-${street}-${zipCode}`}
                      className={"fw-semibold"}
                    >
                      {`${zipCode} ${city?.name}, ${street};`}
                    </span>
                  )
                )}
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Kody wielorazowe: </span>
                <span className={"fw-semibold"}>
                  {data?.multipleUseCodesAllowed === 1 ? "Tak" : "Nie"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Dostępne kody: </span>
                <span className={"fw-semibold"}>{data?.codeUsageCount}</span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Dni do końca: </span>
                <span className={"fw-semibold"}>
                  {data?.daysToEnd ?? "brak ograniczenia"}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Początek: </span>
                <span className={"fw-semibold"}>
                  {dateEmbellishment(data?.startAt, "-")}
                </span>
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Koniec: </span>
                <span className={"fw-semibold"}>
                  {dateEmbellishment(data?.endAt, "-")}
                </span>
              </Col>
              <Col xs={12} as={"p"} className="text-dark-blue d-flex gap-1">
                <span className="align-self-center">Opis: </span>
                {data?.description ? (
                  isHTML(data?.description) ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    />
                  ) : (
                    <span className={"fw-semibold"}>{data?.description}</span>
                  )
                ) : (
                  <span className={"fw-semibold"}>brak opisu</span>
                )}
              </Col>
              <Col xs={12} as={"p"} className="text-dark-blue d-flex gap-1">
                <span className="align-self-center">Warunki umowy: </span>
                {data?.conditions ? (
                  isHTML(data?.conditions) ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: data?.conditions }}
                    />
                  ) : (
                    <span className={"fw-semibold"}>{data?.conditions}</span>
                  )
                ) : (
                  <span className={"fw-semibold"}>brak warunków umowy</span>
                )}
              </Col>
              <Col xs={12} as={"p"} className={"text-dark-blue"}>
                <span>Ostatnia zmiana: </span>
                <span className={"fw-semibold"}>
                  {dateEmbellishment(data?.updatedAt, "-")}
                </span>
              </Col>
            </Row>
          )}
          <Row className={`bg-transparent ${styles.hoverRow}`}>
            <Col>
              <Breadcrumb data={breadcrumbData} />
            </Col>
          </Row>
        </td>
      </tr>
    </>
  );
};

export default ServiceViewTableRow;
