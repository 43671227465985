import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useGetPagesCategoryQuery,
  usePostPagesCategoryMutation,
} from "../../../../redux/services/pagesCategoryApi";
import SelectInput from "../../../atoms/SelectInput/SelectInput";
import TextInput from "../../../atoms/TextInput/TextInput";
import ComponentWithDescription from "../../ComponentWithDescription/ComponentWithDescription";

const CategoryCollapse = () => {
  const { data: pagesCategoryData } = useGetPagesCategoryQuery({});

  const [postPagesCategory] = usePostPagesCategoryMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      title: "",
      content: "",
      url: "",
      parent_id: {},
      status: 0,
      created_by: 2,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const postData = {
          title: data.title,
          content: data.content,
          url: data.url,
          parent_id: data.parent_id,
          status: data.status,
          created_by: data.created_by,
          type: "page",
        };

        postPagesCategory({ data: postData });
      })}
    >
      <ComponentWithDescription className="my-2" label="NAZWA NOWEJ KATEGORII">
        <TextInput
          register={register("title", {
            required: true,
          })}
        />
      </ComponentWithDescription>
      <ComponentWithDescription className="my-2" label="KATEGORIA NADRZĘDNA">
        <SelectInput
          register={register("parent_id")}
          data={pagesCategoryData?.data}
          defaultValue="— Kategoria nadrzędna —"
        />
      </ComponentWithDescription>
      <Button className="btn-outline-blue" type="submit" value="Submit">
        Utwórz kategorię
      </Button>
    </form>
  );
};

export default CategoryCollapse;
