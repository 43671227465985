import BundledEditor from "BundledEditor";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AddInformationModal from "../AddInformationModal/AddInformationModal";
import MediaModal from "../MediaModal/MediaModal";
import { Editor as TinyMCEEditor } from "tinymce";
import styles from "./TinyEditor.module.scss";

const TinyEditor = ({
  setValue,
  data,
  name,
  image,
  type = "full",
  reqiared = false,
  targetBlank,
  label,
  labelClassName,
}: TinyEditorProps) => {
  const [content, setContent] = useState(data);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    setContent(data);
  }, [data]);

  const handleEditorChange = (content: any) => {
    setValue(name, content);
    setContent(content);
  };

  const addCode = (code: string) => {
    editorRef?.current?.execCommand("mceInsertContent", false, code);
  };

  return (
    <>
      <div className="d-flex flex-colum gap-3">
        {label && (
          <span
            className={`${styles.labelMargin} me-3 mb-1 w-100 ${labelClassName}`}
          >
            {label}
          </span>
        )}
        {image && (
          <Button className="mb-2" onClick={() => setShowMediaModal(true)}>
            Dodaj obraz
          </Button>
        )}

        {image && (
          <Button
            className="mb-2"
            onClick={() => setShowInformationModal(true)}
          >
            Dodaj informację
          </Button>
        )}
      </div>

      <MediaModal
        setContent={addCode}
        show={showMediaModal}
        setShow={setShowMediaModal}
        data={typeof data !== "string" ? data : undefined}
      />

      <AddInformationModal
        setContent={addCode}
        show={showInformationModal}
        setShow={setShowInformationModal}
      />
      <BundledEditor
        onInit={(evt: Event, editor: TinyMCEEditor) =>
          (editorRef.current = editor)
        }
        value={content}
        onEditorChange={handleEditorChange}
        init={{
          isRequired: reqiared,
          height: name === "content" ? 470 : 300,
          forced_root_block: "div",
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "charmap",
            "preview",
            "searchreplace",
            "visualblocks",
            "fullscreen",
            "insertdatetime",
            "wordcount",
            "importcss",
            type !== "small" && "media",
            type !== "small" && "image",
            type !== "small" && "code",
            type !== "small" && "anchor",
          ].filter((n) => n),
          link_default_target: targetBlank ? "_blank" : "",
          toolbar:
            name === "content"
              ? "undo redo | blocks | " +
                "fontsize bold italic forecolor  blockquote  alignleft aligncenter" +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "link image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol" +
                ""
              : "undo redo | blocks | " +
                "fontsize bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | ",

          image_dimensions: true,
          image_uploadtab: false,
          images_upload_credentials: true,
          a11y_advanced_options: true,
          setup: function (editor: any) {
            editor.ui.registry.addButton("customInsertButton", {
              text: "Nowy cytat",
              icon: "quote",
              onAction: function (_: any) {
                const selected = editor.selection.getNode();
                // editor.selection.setContent("");
                editor.insertContent(
                  "<blockquote class='new'>" +
                    editor.serializer.serialize(selected) +
                    "</blockquote>"
                );
              },
            });
          },
          formats: {
            blockquote: [
              {
                block: "blockquote",
                attributes: { class: "center" },
                wrapper: true,
                remove: "all",
              },
              {
                block: "blockquote",
                attributes: { class: "new" },
                wrapper: true,
              },
              { block: "blockquote", attributes: { class: "pull-left" } },
              { block: "blockquote", attributes: { class: "pull-right" } },
            ],
            pullquote_left: {
              selector: "blockquote",
              attributes: { class: "pull-left" },
              remove: "all",
            },
            pullquote_right: {
              selector: "blockquote",
              attributes: { class: "pull-right" },
              remove: "all",
            },
            pullquote_center: {
              selector: "blockquote",
              attributes: { class: "center" },
              remove: "all",
            },
          },
          content_style: `
          
          body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
            blockquote {
              border-left: none !important;
              margin-left: none !important;
              border: 1px solid #EBEBEB;
              border-radius: 24px;
              background-color: #f8f9fa;
              padding: 20px;
              position: relative;
            }
            blockquote.new {
              border-left: none !important;
              margin-left: none !important;
              border-radius:0;
              border: 0;
              background-color: unset;
              position: relative;
              padding: 0;
            }
            blockquote.new::before {
              border-left: 4px solid #ED2369!important;
              border-radius: 5px;
              border: 0;
              height:100%;
              background-color: unset;
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: -15px;
            }

            `,
        }}
      />
    </>
  );
};

export default TinyEditor;
