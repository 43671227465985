import { doctorDegree } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetDoctorDegreesSearchParams {
    nameLike?: string;
    changes?: string;
    offset?: number;
    limit?: number;
}

const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: ["DoctorDegrees"],
});

export const doctorDegreeApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({

        getDoctorDegrees: builder.query({

            query: ({ nameLike, changes, offset, limit }: GetDoctorDegreesSearchParams) => {
                const params = new URLSearchParams({
                    ...(nameLike && { nameLike: nameLike.toString() }),
                    ...(changes && { changes: changes.toString() }),
                    ...(offset && { offset: offset.toString()}),
                    ...(limit && { limit: limit.toString()}),
                });

                return {
                    url: `${doctorDegree}?${params.toString}`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            },
            providesTags: ["DoctorDegrees"],
        }),
    }),
});

export const { useGetDoctorDegreesQuery, useLazyGetDoctorDegreesQuery } = doctorDegreeApi;