import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { Persistor, store } from "./redux/store/index";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.min.css";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-M72JSZ4D",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persistor}>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          {/* <MopinionFormLoader formKey="305mhs6hXw5Es4TTnfKl1JUpafr1QEI179hGJemN" /> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
