import { ReactNode } from "react";

interface SectionTitleProps {
  className?: string;
  children?: ReactNode;
}

const SectionTitle = ({ className = "", children }: SectionTitleProps) => (
  <h2 className={`px-0 fs-1 lh-base fw-600 text-dark ${className}`}>
    {children}
  </h2>
);

export default SectionTitle;
