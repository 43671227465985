import { Badge, Button, Dropdown } from "react-bootstrap";
import { Gear } from "react-bootstrap-icons";
import TextInput from "components/atoms/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import styles from "./AccountRoleWidget.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/index";
import { SystemSetting } from "redux/Slice/system-settings-slice";
import { useGetCurrentDoctorQuery } from "redux/services/doctorApi";
import { useRemoveRole } from "Helpers/Roles/useRemoveRole";

const AccountRoleWidget = ({
  status,
  surveyButtonAction,
  role,
}: AccountRoleWidgetProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const { data: doctorData, isLoading } = useGetCurrentDoctorQuery({});
  const removeRole = useRemoveRole();
  const { systemSettings } = useSelector(
    (state: RootState) => state.systemSettings,
  );
  const setting =
    systemSettings &&
    systemSettings.find(
      (systemSetting: SystemSetting) => systemSetting?.context === role.role,
    );

  const { t } = useTranslation();

  const onRemoveRoleClick = () => {
    if (role.role === "doctor") {
      removeRole(authUser, role, doctorData?.id);
    } else {
      removeRole(authUser, role);
    }
  };

  const onUpdatePollClick = () => {
    surveyButtonAction(role.role);
  };

  return (
    <div
      className={`d-flex p-4 flex-row mt-4 ${
        status === 0 ? "text-grey-2" : ""
      }`}
      style={{ border: "1px solid #E9ECEF", borderRadius: "4px" }}
    >
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          <div className="d-flex flex-column">
            {!isLoading &&
              status &&
              status !== 2 &&
              doctorData?.data?.status !== "ACCEPTED" &&
              role.role === "doctor" && (
                <>
                  <Badge
                    className={`bg-secondary w-auto fw-medium lh-130 mb-1 ${styles.badge}`}
                  >
                    {status === 0 ? "Zawieszone" : "Oczekuje weryfikacji"}
                  </Badge>
                </>
              )}
            <p className="fw-600 fs-19 m-0 text-dark-blue">
              Konto {role.role === "doctor" && "doktora"}
              {role.role === "sponsor" && "partnera"}
              {role.role === "patient" && "pacjenta"}
              {role.role === "guardian" && "opiekuna"}
            </p>
          </div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant="transparent"
                id="dropdown-basic"
                className={`btn-alivia ${styles.dropdownToggle}`}
              >
                <Gear size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={onRemoveRoleClick}>
                  Usuń rolę
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <p className="fw-400 fs-16 w-100 mb-3 text-dark-blue">
          {t("moja_roles_" + role.role + "_long")}
        </p>
        {role.role === "doctor" && (
          <TextInput
            label="Służbowy telefon"
            inputClassName="bg-gray"
            className="fs-16 fw-400 flex-column w-100 mb-3 mt-1"
            labelClassName="fw-400 fs-16 text-dark-blue"
            type="EventInput"
            placeholder={doctorData?.data?.phone ? doctorData?.data?.phone : ""}
          />
        )}
        {status !== 0 && setting && setting?.value && (
          <Button
            variant="outline-primary"
            className="btn-outline-primary w-100 text-uppercase mt-2"
            onClick={onUpdatePollClick}
          >
            ZAKTUALIZUJ ANKIETĘ
          </Button>
        )}
      </div>
    </div>
  );
};

export default AccountRoleWidget;
