import {
  UseFieldArrayRemove,
  useFormContext,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { POLL_META_DATA, POLL_PAGE_QUESTION_DATA } from "Helpers/pollsData";
import { useEffect, useState } from "react";
import { CancelModal } from "components/molecules/CancelModal/CancelModal";
import { Draggable } from "react-beautiful-dnd";

import AccordionToggleButton from "components/atoms/AccordionToggleButton/AccordionToggleButton";
import PollMeta from "../PollMeta/PollMeta";
import PollMetaAnswers from "../PollMetaAnswers/PollMetaAnswers";
import styles from "./PollQuestion.module.scss";
import { v4 as uuidv4 } from "uuid";
import TinyEditor from "../TinyEditor/TinyEditor";
import {
  areFieldOptionsNeeded,
  areLabelsSwitchNeeded,
  isAgreement,
  isFieldTypeList,
  isOutputTableNameMetaAvailable,
  isPlaceholderAvailable,
  isSfSynchroAvailable,
} from "Helpers/pollMetaHandlers";

interface PollQuestionProps {
  id: string;
  pageIndex: number;
  index: number;
  remove: UseFieldArrayRemove;
  isAccordionActive: boolean;
  changeAccordionStatus: () => void;
  openAccordion: (questionIndex: number) => void;
  deleteAccordionStatus: () => void;
}

const PollQuestion = ({
  id,
  pageIndex,
  index,
  remove,
  isAccordionActive,
  changeAccordionStatus,
  openAccordion,
  deleteAccordionStatus,
}: PollQuestionProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    control,
    register,
    setValue,
    formState: { isSubmitting, errors },
  }: UseFormReturn<PollPageFormValues> = useFormContext();

  const [
    description,
    sfSynchro,
    fieldType,
    metaTableName,
    isActiveValue,
    isOutputTableName,
  ] = useWatch({
    control,
    name: [
      `pages.${pageIndex}.questions.${index}.description`,
      `pages.${pageIndex}.questions.${index}.sfSynchro`,
      `pages.${pageIndex}.questions.${index}.fieldType`,
      `pages.${pageIndex}.questions.${index}.meta.tableName`,
      `pages.${pageIndex}.questions.${index}.isActive`,
      `pages.${pageIndex}.questions.${index}.isOutputTableName`,
    ],
  });

  useEffect(() => {
    if (!isAccordionActive && isSubmitting) {
      openAccordion(index);
    }
    // eslint-disable-next-line
  }, [isSubmitting]);

  const hideModalHandler = () => setShowModal(false);
  const showModalHandler = () => setShowModal(true);

  const modalAcceptanceHandler = (index: number) => {
    remove(index);
    deleteAccordionStatus();
    hideModalHandler();
  };

  const canThereBePlaceholder = isPlaceholderAvailable(fieldType);

  const questionErrors = errors?.pages?.[pageIndex]?.questions;

  const formGroupStyles = "mb-3";
  const labelStyles = "mb-1 fs-14 lh-base";
  const formCheckStyles = "mb-0 py-2";

  return (
    <>
      <Draggable key={id} index={index} draggableId={id}>
        {(questionProvided) => (
          <div
            ref={questionProvided.innerRef}
            {...questionProvided.draggableProps}
          >
            <Accordion defaultActiveKey={isAccordionActive ? "0" : null}>
              <Card className="m-3">
                <Card.Header {...questionProvided.dragHandleProps}>
                  <div className="lg-d-flex justify-content-between align-items-center">
                    <Form.Group className="mb-2">
                      <Form.Label
                        htmlFor={`q-${id}-${index + 1}-question`}
                        className="d-none"
                      >
                        Treść pytania {index + 1}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>{index + 1}</InputGroup.Text>
                        <Form.Control
                          id={`q-${id}-${index + 1}-question`}
                          {...register(
                            `pages.${pageIndex}.questions.${index}.question`,
                            {
                              required: !isAgreement(fieldType)
                                ? "Podaj treść pytania."
                                : false,
                            }
                          )}
                          isInvalid={
                            !isAgreement(fieldType)
                              ? !!questionErrors?.[index]?.question
                              : false
                          }
                          placeholder={
                            isAgreement(fieldType)
                              ? "Treść pytania (opcjonalne)"
                              : "Treść pytania"
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    <div
                      className={`d-flex ${
                        index > 0
                          ? "justify-content-between"
                          : "justify-content-end"
                      }`}
                    >
                      {index > 0 && (
                        <Button variant="danger" onClick={showModalHandler}>
                          Usuń pytanie
                        </Button>
                      )}
                      <AccordionToggleButton
                        isActive={isAccordionActive}
                        onClick={changeAccordionStatus}
                        className="ms-2"
                      />
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <>
                    <Card.Body className="border-2 border-bottom">
                      <Form.Group className={formGroupStyles}>
                        <p className={`${labelStyles} fs-6`}>
                          Opis (opcjonalne)
                        </p>
                        <TinyEditor
                          data={description}
                          setValue={setValue}
                          name={`pages.${pageIndex}.questions.${index}.description`}
                        />
                      </Form.Group>
                      {/* <Form.Group className={formGroupStyles}>
                        <Form.Label
                          htmlFor={`q-${id}-${index + 1}-type`}
                          className={labelStyles}
                        >
                          Typ (opcjonalne)
                        </Form.Label>
                        <Form.Control
                          id={`q-${id}-${index + 1}-type`}
                          {...register(
                            `pages.${pageIndex}.questions.${index}.type`
                          )}
                        />
                      </Form.Group> */}
                      {/* <Form.Group className={formGroupStyles}>
                        <Form.Label
                          htmlFor={`q-${id}-${index + 1}-target`}
                          className={labelStyles}
                        >
                          Cel (opcjonalne)
                        </Form.Label>
                        <Form.Control
                          id={`q-${id}-${index + 1}-target`}
                          {...register(
                            `pages.${pageIndex}.questions.${index}.target`
                          )}
                        />
                      </Form.Group> */}
                    </Card.Body>
                    <Card.Body className="border-2 border-bottom">
                      <Container fluid className="px-0">
                        <Row>
                          {isSfSynchroAvailable(fieldType) && (
                            <Col lg={6}>
                              <Form.Group
                                className={`${styles.formSwitch} ${formGroupStyles}`}
                              >
                                <Form.Check
                                  className="mb-0"
                                  type="switch"
                                  id={`q-${id}-${index + 1}-sfSynchro`}
                                  label="Synchronizacja z Salesforce"
                                  {...register(
                                    `pages.${pageIndex}.questions.${index}.sfSynchro`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          )}
                          {sfSynchro && (
                            <Col lg={6}>
                              <Form.Group className={formGroupStyles}>
                                <Form.Label
                                  htmlFor={`q-${id}-${index + 1}-fieldName`}
                                  className={labelStyles}
                                >
                                  Nazwa pola
                                </Form.Label>
                                <Form.Control
                                  id={`q-${id}-${index + 1}-fieldName`}
                                  {...register(
                                    `pages.${pageIndex}.questions.${index}.fieldName`,
                                    {
                                      required: "Pole nie może być puste.",
                                    }
                                  )}
                                  isInvalid={
                                    !!questionErrors?.[index]?.fieldName
                                  }
                                />
                                {questionErrors?.[index]?.fieldName && (
                                  <Form.Control.Feedback type="invalid">
                                    {
                                      questionErrors?.[index]?.fieldName
                                        ?.message
                                    }
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className={formGroupStyles}>
                              <Form.Label
                                htmlFor={`q-${id}-${index + 1}-fieldType`}
                                className={labelStyles}
                              >
                                Typ pola
                              </Form.Label>
                              <Form.Select
                                id={`q-${id}-${index + 1}-fieldType`}
                                {...register(
                                  `pages.${pageIndex}.questions.${index}.fieldType`,
                                  {
                                    required:
                                      "Wybierz typ odpowiedzi na pytanie.",
                                    pattern: {
                                      value:
                                        /^(text|textarea|radio|checkbox|rating-radio|boolean-radio|agreement|select|datalist|facilities-select|datetime-local|date|birth-date|time|email|tel|attachments|number|range|header)$/,
                                      message:
                                        "Wybierz typ odpowiedzi na pytanie.",
                                    },
                                  }
                                )}
                                defaultValue=""
                                isInvalid={!!questionErrors?.[index]?.fieldType}
                              >
                                <option value={""} disabled>
                                  Wybierz
                                </option>
                                {POLL_PAGE_QUESTION_DATA.FIELD_TYPES?.map(
                                  (ft: FieldTypeData, idx: number) => (
                                    <option key={idx} value={ft.value}>
                                      {ft.label}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                              {questionErrors?.[index]?.fieldType && (
                                <Form.Control.Feedback type="invalid">
                                  {questionErrors?.[index]?.fieldType?.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className={formGroupStyles}>
                              <Form.Label
                                htmlFor={`q-${id}-${index + 1}-fieldSize-lg`}
                                className={labelStyles}
                              >
                                Szerokość pytania - desktop
                              </Form.Label>
                              <Form.Select
                                id={`q-${id}-${index + 1}-fieldSize-lg`}
                                {...register(
                                  `pages.${pageIndex}.questions.${index}.meta.fieldSize.lg`,
                                  {
                                    required:
                                      "Wybierz szerokość pytania na urządzeniach desktopowych.",
                                    pattern: {
                                      value: /^(12|9|8|6|4|3)?$/,
                                      message:
                                        "Wybierz poprawną szerokość pytania.",
                                    },
                                  }
                                )}
                                isInvalid={
                                  !!questionErrors?.[index]?.meta?.tableName
                                }
                              >
                                {POLL_META_DATA.FIELD_SIZES?.map(
                                  (fs: FieldSizeData, idx: number) => (
                                    <option key={idx} value={fs.value}>
                                      {fs.label}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                              {questionErrors?.[index]?.meta?.tableName && (
                                <Form.Control.Feedback type="invalid">
                                  {
                                    questionErrors?.[index]?.meta?.tableName
                                      ?.message
                                  }
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          {isFieldTypeList(fieldType) && (
                            <Col lg={6}>
                              <Form.Group className={formGroupStyles}>
                                <Form.Label
                                  htmlFor={`q-${id}-${index + 1}-tableName`}
                                  className={labelStyles}
                                >
                                  Grupa listy
                                </Form.Label>
                                <Form.Select
                                  id={`q-${id}-${index + 1}-tableName`}
                                  {...register(
                                    `pages.${pageIndex}.questions.${index}.meta.tableName`,
                                    {
                                      pattern: {
                                        value:
                                          /^(city|doctor|doctor_specialization|facility|oi_cancer|province)?$/,
                                        message:
                                          "Wybierz poprawną grupę listy.",
                                      },
                                    }
                                  )}
                                  defaultValue=""
                                  isInvalid={
                                    !!questionErrors?.[index]?.meta?.tableName
                                  }
                                >
                                  <option value={""}>Brak</option>
                                  {POLL_META_DATA.TABLE_NAMES?.map(
                                    (tn: TableNameData, idx: number) => (
                                      <option key={idx} value={tn.value}>
                                        {tn.label}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                {questionErrors?.[index]?.meta?.tableName && (
                                  <Form.Control.Feedback type="invalid">
                                    {
                                      questionErrors?.[index]?.meta?.tableName
                                        ?.message
                                    }
                                  </Form.Control.Feedback>
                                )}
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className={formGroupStyles}>
                              <p className={labelStyles}>Status</p>
                              {POLL_PAGE_QUESTION_DATA.STATUSES.map(
                                (s, idx) => (
                                  <Form.Check
                                    className={formCheckStyles}
                                    key={idx}
                                    inline
                                    {...register(
                                      `pages.${pageIndex}.questions.${index}.isActive`,
                                      {
                                        required: "Wybierz wartość.",
                                      }
                                    )}
                                    type="radio"
                                    id={`q-${id}-${index + 1}-isActive`}
                                    label={s.label}
                                    value={s.value}
                                    defaultChecked={
                                      s.value === isActiveValue ?? false
                                    }
                                    isInvalid={
                                      !!questionErrors?.[index]?.isActive
                                    }
                                  />
                                )
                              )}
                              {questionErrors?.[index]?.isActive && (
                                <Form.Control.Feedback type="invalid">
                                  {questionErrors?.[index]?.isActive?.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          {canThereBePlaceholder && (
                            <Col lg={6}>
                              <Form.Group className={formGroupStyles}>
                                <Form.Label
                                  htmlFor={`q-${id}-${
                                    index + 1
                                  }-meta-placeholder`}
                                  className={labelStyles}
                                >
                                  Tekst pomocniczy (opcjonalne)
                                </Form.Label>
                                <Form.Control
                                  id={`q-${id}-${index + 1}-meta-placeholder`}
                                  {...register(
                                    `pages.${pageIndex}.questions.${index}.meta.placeholder`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                        {areLabelsSwitchNeeded(fieldType, metaTableName) && (
                          <Row>
                            <Col lg={6}>
                              <Form.Group className={`${styles.formSwitch}`}>
                                <Form.Check
                                  className="mb-0"
                                  type="switch"
                                  id={`q-${id}-${index + 1}-areLabelsNeeded`}
                                  label="Różne wartości i etykiety"
                                  {...register(
                                    `pages.${pageIndex}.questions.${index}.areLabelsNeeded`
                                  )}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          {isOutputTableNameMetaAvailable(
                            fieldType,
                            metaTableName
                          ) && (
                            <>
                              <Col lg={6}>
                                <Form.Group
                                  className={`${styles.formSwitch} ${formGroupStyles}`}
                                >
                                  <Form.Check
                                    className="mb-0"
                                    type="switch"
                                    id={`q-${id}-${
                                      index + 1
                                    }-isOutputTableName`}
                                    label="Zbieranie danych do opinii"
                                    {...register(
                                      `pages.${pageIndex}.questions.${index}.isOutputTableName`
                                    )}
                                  />
                                </Form.Group>
                              </Col>
                              {isOutputTableName && (
                                <Col lg={6}>
                                  <Form.Group className={formGroupStyles}>
                                    <Form.Label
                                      htmlFor={`q-${id}-${
                                        index + 1
                                      }-meta-outputTableName`}
                                      className={labelStyles}
                                    >
                                      Miejsce zbieranych danych
                                    </Form.Label>
                                    <Form.Select
                                      id={`q-${id}-${
                                        index + 1
                                      }-meta-outputTableName`}
                                      {...register(
                                        `pages.${pageIndex}.questions.${index}.meta.outputTableName`,
                                        {
                                          pattern: {
                                            value:
                                              /^(doctor_reviews|facility_reviews)?$/,
                                            message:
                                              "Wybierz poprawne miejsce gromadzenia danych.",
                                          },
                                        }
                                      )}
                                      defaultValue=""
                                      isInvalid={
                                        !!questionErrors?.[index]?.meta
                                          ?.outputTableName
                                      }
                                    >
                                      <option value={""}>Wybierz</option>
                                      {POLL_META_DATA.OUTPUT_TABLE_NAMES?.map(
                                        (
                                          otn: OutputTableNameData,
                                          idx: number
                                        ) => (
                                          <option key={idx} value={otn.value}>
                                            {otn.label}
                                          </option>
                                        )
                                      )}
                                    </Form.Select>
                                    {questionErrors?.[index]?.meta
                                      ?.outputTableName && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          questionErrors?.[index]?.meta
                                            ?.outputTableName?.message
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>
                              )}
                            </>
                          )}
                        </Row>
                      </Container>
                    </Card.Body>
                    {areFieldOptionsNeeded(fieldType, metaTableName) && (
                      <Card.Body className="border-2 border-bottom">
                        <PollMetaAnswers
                          pageIndex={pageIndex}
                          questionIndex={index}
                        />
                      </Card.Body>
                    )}
                    <Card.Body>
                      <PollMeta
                        id={uuidv4()}
                        pageIndex={pageIndex}
                        questionIndex={index}
                      />
                    </Card.Body>
                  </>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
      </Draggable>

      <CancelModal
        title="Komunikat"
        content="Czy na pewno chcesz usunąć pytanie?"
        cancelText="Nie"
        confirmText="Tak"
        show={showModal}
        onHide={hideModalHandler}
        onConfirm={() => modalAcceptanceHandler(index)}
      />
    </>
  );
};

export default PollQuestion;
