import { Button, Col, Container, Stack } from "react-bootstrap";
import styles from "../../templates/ChangesCompanyTemplate/ChangesCompanyTemplate.module.scss";
import { RejectModal } from "../RejectModal/RejectModal";
import React, { useEffect, useState } from "react";
import { SeparationUpdate } from "../../atoms/SeparationUpdate/SeparationUpdate";

interface Props {
  name: string;
  data: any;
  keyName: string;
  changes: any;
  handlePatch?: (obj: { [key: string]: any }, keyName: string) => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

export const ChangesContact = (props: Props) => {
  const [showReject, setShowReject] = useState(false);
  const [contact, setContact] = useState<string[] | null>(null);
  const [contactChanges, setContactChanges] = useState<string[] | null>(null);

  useEffect(() => {
    if (props.data?.emailAddresses) {
      setContact(JSON.parse(props.data.emailAddresses));
    } else {
      setContact(null);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.changes?.emailAddresses) {
      setContactChanges(JSON.parse(props.changes.emailAddresses));
    } else {
      setContactChanges(null);
    }
  }, [props.changes]);

  const handleComments = (comment: string) => {
    if (props.handleComments) {
      props.handleComments(comment);
    }
  };

  const handlePatch = () => {
    const obj: { [key: string]: string } = {};

    const key = props?.changes?.[props.keyName];

    obj[props.keyName] = typeof key !== "string" ? JSON.stringify(key) : key;
    if (props.handlePatch) {
      props.handlePatch(obj, props.keyName);
    }
  };

  return (
    <Stack gap={3}>
      <Col className={`${props.isAdmin && styles.wrapper}`}>
        <Container fluid={true}>
          <span className={"fs-22 fw-semibold text-dark-blue"}>
            {props.name}
          </span>
          <Col>
            {contact?.length &&
              contact?.map((value: string, index: number) => {
                return (
                  <div key={index} className={"mt-3"}>
                    {value}
                  </div>
                );
              })}
          </Col>
        </Container>
      </Col>
      {!!props.changes?.[props.keyName] && (
        <>
          <SeparationUpdate />
          <Col className={`${props.isAdmin && styles.wrapper} text-danger`}>
            <span className={"fs-22 fw-semibold"}>{props.name}</span>
            {contactChanges !== null &&
              contactChanges.map((value: string, index: number) => (
                <p key={index} className={"m-0 mt-3"}>
                  {value}
                </p>
              ))}
          </Col>
        </>
      )}
      {props.isAdmin && !!props.changes?.[props.keyName] && (
        <Col className={"d-flex justify-content-end gap-2"}>
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowReject((prev) => !prev)}
          >
            ODRZUĆ
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev) => !prev)}
            handleComments={handleComments}
            comments={
              props.changes?.comments
                ? `${props.changes.comments}; ${props.name}: `
                : `${props.name}: `
            }
          />
          <Button className={" fs-16"} onClick={handlePatch}>
            AKCEPTUJ
          </Button>
        </Col>
      )}
    </Stack>
  );
};
