import { EventCard } from "components/molecules/EventCard/EventCard";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useGetEventsQuery } from "redux/services/eventsApi";
import { EventData } from "../AddEventModal/types";
import { useGetEventTypesQuery } from "redux/services/eventTypesApi";
import { RootState } from "redux/store/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./IncomingEvents.module.scss";
import { NAV_PATH } from "../Navigation/navigationData";

const IncomingEvents = () => {
  const navigate = useNavigate();
  const { authUser } = useSelector((state: RootState) => state.user);
  const goToEvents = () => {
    navigate(`/${NAV_PATH.EVENTS}`);
  };
  const { data: premiumEventsData } = useGetEventsQuery({
    dateFrom: new Date(new Date().toDateString()).toString(),
    limit: 0,
    orderBy: "startingDate",
    sortBy: "DESC",
    type: ["online", "getresponse", "webinar", "locally", "conference"],
    includeOtherUserTypes: "yes",
    profilingSlugs: authUser?.settings?.Profilowanie__c + ",premium",
  });
  const { data: eventsData } = useGetEventsQuery({
    dateFrom: new Date(new Date().toDateString()).toString(),
    limit: 0,
    orderBy: "startingDate",
    sortBy: "DESC",
    type: ["online", "getresponse", "webinar", "locally", "conference"],
    includeOtherUserTypes: "yes",
    profilingSlugs: authUser?.settings?.Profilowanie__c,
  });
  const { data: eventTypesData } = useGetEventTypesQuery({});
  const { t } = useTranslation();

  const fetchedEvents: EventData[] = [];
  premiumEventsData && fetchedEvents.push(...premiumEventsData?.events);
  eventsData && fetchedEvents?.push(...eventsData?.events);

  const filteredEvents = fetchedEvents?.filter(
    (event: EventData) =>
      event?.cancer?.id ===
        parseInt(authUser?.settings?.ID_nowotworu_HUB__c ?? "") ||
      event?.cancer?.id === undefined
  );
  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <h2 className={`fw-600 fs-22 text-dark-blue mb-4 ${styles.title}`}>
        {t("widget_incoming_events_title")}
      </h2>
      {filteredEvents?.length > 0 && eventTypesData ? (
        <EventCard
          data={filteredEvents[0]}
          noShadow={true}
          typesArray={eventTypesData?.eventCategories}
        />
      ) : (
        <p>{t("widget_incoming_events_no_results_p")}</p>
      )}
      <div className="mt-4 d-flex justify-content-end align-items-center">
        <Button variant="primary" onClick={goToEvents}>
          {t("widget_incoming_events_more_button")}
        </Button>
      </div>
    </Container>
  );
};

export default IncomingEvents;
