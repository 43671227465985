import { apiSlice } from "./apiSlice";
import { pollQuestionAnswers } from "endpoints/endpoints";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["PollQuestionAnswers"],
});

export const pollQuestionAnswersApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    postPollQuestionAnswer: builder.mutation({
      query: ({ data }) => ({
        url: `${pollQuestionAnswers}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PollQuestionAnswers"],
    }),

    getPollQuestionAnswers: builder.query({
      query: ({
        limit,
        offset,
        pollUser,
        pollPageQuestion,
        answer,
        sortBy,
      }) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(pollUser && { pollUser }),
          ...(pollPageQuestion && { pollPageQuestion }),
          ...(answer && { answer }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });

        return {
          url: `${pollQuestionAnswers}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PollQuestionAnswers"],
    }),

    getPollQuestionAnswer: builder.query({
      query: (id) => {
        return {
          url: `${pollQuestionAnswers}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PollQuestionAnswers"],
    }),

    patchPollQuestionAnswer: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `${pollQuestionAnswers}/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["PollQuestionAnswers"],
    }),
  }),
});

export const {
  usePostPollQuestionAnswerMutation,
  useGetPollQuestionAnswersQuery,
  useGetPollQuestionAnswerQuery,
  usePatchPollQuestionAnswerMutation,
} = pollQuestionAnswersApi;
