import {
  UseFieldArrayRemove,
  UseFieldArrayMove,
  UseFormReturn,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import PollQuestions from "../PollQuestions/PollQuestions";
import { POLL_DATA } from "Helpers/pollsData";
import { CancelModal } from "components/molecules/CancelModal/CancelModal";
import { useState } from "react";
import { ReactComponent as ArrowUp } from "../../../assets/Icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../assets/Icons/ArrowDown.svg";

interface PollPageProps {
  id: string;
  index: number;
  maxIndex: number;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
}

const PollPage = ({ id, index, maxIndex, remove, move }: PollPageProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    control,
    register,
    formState: { errors },
  }: UseFormReturn<PollPageFormValues> = useFormContext();

  const [isActiveValue] = useWatch({
    control,
    name: [`pages.${index}.isActive`],
  });

  const hideModalHandler = () => setShowModal(false);
  const showModalHandler = () => setShowModal(true);

  const modalAcceptanceHandler = (index: number) => {
    remove(index);
    hideModalHandler();
  };

  const movePollPageUp = () => move(index, index - 1);
  const movePollPageDown = () => move(index, index + 1);

  const pageErrors = errors?.pages;

  const isFirstPollPage = index === 0;
  const isLastPollPage = index === maxIndex;

  const formGroupStyles = "mb-3";
  const labelStyles = "mb-1 fs-14 lh-base";
  const buttonStyles = "lh-base text-light px-2";

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Container fluid>
            <Row className="align-items-center">
              <Col md={4} as="p" className="mb-2 p-0 mb-md-0">
                Strona {index + 1}
              </Col>
              <Col
                md={8}
                className="d-flex justify-content-between p-0 justify-content-md-end"
              >
                {(!isFirstPollPage || !isLastPollPage) && (
                  <div className="d-inline-flex">
                    {!isFirstPollPage && (
                      <Button
                        variant="dark"
                        className={`${buttonStyles} me-2`}
                        onClick={movePollPageUp}
                      >
                        <ArrowUp width={24} height={24} />
                      </Button>
                    )}

                    {!isLastPollPage && (
                      <Button
                        variant="dark"
                        className={`${buttonStyles} me-2`}
                        onClick={movePollPageDown}
                      >
                        <ArrowDown width={24} height={24} />
                      </Button>
                    )}
                  </div>
                )}
                {!(isFirstPollPage && isLastPollPage) && (
                  <Button variant="danger" onClick={showModalHandler}>
                    Usuń stronę
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Card.Header>

        <Card.Body className="border-2 border-bottom">
          <Form.Group className={formGroupStyles}>
            <Form.Label
              htmlFor={`p-${id}-${index + 1}-title`}
              className={labelStyles}
            >
              Tytuł strony
            </Form.Label>
            <Form.Control
              id={`p-${id}-${index + 1}-title`}
              {...register(`pages.${index}.title`, {
                required: "Pole nie może być puste.",
              })}
              type="text"
              isInvalid={!!pageErrors?.[index]?.title}
            />
            {pageErrors?.[index]?.title && (
              <Form.Control.Feedback as="p" type="invalid">
                {pageErrors?.[index]?.title?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <Form.Label
              htmlFor={`p-${id}-${index + 1}-description`}
              className={labelStyles}
            >
              Opis strony (opcjonalne)
            </Form.Label>
            <Form.Control
              className="lh-base ta-resize-none"
              as="textarea"
              rows={3}
              id={`p-${id}-${index + 1}-description`}
              {...register(`pages.${index}.description`)}
            />
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <p className={labelStyles}>Status</p>
            {POLL_DATA.STATUSES.map((s, idx) => (
              <Form.Label
                key={idx}
                htmlFor={`p-${id}-${index + 1}-isActive-${s.name}`}
                className="mb-0 me-3"
              >
                <Form.Check
                  inline
                  id={`p-${id}-${index + 1}-isActive-${s.name}`}
                  {...register(`pages.${index}.isActive`, {
                    required: "Wybierz wartość.",
                  })}
                  type="radio"
                  value={s.value}
                  defaultChecked={s.value === isActiveValue ?? false}
                  isInvalid={!!pageErrors?.[index]?.isActive}
                />
                {s.label}
              </Form.Label>
            ))}
            {pageErrors?.[index]?.isActive && (
              <Form.Control.Feedback as="p" type="invalid">
                {pageErrors?.[index]?.isActive?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Card.Body>

        <PollQuestions pageIndex={index} />
      </Card>

      <CancelModal
        title="Komunikat"
        content="Czy na pewno chcesz usunąć stronę?"
        cancelText="Nie"
        confirmText="Tak"
        show={showModal}
        onHide={hideModalHandler}
        onConfirm={() => modalAcceptanceHandler(index)}
      />
    </>
  );
};

export default PollPage;
