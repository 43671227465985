import { Col, Container, Form, Row } from "react-bootstrap";
import { useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import TinyEditor from "../TinyEditor/TinyEditor";
import { POLL_META_DATA } from "Helpers/pollsData";
import {
  areCharLengthFieldsAvailable,
  areQuantityFieldsAvailable,
  areSlugSwitchNeeded,
  isFieldPatternAvailable,
  isFieldRequired,
} from "Helpers/pollMetaHandlers";

interface PollMetaProps {
  id: string;
  pageIndex: number;
  questionIndex: number;
}

const PollMeta = ({ id, pageIndex, questionIndex }: PollMetaProps) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  }: UseFormReturn<PollPageFormValues> = useFormContext();

  const [
    fieldType,
    minLengthValue,
    minValue,
    isHtmlTooltip,
    htmlTooltipContent,
    metaTableName,
  ] = useWatch({
    control,
    name: [
      `pages.${pageIndex}.questions.${questionIndex}.fieldType`,
      `pages.${pageIndex}.questions.${questionIndex}.meta.minLength`,
      `pages.${pageIndex}.questions.${questionIndex}.meta.min`,
      `pages.${pageIndex}.questions.${questionIndex}.meta.isHtmlTooltip`,
      `pages.${pageIndex}.questions.${questionIndex}.meta.htmlTooltipContent`,
      `pages.${pageIndex}.questions.${questionIndex}.meta.tableName`,
    ],
  });

  const metaErrors =
    errors?.pages?.[pageIndex]?.questions?.[questionIndex]?.meta;

  const canRequiredFieldBeValidated = isFieldRequired(fieldType);
  const canBeValidatedWithPattern = isFieldPatternAvailable(fieldType);
  const canCharLengthBeValidated = areCharLengthFieldsAvailable(fieldType);
  const canNumericValueBeValidated = areQuantityFieldsAvailable(fieldType);

  const formGroupStyles = "mb-3";
  const labelStyles = "mb-1 fs-14 lh-base";

  return (
    <Container fluid className="px-0">
      <Row className="align-items-center">
        {canRequiredFieldBeValidated && (
          <Col lg={6}>
            <Form.Group className={formGroupStyles}>
              <Form.Check
                className="mb-0"
                type="switch"
                id={`q-${id}-${questionIndex + 1}-meta-required`}
                label="Wymagane"
                {...register(
                  `pages.${pageIndex}.questions.${questionIndex}.meta.isRequired`
                )}
              />
            </Form.Group>
          </Col>
        )}
        {canBeValidatedWithPattern && (
          <Col lg={6}>
            <Form.Group className={formGroupStyles}>
              <Form.Label
                htmlFor={`q-${id}-${questionIndex + 1}-meta-pattern`}
                className={labelStyles}
              >
                Wzorzec walidacji (opcjonalne)
              </Form.Label>
              <Form.Select
                id={`q-${id}-${questionIndex + 1}-meta-pattern`}
                {...register(
                  `pages.${pageIndex}.questions.${questionIndex}.meta.pattern`
                )}
                defaultValue=""
                isInvalid={!!metaErrors?.pattern}
              >
                <option value={""}>Brak</option>
                {POLL_META_DATA?.PATTERNS?.filter(
                  (pd: PatternData, idx: number) =>
                    pd.fieldTypes.includes(fieldType)
                ).map((pd: PatternData, idx: number) => (
                  <option key={idx} value={pd.value}>
                    {pd.label}
                  </option>
                ))}
              </Form.Select>
              {metaErrors?.pattern && (
                <Form.Control.Feedback type="invalid">
                  {metaErrors?.pattern?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        )}
        {areSlugSwitchNeeded(fieldType, metaTableName) && (
          <Col lg={6}>
            <Form.Group className={formGroupStyles}>
              <Form.Check
                className="mb-0"
                type="switch"
                id={`q-${id}-${questionIndex + 1}-meta-areAnswersAsSlug`}
                label="Odpowiedzi jako slug"
                {...register(
                  `pages.${pageIndex}.questions.${questionIndex}.meta.areAnswersAsSlug`
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      {canCharLengthBeValidated && (
        <Row>
          <Col lg={6}>
            <Form.Group className={formGroupStyles}>
              <Form.Label
                htmlFor={`q-${id}-${questionIndex + 1}-meta-minLength`}
                className={labelStyles}
              >
                Minimalna długość (opcjonalne)
              </Form.Label>
              <Form.Control
                id={`q-${id}-${questionIndex + 1}-meta-minLength`}
                {...register(
                  `pages.${pageIndex}.questions.${questionIndex}.meta.minLength`
                )}
                type="number"
                min={1}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className={formGroupStyles}>
              <Form.Label
                htmlFor={`q-${id}-${questionIndex + 1}-meta-maxLength`}
                className={labelStyles}
              >
                Maksymalna długość (opcjonalne)
              </Form.Label>
              <Form.Control
                id={`q-${id}-${questionIndex + 1}-meta-maxLength`}
                {...register(
                  `pages.${pageIndex}.questions.${questionIndex}.meta.maxLength`
                )}
                type="number"
                min={minLengthValue || 1}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {canNumericValueBeValidated && (
        <>
          <Row>
            <Col lg={6}>
              <Form.Group className={formGroupStyles}>
                <Form.Label
                  htmlFor={`q-${id}-${questionIndex + 1}-meta-min`}
                  className={labelStyles}
                >
                  Minimalna wartość (opcjonalne)
                </Form.Label>
                <Form.Control
                  id={`q-${id}-${questionIndex + 1}-meta-min`}
                  {...register(
                    `pages.${pageIndex}.questions.${questionIndex}.meta.min`
                  )}
                  type="number"
                  min={1}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className={formGroupStyles}>
                <Form.Label
                  htmlFor={`q-${id}-${questionIndex + 1}-meta-max`}
                  className={labelStyles}
                >
                  Maksymalna wartość (opcjonalne)
                </Form.Label>
                <Form.Control
                  id={`q-${id}-${questionIndex + 1}-meta-max`}
                  {...register(
                    `pages.${pageIndex}.questions.${questionIndex}.meta.max`
                  )}
                  type="number"
                  min={minValue || 1}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group className={formGroupStyles}>
                <Form.Label
                  htmlFor={`q-${id}-${questionIndex + 1}-meta-step`}
                  className={labelStyles}
                >
                  Krok (opcjonalne)
                </Form.Label>
                <Form.Control
                  id={`q-${id}-${questionIndex + 1}-meta-step`}
                  {...register(
                    `pages.${pageIndex}.questions.${questionIndex}.meta.step`
                  )}
                  type="number"
                  min={0}
                  step={0.1}
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col lg={4} xl={3}>
          <Form.Group className={formGroupStyles}>
            <Form.Check
              className="mb-0"
              type="switch"
              id={`q-${id}-${questionIndex + 1}-meta-isHtmlTooltip`}
              label="Podpowiedź"
              {...register(
                `pages.${pageIndex}.questions.${questionIndex}.meta.isHtmlTooltip`
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      {isHtmlTooltip && (
        <Row>
          <Col>
            <TinyEditor
              data={htmlTooltipContent}
              setValue={setValue}
              name={`pages.${pageIndex}.questions.${questionIndex}.meta.htmlTooltipContent`}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default PollMeta;
