import styles from "./LoyaltyProgramServiceTemplate.module.scss";
import { Row, Container, Spinner, Stack } from "react-bootstrap";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { useEffect, useState, useRef } from "react";
import SectionTitle from "../../atoms/SectionTitle/SectionTitle";
import { useSearchParams } from "react-router-dom";
import { LatestServiceContent } from "../../organisms/LatestServiceContent/LatestServiceContent";
import { FaqContent } from "../../organisms/FaqContent/FaqContent";
import { Agreement } from "../../organisms/Agreement/Agreement";
import { LoyaltyProgramServiceSearch } from "../../organisms/LoyaltyProgramServiceSearch/LoyaltyProgramServiceSearch";
import { ImageIds } from "../../../Helpers/convertImageIdToUrl";
import { useGetSystemSettingsQuery } from "../../../redux/services/systemSettingsApi";

export interface LoyaltyProgramServiceDataInterface {
  id: number;
  name: string;
  imageId: ImageIds[];
  status: number;
  deleted?: number;
  startAt: string;
  endAt: string | null;
  views: 0;
  company: {
    id: number;
    name: string;
  };
  departmentCount: number;
  priorityCategory: {
    id: number;
    name: string;
    priority: number;
  }[];
  daysToEnd: number | null;
  promoCodesEndAt?: string | null;
  promoCodesAmount?: number | null;
  loyaltyProgram?: {
    status: number;
    deleted: number;
  };
  codeUsageCount: number;
  isActive?: number;
  changes?: string;
  multipleUseCodesAllowed: number;
  description: string;
  conditions: string;
  updatedAt: string;
}

const LIMIT = 9;

export const LoyaltyProgramServiceTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [sort, setSort] = useState<string | null>(searchParams.get("sortBy"));
  const [pageId, setPageId] = useState<string>("1346206625");

  const { data: systemSettings, isLoading: systemSettingsIsLoading } =
    useGetSystemSettingsQuery({});

  const { data: loyaltyProgramsData, isLoading } =
    useGetLoyaltyProgramsServicesQuery({
      offset: currPage ? ((currPage - 1) * LIMIT).toString() : "0",
      limit: LIMIT,
      categoryId: searchParams.get("category"),
      provinceId: searchParams.get("province"),
      programId: searchParams.get("program") || "",
      cityId: searchParams.get("city"),
      sortBy: searchParams.get("sortBy"),
    });

  useEffect(() => {
    if (systemSettings) {
      const faqSystemItem = systemSettings?.data?.find(
        (item: { context: string; value: string }) => item.context === "loyalty"
      );
      setPageId(faqSystemItem ? faqSystemItem.value : "1346206625");
    }
  }, [systemSettings]);

  const searchRef = useRef<HTMLDivElement>(null);

  if (isLoading || systemSettingsIsLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const handlePageChange = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
    setCurrPage(newPage);
    searchRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const newsSortHandler = () =>
    sort === "news,ASC" ? handleSort("news,DESC") : handleSort("news,ASC");

  const popularSortHandler = () =>
    sort === "popular,ASC"
      ? handleSort("popular,DESC")
      : handleSort("popular,ASC");

  // const endSortHandler = () =>
  //     sort === "end,ASC" ? handleSort("end,DESC") : handleSort("end,ASC");

  return (
    <Container fluid={true} className="bg-light">
      <section className={styles.section}>
        <Stack gap={5}>
          <Agreement programId={searchParams.get("program") || ""} />
          <Row>
            <SectionTitle className={`text-dark-blue ${styles.title}`}>
              Program beneficjenta
            </SectionTitle>
          </Row>
          <Row className={`${styles.wrapper}`}>
            <LoyaltyProgramServiceSearch
              data={loyaltyProgramsData}
              label={"Szukaj benefitów"}
              limit={LIMIT}
              currPage={currPage}
              sort={sort}
              handlePageChange={handlePageChange}
              newsSortHandler={newsSortHandler}
              popularSortHandler={popularSortHandler}
              searchRef={searchRef}
            />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <LatestServiceContent name={"Najnowsze benefity"} latest={true} />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <FaqContent pageId={pageId} />
          </Row>
        </Stack>
      </section>
    </Container>
  );
};
