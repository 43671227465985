import { format, parseISO } from "date-fns";

export const formatISODate = (date: string) => {
  if (date) {
    const parsedDate = parseISO(date);
    const formattedDate = format(parsedDate, "dd.MM.yyyy HH:mm");

    return formattedDate;
  }
  return "Brak danych";
};
