import { CompanyService } from "../../organisms/CompanyService/CompanyService";
import { WidgetCreator } from "../WidgetCreator/WidgetCreator";
import { Company } from "../LoyaltyProgramServiceArticle/types";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { Container, Spinner } from "react-bootstrap";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { GetCompaniesProps } from "../../../redux/services/companyApi";

interface Props {
  data: Company;
  refetch: () => QueryActionCreatorResult<
    QueryDefinition<
      GetCompaniesProps,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
}
const ALL_SERVICE = 1;
export const WidgetCompanyServices = (props: Props) => {
  const {
    data: loyaltyProgramsServicesCompanyData,
    isLoading: isLoadingService,
  } = useGetLoyaltyProgramsServicesQuery({
    companyId: props.data?.id?.toString(),
    allService: ALL_SERVICE,
  });
  if (isLoadingService) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }
  return (
    <div className={"mt-5"}>
      <WidgetCreator
        nameLabel={"Benefity"}
        buttonName={"STWÓRZ PIERWSZY BENEFIT"}
        buttonIcon={"plus"}
        data={loyaltyProgramsServicesCompanyData?.data}
        condition={loyaltyProgramsServicesCompanyData?.data?.length > 0 || null}
        Child={CompanyService}
        reFetchCompany={props.refetch}
        disableBtn={props.data?.companyDepartment?.length === 0}
        info={
          props.data?.companyDepartment?.length === 0
            ? "Tworzenie benefitów staje się aktywne dopiero po dodaniu lokalizacji"
            : false
        }
      />
    </div>
  );
};
