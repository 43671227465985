import QuickEditCancer from "components/molecules/QuickEditCollapse/QuickEditCancer/QuickEditCancer";
import TableRowCancer from "components/organisms/TableRowCancer/TableRowCancer";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import SortButton from "components/organisms/SortButton/SortButton";

interface CancersTableProps {
  data: Cancer[];
}

const CancersTable = ({ data }: CancersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(searchParams.get("sortBy"));

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const sortHandler = (
    label: "name" | "code" | "categoryId" | "id" | "displayOrder"
  ) => {
    let nextSort: string;
    switch (label) {
      case "name":
        sort === "name,ASC"
          ? (nextSort = "name,DESC")
          : (nextSort = "name,ASC");
        break;

      case "code":
        sort === "code,ASC"
          ? (nextSort = "code,DESC")
          : (nextSort = "code,ASC");
        break;

      case "categoryId":
        sort === "categoryId,ASC"
          ? (nextSort = "categoryId,DESC")
          : (nextSort = "categoryId,ASC");
        break;

      case "displayOrder":
        sort === "displayOrder,ASC"
          ? (nextSort = "displayOrder,DESC")
          : (nextSort = "displayOrder,ASC");
        break;

      case "id":
        sort === "id,ASC" ? (nextSort = "id,DESC") : (nextSort = "id,ASC");
        break;

      default:
        nextSort = "displayOrder,ASC";
        break;
    }

    handleSort(nextSort);
  };

  const thStyles = "container-fluid pb-4 border border-0";

  return (
    <Table className="mt-5">
      <thead>
        <tr>
          <th className={thStyles}>
            <Row>
              <Col className="col-4">
                <SortButton
                  label="Nazwa"
                  onClick={() => sortHandler("name")}
                  isArrowRotated={sort === "name,DESC"}
                />
              </Col>
              <Col className="col-2">
                <SortButton
                  label="Kod"
                  onClick={() => sortHandler("code")}
                  isArrowRotated={sort === "code,DESC"}
                />
              </Col>
              <Col className="col-3">
                <SortButton
                  label="Kategoria"
                  onClick={() => sortHandler("categoryId")}
                  isArrowRotated={sort === "categoryId,DESC"}
                />
              </Col>
              <Col className="col-2">
                <SortButton
                  label="Kolejność"
                  onClick={() => sortHandler("displayOrder")}
                  isArrowRotated={sort === "displayOrder,DESC"}
                />
              </Col>
              <Col className="col-1">
                <SortButton
                  label="ID"
                  onClick={() => sortHandler("id")}
                  isArrowRotated={sort === "id,DESC"}
                />
              </Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((cancer, idx) => (
          <TableRowCancer
            key={idx}
            data={cancer}
            CollapseItem={QuickEditCancer}
          />
        ))}
        {(!data || data?.length === 0) && (
          <tr>
            <td colSpan={4} className="text-center">
              Nie znaleziono żadnych nowotworów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CancersTable;
