import CategoriesTemplate from "../../templates/CategoriesTemplate/CategoriesTemplate";
import { LoyaltyProgramCategoryTemplate } from "../../templates/LoyaltyProgramCategoryTemplate/LoyaltyProgramCategoryTemplate";
import { Container } from "react-bootstrap";

const CategoriesPage = () => {
  return (
    <Container fluid={true} className={"d-flex flex-column me-4 gap-5"}>
      <CategoriesTemplate />
      <LoyaltyProgramCategoryTemplate />
    </Container>
  );
};

export default CategoriesPage;
