import { useGetArticlesQuery } from "redux/services/articlesApi";
import { capitalizeString } from "./capitalizeString";

export const useArticleRssCategories = () => {
  const { data: articleRssData } = useGetArticlesQuery({
    offset: "0",
    limit: "0",
  });

  const rssCategoryOptions = Array.from(
    new Set<string>(
      articleRssData?.articles?.reduce(
        (acc: string[], curr: ArticleRss) => [
          ...acc,
          ...(curr?.categoryNames?.split(", ") ?? ""),
        ],
        []
      )
    )
  );

  const selectOptions = rssCategoryOptions.map((category) => ({
    value: category,
    label: capitalizeString(category),
  }));

  return { options: rssCategoryOptions, selectOptions };
};
