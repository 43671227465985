import { useState } from "react";
import { Form } from "react-bootstrap";
import { ChangesStatusAbuseEntity } from "../ChangesStatusAbuseEntity/ChangesStatusAbuseEntity";
import {
  PatchDoctorAbusePayload,
  usePatchDoctorReviewAbuseMutation,
} from "../../../redux/services/doctorApi";
import { usePatchFacilityReviewsAbuseMutation } from "../../../redux/services/facilityReviewsApi";
import {
  usePatchPostsAbuseMutation,
  usePatchPostsCommentAbuseMutation,
} from "../../../redux/services/feedApi";
import { Review } from "../ChangesStatus/types";

interface Props {
  data: Review[];
  type: AcceptableChanges;
  id: number | string;
}
export const ChangesStatusAbuse = ({ data, type, id }: Props) => {
  const [isActiveStatus, setIsActiveStatus] = useState<boolean>(false);
  const [arrayIds, setArrayIds] = useState<number[]>([]);

  const [patchDoctorReviewAbuse] = usePatchDoctorReviewAbuseMutation();

  const [patchFacilityReviewsAbuse] = usePatchFacilityReviewsAbuseMutation();

  const [patchPostsAbuse] = usePatchPostsAbuseMutation();
  const [patchPostsCommentAbuse] = usePatchPostsCommentAbuseMutation();

  const handlePatchReview = async (
    type: AcceptableChanges,
    data: PatchDoctorAbusePayload,
  ) => {
    if (data.id !== undefined) {
      setArrayIds([...arrayIds, data.id]);
    }
    switch (type) {
      case "doctorsReview":
        await patchDoctorReviewAbuse(data);

        break;
      case "facilityReview":
        await patchFacilityReviewsAbuse(data);
        break;
      case "feed":
        await patchPostsAbuse(data);
        break;
      case "feedComments":
        await patchPostsCommentAbuse(data);

        break;
    }
  };

  const changesStatusAbuse = () => {
    return data
      .filter(
        // eslint-disable-next-line
        (value: Review) => {
          if (!isActiveStatus) {
            if (value.decision === null && !arrayIds.includes(value.id)) {
              return value;
            }
          } else {
            return value;
          }
        },
      )
      .map((review: Review) => {
        return (
          <ChangesStatusAbuseEntity
            key={review.id}
            data={review}
            type={type}
            handlePatchReview={handlePatchReview}
          />
        );
      });
  };

  return (
    <>
      <div
        className={
          "d-flex justify-content-between flex-column flex-lg-row text-dark-blue mt-4 mb-3"
        }
      >
        <Form.Check
          type="switch"
          checked={isActiveStatus}
          id={`${type}-${id}-switch`}
          label="Pokaż wszystkie zgłoszenia"
          onChange={() => {
            setIsActiveStatus((prevState) => !prevState);
          }}
        />
      </div>
      <div className={"d-flex flex-column gap-3"}>{changesStatusAbuse()}</div>
    </>
  );
};
