import { system } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Systems"],
});

export const systemsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSystems: builder.query({
      query: () => {
        return {
          url: `${system}`,
          method: "GET",
        };
      },
      providesTags: ["Systems"],
    }),
  }),
});

export const { useGetSystemsQuery } = systemsApi;
