import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  linkLabel: string;
  linkSrc: string;
}

export const LoyaltyProgramServiceEmptyCard = ({
  text,
  linkLabel,
  linkSrc,
}: Props) => {
  return (
    <Card
      as="article"
      className={`d-flex flex-column justify-content-center align-items-center fw-normal gap-3 h-100 w-100 alivia-radius p-4`}
    >
      <span className={"text-center fs-16 text-grey"}>{text}</span>
      <Link to={linkSrc} className={"btn btn-primary col-12 col-md-auto"}>
        <span className={"text-white"}>{linkLabel}</span>
      </Link>
    </Card>
  );
};
