import { useState } from "react";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./TableRowCancer.module.scss";
import { Col, Row } from "react-bootstrap";
import { NAV_PATH } from "../Navigation/navigationData";

const TableRowCancer = ({ data, CollapseItem }: any) => {
  const [open, setOpen] = useState(false);

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.CANCERS}/${data.id}/edit`,
    },
    {
      name: "Szybka edycja",
      action: () => setOpen(!open),
    },
  ];

  return (
    <>
      <tr aria-controls="example-collapse-text " className={styles.mainTr}>
        <td
          className={`container-fluid ${styles.mainTd} ${
            !data.status ? styles.inactiveTd : ""
          }`}
          colSpan={4}
        >
          <Row className="pb-2 bg-transpatent">
            <Col className="col-4">{data.name}</Col>
            <Col className="col-2">{data.code}</Col>
            <Col className="col-3">
              {data.categories
                .map((category: PageCategory) => category.title)
                .join(", ")}
            </Col>
            <Col className="col-2">{data.displayOrder}</Col>
            <Col className="col-1">{data.id}</Col>
          </Row>
          {!open && (
            <Row className={`bg-transpatent ${styles.hoverRow}`}>
              <Col>
                <Breadcrumb data={breadcrumbData} />
              </Col>
            </Row>
          )}
          {open && (
            <Row>
              <Col>
                <CollapseItem open={open} data={data} setOpen={setOpen} />
              </Col>
            </Row>
          )}
        </td>
      </tr>
    </>
  );
};

export default TableRowCancer;
