import { useState } from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CardCodes } from "redux/services/cardCodesApi";

interface Props {
  fileName: string;
  data?: CardCodes[];
  headerTable: string[];
}

const ExportCSV = ({ fileName, data, headerTable }: Props) => {
  const [buttonIsBlocked, setButtonIsBlocked] = useState(false);

  const pdfData = () => {
    const headerData = [...headerTable];

    const bodyData =
      data?.map((item: CardCodes) => [
        item?.number,
        XLSX.SSF.format("yyyy-mm-dd", new Date(item?.createdAt)),
        item?.user?.name
          ? item?.user?.name
          : item?.user?.firstName && item?.user?.lastName
          ? `${item?.user?.firstName} ${item?.user?.lastName}`
          : "",
      ]) || [];

    return [headerData, ...bodyData];
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const ExportToCSV = async (csvData: object[], fileName: string) => {
    const ws = XLSX.utils.aoa_to_sheet([["MOJA_ALIVIA", "KARTY_KODÓW"]]);
    const wsrows = [{ hpt: 16 }, { hpx: 24 }];
    ws["!rows"] = wsrows;
    ws["!cols"] = [{ wch: 20 }, { wch: 25 }, { wch: 25 }];

    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: false,
      origin: "A2",
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    await FileSaver.saveAs(data, fileName);
  };

  const ExportCSVEventHandler = () => {
    ExportToCSV(pdfData(), fileName).then(() => setButtonIsBlocked(false));
  };

  return (
    <Button
      onClick={ExportCSVEventHandler}
      disabled={buttonIsBlocked}
      className="btn-outline-pink loadingButton"
    >
      POBIERZ RAPORT XLSX/CSV
      {buttonIsBlocked && (
        <div className="loadingButtonSpinner">
          <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </Button>
  );
};

export default ExportCSV;
