export const ChangesCompanyDepartmentConfig = [
    {
        keyName: 'name',
        type: 'normal',
        name: 'Nazwa lokalizacji'
    },
    {
        keyName: 'street',
        type: 'normal',
        name: 'Ulica'
    },
    {
        keyName: 'houseNumber',
        type: 'normal',
        name: 'Nr budynku'
    },
    // {
    //     keyName: 'additionalDescription',
    //     type: 'normal',
    //     name: 'Dodatkowe informacje'
    // },
    {
        keyName: 'emailAddresses',
        type: 'contact',
        name: 'Adres e-mail'
    },
]