import { Badge, Button, Container, ProgressBar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { CompanyServiceInfo } from "../CompanyServiceInfo/CompanyServiceInfo";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { ReactComponent as Copy } from "../../../assets/Icons/Copy.svg";
import styles from "./CompanyServiceEntity.module.scss";
import { useNavigate } from "react-router-dom";
import { LoyaltyProgramServiceDataInterface } from "../../templates/LoyaltyProgramTemplate/LoyaltyProgramServiceTemplate";
import { toast } from "react-toastify";
import { useDeleteLoyaltyProgramsServiceMutation } from "../../../redux/services/loyaltyProgramApi";
import PopupModal from "../PopupModal/PopupModal";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: LoyaltyProgramServiceDataInterface;
  handleChange: (data: any) => void;
  handleCopy: () => void;
  index: number;
}

export const CompanyServiceEntity = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showValue, setShowValue] = useState(false);
  const [changes, setChanges] = useState<React.ReactNode | false>(false);
  const [deletedBadge, setDeletedBadge] = useState<React.ReactNode | false>(
    false
  );
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [deleteService, { isSuccess: deleteIsSuccess, error: deleteError }] =
    useDeleteLoyaltyProgramsServiceMutation();

  // const [deleteService, {
  //     isSuccess: deleteIsSuccess,
  //     error: deleteError,
  // }] = useDeleteLoyaltyProgramsServiceQuery();

  useEffect(() => {
    if (deleteIsSuccess) {
      toast.success(t("toast_companyServiceEntity_success"));
    }
    // eslint-disable-next-line
  }, [deleteIsSuccess, deleteError]);

  useEffect(() => {
    if (props.data.changes === undefined || props.data.changes === null) {
      setChanges(false);
    } else {
      const newChanges = JSON.parse(props.data.changes);
      if (newChanges.comments !== undefined) {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            wymaga poprawy: {newChanges.comments}
          </Badge>
        );
      } else {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            oczekuje na weryfikację
          </Badge>
        );
      }
    }
  }, [props.data.changes]);

  useEffect(() => {
    if (!props?.data?.deleted) {
      setDeletedBadge(false);
    } else {
      setDeletedBadge(
        <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
          Trwale usunięty
        </Badge>
      );
    }
  }, [props.data.deleted]);

  const hideModalHandler = () => setShowModal(false);

  const modalAcceptanceHandler = async () => {
    await deleteService({ id: props.data.id });
    hideModalHandler();
    // props.handleDelete(props.data);
  };

  const handlerEdit = () => {
    props.handleChange(props.data);
  };

  const handlerCopy = () => {
    props.handleCopy();
    props.handleChange(props.data);
  };

  return (
    <Container
      fluid={true}
      key={props.data.id}
      className={"d-flex flex-column  py-1"}
    >
      <div className="d-flex flex-column flex-lg-row justify-content-between">
        <div
          // className={`d-flex justify-content-start align-items-center`}
          className={`d-flex justify-content-start align-items-center ${
            props.data.isActive ? "text-dark-blue" : "text-grey-2"
          }`}
        >
          <div className={"fs-19"} style={{ width: 40 }}>
            {props.index}.
          </div>
          <div>
            {deletedBadge ? deletedBadge : changes ? changes : <></>}
            <div className={"fs-19"}>{props.data.name}</div>
          </div>
          <Button
            className={`d-flex d-lg-none ms-auto justify-content-center align-items-center p-0 m-0 ${
              styles.buttonSize
            } ${showValue ? styles.arrowUp : styles.arrowDown} ${
              !showValue && "text-dark-blue"
            }`}
            variant={showValue ? "dark" : "transparent"}
            onClick={() => setShowValue((prevState) => !prevState)}
          >
            <Arrow rotate={90} />
          </Button>
        </div>
        <div className={"d-flex d-lg-none w-100"}>
          {!!props.data.promoCodesAmount && (
            <div
              className={`d-flex flex-column w-100 justify-content-center align-items-center fs-16 mt-4 ${
                props.data.isActive ? "text-dark-blue" : "text-grey-2"
              }`}
            >
              <span>
                {props.data.multipleUseCodesAllowed !== 1
                  ? props.data.promoCodesAmount - props.data.codeUsageCount
                  : props.data.promoCodesAmount}
                /{props.data.promoCodesAmount}
              </span>
              <div className={"d-flex w-100"}>
                <ProgressBar
                  className={`${styles.progresBars} w-100`}
                  variant={
                    props.data.isActive
                      ? (props.data.multipleUseCodesAllowed !== 1
                          ? props.data.promoCodesAmount -
                            props.data.codeUsageCount
                          : props.data.promoCodesAmount) /
                          props.data.promoCodesAmount >=
                        0.7
                        ? "success"
                        : (props.data.multipleUseCodesAllowed !== 1
                            ? props.data.promoCodesAmount -
                              props.data.codeUsageCount
                            : props.data.promoCodesAmount) /
                            props.data.promoCodesAmount >=
                          0.3
                        ? "warning"
                        : "danger"
                      : "grey"
                  }
                  now={
                    props.data.multipleUseCodesAllowed !== 1
                      ? props.data.promoCodesAmount - props.data.codeUsageCount
                      : props.data.promoCodesAmount
                  }
                  max={props.data.promoCodesAmount}
                />
              </div>
            </div>
          )}
        </div>
        <div className={"d-flex d-lg-none justify-content-between mt-4"}>
          {props.data.isActive === 2 ? (
            <>
              <Button
                className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
                variant={"transparent"}
                onClick={() => setShowModal((prev: boolean) => !prev)}
              >
                <TrashBlank />
              </Button>
              <Button
                className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
                variant={"transparent"}
                onClick={() =>
                  navigate(
                    `/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${props.data.id}`
                  )
                }
              >
                <ArrowUpRightFromSquare />
              </Button>
            </>
          ) : (
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
              variant={"transparent"}
              onClick={handlerCopy}
            >
              <Copy />
            </Button>
          )}
          {!props?.data?.deleted && (
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
              variant={"transparent"}
              onClick={handlerEdit}
            >
              <Pencil />
            </Button>
          )}
        </div>

        <div
          className={`d-none d-lg-flex flex-row justify-content-between align-items-center gap-2 ${styles.button}`}
        >
          {!!props.data.promoCodesAmount && (
            <div
              className={`d-flex flex-column justify-content-center align-items-center fs-16 ${
                props.data.isActive ? "text-dark-blue" : "text-grey-2"
              }`}
            >
              <span>
                {props.data.multipleUseCodesAllowed !== 1
                  ? props.data.promoCodesAmount - props.data.codeUsageCount
                  : props.data.promoCodesAmount}
                /{props.data.promoCodesAmount}
              </span>
              <div>
                <ProgressBar
                  className={styles.progresBars}
                  variant={
                    props.data.isActive
                      ? (props.data.multipleUseCodesAllowed !== 1
                          ? props.data.promoCodesAmount -
                            props.data.codeUsageCount
                          : props.data.promoCodesAmount) /
                          props.data.promoCodesAmount >=
                        0.7
                        ? "success"
                        : (props.data.multipleUseCodesAllowed !== 1
                            ? props.data.promoCodesAmount -
                              props.data.codeUsageCount
                            : props.data.promoCodesAmount) /
                            props.data.promoCodesAmount >=
                          0.3
                        ? "warning"
                        : "danger"
                      : "grey"
                  }
                  now={
                    props.data.multipleUseCodesAllowed !== 1
                      ? props.data.promoCodesAmount - props.data.codeUsageCount
                      : props.data.promoCodesAmount
                  }
                  max={props.data.promoCodesAmount}
                />
              </div>
            </div>
          )}
          <Button
            className={`d-flex  ms-auto justify-content-center align-items-center p-0 m-0 ${
              showValue ? styles.arrowUp : styles.arrowDown
            } ${!showValue && "text-dark-blue"}`}
            variant={showValue ? "dark" : "transparent"}
            onClick={() => setShowValue((prevState) => !prevState)}
          >
            <Arrow rotate={90} />
          </Button>

          {!props?.data?.deleted && (
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
              variant={"transparent"}
              onClick={handlerEdit}
            >
              <Pencil />
            </Button>
          )}

          {props.data.isActive === 2 ? (
            <>
              <Button
                className="d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue"
                variant={"transparent"}
                onClick={() =>
                  navigate(
                    `/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${props.data.id}`
                  )
                }
              >
                <ArrowUpRightFromSquare />
              </Button>
              <Button
                className="d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue"
                variant={"transparent"}
                onClick={() => setShowModal((prev: boolean) => !prev)}
              >
                <TrashBlank />
              </Button>
            </>
          ) : (
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
              variant={"transparent"}
              onClick={handlerCopy}
            >
              <Copy />
            </Button>
          )}
        </div>
      </div>
      {showValue && <CompanyServiceInfo id={props.data.id} />}
      <PopupModal
        titleClasses="fw-600 text-center"
        text="Czy na pewno chcesz usunąć benefit?"
        type="confirm"
        show={showModal}
        setShow={hideModalHandler}
        confirmAction={() => modalAcceptanceHandler()}
      />
    </Container>
  );
};
