interface UserDataStatus {
  value: string;
  label: string;
}

export const USER_DATA: {
  STATUSES: UserDataStatus[];
  ACCOUNT_STATUSES: UserDataStatus[];
  KEYCLOAK_STATUSES: UserDataStatus[];
} = {
  KEYCLOAK_STATUSES: [
    {
      value: "0",
      label: "Niepotwierdzone",
    },
    {
      value: "1",
      label: "Potwierdzone",
    },
  ],
  STATUSES: [
    {
      value: "0",
      label: "Nieaktywne",
    },
    {
      value: "1",
      label: "Aktywne",
    },
    {
      value: "90",
      label: "Do usunięcia",
    },
    {
      value: "100",
      label: "Usunięty",
    },
    {
      value: "200",
      label: "Zanonimizowany",
    },
  ],
  ACCOUNT_STATUSES: [
    {
      value: "0",
      label: "Konto aktywne",
    },
    {
      value: "1",
      label: "Konto usunięte",
    },
  ],
};
