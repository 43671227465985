import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  useGetPageQuery,
  useGetPagesQuery,
  useLazyGetPagesQuery,
  usePatchPageUpdateMutation,
} from "../../../redux/services/pagesApi";
import TextInput from "../../atoms/TextInput/TextInput";
import TinyEditor from "../../organisms/TinyEditor/TinyEditor";
import CollapseWithIcon from "../../organisms/CollapseWithIcon/CollapseWithIcon";
import {
  useGetPagesCategoryQuery,
  useLazyGetPagesCategoryQuery,
} from "../../../redux/services/pagesCategoryApi";
import CategoryCollapse from "../../molecules/CollapseComponents/CategoryCollapse/CategoryCollapse";
import { enums } from "../../../Helpers/enums";
import RadioInput from "../../atoms/RadioInput/RadioInput";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { useEffect, useState } from "react";
import DateTimePicker from "../../atoms/DateTimePicker/DateTimePicker";
import { todayTimeIsoString } from "../../../Helpers/dateHelpers";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleActiveSubstance,
  handleNewField,
  handleActiveSubstanceOffline,
} from "../../../Helpers/helpers";
import {
  useGetSubstanceQuery,
  useLazyGetSubstanceQuery,
  useGetActiveSubstanceShortQuery,
  useLazyGetActiveSubstanceShortQuery,
} from "redux/services/substanceApi";
import { replacePolishLetters } from "Helpers/replacePolishLetters";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import CheckboxInput from "components/atoms/CheckboxInput/CheckboxInput";
import SingleSelect from "components/molecules/SingleSelect/SingleSelect";
import {
  useGetCancerQuery,
  useGetCancersQuery,
  useLazyGetCancersQuery,
} from "redux/services/cancerApi";
import { useGetCountriesQuery } from "redux/services/countriesApi";
import TextArea from "components/atoms/TextArea/TextArea";
import AsyncMultiSelect from "components/molecules/AsyncMultiSelect/AsyncMultiSelect";
import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
import { toast } from "react-toastify";
import MediaPicker from "components/atoms/MediaPicker/MediaPicker";
import { useTranslation } from "react-i18next";
import { sortSubstanceByName } from "Helpers/sortSubstaceByName";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const PageEditTemplate = () => {
  const linkParams = useParams();

  const [patchPageUpdate, { isSuccess: patchIsSuccess, isError }] =
    usePatchPageUpdateMutation();

  const { data: pageData } = useGetPageQuery(linkParams.id, {
    skip: !linkParams.id,
  });
  const getAttachmentIDFromLink = (link: string) => {
    let firstSplit = link.split(".");
    let secondSplit = firstSplit[firstSplit.length - 2].split("/");
    let result = secondSplit[secondSplit.length - 1];
    return result;
  };

  const setAttachment = (id: string) => {
    if (id) {
      const attachments = id.includes("http")
        ? [getAttachmentIDFromLink(id)]
        : [id];
      setValue("attachments", attachments);
    } else {
      setValue("attachments", []);
    }
  };

  const [dataSend, setDataSend] = useState<PageDataPost>();

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PageDataPost>({
    defaultValues: {
      attachments: [],
      cancerIds: [],
      categoryIds: [],
      content: "",
      country: null,
      language: 1,
      lead: "",
      metaDescription: null,
      metaTitle: null,
      parentIds: [],
      password: "",
      slug: "",
      sortOrder: 0,
      substanceIds: [],
      substanceOfflineIds: [],
      title: "",
      type: 0,
      updatedBy: 2,
      url: "",
      date: todayTimeIsoString,
    },
  });

  const [handleReset, setHandleReset] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (linkParams.id) {
      setValue("title", pageData?.data.title);
      pageData?.data.featureImage.length > 0 &&
        setValue("attachments", [
          getAttachmentIDFromLink(pageData?.data.featureImage[0].accessUrl),
        ]);
      setValue("url", pageData?.data.url);
      setValue("metaTitle", pageData?.data.metaTitle);
      setValue("metaDescription", pageData?.data.metaDescription);
      setValue("content", pageData?.data.content);
      setValue("slug", pageData?.data.slug);
      setValue("lead", pageData?.data.lead);
      setValue("password", pageData?.data.password);
      setValue(
        "categoryIds",
        pageData?.data.category.map((item: PageDataTypes) => item.id)
      );
      setValue("sortOrder", pageData?.data.sortOrder);
      setValue("type", pageData?.data?.type?.toString());
      setValue(
        "substanceIds",
        pageData?.data?.activeSubstance.map(
          (activeSubstance: ActiveSubstance) => activeSubstance.id
        )
      );
      setValue(
        "substanceOfflineIds",
        pageData?.data?.activeSubstanceOffline?.map(
          (activeSubstanceOffline: ActiveSubstance) => activeSubstanceOffline.id
        )
      );
      setValue(
        "parentIds",
        pageData?.data?.parent?.map((item: PageDataTypes) => item.id)
      );
      setValue("cancerIds", pageData?.data?.cancer[0]?.id);
      setValue("addActiveSubstance", !!pageData?.data.cancer.length);
      setValue("country", pageData?.data?.country?.id || 1);
      setValue("language", pageData?.data?.language?.id || 1);
    }
  }, [pageData, setValue, linkParams]);

  const [urlParam, setUrlParam] = useState("");

  const { data: cancersData } = useGetCancersQuery({ status: "1" });
  const cancerId = watch("cancerIds") as any;
  const { data: cancerData } = useGetCancerQuery(
    { id: cancerId?.toString() ?? "" },
    { skip: watch("cancerIds") === undefined }
  );

  const [cancerValue, setCancerValue] = useState(pageData?.data?.cancer);

  useEffect(() => {
    if (watch("cancerIds")) {
      setCancerValue([
        { name: cancerData?.data?.name, id: cancerData?.data?.id },
      ]);
    }
    //eslint-disable-next-line
  }, [watch("cancerIds"), cancerData]);

  const { data: countriesData } = useGetCountriesQuery({});
  const availableCountriesData = countriesData?.data.filter(
    (c: any) => c.active
  );

  useEffect(() => {
    if (dataSend && getValues("attachments").length > 0 && patchIsSuccess) {
      const newDataSend = {
        ...dataSend,
        attachments: getValues("attachments"),
      };
      patchPageUpdate({ id: linkParams.id, data: newDataSend });
    }
    if (isError) {
      toast.error(t("toast_pageEditTemplate_add_image_error"));
    }
    // eslint-disable-next-line
  }, [
    isError,
    dataSend,
    patchPageUpdate,
    linkParams,
    getValues,
    patchIsSuccess,
  ]);

  useEffect(() => {
    if (patchIsSuccess) {
      return navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}`);
    }
  }, [navigate, patchIsSuccess]);

  const { data: pagesData } = useGetPagesQuery({ limit: "0" });

  const { data: pagesCategoryData } = useGetPagesCategoryQuery({
    type: "page",
  });

  const { data: activeSubstanceData } = useGetActiveSubstanceShortQuery(
    {
      cancerId: watch("cancerIds"),
      countryId: watch("country"),
    },
    { skip: !watch("cancerIds") || !watch("country") }
  );
  const { data: substanceData } = useGetSubstanceQuery({ limit: "0" });

  const sortSubstanceData = sortSubstanceByName(substanceData);

  const pageStatus = [
    {
      name: "draft",
      value: "0",
      description: "",
    },
    {
      name: "Publiczne",
      value: "1",
      description: "Widoczne dla wszystkich.",
    },
    {
      name: "Prywatne",
      value: "3",
      description: "Widoczne tylko dla administratorów i redaktorów witryny.",
    },
    {
      name: "Zabezpieczono hasłem",
      value: "2",
      description: "Tylko osoby znające hasło mogą zobaczyć ten wpis.",
    },
  ];

  useEffect(() => {
    if ("metaTitle" in errors) {
      toast.error(errors.metaTitle?.message);
    } else if ("metaDescription" in errors) {
      toast.error(errors.metaDescription?.message);
    }
  }, [errors.metaTitle, errors.metaDescription, errors]);

  return (
    <div className="mt-2 px-4 m-0 d-flex w-100 gap-3 ">
      <div className="w-100">
        <h1 className="pe-3 fs-3 mb-4">Edytuj stronę</h1>
        <TextInput
          className="mb-4 flex-column w-100"
          placeholder="Dodaj tytuł"
          register={register("title")}
        />
        {pageData && (
          <TinyEditor
            setValue={setValue}
            name="content"
            data={pageData?.data.content}
            image
          />
        )}

        <ComponentWithDescription
          className="my-2"
          labelFs="fs-16"
          label="Zajawka"
        >
          <TinyEditor
            setValue={setValue}
            name="lead"
            data={pageData?.data.lead}
          />
        </ComponentWithDescription>
        <h3 className="mt-5">Ustawienia seo</h3>
        <TextInput
          style={{ width: "1500px" }}
          className="mb-4 flex-column w-100"
          placeholder="Dodaj tytuł"
          register={register("metaTitle", {
            maxLength: {
              value: 250,
              message: "Za dużo znaków [limit 250]",
            },
          })}
          label="Meta title"
          error={errors.metaTitle?.message}
          limitSeo={watch("metaTitle")?.length}
        />

        <TextArea
          row={3}
          register={register("metaDescription", {
            maxLength: {
              value: 250,
              message: "Za dużo znaków [limit 250]",
            },
          })}
          error={errors.metaDescription?.message}
          label="Meta description"
          limitSeo={watch("metaDescription")?.length}
        />
      </div>
      <div className="d-flex flex-column gap-3 w-auto">
        <div
          className="accordion"
          id="accordionExample"
          style={{ width: "18rem" }}
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-expanded="false"
                aria-controls="collapse1"
              >
                Podsumowanie
              </button>
            </h2>
            <div
              id="collapse1"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body ">
                <CollapseWithIcon
                  title="Widoczność"
                  label={
                    enums.pagesStatusType[
                      Number(
                        watch("type")
                      ) as keyof typeof enums.pagesStatusType
                    ]
                  }
                  CollapseComponent={() => (
                    <>
                      <span className="text-muted">
                        Kontroluj sposób wyświetlania wpisu.
                      </span>
                      {pageData &&
                        pageStatus.map((status) => {
                          return (
                            <RadioInput
                              key={status.value}
                              register={register("type", {
                                required: true,
                              })}
                              label={status.name}
                              value={status.value}
                              checked={
                                watch("type")?.toString() === status.value
                              }
                              description={status.description}
                            />
                          );
                        })}

                      {watch("type") === 2 && (
                        <TextInput
                          style={{ width: "1500px" }}
                          className="mb-4"
                          placeholder="Wprowadź bezpieczne hasło"
                          register={register("password", {
                            required: watch("type") === 2,
                          })}
                        />
                      )}
                    </>
                  )}
                />
                <CollapseWithIcon
                  title="Opublikuj"
                  label={todayTimeIsoString.split("T").join(" ")}
                  CollapseComponent={() => (
                    <div className="d-flex gap-3 align-items-center">
                      <DateTimePicker
                        setValue={setValue}
                        name="date"
                        value={watch("date")}
                      />
                    </div>
                  )}
                />
                <CollapseWithIcon
                  title="Adres URL"
                  label={urlParam || pageData?.data?.url || "Dodaj url"}
                  CollapseComponent={() => (
                    <>
                      <TextInput
                        style={{ width: "1500px" }}
                        className="mb-1"
                        placeholder=""
                        register={register("url", {
                          onChange: (e) => {
                            setValue(
                              "url",
                              replacePolishLetters(e.target.value)
                            );
                          },
                        })}
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => setUrlParam(getValues("url"))}
                      >
                        Utwórz url
                      </Button>
                    </>
                  )}
                />
                <ComponentWithDescription className="my-2" label="Autor">
                  <TextInput
                    disabled
                    register={register("updatedBy", {
                      required: true,
                    })}
                  />
                </ComponentWithDescription>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
              >
                Strona nadrzędna
              </button>
            </h2>
            <div
              id="collapse2"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-3">
                <>
                  {pageData && (
                    <AsyncMultiSelect
                      data={pagesData}
                      setValue={setValue}
                      name="parentIds"
                      relativeoptions
                      menuIsOpen
                      asyncQuery={useLazyGetPagesQuery}
                      value={handleNewField(pageData?.data.parent)}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-expanded="false"
                aria-controls="collapse5"
              >
                Wybierz kraj
              </button>
            </h2>
            <div
              id="collapse5"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-3">
                <SingleSelect
                  value={[
                    {
                      label:
                        (pageData?.data?.country &&
                          `${pageData?.data?.country?.name} [${pageData?.data?.language?.name}]`) ||
                        "Polska [polski]",
                      value: pageData?.data?.country?.id || 1,
                      languageId: pageData?.data?.language?.id || 1,
                    },
                  ]}
                  data={availableCountriesData}
                  menuIsOpen
                  setValue={setValue}
                  name="country"
                  relativeoptions
                  isNotClearable
                  setHandleReset={setHandleReset}
                  language={"language"}
                />
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                Kategorie
              </button>
            </h2>
            <div
              id="collapse3"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-3">
                {pageData && (
                  <AsyncMultiSelect
                    data={pagesCategoryData}
                    setValue={setValue}
                    name="categoryIds"
                    value={handleNewField(pageData?.data.category)}
                    relativeoptions
                    menuIsOpen
                    asyncQuery={useLazyGetPagesCategoryQuery}
                    type="page"
                  />
                )}
                <CollapseWithIcon
                  label="Utwórz kategorię"
                  CollapseComponent={CategoryCollapse}
                />
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse7"
                aria-expanded="false"
                aria-controls="collapse7"
              >
                Obrazek wyróżniający
              </button>
            </h2>
            <div
              id="collapse7"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-3">
                {pageData && (
                  <MediaPicker
                    pageData={pageData}
                    setAttachment={setAttachment}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <CheckboxInput
          register={register("addActiveSubstance")}
          label="Dodaj substancje aktywne"
        />
        {!!watch("addActiveSubstance") && (
          <div
            className="accordion"
            id="accordionExample2"
            style={{ width: "18rem" }}
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse4"
                  aria-expanded="true"
                  aria-controls="collapse4"
                >
                  Nowotwór
                </button>
              </h2>
              <div
                id="collapse4"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-3">
                  <AsyncSelectLoading
                    value={handleNewField(cancerValue)}
                    data={cancersData}
                    setValue={setValue}
                    name="cancerIds"
                    menuIsOpen
                    relativeoptions
                    asyncQuery={useLazyGetCancersQuery}
                    setHandleReset={setHandleReset}
                  />
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse6"
                  aria-expanded="false"
                  aria-controls="collapse6"
                  disabled={!watch("cancerIds") || !watch("country")}
                  style={{
                    background:
                      !watch("cancerIds") || !watch("country") ? "#cccccc" : "",
                  }}
                >
                  Substancje Onkoindex
                </button>
              </h2>
              <div
                id="collapse6"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-3">
                  <AsyncMultiSelect
                    data={activeSubstanceData}
                    setValue={setValue}
                    name="substanceIds"
                    relativeoptions
                    menuIsOpen
                    value={handleNewField(pageData?.data.activeSubstance)}
                    asyncQuery={useLazyGetActiveSubstanceShortQuery}
                    watch={watch("substanceIds")}
                    reset={handleReset}
                    setHandleReset={setHandleReset}
                    handleField={handleActiveSubstance}
                  />
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                  disabled={!watch("cancerIds") || !watch("country")}
                  style={{
                    background:
                      !watch("cancerIds") || !watch("country") ? "#cccccc" : "",
                  }}
                >
                  Substancje Offline
                </button>
              </h2>
              <div
                id="collapse7"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample2"
              >
                <div className="accordion-body p-3">
                  <AsyncMultiSelect
                    data={sortSubstanceData}
                    setValue={setValue}
                    name="substanceOfflineIds"
                    relativeoptions
                    menuIsOpen
                    value={handleNewField(
                      pageData?.data?.activeSubstanceOffline
                    )}
                    asyncQuery={useLazyGetSubstanceQuery}
                    watch={watch("substanceOfflineIds")}
                    reset={handleReset}
                    setHandleReset={setHandleReset}
                    handleField={handleActiveSubstanceOffline}
                  />
                </div>
              </div>
            </div>

            {watch("cancerIds") &&
              watch("country") &&
              !watch("substanceIds").length && (
                <p className="text-danger fs-13">
                  Możesz dodać aktywne substancje
                </p>
              )}
          </div>
        )}
        <div className="card-footer text-muted">
          <Button
            onClick={handleSubmit(async (data) => {
              let cancerIdsArray: string[] = [];
              if (data?.cancerIds) {
                if (typeof data?.cancerIds === "number") {
                  cancerIdsArray.push(data?.cancerIds);
                } else {
                  cancerIdsArray.push(...data?.cancerIds);
                }
              }
              const postData: PageDataPost = {
                parentIds: data.parentIds,
                title: data.title,
                url: data.url,
                metaTitle: data.metaTitle,
                metaDescription: data.metaDescription,
                content: data.content,
                slug: data.slug,
                lead: data.lead,
                password: data.password,
                categoryIds: data.categoryIds,
                sortOrder: data.sortOrder,
                type: Number(data.type),
                attachments: data.attachments,
                updatedBy: data.updatedBy,
                cancerIds: data.addActiveSubstance
                  ? !!data?.cancerIds
                    ? cancerIdsArray
                    : []
                  : [],
                country: data.addActiveSubstance ? data.country : null,
                substanceIds: data.addActiveSubstance ? data.substanceIds : [],
                substanceOfflineIds: data.addActiveSubstance
                  ? data.substanceOfflineIds
                  : [],
                language: data.language,
              };
              await setDataSend(postData);

              const patchPage = await patchPageUpdate({
                id: linkParams.id,
                data: postData,
              });

              if ("data" in patchPage) {
                toast.success(t("toast_pageEditTemplate_edit_success"));
              } else if ("error" in patchPage) {
                toast.error(t("toast_pageEditTemplate_edit_error"));
              }
            })}
          >
            Zapisz zmiany
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageEditTemplate;
