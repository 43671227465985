import { Button, Container } from "react-bootstrap";
import PollPage from "../PollPage/PollPage";
import { Dispatch, SetStateAction } from "react";
import {
  UseFieldArrayReturn,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

interface PollPagesProps {
  pagesController: UseFieldArrayReturn<PollSendingData, "pages", "id">;
  setVisibleForm: Dispatch<SetStateAction<string>>;
}

const PollPages = ({ pagesController, setVisibleForm }: PollPagesProps) => {
  const {
    formState: { isSubmitting },
  }: UseFormReturn<PollPageFormValues> = useFormContext();
  const { fields: pageFields, append, remove, move } = pagesController;

  const handleBackBtnClick = () => {
    setVisibleForm("poll");
  };

  const buttonStyles = "lh-base text-light";

  return (
    <Container>
      {pageFields.map((pp, index) => (
        <PollPage
          key={pp.id}
          id={pp.id}
          index={index}
          maxIndex={pageFields.length - 1}
          remove={remove}
          move={move}
        />
      ))}

      <div className="d-flex justify-content-between py-2 px-3">
        <Button
          type="button"
          className={`${buttonStyles} me-2`}
          onClick={() =>
            append({
              title: "",
              description: "",
              isActive: 1,
              questions: [
                {
                  question: "",
                  description: "",
                  sfSynchro: false,
                  fieldName: `field_${uuidv4()}`,
                  fieldType: "",
                  isActive: 1,
                  type: "",
                  target: "",
                  isOutputTableName: false,
                  areLabelsNeeded: false,
                  meta: {},
                },
              ],
            })
          }
        >
          Dodaj stronę
        </Button>
        <div>
          <Button
            type="button"
            className={`${buttonStyles} ${pageFields.length > 0 && "me-2"}`}
            onClick={handleBackBtnClick}
          >
            Wróć
          </Button>
          {pageFields.length > 0 && (
            <Button
              type="submit"
              className={buttonStyles}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Wysyłanie..." : "Zapisz"}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PollPages;
