import { Button, Collapse } from "react-bootstrap";
import { useForm } from "react-hook-form";

import TextInput from "../../../atoms/TextInput/TextInput";
import { toast } from "react-toastify";
import { usePatchPagesCategoryMutation } from "redux/services/pagesCategoryApi";
import { useTranslation } from "react-i18next";

const QuickEditCategory = ({ data, open, setOpen }: any) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: data.title,
      content: data.content,
      url: data.url,
      parent_id: data?.parentId || "",
      status: data.status,
      created_by: data.createdBy,
    },
  });

  const { t } = useTranslation();

  const [patchPagesCategory] = usePatchPagesCategoryMutation();

  return (
    <Collapse in={open}>
      <>
        <span>Szybka edycja</span>
        <form
          onSubmit={handleSubmit(async (submitData) => {
            const patchData = {
              title: submitData.title,
              content: submitData.content,
              url: submitData.url,
              parent_id: submitData.parent_id,
              status: submitData.status,
              created_by: submitData.created_by,
              type: "page",
            };
            const pagesCategoryx = await patchPagesCategory({
              id: data.id,
              data: patchData,
            });
            setOpen(false);
            if ("data" in pagesCategoryx) {
              toast.success(t("toast_quickEditCategory_success"));
            } else if ("error" in pagesCategoryx) {
              toast.error(t("toast_quickEditCategory_error"));
            }
          })}
        >
          <div className="d-flex w-100 gap-5">
            <div className="w-100 d-flex flex-column gap-2">
              <TextInput label={"Nazwa"} register={register("title")} />
              <TextInput
                label={"Uproszczona nazwa"}
                register={register("url")}
              />
            </div>
          </div>
          <div className="d-flex gap-2 mt-4">
            <Button type="submit" value="Submit">
              Aktualizuj
            </Button>
            <Button className="btn-outline-blue" onClick={() => setOpen(false)}>
              Anuluj
            </Button>
          </div>
        </form>
      </>
    </Collapse>
  );
};

export default QuickEditCategory;
