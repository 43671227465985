import { capitalizeString } from "Helpers/capitalizeString";
import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useEditArticleMutation,
  usePostArticleMutation,
} from "redux/services/articlesApi";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

interface ArticleRssFormProps {
  data: ArticleFormData;
  action: string;
  rssTypes: RssType[];
  countries: Country[];
  statuses: Status[];
}

const ArticleRssForm = ({
  data,
  action,
  rssTypes,
  countries,
  statuses,
}: ArticleRssFormProps) => {
  const navigate = useNavigate();
  const linkParams = useParams();
  const [postArticleRssData] = usePostArticleMutation();
  const [editArticleRssData] = useEditArticleMutation();

  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ArticleFormData>({
    values: data && {
      name: data.name,
      slug: data.slug || "",
      url: data.url,
      type: data.type,
      categoryNames: data.categoryNames || "",
      countryId: data.countryId,
      status: data.status,
    },
  });

  const submitHandler: SubmitHandler<ArticleFormData> = async (data) => {
    const payload = {
      data: {
        ...data,
        countryId: +data.countryId,
        status: +data.status,
      },
    };

    const hasBeenSent =
      action === "PATCH"
        ? await editArticleRssData({ ...payload, id: linkParams?.id })
        : await postArticleRssData(payload);

    if ("data" in hasBeenSent) {
      action === "PATCH"
        ? toast.success(t("toast_articleRssForm_edit_success"))
        : toast.success(t("toast_articleRssForm_add_success"));
      navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.ARTICLES}`);
    } else if ("error" in hasBeenSent) {
      const error = hasBeenSent?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_articleRssForm_error_500"));

      if (action === "PATCH" && error.data.statusCode === 404) {
        toast.error(t("toast_articleRssForm_error_404"));
      }

      if (error.data.statusCode === 400)
        toast.error(t("toast_articleRssForm_error_400"));
    }
  };

  const formGroupStyles = "mb-3";
  const labelStyles = "mb-1 fs-14 lh-base";
  const formCheckStyles = "mb-0";

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group className={formGroupStyles}>
        <Form.Label className={labelStyles} htmlFor="articleRssName">
          Nazwa
        </Form.Label>
        <Form.Control
          id="articleRssName"
          {...register("name", {
            required: "Pole nie może być puste.",
            pattern: {
              value: /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/i,
              message: "Nazwa nie może zawierać znaków specjalnych.",
            },
          })}
          type="text"
          isInvalid={Boolean(errors.name)}
        />
        {errors.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <Form.Label className={labelStyles} htmlFor="articleRssSlug">
          Uproszczona nazwa (opcjonalne)
        </Form.Label>
        <Form.Control
          id="articleRssSlug"
          {...register("slug", {
            pattern: {
              value: /^[a-z0-9-]+$/,
              message:
                "Uproszczona nazwa jest przyjazną dla adresu URL wersją nazwy. Może ona zawierać wyłącznie małe litery, cyfry oraz znaki myślnika.",
            },
          })}
          type="text"
          isInvalid={Boolean(errors.slug)}
        />
        {errors.slug && (
          <Form.Control.Feedback type="invalid">
            {errors.slug.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <Form.Label className={labelStyles} htmlFor="articleRssUrl">
          Adres URL
        </Form.Label>
        <Form.Control
          id="articleRssUrl"
          {...register("url", {
            required: "Pole nie może być puste.",
            pattern: {
              value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
              message: "Wprowadź poprawny adres URL.",
            },
          })}
          type="text"
          isInvalid={Boolean(errors.url)}
        />
        {errors.url && (
          <Form.Control.Feedback type="invalid">
            {errors.url.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <div className={labelStyles}>Typ kanału RSS</div>
        {rssTypes.map((t, idx) => (
          <Form.Check
            key={idx}
            className={formCheckStyles}
            inline
            id={`article${capitalizeString(t.name)}Type`}
            {...register("type")}
            type="radio"
            label={t.label}
            value={t.value}
          />
        ))}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <Form.Label className={labelStyles} htmlFor="articleRssCategories">
          Nazwy kategorii artykułów (opcjonalne)
        </Form.Label>
        <Form.Control
          id="articleRssCategories"
          {...register("categoryNames", {
            validate: (value: string | undefined) =>
              value === undefined ||
              value.trim() === "" ||
              (/^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s,]+$/i.test(value)
                ? true
                : "Nazwy kategorii nie mogą zawierać znaków specjalnych, a każda kategoria powinna być oddzielona przecinkiem."),
          })}
          type="text"
          isInvalid={Boolean(errors.categoryNames)}
        />
        {errors.categoryNames && (
          <Form.Control.Feedback type="invalid">
            {errors.categoryNames.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <Form.Label className={labelStyles} htmlFor="articleRssCountry">
          Kraj
        </Form.Label>
        <Form.Select
          id="articleRssCountry"
          {...register("countryId", {
            required: "Wybierz kraj dla którego chcesz dodać kanał RSS.",
            min: {
              value: 1,
              message: "Wybierz kraj dla którego chcesz dodać kanał RSS.",
            },
          })}
          isInvalid={Boolean(errors.countryId)}
        >
          <option value={0} disabled>
            Wybierz
          </option>
          {countries?.map((c: Country) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </Form.Select>
        {errors.countryId && (
          <Form.Control.Feedback type="invalid">
            {errors.countryId.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className={formGroupStyles}>
        <div className={labelStyles}>Status</div>
        {statuses.map((s, idx) => (
          <Form.Check
            key={idx}
            className={formCheckStyles}
            inline
            id={`article${capitalizeString(s.name)}Status`}
            {...register("status")}
            type="radio"
            label={s.label}
            value={s.value}
            checked={s.checked}
          />
        ))}
      </Form.Group>

      <div className="container-fluid d-flex justify-content-end px-0">
        <Button className="lh-base text-uppercase text-light" type="submit">
          Wyślij
        </Button>
      </div>
    </Form>
  );
};

export default ArticleRssForm;
