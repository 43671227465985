import React, { Fragment, useContext, useState } from "react";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "../TableRowWithCollapse/TableRowWithCollapse.module.scss";
import { format, parseISO } from "date-fns";
import {
  useDeleteFeedTagMutation,
  useDeleteFeedTopicMutation,
} from "../../../redux/services/feedApi";
import { TypeContext } from "../../templates/FeedCategoryTemplate/FeedCategoryTemplate";
import PopupModal from "../../molecules/PopupModal/PopupModal";

const FeedTableRowWithCollapse = ({
  data,
  CollapseItem,
}: FeedTableRowWithCollapse) => {
  const [open, setOpen] = useState(false);
  const [showModalEnabled, setShowModalEnabled] = useState<boolean>(false);

  const type = useContext(TypeContext);

  const [deleteFeedTag] = useDeleteFeedTagMutation();
  const [deleteFeedTopic] = useDeleteFeedTopicMutation();

  const breadcrumbData = [
    {
      name: "Edycja",
      action: () => setOpen(!open),
    },
    {
      name: "Kosz",
      action: () => setShowModalEnabled(true),
    },
  ];

  const modalEnabledHandler = async () => {
    switch (type) {
      case "tags":
        await deleteFeedTag({
          id: data.id.toString(),
        });
        break;
      case "topics":
        await deleteFeedTopic({
          id: data.id.toString(),
        });
        break;
    }
    setShowModalEnabled(false);
  };

  const formatISODate = (date: string) => {
    if (date) {
      const parsedDate = parseISO(date);
      const formattedDate = format(parsedDate, "dd.MM.yyyy HH:mm");

      return formattedDate;
    }
    return "Brak danych";
  };

  return (
    <>
      <Fragment>
        <tr
          aria-controls={`example-collapse-text`}
          aria-expanded={open}
          className={`${styles.mainTr}`}
        >
          <td>{data.name}</td>
          <td>{data.delete === 0 ? "Aktywny" : "Nieaktywny"}</td>
          <td>{formatISODate(data?.createdAt)}</td>
        </tr>
        {!open && (
          <tr className={`${styles.hoverTr}`}>
            <td colSpan={4}>
              <Breadcrumb data={breadcrumbData} />
            </td>
          </tr>
        )}

        {open && (
          <tr className={`${styles.collapseTr}`} data-active={open}>
            <td colSpan={4}>
              <CollapseItem open={open} data={data} setOpen={setOpen} />
            </td>
          </tr>
        )}
      </Fragment>
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz usunąć ten filtr?`}
        type="confirm"
        show={showModalEnabled}
        setShow={() => setShowModalEnabled(false)}
        confirmAction={() => modalEnabledHandler()}
      />
    </>
  );
};

export default FeedTableRowWithCollapse;
