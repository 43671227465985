import styles from "../../templates/FeedTemplate/FeedTemplate.module.scss";
import React from "react";

export const Loading = ({ propStyles }: Loading) => {
  return (
    <div
      className={`col-12 d-flex justify-content-end ${styles.loading} ${propStyles}`}
    >
      <span />
    </div>
  );
};
