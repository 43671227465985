import "./RadioInput.scss";

const RadioInput = ({
  label,
  register,
  value,
  description,
  labelClasses,
  customClasses,
}: RadioInputProps) => {
  return (
    <>
      <label
        className={
          labelClasses
            ? labelClasses
            : `form-check-label form-check m-0 me-3 ${customClasses}`
        }
      >
        <input
          className="form-check-input "
          type="radio"
          value={value}
          {...register}
        />
        {label}
      </label>
      {description && <span className="text-muted fs-13">{description}</span>}
    </>
  );
};

export default RadioInput;
