import { useEffect, useState } from "react";
import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import styles from "./ArticleTemplate.module.scss";
import ArticlesCollapse from "components/molecules/CollapseComponents/ArticlesCollapse/ArticlesCollapse";
import { AccordionCard } from "../../molecules/AccordionCard/AccordionCard";
import { Col, Container, Row, Stack, Button } from "react-bootstrap";
import { ArticleCard } from "components/molecules/ArticleCard/ArticleCard";
import { useSwitch } from "Helpers/useSwitch";
import { useFilter } from "Helpers/useFilter";
import { useSearchParams } from "react-router-dom";
import { useGetArticlesQuery } from "redux/services/articlesApi";
import { fetchArticlesData } from "Helpers/fetchArticlesData";
import { categories } from "./articlesData"; // trzeba wydzielić
import {
  useGetCancerQuery,
  useGetCancersQuery,
} from "redux/services/cancerApi";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import { useSelector } from "react-redux";
import type { RootState } from "redux/store/index";
import { Spinner } from "react-bootstrap";

interface InitialSearchParams {
  page: number;
  limit: number;
}

interface ArticleData {
  id: string;
  articleSrc?: string;
  categories?: string;
  description: string;
  imgSrc?: string;
  link: string;
  title: string;
}

export const INITIAL_SEARCH_PARAMS: InitialSearchParams = {
  page: 1,
  limit: 0,
};

const ArticlesTemplate = () => {
  const [searchParams] = useSearchParams();

  const [visibleCount, setVisibleCount] = useState(9);
  const [articleSources, setArticleSources] = useState<string[]>([]);
  const [articlesData, setArticlesData] = useState<ArticleData[]>([]);
  const [savedArticlesId, setSavedArticlesId] = useState<string[] | null>(null);
  const [displayedArticles, setDisplayedArticles] =
    useState<ArticleData[]>(articlesData);
  const [articlesTopics, setArticlesTopics] = useState<ReactSelectOption[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<ReactSelectOption>({
    value: "",
    label: "Wszystkie",
  });
  const [filteredTopic, setFilteredTopic] = useState("");
  const [articlesCancer, setArticlesCancer] = useState<ReactSelectOption[]>([]);
  const [selectedCancer, setSelectedCancer] = useState<ReactSelectOption>({
    value: "",
    label: "Wszystkie",
  });
  const [filteredCancer, setFilteredCancer] = useState("");
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  const currRssPage = Number(searchParams.get("rssPage")) || 1;

  const { data: articleRssData } = useGetArticlesQuery({
    offset: currRssPage
      ? ((currRssPage - 1) * INITIAL_SEARCH_PARAMS.limit).toString()
      : INITIAL_SEARCH_PARAMS.page.toString(),
    limit: INITIAL_SEARCH_PARAMS.limit.toString(),
    sortBy: searchParams.get("rssSortBy") ?? ",",
    categoryName: searchParams.get("rssCategory") ?? "",
    nameLike: searchParams.get("rssNameLike") ?? "",
  });
  const { data: cancersData } = useGetCancersQuery({
    limit: "0",
    status: "1",
  });
  const { data: meData, isLoading: isLoadingGetMe } = useGetMeQuery({});
  const [patchMe, { isLoading: isLoadingPatchMe }] = usePatchMeMutation();

  const { authUser } = useSelector((state: RootState) => state.user);

  const { data: userCancer } = useGetCancerQuery(
    { id: authUser?.settings?.ID_nowotworu_HUB__c ?? "" },
    { skip: !authUser?.settings?.ID_nowotworu_HUB__c }
  );

  useEffect(() => {
    if (userCancer) {
      setSelectedCancer({
        value: userCancer?.data?.id,
        label: userCancer?.data?.name,
      });
      setFilteredCancer(userCancer?.data?.id);
    }
  }, [userCancer]);

  const { isSwitched: showSaved, switchChangeHandler: switchSaveHandler } =
    useSwitch("showSavedArticles");
  const {
    selectedValues: selectedSources,
    filterClickHandler: filterSourceHandler,
  } = useFilter("articleSources");

  useEffect(() => {
    const fetchArticleSources = async () => {
      const sources: string[] = await articleRssData?.articles?.reduce(
        (acc: string[], currentRss: ArticleRss) =>
          acc?.includes(currentRss?.name) ? acc : [...acc, currentRss?.name],
        []
      );

      if (sources) setArticleSources(sources);
    };

    const fetchArticleTopics = async () => {
      const filteredArticles =
        articleRssData?.articles?.filter(
          (article: ArticleRss) => article?.type === "wp-json"
        ) || [];

      const flattenedTopics = filteredArticles.flatMap(
        (article: ArticleRss) => {
          if (article?.topics) {
            const topicsObject = JSON.parse(article?.topics);
            return Object.entries(topicsObject)?.map(([id, name]) => ({
              value: id?.toString(),
              label: name?.toString(),
            }));
          } else {
            return [];
          }
        }
      );

      flattenedTopics.unshift({ value: "", label: "Wszystkie" });

      const uniqueTopics: ReactSelectOption[] = Array.from(
        flattenedTopics
          ?.reduce(
            (map: Map<string, ReactSelectOption>, topic: ReactSelectOption) => {
              const key = topic?.value + topic?.label;
              if (!map.has(key)) {
                map.set(key, topic);
              }
              return map;
            },
            new Map()
          )
          ?.values()
      );

      if (uniqueTopics.length > 0) setArticlesTopics(uniqueTopics);
    };

    const fetchArticles = async () => {
      setIsLoadingArticles(true);
      const articlePromises = await articleRssData?.articles?.map(
        (article: ArticleRss) =>
          fetchArticlesData(article, {
            topicId: filteredTopic,
            cancerId: filteredCancer,
            perPage: "100",
          })
      );

      const articlePromiseSettled = await Promise.allSettled(articlePromises);
      const articleData = await articlePromiseSettled
        .filter((result) => result?.status === "fulfilled")
        .map((result) => {
          if (result?.status === "fulfilled") {
            return result?.value;
          }
          return null;
        })
        .filter((result) => result !== null);

      const flattenedData = articleData?.flat();

      setIsLoadingArticles(false);
      setArticlesData(flattenedData);
    };

    if (articleRssData?.articles) {
      fetchArticleSources();
      fetchArticleTopics();
      fetchArticles();
    }
  }, [articleRssData?.articles, filteredTopic, filteredCancer, visibleCount]);

  useEffect(() => {
    setDisplayedArticles(articlesData);
  }, [articlesData]);

  useEffect(() => {
    const cancerOptions =
      cancersData?.data?.map(({ name, id }: Cancer) => ({
        label: name,
        value: id,
      })) || [];

    cancerOptions?.unshift({ value: "", label: "Wszystkie" });

    setArticlesCancer(cancerOptions);
  }, [cancersData]);

  useEffect(() => {
    if (
      meData?.data &&
      Array.isArray(meData?.data?.localSettings?.saved_articlesId)
    )
      setSavedArticlesId(meData?.data?.localSettings?.saved_articlesId);
    //eslint-disable-next-line
  }, [meData?.data?.localSettings]);

  const updateSavedArticles = async (articleId: string) => {
    let savedArticlesData: string[] = savedArticlesId || [];

    if (isArticleSaved(articleId)) {
      savedArticlesData = savedArticlesData.filter((id) => id !== articleId);
    } else {
      savedArticlesData = [...savedArticlesData, articleId];
    }

    setSavedArticlesId(savedArticlesData);

    if (Array.isArray(savedArticlesData)) {
      try {
        await patchMe({
          data: {
            ...authUser,
            localSettings: {
              ...authUser.localSettings,
              saved_articlesId: savedArticlesData,
            },
          },
        });
      } catch (error) {
        console.error("Błąd podczas aktualizacji danych użytkownika:", error);
      }
    }
    return;
  };

  const showMore = () => {
    setVisibleCount(visibleCount + 9);
  };

  const clearFilters = () => {
    filterTopicHandler("", "");
    filterSourceHandler("");
    switchSaveHandler({
      target: { checked: false },
    } as React.ChangeEvent<HTMLInputElement>);
    setFilteredTopic("");
    setSelectedTopic({ value: "", label: "Wszystkie" });
    setFilteredCancer("");
    setSelectedCancer({ value: "", label: "Wszystkie" });
    setDisplayedArticles(articlesData);
  };

  const applyFilters = () => {
    const filteredArticles = articlesData
      .filter((articleObj) =>
        !showSaved ? true : savedArticlesId?.includes(articleObj?.id)
      )
      .filter((article?) =>
        selectedSources?.length === 0
          ? true
          : selectedSources?.includes(article?.articleSrc ?? "")
      );

    setFilteredTopic(selectedTopic?.value);
    setFilteredCancer(selectedCancer?.value);
    setDisplayedArticles(filteredArticles);
  };

  const isArticleSaved = (articleId: string) =>
    savedArticlesId?.includes(articleId);

  const filterTopicHandler = (topicId?: string, topicLabel?: string) => {
    setSelectedTopic({
      value: topicId || "",
      label: topicLabel || "Wszystkie",
    });
  };

  const filterCancerHandler = (cancerId?: string, cancerLabel?: string) => {
    setSelectedCancer({
      value: cancerId || "",
      label: cancerLabel || "Wszystkie",
    });
  };

  return (
    <Container fluid className="bg-light">
      <section className={styles.section}>
        <Stack gap={4}>
          <Row>
            <span className={`me-lg-1`}>
              <SectionTitle className={`mb-3 ${styles.title}`}>
                Artykuły
              </SectionTitle>
            </span>
          </Row>
          <Row>
            <AccordionCard
              title={"Filtrowanie artykułów"}
              child={
                <ArticlesCollapse
                  categories={categories}
                  sources={articleSources}
                  topics={articlesTopics}
                  showSaved={showSaved}
                  onSwitchSaved={switchSaveHandler}
                  selectedTopic={selectedTopic}
                  onTopicChange={filterTopicHandler}
                  selectedSources={selectedSources}
                  onSourceChange={filterSourceHandler}
                  onFiltersReset={clearFilters}
                  onApplyFilters={applyFilters}
                  cancers={articlesCancer}
                  onCancerChange={filterCancerHandler}
                  selectedCancer={selectedCancer}
                />
              }
              classAccordion={`containerWithShadow p-0`}
            />
          </Row>
          <Row>
            {!isLoadingArticles ? (
              <Container fluid className="px-0">
                <Row xs={1} lg={2} xl={3} className="g-4">
                  {displayedArticles?.length > 0
                    ? displayedArticles
                        ?.slice(0, visibleCount)
                        ?.map((article) => (
                          <Col
                            className="d-flex align-items-stretch"
                            key={article?.id}
                          >
                            <ArticleCard
                              articleSrc={article?.articleSrc}
                              categories={article?.categories}
                              description={article?.description}
                              imgSrc={article?.imgSrc}
                              isArticleSaved={isArticleSaved(article?.id)}
                              link={article?.link}
                              onIconClick={() =>
                                updateSavedArticles(article?.id)
                              }
                              title={article?.title}
                              isPending={isLoadingPatchMe || isLoadingGetMe}
                            />
                          </Col>
                        ))
                    : null}
                </Row>
                {displayedArticles?.length === 0 && (
                  <Container
                    fluid
                    className={`${styles.noEventsContainer} d-flex flex-column align-items-center`}
                  >
                    <h3 className={`${styles.noEventsHeader} text-center mb-4`}>
                      Brak wydarzeń spełniających kryteria filtrów
                    </h3>
                    <Button variant="primary" onClick={clearFilters}>
                      WYCZYŚĆ FILTRY
                    </Button>
                  </Container>
                )}
                {displayedArticles?.length < visibleCount ||
                  (displayedArticles?.length > 0 && (
                    <div className="d-flex justify-content-center align-items-center my-5 ">
                      <Button onClick={showMore}>Pokaż więcej</Button>
                    </div>
                  ))}
              </Container>
            ) : (
              <Container className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </Container>
            )}
          </Row>
        </Stack>
      </section>
    </Container>
  );
};

export default ArticlesTemplate;
