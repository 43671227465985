import { CommentInput } from "components/atoms/CommentInput/CommentInput";
import { FeedActionIcon } from "components/atoms/FeedActionIcon/FeedActionIcon";
import { LinkPreview } from "components/atoms/LinkPreview/LinkPreview";
import { UserAvatarAndName } from "components/atoms/UserAvatarAndName/UserAvatarAndName";
import { Comment } from "components/molecules/Comment/Comment";
import { useEffect, useState } from "react";
import { Image, Row, Stack } from "react-bootstrap";
import Linkify from "react-linkify";
import {
  usePostLikeMutation,
  usePostUnLikeMutation,
  useGetCommentsByIdQuery,
  usePostPostsAbuseMutation,
} from "redux/services/feedApi";
import { ReactComponent as ClockIcon } from "../../../assets/Icons/Clock.svg";
import { ReactComponent as CommentIcon } from "../../../assets/Icons/Comment.svg";
import { ReactComponent as LikeIcon } from "../../../assets/Icons/Like.svg";
import { ReactComponent as ReportIcon } from "../../../assets/Icons/Report.svg";
import styles from "./FeedPost.module.scss";
import { toast } from "react-toastify";
import { ShowOlder } from "components/atoms/ShowOlder/ShowOlder";
import React from "react";
import { AddPostModal } from "../AddPostModal/AddPostModal";
import { RootState } from "redux/store/index";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RejectModal } from "../RejectModal/RejectModal";
import { useTranslation } from "react-i18next";
import { usePostNotificationsMutation } from "../../../redux/services/notificationApi";
import { domain } from "../Landing/Landing";
import { NAV_PATH } from "../Navigation/navigationData";

const LIMIT_COMMENTS = 0;

export const FeedPost = ({
  id,
  feedTag,
  topic,
  content,
  attachment,
  likesAmount,
  createdAt,
  like,
  user,
  savedPostHandler,
  currentUser,
  status,
  isCommentingAllowed,
  isPostClickable,
  setTags,
  setFilters,
  forwardedRef,
  comment,
  refetchPost,
}: FeedPostProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { authUser } = useSelector((state: RootState) => state.user);
  const [isPostLiked, setPostLiked] = useState<boolean>(false);
  const [postContent, setPostContent] = useState<string>(content);
  const [limit, setLimit] = useState<number>(3);
  const [edit, setEdit] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);

  const { feedId, commentId } = useParams();

  const [likePostAction] = usePostLikeMutation();
  const [unLikePostAction] = usePostUnLikeMutation();
  const [patchPostAbuse] = usePostPostsAbuseMutation();
  const [comments, setComments] = useState<IComment[]>([]);
  const {
    data: commentsData,
    isSuccess: commentsDataOk,
    refetch: rerenderComments,
  } = useGetCommentsByIdQuery({
    feedId: feedId || id?.toString(),
    limit: commentId ? LIMIT_COMMENTS.toString() : limit.toString(),
    sortOrder: "DESC",
  });

  const [notification] = usePostNotificationsMutation();

  useEffect(() => {
    if (feedId) {
      const targetElement = document.getElementById(`feedPost${id}`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    // eslint-disable-next-line
  }, [commentId]);

  useEffect(() => {
    setPostContent(content);
    setPostLiked(
      like?.filter((like: PostLikes) => like?.id === authUser?.id)?.length > 0,
    );
  }, [content, like, authUser?.id]);

  const handleLike = async () => {
    if (!isPostLiked) {
      setPostLiked(true);
      await likePostAction({ id: id });
      await notification({
        data: {
          user: user?.authId,
          title: t("notification_FeedPost_like_title"),
          viewSender: 1,
          type: 3,
          content: t("notification_FeedPost_like_content").replace(
            "%name%",
            authUser?.name,
          ),
          url: `${domain}/${NAV_PATH.FEED}/${id}`,
        },
      });
    } else {
      setPostLiked(false);
      await unLikePostAction({ id: id });
    }
  };

  useEffect(() => {
    if (commentsDataOk && commentsData) {
      setComments(commentsData?.data);
    }
  }, [commentsDataOk, commentsData, authUser?.id, authUser?.roles, id]);

  const handleReport = async (content: string) => {
    if (user.id !== currentUser.id) {
      const postAbuse = await patchPostAbuse({
        data: {
          reviewId: id,
          content,
        },
      });
      if ("data" in postAbuse) {
        toast.success(`${t("toast_feedPost_success")} ${user.name}`);
      } else if ("error" in postAbuse) {
        const error = postAbuse?.error as TranslationSubmitError;

        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_feedPost_error_500"));
            break;
          case 400:
            toast.error(t("toast_feedPost_error_400"));
            break;
          default:
            toast.error(t("toast_feedPost_error"));
            break;
        }
      }
    } else {
      toast.error(t("toast_feedPost_report_myself_error"));
    }
  };

  return (
    <>
      <Row
        xl={1}
        ref={forwardedRef && forwardedRef}
        onClick={() => isPostClickable && navigate(`/${NAV_PATH.FEED}/${id}`)}
        id={`feedPost${id}`}
        className={`containerWithShadow ${isPostClickable && "cursor-pointer"}`}
      >
        <article className={`${styles.feedPosts} px-0 mx-0`}>
          <Row className="px-4">
            <UserAvatarAndName
              userName={user?.name}
              userAvatar={user?.avatar?.accessUrl}
              className={`col-12 ps-2 mx-0 mb-4`}
              isOnFeed
              feedIcon={<ClockIcon />}
              feedWhenAdded={createdAt}
              feedCancerType={topic?.[0]?.name}
              savedPostHandler={savedPostHandler}
              id={id?.toString()}
              bookmarkToggle={true}
              status={status}
              userId={user?.id}
              userAuthId={user?.authId}
              setEdit={setEdit}
              key={id}
            />
          </Row>
          {feedTag?.length > 0 && (
            <Row className={`${styles.feedPostTags} px-4 mb-4`}>
              {feedTag.map((tag, index) => (
                <React.Fragment key={index}>
                  <span
                    className="col-auto cursor-pointer"
                    onClick={() => {
                      setTags([
                        { id: tag.id, label: tag.name, value: tag.name },
                      ]);
                      setFilters(true);
                    }}
                  >
                    {tag.name}
                  </span>
                </React.Fragment>
              ))}
            </Row>
          )}
          <Row className={`${styles.feedPostContent} px-4`}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  href={decoratedHref}
                  key={key}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {decoratedText}
                </a>
              )}
            >
              <p className="text-wrap text-break text-dark-blue">{content}</p>
            </Linkify>
          </Row>
          {attachment && attachment.length > 0 ? (
            <Row>
              <div className={`${styles.postImage}`}>
                <Image
                  fluid
                  src={attachment[attachment.length - 1].accessUrl}
                  alt="post-image"
                ></Image>
              </div>
            </Row>
          ) : (
            <LinkPreview content={postContent} />
          )}
          <Row
            className={`w-100 d-flex justify-content-center flex-wrap px-0 mx-0`}
          >
            <FeedActionIcon
              svg={<LikeIcon />}
              liked={isPostLiked}
              content={likesAmount}
              className="col-4 cursor-pointer"
              onClick={handleLike}
            />
            <FeedActionIcon
              svg={<CommentIcon />}
              content={comment?.length}
              className="col-4"
            />
            <FeedActionIcon
              svg={<ReportIcon />}
              onClick={() => setShowReject(true)}
              className="col-4 cursor-pointer"
            />
          </Row>
          {isCommentingAllowed && (
            <>
              <Row className="w-100 px-4 mx-0 mt-3 d-flex justify-content-center flex-wrap">
                <CommentInput
                  placeholder="Napisz komentarz..."
                  postId={id}
                  rerenderer={rerenderComments}
                  author={user}
                />
              </Row>
              {comments &&
                comments?.length > 0 &&
                comments?.map((comment: IComment) => {
                  return (
                    <Row className="w-100 mt-4 mb-2 mx-0 px-0" key={comment.id}>
                      <Stack gap={1} className="px-0 mx-0">
                        <Comment
                          id={comment.id}
                          postId={id}
                          content={comment.content}
                          author={comment.user}
                          commentWhenAdded={comment.createdAt}
                          className="col-12"
                          key={comment.id}
                          replies={comment.child}
                          rerenderer={rerenderComments}
                          status={comment.status}
                        />
                      </Stack>
                    </Row>
                  );
                })}
              <ShowOlder
                count={commentsData?.meta?.count}
                dataDiff={3}
                setLimit={setLimit}
                limit={limit}
              />
            </>
          )}
        </article>
      </Row>
      <RejectModal
        label={"Zgłoszenie"}
        desc={"Podaj powód zgłoszenia"}
        show={showReject}
        handleClose={() => setShowReject((prev) => !prev)}
        handleComments={handleReport}
        comments={""}
      />
      <AddPostModal
        setShow={setEdit}
        show={edit}
        user={user}
        isEdit={edit}
        data={{
          id: id,
          content: content,
          image: attachment && attachment[0],
          tag: feedTag,
          topic: topic,
          refetchPost: refetchPost,
        }}
      />
    </>
  );
};
