import { Button } from "react-bootstrap";
import { ReactComponent as AccordionIconDown } from "../../../assets/Icons//AccordionIconDown.svg";

interface AccordionToggleButtonProps {
  className?: string;
  variant?: string;
  isActive: boolean;
  onClick: () => void;
}

const AccordionToggleButton = ({
  variant = "light",
  className = "",
  isActive,
  onClick,
}: AccordionToggleButtonProps) => (
  <Button
    variant={variant}
    className={className}
    type="button"
    onClick={onClick}
  >
    <AccordionIconDown style={{ rotate: isActive ? "180deg" : "0deg" }} />
  </Button>
);

export default AccordionToggleButton;
