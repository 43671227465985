import { articles } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetArticlesProps {
  limit?: string;
  offset?: string;
  sortBy?: string;
  categoryName?: string;
  nameLike?: string;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Articles"],
});

export const articlesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    postArticle: builder.mutation({
      query: ({ data }) => ({
        url: `${articles}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Articles"],
    }),

    getArticles: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        categoryName,
        nameLike,
      }: GetArticlesProps) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(categoryName && { categoryName }),
          ...(nameLike && { nameLike }),
        });

        return {
          url: `${articles}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Articles"],
    }),

    getArticle: builder.query({
      query: (id) => {
        return {
          url: `${articles}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Articles"],
    }),

    editArticle: builder.mutation({
      query: ({ id, data }) => ({
        url: `${articles}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Articles"],
    }),
  }),
});

export const {
  usePostArticleMutation,
  useGetArticlesQuery,
  useGetArticleQuery,
  useEditArticleMutation,
} = articlesApi;
