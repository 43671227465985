import React, { useState } from "react";
import SortArrowIcon from "components/atoms/SortArrowIcon/SortArrowIcon";
import styles from "./SortButton.module.scss";

interface SortButtonProps {
  className?: string;
  label: string;
  isArrowRotated?: boolean;
  onClick?: () => void;
}

const SortButton = (props: SortButtonProps) => {
  const [isNameBtnFocussed, setIsNameBtnFocussed] = useState<boolean>(false);

  return (
    <button
      className={`${styles.button} ${props.className}`}
      onClick={props.onClick}
      onFocus={() => setIsNameBtnFocussed(true)}
      onBlur={() => setIsNameBtnFocussed(false)}
    >
      <span className="me-1">{props.label}</span>
      <SortArrowIcon
        isRotated={props.isArrowRotated}
        isFocussed={isNameBtnFocussed}
      />
    </button>
  );
};

export default SortButton;
