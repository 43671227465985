import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { doctorApi } from "redux/services/doctorApi";
import { cancersApi } from "redux/services/cancerApi";
import { citiesApi } from "redux/services/citiesApi";
import { provincesApi } from "redux/services/provincesApi";
import { facilitiesApi } from "redux/services/facilitiesApi";
import { doctorSpecializationApi } from "redux/services/doctorSpecializationApi";
import { Form } from "react-bootstrap";
import {
  customStyles,
  DropdownIndicator,
} from "../../../../styles/reactSelectCustomStyles";
import {
  City,
  Province,
} from "components/molecules/LoyaltyProgramServiceArticle/types";
import { slugify } from "Helpers/slugify";
import { useDispatch } from "react-redux";
import {
  updateSelectValue,
  updateDoctorId,
  updateFacilityId,
} from "redux/Slice/select-slice";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

type UnifiedOption = {
  label: string;
  value: string;
};

export default function SelectInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
  setPollDefaultValueLoadingState,
}: InputComponentProps) {
  const {
    register,
    formState: { errors, isSubmitted },
    setValue,
    watch,
  } = useFormContext();
  const [options, setOptions] = useState<UnifiedOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: doctorsData } = doctorApi.useGetDoctorsQuery({
    offset: "0",
    limit: "100",
    nameLike: searchTerm,
  });
  const { data: cancersData } = cancersApi.useGetCancersQuery({
    offset: "0",
    limit: "0",
  });
  const { data: citiesData } = citiesApi.useGetCitiesQuery({
    offset: "0",
    limit: "0",
    q: searchTerm,
  });
  const { data: provincesData } = provincesApi.useGetProvincesQuery({
    offset: "0",
    limit: "0",
  });
  const { data: facilitiesData } = facilitiesApi.useGetFacilitiesQuery({
    offset: "0",
    limit: "0",
    sortBy: "ASC",
    nameLike: searchTerm,
  });
  const { data: doctorSpecializationData } =
    doctorSpecializationApi.useGetDoctorSpecializationsQuery({
      offset: "0",
      limit: "0",
    });

  const doctorIdRef = useRef<string>("0");
  const facilityIdRef = useRef<string>("0");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let unifiedOptions = [];
        if (question?.meta.some((meta) => meta?.key === "answers")) {
          const answers = question?.meta.find(
            (meta) => meta.key === "answers"
          )?.value;
          const predefinedOptions = answers?.split("||") || [];
          unifiedOptions = predefinedOptions.map((option) => ({
            label: option,
            value: option,
          }));
        } else if (question?.meta?.some((meta) => meta.key === "table_name")) {
          const tableName = question?.meta.find(
            (meta) => meta.key === "table_name"
          )?.value;
          switch (tableName) {
            case "doctor":
              if (doctorsData?.data) {
                unifiedOptions = doctorsData.data.map((doctor: Doctor) => {
                  const fullName = `${doctor?.firstName} ${doctor?.lastName}`;
                  return { label: fullName, value: fullName };
                });
              }
              break;
            case "oi_cancer":
              if (cancersData?.data) {
                const filteredData = cancersData.data.filter(
                  (cancer: Cancer) => cancer.status === 1
                );
                unifiedOptions = filteredData.map((cancer: Cancer) => ({
                  label: cancer.name,
                  value: cancer.id,
                }));
              }
              break;
            case "province":
              unifiedOptions =
                provincesData?.data?.map((province: Province) => ({
                  label: province?.name,
                  value: province?.name,
                })) || [];
              break;
            case "city":
              unifiedOptions =
                citiesData?.data?.map((city: City) => ({
                  label: city?.name,
                  value: city?.name,
                })) || [];
              break;
            case "facility":
              if (facilitiesData?.data) {
                unifiedOptions = facilitiesData.data.map(
                  (facility: Facility) => {
                    return { label: facility?.name, value: facility?.name };
                  }
                );
              }
              break;
            case "doctor_specialization":
              unifiedOptions =
                doctorSpecializationData?.data?.map(
                  (spec: DoctorSpecializationsData) => ({
                    label: spec?.name,
                    value: spec?.name,
                  })
                ) || [];
              break;
            default:
              break;
          }
        }
        setOptions(unifiedOptions);
      } catch (e: unknown) {
        console.error(e);
      }
    };

    fetchOptions();
  }, [
    question.meta,
    doctorsData,
    cancersData,
    provincesData,
    citiesData,
    facilitiesData,
    doctorSpecializationData,
  ]);

  useEffect(() => {
    if (options.length > 1) {
      setPollDefaultValueLoadingState((prevState) => ({
        ...prevState,
        isSelectInputLoading: false,
      }));
    }
    //eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, Number(defaultValue));
    }
  }, [defaultValue, setValue, question.fieldName]);

  const handleSelectChange = (selectedOption: SelectOption | null) => {
    const value = selectedOption?.value || "";
    const effectiveValue = areAnswersAsSlug ? slugify(value) : value;

    setValue(question?.fieldName, effectiveValue);

    dispatch(
      updateSelectValue({
        fieldName: question?.fieldName,
        value: effectiveValue,
      })
    );
    dispatch(updateDoctorId(doctorIdRef.current));
    dispatch(updateFacilityId(facilityIdRef.current));
  };

  const handleInputChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const areAnswersAsSlug = validationMeta
    ? JSON.parse(validationMeta.value)?.areAnswersAsSlug ?? false
    : false;
  const selectOptions = options.map((option: UnifiedOption) => ({
    value: areAnswersAsSlug ? slugify(option.value) : option.value,
    label: option.label,
  }));

  const watchedValue = areAnswersAsSlug
    ? slugify(watch(question.fieldName, ""))
    : watch(question.fieldName, "");

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <Select
        {...register(question?.fieldName, {
          required: requiredMessage,
        })}
        className="basic-single mt-2 mb-2"
        classNamePrefix="select"
        defaultValue={selectOptions.find(
          (option) => option?.value === watchedValue
        )}
        isClearable
        isSearchable
        options={selectOptions}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        value={selectOptions.find((option) => option?.value === watchedValue)}
        placeholder="Wybierz z listy"
        isDisabled={readOnly}
        styles={customStyles(false)}
        components={{ DropdownIndicator }}
      />
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
