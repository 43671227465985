import { Container, Row, Spinner } from "react-bootstrap";
import { OncocollectionsEntity } from "../../molecules/OncocollectionsEntity/OncocollectionsEntity";
import styles from "./Oncocollections.module.scss";
import { useGetSFContactQuery } from "redux/services/salesforceApi";
import { isOneOfObjectValuesEmpty } from "../../../Helpers/isOneOfObjectValuesEmpty";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
}

export const Oncocollections = ({ name }: Props) => {
  const { t } = useTranslation();

  const companiesIsLoading = false;

  const { data: sfContactData } = useGetSFContactQuery({});

  const contactData = sfContactData?.data;

  const processedContactData = contactData && {
    name: contactData?.Wskazanie_na_skarbonke__r?.Tytul_zbiorki__c?.toString(),
    value: (
      contactData?.Wskazanie_na_skarbonke__r?.Dost_pne_rodki__c +
      -contactData?.Wskazanie_na_skarbonke__r?.Wyplaty__c
    )?.toString(),
    max: contactData?.Wskazanie_na_skarbonke__r?.Cel_zbi_rki__c?.toString(),
    link: contactData?.Wskazanie_na_skarbonke__r?.Adres__c?.toString(),
  };

  return (
    <Container fluid={true} className={`p-4 containerWithShadow `}>
      <Row>
        <p
          className={`fs-22 fw-600 lh-130 mb-4 text-dark-blue d-md-flex justify-content-between align-items-center ${styles.title}`}
        >
          {name}
        </p>
        {!isOneOfObjectValuesEmpty(processedContactData) ? (
          companiesIsLoading ? (
            <Container className={"d-flex justify-content-center"}>
              <Spinner animation="border" variant="primary" />
            </Container>
          ) : (
            <div className={"d-flex flex-column gap-4"}>
              <OncocollectionsEntity data={processedContactData} />
            </div>
          )
        ) : (
          <div className={"col-12 text-center text-dark-blue fw-600"}>
            {t("widget_onkozbiorka_brak_danych")}
          </div>
        )}
      </Row>
    </Container>
  );
};
