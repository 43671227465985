import Select, { OptionProps } from "react-select";
import CheckboxInput from "../../atoms/CheckboxInput/CheckboxInput";
import "./MultiSelect.scss";
import { handleNewField } from "../../../Helpers/helpers";

interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

interface MultiSelectProps {
  menuIsOpen?: boolean;
  data?: {
    id: number;
    title: string;
  };
  setValue?: any;
  name?: string;
  value?: any;
  relativeoptions?: boolean;
}

const MultiSelect = ({
  menuIsOpen,
  data,
  setValue,
  name,
  value,
  relativeoptions,
}: MultiSelectProps) => {
  const Option = (props: OptionProps<ColourOption>) => {
    const { children, isDisabled, isSelected, innerProps } = props;

    return (
      <div {...innerProps}>
        <CheckboxInput
          className="ms-2 me-1"
          disabled={isDisabled}
          checked={isSelected}
        />
        {children}
      </div>
    );
  };

  const handleCategoryInputChange = (newValue: any) => {
    const values = newValue.map((item: any) => item.value);
    return setValue(name, values);
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={{
        Option,
        ...(menuIsOpen && { DropdownIndicator: () => null }),
        ...(menuIsOpen && { IndicatorSeparator: () => null }),
      }}
      hideSelectedOptions={false}
      isMulti
      options={data && handleNewField(data)}
      noOptionsMessage={() => "Brak danych"}
      placeholder="Wybierz"
      menuIsOpen={menuIsOpen}
      classNamePrefix={relativeoptions ? "relativeSelect" : "select"}
      onChange={handleCategoryInputChange}
      defaultValue={value}
    />
  );
};

export default MultiSelect;
