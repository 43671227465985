import { Carousel, Col, Container, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "assets/Icons/Arrow.svg";
import styles from "./LoyaltyProgramServiceMyCode.module.scss";
import { getWindowWidth } from "../../../Helpers/getWindowWidth";
import { LoyaltyProgramServiceCardMyCode } from "../../molecules/LoyaltyProgramServiceCardMyCode/LoyaltyProgramServiceCardMyCode";
import { useGetLoyaltyProgramsServicesMyCodeQuery } from "../../../redux/services/loyaltyProgramApi";
import { LoyaltyProgramServiceEmptyCard } from "../../atoms/LoyaltyProgramServiceEmptyCard/LoyaltyProgramServiceEmptyCard";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  name: string;
  maxCard?: number;
}

const INITIAL_LIMIT = 12;

export const LoyaltyProgramServiceMyCode = ({ name, maxCard = 3 }: Props) => {
  const [index, setIndex] = useState<number>(0);
  const [maxCardForLatest, setMaxCardForLatest] = useState<number>(
    getWindowWidth() <= 991 ? 1 : maxCard
  );
  const { t } = useTranslation();
  const { data: loyaltyProgramsData, isLoading } =
    useGetLoyaltyProgramsServicesMyCodeQuery({ limit: INITIAL_LIMIT });
  useEffect(() => {
    function handleResize() {
      if (getWindowWidth() <= 991) {
        setMaxCardForLatest(1);
      } else {
        setMaxCardForLatest(maxCard);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  let loyaltyProgramServiceMyCodeData: LoyaltyProgramServiceMyCode[] = [];
  if (loyaltyProgramsData?.data) {
    loyaltyProgramServiceMyCodeData = [
      ...loyaltyProgramsData?.data?.map(
        (value: LoyaltyProgramServiceMyCode) => ({
          id: value?.id,
          code: value?.code,
          expireAt: value?.expireAt,
          loyaltyProgramService: {
            id: value?.loyaltyProgramService?.id,
            name: value?.loyaltyProgramService?.name,
          },
        })
      ),
    ];
  }
  loyaltyProgramServiceMyCodeData.push({
    id: 0,
    code: "1",
    expireAt: "1",
    loyaltyProgramService: {
      id: 0,
      name: "1",
    },
  });

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const onPrevClick = () => {
    const length = loyaltyProgramServiceMyCodeData?.length;
    const newIndex = length / maxCardForLatest;
    if (index < 1) {
      setIndex(
        Number(newIndex) === newIndex && newIndex % 1 === 0
          ? Math.floor(newIndex) - 1
          : Math.floor(newIndex)
      );
    } else {
      setIndex(index - 1);
    }
  };
  const onNextClick = () => {
    const length = loyaltyProgramServiceMyCodeData?.length;
    if (index !== 0 && index >= length / maxCardForLatest - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };
  
  return (
    <Container fluid={true} className={`p-4 containerWithShadow `}>
      <Row>
        <h2
          className={`fs-22 fw-600 mb-5 text-dark-blue mx-0 col-9 d-md-flex align-items-center ${styles.title}`}
        >
          {name}
        </h2>

        {loyaltyProgramServiceMyCodeData?.length > maxCardForLatest && (
          <div className={"d-flex justify-content-end col-3"}>
            <div className={styles.buttonCarousel} onClick={onPrevClick}>
              <Arrow
                style={{ transform: "rotate(180deg)" }}
                className={"text-dark-blue"}
              />
            </div>
            <div className={styles.buttonCarousel} onClick={onNextClick}>
              <Arrow className={"text-dark-blue"} />
            </div>
          </div>
        )}
      </Row>
      <Row>
        <h3 className="fs-16 mb-4 text-dark-blue mx-0">
          {t("widget_loyalty_your_benefits_subTitle")}
        </h3>
      </Row>

      <Carousel
        indicators={false}
        controls={false}
        pause={"hover"}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={false}
        prevIcon={false}
      >
        {loyaltyProgramServiceMyCodeData?.map(
          // eslint-disable-next-line
          (item: LoyaltyProgramServiceMyCode, i: number) => {
            if (i % maxCardForLatest === 0) {
              return (
                <Carousel.Item className={`border-danger`} key={i}>
                  <Row className={`g-4 ${styles.maxHeight}`}>
                    {loyaltyProgramServiceMyCodeData
                      .slice(i, i + maxCardForLatest)
                      .map((value: LoyaltyProgramServiceMyCode) =>
                        value?.id === 0 ? (
                          <Col className={"col"} key={value?.id}>
                            <LoyaltyProgramServiceEmptyCard
                              text={t("widget_loyalty_your_benefits_text")}
                              linkLabel={t(
                                "widget_loyalty_your_benefits_linklabel"
                              )}
                              linkSrc={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}`}
                            />
                          </Col>
                        ) : (
                          <Col className={"col-12 col-lg-4"} key={value?.id}>
                            <LoyaltyProgramServiceCardMyCode data={value} />
                          </Col>
                        )
                      )}
                  </Row>
                </Carousel.Item>
              );
            }
          }
        )}
      </Carousel>
    </Container>
  );
};
