import { Button, Modal } from "react-bootstrap";
import styles from "./CancelModal.module.scss";

export const CancelModal = ({
  title,
  content,
  cancelText,
  confirmText,
  show,
  onHide,
  onConfirm,
  buttonClass,
}: TranslationCancelModalProps) => {
  return (
    <Modal show={show} onHide={onHide} centered className={styles.modal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant={buttonClass ? "" : "outline-danger"} className={buttonClass ? buttonClass.cancel : ""} onClick={onHide}>
          {cancelText}
        </Button>
        <Button variant={buttonClass ? "" : "outline-success"} className={buttonClass ? buttonClass.confirm : ""} onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
