import { Col, Container, Stack, Button } from "react-bootstrap";
import styles from "../../templates/ChangesCompanyTemplate/ChangesCompanyTemplate.module.scss";
import { useState, useEffect } from "react";
import { Pencil } from "react-bootstrap-icons";
import { RejectModal } from "../RejectModal/RejectModal";
import TooltipIcon from "../../atoms/TooltipIcon";
import { SeparationUpdate } from "components/atoms/SeparationUpdate/SeparationUpdate";
import { isHTML } from "Helpers/isHTML";
import TinyEditor from "../TinyEditor/TinyEditor";
import { useForm } from "react-hook-form";

interface Props {
  name: string;
  data: any;
  keyName: string;
  changes: any;
  handlePatch?: (obj: { [key: string]: any }, keyName: string) => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

const ChangesHtml = ({
  name,
  data,
  keyName,
  changes,
  handlePatch,
  handleComments,
  isAdmin,
}: Props) => {
  const [showReject, setShowReject] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const { setValue, watch } = useForm({
    defaultValues: {
      [keyName]: changes?.[keyName]
        ? changes?.[keyName]
        : data
        ? data?.[keyName]
        : "",
    },
  });

  useEffect(() => {
    if (changes?.[keyName]) {
      setValue(keyName, changes?.[keyName] ?? "");
    } else {
      setValue(keyName, data?.[keyName] ?? "");
    }
    // eslint-disable-next-line
  }, [changes?.[keyName], data?.[keyName]]);

  const handlePatchLocal = () => {
    const obj: any = {};
    obj[keyName] = watch(keyName);

    setIsActive(false);

    if (handlePatch) {
      handlePatch(obj, keyName);
    }
  };

  const handleCommentsLocal = (comment: string) => {
    if (handleComments) {
      handleComments(comment);
    }
  };

  return (
    <Stack gap={3}>
      <Col className={`${isAdmin ? styles.wrapper : ""}`}>
        <Container fluid={true}>
          <div className={"d-flex justify-content-between mb-3"}>
            <p className={"fs-22 fw-semibold text-dark-blue"}>{name}</p>
            {isAdmin && !changes?.[keyName] && (
              <Button
                style={{ width: 38, height: 38 }}
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                  isActive
                    ? "text-light"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prev: boolean) => !prev)}
              >
                <Pencil />
              </Button>
            )}
          </div>
          {isAdmin && isActive && !changes?.[keyName] ? (
            <div className={"w-100"}>
              <TinyEditor
                name={keyName}
                setValue={setValue}
                data={watch(keyName)}
              />
              <Button
                className={"btn-outline-dark-blue fs-16 mt-2 end-0 mt-3"}
                onClick={() => setValue(keyName, data?.[keyName])}
              >
                cofnij
              </Button>
              <Button
                className={"btn-outline-dark-blue fs-16 mt-2 end-0 mt-3 ms-3"}
                onClick={handlePatchLocal}
              >
                AKCEPTUJ
              </Button>
            </div>
          ) : isHTML(watch(keyName)) ? (
            <div
              className={`text-dark-blue ${styles.html}`}
              dangerouslySetInnerHTML={{
                __html: changes?.[keyName] ? data?.[keyName] : watch(keyName),
              }}
            />
          ) : (
            <p className={`text-dark-blue m-0`}>
              {changes?.[keyName] ? data?.[keyName] : watch(keyName)}
            </p>
          )}
        </Container>
      </Col>
      {!!changes?.[keyName] && (
        <>
          <SeparationUpdate />
          <Col className={`${isAdmin && styles.wrapper} text-danger`}>
            <Container fluid={true}>
              <div className={"d-flex justify-content-between mb-3"}>
                <p className={"fs-22 fw-semibold"}>{name}</p>
                {isAdmin && (
                  <Button
                    style={{ width: 38, height: 38 }}
                    variant={isActive ? "dark" : "transparent"}
                    className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                      isActive
                        ? "text-light"
                        : "text-dark-blue bg-transparent border-0"
                    }`}
                    onClick={() => setIsActive((prev: boolean) => !prev)}
                  >
                    <Pencil />
                  </Button>
                )}
              </div>
              {isAdmin && isActive ? (
                <div className={"w-100"}>
                  <TinyEditor
                    name={keyName}
                    setValue={setValue}
                    data={watch(keyName)}
                  />
                  <Button
                    className=" btn-outline-dark-blue fs-16 mt-2 end-0 mt-3"
                    onClick={() => setValue(keyName, changes?.[keyName])}
                  >
                    cofnij
                  </Button>
                  <Button
                    className={
                      "btn-outline-dark-blue fs-16 mt-2 end-0 mt-3 ms-3"
                    }
                    onClick={handlePatchLocal}
                  >
                    AKCEPTUJ
                  </Button>
                </div>
              ) : isHTML(watch(keyName)) ? (
                <div
                  className={`text-dark-blue ${styles.html}`}
                  dangerouslySetInnerHTML={{ __html: watch(keyName) }}
                />
              ) : (
                <p className={`text-dark-blue m-0`}>{watch(keyName)}</p>
              )}
            </Container>
          </Col>
        </>
      )}
      {isAdmin && !!changes?.[keyName] && (
        <Col className={"d-flex justify-content-end gap-2"}>
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowReject((prev) => !prev)}
          >
            ODRZUĆ
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev) => !prev)}
            handleComments={handleCommentsLocal}
            comments={
              changes?.comments
                ? `${changes?.comments}; ${name}: `
                : `${name}: `
            }
          />
          <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
            <Button
              disabled={isActive}
              className={"fs-16"}
              onClick={handlePatchLocal}
            >
              AKCEPTUJ
            </Button>
          </TooltipIcon>
        </Col>
      )}
    </Stack>
  );
};

export default ChangesHtml;
