import styles from "./FeedActionIcon.module.scss";
import { ReactComponent as LikeActive } from "../../../assets/Icons/LikeActive.svg";

interface FeedActionIconProps {
  svg: JSX.Element;
  className?: string;
  content?: number;
  liked?: boolean;
  onClick?: () => void;
}

export const FeedActionIcon = ({
  svg,
  content,
  className,
  liked,
  onClick,
}: FeedActionIconProps) => {
  return (
    <span
      onClick={onClick}
      className={`d-flex justify-content-center align-items-center my-4 ${className}`}
    >
      {!content ? (
        <span>{svg}</span>
      ) : (
        <>
          <span>{!liked ? svg : <LikeActive />}</span>
          <span
            className={`bg-primary px-2 m-2 rounded-2 text-light ${styles.feedActionContent}`}
          >
            {content}
          </span>
        </>
      )}
    </span>
  );
};
