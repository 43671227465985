import { MouseEvent } from "react";
import styles from "./BreadcrumbButton.module.scss";

interface BreadcrumbButtonProps {
  children?: string;
  handler: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const BreadcrumbButton = ({ children, handler }: BreadcrumbButtonProps) => {
  return (
    <button className={styles.breadcrumbButton} onClick={handler}>
      {children}
    </button>
  );
};

export default BreadcrumbButton;
