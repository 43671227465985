import { Button, Image } from "react-bootstrap";
import styles from "./Avatar.module.scss";
import AvatarPlaceholder from "../../../assets/images/user normal.png";
import { Pencil } from "react-bootstrap-icons";

const Avatar = ({
  isAuthenticated,
  size,
  className,
  url,
  handleChangeAvatar,
  accountStatus,
}: AvatarProps) => (
  <div
    className={`text-grey-2 d-flex align-items-center justify-content-center position-relative p-0 ${className} ${
      styles.avatar
    } ${size === "lg" && styles.avatarLg} 
      ${size === "sm" && styles.avatarSm} 
      ${size === "md" && styles.avatarMd} 
      ${size === "lg-fixed" && styles.avatarLgFixed} 
      ${size === "xs" && styles.avatarXs} 
      ${size === "fluid" && "w-100 h-auto"}`}
  >
    <Image
      className={`w-100 rounded-circle ${!url && styles.avatarBorder}`}
      src={url ? url : AvatarPlaceholder}
    />
    {isAuthenticated && accountStatus === "active" && (
      <Button
        variant="outline-primary"
        onClick={handleChangeAvatar}
        className={`btn-outline-primary stroke-primary btn-alivia ${styles.editAvatarButton}`}
      >
        <Pencil />
      </Button>
    )}
  </div>
);

export default Avatar;
