import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import ArticlesTable from "components/organisms/Tables/ArticlesTable/ArticlesTable";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetArticlesQuery } from "redux/services/articlesApi";
import Select, { SingleValue } from "react-select";
import { SubmitHandler, useForm } from "react-hook-form";
import { useArticleRssCategories } from "Helpers/useArticleCategories";
import CustomPagination from "components/organisms/CustomPagination/CustomPagination";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const INITIAL_SEARCH_PARAMS: InitialSearchParamsUser = {
  PAGE: 1,
  LIMIT: 10,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
};

const ArticlesAdminTemplate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.PAGE
  );

  const { selectOptions: rssCategoryOptions } = useArticleRssCategories();

  const { data: articleRssData } = useGetArticlesQuery({
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS.OFFSET,
    limit: INITIAL_SEARCH_PARAMS.LIMIT.toString(),
    sortBy: searchParams.get("sortBy") ?? ",",
    categoryName: searchParams.get("category") ?? "",
    nameLike: searchParams.get("nameLike") ?? "",
  });

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      nameLike: "",
      category: "",
    },
  });

  const handleCategoryFilter: SubmitHandler<SubmitData> = (data) => {
    searchParams.set("category", data.category);
    setSearchParams(searchParams);
  };

  const handleCategoryFilterChange = (
    newCategory: SingleValue<{
      value: string;
      label: string;
    } | null>
  ) => {
    setValue("category", newCategory?.value || "");
  };

  const handleRssNameFilter: SubmitHandler<SubmitData> = (data) => {
    if (data.nameLike) {
      searchParams.set("page", INITIAL_SEARCH_PARAMS.PAGE.toString());
      setCurrPage(INITIAL_SEARCH_PARAMS.PAGE);
    }
    searchParams.set("nameLike", data.nameLike);
    setSearchParams(searchParams);
  };

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  return (
    <Container as="section" fluid className="px-4 pt-2 bg-light">
      <div className="d-flex align-items-center mb-3">
        <SectionTitle className="me-4">Artykuły</SectionTitle>
        <Button
          className="btn-outline-blue"
          type="button"
          onClick={() =>
            navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.ARTICLES}/new`, {
              replace: true,
            })
          }
        >
          Dodaj nowy kanał RSS
        </Button>
      </div>

      <div className="row justify-content-between g-3 mb-5">
        <Form
          className="col col-lg-6 d-flex gap-3"
          onSubmit={handleSubmit(handleCategoryFilter)}
        >
          <div className="col-4">
            <Select
              classNamePrefix="select"
              defaultValue={null}
              placeholder="Wszystkie"
              isClearable={true}
              isSearchable={true}
              name="category"
              options={rssCategoryOptions}
              onChange={handleCategoryFilterChange}
            />
          </div>

          <div className="col-auto">
            <Button className="btn-outline-blue" type="submit" value="Submit">
              Przefiltruj
            </Button>
          </div>
        </Form>
        <Form
          className="col col-lg-6 d-flex gap-3 flex-end justify-content-lg-end"
          onSubmit={handleSubmit(handleRssNameFilter)}
        >
          <div className="col-4">
            <Form.Control type="text" {...register("nameLike")} />
          </div>
          <div className="col-auto">
            <Button className="btn-outline-blue" type="submit" value="Submit">
              Szukaj
            </Button>
          </div>
        </Form>
      </div>

      <ArticlesTable data={articleRssData?.articles} />
      <CustomPagination
        totalCount={
          articleRssData?.totalResults || INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
        }
        pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
        currentPage={currPage}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};

export default ArticlesAdminTemplate;
