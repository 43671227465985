import { Button, Container, Form } from "react-bootstrap";
import { Pencil } from "react-bootstrap-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { MeUser } from "redux/Slice/user-slice";
import TextInput from "components/atoms/TextInput/TextInput";
import { toast } from "react-toastify";
import { usePatchMeMutation } from "redux/services/meApi";
import style from "./AboutMeSettingsCard.module.scss";
import { useTranslation } from "react-i18next";

export const AboutMeSettingsCard = ({
  authUser,
  className,
  setShow,
  show,
}: SettingsCardProps) => {
  const [patchMe] = usePatchMeMutation();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<MeUser>({
    defaultValues: {
      ...authUser,
    },
  });

  const submitHandler: SubmitHandler<MeUser> = async (data) => {
    const meUpdated = await patchMe({
      data: {
        ...data,
        localSettings: {
          ...data.localSettings,
          displayName:
            data.localSettings.displayName || data.settings.FirstName,
        },
        name: data.localSettings.displayName || data.settings.FirstName,
      },
    });

    if ("data" in meUpdated) {
      toast.success(t("toast_aboutMeSettingsCard_success"));
      setValue(
        "localSettings.displayName",
        meUpdated?.data?.data?.localSettings?.displayName
      );
    } else if ("error" in meUpdated) {
      const error = meUpdated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_aboutMeSettingsCard_error_500"));

      if (error.data.statusCode === 400) toast.error(error.data.message);
      toast.error(t("toast_aboutMeSettingsCard_error"));
    }

    setShow(false);
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Container fluid className={className}>
        <div className="w-100 p-4">
          <div className="justify-content-between d-flex flex-row align-items-center mb-5">
            <p className={`fw-600 fs-22 text-dark-blue m-0 ${style.title}`}>
              O mnie
            </p>
            {authUser?.status === 1 && (
              <Button
                onClick={() => setShow(!show)}
                className={`btn-alivia ${
                  !show ? "text-dark-blue stroke-dark-blue" : "stroke-white"
                }`}
                variant={show ? "dark" : "link"}
              >
                <Pencil size={20} />
              </Button>
            )}
          </div>
          <div className="d-flex flex-column gap-2 w-100">
            <TextInput
              label="Imię"
              className="fs-16 fw-400 flex-column w-100 mb-3"
              type="EventInput"
              register={register("settings.FirstName", {
                required: { value: true, message: "Pole wymagane" },
                maxLength: {
                  value: 128,
                  message: "Maksymalnie 128 znaków",
                },
              })}
              disabled={!show}
              labelClassName="fw-400 fs-16 text-dark-blue"
              error={errors?.settings?.FirstName?.message}
            />
            <TextInput
              label="Nazwisko / nazwiska"
              register={register("settings.LastName", {
                required: { value: true, message: "Pole wymagane" },
                maxLength: {
                  value: 128,
                  message: "Maksymalnie 128 znaków",
                },
              })}
              className="fs-16 fw-400 flex-column w-100 mb-3"
              disabled={!show}
              labelClassName="fw-400 fs-16 text-dark-blue"
              type="EventInput"
              error={errors?.settings?.LastName?.message}
            />
            <TextInput
              label="Nazwa wyświetlana"
              register={register("localSettings.displayName", {
                maxLength: {
                  value: 256,
                  message: "Maksymalnie 256 znaków",
                },
              })}
              className="fs-16 fw-400 flex-column w-100"
              disabled={!show}
              withTooltip={true}
              tooltipText="Ta nazwa widoczna jest przy wszystkich aktywnościach w ramach Społeczności oraz w wiadomościach prywatnych. Możesz ją zmienić podając nick lub inną nazwę, aby kontrolować swoją prywatność."
              labelClassName="fw-400 fs-16 text-dark-blue"
              type="EventInput"
              error={errors.localSettings?.displayName?.message}
            />
          </div>
          {show && (
            <div className="d-flex flex-row justify-content-end mt-4">
              <Button
                variant="outline-dark"
                className="text-uppercase me-3"
                onClick={() => setShow(!show)}
              >
                Anuluj
              </Button>
              <Button
                variant="primary"
                className="text-uppercase"
                type="submit"
              >
                Aktualizuj
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Form>
  );
};
