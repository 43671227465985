import { Table } from "react-bootstrap";
import type { CodeData } from "../LoyaltyProgramServiceCodes/LoyaltyProgramServiceCodes";
import { HEADER_TABLE_NAMES } from "./LoyaltyProgramServiceCode.consts";
import { formatISODate } from "Helpers/formatISODate";

interface Props {
  data: CodeData[];
}

const LoyaltyProgramServiceCode = ({ data }: Props) => {
  return (
    <div className="table-responsive">
      <Table>
        <thead>
          <tr>
            {HEADER_TABLE_NAMES.map((name) => (
              <th key={name} className="pb-4">
                <span>{name}</span>
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="tableBody">
          {data ? (
            data?.map(({ code, createdAt, expireAt, usedAt }: CodeData) => (
              <tr>
                <td>{code}</td>
                <td>{formatISODate(createdAt)}</td>
                <td>
                  {expireAt ? formatISODate(expireAt) : "brak daty wygaśnięcia"}
                </td>
                <td>
                  {usedAt ? formatISODate(usedAt) : "kod nie został użyty"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                Nie znaleziono żadnych filtrów.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default LoyaltyProgramServiceCode;
