import QuickEditArticle from "components/molecules/QuickEditCollapse/QuickEditArticle/QuickEditArticle";
import ArticleTableRow from "components/organisms/ArticleTableRow/ArticleTableRow";
import SortButton from "components/organisms/SortButton/SortButton";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import styles from "./ArticlesTable.module.scss";

interface ArticlesTableProps {
  data: ArticleRss[];
}

type Props = ArticlesTableProps & React.ComponentPropsWithoutRef<"button">;

const ArticlesTable = ({ data, ...props }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState<string | null>(searchParams.get("sortBy"));

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const nameSortHandler = () =>
    sort === "name,ASC" ? handleSort("name,DESC") : handleSort("name,ASC");

  const thStyles = "pb-4 border border-0";

  return (
    <Table>
      <thead>
        <tr>
          <th className={thStyles}>
            <SortButton
              label="Nazwa"
              onClick={nameSortHandler}
              isArrowRotated={sort === "name,DESC"}
            />
          </th>
          <th className={thStyles}>
            <span className={styles.thLabel}>Adres URL</span>
          </th>
          <th className={thStyles}>
            <span className={styles.thLabel}>Typ</span>
          </th>
          <th className={thStyles}>
            <span className={styles.thLabel}>Kategorie</span>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((article, idx) => (
          <ArticleTableRow
            key={idx}
            data={article}
            QuickEditCollapse={QuickEditArticle}
          />
        ))}
        {!data?.length && (
          <tr>
            <td colSpan={4} className="text-center">
              Nie znaleziono żadnych kanałów RSS artykułów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default ArticlesTable;
