export const SYSTEM_SETTINGS = {
  TYPE_NAMES: {
    poll_role: "Ankiety po wyborze roli",
    poll_comments: "Ankiety w komentarzach",
    poll_satisfaction: "Ankiety satysfakcji",
    faq: "FAQ",
    emailTemplate: "Szablony email",
    online_category_all_province: "online_category_all_province",
    onkosnajper: "Onkosnajper",
  },
};
