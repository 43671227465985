import { translations } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

export interface TransferredTranslationsData {
  data: {
    language: number;
    term: string;
    type: string;
    value?: string;
  };
}

interface GetTranslationsSearchParams {
  limit?: string;
  offset?: string;
  language?: string;
  type?: string | null;
  sortOrder?: string | null;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Translations"],
});

export const translationsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    postTranslation: builder.mutation({
      query: ({ data }: TransferredTranslationsData) => ({
        url: `${translations}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Translations"],
    }),

    getTranslations: builder.query({
      query: ({
        limit,
        offset,
        language,
        type,
        sortOrder,
      }: GetTranslationsSearchParams) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(language && { language }),
          ...(type && { type }),
          ...(sortOrder && { sortOrder }),
        });

        return {
          url: `${translations}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Translations"],
    }),

    getTranslationsKey: builder.query({
      query: ({
        sortOrder,
      }: Pick<GetTranslationsSearchParams, "sortOrder">) => {
        const params = new URLSearchParams({
          ...(sortOrder && { sortOrder }),
        });

        return {
          url: `${translations}/keys?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Translations"],
    }),

    getTranslationsByLangId: builder.query({
      query: ({
        languageId,
        sortOrder,
      }: {
        languageId: number;
        sortOrder?: string | null;
      }) => {
        const params = new URLSearchParams({
          ...(sortOrder && { sortOrder }),
        });
        return {
          url: `${translations}/${languageId}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Translations"],
    }),

    updateTranslations: builder.mutation({
      query: ({ data }: TranslationPayload) => ({
        url: `${translations}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Translations"],
    }),

    createTranslationsFile: builder.mutation({
      query: () => ({
        url: `${translations}/generate`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Translations"],
    }),

    downloadTranslationsFile: builder.query({
      query: ({ languageId }: { languageId: number }) => ({
        url: `${translations}/${languageId}/download`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Translations"],
    }),
  }),
});

export const {
  usePostTranslationMutation,
  useGetTranslationsQuery,
  useGetTranslationsKeyQuery,
  useGetTranslationsByLangIdQuery,
  useUpdateTranslationsMutation,
  useDownloadTranslationsFileQuery,
  useLazyGetTranslationsByLangIdQuery,
  useCreateTranslationsFileMutation,
} = translationsApi;
