import { loyaltyPrograms } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["LoyaltyPrograms"],
});

interface GetLoyaltyProgramsProps {
  all?: string;
  limit?: string;
  offset?: string;
  skip?: boolean;
}

export interface GetLoyaltyProgramsServicesProps {
  limit?: number;
  offset?: string;
  sortBy?: string | null;
  companyId?: string | null;
  allService?: number;
  programId?: string | null;
  categoryId?: string | null;
  provinceId?: string | null;
  cityId?: string | null;
  changes?: number;
  categorySlug?: string;
  description?: string;
  nameLike?: string;
  withoutServiceIds?: string;
}

export interface GetLoyaltyProgramsAgreementAllProps {
  all?: string;
  limit?: string;
  offset?: string;
}

export interface GetLoyaltyProgramsServiceCategoryProps {
  allService?: number;
  limit?: string;
  offset?: string;
  allCategory?: string;
  hidden?: string;
}

interface GetLoyaltyProgramsServiceProps {
  id: number;
  status?: number;
}

interface GetLoyaltyProgramsServiceAgreementsProps {
  serviceId?: string;
  programId?: string;
  typesAgreement?: "sponsor" | "user";
}

interface GetLoyaltyProgramServiceCodesProps {
  code?: string;
  limit?: string;
  offset?: string;
  serviceId?: string;
}

interface PatchLoyaltyProgramServiceProps {
  id?: string;
  data: {
    name?: string;
    category?: string;
    department?: string;
    promoCodesEndAt?: string | null;
    promoCodesAmount?: number;
    promoCodesReuseInterval?: number;
    startAt?: string;
    endAt?: string | null;
    description?: string;
    conditions?: string;
    imageId?: string | null;
    loyaltyProgramId?: string | null;
    changes?: string | null;
    status?: number;
    deleted?: number;
  };
}

interface PatchLoyaltyProgramDataProps {
  status?: number;
  imageId?: string;
}

interface PatchLoyaltyProgramProps {
  id?: string;
  data: PatchLoyaltyProgramDataProps;
}

interface PostLoyaltyProgramServiceUploadBatchProps {
  serviceId: string;
  data: {
    file?: File;
    codes?: string;
  };
}

interface PostLoyaltyProgramServiceProps {
  data: {
    additionalDescription?: string | null;
    apartmentNumber?: number | null;
    cityId?: string | number;
    companyId?: string | number;
    emailAddresses?: string | null;
    houseNumber?: string;
    name?: string;
    phoneNumbers?: string;
    provinceId?: string | number;
    status?: number;
    street?: string;
    teryt?: string;
    zipCode?: string;
    isMain?: number;
    imageId?: string;
  };
}

interface PatchLoyaltyProgramServiceCodeProps {
  id?: string;
  data: {
    code: string;
  };
}

interface PostAcceptedAgreementProps {
  agreementId: number;
}

interface LoyaltyProgramCodeGenerateProps {
  serviceId: number;
}

interface LoyaltyProgramCodeGeneratePositiveData {
  code: string;
  endAt: string;
}

interface LoyaltyProgramCodeGeneratePositiveResponse {
  data: LoyaltyProgramCodeGeneratePositiveData;
  meta: {
    status: string;
  };
}

export interface LoyaltyProgramCodeGenerateNegativeData {
  translationKey: string;
  translationParam?: string;
  message: string;
  error: string;
  statusCode: number;
}

interface LoyaltyProgramCodeGenerateNegativeResponse {
  data: LoyaltyProgramCodeGenerateNegativeData;
}

type LoyaltyProgramCodeGenerateResponse =
  | LoyaltyProgramCodeGeneratePositiveResponse
  | LoyaltyProgramCodeGenerateNegativeResponse;

interface DeleteLoyaltyProgramsServiceProps {
  id: number;
}

interface DeleteLoyaltyProgramsServiceProps {
  id: number;
}

export interface PostLoyaltyProgramAgreementData {
  name?: string;
  description?: string | null;
}

interface PostLoyaltyProgramAgreementProps {
  programId: string;
  data: PostLoyaltyProgramAgreementData;
}

export interface PatchLoyaltyProgramAgreementData
  extends PostLoyaltyProgramAgreementData {
  status?: number;
  version?: string | null;
}

interface PatchLoyaltyProgramAgreementProps {
  agreementId: string;
  data: PatchLoyaltyProgramAgreementData;
}

export interface PostLoyaltyProgramServiceCategoryData {
  name: string;
  slug: string | null;
  priority: number;
  status: number;
  show: number;
}

interface PostLoyaltyProgramServiceCategoryProps {
  data: PostLoyaltyProgramServiceCategoryData;
}

export interface PatchLoyaltyProgramServiceCategoryData {
  name?: string;
  slug?: string | null;
  priority?: number;
  status?: number;
  show?: number;
}

interface PatchLoyaltyProgramServiceCategoryProps {
  id: number;
  data: PatchLoyaltyProgramServiceCategoryData;
}

export type AgreementType = "user" | "sponsor";

interface GetLoyaltyProgramsAgreementLastVersionProps {
  type: AgreementType;
  programId: string;
}

export const loyaltyProgramsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getLoyaltyPrograms: builder.query({
      query: ({ all, limit, offset }: GetLoyaltyProgramsProps) => {
        const params = new URLSearchParams({
          ...(all && { all }),
          ...(limit && { limit }),
          ...(offset && { offset }),
        });
        return {
          url: `${loyaltyPrograms}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    patchLoyaltyProgram: builder.mutation({
      query: ({ id, data }: PatchLoyaltyProgramProps) => ({
        url: `${loyaltyPrograms}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsServices: builder.query({
      query: ({
        companyId,
        allService,
        programId,
        categoryId,
        provinceId,
        cityId,
        limit,
        offset,
        sortBy,
        changes,
        categorySlug,
        description,
        nameLike,
        withoutServiceIds,
      }: GetLoyaltyProgramsServicesProps) => {
        const sortArray = sortBy?.split(",");

        const params = new URLSearchParams({
          ...(companyId && { companyId }),
          ...(allService && { allService: String(allService) }),
          ...(programId && { programId }),
          ...(changes && { changes: String(changes) }),
          ...(categoryId && { categoryId }),
          ...(provinceId && { provinceId }),
          ...(cityId && { cityId }),
          ...((limit || limit === 0) && { limit: String(limit) }),
          ...(offset && { offset }),
          ...(categorySlug && { categorySlug }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(description && { description }),
          ...(nameLike && { nameLike }),
          ...(withoutServiceIds && { withoutServiceIds }),
        });

        return {
          url: `${loyaltyPrograms}/service?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsAgreementAll: builder.query({
      query: ({ all, limit, offset }: GetLoyaltyProgramsAgreementAllProps) => {
        const params = new URLSearchParams({
          ...(all && { all }),
          ...(limit && { limit }),
          ...(offset && { offset }),
        });

        return {
          url: `${loyaltyPrograms}/agreement-all?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsServiceCode: builder.query({
      query: (id: string) => {
        return {
          url: `${loyaltyPrograms}/service/${String(id)}/generator`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsService: builder.query({
      query: ({ id, status }: GetLoyaltyProgramsServiceProps) => {
        const params = new URLSearchParams({
          ...(status && { status: String(status) }),
        });
        return {
          url: `${loyaltyPrograms}/service/${String(id)}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsServicesMyCode: builder.query({
      query: ({ limit, offset }: GetLoyaltyProgramsServicesProps) => {
        const params = new URLSearchParams({
          ...(limit && { limit: String(limit) }),
          ...(offset && { offset }),
        });

        return {
          url: `${loyaltyPrograms}/service/my-code?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsServiceAgreements: builder.query({
      query: ({
        serviceId,
        programId,
        typesAgreement,
      }: GetLoyaltyProgramsServiceAgreementsProps) => {
        const params = new URLSearchParams({
          ...(serviceId && { serviceId }),
          ...(programId && { programId }),
          ...(typesAgreement && { typesAgreement }),
        });

        return {
          url: `${loyaltyPrograms}/agreement?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsServiceCategory: builder.query({
      query: ({
        allService,
        limit,
        offset,
        allCategory,
        hidden,
      }: GetLoyaltyProgramsServiceCategoryProps) => {
        const params = new URLSearchParams({
          ...(allService && { allService: String(allService) }),
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(allCategory && { allCategory }),
          ...(hidden && { hidden }),
        });
        return {
          url: `${loyaltyPrograms}/category?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramsAgreementLastVersion: builder.query({
      query: ({
        type,
        programId,
      }: GetLoyaltyProgramsAgreementLastVersionProps) => {
        return {
          url: `${loyaltyPrograms}/agreement-last-version/${type}/${programId}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    getLoyaltyProgramServiceCodes: builder.query({
      query: ({
        code,
        serviceId,
        limit,
        offset,
      }: GetLoyaltyProgramServiceCodesProps) => {
        const params = new URLSearchParams({
          ...(code && { code }),
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(serviceId && { serviceId }),
        });
        return {
          url: `${loyaltyPrograms}/service/${serviceId}/codes?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    getAgreementLastUser: builder.query({
      query: () => {
        return {
          url: `${loyaltyPrograms}/agreement-last-user`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),

    patchLoyaltyProgramService: builder.mutation({
      query: ({ id, data }: PatchLoyaltyProgramServiceProps) => ({
        url: `${loyaltyPrograms}/service/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    patchLoyaltyProgramServiceCode: builder.mutation({
      query: ({ id, data }: PatchLoyaltyProgramServiceCodeProps) => ({
        url: `${loyaltyPrograms}/service/${id}/generator`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramService: builder.mutation({
      query: ({ data }: PostLoyaltyProgramServiceProps) => ({
        url: `${loyaltyPrograms}/service`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postAcceptedAgreement: builder.mutation({
      query: ({ agreementId }: PostAcceptedAgreementProps) => ({
        url: `${loyaltyPrograms}/agreement-accept/${agreementId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramCodeGenerate: builder.mutation<
      LoyaltyProgramCodeGenerateResponse,
      LoyaltyProgramCodeGenerateProps
    >({
      query: ({ serviceId }) => {
        return {
          url: `${loyaltyPrograms}/service/${serviceId}/generator`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramAgreementUser: builder.mutation({
      query: ({ programId, data }: PostLoyaltyProgramAgreementProps) => {
        return {
          url: `${loyaltyPrograms}/agreement-user/${programId}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramAgreementSponsor: builder.mutation({
      query: ({ programId, data }: PostLoyaltyProgramAgreementProps) => {
        return {
          url: `${loyaltyPrograms}/agreement-sponsor/${programId}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    patchLoyaltyProgramAgreement: builder.mutation({
      query: ({ agreementId, data }: PatchLoyaltyProgramAgreementProps) => {
        return {
          url: `${loyaltyPrograms}/agreement/${agreementId}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramServiceCategory: builder.mutation({
      query: ({ data }: PostLoyaltyProgramServiceCategoryProps) => {
        return {
          url: `${loyaltyPrograms}/category`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    patchLoyaltyProgramServiceCategory: builder.mutation({
      query: ({ id, data }: PatchLoyaltyProgramServiceCategoryProps) => {
        return {
          url: `${loyaltyPrograms}/category/${id}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    deleteLoyaltyProgramsService: builder.mutation({
      query: ({ id }: DeleteLoyaltyProgramsServiceProps) => {
        return {
          url: `${loyaltyPrograms}/service/${String(id)}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramServiceSendCode: builder.mutation({
      query: (id: number) => {
        return {
          url: `${loyaltyPrograms}/service/my-code/${id}`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),

    postLoyaltyProgramServiceUploadBatch: builder.mutation({
      query: ({
        serviceId,
        data,
      }: PostLoyaltyProgramServiceUploadBatchProps) => {
        const formData = new FormData();

        if (data.file) {
          formData.append("file", data.file);
        }

        if (data.codes) {
          formData.append("codes", data.codes);
        }

        return {
          url: `${loyaltyPrograms}/service/${serviceId}/upload-batch`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["LoyaltyPrograms"],
    }),
  }),
});

export const {
  useGetLoyaltyProgramsQuery,
  useGetLoyaltyProgramsServiceQuery,
  useGetLoyaltyProgramsServicesQuery,
  useGetLoyaltyProgramsServiceCodeQuery,
  useGetLoyaltyProgramsServicesMyCodeQuery,
  useGetLoyaltyProgramsServiceAgreementsQuery,
  useGetLoyaltyProgramsAgreementAllQuery,
  usePostLoyaltyProgramServiceMutation,
  usePatchLoyaltyProgramServiceMutation,
  usePatchLoyaltyProgramServiceCodeMutation,
  usePostAcceptedAgreementMutation,
  usePostLoyaltyProgramCodeGenerateMutation,
  useGetLoyaltyProgramsServiceCategoryQuery,
  useDeleteLoyaltyProgramsServiceMutation,
  usePostLoyaltyProgramAgreementSponsorMutation,
  usePostLoyaltyProgramAgreementUserMutation,
  usePatchLoyaltyProgramAgreementMutation,
  useGetLoyaltyProgramsAgreementLastVersionQuery,
  useGetAgreementLastUserQuery,
  usePatchLoyaltyProgramMutation,
  usePostLoyaltyProgramServiceCategoryMutation,
  usePatchLoyaltyProgramServiceCategoryMutation,
  usePostLoyaltyProgramServiceSendCodeMutation,
  useGetLoyaltyProgramServiceCodesQuery,
  usePostLoyaltyProgramServiceUploadBatchMutation,
} = loyaltyProgramsApi;
