import { useEffect, useState } from "react";
import { handleNewField } from "Helpers/helpers";
import AsyncSelect from "react-select/async";
import { OptionProps } from "react-select";
import CheckboxInput from "components/atoms/CheckboxInput/CheckboxInput";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AsyncMultiSelect = ({
  menuIsOpen,
  data,
  placeholder,
  setValue,
  name,
  width,
  relativeoptions,
  asyncQuery,
  value,
  reset,
  setHandleReset,
  type,
  label,
  handleField: handleFieldProps,
  labelStyles,
  inputStyles,
}: any) => {
  interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
  }

  const [trigger] = asyncQuery();

  const source = data?.data;
  const meta = data?.meta;
  const [values, setValues] = useState(value || []);

  useEffect(() => {
    if (reset) {
      setValues([]);
      setHandleReset(false);
      setValue(name, []);
    }
  }, [setHandleReset, reset, setValue, name]);

  const handleField = handleFieldProps || handleNewField;

  const filterData = (inputValue: string) =>
    handleField(source)?.filter((i: any) =>
      i?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );

  const promiseOptions = (inputValue: string) => {
    if (inputValue?.length > 3 && meta?.currentLimit < meta?.totalResults) {
      return trigger({
        titleLike: inputValue,
        ...(type && { type: type }),
      }).then((res: any) => handleField(res?.data?.data));
    } else
      return new Promise<any>((resolve) => {
        resolve(filterData(inputValue));
      });
  };

  const handleCategoryInputChange = (newValue: any) => {
    const values = newValue?.map((item: any) => item?.value);
    setValues(newValue);
    return setValue(name, values);
  };

  const Option = (props: OptionProps<ColourOption>) => {
    const { children, isDisabled, isSelected, innerProps, data } = props;

    const childrenData = source?.find((e: any) => e?.id === data?.value);

    const tooltip = childrenData?.parent?.[0]?.title ? (
      <Tooltip id="tooltip">{childrenData?.parent?.[0]?.title}</Tooltip>
    ) : null;

    if (tooltip) {
      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <div {...innerProps}>
            <CheckboxInput
              className="ms-2 me-1"
              disabled={isDisabled}
              checked={isSelected}
            />
            {children}
          </div>
        </OverlayTrigger>
      );
    } else {
      return (
        <div {...innerProps}>
          <CheckboxInput
            className="ms-2 me-1"
            disabled={isDisabled}
            checked={isSelected}
          />
          {children}
        </div>
      );
    }
  };

  return (
    <div style={{ width: width }}>
      <label
        style={labelStyles}
        className={
          label &&
          `form-check-label col-auto d-flex fs-16 fw-400 mt-3 flex-column`
        }
      >
        {label && (
          <span className="me-3 mb-1" style={{ width: "100%" }}>
            {label}
          </span>
        )}
        <AsyncSelect
          styles={inputStyles}
          closeMenuOnSelect={false}
          components={{
            Option,
            ...(menuIsOpen && { DropdownIndicator: () => null }),
            ...(menuIsOpen && { IndicatorSeparator: () => null }),
          }}
          cacheOptions
          defaultOptions={source && handleField(source)}
          isClearable
          isMulti
          value={values}
          menuIsOpen={menuIsOpen}
          hideSelectedOptions={false}
          noOptionsMessage={() => "Brak danych"}
          placeholder={placeholder || "Wybierz"}
          loadOptions={promiseOptions}
          loadingMessage={() => "Ładowanie danych"}
          classNamePrefix={relativeoptions ? "relativeSelect" : "select"}
          onChange={handleCategoryInputChange}
          defaultValue={value}
        />
      </label>
    </div>
  );
};

export default AsyncMultiSelect;
