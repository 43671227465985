import {
  useGetSystemSettingsQuery,
  useUpdateSystemSettingsMutation,
} from "redux/services/systemSettingsApi";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import { useForm, FormProvider } from "react-hook-form";
import RenderSettingsOption from "components/molecules/RenderSettingsOptions/RenderSettingsOptions";
import { Button, Collapse } from "react-bootstrap";
import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import styles from "./SystemSettings.module.scss";
import { SYSTEM_SETTINGS } from "Helpers/systemSettingsData";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const SystemSettings: React.FC = () => {
  const { data: settingsData, refetch } = useGetSystemSettingsQuery({
    query: "0",
    limit: "0",
  });
  const [updateSystemSettings] = useUpdateSystemSettingsMutation();
  const [postAttachment] = usePostAttachmentsMutation();

  const [openPage, setOpenPage] = useState<number | null>(null);

  const [defaultValues, setDefaultValues] = useState<SystemSettingsData>(
    {} as SystemSettingsData
  );
  const methods = useForm<SystemSettingsData>({
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (settingsData?.data) {
      const newDefaultValues = settingsData?.data?.reduce(
        (acc: SystemSettingsData, setting: SystemSettingsData) => {
          acc[`${setting?.id}_value`] = setting?.value;
          acc[`${setting?.id}_status`] = setting?.status;
          return acc;
        },
        {} as SystemSettingsData
      );

      setDefaultValues(newDefaultValues);
    }
  }, [settingsData]);

  const { t } = useTranslation();

  const [settingsByPage, setSettingsByPage] = useState<{
    [key: number]: { [key: string]: PageSettings[] };
  }>({});

  useEffect(() => {
    const newSettingsByPage: {
      [key: number]: { [key: string]: PageSettings[] };
    } = {};

    settingsData?.data?.forEach((setting: PageSettings) => {
      if (!newSettingsByPage[setting?.page]) {
        newSettingsByPage[setting?.page] = {};
      }
      if (!newSettingsByPage[setting?.page][setting?.type]) {
        newSettingsByPage[setting?.page][setting?.type] = [];
      }
      newSettingsByPage[setting?.page][setting?.type].push(setting);
    });

    setSettingsByPage(newSettingsByPage);
  }, [settingsData]);

  const handleSaveSubmit = async (data: SystemSettingsData) => {
    const mergedData: MergedData = {};

    if (data["15_status"] && data["15_value"] && data["15_value"][0]) {
      try {
        const uploadResult = await postAttachment({
          data: data["15_value"][0],
          attachmentType: "pages",
          fileName: data["15_value"][0].name,
        }).unwrap();

        if (uploadResult && uploadResult.data) {
          toast.success(t("toast_systemSettings_send_file_success"));
          data["15_value"] = JSON.stringify(uploadResult.data);
        }
      } catch (error) {
        toast.error(t("toast_systemSettings_send_file_error"));
      }
    }

    Object.entries(data).forEach(([key, value]) => {
      const [id, field] = key.split("_");
      let processedValue;
      switch (field) {
        case "value":
          if (value instanceof FileList) {
            if (value.length === 0) {
              processedValue = defaultValues[key];
            } else {
              processedValue = value[0].name;
            }
          } else {
            processedValue = String(value);
          }
          break;
        case "status":
          processedValue = value ? 1 : 0;
          break;
        default:
          processedValue = value;
          break;
      }
      if (mergedData[id]) {
        mergedData[id][field] = processedValue;
      } else {
        mergedData[id] = { id, [field]: processedValue };
      }
    });

    const updatedSettingsData = settingsData?.data
      ?.map((setting: SystemSettingsData) => {
        const mergedSetting = mergedData[setting?.id];
        if (
          mergedSetting &&
          (mergedSetting.value !== defaultValues[`${setting?.id}_value`] ||
            mergedSetting.status !== defaultValues[`${setting?.id}_status`])
        ) {
          const { language, ...rest } = setting;
          const updatedSetting = {
            ...rest,
            ...mergedSetting,
            languageId: language?.id,
          };
          return updatedSetting;
        }
        return null;
      })
      .filter((setting: SystemSettingsData) => setting !== null);

    try {
      const updatePromises = updatedSettingsData.map(
        (item: SystemSettingsData) => {
          return updateSystemSettings({ id: item.id, data: item });
        }
      );

      const results = await Promise.allSettled(updatePromises);
      let operationSuccessful = true;
      refetch();
      results.forEach((result, index) => {
        if (result.status === "rejected") {
          operationSuccessful = false;
          toast.error(
            `${t("toast_systemSettings_new_settings_save_error")} ${
              updatedSettingsData[index].id
            }: ${result.reason}`
          );
        }
      });
      if (operationSuccessful) {
        toast.success(t("toast_systemSettings_update_success"));
      }
    } catch (error) {
      toast.error(t("toast_systemSettings_update_error"));
    }
  };

  const handleCancel = () => {
    reset(defaultValues);
  };

  return (
    <div className="container p-3 p-md-5 m-0">
      {Object.entries(settingsByPage).map(([page, types]) => (
        <FormProvider {...methods} key={page}>
          <div className="row w-100 p-3 p-md-4 m-0 mb-4 containerWithShadow">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="fs-22 fw-600 m-0">Ustawienia {page}</h2>
              <Button
                className={styles.accordionButton}
                onClick={() => setOpenPage(+page === openPage ? null : +page)}
              >
                {openPage === +page ? <ChevronUp /> : <ChevronDown />}
              </Button>
            </div>
            <Collapse in={openPage === Number(page)}>
              <div className="col-12 mt-4 p-0">
                {Object.entries(types).map(([type, settings]) => (
                  <div key={type} className="mb-4">
                    <h3 className="fs-19 fw-600 mb-4 mt-4">
                      {SYSTEM_SETTINGS.TYPE_NAMES[type as TypeNames]}
                    </h3>
                    <div className=" col border p-3 rounded-4 w-100">
                      {settings.map((setting: PageSettings) => (
                        <RenderSettingsOption
                          key={setting.id}
                          settings={setting}
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end gap-4">
                  <Button className="btn-outline-blue" onClick={handleCancel}>
                    Anuluj
                  </Button>
                  <Button onClick={handleSubmit(handleSaveSubmit)}>
                    Zapisz
                  </Button>
                </div>
              </div>
            </Collapse>
          </div>
        </FormProvider>
      ))}
    </div>
  );
};

export default SystemSettings;
