import { Button, Offcanvas, Spinner } from "react-bootstrap";
import styles from "./NotificationView.module.scss";
import { Container } from "react-bootstrap";
import useScreenSize from "Helpers/useScreenSize";
import {
  useGetNotificationsQuery,
  usePatchNotificationsReadMutation,
} from "../../../redux/services/notificationApi";
import React, { useEffect, useState } from "react";
import { NotificationEntity } from "../../molecules/NotificationEntity/NotificationEntity";
import { ReactComponent as Close } from "../../../assets/Icons/DeleteIcon.svg";
import CustomPagination from "../CustomPagination/CustomPagination";
import { AUTH_DATA } from "../../../Helpers/Roles/authData";
import { UserRole } from "../../../redux/Slice/user-slice";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { NotificationData } from "./types";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleChangeSetUnreadNotification: (unread: boolean) => void;
}

export const INITIAL_SEARCH_PARAMS = {
  PAGE: 1,
  LIMIT: 12,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
};

export const NotificationView = (props: Props) => {
  const screenSize = useScreenSize();
  const [adminOnly, setAdminOnly] = useState<string>("");
  const [unread, setUnread] = useState<string>("1");
  const [currPage, setCurrPage] = useState<number>(INITIAL_SEARCH_PARAMS.PAGE);
  const onNotification = useSelector(
    (state: RootState) => state.webSocket.onNotification
  );
  const { authUser } = useSelector((state: RootState) => state.user);
  const userRolesArray = authUser?.roles?.map(
    (userRole: UserRole) => userRole?.role?.role
  );
  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );
  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    refetch,
  } = useGetNotificationsQuery({
    limit: INITIAL_SEARCH_PARAMS.LIMIT.toString(),
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : INITIAL_SEARCH_PARAMS.OFFSET,
    adminMessagesOnly: adminOnly,
    unread: unread,
  });
  const [patchNotificationsRead] = usePatchNotificationsReadMutation();

  useEffect(() => {
    if (onNotification) {
      props.handleChangeSetUnreadNotification(
        !!onNotification?.data?.payload?.unreadMessage
      );
      refetch();
    }
    //eslint-disable-next-line
  }, [onNotification]);

  useEffect(() => {
    setCurrPage(INITIAL_SEARCH_PARAMS.PAGE);
  }, [unread, adminOnly]);

  const handleReadAll = async () => {
    await patchNotificationsRead({});
  };

  const toggleAdmin = () => {
    setAdminOnly((prevState) => (prevState === "" ? "1" : ""));
  };

  const toggleUnread = () => {
    setUnread((prevState) => (prevState === "" ? "1" : ""));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={props.handleClose}
      backdropClassName="bg-white p-0"
      placement="end"
      className={`${styles.offcanvas}`}
    >
      {screenSize === "small" && (
        <div
          className={`d-flex justify-content-between p-3 border-1 border-bottom ${styles.mobileHeader}`}
        >
          <p className="fs-22 fw-600 m-0 text-dark-blue">Powiadomienia</p>
          <Close className={`${styles.close}`} onClick={props.handleClose} />
        </div>
      )}
      <Container fluid={true} className={`p-3 w-100 h-100 overflow-auto`}>
        <div className={"d-flex gap-3 flex-wrap mb-3 text-dark-blue"}>
          <Button
            variant={"transparent"}
            className={`text-dark-blue ${unread === "" && "btn-active"}`}
            onClick={toggleUnread}
          >
            Wszystkie
          </Button>
          {isAdmin && (
            <Button
              variant={"transparent"}
              className={`text-dark-blue ${adminOnly === "1" && "btn-active"}`}
              onClick={toggleAdmin}
            >
              admin
            </Button>
          )}
          {adminOnly === "" && (
            <Button
              variant={"transparent"}
              className={"text-dark-blue"}
              onClick={handleReadAll}
            >
              oznacz wszystkie jako przeczytane
            </Button>
          )}
        </div>
        {notificationsData?.data?.length ? (
          <div
            className={
              "position-relative d-flex flex-column justify-content-between align-content-between pb-4"
            }
            style={{ height: "auto" }}
          >
            <div className={"flex-grow-1"}>
              {notificationsData?.data?.map(
                (notification: NotificationData) => (
                  <NotificationEntity
                    key={notification.id}
                    data={notification}
                    show={props.show}
                  />
                )
              )}
            </div>

            <div className={"fixed-bottom d-flex justify-content-end me-3"}>
              <CustomPagination
                onPageChange={handlePageChange}
                totalCount={
                  notificationsData?.meta?.totalResults ??
                  INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
                }
                pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
                currentPage={currPage}
              />
            </div>
          </div>
        ) : (
          <div
            className={
              "d-flex justify-content-center align-items-center m-3 border border-natural-gray alivia-radius"
            }
            style={{
              height: `calc(100% - 144px ${adminOnly && " + 72px"})`,
            }}
          >
            <p
              className={
                "flex-grow-1 natural-gray fs-16 fw-semibold text-center m-0 py-5"
              }
            >
              Nie masz żadnych Powiadomień
            </p>
          </div>
        )}

        {notificationsIsLoading && (
          <Container className={"d-flex justify-content-center"}>
            <Spinner animation="border" variant="primary" />
          </Container>
        )}
      </Container>
    </Offcanvas>
  );
};
