import { Container, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useGetLoyaltyProgramServiceCodesQuery } from "redux/services/loyaltyProgramApi";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import LoyaltyProgramServiceCode from "../LoyaltyProgramServiceCode/LoyaltyProgramServiceCode";

interface Props {
  serviceId?: string;
}

export interface CodeData {
  status: number;
  partnerUploaded: number;
  id: number;
  code: string;
  createdAt: string;
  expireAt: string | null;
  usedAt: string | null;
}

const LoyaltyProgramServiceCodes = ({ serviceId }: Props) => {
  const [valueCode, setValueCode] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const { watch, setValue } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const { data: loyaltyProgramServiceCodesData } =
    useGetLoyaltyProgramServiceCodesQuery({
      code: watch("code") || "",
      limit: "0",
      serviceId: serviceId || "",
    });

  const codesOptions = loyaltyProgramServiceCodesData?.data?.map(
    ({ code }: CodeData) => ({
      value: code,
      label: code,
    })
  );

  const handleCodeChange = (
    newCity: SingleValue<{
      value: string;
      label: string;
    } | null>
  ) => {
    setValue("code", newCity?.value || "");
    setValueCode(newCity);
  };

  return (
    <Container fluid={true} className="d-flex flex-column gap-4 mt-4">
      <Row>
        <h2
          className={`fs-22 fw-600 mb-2 text-dark-blue mx-0 col-9 d-md-flex align-items-center`}
        >
          Szukaj kodu
        </h2>
      </Row>
      <Row>
        <label className={"fs-16 pb-1 text-dark-blue"} htmlFor="code">
          Kody
        </label>
        <div className={"w-100"}>
          <Select
            id="code"
            className="w-100"
            classNamePrefix="select"
            value={valueCode}
            isClearable={true}
            isSearchable={true}
            placeholder="Wpisz kod"
            name="code"
            options={codesOptions}
            onChange={handleCodeChange}
            styles={customStyles(false)}
            components={{ DropdownIndicator }}
          />
        </div>
      </Row>
      {watch("code") && (
        <LoyaltyProgramServiceCode
          data={loyaltyProgramServiceCodesData?.data}
        />
      )}
    </Container>
  );
};

export default LoyaltyProgramServiceCodes;
