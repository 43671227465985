import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import { Button, Form, Row } from "react-bootstrap";
import styles from "components/molecules/CompanyForm/CompanyForm.module.scss";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { usePatchDoctorMutation } from "../../../redux/services/doctorApi";
import { useGetDoctorDegreesQuery } from "redux/services/doctorDegreeApi";
import { useTranslation } from "react-i18next";

interface DoctorScientificDegreeFormProps {
  data: DoctorScientificDegreeData;
  handleEdit?: () => void;
  handleClose: () => void;
}

interface DoctorScientificDegreeData {
  id: number;
  firstName: string;
  lastName: string;
  degree: DoctorScientificDegree;
}

interface DoctorScientificDegree {
  id: number;
  name: string;
  shortcut: string;
}

export const DoctorScientificDegreeForm = ({
  data,
  handleClose,
  handleEdit,
}: DoctorScientificDegreeFormProps) => {
  const [patchDoctor] = usePatchDoctorMutation();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DoctorScientificDegreeForm>({
    defaultValues: {
      degreeId: data.degree.id,
      firstName: data.firstName,
      lastName: data.lastName,
    },
  });

  const { data: degreesData, isSuccess: isDegreesDataSuccess } =
    useGetDoctorDegreesQuery({});

  const submitHandler: SubmitHandler<DoctorScientificDegreeForm> = async (
    formData
  ) => {
    const payload = {
      id: data.id,
      data: { ...formData, degreeId: +formData.degreeId },
    };

    const hasBeenUpdated = await patchDoctor(payload);

    if ("data" in hasBeenUpdated) {
      toast.success(t("toast_doctorScientificDegreeForm_success"));
      reset();
      if (handleClose) {
        handleClose();
      }
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated?.error as DoctorScientificDegreeSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorScientificDegreeForm_error_500"));

      if (error.data.statusCode === 409)
        toast.error(t("toast_doctorScientificDegreeForm_error_409"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_doctorScientificDegreeForm_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorScientificDegreeForm_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };

  const MAX_LENGTH = 200;

  return (
    <Form
      className={"d-flex flex-column w-100 "}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Row className={"text-dark-blue"}>
        <span className={"pb-3 text-grey-2 fs-12"}>Zmiana danych konta</span>
        <ComponentWithDescription
          label={"Twój stopień naukowy"}
          labelFs={`fs-16 ${
            errors.degreeId ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 pb-3"
        >
          {isDegreesDataSuccess && (
            <>
              <Form.Select
                id="degree"
                aria-label="Wybierz tytuł naukowy"
                className={`w-100 text-dark-blue ${styles.input} `}
                {...register("degreeId", {
                  required: "Pole nie może być puste.",
                })}
                defaultValue={data.degree.id}
                isInvalid={!!errors?.degreeId}
              >
                {degreesData?.data.map((degree: DoctorScientificDegree) => (
                  <option key={degree.id} value={degree.id}>
                    {degree.shortcut}
                  </option>
                ))}
              </Form.Select>
              {errors?.degreeId && (
                <Form.Control.Feedback type="invalid">
                  {errors?.degreeId?.message}
                </Form.Control.Feedback>
              )}
            </>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Imię"}
          labelFs={"fs-16"}
          className="my-2 pb-3"
        >
          <Form.Control
            type="text"
            id="firstName"
            aria-describedby="firstName"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("firstName", {
              required: "Pole nie może być puste.",
              maxLength: {
                value: MAX_LENGTH,
                message: `Pole nie może zawierać więcej niż ${MAX_LENGTH} znaków`,
              },
            })}
            isInvalid={!!errors?.firstName}
          />
          {errors?.firstName && (
            <Form.Control.Feedback type="invalid">
              {errors?.firstName?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Nazwisko / nazwiska"}
          labelFs={"fs-16"}
          className="my-2 pb-3"
        >
          <Form.Control
            type="text"
            id="lastName"
            aria-describedby="lastName"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("lastName", {
              required: "Pole nie może być puste.",
              maxLength: {
                value: MAX_LENGTH,
                message: `Pole nie może zawierać więcej niż ${MAX_LENGTH} znaków`,
              },
            })}
            isInvalid={!!errors?.lastName}
          />
          {errors?.lastName && (
            <Form.Control.Feedback type="invalid">
              {errors?.lastName?.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
      </Row>
      <div className="d-flex gap-2 mt-2 justify-content-between align-items-center">
        <div className={"text-dark-blue fs-16 fw-semibold"}></div>
        <div className={"d-flex gap-2"}>
          <Button
            className="btn-outline-dark-blue"
            onClick={() => {
              if (handleClose) {
                handleClose();
              }
            }}
          >
            ANULUJ
          </Button>
          <Button type="submit" value="Submit">
            {data ? "ZAPISZ" : "UTWÓRZ"}
          </Button>
        </div>
      </div>
    </Form>
  );
};
