import { Container } from "react-bootstrap";
import styles from "./PatientsOnkorallies.module.scss";
import { Onkorally } from "components/molecules/Onkorally/Onkorally";
import {
  useGetUserGuardiansAndPatientsQuery,
  useGetSfContactfromPatientIdQuery,
} from "redux/services/usersApi";
import { useTranslation } from "react-i18next";
import { isOneOfObjectValuesEmpty } from "../../../Helpers/isOneOfObjectValuesEmpty";

interface PatientsData {
  patientId: PatientIdData;
}

interface PatientIdData {
  id: number;
}

function PatientOnkorally({ patientId }: { patientId: number }) {
  const { data: sfContactData } = useGetSfContactfromPatientIdQuery({
    userId: String(patientId),
  });

  const contactData = sfContactData?.data;

  const analitycsData = contactData
    ? {
        name: contactData?.Wskazanie_na_skarbonke__r?.Tytul_zbiorki__c?.toString(),
        value: (
          contactData?.Wskazanie_na_skarbonke__r?.Dost_pne_rodki__c +
          -contactData?.Wskazanie_na_skarbonke__r?.Wyplaty__c
        )?.toString(),
        max: contactData?.Wskazanie_na_skarbonke__r?.Cel_zbi_rki__c?.toString(),
        link: contactData?.Wskazanie_na_skarbonke__r?.Adres__c?.toString(),
        user: contactData?.GR_imi_i_nazwisko__c?.toString(),
      }
    : null;

  if (
    analitycsData &&
    contactData?.Wskazanie_na_skarbonke__r?.Cel_zbiorki__c !== 0 &&
    contactData?.Wskazanie_na_skarbonke__r?.Cel_zbiorki__c !== null &&
    !isOneOfObjectValuesEmpty(analitycsData)
  ) {
    return <Onkorally data={analitycsData} />;
  }
  return null;
}

const PatientsOnkorallies = () => {
  const { data: usersData, error } = useGetUserGuardiansAndPatientsQuery({});
  const patientIds =
    usersData?.data?.patients?.map(
      (patient: PatientsData) => patient.patientId.id
    ) || [];
  const { t } = useTranslation();

  const renderedPatients = patientIds.map((id: number) => (
    <PatientOnkorally key={id} patientId={id} />
  ));
  const hasValidPatients = renderedPatients.some(
    (patient: JSX.Element) => patient !== null
  );

  if (error) {
    return (
      <Container
        fluid
        className={`p-4 containerWithShadow align-items-center text-center d-flex justify-content-center ${styles.wrapper}`}
      >
        <p className="m-0 text-dark-blue">
          {t("widget_patients_oncocollections_error")}
        </p>
      </Container>
    );
  }

  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
        {t("widget_patients_oncocollections_title")}
      </h2>
      {hasValidPatients ? (
        renderedPatients
      ) : (
        <Container
          fluid
          className={`p-4 my-2 align-items-center text-center d-flex justify-content-center ${styles.wrapper}`}
        >
          <p className="m-0 text-dark-blue">
            {t("widget_patients_oncocollections_p")}
          </p>
        </Container>
      )}
    </Container>
  );
};

export default PatientsOnkorallies;
