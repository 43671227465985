export const POLL_DATA: {
  VERIFICATION_TYPES: RadioInputWithStringValue[];
  ADMIN_VERIFICATION: RadioInputWithNumberValue[];
  REUSABILITY: RadioInputWithNumberValue[];
  STATUSES: RadioInputWithNumberValue[];
  SF_CONNECTION: RadioInputWithNumberValue[];
} = {
  VERIFICATION_TYPES: [
    {
      name: "none",
      label: "Brak",
      value: "none",
    },
    {
      name: "email",
      label: "E-mail",
      value: "email",
    },
    {
      name: "sms",
      label: "SMS",
      value: "sms",
    },
  ],
  ADMIN_VERIFICATION: [
    {
      name: "verified",
      label: "Zweryfikowana",
      value: 1,
    },
    {
      name: "notVerified",
      label: "Niezweryfikowana",
      value: 0,
    },
  ],
  REUSABILITY: [
    {
      name: "reusable",
      label: "Wielorazowa",
      value: 1,
    },
    {
      name: "disposable",
      label: "Jednorazowa",
      value: 0,
    },
  ],
  STATUSES: [
    {
      name: "active",
      label: "Aktywna",
      value: 1,
    },
    {
      name: "inactive",
      label: "Nieaktywna",
      value: 0,
    },
  ],
  SF_CONNECTION: [
    {
      name: "yes",
      label: "Tak",
      value: 1,
    },
    {
      name: "no",
      label: "Nie",
      value: 0,
    },
  ],
};

export const POLL_PAGE_QUESTION_DATA: {
  FIELD_TYPES: FieldTypeData[];
  STATUSES: RadioInputWithNumberValue[];
} = {
  FIELD_TYPES: [
    {
      label: "Krótka odpowiedź",
      value: "text",
    },
    {
      label: "Długa odpowiedź",
      value: "textarea",
    },
    {
      label: "Jednokrotny wybór",
      value: "radio",
    },
    {
      label: "Wielokrotny wybór",
      value: "checkbox",
    },
    {
      label: "Ocena",
      value: "rating-radio",
    },
    {
      label: "Tak / Nie",
      value: "boolean-radio",
    },
    {
      label: "Akceptacja",
      value: "agreement",
    },
    {
      label: "Lista wyboru - select (zapisuje ID)",
      value: "select",
    },
    {
      label: "Lista wyboru - select (zapisuje nazwę)",
      value: "datalist",
    },
    {
      label: "Wybór placówek medycznych",
      value: "facilities-select",
    },
    {
      label: "Data i godzina",
      value: "datetime-local",
    },
    {
      label: "Data",
      value: "date",
    },
    {
      label: "Data urodzenia",
      value: "birth-date",
    },
    {
      label: "Godzina",
      value: "time",
    },
    {
      label: "E-mail",
      value: "email",
    },
    {
      label: "Numer telefonu",
      value: "tel",
    },
    {
      label: "Załączniki",
      value: "attachments",
    },
    {
      label: "Wartość numeryczna",
      value: "number",
    },
    {
      label: "Suwak",
      value: "range",
    },
    {
      label: "Nagłówek",
      value: "header",
    },
  ],
  STATUSES: [
    {
      name: "active",
      label: "Aktywne",
      value: 1,
    },
    {
      name: "inactive",
      label: "Nieaktywne",
      value: 0,
    },
  ],
};

export const POLL_META_DATA: {
  PATTERNS: PatternData[];
  TABLE_NAMES: TableNameData[];
  FIELD_SIZES: FieldSizeData[];
  OUTPUT_TABLE_NAMES: OutputTableNameData[];
} = {
  PATTERNS: [
    {
      fieldTypes: ["tel"],
      label: "Numer telefonu (Polska)",
      value:
        "/^(?:(?:(?:+|00)48)|(?:(+?48)))?[ -]?(?:(?:d{2}[ -]?d{3}[ -]?d{2}[ -]?d{2})|(?:d{3}[ -]?d{3}[ -]?d{3}))$/",
      message: "Podaj prawidłowy numer telefonu zarejestrowany w Polsce.",
    },
    {
      fieldTypes: ["text", "textarea"],
      label: "Tekst - polski alfabet",
      value: "/^[p{L}p{N}p{P}p{S}]+$/u",
      message:
        "Tekst może zawierać tylko litery, cyfry i znaki specjalne dostepne w polskim alfabecie.",
    },
    {
      fieldTypes: ["text"],
      label: "Link (adres URL)",
      value: "/^(?:https?|ftp)://[^s/$.?#].[^s]*$/",
      message: "Podaj prawidłowy link (adres URL).",
    },
  ],
  TABLE_NAMES: [
    {
      label: "Lekarze",
      value: "doctor",
    },
    {
      label: "Miasta",
      value: "city",
    },
    {
      label: "Nowotwory",
      value: "oi_cancer",
    },
    {
      label: "Placówki",
      value: "facility",
    },
    {
      label: "Specjalizacje lekarskie",
      value: "doctor_specialization",
    },
    {
      label: "Województwa",
      value: "province",
    },
  ],
  FIELD_SIZES: [
    {
      label: "100% (1/1)",
      value: 12,
    },
    {
      label: "75% (3/4)",
      value: 9,
    },
    {
      label: "66% (2/3)",
      value: 8,
    },
    {
      label: "50% (1/2)",
      value: 6,
    },
    {
      label: "33% (1/3)",
      value: 4,
    },
    {
      label: "25% (1/4)",
      value: 3,
    },
  ],
  OUTPUT_TABLE_NAMES: [
    {
      label: "Opinie lekarzy",
      value: "doctor_reviews",
    },
    {
      label: "Ocene placówek",
      value: "facility_reviews",
    },
  ],
};
