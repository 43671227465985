import React from "react";
import { HTMLAttributes } from "react";
import { Container } from "react-bootstrap";
import styles from "./PageContainer.module.scss";
interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export default function PageContainer({
  children,
  className,
  ...props
}: PageContainerProps) {
  return (
    <Container fluid className={`${styles.Container} ${className}`} {...props}>
      {children}
    </Container>
  );
}
