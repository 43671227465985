import PollViewTableRow from "components/organisms/PollViewTableRow/PollViewTableRow";
import SortButton from "components/organisms/SortButton/SortButton";
import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

interface PollsTableProps {
  data: PollTableData[];
}

const PollsTable = ({ data }: PollsTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState<string | null>(searchParams.get("sortBy"));

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const sortHandler = (label: SortLabel) => {
    let nextSort: string;
    switch (label) {
      case "title":
        sort === "title,ASC"
          ? (nextSort = "title,DESC")
          : (nextSort = "title,ASC");
        break;

      case "verificationType":
        sort === "verificationType,ASC"
          ? (nextSort = "verificationType,DESC")
          : (nextSort = "verificationType,ASC");
        break;

      case "language":
        sort === "language,ASC"
          ? (nextSort = "language,DESC")
          : (nextSort = "language,ASC");
        break;

      case "userRole":
        sort === "userRole,ASC"
          ? (nextSort = "userRole,DESC")
          : (nextSort = "userRole,ASC");
        break;

      default:
        nextSort = "title,ASC";
        break;
    }

    handleSort(nextSort);
  };

  const thStyles = "container-fluid pb-4 border border-0";

  return (
    <Table>
      <thead>
        <tr>
          <th className={thStyles}>
            <Row>
              <Col>
                <SortButton
                  label="Tytuł"
                  onClick={() => sortHandler("title")}
                  isArrowRotated={sort === "title,DESC"}
                />
              </Col>
              <Col>
                <SortButton
                  label="Weryfikacja"
                  onClick={() => sortHandler("verificationType")}
                  isArrowRotated={sort === "verificationType,DESC"}
                />
              </Col>
              <Col>
                <SortButton
                  label="Rola"
                  onClick={() => sortHandler("userRole")}
                  isArrowRotated={sort === "userRole,DESC"}
                />
              </Col>
              <Col>
                <SortButton
                  label="Język"
                  onClick={() => sortHandler("language")}
                  isArrowRotated={sort === "language,DESC"}
                />
              </Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((poll, idx) => (
          <PollViewTableRow key={idx} data={poll} />
        ))}
        {(!data || data?.length === 0) && (
          <tr>
            <td colSpan={4} className="text-center">
              Nie znaleziono żadnych ankiet.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PollsTable;
