import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import Star from "../../../assets/Icons/star.svg";
import StarFilled from "../../../assets/Icons/starFill.svg";
import styles from "./RatingRadio.module.scss";

interface RatingRadioProps {
  register: UseFormRegisterReturn;
  setValue: (name: string, value: string) => void;
  onButtonClick: (value: number) => void;
  currentRating: number;
  numberOfPossibleAnswers: number;
  fieldName: string;
  readOnly?: boolean;
}

const RatingRadio: React.FC<RatingRadioProps> = ({
  register,
  setValue,
  onButtonClick,
  currentRating,
  numberOfPossibleAnswers,
  fieldName,
  readOnly,
}) => {
  const stars = Array.from(
    { length: numberOfPossibleAnswers },
    (_, i) => i + 1
  );

  return (
    <div>
      {stars.map((star) => (
        <span key={star} className={`p-2  ${styles.ratingButton}`}>
          <input
            className={styles.ratingRadio}
            type="radio"
            id={`${fieldName}-${star}`}
            value={star}
            checked={currentRating === star}
            {...register}
            onChange={() => {
              setValue(fieldName, star.toString());
              onButtonClick(star);
            }}
            disabled={readOnly}
          />

          <label htmlFor={`${fieldName}-${star}`}>
            {currentRating >= star ? (
              <img src={StarFilled} alt={`Star ${star}`} />
            ) : (
              <img src={Star} alt={`Star ${star}`} />
            )}
          </label>
        </span>
      ))}
    </div>
  );
};

export default RatingRadio;
