// const apiKey = sessionStorage.getItem("api_key");

export const articles = `/api/v1/articles`;
export const attachments = `/api/v1/attachments`;
export const cancers = `/api/v1/cancers`;
export const cardCodes = `/api/v1/card-codes`;
export const cities = `/api/v1/cities`;
export const companies = `/api/v1/company`;
export const companyDepartment = `/api/v1/company-department`;
export const countries = `/api/v1/countries`;
export const detailedFacilities = `/api/v1/detailed-facilities`;
export const doctor = `/api/v1/doctor`;
export const doctorDegree = `/api/v1/doctor/degree`;
export const doctorSpecialization = `/api/v1/doctor-specialization`;
export const events = `/api/v1/events`;
export const eventTypes = `/api/v1/event-types`;
export const facilities = `/api/v1/facilities`;
export const facilityReviews = `/api/v1/facility-reviews`;
export const feed = `/api/v1/feed`;
export const getResponse = "api/v1/get-response";
export const languages = `/api/v1/languages`;
export const logOutImpersonate = `/api/v1/logout-impersonate`;
export const loyaltyPrograms = `/api/v1/loyalty-programs`;
export const me = "/api/v1/me";
export const notifications = `/api/v1/notifications`;
export const pages = `/api/v1/pages`;
export const pagesCategory = `/api/v1/pages-category`;
export const polls = `/api/v1/polls`;
export const pollQuestionAnswers = `/api/v1/poll-question-answers`;
export const pollUsers = `/api/v1/poll-users`;
export const provinces = `/api/v1/provinces`;
export const salesForce = "api/v1/me/sf-contact";
export const searchResults = `/api/v1/search-results`;
export const services = `/api/v1/services`;
export const system = `/api/v1/systems`;
export const systemSettings = `/api/v1/system-settings`;
export const stats = `/api/v1/stats`;
export const substance = `/api/v1/substance`;
export const topic = "api/v1/topic";
export const topicCase = `/api/v1/topic-case`;
export const translations = `/api/v1/translations`;
export const users = `/api/v1/users`;
export const usersSF = `https://${
  process.env.REACT_APP_MODE === "dev" ? "dev-" : ""
}api.alivia.org.pl/api/v1/users`;
export const usersVerify = `https://${
  process.env.REACT_APP_MODE === "dev" ? "dev-" : ""
}api.alivia.org.pl/api/v1/users/verify`;
export const userRoles = `/api/v1/user-roles`;
export const emails = `/api/v1/messages`;
