import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { MapModal } from "../../organisms/MapModal/MapModal";
import { LoyaltyProgramServiceDepartment } from "../LoyaltyProgramServiceArticle/types";
import { PhoneNumberLink } from "../../atoms/PhoneNumberLink/PhoneNumberLink";
import styles from "./CompanyInfo.module.scss";

interface Props {
  data: LoyaltyProgramServiceDepartment;
}

export const CompanyInfo = (props: Props) => {
  const [showMap, setShowMap] = useState(false);
  const address = `${props.data.city.name}, ${props.data.street} ${
    props.data.houseNumber
  }${props.data.apartmentNumber ? `/${props.data.apartmentNumber}` : ""}`;

  return (
    <Col className={"border rounded-3 mb-4 p-3 text-dark-blue"}>
      <span className={"fw-bolder fs-16"}>{props.data.name}</span>
      <Row className={`text-dark-blue mt-4 ${styles.article}`}>
        <Col className={`col-12 col-lg-4 pb-2 pb-lg-0`}>
          <div className={"fs-12 ls-6 fw-semibold pb-3 text-grey-2"}>
            <span>Telefon</span>
          </div>
          {props.data.phoneNumbers && (
            <div className={"fs-14 pb-2"}>
              {JSON.parse(props.data.phoneNumbers).map(
                (value: string, index: number) => (
                  <PhoneNumberLink key={index} phoneNumber={value} />
                )
              )}
            </div>
          )}
        </Col>
        <Col className={`col-12 col-lg-4 py-2 py-lg-0`}>
          <div className={"fs-12 ls-6 fw-semibold pb-3 text-grey-2"}>
            <span>Adres</span>
          </div>
          <div className={"fs-14 pb-2"}>
            <span>{address}</span>
          </div>
          <span
            onClick={() => setShowMap((prev) => !prev)}
            className={"fs-14 text-primary cursor-pointer"}
          >
            POKAŻ NA MAPIE
          </span>
          <MapModal
            show={showMap}
            handleClose={() => setShowMap((prev) => !prev)}
            address={address}
          />
        </Col>
        <Col className={`col-12 col-lg-4 pt-2 pt-lg-0`}>
          <div className={"fs-12 ls-6 fw-semibold pb-3 text-grey-2"}>
            <span>E-mail</span>
          </div>
          {props.data.emailAddresses !== '[""]' &&
            props.data.emailAddresses !== null &&
            JSON.parse(props.data.emailAddresses).map((value: string) => (
              <div className={"fs-14 pb-2 "}>
                <a
                  className={"linkAlivia"}
                  href={`mailto:${value}?subject=${props.data.name} - ${address}`}
                >
                  {value}
                </a>
              </div>
            ))}
        </Col>
      </Row>
    </Col>
  );
};
