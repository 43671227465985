import { EventData } from "../components/organisms/AddEventModal/types";

const sortByEventTime = (events: EventData[]) => {
  if (events) {
    return events?.sort((eventA, eventB) => {
      const eventATime = new Date(eventA?.startingDate)?.getTime();
      const eventBTime = new Date(eventB?.startingDate)?.getTime();

      if (eventATime < eventBTime) {
        return -1;
      } else if (eventATime > eventBTime) {
        return 1;
      }

      return 0;
    });
  }

  return null;
};

export default sortByEventTime;