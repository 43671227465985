import { useGetPostsQuery } from "redux/services/feedApi";
import { useGetFacilitiesQuery } from "redux/services/facilitiesApi";
import { useGetDoctorsQuery } from "redux/services/doctorApi";
import { useGetLoyaltyProgramsServicesQuery } from "redux/services/loyaltyProgramApi";
import { useGetCompaniesQuery } from "redux/services/companyApi";
import { useGetEventsQuery } from "redux/services/eventsApi";
import { useGetSearchResultsQuery } from "../../../redux/services/searchResults";
import { usePostSearchResultsMutation } from "../../../redux/services/searchResults";
import React, { useEffect, useState, useMemo } from "react";
import Select, { ActionMeta } from "react-select";
import { useNavigate } from "react-router-dom";
import style from "../NavBar/NavBar.module.scss";
import {
  CustomGroup,
  customStyles,
  SearchDropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { removePolishCharacters } from "../../../Helpers/removePolishCharacters";
import { replaceSpacesWithDashes } from "../../../Helpers/replaceSpacesWithDashes";
import openInNewTab from "../../../Helpers/openInNewTab";
import { NAV_PATH } from "../Navigation/navigationData";
import bufferToString from "../../../Helpers/bufferToString";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

interface SearchBarProps {
  isDisabled?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ isDisabled }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [categoryLimits, setCategoryLimits] = useState<CategoryLimits>({
    "Posty społeczności": 5,
    Placówki: 5,
    Lekarze: 5,
    Benefity: 5,
    Wydarzenia: 5,
    "Partnerzy fundacji": 5,
  });
  const [optionsCountState, setOptionsCountState] = useState<CategoryLimits>(
    {}
  );
  const [recentSearchTerms] = useState<string[]>([]);
  const [loadingMoreCategory, setLoadingMoreCategory] = useState<string | null>(
    null
  );

  const queryFeedOptions = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          nameLike: searchTerm.toLowerCase(),
          limit: categoryLimits["Posty społeczności"],
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const queryEventOptions = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          search: searchTerm.toLowerCase(),
          limit: categoryLimits["Wydarzenia"],
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const queryLoyaltyProgramOptions = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          nameLike: searchTerm.toLowerCase(),
          limit: categoryLimits["Benefity"],
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const queryDoctorOptions = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          nameLike: searchTerm.toLowerCase(),
          limit: categoryLimits["Lekarze"].toString(),
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const queryOptionsForFacilities = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          status: "1",
          nameLike: searchTerm.toLowerCase(),
          limit: categoryLimits.Placówki.toString(),
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const queryOptionsForCompanies = useMemo(() => {
    return searchTerm.length >= 3
      ? {
          limit: categoryLimits["Partnerzy fundacji"],
          nameLike: searchTerm,
          isSearch: 1,
        }
      : { skip: true };
  }, [searchTerm, categoryLimits]);

  const { data: feedPostsData, isLoading: feedPostsLoading } =
    useGetPostsQuery(queryFeedOptions);
  const { data: facilitiesData, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery(queryOptionsForFacilities);
  const { data: doctorsData, isLoading: doctorsLoading } =
    useGetDoctorsQuery(queryDoctorOptions);

  const {
    data: loyaltyProgramsServiceCompanyData,
    isLoading: loyaltyProgramsLoading,
  } = useGetLoyaltyProgramsServicesQuery(queryLoyaltyProgramOptions);
  const { data: eventsData, isLoading: eventsLoading } =
    useGetEventsQuery(queryEventOptions);
  const { data: searchResultsData, isLoading: searchResultsDataLoading } =
    useGetSearchResultsQuery({ limit: "4" });
  const { data: companyDepartmentsData, isLoading: companyDepartmentsLoading } =
    useGetCompaniesQuery(queryOptionsForCompanies);

  const [mutateAsync] = usePostSearchResultsMutation();

  const ONKO_URL =
    process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
    (process.env?.REACT_APP_MODE === "dev"
      ? "https://dev.onkomapa.pl"
      : "https://onkomapa.pl");
  const ONKO_URL_DOCTOR = `${ONKO_URL}/szukaj/lekarz`;
  const ONKO_URL_FACILITY = `${ONKO_URL}/szukaj/placowka`;

  const allOptions: OptionGroup[] = useMemo(() => {
    const loadingData: Option[] = [{ value: "loading", label: "Ładowanie..." }];
    const postOptions: Option[] = Array.isArray(feedPostsData?.data)
      ? feedPostsData?.data?.map((post: Post) => {
          const tags = post?.feedTag?.map((tag) => tag.tag).join(", ");
          return {
            value: `feed-${post.id.toString()}`,
            label: tags ? `${post.content} - ${tags}` : `${post.content}`,
          };
        })
      : [];
    // const facilityOptions: Option[] = Array.isArray(facilitiesData?.data)
    //   ? facilitiesData?.data?.map((facility: Facility) => ({
    //       value: `facility-${facility.id.toString()}`,
    //       label: facility.name,
    //     }))
    //   : [];
    // const doctorOptions: Option[] = Array.isArray(doctorsData?.data)
    //   ? doctorsData?.data?.map((doctor: Doctor) => ({
    //       value: `doctor-${doctor.id.toString()}`,
    //       label: `${doctor.firstName} ${doctor.lastName}`,
    //     }))
    //   : [];
    const loyaltyProgramOptions: Option[] = Array.isArray(
      loyaltyProgramsServiceCompanyData?.data
    )
      ? loyaltyProgramsServiceCompanyData?.data?.map(
          (loyaltyProgram: LoyaltyProgram) => {
            const description = loyaltyProgram?.description
              ? loyaltyProgram?.description
                  ?.replace(/(<([^>]+)>)/gi, "")
                  ?.substring(0, 100) +
                (loyaltyProgram?.description?.length > 100 ? "..." : "")
              : null;

            return {
              value: `benefit-${loyaltyProgram?.id?.toString()}`,
              label: description
                ? `${loyaltyProgram?.name} - ${description}`
                : `${loyaltyProgram?.name}`,
            };
          }
        )
      : [];
    const eventsOptions: Option[] = Array.isArray(eventsData?.events)
      ? eventsData?.events?.map((event: Event) => {
          // zachowana funkcjonalność usuwania HTML ze string w razie zmiany decyzji przez klienta w sprawie pozostawienia description w wynikach wyszukiwania
          // let description = null;
          //
          // if (event?.description) {
          //   const formattedDescription = `${event?.description?.substring(
          //     0,
          //     100,
          //   )}${event?.description?.length > 100 ? "..." : ""}`;
          //   const parser = new DOMParser();
          //   description =
          //     parser?.parseFromString(formattedDescription, "text/html")?.body
          //       ?.textContent ?? null;
          // }

          return {
            value: `event-${event?.id?.toString()}`,
            label: event?.name,
            extendedSearch: event?.description,
          };
        })
      : [];
    const companyOptions: Option[] = Array.isArray(companyDepartmentsData?.data)
      ? companyDepartmentsData?.data?.map((company: CompanyData) => ({
          value: `company-${company.id.toString()}`,
          label: company.name,
        }))
      : [];
    return [
      {
        label: "Posty społeczności",
        options: feedPostsLoading ? loadingData : postOptions,
      },
      // todo: do przywrócenia po publikacji "nowej Onkomapy"
      // todo: https://portal.atsolutions24.com/admin/tasks/view/79
      // {
      //   label: "Placówki",
      //   options: facilitiesLoading ? loadingData : facilityOptions,
      // },
      // {
      //   label: "Lekarze",
      //   options: doctorsLoading ? loadingData : doctorOptions,
      // },
      {
        label: "Benefity",
        options: loyaltyProgramsLoading ? loadingData : loyaltyProgramOptions,
      },
      {
        label: "Wydarzenia",
        options: eventsLoading ? loadingData : eventsOptions,
      },
      {
        label: "Partnerzy fundacji",
        options: companyDepartmentsLoading ? loadingData : companyOptions,
      },
    ];
  }, [
    feedPostsData,
    feedPostsLoading,
    // facilitiesData,
    // facilitiesLoading,
    // doctorsData,
    // doctorsLoading,
    loyaltyProgramsServiceCompanyData,
    loyaltyProgramsLoading,
    eventsData,
    eventsLoading,
    companyDepartmentsLoading,
    companyDepartmentsData,
  ]);

  const handleSelectChange = async (
    selectedOption: Option | null,
    _: ActionMeta<Option>,
    event?: React.SyntheticEvent<HTMLElement>
  ) => {
    if (selectedOption === null) {
      setSelectedOption(null);
      setSearchTerm("");
      return;
    }
    const [prefix, realId] = selectedOption.value.split("-");

    const searchedBy = searchTerm;
    const searchedModule = prefix;
    const postData = {
      searchText: searchedBy,
      url: "",
      module: searchedModule,
    };
    let url = "";
    const firePostRequest = async () => {
      try {
        await mutateAsync({ data: postData });
      } catch (error) {
        console.log(error);
      }
    };
    switch (prefix) {
      case "feed":
        url = `/${NAV_PATH.FEED}/${realId}`;
        postData.url = url;
        await firePostRequest();
        break;
      case "facility":
        const selectedFacility = facilitiesData?.data?.find(
          (facility: Facility) => facility.id.toString() === realId
        );
        const sanitizedFacilityName = replaceSpacesWithDashes(
          removePolishCharacters(selectedFacility.name)
        );
        url = `${ONKO_URL_FACILITY}/${sanitizedFacilityName}/${realId}`;
        postData.url = url;
        await firePostRequest();
        break;
      case "doctor":
        const selectedDoctor = doctorsData?.data?.find(
          (doctor: Doctor) => doctor.id.toString() === realId
        );
        if (selectedDoctor) {
          const sanitizedDegree = replaceSpacesWithDashes(
            removePolishCharacters(selectedDoctor.degree.name)
          );
          const sanitizedFirstName = replaceSpacesWithDashes(
            removePolishCharacters(selectedDoctor.firstName)
          );
          const sanitizedLastName = replaceSpacesWithDashes(
            removePolishCharacters(selectedDoctor.lastName)
          );
          url = `${ONKO_URL_DOCTOR}/${sanitizedDegree}-${sanitizedFirstName}-${sanitizedLastName}/${selectedDoctor.id}`;
        }
        postData.url = url;
        await firePostRequest();
        break;
      case "benefit":
        url = `/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}/${realId}`;
        postData.url = url;
        await firePostRequest();
        break;
      case "event":
        url = `/${NAV_PATH.EVENTS}/${realId}`;
        postData.url = url;
        await firePostRequest();
        break;
      case "company":
        url = `/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${realId}`;
        postData.url = url;
        await firePostRequest();
        break;
      case "searchResult":
      case "loading":
      case "brak":
        if (selectedOption?.value === "brak") {
          event?.preventDefault();
          event?.stopPropagation();
        }
        if (selectedOption?.value === "loading") {
          event?.preventDefault();
          event?.stopPropagation();
        }
        event?.preventDefault();
        event?.stopPropagation();
        const uniqueSearchResult = searchResultsData?.data?.find(
          (searchResult: SearchResults) =>
            searchResult.searchText === selectedOption.label
        );
        if (uniqueSearchResult) {
          setSearchTerm(uniqueSearchResult.searchText);
          setSelectedOption(selectedOption);
          return;
        } else {
          console.warn("No search result found for the selected option");
          return;
        }
      default:
        break;
    }
    if (url.startsWith("https://")) {
      openInNewTab(url);
    } else {
      navigate(url);
    }
    setSelectedOption(selectedOption);
  };
  const [filteredOptions, setFilteredOptions] = useState(allOptions);
  const handleSearchResultClick = (
    searchResultId: string,
    event: React.MouseEvent
  ) => {
    event?.preventDefault();
  };

  useEffect(() => {
    if (searchTerm === "") {
      const recommendedOptions: OptionGroup = {
        label: "Ostatnio wyszukiwane",
        options:
          searchResultsData?.data?.map((searchResult: SearchResults) => ({
            value: `searchResult-${bufferToString(searchResult.id)}`,
            label: searchResult.searchText,
          })) || [],
      };
      recommendedOptions.options.forEach((option: Option) => {
        (option as any).onClick = (event: any) =>
          handleSearchResultClick(option.value, event);
      });
      setFilteredOptions([recommendedOptions]);
      return;
    }

    const terms = searchTerm.toLowerCase().split(" ").filter(Boolean);

    const newOptions: OptionGroup[] = allOptions
      .map((category: OptionGroup) => {
        const filteredItems = category.options
          .filter((item: Option) => {
            const label =
              typeof item.label === "string"
                ? item.label.toLowerCase()
                : item.label;
            return typeof label === "string" && !item?.extendedSearch
              ? terms.every((term) => label.includes(term))
              : true;
          })
          .slice(0, categoryLimits[category.label]);
        return {
          ...category,
          options: filteredItems,
          optionsCount: filteredItems.length,
        };
      })
      .filter((category: OptionGroup) => category.options.length > 0);

    setFilteredOptions(newOptions);
  }, [searchTerm, allOptions, searchResultsData, categoryLimits]);
  const showMore = async (categoryLabel: string) => {
    setLoadingMoreCategory(categoryLabel);
    try {
      setCategoryLimits((prevLimits) => ({
        ...prevLimits,
        [categoryLabel]: prevLimits[categoryLabel] + 5,
      }));

      const allQueriesLoaded = !(
        feedPostsLoading ||
        facilitiesLoading ||
        doctorsLoading ||
        loyaltyProgramsLoading ||
        eventsLoading ||
        searchResultsDataLoading ||
        companyDepartmentsLoading
      );

      if (allQueriesLoaded) {
        setLoadingMoreCategory(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const newOptionsCountState: CategoryLimits = {};
    filteredOptions.forEach((optionGroup: OptionGroup) => {
      newOptionsCountState[optionGroup.label] = optionGroup.options.length;
    });
    setOptionsCountState(newOptionsCountState);
  }, [filteredOptions]);

  const customFiltering = (option: FilterOptionOption<any>, input: string) => {
    const newFilteredOptions = filteredOptions?.map((category: OptionGroup) =>
      category.options.filter((item: Option) => {
        return (
          (typeof item?.label === "string" && item?.label?.includes(input)) ??
          item?.extendedSearch?.includes(input)
        );
      })
    ) as any;

    return newFilteredOptions;
  };

  return (
    <>
      <Select
        value={selectedOption}
        options={filteredOptions}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            setSearchTerm(value);
          }
        }}
        isDisabled={isDisabled}
        isLoading={
          feedPostsLoading ||
          facilitiesLoading ||
          doctorsLoading ||
          loyaltyProgramsLoading ||
          eventsLoading ||
          searchResultsDataLoading ||
          companyDepartmentsLoading
        }
        loadingMessage={() => "Ładowanie..."}
        isMulti={false}
        isSearchable={true}
        isClearable={true}
        classNamePrefix="select"
        className={`col-12 ${style.navBarSearchInput}`}
        placeholder="Szukaj"
        styles={customStyles(false)}
        filterOption={customFiltering}
        components={{
          DropdownIndicator: SearchDropdownIndicator,
          Group: (props) => {
            const { label, ...rest } = props as any;
            if (typeof label === "string") {
              return (
                <CustomGroup
                  {...rest}
                  label={label}
                  optionsCount={optionsCountState[label]}
                  onShowMore={showMore}
                  isLoadingMore={loadingMoreCategory === label}
                />
              );
            } else {
              return null;
            }
          },
        }}
        noOptionsMessage={() =>
          feedPostsLoading ||
          facilitiesLoading ||
          doctorsLoading ||
          loyaltyProgramsLoading ||
          eventsLoading ||
          searchResultsDataLoading ||
          companyDepartmentsLoading ? (
            "Ładowanie..."
          ) : searchTerm === "" ? (
            recentSearchTerms.length > 0 ? null : (
              "Wpisz szukaną frazę"
            )
          ) : (
            <span>
              <p className="p-0 my-0 me-1">
                Brak wyników wyszukiwania, spróbuj jeszcze użyć wyszukiwarki w
                naszej{" "}
              </p>

              <a
                href="https://alivia.org.pl/wiedza-o-raku/"
                target="_blank"
                rel="noreferrer"
              >
                Bazie Wiedzy o Raku.
              </a>
            </span>
          )
        }
        onChange={handleSelectChange}
      />
    </>
  );
};

export default SearchBar;
