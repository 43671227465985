import YouTube from "react-youtube";
import styles from "../LinkPreview.module.scss";

export const YoutubeWidget = ({ id }: OtherPlatformWidgetProps) => (
  <div
    className={`d-flex justify-content-center align-items-center col-12 ${styles.YoutubeWidgetWrapper}`}
  >
    <YouTube
      videoId={id}
      className="col-12 h-100"
      iframeClassName="col-12 h-100"
    />
  </div>
);
