import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import { useState } from "react";
import { Button, Col, Container, Form } from "react-bootstrap";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetPollsQuery } from "redux/services/pollsApi";
import { useForm } from "react-hook-form";
import PollsTable from "components/organisms/Tables/PollsTable/PollsTable";
import CustomPagination from "components/organisms/CustomPagination/CustomPagination";
import { useGetLanguagesQuery } from "redux/services/languagesApi";
import { POLL_DATA } from "Helpers/pollsData";
import { useGetUserRolesQuery } from "redux/services/userRolesApi";
import PollAnswersDisplay from "components/organisms/PollAnswersDisplay/PollAnswersDisplay";
import PageContainer from "components/atoms/PageContainer/PageContainer";
import PollDisplaySection from "components/organisms/PollDisplaySection/PollDisplaySection";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const INITIAL_SEARCH_PARAMS: InitialSearchParams = {
  page: 1,
  limit: 20,
};

const PollsAdminTemplate = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.page
  );

  const { data: languages } = useGetLanguagesQuery({});
  const activeLanguages: Language[] = languages?.data?.filter(
    (l: Language) => l.active
  );

  const { data: userRoles } = useGetUserRolesQuery({
    filterBy: "polls",
  });

  const { data: pollsData } = useGetPollsQuery({
    limit: INITIAL_SEARCH_PARAMS.limit.toString(),
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.limit).toString()
      : "0",
    sortBy: searchParams.get("sortBy"),
    title: searchParams.get("title"),
    verificationType: searchParams.get("verificationType"),
    language: searchParams.get("language"),
    userRole: searchParams.get("userRole"),
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      title: "",
      verificationType: "",
      language: 0,
      userRole: 0,
    },
  });

  const handleFilters = (filterData: FilterData) => {
    searchParams.set("title", filterData.title);
    searchParams.set("verificationType", filterData.verificationType);

    filterData.language > 0
      ? searchParams.set("language", filterData.language.toString())
      : searchParams.set("language", "");

    filterData.userRole > 0
      ? searchParams.set("userRole", filterData.userRole.toString())
      : searchParams.set("userRole", "");

    searchParams.set("page", INITIAL_SEARCH_PARAMS.page.toString());

    setSearchParams(searchParams);
  };

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const labelStyles = "mb-1 fs-14 lh-base";

  return (
    <PageContainer>
      <Container as="section" fluid className="px-4 pt-2 bg-light">
        <PollDisplaySection />
        <div className="d-flex align-items-center">
          <SectionTitle className="me-4">Ankiety</SectionTitle>
          <Button
            className="d-flex justify-content-center align-items-center"
            type="button"
            onClick={() =>
              navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.POLLS}/new`, {
                replace: true,
              })
            }
          >
            <PlusIcon className="me-2 text-white" />
            <span className="text-light lh-base">Dodaj ankietę</span>
          </Button>
        </div>

        <Form
          className="row justify-content-between align-items-end g-3 mb-lg-5"
          onSubmit={handleSubmit(handleFilters)}
        >
          <Col lg={3}>
            <Form.Group>
              <Form.Label className={labelStyles} htmlFor="pollsTitleFilter">
                Tytuł
              </Form.Label>
              <Form.Control
                type="text"
                id="pollsTitleFilter"
                {...register("title")}
              />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label
                className={labelStyles}
                htmlFor="pollVerificationTypeFilter"
              >
                Weryfikacja
              </Form.Label>
              <Form.Select
                id="pollVerificationTypeFilter"
                {...register("verificationType")}
              >
                <option value="">Wszystkie</option>
                {POLL_DATA.VERIFICATION_TYPES.map(
                  (vt: RadioInputWithStringValue) => (
                    <option key={vt.name} value={vt.value}>
                      {vt.label}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label className={labelStyles} htmlFor="pollUserRoleFilter">
                Rola użytkownika
              </Form.Label>
              <Form.Select id="pollUserRoleFilter" {...register("userRole")}>
                <option value={0}>Wszystkie</option>
                {userRoles?.userRoles.map((ur: Role) => (
                  <option key={ur.id} value={ur.id}>
                    {ur.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label className={labelStyles} htmlFor="pollLanguageFilter">
                Język
              </Form.Label>
              <Form.Select id="pollsLanguageFilter" {...register("language")}>
                <option value={0}>Wszystkie</option>
                {activeLanguages?.map((l: Language) => (
                  <option key={l.id} value={l.id}>
                    {l.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} className="d-flex justify-content-end mb-3 mb-lg-0">
            <Button className="btn-outline-blue" type="submit" value="Submit">
              Przefiltruj
            </Button>
          </Col>
        </Form>
      </Container>
      <Container as="section" fluid className="px-4 pt-2 bg-light">
        <PollsTable data={pollsData?.data} />
        <CustomPagination
          totalCount={pollsData?.totalResults || 0}
          pageSize={INITIAL_SEARCH_PARAMS.limit}
          currentPage={currPage}
          onPageChange={handlePageChange}
        />
        <PollAnswersDisplay />
      </Container>
    </PageContainer>
  );
};

export default PollsAdminTemplate;
