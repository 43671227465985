import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum View {
  MAIN = "MAIN",
  CHAT = "CHAT",
  TOPIC = "TOPIC",
}

interface MessagesState {
  showMessages: boolean;
  view: View;
  selectedChatTopic: ChatCase | null;
  chatMessages: TopicMessage[];
  chatData: ChatData | null;
  customMessage: string | null;
  chatUserData: ChatUser | null;
  selectedAdminChatTopic: ChatCase | null;
  adminChatData: ChatData | null;
  unreadMessages: boolean;
  forceSend: boolean;
}

const initialState: MessagesState = {
  showMessages: false,
  view: View.MAIN,
  selectedChatTopic: null,
  chatMessages: [],
  chatData: null,
  customMessage: null,
  chatUserData: null,
  selectedAdminChatTopic: null,
  adminChatData: null,
  unreadMessages: false,
  forceSend: false,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setShowMessages: (state, action: PayloadAction<boolean>) => {
      state.showMessages = action.payload;
    },
    setView: (state, action: PayloadAction<View>) => {
      state.view = action.payload;
    },
    setSelectedChatTopic: (state, action: PayloadAction<ChatCase | null>) => {
      state.selectedChatTopic = action.payload;
    },
    setChatData: (state, action: PayloadAction<ChatData | null>) => {
      state.chatData = action.payload;
    },
    setCustomMessage: (state, action: PayloadAction<string | null>) => {
      state.customMessage = action.payload;
    },
    setChatUserData: (state, action: PayloadAction<ChatUser | null>) => {
      state.chatUserData = action.payload;
    },
    setUnreadMessages: (state, action: PayloadAction<boolean>) => {
      state.unreadMessages = action.payload;
    },
    setSelectedAdminChatTopic: (
      state,
      action: PayloadAction<ChatCase | null>
    ) => {
      state.selectedAdminChatTopic = action.payload;
    },
    setAdminChatData: (state, action: PayloadAction<ChatData | null>) => {
      state.adminChatData = action.payload;
    },
    setForceSend: (state, action: PayloadAction<boolean>) => {
      state.forceSend = action.payload;
    },
  },
});

export const {
  setShowMessages,
  setView,
  setSelectedAdminChatTopic,
  setAdminChatData,
  setSelectedChatTopic,
  setChatData,
  setCustomMessage,
  setChatUserData,
  setUnreadMessages,
  setForceSend,
} = messagesSlice.actions;

export default messagesSlice.reducer;
