import { Modal } from "react-bootstrap";
import styles from "./StatuteModal.module.scss"

const StatuteModal = ({ show, setShow, title, text, isTextFromHTML }: StatuteModalProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 d-flex flex-row justify-content-between px-4 pb-0 pt-4"
        >
          <p className="fw-600 fs-22 text-dark-blue">{title}</p>
        </Modal.Header>
        <Modal.Body className="align-items-center justify-content-center p-4">
          <div
            className={`p-3 text-grey ${styles.statuteContainer}`}
          >
            <h3 className="fs-16 fw-600">{title}</h3>
            {isTextFromHTML ? <div dangerouslySetInnerHTML={{__html: text}} /> : text }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatuteModal;
