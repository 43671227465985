import BreadcrumbLink from "components/atoms/BreadcrumbLink/BreadcrumbLink";
import BreadcrumbButton from "../../atoms/BreadcrumbButton/BreadcrumbButton";
import styles from "./Breadcrumb.module.scss";

interface MyExactData {
  data: {
    name: string;
    path?: string;
    action?: () => void;
  }[];
}

const Breadcrumb = ({ data }: MyExactData) => {
  const defaultAction = () => {};

  return (
    <ul className={styles.breadcrumbList}>
      {data.map((item, idx) => (
        <li key={idx} className={styles.breadcrumbListItem}>
          {item.path ? (
            <BreadcrumbLink to={item.path}>{item.name}</BreadcrumbLink>
          ) : (
            <BreadcrumbButton handler={item.action ?? defaultAction}>
              {item.name}
            </BreadcrumbButton>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
