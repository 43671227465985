import { Button, Container, Row } from "react-bootstrap";
import {
  UseFormReturn,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import PollMetaAnswer from "../PollMetaAnswer/PollMetaAnswer";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/SmallPlus.svg";
import styles from "./PollMetaAnswers.module.scss";
import { v4 as uuidv4 } from "uuid";

interface PollMetaAnswersProps {
  pageIndex: number;
  questionIndex: number;
}

const PollMetaAnswers = ({
  pageIndex,
  questionIndex,
}: PollMetaAnswersProps) => {
  const { control }: UseFormReturn<PollPageFormValues> = useFormContext();

  const {
    fields: metaAnswersFields,
    remove,
    append,
    move,
  } = useFieldArray({
    name: `pages[${pageIndex}].questions[${questionIndex}].meta.answers`,
  });

  const [fieldType, areLabelsNeeded] = useWatch({
    control,
    name: [
      `pages.${pageIndex}.questions.${questionIndex}.fieldType`,
      `pages.${pageIndex}.questions.${questionIndex}.areLabelsNeeded`,
    ],
  });

  const isAgreement = ["agreement"].includes(fieldType);

  const buttonStyles = `d-inline-flex align-items-center justify-content-center lh-base text-light p-1 ${
    metaAnswersFields.length > 0 && "rounded-circle"
  } ${metaAnswersFields.length > 0 && styles.button}`;
  const PLUS_ICON_SIZE = 20;

  return (
    <>
      <Container fluid className="px-0">
        {isAgreement && (
          <Row key={uuidv4()} className="mb-3 align-items-center">
            <PollMetaAnswer
              id={uuidv4()}
              pageIndex={pageIndex}
              questionIndex={questionIndex}
              index={0}
              maxIndex={0}
              valuePlaceholder="Treść zgody / akceptacji"
              deleteVisibility={false}
              areLabelNeeded={false}
              remove={remove}
              move={move}
            />
          </Row>
        )}
        {!isAgreement && metaAnswersFields.length <= 2 && (
          <>
            <Row key={uuidv4()} className="mb-3 align-items-center">
              <PollMetaAnswer
                id={uuidv4()}
                pageIndex={pageIndex}
                questionIndex={questionIndex}
                index={0}
                maxIndex={1}
                deleteVisibility={false}
                areLabelNeeded={areLabelsNeeded}
                remove={remove}
                move={move}
              />
            </Row>

            <Row key={uuidv4()} className="mb-3 align-items-center">
              <PollMetaAnswer
                id={uuidv4()}
                pageIndex={pageIndex}
                questionIndex={questionIndex}
                index={1}
                maxIndex={1}
                deleteVisibility={false}
                areLabelNeeded={areLabelsNeeded}
                remove={remove}
                move={move}
              />
            </Row>
          </>
        )}
        {!isAgreement &&
          metaAnswersFields.length > 2 &&
          metaAnswersFields.map((ma, index) => (
            <Row key={ma.id} className="mb-3 align-items-center">
              <PollMetaAnswer
                id={ma.id}
                pageIndex={pageIndex}
                questionIndex={questionIndex}
                index={index}
                maxIndex={metaAnswersFields.length - 1}
                deleteVisibility={true}
                areLabelNeeded={areLabelsNeeded}
                remove={remove}
                move={move}
              />
            </Row>
          ))}
      </Container>

      {!isAgreement && (
        <Container fluid className="p-0 d-flex justify-content-start">
          <Button className={buttonStyles} onClick={() => append({})}>
            <PlusIcon width={PLUS_ICON_SIZE} height={PLUS_ICON_SIZE} />
            {metaAnswersFields.length === 0 && (
              <span className="ms-2">Dodaj opcję</span>
            )}
          </Button>
        </Container>
      )}
    </>
  );
};

export default PollMetaAnswers;
