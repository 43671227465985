export const convertToString = (value: unknown): string => {
  if (typeof value === "boolean") return value ? "true" : "false";
  if (Array.isArray(value)) return value.join(",");
  return String(value);
};

export const convertAllValuesToString = (
  formData: Record<string, unknown>
): { [key: string]: string } => {
  const result: { [key: string]: string } = {};
  Object.keys(formData).forEach((key) => {
    result[key] = convertToString(formData[key]);
  });
  return result;
};
