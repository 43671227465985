import { Button, Form, Spinner } from "react-bootstrap";
import styles from "./ProfileTemplate.module.scss";
import ProfileHeader from "components/molecules/ProfileHeader/ProfileHeader";
import { AvatarModal } from "components/organisms/AvatarModal/AvatarModal";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { useGetUsersQuery } from "redux/services/usersApi";
import getDaysFromDate from "Helpers/getDaysFromDate";
import ComponentWithDescription from "components/molecules/ComponentWithDescription/ComponentWithDescription";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLazyGetPostsQuery } from "redux/services/feedApi";
import { FeedPost } from "components/organisms/FeedPost/FeedPost";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/index";
import { MeUser, User } from "redux/Slice/user-slice";
import { usePatchMeMutation } from "redux/services/meApi";
import { toast } from "react-toastify";
import PageContainer from "components/atoms/PageContainer/PageContainer";
import {
  useLazyGetFeedCommentStatsQuery,
  useLazyGetFeedStatsQuery,
} from "redux/services/statsApi";
import { useTranslation } from "react-i18next";
import {
  useGetSFContactQuery,
  usePatchSFContactMutation,
} from "redux/services/salesforceApi";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const ProfileTemplate = () => {
  const linkParams = useParams();
  const { authUser } = useSelector((state: RootState) => state.user);
  const [userData, setUserData] = useState<User>();
  const [error, setError] = useState("");
  const { data: sfData } = useGetSFContactQuery({});

  const isCurrentUserProfile = !linkParams?.authID;

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<MeUser>({
    defaultValues: {
      ...authUser,
    },
  });

  const [getLastPost, { data: feedData }] = useLazyGetPostsQuery();
  const { data: fetchedUserData } = useGetUsersQuery({
    authId: linkParams.authID,
    limit: "1",
  });
  const [getFeedStats, { data: feedStats }] = useLazyGetFeedStatsQuery();
  const [getFeedCommentStats, { data: feedCommentStats }] =
    useLazyGetFeedCommentStatsQuery();
  const [patchMe] = usePatchMeMutation();
  const [patchSf] = usePatchSFContactMutation();

  const [show, setShow] = useState(false);
  const [showEditBiogram, setShowEditBiogram] = useState(false);

  const { t } = useTranslation();

  const getPath = async (id: string) => {
    await patchMe({
      data: { ...authUser, avatarId: id },
    });
  };

  const submitHandler: SubmitHandler<MeUser> = async (data) => {
    const biogramUpdated = await patchSf({
      data: { ...data?.settings },
    });
    await patchMe({
      data: {
        ...data,
        localSettings: {
          ...authUser?.localSettings,
          description: data?.settings?.Description ?? undefined,
        },
      },
    });

    if ("data" in biogramUpdated) {
      toast.success(t("toast_profileTemplate_success"));
    } else if ("error" in biogramUpdated) {
      const error = biogramUpdated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_profileTemplate_error_500"));

      if (error.data.statusCode === 400) toast.error(error.data.message);
      toast.error(t("toast_profileTemplate_error"));
    }
    setShowEditBiogram(false);
  };

  useEffect(() => {
    if (authUser && isCurrentUserProfile) {
      getFeedStats({ userId: authUser.id.toString() });
      getFeedCommentStats({ userId: authUser.id.toString() });
      getLastPost({
        authId: authUser.authId,
        limit: 1,
        sortBy: "created_at,DESC",
      });
      setUserData(authUser as User);
    }
    if (fetchedUserData && !isCurrentUserProfile) {
      if (
        fetchedUserData.data.length !== 0 &&
        fetchedUserData.data[0].deleted !== 1
      ) {
        getFeedStats({ userId: fetchedUserData.data[0].id });
        getFeedCommentStats({ userId: fetchedUserData.data[0].id });
        getLastPost({
          authId: linkParams.authID,
          limit: 1,
          sortBy: "created_at,DESC",
        });
        const newUSerData: User = {
          ...fetchedUserData.data[0],
          settings: JSON.parse(fetchedUserData.data[0].settings),
        };
        setUserData(newUSerData);
      } else {
        setError(
          "Nie istnieje użytkownik o podanym ID bądź użytkownik usunięty.",
        );
      }
    }
    // eslint-disable-next-line
  }, [authUser, fetchedUserData, linkParams.authID]);

  const accountStatus = (() => {
    switch (true) {
      case authUser?.status === 0:
        return "blocked";
      case authUser?.status === 100 || sfData?.data?.Dead__c:
        return "dead";
      case authUser?.status === 90 || sfData?.data?.Do_usuni_cia__c:
        return "deleteProccess";
      case authUser?.status === 200:
        return "anonymization";
      default:
        return "active";
    }
  })();

  if (error && error !== "") {
    return (
      <div className="d-flex p-5">
        <h1 className="mt-5">{error}</h1>
      </div>
    );
  }

  if (userData && feedStats) {
    return (
      <>
        <PageContainer>
          <div className="px-lg-5 px-1">
            <ProfileHeader
              isAuthenticated={isCurrentUserProfile}
              isFirstVisit={false}
              type="account"
              userData={userData}
              accountStatus={accountStatus}
              handleChangeAvatar={() => setShow(true)}
            />
            <div
              className={`mt-5 containerWithShadow p-2 col-12 d-lg-flex d-block flex-row`}
            >
              <div className="col-lg-8 col-12 p-lg-4 p-2">
                <div className="justify-content-between d-flex flex-row align-items-center mb-lg-5 mb-4">
                  <p
                    className={`fw-600 fs-22 text-dark-blue m-0 ${styles.title}`}
                  >
                    O mnie
                  </p>
                  {isCurrentUserProfile && authUser?.status === 1 && (
                    <Button
                      onClick={() => setShowEditBiogram(!showEditBiogram)}
                      className={!showEditBiogram ? "text-dark-blue" : ""}
                      variant={showEditBiogram ? "dark" : "link"}
                    >
                      <Pencil />
                    </Button>
                  )}
                </div>
                {(getValues("settings.Description") === "" ||
                  getValues("settings.Description") === null) &&
                !showEditBiogram &&
                isCurrentUserProfile ? (
                  <div className="d-flex flex-column align-items-center justify-content-center p-5 border">
                    <p className="text-grey fs-19 fw-600 mb-4 mt-5 text-center">
                      Przedstaw się innym użytkownikom. Napisz kilka słów o
                      sobie i daj się poznać! Nawiązuj nowe znajomości i
                      przekonaj się, jak wiele wsparcia możesz tu otrzymać. Ty
                      także możesz pomagać innym i dzielić się swoimi
                      doświadczeniami w walce z chorobą.
                    </p>
                    <Button
                      variant="primary"
                      className="mb-5"
                      onClick={() => setShowEditBiogram(true)}
                    >
                      NAPISZ COŚ O SOBIE
                    </Button>
                  </div>
                ) : (
                  <>
                    {getValues("settings.Description") &&
                      !showEditBiogram &&
                      isCurrentUserProfile && (
                        <p className="text-dark-blue fs-16 fw-400">
                          {getValues("settings.Description")}
                        </p>
                      )}
                    {!isCurrentUserProfile && (
                      <p className="text-dark-blue fs-16 fw-400">
                        {userData?.settings?.Description
                          ? userData?.settings?.Description
                          : "Użytkownik nie dodał jeszcze opisu."}
                      </p>
                    )}
                    {showEditBiogram && (
                      <Form className={"d-flex flex-column w-100"}>
                        <p className="text-grey-2 fs-12 fw-600">
                          Dodawanie biogramu
                        </p>
                        <ComponentWithDescription
                          className="my-2 w-100"
                          desc="Max 1500 znaków"
                          descFs={`fs-12`}
                          label={"Kilka zdań o Tobie*"}
                          labelFs={`fs-16 mb-1 ${
                            errors.settings?.Description
                              ? "text-danger"
                              : "text-dark-blue"
                          }`}
                        >
                          <Form.Control
                            as="textarea"
                            rows={6}
                            {...register("settings.Description", {
                              required: {
                                value: true,
                                message: "Pole wymagane",
                              },
                              maxLength: {
                                value: 1500,
                                message: "Maksymalnie 1500 znaków",
                              },
                            })}
                            isInvalid={Boolean(errors.settings?.Description)}
                          />
                          {errors.settings?.Description && (
                            <Form.Control.Feedback type={"invalid"}>
                              {errors.settings.Description.message}
                            </Form.Control.Feedback>
                          )}
                        </ComponentWithDescription>
                        <div className="d-flex gap-2 mt-4 justify-content-end">
                          <Button
                            className="btn-outline-dark-blue"
                            onClick={() => {
                              setShowEditBiogram(false);
                            }}
                          >
                            ANULUJ
                          </Button>
                          <Button
                            type="submit"
                            value="Submit"
                            onClick={handleSubmit(submitHandler)}
                          >
                            ZAPISZ
                          </Button>
                        </div>
                      </Form>
                    )}
                  </>
                )}
              </div>
              <div
                className={`col-lg-4 col-12 mt-2 mt-lg-0 ${styles.accountStats}`}
              >
                <div className="px-lg-4 px-2 py-lg-4 pt-3 pb-4">
                  <p
                    className={`fw-600 fs-22 text-dark-blue mb-lg-5 ${styles.title}`}
                  >
                    Konto w liczbach
                  </p>
                  <div
                    className="pb-3 pb-lg-4"
                    style={{ borderBottom: "1px solid #E9ECEF" }}
                  >
                    <p className="text-grey-2 fw-600 fs-12 mb-3 pt-3 pt-0">
                      Liczba Twoich postów
                    </p>
                    <h2
                      className={`fw-600 ${
                        feedStats > 0 ? "text-dark-blue" : "text-grey-2"
                      } ${styles.statElem}`}
                    >
                      {feedStats && feedStats?.data ? feedStats?.data : "00"}
                    </h2>
                  </div>
                  <div
                    className="py-lg-4 py-3"
                    style={{ borderBottom: "1px solid #E9ECEF" }}
                  >
                    <p className="text-grey-2 fw-600 fs-12 mb-3">
                      Liczba Twoich komentarzy
                    </p>
                    <h2
                      className={`fw-600 ${
                        feedCommentStats > 0 ? "text-dark-blue" : "text-grey-2"
                      } ${styles.statElem}`}
                    >
                      {feedStats && feedCommentStats?.data
                        ? feedCommentStats?.data
                        : "00"}
                    </h2>
                  </div>
                  <div className="pt-lg-4 pt-3">
                    <p className="text-grey-2 fw-600 fs-12 mb-3">
                      Jesteś już z nami
                    </p>
                    <h1
                      className={`fw-600 ${
                        authUser?.createdAt || userData?.createdAt
                          ? "text-dark-blue"
                          : "text-grey-2"
                      } ${styles.statElem}`}
                    >
                      {(authUser?.createdAt && isCurrentUserProfile) ||
                      (userData?.createdAt && !isCurrentUserProfile)
                        ? getDaysFromDate(
                            isCurrentUserProfile
                              ? authUser?.createdAt
                              : userData?.createdAt,
                          ).toString()
                        : "0"}{" "}
                      dni
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            {authUser?.status === 1 && (
              <div
                className={`mt-5 containerWithShadow d-flex flex-row jusfity-content-start col-sm-8 col-12`}
              >
                <div className="p-4 w-100">
                  <p
                    className={`fw-600 fs-22 text-dark-blue mb-lg-5 pb-3 pb-lg-0 mb-4 ${styles.title}`}
                  >
                    Ostatni post
                  </p>

                  {!feedData || feedData?.data.length === 0 ? (
                    <div className="d-flex flex-column align-items-center justify-content-center mt-lg-0 p-lg-5 py-5 px-4 border">
                      <p
                        className={`text-center text-grey fs-19 fw-600 ${styles.paragraph}`}
                      >
                        Opublikuj swój pierwszy post
                      </p>
                      <Button variant="primary text-wrap">
                        <Link to={`/${NAV_PATH.FEED}`} className="text-white">
                          ZOBACZ SPOŁECZNOŚĆ
                        </Link>
                      </Button>
                    </div>
                  ) : (
                    <FeedPost {...feedData.data[0]} isPostClickable />
                  )}
                </div>
              </div>
            )}
          </div>
        </PageContainer>
        <AvatarModal
          show={show}
          types="user-avatar"
          handleClose={() => setShow(false)}
          headerTitle="Zmień zdjęcie"
          handlePatch={getPath}
        />
      </>
    );
  } else {
    return (
      <PageContainer
        className={
          "d-flex justify-content-center h-100 w-100 align-items-center p-5"
        }
      >
        <Spinner animation="border" variant="primary" className="mt-5" />
      </PageContainer>
    );
  }
};

export default ProfileTemplate;
