import { Suspense, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Navigation from "../Navigation/Navigation";
import styles from "./MenuLayouts.module.scss";

const MenuLayout = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <NavBar setShow={setShow} />
      <div className="d-flex">
        <Offcanvas show={show} onHide={handleClose} responsive="sm">
          <Offcanvas.Body>
            <Navigation setShow={setShow} />
          </Offcanvas.Body>
        </Offcanvas>

        <Suspense>
          <div className={`${styles.container}`}>
            <Outlet />
          </div>
        </Suspense>
      </div>
    </>
  );
};

export default MenuLayout;
