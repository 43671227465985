import React from "react";
import styles from "./AgreementTemplate.module.scss";
import { Container, Stack } from "react-bootstrap";
import { AccordionCard } from "../../molecules/AccordionCard/AccordionCard";
import { AgreementLoyaltyProgram } from "../../organisms/AgreementLoyaltyProgram/AgreementLoyaltyProgram";

export const AgreementTemplate = () => {
  return (
    <Container fluid={true} as="section" className={styles.section}>
      <Stack gap={5}>
        <AccordionCard
          title={"Regulaminy Benefitów"}
          classAccordion={"w-100"}
          classAccordionItem={"p-3"}
          child={<AgreementLoyaltyProgram />}
          isActiveStars={true}
        />
      </Stack>
    </Container>
  );
};
