import { Row, Form, Button, Image } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import styles from "./CompanyBasicInformationForm.module.scss";
import { useState } from "react";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import BanerImg from "../../../assets/images/companyNormal.png";
import { ReactComponent as Pencil } from "assets/Icons/Pencil.svg";
import { AvatarModal } from "../../organisms/AvatarModal/AvatarModal";
import { Company } from "../LoyaltyProgramServiceArticle/types";
import { PatchCompanyProps } from "redux/services/companyApi";
import { usePatchCompanyMutation } from "redux/services/companyApi";
import { toast } from "react-toastify";

interface Props {
  data: Company | null;
  disabled: boolean;
  handleClose?: () => void;
}

interface FormType {
  name: string;
  fullName: string;
  imageId: string;
  nipProvider: string;
}

const CompanyBasicInformationForm = ({
  data,
  disabled,
  handleClose,
}: Props) => {
  const [showAvatar, setShowAvatar] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      name: data?.name,
      fullName: data?.fullName,
      nipProvider: data?.nipProvider,
    },
  });

  const [patchCompany, { isSuccess }] = usePatchCompanyMutation();

  const { t } = useTranslation();

  const handlePatch = async (id: string) => {
    setValue("imageId", id);
  };

  const submitHandler: SubmitHandler<FormType> = async (formData) => {
    if (data) {
      const patchData: PatchCompanyProps = {
        id: data?.id,
        data: formData,
      };

      const hasBeenUpdate = await patchCompany(patchData);
      if ("data" in hasBeenUpdate) {
        toast.success(t("toast_companyBasicInformationForm_update_success"));
        if (handleClose && isSuccess) {
          handleClose();
        }
      } else if ("error" in hasBeenUpdate) {
        toast.error(t("toast_companyBasicInformationForm_error"));
      }
    }
  };

  return (
    <Form
      className={"d-flex flex-column w-100"}
      onSubmit={handleSubmit(submitHandler)}
    >
      <p className={"text-grey-2 fs-12 fw-semibold ls-6"}>Podstawowe dane</p>
      <div className="d-flex justify-content-around">
        <div
          className={
            "d-flex justify-content-center justify-content-lg-start align-items-center"
          }
        >
          <Row className={"m-0 p-0 position-relative"} style={{ width: 160 }}>
            <Image
              className={`p-0 ${styles.image}`}
              src={
                convertImageIdToUrl(data?.imageIds, false, "normal.jpeg") ||
                BanerImg
              }
            />
            {!disabled && (
              <div className={"bg-white"}>
                <Button
                  className={`d-flex justify-content-center align-items-center position-absolute bottom-0 end-0 p-0 ${styles.buttonEditAvatar}`}
                  variant={"outline-primary"}
                  onClick={() => setShowAvatar((prev) => !prev)}
                >
                  <Pencil />
                </Button>
              </div>
            )}
            <AvatarModal
              headerTitle={"Avatar firmy"}
              types={"company-logo"}
              show={showAvatar}
              handleClose={() => setShowAvatar(false)}
              handlePatch={handlePatch}
              isActiveToast={false}
            />
          </Row>
        </div>

        <Row className={"text-dark-blue"}>
          <ComponentWithDescription
            label={"Nazwa firmy*"}
            labelFs={`fs-16 ${errors?.name ? "text-danger" : "text-dark-blue"}`}
            className="my-2 pb-3"
          >
            <Form.Control
              type="text"
              id="name"
              maxLength={100}
              aria-describedby="name"
              disabled={disabled}
              className={`w-100 text-dark-blue ${styles.input} `}
              {...register("name", {
                required: { value: true, message: "Pole wymagane" },
                maxLength: { value: 100, message: "Max. 100 znaków" },
              })}
              isInvalid={!!errors?.name}
            />
            {errors?.name && (
              <Form.Control.Feedback type={"invalid"}>
                {errors?.name?.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
          <ComponentWithDescription
            label={"Pełna nazwa firmy*"}
            labelFs={`fs-16 ${
              errors?.fullName ? "text-danger" : "text-dark-blue"
            }`}
            className="my-2 pb-3"
          >
            <Form.Control
              type="text"
              id="fullName"
              maxLength={200}
              aria-describedby="fullName"
              disabled={disabled}
              className={`w-100 text-dark-blue ${styles.input} `}
              {...register("fullName", {
                required: { value: true, message: "Pole wymagane" },
                maxLength: { value: 200, message: "Max. 200 znaków" },
              })}
              isInvalid={!!errors?.fullName}
            />
            {errors?.fullName && (
              <Form.Control.Feedback type={"invalid"}>
                {errors?.fullName?.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
          <ComponentWithDescription
            label={"NIP*"}
            labelFs={`fs-16 ${
              errors?.nipProvider ? "text-danger" : "text-dark-blue"
            }`}
            className="my-2 pb-3"
          >
            <Form.Control
              type="text"
              id="nipProvider"
              aria-describedby="nipProvider"
              disabled={disabled}
              className={`w-100 text-dark-blue ${styles.input} `}
              maxLength={10}
              {...register("nipProvider", {
                required: { value: true, message: "Pole wymagane" },
                minLength: { value: 10, message: "Minimum 10 znaków" },
                maxLength: { value: 10, message: "Max. 10 znaków" },
                pattern: { value: /^\d{10}$/, message: " Tylko liczby" },
              })}
              isInvalid={!!errors?.nipProvider}
            />
            {errors?.nipProvider && (
              <Form.Control.Feedback type={"invalid"}>
                {errors?.nipProvider?.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
        </Row>
      </div>
      {!disabled && (
        <div className="d-flex gap-2 align-self-end">
          <Button type="submit" value="Submit">
            AKTUALIZUJ
          </Button>
        </div>
      )}
    </Form>
  );
};

export default CompanyBasicInformationForm;
