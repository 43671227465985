export function addObjectsBetweenKey(
  array: any[],
  nameKey: string,
  newObject: any,
) {
  if (typeof array === "undefined") {
    return [];
  }
  const newArray = [];

  for (let i = 0; i < array.length - 1; i++) {
    newArray.push(array[i]);

    if (array[i][nameKey] !== array[i + 1][nameKey]) {
      newArray.push(newObject);
    }
  }
  const lastArrayIndex = array.length - 1;
  newArray.push(array[lastArrayIndex]);

  return newArray;
}
