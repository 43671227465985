// webSocketSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendMessage } from "Helpers/webSocketMiddleware";

export interface WebSocketState {
  onMessage: string | null;
  onNotification: {
    data: { eventType: "UNREAD_NOTIFICATIONS"; payload: any };
  } | null;
}

const initialState: WebSocketState = {
  onMessage: null,
  onNotification: null,
};

export const sendMessageAsync = createAsyncThunk(
  "webSocket/sendMessage",
  async (messageObject: any, { rejectWithValue }) => {
    try {
      sendMessage(messageObject);
      return messageObject;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setOnMessage: (state, action) => {
      state.onMessage = action.payload;
    },
    setOnNotification: (state, action) => {
      state.onNotification = action.payload;
    },
  },
});

export const { setOnMessage, setOnNotification } = webSocketSlice.actions;
export default webSocketSlice.reducer;
