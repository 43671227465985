import { capitalizeString } from "Helpers/capitalizeString";
import { POLL_DATA } from "Helpers/pollsData";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useFormContext, UseFormReturn, useWatch } from "react-hook-form";
import { useGetLanguagesQuery } from "redux/services/languagesApi";
import { useGetUserRolesQuery } from "redux/services/userRolesApi";

const PollForm = () => {
  const { data: languages } = useGetLanguagesQuery({});
  const activeLanguages = languages?.data?.filter((l: Language) => l.active);

  const { data: userRoles } = useGetUserRolesQuery({
    filterBy: "polls",
  });

  const {
    control,
    register,
    formState: { errors },
  }: UseFormReturn<PollSendingData> = useFormContext();

  const [
    verificationTypeValue,
    isReusableValue,
    isActiveValue,
    sfConnectionValue,
  ] = useWatch({
    control,
    name: ["verificationType", "isReusable", "isActive", "sfConnection"],
  });

  const formGroupStyles = "mb-3";
  const labelStyles = "mb-1 fs-14 lh-base";
  const formCheckStyles = "mb-0";

  return (
    <Container>
      <Card>
        <Card.Header className="m-0 fs-4 fw-semibold lh-130 ls-3">
          Tworzenie ankiety
        </Card.Header>
        <Card.Body>
          <Form.Group className={formGroupStyles}>
            <Form.Label className={labelStyles} htmlFor="pollTitle">
              Tytuł ankiety
            </Form.Label>
            <Form.Control
              type="text"
              id="pollTitle"
              {...register("title", {
                required: "Pole nie może być puste.",
                pattern: {
                  value:
                    // eslint-disable-next-line
                    /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻáéíóúüñÁÉÍÓÚÜÑßÄäÖöÜüß\s\-]+$/,
                  message:
                    "Nazwa nie może zawierać innych znaków niż małe i duże litery, cyfry, spacje oraz myślniki.",
                },
              })}
              isInvalid={Boolean(errors.title)}
            />
            {errors.title && (
              <Form.Control.Feedback type="invalid">
                {errors.title.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <Form.Label className={labelStyles} htmlFor="pollDescription">
              Opis (opcjonalne)
            </Form.Label>
            <Form.Control
              className="lh-base ta-resize-none"
              as="textarea"
              rows={3}
              type="text"
              id="pollDescription"
              {...register("description")}
            />
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <Form.Label className={labelStyles} htmlFor="pollLanguage">
              Język
            </Form.Label>
            <Form.Select
              id="pollLanguage"
              {...register("languageId", {
                required: "Wybierz język ankiety.",
                min: {
                  value: 1,
                  message: "Wybierz język ankiety.",
                },
              })}
              isInvalid={Boolean(errors.languageId)}
            >
              <option value={0} disabled>
                Wybierz
              </option>
              {activeLanguages?.map((l: Language) => (
                <option key={l.id} value={l.id}>
                  {l.name}
                </option>
              ))}
            </Form.Select>
            {errors.languageId && (
              <Form.Control.Feedback type="invalid">
                {errors.languageId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <Form.Label className={labelStyles} htmlFor="pollUserRole">
              Rola użytkownika
            </Form.Label>
            <Form.Select
              id="pollUserRole"
              {...register("userRoleId", {
                required:
                  "Wybierz rolę użytkownika, dla której przeznaczona jest ankieta.",
                min: {
                  value: 1,
                  message:
                    "Wybierz rolę użytkownika, dla której przeznaczona jest ankieta.",
                },
              })}
              isInvalid={Boolean(errors.userRoleId)}
            >
              <option value={0} disabled>
                Wybierz
              </option>
              {userRoles?.userRoles?.map((ur: Role) => (
                <option key={ur.id} value={ur.id}>
                  {ur.name}
                </option>
              ))}
            </Form.Select>
            {errors.userRoleId && (
              <Form.Control.Feedback type="invalid">
                {errors.userRoleId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <div className={labelStyles}>Typ weryfikacji</div>
            {POLL_DATA.VERIFICATION_TYPES.map((vt, idx) => (
              <Form.Check
                key={idx}
                className={formCheckStyles}
                inline
                id={`pollVerification-${vt.name}`}
                {...register("verificationType", {
                  required: true,
                })}
                type="radio"
                label={vt.label}
                value={vt.value}
                defaultChecked={vt.value === verificationTypeValue ?? false}
                isInvalid={Boolean(errors.verificationType)}
              />
            ))}
          </Form.Group>

          {/* <Form.Group className={formGroupStyles}>
              <div className={labelStyles}>Weryfikacja administratora</div>
              {POLL_DATA.ADMIN_VERIFICATION.map((av, idx) => (
                <Form.Check
                  key={idx}
                  className={formCheckStyles}
                  inline
                  id={`poll${capitalizeString(av.name)}`}
                  {...register("pollAdminVerification", {
                    required: true,
                  })}
                  type="radio"
                  label={av.label}
                  value={av.value}
                  defaultChecked={vt.checked}
                  isInvalid={Boolean(errors.pollAdminVerification)}
                />
              ))}
            </Form.Group> */}

          <Form.Group className={formGroupStyles}>
            <div className={labelStyles}>Reużywalość ankiety</div>
            {POLL_DATA.REUSABILITY.map((r, idx) => (
              <Form.Check
                key={idx}
                className={formCheckStyles}
                inline
                id={`poll${capitalizeString(r.name)}`}
                {...register("isReusable", {
                  required: true,
                })}
                type="radio"
                label={r.label}
                value={r.value}
                defaultChecked={r.value === isReusableValue ?? false}
                isInvalid={Boolean(errors.isReusable)}
              />
            ))}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <div className={labelStyles}>Status</div>
            {POLL_DATA.STATUSES.map((s, idx) => (
              <Form.Check
                key={idx}
                className={formCheckStyles}
                inline
                id={`poll${capitalizeString(s.name)}Status`}
                {...register("isActive", {
                  required: true,
                })}
                type="radio"
                label={s.label}
                value={s.value}
                defaultChecked={s.value === isActiveValue ?? false}
                isInvalid={Boolean(errors.isActive)}
              />
            ))}
          </Form.Group>

          <Form.Group className={formGroupStyles}>
            <div className={labelStyles}>
              Nadpisywanie identyfikatora ankiety w salesforce
            </div>
            {POLL_DATA.SF_CONNECTION.map((sfc, idx) => (
              <Form.Check
                key={idx}
                className={formCheckStyles}
                inline
                id={`poll${capitalizeString(sfc.name)}SfConnection`}
                {...register("sfConnection", {
                  required: true,
                })}
                type="radio"
                label={sfc.label}
                value={sfc.value}
                defaultChecked={sfc.value === sfConnectionValue ?? false}
                isInvalid={Boolean(errors.sfConnection)}
              />
            ))}
          </Form.Group>

          <div className="container-fluid d-flex justify-content-end px-0">
            <Button className="lh-base text-light" type="submit">
              Dalej
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PollForm;
