import { Button, Container, Dropdown } from "react-bootstrap";
import React, { useState } from "react";
import styles from "./CompanySocialMediaEntity.module.scss";
import { ReactComponent as TripleDot } from "../../../assets/Icons/TripleDot.svg";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import { SocialSelect } from "../../organisms/CompanySocialMedia/CompanySocialMedia";
import PopupModal from "../PopupModal/PopupModal";

interface Props {
  data: SocialSelect;
  handleChange: (data: SocialSelect) => void;
  handleDelete: (data: SocialSelect) => void;
}

export const CompanySocialMediaEntity = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const hideModalHandler = () => setShowModal(false);

  const modalAcceptanceHandler = () => {
    hideModalHandler();
    props.handleDelete(props.data);
  };

  const handlerEdit = () => {
    props.handleChange(props.data);
  };

  return (
    <Container
      fluid={true}
      className={"d-flex flex-row justify-content-between py-1"}
    >
      <div
        className={`d-flex justify-content-start align-items-center ${styles.nameLabel}`}
      >
        <div className={styles.icon}>{props.data.icon}</div>
        <span className={"fs-19 ms-1"}>{props.data.label}</span>
      </div>
      <Dropdown className={"d-flex d-lg-none"}>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-basic"
          className={styles.dropdownToggle}
        >
          <TripleDot />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href={props.data.data || ""} target="_blank">
            Otwórz stronę
          </Dropdown.Item>
          <Dropdown.Item onClick={handlerEdit}>Edytuj adres</Dropdown.Item>
          <Dropdown.Item onClick={() => setShowModal((prev: boolean) => !prev)}>
            Usuń serwis
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className={`d-none d-lg-flex justify-content-start align-items-center text-grey-2 w px-2 py-3 py-lg-0 ${styles.inputDiv}`}
      >
        <div className={"fs-19 text-break"}>
          {props.data.data ?? "wprowadź nowy adres"}
        </div>
      </div>
      <div
        className={`d-none d-lg-flex flex-row justify-content-start align-items-center gap-2 ${styles.button}`}
      >
        <Button
          className="d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue"
          variant={"transparent"}
          onClick={() => {
            window.open(props.data.data || "", "_blank");
          }}
        >
          <ArrowUpRightFromSquare />
        </Button>
        <Button
          className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
          variant={"transparent"}
          onClick={handlerEdit}
        >
          <Pencil />
        </Button>
        <Button
          className="d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue"
          variant={"transparent"}
          onClick={() => setShowModal((prev: boolean) => !prev)}
        >
          <TrashBlank />
        </Button>
        <PopupModal
          titleClasses="fw-600 text-center"
          text="Czy na pewno chcesz usunąć adres portalu?"
          type="confirm"
          show={showModal}
          setShow={hideModalHandler}
          confirmAction={() => modalAcceptanceHandler()}
        />
      </div>
    </Container>
  );
};
