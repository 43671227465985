import { stats } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Stats"],
});

interface GetFeedStatsSearchParams {
  userId: string;
}

interface GetFeedCommentStatsSearchParams {
  userId?: string;
}

interface GetDoctorReviewStatsSearchParams {
  doctorId: string;
  days?: string;
  dateTo?: string;
}

export const statsApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getFeedStats: builder.query({
      query: ({ userId }: GetFeedStatsSearchParams) => {
        const params = new URLSearchParams({
          ...(userId && { userId }),
        });

        return {
          url: `${stats}/feed-stats?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    getFeedCommentStats: builder.query({
      query: ({ userId }: GetFeedCommentStatsSearchParams) => {
        const params = new URLSearchParams({
          ...(userId && { userId }),
        });

        return {
          url: `${stats}/feed-comment-stats?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    getUserStats: builder.query({
      query: () => {
        return {
          url: `${stats}/user-stats`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    getDoctorReviewStats: builder.query({
      query: ({ doctorId, days, dateTo }: GetDoctorReviewStatsSearchParams) => {
        const params = new URLSearchParams({
          ...(doctorId && { doctorId }),
          ...(days && { days }),
          ...(dateTo && { dateTo }),
        });

        return {
          url: `${stats}/doctor-review?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useGetFeedStatsQuery,
  useLazyGetFeedStatsQuery,
  useGetFeedCommentStatsQuery,
  useLazyGetFeedCommentStatsQuery,
  useGetUserStatsQuery,
  useGetDoctorReviewStatsQuery,
} = statsApi;
