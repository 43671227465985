import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import {
  useCreatePollUserMutation,
  useSendVerificationEmailMutation,
  useSendVerificationSmsMutation,
} from "redux/services/pollUsersApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { RootState } from "redux/store/index";

interface PollVerificationInputProps {
  type: "email" | "sms";
  setVerifiedPollUserId: Dispatch<SetStateAction<number | undefined>>;
  data: PatientPollData;
  setVerificationCode: (code: string) => void;
}

export default function PollVerificationInput({
  type,
  setVerifiedPollUserId,
  data,
  setVerificationCode,
}: PollVerificationInputProps) {
  const [createPollUser] = useCreatePollUserMutation();
  const [sendVerificationEmail] = useSendVerificationEmailMutation();
  const [sendVerificationSms] = useSendVerificationSmsMutation();

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const { authUser } = useSelector((state: RootState) => state.user);
  const [pollUserId, setPollUserId] = useState<number | null>(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(60);

  const pollId = data.id;

  const getIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      toast.error(`${t("toast_dynamicPatientPoll_ip_error")} ${error}`);
      return null;
    }
  };

  const createPollUserRequest = async (
    pollUser: PollUserRequest
  ): Promise<number | void> => {
    const resultPollUser = await createPollUser(pollUser);

    if ("error" in resultPollUser) {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_error")} ${
          resultPollUser.error
        }`
      );
      return;
    }

    if (resultPollUser?.data?.statusCode === 400) {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_error_400")} ${
          resultPollUser.data.message
        }`
      );
      return;
    }

    if (resultPollUser?.data?.data?.statusCode === 404) {
      toast.error(t("toast_dynamicPatientPoll_create_poll_user_error_404"));
      return;
    }

    if (resultPollUser?.data?.data?.statusCode === 500) {
      toast.error(t("toast_dynamicPatientPoll_create_poll_user_error_500"));
      return;
    }

    if (resultPollUser?.data?.data) {
      return resultPollUser.data.data.id;
    } else {
      toast.error(
        `${t("toast_dynamicPatientPoll_create_poll_user_unexpected_error")} ${
          resultPollUser?.data?.data?.message
        }`
      );
    }
  };

  const handleSendVerificationCode = async () => {
    const ipAddress = await getIpAddress();

    if (ipAddress) {
      const pollUser: PollUserRequest = {
        pollId: pollId || null,
        userId: authUser?.id || null,
        ip: ipAddress,
      };
      const id = await createPollUserRequest(pollUser);
      if (typeof id === "number") {
        setPollUserId(id);
        if (type === "email") {
          sendVerificationEmail({
            id,
            data: { email: inputValue },
          });
        } else if (type === "sms") {
          sendVerificationSms({
            id,
            data: { phone: inputValue },
          });
        }
      }
    }

    setIsButtonDisabled(true);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          setIsButtonDisabled(false);
          return 60;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = e.target.value;
    setVerificationCode(enteredCode);

    if (setVerifiedPollUserId) {
      setVerifiedPollUserId(pollUserId !== null ? pollUserId : undefined);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isButtonDisabled) {
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 60;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isButtonDisabled]);

  const placeholderText =
    type === "email" ? "Wpisz swój email*" : "Wpisz nr telefonu*";
  const typeAttribute = type === "email" ? "email" : "tel";

  return (
    <Container className="my-4">
      <h6 className={`mt-12 mb-12 fw-600 fs-12 text-grey-2`}>Weryfikacja</h6>
      <p className="mt-12 mb-12 fs-12">
        Aby opinie i dyskusje na naszym portalu były maksymalnie pomocne dla
        reszty użytkowników, niezbędna jest weryfikacja kodem{" "}
        {type === "email" ? "email. Twój email" : "sms. Twój numer"} nie
        zostanie udostępniony, ani nie będzie wykorzystany w celach
        marketingowych.
      </p>
      <Row className="mb-3">
        <Col xs={12} md={4}>
          <Form.Control
            type={typeAttribute}
            placeholder={placeholderText}
            value={inputValue}
            onChange={handleInputChange}
          />
        </Col>
        <Col xs={12} md={4}>
          <Button
            variant="primary"
            type="button"
            onClick={handleSendVerificationCode}
            className="w-100"
            disabled={isButtonDisabled}
          >
            {isButtonDisabled
              ? `Wyślij kod ponownie za ${countdown} sekund`
              : "Wyślij kod"}
          </Button>
        </Col>
        <Col xs={12} md={4}>
          <Form.Control
            type="text"
            placeholder="Wprowadź otrzymany kod*"
            onChange={handleCodeChange}
          />
        </Col>
      </Row>
    </Container>
  );
}
