import { Button, Form } from "react-bootstrap";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { usePostTranslationMutation } from "redux/services/translationsApi";
import { useTranslation } from "react-i18next";

const TranslationSubmissionForm = ({
  languageOptions,
  systemOptions,
}: TranslationSubmissionFormProps) => {
  const { control, register, handleSubmit, reset } =
    useForm<TranslationFormData>({
      defaultValues: {
        term: "",
        type: "",
        value: "",
        language: 0,
      },
    });
  const [sendTranslationData] = usePostTranslationMutation();

  const languageValue = useWatch({
    control,
    name: "language",
  });

  const { t } = useTranslation();

  const submitHandler: SubmitHandler<{
    term: string;
    type: string;
    value: string;
    language: number;
  }> = async (data) => {
    const payload = {
      data: {
        term: data.term,
        type: data.type,
        value: data.value,
        language: +data.language,
      },
    };

    const hasBeenCreated = await sendTranslationData(payload);

    if ("data" in hasBeenCreated) {
      toast.success(t("toast_translationSubmissionForm_success"));
      reset();
    } else if ("error" in hasBeenCreated) {
      const error = hasBeenCreated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_translationSubmissionForm_error_500"));

      if (error.data.statusCode === 409)
        toast.error(t("toast_translationSubmissionForm_error_409"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_translationSubmissionForm_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <Form.Group className="container-fluid mb-3">
        <Form.Label htmlFor="translationKey">Klucz</Form.Label>
        <Form.Control type="text" id="translationKey" {...register("term")} />
      </Form.Group>

      <Form.Group className="container-fluid mb-3">
        <Form.Label htmlFor="translationType">Typ</Form.Label>
        <Form.Select id="translationType" {...register("type")}>
          <option value="">Wybierz</option>
          {systemOptions?.map((system: System) => (
            <option key={system.id} value={system.slug}>
              {system.slug}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="container-fluid mb-3">
        <Form.Label htmlFor="translationValue">
          Wartość {languageValue === 2 && "(opcjonalne)"}
        </Form.Label>
        <Form.Control
          as="textarea"
          className="lh-base ta-resize-none"
          id="translationValue"
          rows={4}
          {...register("value")}
        />
      </Form.Group>

      <Form.Group className="container-fluid mb-3">
        <Form.Label htmlFor="translationLanguage">Język</Form.Label>
        <Form.Select id="translationLanguage" {...register("language")}>
          <option value={0}>Wybierz</option>
          {languageOptions?.map((lang: LanguageData) => (
            <option key={lang.id} value={lang.id}>
              {lang.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <div className="container-fluid d-flex justify-content-end">
        <Button
          className="lh-base text-uppercase text-light btn-outline-blue"
          type="submit"
        >
          Wyślij
        </Button>
      </div>
    </Form>
  );
};

export default TranslationSubmissionForm;
