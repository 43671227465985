import {
  LoyaltyProgramCodeGenerateNegativeData,
  useGetLoyaltyProgramsServiceCodeQuery,
  usePostLoyaltyProgramCodeGenerateMutation,
  usePostLoyaltyProgramServiceSendCodeMutation,
} from "redux/services/loyaltyProgramApi";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { CountdownTimer } from "../../atoms/CountdownTimer/CountdownTimer";
import styles from "../LoyaltyProgramServiceArticle/LoyaltyProgramServiceArticle.module.scss";
import { CodesAvailable } from "../../atoms/CodesAvailable/CodesAvailable";
import { CodeModal } from "../../organisms/CodeModal/CodeModal";
import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { downloadPdf } from "../../../Helpers/downloadPdf";
import { useTranslation } from "react-i18next";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { domain } from "../../organisms/Landing/Landing";
import { isAuthPermission } from "../../../Helpers/Roles/isAuthPermission";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { PopoverLine } from "components/atoms/PopoverLine/PopoverLine";

interface Props {
  companyId: number;
  name: string;
}

export const LoyaltyProgramServiceCode = (props: Props) => {
  const { t } = useTranslation();
  const { authUser } = useSelector((state: RootState) => state.user);
  const [printPdf, setPrintPdf] = useState(false);
  const barcodeRef = useRef<HTMLInputElement>(null);
  const qrRef = useRef<HTMLInputElement>(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [showCode, setShowCode] = useState<boolean>(false);
  // const [showQRCode, setShowQRCode] = useState<boolean>(false);
  // const [showBarcode, setShowBarcode] = useState<boolean>(false);
  const { id } = useParams();
  const serviceId = id ?? "";

  const { data: loyaltyProgramServiceCodeData, isLoading } =
    useGetLoyaltyProgramsServiceCodeQuery(serviceId, { skip: !!!serviceId });
  const [sendCodeGeneration] = usePostLoyaltyProgramCodeGenerateMutation();
  const [notification] = usePostNotificationsMutation();

  const [sendCodeEmail] = usePostLoyaltyProgramServiceSendCodeMutation();

  const { handleSubmit, setValue } = useForm({
    defaultValues: {
      serviceId: loyaltyProgramServiceCodeData?.data?.id,
    },
  });

  const hasUserAssignedRole = isAuthPermission("loyalty-program", authUser);
  const hasUserAcceptedRegulations =
    loyaltyProgramServiceCodeData?.meta?.acceptedAgreement;
  const areCodesUnavailable =
    loyaltyProgramServiceCodeData?.data?.multipleUseCodesAllowed !== 1 &&
    loyaltyProgramServiceCodeData?.data?.promoCodesAmount !== null &&
    loyaltyProgramServiceCodeData?.data?.codeUsageCount >=
      loyaltyProgramServiceCodeData?.data?.promoCodesAmount;
  const hasLoyaltyProgramServiceEnded =
    loyaltyProgramServiceCodeData?.data?.endAt !== null &&
    new Date(loyaltyProgramServiceCodeData?.data?.endAt) < new Date();
  const isLoyaltyProgramServiceInactive =
    loyaltyProgramServiceCodeData?.data?.status !== 1;
  const isLoyaltyProgramServiceDeleted =
    loyaltyProgramServiceCodeData?.data?.deleted !== 0;
  const userRoles =
    authUser?.roles?.map((userRole) => userRole?.role?.role) ?? [];
  const isAdmin =
    userRoles.includes("superadmin") || userRoles.includes("admin");
  const isPatientAssigned = !!authUser?.settings?.Wskazanie_podopiecznego__c;
  const isGuardianWithAssignedPatient =
    userRoles.includes("guardian") && isPatientAssigned;
  const isPollCompleted = !!authUser?.settings?.pollID__c;
  const isPatientWithCompletedPoll =
    userRoles.includes("patient") && isPollCompleted;

  useEffect(() => {
    if (printPdf) {
      downloadPdf(
        qrRef,
        barcodeRef,
        loyaltyProgramServiceCodeData?.data?.code,
        props.name,
        loyaltyProgramServiceCodeData?.data?.expireAt
      ).then(() => setPrintPdf(false));
    }
    // eslint-disable-next-line
  }, [printPdf]);

  useEffect(() => {
    const isLoyaltyProgramServiceCodeNull =
      loyaltyProgramServiceCodeData?.data?.code === null;

    if (
      isLoyaltyProgramServiceCodeNull &&
      hasUserAssignedRole &&
      hasUserAcceptedRegulations &&
      !isLoyaltyProgramServiceDeleted &&
      !isLoyaltyProgramServiceInactive &&
      !hasLoyaltyProgramServiceEnded &&
      !areCodesUnavailable &&
      (isAdmin || isGuardianWithAssignedPatient || isPatientWithCompletedPoll)
    ) {
      setDisabledButton(false);
      setValue("serviceId", loyaltyProgramServiceCodeData?.data?.id);
    } else {
      setDisabledButton(true);
    }
    // eslint-disable-next-line
  }, [loyaltyProgramServiceCodeData, hasUserAssignedRole]);

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const submitHandler: SubmitHandler<CodeFormData> = async (data) => {
    if (!disabledButton) {
      const sendCodeGenerationResponse = await sendCodeGeneration(data);

      if ("data" in sendCodeGenerationResponse) {
        if ("data" in sendCodeGenerationResponse?.data) {
          toast.success(t("toast_loyalityProgramServiceCode_code_success"));
        } else if ("translationKey" in sendCodeGenerationResponse?.data) {
          const error: LoyaltyProgramCodeGenerateNegativeData =
            sendCodeGenerationResponse?.data;

          if (error?.translationKey) {
            error?.translationParam
              ? toast.error(
                  t(`${error?.translationKey} ${error?.translationParam}`)
                )
              : toast.error(t(error?.translationKey));
          } else {
            toast.error(t("toast_loyalityProgramServiceCode_code_error"));
          }
        }
      } else {
        toast.error(t("toast_loyalityProgramServiceCode_code_error"));
      }
    }
  };

  const handleDownload = () => {
    setPrintPdf(true);
  };

  const sendCode = async () => {
    if (loyaltyProgramServiceCodeData?.data?.id) {
      const hasBeenSendEmail = await sendCodeEmail(
        loyaltyProgramServiceCodeData?.data?.id
      );

      if ("data" in hasBeenSendEmail) {
        toast.success(t("toast_loyalityProgramServiceCode_email_success"));
        if (
          props.companyId &&
          loyaltyProgramServiceCodeData?.data?.promoCodesAmount &&
          loyaltyProgramServiceCodeData?.data?.codeUsageCount &&
          loyaltyProgramServiceCodeData?.data?.promoCodesAmount -
            loyaltyProgramServiceCodeData?.data?.codeUsageCount ===
            1
        ) {
          await notification({
            data: {
              title: "notification_LoyaltyProgramServiceCode_emptyCode_title",
              type: NotificationTypeEnum["loyalty-program"],
              viewSender: 0,
              senderUser: null,
              content: props.name,
              url: `${domain}/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${props.companyId}#services`,
              manyUser: "COMPANY",
              idMany: Number(props.companyId),
            },
          });
        }
      } else if ("error" in hasBeenSendEmail) {
        const error = hasBeenSendEmail.error as TranslationSubmitError;
        if (error.data.statusCode === 500)
          toast.error(t("toast_loyalityProgramServiceCode_email_error_500"));

        if (error.data.statusCode === 404)
          toast.error(t("toast_loyalityProgramServiceCode_email_error_404"));

        if (error.data.statusCode === 400)
          toast.error(
            `${t("toast_loyalityProgramServiceCode_email_error_400")} ${
              error.data.message[0]
            }`
          );
      }
    }
  };

  return (
    <>
      {loyaltyProgramServiceCodeData?.data?.code && (
        <div>
          <Container ref={qrRef} className={`${styles.pdfContainer}`}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={loyaltyProgramServiceCodeData?.data?.code}
              viewBox={`0 0 256 256`}
            />
          </Container>
          <Container
            style={{ width: 2000 }}
            ref={barcodeRef}
            className={`${styles.pdfContainer}`}
          >
            <div>
              <Barcode
                displayValue={false}
                format="CODE128"
                value={loyaltyProgramServiceCodeData?.data?.code}
              />
            </div>
          </Container>
        </div>
      )}

      <Row className={`${styles.wrapper}`}>
        {loyaltyProgramServiceCodeData?.data?.code ? (
          <>
            {loyaltyProgramServiceCodeData?.data?.expireAt && (
              <div className={"border-bottom p-4"}>
                <CountdownTimer
                  endDate={loyaltyProgramServiceCodeData?.data?.expireAt}
                  label={"Czas do utraty ważności kodu"}
                />
              </div>
            )}
            {!loyaltyProgramServiceCodeData?.data?.multipleUseCodesAllowed ? (
              <div className={"p-4"}>
                <Row className={"px-3"}>
                  <Button
                    variant={"dark"}
                    className={"fs-20"}
                    onClick={() => setShowCode((prev) => !prev)}
                  >
                    POKAŻ KOD
                  </Button>
                  <CodeModal
                    show={showCode}
                    handleClose={() => setShowCode((prev) => !prev)}
                    label={"Kod"}
                  >
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={loyaltyProgramServiceCodeData?.data?.code}
                      viewBox={`0 0 256 256`}
                    />
                    <div
                      className={`border border-1 text-center p-2 mt-4 ${styles.radius}`}
                    >
                      <span className={"fs-24 fw-semibold"}>
                        {loyaltyProgramServiceCodeData?.data?.code}
                      </span>
                    </div>
                  </CodeModal>
                </Row>
                {/*<Row className={"px-3 pt-3"}>*/}
                {/*    <Button*/}
                {/*        variant={"dark"}*/}
                {/*        className={"fs-5"}*/}
                {/*        // style={{fontSize: '1rem'}}*/}
                {/*        onClick={() => setShowQRCode((prev) => !prev)}*/}
                {/*    >*/}
                {/*        POKAŻ QR KOD*/}
                {/*    </Button>*/}

                {/*    <CodeModal*/}
                {/*        show={showQRCode}*/}
                {/*        handleClose={() => setShowQRCode((prev) => !prev)}*/}
                {/*        label={"Kod QR"}*/}
                {/*    >*/}
                {/*        <Container>*/}
                {/*            <QRCode*/}
                {/*                size={256}*/}
                {/*                style={{height: "auto", maxWidth: "100%", width: "100%"}}*/}
                {/*                value={loyaltyProgramServiceCodeData?.data?.code}*/}
                {/*                viewBox={`0 0 256 256`}*/}
                {/*            />*/}
                {/*        </Container>*/}
                {/*    </CodeModal>*/}
                {/*</Row>*/}
                {/*<Row className={"px-3 pt-3"}>*/}
                {/*    <Button*/}
                {/*        variant={"dark"}*/}
                {/*        className={"fs-20"}*/}
                {/*        onClick={() => setShowBarcode((prev) => !prev)}*/}
                {/*    >*/}
                {/*        POKAŻ KOD KRESKOWY*/}
                {/*    </Button>*/}

                {/*    <CodeModal*/}
                {/*        show={showBarcode}*/}
                {/*        handleClose={() => setShowBarcode((prev) => !prev)}*/}
                {/*        label={"Kod kreskowy"}*/}
                {/*        size={"lg"}*/}
                {/*    >*/}
                {/*        <div className={"d-flex justify-content-center"}>*/}
                {/*            <Barcode*/}
                {/*                format="CODE128"*/}
                {/*                value={loyaltyProgramServiceCodeData?.data?.code}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </CodeModal>*/}

                {/*</Row>*/}

                {/*<Row className={"px-3 pt-3"}>*/}
                {/*    <Button variant={"outline-primary"} disabled={true} className={"fs-16"}>WYŚLIJ KOD*/}
                {/*        SMSEM</Button>*/}
                {/*</Row>*/}
                <Row className={"px-3 pt-3"}>
                  <Button
                    variant={"outline-primary"}
                    className={"fs-16"}
                    onClick={sendCode}
                  >
                    wyślij w e-mailu
                  </Button>
                </Row>
                <Row className={"px-3 pt-3"}>
                  <Button
                    variant={"outline-primary"}
                    className={"fs-16"}
                    onClick={handleDownload}
                    disabled={printPdf}
                  >
                    WYDRUKUJ PDF
                  </Button>
                </Row>

                {/*<Row className={"px-3 pt-3"}>*/}
                {/*    <Button variant={"outline-primary"} disabled={true} className={"fs-16"}>DODAJ DO*/}
                {/*        WALLET</Button>*/}
                {/*</Row>*/}
              </div>
            ) : (
              <>
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={
                    loyaltyProgramServiceCodeData?.data?.multipleUseCode?.code
                  }
                  viewBox={`0 0 256 256`}
                />
                <p className="fw-600 fs-24 text-center mb-0 mt-2">
                  {loyaltyProgramServiceCodeData?.data?.multipleUseCode?.code}
                </p>
              </>
            )}
          </>
        ) : (
          <>
            {!loyaltyProgramServiceCodeData?.data?.multipleUseCodesAllowed &&
              (loyaltyProgramServiceCodeData?.data?.endAt ||
                loyaltyProgramServiceCodeData?.data?.promoCodesAmount !==
                  null) && (
                <div className="p-4 border-bottom">
                  <CodesAvailable
                    codeUsageCount={
                      loyaltyProgramServiceCodeData?.data?.codeUsageCount
                    }
                    promoCodesAmount={
                      loyaltyProgramServiceCodeData?.data?.promoCodesAmount
                    }
                  />

                  <CountdownTimer
                    endDate={loyaltyProgramServiceCodeData?.data?.endAt}
                    label={"Możesz pobrać benefit w ciągu:"}
                  />
                </div>
              )}

            <div className={"p-4"}>
              <Row className={"px-3"}>
                <Form
                  className={"d-flex flex-column w-100 p-0"}
                  onSubmit={handleSubmit(submitHandler)}
                >
                  <OverlayTrigger
                    placement={"bottom-end"}
                    trigger={["hover", "hover"]}
                    overlay={
                      disabledButton ? (
                        <Popover id="popover-basic" style={{ maxWidth: 600 }}>
                          {!hasUserAssignedRole ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t(
                                  "lps_codeOverlay_userHasNoRoleAssigned"
                                )}
                              />
                            </Popover.Body>
                          ) : !hasUserAcceptedRegulations ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t(
                                  "lps_codeOverlay_userHasNoAcceptedRegulations"
                                )}
                              />
                            </Popover.Body>
                          ) : isLoyaltyProgramServiceDeleted ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t("lps_codeOverlay_benefitIsDeleted")}
                              />
                            </Popover.Body>
                          ) : isLoyaltyProgramServiceInactive ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t("lps_codeOverlay_benefitIsInactive")}
                              />
                            </Popover.Body>
                          ) : hasLoyaltyProgramServiceEnded ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t("lps_codeOverlay_benefitHasEnded")}
                              />
                            </Popover.Body>
                          ) : areCodesUnavailable ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t("lps_codeOverlay_codesAreUnavailable")}
                              />
                            </Popover.Body>
                          ) : userRoles.includes("patient") &&
                            !isPollCompleted ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t(
                                  "lps_codeOverlay_userIsPatientWithoutCompletedPoll"
                                )}
                              />
                            </Popover.Body>
                          ) : userRoles.includes("guardian") &&
                            !isPatientAssigned ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t(
                                  "lps_codeOverlay_userIsGuardianAndHasNoPatient"
                                )}
                              />
                            </Popover.Body>
                          ) : userRoles.includes("sponsor") ? (
                            <Popover.Body>
                              <PopoverLine
                                label={t("lps_codeOverlay_userIsSponsor")}
                              />
                            </Popover.Body>
                          ) : (
                            <></>
                          )}
                        </Popover>
                      ) : (
                        <></>
                      )
                    }
                    defaultShow={false}
                  >
                    <div className="block">
                      <Button
                        variant={"dark"}
                        type="submit"
                        value="Submit"
                        className={
                          disabledButton
                            ? "btn-disabled fs-20 w-100 text-nowrap"
                            : "fs-20 w-100 text-nowrap"
                        }
                        disabled={disabledButton}
                      >
                        SKORZYSTAJ
                      </Button>
                    </div>
                  </OverlayTrigger>
                </Form>
              </Row>
            </div>
          </>
        )}
        <div
          className={`${
            isAuthPermission("loyalty-program", authUser) && "border-top"
          } p-4`}
        >
          <Link
            to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${props.companyId}`}
          >
            <Row className={"px-3"}>
              <Button variant={"outline-primary"} className={"fs-16"}>
                {t("button_LoyaltyProgramServiceCode")}
              </Button>
            </Row>
          </Link>
        </div>
      </Row>
    </>
  );
};
