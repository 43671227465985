import { Accordion, Button, Container, Row, Spinner } from "react-bootstrap";
import styles from "./UserCancerInfo.module.scss";
import { useTranslation } from "react-i18next";

const UserCancerInfo = ({
  placeOfTreatment,
  treatmentStatus,
  cancerName,
  data,
  updatePoll,
  roleName,
  isLoading,
}: UserCancerInfoProps) => {
  const updateOnClick = () => {
    updatePoll(roleName);
  };
  const { t } = useTranslation();

  const renderAccordion = (data: PageDataGet[]) => {
    return (
      <Accordion>
        {data?.map((page: PageDataGet) => (
          <Accordion.Item
            eventKey={page?.id?.toString()}
            key={page?.id}
            className={`mb-3 ${styles.accordinItems}`}
          >
            <Accordion.Header className={styles.accordionHeader}>
              <h2 className="fw-600 fs-16 text-dark-blue m-0">{page?.title}</h2>
            </Accordion.Header>
            <Accordion.Body
              className=""
              id={page?.id?.toString()}
              dangerouslySetInnerHTML={{ __html: page?.content }}
            />
            {page?.childPage?.length > 0 && (
              <Accordion.Body className="" id={page?.id?.toString()}>
                <div>{renderAccordion(page?.childPage)}</div>
              </Accordion.Body>
            )}
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3 mb-md-2">
        {isLoading ? (
          <Spinner animation="border" variant="primary" role="Wczytywanie">
            <span className="visually-hidden">Wczytywanie...</span>
          </Spinner>
        ) : (
          <Row className="w-auto d-flex flex-row">
            <h2
              className={`fw-600 fs-22 text-dark-blue d-flex w-auto mb-2 mb-lg-0 pe-0 ${styles.title}`}
            >
              {cancerName}
            </h2>
            <h2
              className={`fw-400 fs-22 text-primary d-flex w-auto mb-3 mb-lg-0 ${styles.title}`}
            >
              {treatmentStatus}
            </h2>
          </Row>
        )}
        <Button
          variant="outline-primary"
          className="px-2 py-1 mb-3 align-self-stretch align-self-md-start align-self-lg-center mb-sm-0"
          onClick={updateOnClick}
        >
          {t("widget_your_cancer_button")}
        </Button>
      </div>
      <p className="fw-400 fs-16 text-dark-blue mb-4">
        {t("widget_your_cancer_p2")}{" "}
        <span className="fw-600">{placeOfTreatment}</span>
      </p>
      {data && <div>{renderAccordion(data)}</div>}
    </Container>
  );
};

export default UserCancerInfo;
