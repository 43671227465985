import { Button, Row } from "react-bootstrap";
import styles from "./ShortestQueueResult.module.scss";
import { Link } from "react-router-dom";

const ShortestQueueResult = ({
  detailedFacilityData,
}: ShortestQueueResultProps) => (
  <Row
    className={`p-0 m-0 p-2 p-xl-4 d-flex flex-wrap flex-md-nowrap align-items-center ${styles.resultContainer}`}
  >
    <div
      className={`col-12 col-md-3 d-flex flex-wrap mt-3 mt-md-0 justify-content-md-center justify-content-xxl-start text-md-center ${styles.nearestExaminationContainer}`}
    >
      <p className={"py-2 m-0"}>Najbliższa wizyta za </p>
      <span
        className={"fw-700 p-2 text-uppercase text-nowrap mx-2 mx-md-0 mx-xl-2"}
      >
        {detailedFacilityData?.facilityServiceSummary?.daysUntilExamination
          ? `${
              detailedFacilityData?.facilityServiceSummary?.daysUntilExamination
            }${" "}
            dni`
          : "brak danych"}
      </span>
    </div>
    <div
      className={`col-12 col-md-5 col-xl-6 d-flex align-items-center my-4 my-md-0 ${styles.facilityInfo}`}
    >
      <Link
        to={`${process.env.REACT_APP_EXTERNAL_LINK_ONKOSKANER}/szukaj/placowka/${detailedFacilityData?.facility?.id}/${detailedFacilityData?.facility?.name}`}
        className={
          "d-flex align-items-center gap-3 gap-md-1 flex-wrap text-dark text-uppercase"
        }
      >
        <strong className={"col-12"}>
          {detailedFacilityData?.facility?.nameRegional ||
            detailedFacilityData?.facility?.name}
        </strong>
        {`${detailedFacilityData?.facility?.street}, ${detailedFacilityData?.facility?.city?.name}, ${detailedFacilityData?.facility?.province?.name}`}
      </Link>
    </div>
    <div
      className={`col-12 col-md-4 col-xl-3 d-flex align-items-center flex-wrap justify-content-end ${styles.actionButton}`}
    >
      <Button
        className={`bg-white border-secondary text-secondary fw-medium col-12 ${styles.rwdButton}`}
        href={`${process.env.REACT_APP_EXTERNAL_LINK_ONKOSKANER}/szukaj/placowka/${detailedFacilityData?.facility?.id}/${detailedFacilityData?.facility?.name}`}
        target="_blank"
      >
        SPRAWDŹ
      </Button>
    </div>
  </Row>
);

export default ShortestQueueResult;
