export const ChangesCompanyConfig: ConfigChanges[] = [
  {
    keyName: "name",
    type: "normal",
    name: "Nazwa firmy",
  },
  {
    keyName: "imageId",
    type: "image",
    name: "Logo firmy",
    maxHeight: 160,
    rounded: true,
  },
  {
    keyName: "fullName",
    type: "normal",
    name: "Pełna nazwa firmy",
  },
  {
    keyName: "description",
    type: "normal",
    name: "O firmie",
  },
  {
    keyName: "nipProvider",
    type: "normal",
    name: "NIP",
  },
  {
    keyName: "socialAddresses",
    type: "social",
    name: "Serwisy internetowe",
  },
];
