import { useEffect, useState } from "react";
import styles from "./MediaPicker.module.scss";
import { Button, Image } from "react-bootstrap";
import MediaModal from "components/organisms/MediaModal/MediaModal";

const MediaPicker = ({ pageData, setAttachment, imageUrl }: MediaPicker) => {
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image>();
  // eslint-disable-next-line
  const [content, setContent] = useState<string>();

  const removeImage = () => {
    setSelectedImage(undefined);
  };

  useEffect(() => {
    if (setAttachment && selectedImage) {
      const attachment = pageData?.data?.featureImage[0]
        ? selectedImage.accessUrl
        : selectedImage.id;
      setAttachment(attachment);
    }
    if (setAttachment && selectedImage === undefined) {
      setAttachment("");
    }
  }, [selectedImage, setAttachment, pageData?.data?.featureImage]);

  useEffect(() => {
    if (pageData?.data?.featureImage[0]) {
      setSelectedImage(pageData?.data?.featureImage[0]);
    }
  }, [pageData]);

  return (
    <div
      className={`${styles.MediaPickerContainer} ${
        !selectedImage && styles.WordpressButton
      }`}
    >
      {selectedImage ? (
        <div
          className={`d-flex flex-column justify-content-start border-1 p-3 `}
        >
          <Image
            className={`p-0 m-0 w-auto h-auto`}
            fluid={true}
            src={selectedImage.accessUrl}
          />
          <Button
            className={"me-auto mt-3 text-uppercase fs-12"}
            onClick={() => setShowMediaModal(true)}
            variant={"outline-primary"}
          >
            Zastąp obrazek
          </Button>
          <Button
            className={"me-auto mt-3 fs-12 p-0 text-danger"}
            onClick={removeImage}
            variant={"link"}
          >
            Usuń obrazek wyróżniający
          </Button>
        </div>
      ) : (
        <div
          onClick={() => setShowMediaModal(true)}
          className={`d-flex justify-content-center align-items-center ${styles.MediaPickerContainer}`}
        >
          <p className="m-0">Ustaw obrazek wyróżniający</p>
        </div>
      )}
      <MediaModal
        show={showMediaModal}
        setContent={setContent}
        setShow={setShowMediaModal}
        setSelectedImage={setSelectedImage}
      />
    </div>
  );
};

export default MediaPicker;
