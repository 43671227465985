import { Badge, Button, Col, Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import styles from "./CompanyEntity.module.scss";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { Company } from "../LoyaltyProgramServiceArticle/types";
import { useNavigate } from "react-router-dom";
import { WidgetCompanyServices } from "../WidgetCompanyServices/WidgetCompanyServices";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { GetCompaniesProps } from "../../../redux/services/companyApi";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: Company;
  reFetchCompany: () => QueryActionCreatorResult<
    QueryDefinition<
      GetCompaniesProps,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
}

export const CompanyEntity = (props: Props) => {
  const navigate = useNavigate();
  const [showValue, setShowValue] = useState(false);
  const [changes, setChanges] = useState<React.ReactNode | false>(false);

  useEffect(() => {
    if (props.data.changes === undefined || props.data.changes === null) {
      setChanges(false);
    } else {
      const newChanges = JSON.parse(props.data.changes);
      if (newChanges.comments !== undefined) {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            wymaga poprawy: {newChanges.comments}
          </Badge>
        );
      } else {
        setChanges(
          <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
            oczekuje na weryfikację
          </Badge>
        );
      }
    }
  }, [props.data.changes]);
  return (
    <Col className={`col-12 border border-1 ps-3 pe-2 py-3 ${styles.radius}`}>
      <Container fluid={true} className={"d-flex flex-column"}>
        <div className="d-flex flex-row justify-content-between">
          <div className={`d-flex justify-content-start align-items-center`}>
            <div>
              {changes && changes}
              <div
                className={`fs-19 fw-normal ${
                  props.data.deleted === 1 ? "text-grey-2" : "text-dark-blue"
                }`}
              >
                {props.data.name}
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-row justify-content-start align-items-center gap-2 ${styles.button}`}
          >
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
              variant={"transparent"}
              onClick={() =>
                navigate(
                  `/${NAV_PATH.SPONSOR}/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.COMPANY}/${props.data.id}`
                )
              }
            >
              <ArrowUpRightFromSquare />
            </Button>
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 ${
                showValue ? styles.arrowUp : styles.arrowDown
              } ${!showValue && "text-dark-blue"}`}
              variant={showValue ? "dark" : "transparent"}
              onClick={() => setShowValue((prevState) => !prevState)}
            >
              <Arrow rotate={90} />
            </Button>
          </div>
        </div>
        {showValue && (
          <WidgetCompanyServices
            data={props.data}
            refetch={props.reFetchCompany}
          />
        )}
      </Container>
    </Col>
  );
};
