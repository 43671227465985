export interface ImageIds {
  id: string;
  accessUrl: string;
  filename: Filename;
  meta: string | null;
}

type Filename =
  | "small.jpeg"
  | "medium.jpeg"
  | "normal.jpeg"
  | "banner.jpeg"
  | "mini.jpeg";

export const convertImageIdToUrl = (
  images: ImageIds[] | undefined,
  isMain: boolean,
  filename?: Filename,
): string | null => {
  if (images === undefined) {
    return null;
  }
  if (isMain) {
    return images.find((image) => image.meta === null)?.accessUrl || null;
  }
  if (filename) {
    return (
      images.find((image) => image.filename === filename)?.accessUrl || null
    );
  }
  return null;
};
