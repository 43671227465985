import { Button, Col, Container, Image, Stack } from "react-bootstrap";
import styles from "../../templates/ChangesCompanyTemplate/ChangesCompanyTemplate.module.scss";
import { RejectModal } from "../RejectModal/RejectModal";
import React, { useEffect, useState } from "react";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { SeparationUpdate } from "../../atoms/SeparationUpdate/SeparationUpdate";
import { useGetAttachmentQuery } from "../../../redux/services/attachmentsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import {
  Company,
  LoyaltyProgramServiceData,
} from "../../molecules/LoyaltyProgramServiceArticle/types";

interface Props {
  name: string;
  data: Company | LoyaltyProgramServiceData | null;
  keyName: string;
  changes: { [key: string]: any };
  rounded: boolean;
  maxHeight: number;
  maxWidth?: number;
  handlePatch?: (obj: { [key: string]: any }, keyName: string) => void;
  handleComments?: (comment: string) => void;
  isAdmin: boolean;
}

export const ChangesImage = (props: Props) => {
  const [showReject, setShowReject] = useState(false);
  const [image, setImage] = useState<string | null>();
  const { data } = useGetAttachmentQuery(
    !!props.changes?.[props.keyName]
      ? { id: props.changes?.[props.keyName] }
      : skipToken
  );

  useEffect(() => {
    setImage(convertImageIdToUrl(props.data?.imageIds, true, "medium.jpeg"));
  }, [props.data?.imageIds]);

  const handlePatch = () => {
    const obj: any = {};
    obj[props.keyName] = props.changes?.[props.keyName];
    if (props.handlePatch) {
      props.handlePatch(obj, props.keyName);
    }
  };

  const handleComments = (comment: string) => {
    if (props.handleComments) {
      props.handleComments(comment);
    }
  };

  return (
    <Stack gap={3}>
      <Col className={`${props.isAdmin && styles.wrapper}`}>
        <Container fluid={true}>
          <div className={"fs-22 fw-semibold m-0 p-0 text-dark-blue"}>
            {props.name}
          </div>
          {props.data && image ? (
            <Image
              style={{
                maxHeight: props.maxHeight,
                maxWidth: props.maxWidth || props.maxHeight,
              }}
              className={`mt-3 p-0 ${styles.image}`}
              fluid={true}
              src={image}
              roundedCircle={props.rounded}
            />
          ) : (
            <div className={"text-dark-blue"}>Brak obrazu</div>
          )}
        </Container>
      </Col>
      {((data && !!props.changes?.[props.keyName]) ||
        props.changes?.[props.keyName] === null) && (
        <>
          <SeparationUpdate />
          <Col className={`${props.isAdmin && styles.wrapper} text-danger`}>
            <Container fluid={true}>
              <div className={"fs-22 fw-semibold m-0 p-0"}>{props.name}</div>
              {props.changes?.[props.keyName] ? (
                <Image
                  style={{
                    maxHeight: props.maxHeight,
                    maxWidth: props.maxWidth || props.maxHeight,
                  }}
                  className={`mt-3 p-0 border border-danger border-2 ${styles.image}`}
                  fluid={true}
                  src={data?.data?.accessUrl ?? ""}
                  roundedCircle={props.rounded}
                />
              ) : (
                <p>Usunięto obraz</p>
              )}
            </Container>
          </Col>
        </>
      )}

      {props.isAdmin && !!props.changes?.[props.keyName] && (
        <Col className={"d-flex justify-content-end gap-2"}>
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowReject((prev) => !prev)}
          >
            ODRZUĆ
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev) => !prev)}
            handleComments={handleComments}
            comments={
              props.changes?.comments
                ? `${props.changes.comments}; ${props.name}: `
                : `${props.name}: `
            }
          />
          <Button className={" fs-16"} onClick={handlePatch}>
            AKCEPTUJ
          </Button>
        </Col>
      )}
    </Stack>
  );
};
