import { events } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";
import { PatchEvent } from "components/organisms/EditEventModal/types";

interface GetEventProps {
  id: string;
}

interface GetEventsProps {
  limit?: string | number;
  offset?: number;
  sortBy?: string;
  categoryName?: string;
  dateFrom?: string;
  orderBy?: string;
  type?: string[];
  includeOtherUserTypes?: "yes" | "no";
  user?: number;
  profilingSlugs?: string;
  name?: string;
  description?: string;
  search?: string;
  cancer?: number | string | null;
}

interface PostEventDataProps {
  data: PostEventProps;
}

export interface PostEventProps {
  address?: string;
  categoryIds: number[];
  cityId: number;
  deleted?: number;
  description?: string;
  endingDate?: string;
  eventUrl?: string;
  facilityId?: number;
  languageId: number;
  name: string;
  notes?: string;
  provinceId: number;
  startingDate: string;
  type?: string;
  userId: number;
  zipCode?: string;
  doctorId?: number;
  coverId?: string;
  cancerId: number;
  profilingIds?: number[];
  placeName?: string;
  street?: string;
  houseNumber?: string;
  apartmentNumber?: string;
  poll_Id: number;
}

export enum EventsTypes {
  COMPANY_USER = "COMPANY_USER",
  PATIENT_GUARDIAN = "PATIENT_GUARDIAN",
  GUARDIAN_PATIENT = "GUARDIAN_PATIENT",
  DOCTOR_FACILITY = "DOCTOR_FACILITY",
  FACILITY_DOCTOR = "FACILITY_DOCTOR",
}

interface PatchEventProps {
  id: number;
  data: PatchEvent;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Events"],
});

export const EventsApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    postEvent: builder.mutation({
      query: ({ data }: PostEventDataProps) => ({
        url: `${events}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Events"],
    }),

    getEvents: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        categoryName,
        dateFrom,
        orderBy,
        type,
        includeOtherUserTypes,
        user,
        profilingSlugs,
        name,
        description,
        search,
        cancer,
      }: GetEventsProps) => {
        const params = new URLSearchParams({
          ...(limit && { limit: limit.toString() }),
          ...(offset && { offset: offset.toString() }),
          ...(dateFrom && { dateFrom }),
          ...(orderBy && { orderBy }),
          ...(sortBy && { sortBy }),
          ...(categoryName && { categoryName }),
          ...(type && { type: type.toString() }),
          ...(includeOtherUserTypes && { includeOtherUserTypes }),
          ...(user && { user: user.toString() }),
          ...(profilingSlugs && { profilingSlugs }),
          ...(name && { name }),
          ...(description && { description }),
          ...(search && { search }),
          ...(cancer && { cancer: cancer.toString() }),
        });

        return {
          url: `${events}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Events"],
    }),

    getEvent: builder.query({
      query: ({ id }: GetEventProps) => {
        return {
          url: `${events}/${id?.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Events"],
    }),

    editEvent: builder.mutation({
      query: ({ id, data }: PatchEventProps) => ({
        url: `${events}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Events"],
    }),

    deleteEvent: builder.mutation({
      query: ({ id }: GetEventProps) => ({
        url: `${events}/${id}/delete`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Events"],
    }),
  }),
});

export const {
  usePostEventMutation,
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useGetEventQuery,
  useEditEventMutation,
  useDeleteEventMutation,
} = EventsApi;
