import { Button, Container, Row } from "react-bootstrap";
import styles from "../WidgetShortestQueue/WidgetShortestQueue.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FeedPost } from "../FeedPost/FeedPost";
import { useGetPostsQuery } from "../../../redux/services/feedApi";
import { NAV_PATH } from "../Navigation/navigationData";

export const LastPostWidget = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: feedData } = useGetPostsQuery({
    limit: 1,
    sortBy: "created_at,DESC",
  });

  return (
    <Container fluid className={`p-4 containerWithShadow`}>
      <h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
        {t("widget_last_post_title")}
      </h2>
      <Row className={"col-12 justify-content-center mx-0"}>
        <div
          className={
            "col-12 col-md-10 col-lg-9 col-xxl-8 d-flex justify-content-center mx-0"
          }
        >
          <FeedPost
            {...feedData?.data[0]}
            isPostClickable
            isCommentingAllowed
          />
        </div>
      </Row>

      <div className={"p-0 mx-0 mt-4 d-flex justify-content-end"}>
        <Button
          variant="primary"
          className={`${styles.button} text-white`}
          target="_blank"
          onClick={() => navigate(`/${NAV_PATH.FEED}`)}
        >
          {t("widget_last_post_go_to_button")}
        </Button>
      </div>
    </Container>
  );
};
