import { doctorSpecialization } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface GetDoctorSpecializationsSearchParams {
  nameLike?: string;
  offset?: string;
  limit?: string;
}
interface PatchDoctorSpecializationData {
  name: string;
  searchKeyword: string;
  description: string;
}

interface PatchDoctorSpecializationPayload {
  id: number;
  data: PatchDoctorSpecializationData;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["DoctorSpecialization"],
});

export const doctorSpecializationApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getDoctorSpecializations: builder.query({
      query: ({
        limit,
        offset,
        nameLike,
      }: GetDoctorSpecializationsSearchParams) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(nameLike && { nameLike }),
        });

        return {
          url: `${doctorSpecialization}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["DoctorSpecialization"],
    }),

    getDoctorSpecialization: builder.query({
      query: (id: number) => {
        return {
          url: `${doctorSpecialization}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["DoctorSpecialization"],
    }),

    patchDoctorSpecialization: builder.mutation({
      query: ({ id, data }: PatchDoctorSpecializationPayload) => ({
        url: `${doctorSpecialization}/${id}/update`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["DoctorSpecialization"],
    }),
  }),
});

export const {
  useGetDoctorSpecializationsQuery,
  useGetDoctorSpecializationQuery,
  usePatchDoctorSpecializationMutation,
  useLazyGetDoctorSpecializationsQuery
} = doctorSpecializationApi;
