import PollSendingTemplate from "components/templates/PollSendingTemplate/PollSendingTemplate";
import { v4 as uuidv4 } from "uuid";

export const INITIAL_POLL_FORM_DATA: PollSendingData = {
  title: "",
  description: "",
  languageId: 0,
  userRoleId: 0,
  verificationType: "none",
  adminVerification: 0,
  isReusable: 1,
  isActive: 1,
  sfConnection: 0,
  pages: [
    {
      title: "",
      description: "",
      isActive: 1,
      questions: [
        {
          question: "",
          description: "",
          sfSynchro: false,
          fieldName: `field_${uuidv4()}`,
          fieldType: "",
          isActive: 1,
          type: "",
          target: "",
          isOutputTableName: false,
          areLabelsNeeded: false,
          meta: {},
        },
      ],
    },
  ],
};

const PollNewPage = () => {
  return <PollSendingTemplate data={INITIAL_POLL_FORM_DATA} action="POST" />;
};

export default PollNewPage;
