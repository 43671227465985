import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./PollAttachmentDropzone.module.scss";
import { Button } from "react-bootstrap";
import { ReactComponent as ArrowNarrowUpMove } from "../../../assets/Icons/ArrowNarrowUpMove.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PollAttachmentDropzone: React.FC<PollAttachmentDropzoneProps> = ({
  files,
  setFiles,
  isError = false,
}) => {
  const [previews, setPreviews] = useState<
    { url: string; name: string; type: string }[]
  >([]);
  const { t } = useTranslation();

  const getCustomErrorMessage = (code: string) => {
    switch (code) {
      case "file-invalid-type":
        return t("toast_PollAttachmentDropzone_error_file_invalid_type");
      case "file-too-large":
        return t("toast_PollAttachmentDropzone_error_file_too_large");
      case "file-too-small":
        return t("toast_PollAttachmentDropzone_error_file_too_small");
      case "too-many-files":
        return t("toast_PollAttachmentDropzone_error_too_many_files");
      default:
        return t("toast_PollAttachmentDropzone_error_default");
    }
  };

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: {
        file: File;
        errors: { code: string; message: string }[];
      }[]
    ) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach((rejection) => {
          const { errors } = rejection;
          errors.forEach((error) => {
            const customMessage = getCustomErrorMessage(error.code);
            toast.error(
              `${t("toast_PollAttachmentDropzone_error_file")} ${
                rejection.file.name
              } ${t("toast_PollAttachmentDropzone_error")} ${customMessage}`
            );
          });
        });
      }
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
    // eslint-disable-next-line
    [setFiles]
  );

  useEffect(() => {
    setPreviews(
      files.map((file) => {
        if (file.type.startsWith("image/")) {
          return {
            url: URL.createObjectURL(file),
            name: file.name,
            type: "image",
          };
        } else {
          return { url: "", name: file.name, type: "file" };
        }
      })
    );
  }, [files]);

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
    setPreviews((prevPreviews) =>
      prevPreviews.filter((file, i) => i !== index)
    );
  };

  const { isDragActive, open, getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    minSize: 0,
    maxSize: 5242880,
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "image/heic": [".heic"],
    },
    onDrop,
  });

  return (
    <div className={`${styles.dropzoneWrapper}`}>
      <div
        {...getRootProps()}
        className={`${styles.label} d-flex flex-column ${
          isError ? styles.labelError : ""
        }`}
        data-dragactive={isDragActive}
      >
        <Button
          className="btn-primary d-flex justify-content-center align-items-center mb-2 px-4"
          onClick={open}
        >
          <ArrowNarrowUpMove />
          <span className={"ps-2 fs-16"}>PRZEGLĄDAJ PLIKI</span>
        </Button>
        <span> lub przeciągnij plik na pole </span>
        <input
          {...getInputProps()}
          multiple
          accept="image/jpeg, image/png, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/heic"
        />

        <div className={`d-flex ${styles.previews}`}>
          {previews.map((preview, i) =>
            preview.type === "image" ? (
              <div
                key={i}
                className={`${styles.previewCard} position-relative`}
              >
                <img src={preview.url} alt={preview.name} />
                <div className="d-flex justify-content-between">
                  <p>{preview.name}</p>
                  <button
                    type="button"
                    onClick={() => removeFile(i)}
                    className={`btn-close ${styles.removeBtn}`}
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            ) : (
              <div
                key={i}
                className={`${styles.previewCard} d-flex justify-content-between`}
              >
                <p>{preview.name}</p>
                <button
                  type="button"
                  onClick={() => removeFile(i)}
                  className={`btn-close ${styles.removeBtn}`}
                  aria-label="Close"
                ></button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PollAttachmentDropzone;
