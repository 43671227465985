import QuickEditPage from "components/molecules/QuickEditCollapse/QuickEditPage/QuickEditPage";
import { useId, useState, Fragment } from "react";
import { Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as SortArrow } from "../../../../assets/Icons/SortArrow.svg";
import TableRowWithCollapse from "../../TableRowWithCollapse/TableRowWithCollapse";

import "./PagesTable.scss";

const PagesTable = ({ data }: dataType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(searchParams.get("sortBy"));

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const id = useId();

  return (
    <Table className="mt-5">
      <thead>
        <tr>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "title,DESC"}
              onClick={() =>
                sort === "title,ASC"
                  ? handleSort(`title,DESC`)
                  : handleSort(`title,ASC`)
              }
            >
              <span>Tytuł</span>
              <SortArrow
                data-active={sort?.includes("title")}
                className="sortArrow"
              />
            </button>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "createdBy,DESC"}
              onClick={() =>
                sort === "createdBy,ASC"
                  ? handleSort(`createdBy,DESC`)
                  : handleSort(`createdBy,ASC`)
              }
            >
              <span>Autor</span>
              <SortArrow
                data-active={sort?.includes("createdBy")}
                className="sortArrow"
              />
            </button>
          </th>
          <th className="pb-4">
            <span className="stats__sortButton">Kategoria</span>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "createdAt,DESC"}
              onClick={() =>
                sort === "createdAt,ASC"
                  ? handleSort(`createdAt,DESC`)
                  : handleSort(`createdAt,ASC`)
              }
            >
              <span>Data</span>
              <SortArrow
                data-active={sort?.includes("createdAt")}
                className="sortArrow"
              />
            </button>
          </th>
        </tr>
      </thead>

      <tbody className="tableBody">
        {data?.map((item: any, i: any) => {
          if (!!item?.childPage?.length)
            return (
              <Fragment key={`${id}-${i}-xd`}>
                <TableRowWithCollapse
                  key={i}
                  data={item}
                  CollapseItem={QuickEditPage}
                />

                {item?.childPage?.map((item: any, idx: number) => (
                  <Fragment key={`${id}-${idx}`}>
                    <TableRowWithCollapse
                      key={`${id}-${idx}`}
                      data={item}
                      CollapseItem={QuickEditPage}
                      child={1}
                    />
                    {item?.childPage?.map((innerItem: any, idx: number) => {
                      return (
                        <Fragment key={`${id}-${idx}`}>
                          <TableRowWithCollapse
                            key={`${id}-${idx}`}
                            data={innerItem}
                            CollapseItem={QuickEditPage}
                            child={2}
                          />
                          {innerItem?.childPage?.map(
                            (innerItem2: any, idx: number) => {
                              return (
                                <TableRowWithCollapse
                                  key={`${id}-${idx}`}
                                  data={innerItem2}
                                  CollapseItem={QuickEditPage}
                                  child={3}
                                />
                              );
                            }
                          )}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ))}
              </Fragment>
            );
          else
            return (
              <TableRowWithCollapse
                key={i}
                data={item}
                CollapseItem={QuickEditPage}
              />
            );
        })}
        {!data?.length && (
          <tr>
            <td colSpan={6} className="text-center">
              Nie znaleziono żadnych stron.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PagesTable;
