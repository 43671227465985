import { useEffect, useState } from "react";
import styles from "./TextArea.module.scss";

const TextArea = ({
  label,
  register,
  placeholder,
  type,
  className,
  disabled,
  error,
  row,
  limitSeo,
  maxLength,
  labelClassName,
  onFocus,
  onClick,
  readonly = false,
}: TextAreaProps) => {
  const [limitCounter, setLimitCounter] = useState(0);

  useEffect(() => {
    if (!limitSeo) return;
    if (limitSeo > 0) {
      setLimitCounter(limitSeo);
    }
  }, [limitSeo]);

  return (
    <label
      className={`form-check-label col-auto d-flex flex-column  ${className}`}
    >
      {label && (
        <span
          className={`${styles.labelMargin} me-3 mb-1 w-100 ${labelClassName}`}
        >
          {label}
        </span>
      )}
      <textarea
        inputMode={type || "text"}
        placeholder={placeholder}
        maxLength={maxLength}
        className={`form-control text-dark-blue ${styles.InputStyle} `}
        disabled={disabled}
        key="password"
        rows={row || 3}
        style={{ border: error && "1px solid red" }}
        onChange={(e) =>
          !!limitSeo?.toString().length &&
          setLimitCounter(e.target.value.length)
        }
        onFocus={onFocus}
        onClick={onClick}
        readOnly={readonly}
        {...register}
      />
      {error && <p className="text-danger fs-13">{error}</p>}
      {!!limitSeo?.toString().length &&
        (80 - limitCounter > -1 ? (
          <p className="text-primary fs-13">limit seo [{80 - limitCounter}]</p>
        ) : (
          <p className="text-danger fs-13">limit seo [{80 - limitCounter}]</p>
        ))}
    </label>
  );
};

export default TextArea;
