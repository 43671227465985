import { isHTML } from "Helpers/isHTML";
import styles from "../../molecules/LoyaltyProgramServiceArticle/LoyaltyProgramServiceArticle.module.scss";
import { Row } from "react-bootstrap";

interface Props {
  description: string;
  label: string;
}

const LoyaltyProgramServiceArticleDescriptions = ({
  label,
  description,
}: Props) => {
  if (isHTML(description)) {
    return (
      <Row className={`${styles.rowPadding}`}>
        <span
          className={`fw-bolder fs-19 col-12 text-dark-blue ${styles.infoPadding}`}
        >
          {label}
        </span>

        <div
          className={`text-dark-blue text-break ${styles.html}`}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Row>
    );
  }

  return (
    <Row className={`${styles.rowPadding}`}>
      <span
        className={`fw-bolder fs-19 col-12 text-dark-blue ${styles.infoPadding}`}
      >
        {label}
      </span>

      <div className={"text-dark-blue text-break"}>{description}</div>
    </Row>
  );
};

export default LoyaltyProgramServiceArticleDescriptions;
