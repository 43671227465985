import React, { useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import styles from "./MessagesTemplate.module.scss";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  PatchTopicCaseData,
  PostTopicCaseData,
  useGetTopicsCaseQuery,
  usePatchTopicCaseMutation,
  usePostTopicCaseMutation,
} from "../../../redux/services/topicCaseApi";
import { TopicCaseTable } from "../../organisms/Tables/TopicCaseTable/TopicCaseTable";
import { useTranslation } from "react-i18next";

const INITIAL_SEARCH_PARAMS = {
  PAGE: 1,
  LIMIT: 10,
  OFFSET: 0,
  TOTAL_RESULTS: 0,
};

export interface EditObj extends PatchTopicCaseData {
  id: number;
}

export const MessagesTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState<number>(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.PAGE
  );
  const [editObj, setEditObj] = useState<EditObj | null>(null);

  const { t } = useTranslation();

  const { data: topicCaseData, isLoading: topicCaseIsLoading } =
    useGetTopicsCaseQuery({
      offset: String(
        currPage
          ? (currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT
          : INITIAL_SEARCH_PARAMS.OFFSET
      ),
      limit: String(INITIAL_SEARCH_PARAMS.LIMIT),
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PostTopicCaseData | PatchTopicCaseData>({
    defaultValues: {
      case: "",
      sfSynchro: 0,
      status: 1,
    },
  });

  useEffect(() => {
    if (editObj === null) {
      setValue("case", "");
      setValue("sfSynchro", 0);
      setValue("status", 1);
    } else {
      setValue("case", editObj.case);
      setValue("sfSynchro", editObj.sfSynchro);
      setValue("status", editObj.status);
    }
    // eslint-disable-next-line
  }, [editObj]);

  const [postTopicCase, { isSuccess: postSuccess, error: postError }] =
    usePostTopicCaseMutation();

  const [patchTopicCase, { isSuccess: patchSuccess, error: patchError }] =
    usePatchTopicCaseMutation();

  useEffect(() => {
    if (postSuccess) {
      toast.success(t("toast_messagesTemplate_add_category_success"));
    } else if (patchSuccess) {
      toast.success(t("toast_messagesTemplate_edit_category_success"));
    }
    if (postError) {
      toast.error(t("toast_messagesTemplate_add_category_error"));
    } else if (patchError) {
      toast.error(t("toast_messagesTemplate_edit_category_error"));
    }
    // eslint-disable-next-line
  }, [postSuccess, patchSuccess, postError, patchError]);

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const submitHandler: SubmitHandler<
    PostTopicCaseData | PatchTopicCaseData
  > = async (data: PatchTopicCaseData | PostTopicCaseData): Promise<void> => {
    const newData: PatchTopicCaseData | PostTopicCaseData = {
      ...data,
      sfSynchro: !!data.sfSynchro ? 1 : 0,
    };
    if (editObj === null) {
      await postTopicCase({ data: newData });
    } else {
      await patchTopicCase({ id: editObj.id, data: { ...newData } });
    }
  };

  const handleEdit = async (data: EditObj, now: boolean) => {
    if (now) {
      await patchTopicCase({ id: data.id, data: data });
    } else {
      setEditObj(data);
    }
  };

  return (
    <div className="mt-2 px-4 w-100">
      <h1 className="pe-3 fs-2 my-3 text-dark-blue">Tematy zgłoszeń</h1>
      <div className="row w-100">
        <form
          className={`col ${styles.categoriesForm}`}
          onSubmit={handleSubmit(submitHandler)}
        >
          <p className="fw-bold text-dark-blue">
            {editObj === null ? "Utwórz sprawę" : "Zapisz zamiany"}
          </p>
          <ComponentWithDescription
            label={"Nazwa sprawy*"}
            labelFs={`fs-16 ${errors.case ? "text-danger" : "text-dark-blue"}`}
            className="my-2 pb-3"
          >
            <Form.Control
              type="text"
              id="case"
              aria-describedby="case"
              className={`w-100 text-dark-blue ${styles.input} `}
              {...register("case", {
                required: { value: true, message: "Pole wymagane" },
              })}
              isInvalid={Boolean(errors.case)}
            />
            {errors.case && (
              <Form.Control.Feedback type={"invalid"}>
                {errors.case.message}
              </Form.Control.Feedback>
            )}
          </ComponentWithDescription>
          <Form.Check
            className={"mb-4 text-dark-blue"}
            type="switch"
            id="sfSynchro"
            label="Obsługa contact center"
            {...register("sfSynchro")}
          />
          <div className="col-auto mb-4">
            <Button type="submit" value="Submit">
              {editObj === null ? "Utwórz kategorię" : "Zapisz zamiany"}
            </Button>
            {editObj !== null && (
              <Button
                className="btn-outline-blue ms-2"
                onClick={() => setEditObj(null)}
              >
                Anuluj
              </Button>
            )}
          </div>
        </form>
        {!topicCaseIsLoading && (
          <div className="col bd-highlight">
            <TopicCaseTable
              data={topicCaseData?.data}
              handleEdit={handleEdit}
            />
            <CustomPagination
              totalCount={
                topicCaseData.meta?.totalResults ||
                INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
              }
              pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
              currentPage={currPage}
              onPageChange={(e: number) => handlePageChange(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
