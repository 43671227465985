import { Container, Row, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetCompanyQuery } from "redux/services/companyApi";
import { WidgetCreator } from "../../molecules/WidgetCreator/WidgetCreator";
import styles from "../../templates/LoyaltyProgramSponsorTemplate/LoyaltyProgramSponsorTemplate.module.scss";
import { CompanyLocalization } from "../../organisms/CompanyLocalization/CompanyLocalization";
import { CompanyInfo } from "../../organisms/CompanyInfo/CompanyInfo";
import { CompanySocialMedia } from "../../organisms/CompanySocialMedia/CompanySocialMedia";
import { CompanyUsers } from "../../organisms/CompanyUsers/CompanyUsers";
import { CompanyBasicInformation } from "components/organisms/CompanyBasicInformation/CompanyBasicInformation";
import DeleteCompanyButton from "components/atoms/DeleteCompanyButton/DeleteCompanyButton";

const CompanyEditTemplate = () => {
  let { id } = useParams();

  const { data: companyData, refetch } = useGetCompanyQuery(id);

  return (
    <Container fluid className="bg-light">
      <h2 className="px-2 fs-1 lh-base fw-600 text-dark me-4 pt-2">
        Edycja firmy
      </h2>
      <section className={styles.section}>
        <Stack gap={5}>
          <Row className={`${styles.wrapper}`}>
            <WidgetCreator
              nameLabel={"Dane podstawowe"}
              buttonIcon={"pencil"}
              data={companyData?.data}
              Child={CompanyBasicInformation}
            />
          </Row>
          <Row className={`${styles.wrapper}`} id={"departments"}>
            <WidgetCreator
              nameLabel={"Lokalizacje"}
              buttonName={"DODAJ PIERWSZĄ LOKALIZACJE"}
              buttonIcon={"plus"}
              data={companyData?.data}
              condition={
                companyData?.data?.companyDepartment?.length > 0 || null
              }
              Child={CompanyLocalization}
              reFetchCompany={refetch}
            />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <WidgetCreator
              nameLabel={"O firmie"}
              buttonName={"DODAJ OPIS FIRMY"}
              buttonIcon={"pencil"}
              data={companyData?.data}
              condition={companyData?.data?.description}
              Child={CompanyInfo}
            />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <WidgetCreator
              nameLabel={"Serwisy internetowe"}
              buttonName={"DODAJ STRONĘ INTERNETOWĄ"}
              buttonIcon={"plus"}
              data={companyData?.data}
              condition={companyData?.data?.socialAddresses}
              Child={CompanySocialMedia}
              reFetchCompany={refetch}
            />
          </Row>
          <Row className={`${styles.wrapper}`} id={"users"}>
            <WidgetCreator
              nameLabel={"Dostęp do profilu"}
              buttonName={"DODAJ ADMINISTRATORA"}
              buttonIcon={"plus"}
              data={companyData?.data?.companyUser}
              condition={
                companyData?.data?.companyUser?.length === 0 ? null : true
              }
              Child={CompanyUsers}
              reFetchCompany={refetch}
            />
          </Row>
        </Stack>
      </section>
      <DeleteCompanyButton data={companyData?.data} />
    </Container>
  );
};

export default CompanyEditTemplate;
