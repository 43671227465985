import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { Context } from "../../../redux/services/doctorApi";
import { useTranslation } from "react-i18next";
import { OptionFilter, SearchFiltering } from "./types";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleFiltr: (changeOptions: SearchFiltering) => void;
  status: StatusChangeType;
  context: Context | undefined;
  label?: string;
  desc?: string;
}

export const DoctorReviewFiltrModal = (props: Props) => {
  const { t } = useTranslation();
  const [searchFiltering, setSearchFiltering] = useState<SearchFiltering>({});

  useEffect(() => {
    setSearchFiltering({
      ...(props.status && {
        status: options.find(
          (option: OptionFilter) => props.status === option.value
        ),
      }),
      ...(props.context && {
        context: optionsContext.find(
          (option: OptionFilter) => props.context === option.value
        ),
      }),
    });
    // eslint-disable-next-line
  }, [props.context, props.status]);

  const options: OptionFilter[] = [
    {
      value: "",
      label: t("status_ALL"),
      type: "status",
    },
    {
      value: "AWAITING_REVIEW",
      label: t("status_AWAITING_REVIEW"),
      type: "status",
    },
    { value: "ACCEPTED", label: t("status_ACCEPTED"), type: "status" },
    { value: "REMOVED", label: t("status_REMOVED"), type: "status" },
    { value: "REJECTED", label: t("status_REJECTED"), type: "status" },
    { value: "ABUSED", label: t("status_ABUSED"), type: "status" },
  ];
  const optionsContext: OptionFilter[] = [
    {
      value: "POSITIVE",
      label: t("status_POSITIVE"),
      type: "context",
    },
    { value: "NEGATIVE", label: t("status_NEGATIVE"), type: "context" },
  ];
  const handlerSearch = () => {
    if (searchFiltering) {
      props.handleFiltr(searchFiltering);
    }
    props.handleClose();
  };
  const handlerContext = (option: OptionFilter | null) => {
    if (option === null) {
      setSearchFiltering((prevState: SearchFiltering) => {
        const newSearch: SearchFiltering = { ...prevState };
        delete newSearch.context;
        return newSearch;
      });
    } else {
      setSearchFiltering((prevState) => ({
        ...prevState,
        [option?.type]: option,
      }));
    }
  };

  const handlerStatus = (option: OptionFilter | null) => {
    if (option === null) {
      setSearchFiltering((prevState: SearchFiltering) => {
        const newSearch: SearchFiltering = { ...prevState };
        delete newSearch.status;
        return newSearch;
      });
    } else {
      setSearchFiltering((prevState) => ({
        ...prevState,
        [option?.type]: option,
      }));
    }
  };

  return (
    <Modal
      className="results-title text-dark-blue"
      show={props.show}
      onHide={props.handleClose}
      centered
      size="sm"
    >
      <Modal.Header
        closeButton
        className={"border-bottom-0 m-4 p-0 align-items-start"}
      >
        <Modal.Title className="fw-bold text-dark-blue ">
          {props.label ? props.label : (t("modal_doctor_reviews_filter_title"))}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={"mx-4 p-0"}>
        <Container className="p-0 d-flex flex-column gap-4 ">
          <Col xs={12}>
            <Form.Label htmlFor="filter">Status</Form.Label>
            <Select
              value={options.find(
                (option: OptionFilter) =>
                  option.value === searchFiltering.status?.value
              )}
              placeholder={t("modal_doctor_reviews_filter_select_placeholder1")}
              classNamePrefix="select_"
              isClearable={false}
              isSearchable={false}
              id="status"
              options={options}
              styles={customStyles(false)}
              components={{ DropdownIndicator }}
              onChange={handlerStatus}
            />
          </Col>
          <Col xs={12}>
            <Form.Label htmlFor="filter">Ocena</Form.Label>
            <Select
              value={optionsContext.find(
                (option: OptionFilter) =>
                  option.value === searchFiltering.context?.value
              )}
              placeholder={t("modal_doctor_reviews_filter_select_placeholder2")}
              classNamePrefix="select_"
              isClearable={true}
              isSearchable={false}
              id="context"
              options={optionsContext}
              styles={customStyles(false)}
              components={{ DropdownIndicator }}
              onChange={handlerContext}
            />
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer className={"border-top-0 m-4 p-0 pb-5 pb-md-0"}>
        <Container className="p-0 d-flex w-100 gap-3 justify-content-end">
          <Button
            className={"btn-outline-dark-blue"}
            onClick={props.handleClose}
          >
            {t("modal_doctor_reviews_filter_cancel_button")}
          </Button>
          <Button className={"btn-primary"} onClick={handlerSearch}>
            {t("modal_doctor_reviews_filter_submit_button")}
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
