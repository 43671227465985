import { Badge } from "react-bootstrap";
import styles from "./BadgeAwaitingReview.module.scss";

interface BadgeAwaitingReviewProps {
  text: string;
}

const BadgeAwaitingReview = ({ text }: BadgeAwaitingReviewProps) => {
  return (
    <Badge
      className={`bg-secondary text-white lh-130 d-inline-block ms-2 ${styles.badge}`}
    >
      {text}
    </Badge>
  );
};

export default BadgeAwaitingReview;
