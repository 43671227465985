import PageContainer from "components/atoms/PageContainer/PageContainer";
import { HelpContent } from "components/organisms/HelpContent/HelpContent";
import { useGetSystemSettingsQuery } from "redux/services/systemSettingsApi";
import { useGetPagesQuery } from "redux/services/pagesApi";
import styles from "./HelpTemplate.module.scss";
import { Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ChatSupportFormModal from "../../organisms/ChatSupportFormModal/ChatSupportFormModal";
import { useState } from "react";

export default function HelpTemplate() {
  const [isChatSupportFormModalVisible, setIsChatSupportFormModalVisible] =
    useState<boolean>(false);
  const { data: systemSettings } = useGetSystemSettingsQuery({});

  const faqSystemItem = systemSettings?.data?.find(
    (item: SystemSettingItem) => item.context === "faq_system",
  );

  const FALLBACK_PAGE_ID = "28";

  const pageId = faqSystemItem ? faqSystemItem.value : FALLBACK_PAGE_ID;

  const { data: pagesData } = useGetPagesQuery({
    categoryIds: pageId,
    parentNotAssigned: 1,
  });
  const { t } = useTranslation();

  return (
    <>
      <PageContainer>
        <div className={`${styles.wrapper}`}>
          <h1
            className={`fs-39 fw-600 mb-5 text-dark-blue lh-130 ${styles.title}`}
          >
            {t("help_page_title")}
          </h1>

          {pagesData?.data?.map((page: Page) => (
            <HelpContent key={page.id} pageId={page.id} />
          ))}

          <Container fluid={true} className={"p-0"}>
            <div
              className={
                "d-flex flex-column p-5 border justify-content-center align-items-center"
              }
            >
              <div className={"col-auto text-center"}>
                <span
                  className={`text-grey fs-19 fw-600 lh-130 ${styles.containerTitle}`}
                >
                  {t("help_page_span")}
                </span>
              </div>
              <div className={"col-auto mt-4"}>
                <Button
                  className={"col-auto"}
                  variant="primary"
                  onClick={() => setIsChatSupportFormModalVisible(true)}
                >
                  {t("help_page_ask_button")}
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </PageContainer>
      <ChatSupportFormModal
        show={isChatSupportFormModalVisible}
        onHide={() => setIsChatSupportFormModalVisible(false)}
        setShow={setIsChatSupportFormModalVisible}
      />
    </>
  );
}
