import {
  Company,
  CompanyDepartment,
} from "components/molecules/LoyaltyProgramServiceArticle/types";
import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import { CompanyLocalizationEntity } from "../../molecules/CompanyLocalizationEntity/CompanyLocalizationEntity";
import { CompanyLocalizationForm } from "../../molecules/CompanyLocalizationForm/CompanyLocalizationForm";
import styles from "./CompanyLocalization.module.scss";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";

interface Props {
  data: Company;
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  reFetchCompany: () => QueryActionCreatorResult<
    QueryDefinition<
      string | undefined,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      "Companies",
      any,
      "api"
    >
  >;
}

export const CompanyLocalization = (props: Props) => {
  const [dataDepartment, setDataDepartment] =
    useState<CompanyDepartment | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleChange = (department: CompanyDepartment) => {
    props.handleActive(true);
    setDataDepartment(department);
    setIsEdit(true);
  };

  const handleEdit = () => {
    setIsEdit(false);
  };

  const handleClose = () => {
    props.handleActive(false);
    if (props.data.companyDepartment.length === 0 && props.isFirst) {
      props.handleFirst(true);
    } else {
      props.handleFirst(false);
    }
    setIsEdit(false);
    setDataDepartment(null);
  };

  return (
    <Container fluid={true} className={" d-flex flex-column gap-4 p-0"}>
      {props.isActive && (
        <Col className={`col-12 border border-1 p-3 gap-3 ${styles.radius}`}>
          <CompanyLocalizationForm
            reFetchCompany={props.reFetchCompany}
            data={isEdit ? dataDepartment : null}
            disabled={false}
            companyId={dataDepartment ? null : props.data?.id}
            isFirst={props.isFirst}
            handleEdit={handleEdit}
            handleClose={handleClose}
          />
        </Col>
      )}
      {!!props.data &&
        props.data?.companyDepartment
          .filter((department: CompanyDepartment) => department.deleted === 0)
          .map((department: CompanyDepartment) => (
            <Col
              key={department.id}
              className={`col-12 border border-1 ps-3 pe-2 py-3 ${styles.radius}`}
            >
              <CompanyLocalizationEntity
                data={department}
                handleChange={handleChange}
                reFetchCompany={props.reFetchCompany}
              />
            </Col>
          ))}
    </Container>
  );
};
