import TextArea from "components/atoms/TextArea/TextArea";
import TextInput from "components/atoms/TextInput/TextInput";
import InformationWithLinks from "components/molecules/InformationWithLinks/InformationWithLinks";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styles from "./AddInformationModal.module.scss";
import { renderToString } from "react-dom/server";

const AddInformationModal = ({
  show,
  setShow,
  setContent,
}: AddInformationModalProps) => {
  const { register, watch } = useForm({
    defaultValues: {
      content: "",
      facebookLink: "",
      twitterLink: "",
      linkedinLink: "",
      mailLink: "",
      instagramLink: "",
      youtubeLink: "",
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Dodaj informację
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row gap-3">
          <div className="col-12 col-sm-8">
            <InformationWithLinks
              content={watch("content")}
              facebookLink={watch("facebookLink")}
              twitterLink={watch("twitterLink")}
              linkedinLink={watch("linkedinLink")}
              mailLink={watch("mailLink")}
              instagramLink={watch("instagramLink")}
              youtubeLink={watch("youtubeLink")}
            />

            <TextArea
              className="fs-13 mt-4"
              label="Kontent"
              row={3}
              register={register("content")}
            />
          </div>

          <div className={`${styles.modalMenu} col-12 col-sm-4  `}>
            <form className="d-flex flex-column gap-2 w-100">
              <TextInput
                register={register("facebookLink")}
                className="fs-13 flex-column "
                label="facebook link"
              />
              <TextInput
                register={register("twitterLink")}
                className="fs-13 flex-column "
                label="twitter link"
              />
              <TextInput
                register={register("linkedinLink")}
                className="fs-13 flex-column "
                label="linkedin link"
              />
              <TextInput
                register={register("mailLink")}
                className="fs-13 flex-column "
                label="mail link"
              />
              <TextInput
                register={register("instagramLink")}
                className="fs-13 flex-column "
                label="instagram link"
              />
              <TextInput
                register={register("youtubeLink")}
                className="fs-13 flex-column "
                label="youtube link"
              />
              <div className="col-auto">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setShow(false);
                    setContent &&
                      setContent(
                          renderToString(
                            <InformationWithLinks
                              content={watch("content")}
                              facebookLink={watch("facebookLink")}
                              twitterLink={watch("twitterLink")}
                              linkedinLink={watch("linkedinLink")}
                              mailLink={watch("mailLink")}
                              instagramLink={watch("instagramLink")}
                              youtubeLink={watch("youtubeLink")}
                            />
                          )
                      );
                  }}
                >
                  Dodaj informację
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddInformationModal;
