import { Table } from "react-bootstrap";
import "./LoyaltyProgramServiceCategoryTable.module.scss";
import { TableRowLoyaltyProgramServiceCategoryWithCollapse } from "../../TableRowLoyaltyProgramServiceCategoryWithCollapse/TableRowLoyaltyProgramServiceCategoryWithCollapse";
import { EditObj } from "../../../templates/LoyaltyProgramCategoryTemplate/LoyaltyProgramCategoryTemplate";

export interface LoyaltyProgramServiceCategoryData {
  id: number;
  name: string;
  priority: number;
  status: number;
  show: number;
}

interface Props {
  data: LoyaltyProgramServiceCategoryData[];
  handleEdit: (data: EditObj, now: boolean) => void;
}

export const LoyaltyProgramServiceCategoryTable = ({
  data,
  handleEdit,
}: Props) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="pb-4 text-dark-blue">
            <span>Nazwa</span>
          </th>
          <th className="pb-4 text-dark-blue">
            <span>Status</span>
          </th>
          <th className="pb-4 text-dark-blue">
            <span>Priorytet</span>
          </th>
          <th className="pb-4 text-dark-blue">
            <span>Profilowanie</span>
          </th>
        </tr>
      </thead>

      <tbody className="tableBody">
        {data?.length > 0 ? (
          data?.map(
            (
              loyaltyProgramServiceCategoryData: LoyaltyProgramServiceCategoryData,
              index: number
            ) => (
              <TableRowLoyaltyProgramServiceCategoryWithCollapse
                key={index}
                data={loyaltyProgramServiceCategoryData}
                handleEdit={handleEdit}
              />
            )
          )
        ) : (
          <tr>
            <td colSpan={3} className="text-center">
              Nie znaleziono żadnych stron.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
