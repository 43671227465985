import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import RatingRadio from "components/atoms/RatingRadio/RatingRadio";
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap";
import TooltipIcon from "../../../../assets/Icons/Tooltip.svg";
import { Form } from "react-bootstrap";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function RatingRadioInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const { register, setValue, formState, watch } = useFormContext();

  const currentRating = watch(question?.fieldName, 0);

  useEffect(() => {
    const validationMeta = question?.meta.find(
      (meta) => meta.key === "validation"
    );
    if (validationMeta) {
      const validation = JSON.parse(validationMeta.value);
      setValue(question?.fieldName, validation.defaultValue || "0", {
        shouldValidate: true,
      });
    }
  }, [question?.meta, setValue, question?.fieldName]);

  const handleRatingChange = (value: number) => {
    setValue(question?.fieldName, value.toString(), { shouldValidate: true });
  };

  const tooltip =
    question?.meta.find((meta) => meta.key === "html_tooltip")?.value || "";

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    </Tooltip>
  );

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";
  const answersMeta = question?.meta.find((meta) => meta.key === "answers");
  const possibleAnswers = answersMeta
    ? answersMeta.value.split("||").length
    : 5;

  return (
    <div
      className={`d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center border p-2 p-md-4 mt-2 mb-2 rounded col-lg-${fieldSize}`}
    >
      <div className="d-flex flex-column justify-content-center gap-2 ">
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}{" "}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
          {tooltip && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <img src={TooltipIcon} alt="tooltip" />
            </OverlayTrigger>
          )}
        </Form.Label>
        {question?.description && (
          <div
            className="mt-1 ms-1"
            dangerouslySetInnerHTML={{ __html: question?.description || "" }}
          ></div>
        )}
      </div>
      <div className="d-flex flex-column justify-content-center gap-4 mt-3 mb-2 mt-md-0 mb-md-0">
        <RatingRadio
          register={register(question?.fieldName, {
            required: requiredMessage,
          })}
          setValue={setValue}
          onButtonClick={handleRatingChange}
          currentRating={Number(currentRating)}
          numberOfPossibleAnswers={possibleAnswers}
          fieldName={question?.fieldName}
          readOnly={readOnly}
        />

        {nextPageClicked && formState.errors[question?.fieldName] && (
          <ErrorMessage
            errors={formState.errors}
            name={question?.fieldName}
            render={({ message }) => (
              <div className="text-validation">{message}</div>
            )}
          />
        )}
      </div>
    </div>
  );
}
