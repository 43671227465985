import { Dropdown } from "react-bootstrap";
import { ReactComponent as TripleDot } from "../../../assets/Icons/TripleDots.svg";
import styles from "./Chat.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setView,
  View,
  setSelectedChatTopic,
  setChatData,
  setCustomMessage,
  setSelectedAdminChatTopic,
  setAdminChatData,
  setShowMessages,
} from "redux/Slice/messages-slice";
import { useGetMeQuery } from "redux/services/meApi";
import defaultAvatar from "../../../assets/images/userAvatarImage.png";
import aliviaAvatar from "../../../assets/Icons/aliviaA.svg";
import { useMarkTopicAsReadMutation } from "redux/services/topicApi";
import moment from "moment";
import "moment/locale/pl";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store/index";
import { useState } from "react";
import { useOpenHelpTopicCustomMessage } from "Helpers/openMessageHooks";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

type ChatProps = {
  data: ChatData;
  isOnMessenger?: boolean;
};

moment.updateLocale("pl", {
  relativeTime: {
    future: "za %s",
    past: "%s temu",
    s: "kilka sekund",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1r",
    yy: "%dr",
  },
});

export const Chat = ({ data, isOnMessenger }: ChatProps) => {
  const dispatch = useDispatch();
  const [updateTopic] = useMarkTopicAsReadMutation();
  const { data: userData } = useGetMeQuery({});

  moment.locale("pl");
  const truncate = (input: string, length: number) =>
    input.length > length ? `${input.substring(0, length)}...` : input;

  let createdAt = new Date();

  if (data?.topicMessage?.length > 0) {
    createdAt = new Date(
      data.topicMessage[data.topicMessage.length - 1].createdAt
    );
  }

  let newTime = moment(createdAt).fromNow();

  const view = useSelector((state: RootState) => state?.messages?.view);

  const currentUserId = userData?.data?.id;

  let otherUser: ChatUser | null = null;

  if (data.senderUser.id === currentUserId) {
    otherUser = data.receiverUser || (isOnMessenger && data.senderUser) || null;
  } else if (data.receiverUser?.id === currentUserId) {
    otherUser = data.senderUser || null;
  } else if (isOnMessenger) {
    otherUser = data.senderUser;
  }

  const lastMessage = data.topicMessage[data.topicMessage.length - 1];
  const isUnreadMessage =
    lastMessage?.isUnreadMessage === 1 &&
    lastMessage?.senderUser?.id === otherUser?.id;

  const unreadMessagesCount = data.topicMessage.filter(
    (message) =>
      message.isUnreadMessage === 1 && message.senderUser?.id === otherUser?.id
  ).length;

  let avatarSrc;
  if (otherUser) {
    avatarSrc = otherUser.avatar?.accessUrl || defaultAvatar;
  } else {
    avatarSrc = aliviaAvatar;
  }

  async function updateToRead(id: number) {
    if (data.id === id) {
      const payload: MarkTopicAsReadPayload = {
        id: id,
        data: {
          senderUser: otherUser?.id,
        },
      };

      await updateTopic(payload);
    }
  }
  let displayMessage;

  if (unreadMessagesCount > 1 && unreadMessagesCount < 5) {
    displayMessage = `${unreadMessagesCount} nowe wiadomości`;
  } else if (unreadMessagesCount > 4) {
    displayMessage = `${unreadMessagesCount} nowych wiadomości`;
  } else {
    const lastMessage = data.topicMessage[data.topicMessage.length - 1];
    const lastMessageText = lastMessage?.message;
    const lastMessageAttachment = lastMessage?.attachment[0]?.filename;

    let messageContent =
      lastMessageText || lastMessageAttachment || "No content";

    displayMessage = truncate(
      `${
        lastMessage?.senderUser?.id === currentUserId ||
        (isOnMessenger && !lastMessage?.senderUser?.id)
          ? "Ty: "
          : ""
      }${messageContent}`,
      20
    );
  }

  const [isActive, setIsActive] = useState<boolean>(false);

  const REPORT_USER_TOPIC_ID = 8;
  const STATUS_TOPIC_HIDDEN = 0;

  const REPORT_USER_TOPIC = {
    id: REPORT_USER_TOPIC_ID,
    case: "Zgłoszenie użytkownika",
    status: STATUS_TOPIC_HIDDEN,
  };
  const CUSTOM_MESSAGE = `Chcę zgłosić użytkownika ${otherUser?.name}.`;

  const reportUser = useOpenHelpTopicCustomMessage({
    topic: REPORT_USER_TOPIC,
    customMessage: CUSTOM_MESSAGE,
  });

  const renderDropdown = () => {
    return (
      <Dropdown className="d-flex" onToggle={() => setIsActive}>
        <Dropdown.Toggle
          variant="Secondary"
          className={`ms-1 ${styles.dropdownToggle}`}
        >
          <TripleDot style={{ stroke: isActive ? "#ffffff" : "#003b50" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isUnreadMessage && (
            <Dropdown.Item onClick={() => updateToRead(data?.id)}>
              Oznacz jako przeczytane
            </Dropdown.Item>
          )}
          {otherUser && (
            <>
              <Dropdown.Item
                as={Link}
                to={`/${NAV_PATH.PROFILE}/${otherUser?.authId}`}
                onClick={() => dispatch(setShowMessages(false))}
              >
                Wyświetl profil
              </Dropdown.Item>
              <Dropdown.Item onClick={reportUser}>
                Zgłoś użytkownika
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  let content;

  if (otherUser === null && isUnreadMessage) {
    content = renderDropdown();
  } else if (otherUser === null && !isUnreadMessage) {
    content = null;
  } else if (otherUser !== null) {
    content = renderDropdown();
  }

  return (
    <div
      className="d-flex flex-row p-2 m-0 rounded-2"
      style={
        isUnreadMessage
          ? { background: "#11C0F21F" }
          : { background: "transparent" }
      }
    >
      <div
        onClick={() => {
          dispatch(!isOnMessenger ? setView(View.CHAT) : setView(view));
          dispatch(
            !isOnMessenger
              ? setSelectedChatTopic(data?.case)
              : setSelectedAdminChatTopic(data?.case)
          );
          dispatch(!isOnMessenger ? setChatData(data) : setAdminChatData(data));
          dispatch(setCustomMessage(null));
        }}
        className="d-flex justify-content-between flex-grow-1  p-0 cursor-pointer"
      >
        <div className="d-flex align-item">
          <div
            className={`px-0 d-flex justify-content-center align-items-center ${styles.chatUserAvatar}`}
          >
            <img src={avatarSrc} alt="avatar" />
          </div>
          <div
            className={`d-flex flex-column ms-2 flex-wrap p-0 m-0 ${styles.chatContent}`}
          >
            <p
              className={`d-flex flex-wrap p-0 m-0 fw-600 ${styles.chatContent}`}
            >
              {otherUser ? otherUser.name : truncate(data.case.case, 10)}
            </p>
            <p
              className={`d-flex flex-wrap p-0 m-0 text-grey fs-12 ${styles.chatContent}`}
            >
              {displayMessage}
            </p>
          </div>
        </div>
        <div
          className={`text-grey  fs-12 d-flex text-end justify-content-center align-items-center ${styles.timer}`}
        >
          {data?.topicMessage?.length > 0 ? (
            <p className="m-0">{newTime}</p>
          ) : (
            <p className="m-0">Brak wiadomości</p>
          )}
        </div>
      </div>
      {content}
    </div>
  );
};
