import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedChatTopic,
  setShowMessages,
  setView,
  View,
  setChatData,
  setCustomMessage,
  setChatUserData,
} from "redux/Slice/messages-slice";
import { RootState } from "redux/store/index";

import { useGetTopicsQuery } from "redux/services/topicApi";
import { useGetMeQuery } from "redux/services/meApi";

type DataState = { userInformation: ChatUser } | null;

type UseOpenHelpTopicMessageProps = {
  topic: ChatCase;
};
type UseOpenHelpTopicCustomMessageProps = {
  topic: ChatCase;
  customMessage: string;
};

const useOpenPrivateMessage = (userInformation: ChatUser) => {
  const { data: userData } = useGetMeQuery({});
  const userId = userData?.data?.id;

  const { data: allMessages } = useGetTopicsQuery({
    topicUserId: userId,
    limit: "0",
  });

  const dispatch = useDispatch();
  const [data, setData] = useState<DataState>(null);
  let TOPIC_CASE = { id: 1, case: "Private message", status: 0 };
  const [chatDataState, setChatDataState] = useState(null);

  useEffect(() => {
    if (userInformation) {
      setData({ userInformation });

      const existingTopic = allMessages?.data?.find(
        (msg: ChatData) =>
          (msg?.senderUser?.id === userId &&
            msg.receiverUser?.id === userInformation?.id) ||
          (msg?.senderUser?.id === userInformation?.id &&
            msg?.receiverUser?.id === userId)
      );

      if (existingTopic) {
        setChatDataState(existingTopic);
      }
    }
    // eslint-disable-next-line
  }, [userId]);

  const handleClick = () => {
    if (!data) return;

    dispatch(setShowMessages(true));
    dispatch(setView(View.CHAT));
    dispatch(setChatData(chatDataState));
    dispatch(setChatUserData(userInformation));
    dispatch(setSelectedChatTopic(TOPIC_CASE));
  };

  return handleClick;
};

const useOpenHelpMessage = () => {
  const dispatch = useDispatch();
  const showMessages = useSelector(
    (state: RootState) => state.messages.showMessages
  );

  const handleClick = () => {
    dispatch(setShowMessages(!showMessages));
    dispatch(setView(View.TOPIC));
  };

  return handleClick;
};

const useOpenHelpTopicMessage = ({ topic }: UseOpenHelpTopicMessageProps) => {
  const dispatch = useDispatch();
  const { data: userData } = useGetMeQuery({});
  const userId = userData?.data?.id;

  const { data: allMessages } = useGetTopicsQuery({
    topicUserId: userId,
    limit: "0",
  });

  const [chatDataState, setChatDataState] = useState(null);

  useEffect(() => {
    const existingTopic = allMessages?.data?.find(
      (msg: ChatData) => msg.case?.id === topic.id
    );

    if (existingTopic?.msg?.status !== 4) {
      setChatDataState(existingTopic);
    }
  }, [allMessages, topic]);

  const handleClick = () => {
    dispatch(setShowMessages(true));
    dispatch(setView(View.CHAT));
    dispatch(setChatData(chatDataState));
    dispatch(setSelectedChatTopic(topic));
  };

  return handleClick;
};

const useOpenHelpTopicCustomMessage = ({
  topic,
  customMessage,
}: UseOpenHelpTopicCustomMessageProps) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowMessages(true));
    dispatch(setView(View.CHAT));
    dispatch(setSelectedChatTopic(topic));
    dispatch(setChatData(null));
    dispatch(setCustomMessage(customMessage));
  };

  return handleClick;
};

export {
  useOpenPrivateMessage,
  useOpenHelpMessage,
  useOpenHelpTopicMessage,
  useOpenHelpTopicCustomMessage,
};
