import styles from "./PollViewTableRow.module.scss";
import Breadcrumb from "components/molecules/Breadcrumb/Breadcrumb";
import { POLL_DATA } from "Helpers/pollsData";
import { useUpdatePollMutation } from "redux/services/pollsApi";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

interface PollViewTableRowProps {
  data: PollTableData;
}

const PollViewTableRow = ({ data }: PollViewTableRowProps) => {
  const [updatePollData] = useUpdatePollMutation();

  const { t } = useTranslation();

  const updatePollStatus = async () => {
    const payload = {
      data: {
        isActive: data.isActive ? 0 : 1,
      },
    };

    const hasBeenSent = await updatePollData({
      ...payload,
      id: data.id,
    });

    if ("data" in hasBeenSent) {
      toast.success(t("toast_pollViewTableRow_success"));
    } else if ("error" in hasBeenSent) {
      const error = hasBeenSent?.error as PollSubmitError;

      if (error?.data?.statusCode === 500) {
        toast.error(t("toast_pollViewTableRow_error_500"));
      }

      if (error.data.statusCode === 404) {
        toast.error(t("toast_pollViewTableRow_error_404"));
      }

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_pollViewTableRow_error_400")} ${error.data.message[0]}`
        );
    }
  };

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.POLLS}/${data.id}/edit`,
    },
    {
      name: data.isActive === 1 ? "Dezaktywuj" : "Aktywuj",
      action: updatePollStatus,
    },
  ];

  const verificationType =
    POLL_DATA.VERIFICATION_TYPES.find(
      (vt) => vt.name === data?.verificationType
    )?.label ?? "";

  return (
    <>
      <tr aria-controls="example-collapse-text" className={styles.mainTr}>
        <td
          className={`container-fluid ${styles.mainTd} ${
            !data.isActive ? styles.inactiveTd : ""
          }`}
          colSpan={4}
        >
          <Row className="pb-2 bg-transpatent">
            <Col className="col">{data.title}</Col>
            <Col className="col">{verificationType}</Col>
            <Col className="col">{data.userRole.name}</Col>
            <Col className="col">{data.language.name}</Col>
          </Row>
          <Row className={`bg-transpatent ${styles.hoverRow}`}>
            <Col>
              <Breadcrumb data={breadcrumbData} />
            </Col>
          </Row>
        </td>
      </tr>
    </>
  );
};

export default PollViewTableRow;
