import DoctorHeader from "components/molecules/DoctorHeader/DoctorHeader";
import { WidgetCreator } from "components/molecules/WidgetCreator/WidgetCreator";
import { Container, Spinner, Button } from "react-bootstrap";
import styles from "components/pages/DoctorPage/DoctorPage.module.scss";
import { AvatarModal } from "components/organisms/AvatarModal/AvatarModal";
import { DoctorBiogram } from "components/organisms/DoctorBiogram/DoctorBiogram";
import { DoctorSpecialization } from "components/organisms/DoctorSpecialization/DoctorSpecialization";
import { DoctorWorkPlace } from "components/organisms/DoctorWorkPlace/DoctorWorkPlace";
import { useGetCurrentDoctorQuery } from "redux/services/doctorApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePatchDoctorMutation } from "redux/services/doctorApi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const DoctorPage = () => {
  const [showAvatar, setShowAvatar] = useState(false);
  const { data: doctorData, isLoading, refetch } = useGetCurrentDoctorQuery("");
  const doctorId = doctorData?.data?.id;
  const [patchDoctor] = usePatchDoctorMutation();
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (doctorData?.data?.status === "AWAITING_REVIEW") {
      setIsReadOnly(true);
    }
  }, [doctorData]);

  const handleDoctorAvatarChange = async (id: string) => {
    const payload = {
      id: Number(doctorId),
      data: { avatarId: id },
    };

    const hasBeenUpdated = await patchDoctor(payload);

    if ("data" in hasBeenUpdated) {
      toast.success(t("toast_doctorPage_success"));
      refetch();
    } else if ("error" in hasBeenUpdated) {
      const error = hasBeenUpdated?.error as DoctorAvatarUpdateError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorPage_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_doctorPage_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorPage_error_400")} ${error.data.message[0]}`
        );
    }
  };

  const handleDoctorAvatarModalShow = () => {
    setShowAvatar((prevShowAvatar) => !prevShowAvatar);
  };

  if (isLoading) {
    return (
      <Container className={"d-flex m-4 p-4 justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (doctorData?.data == null && !isLoading) {
    return (
      <Container>
        <Container
          className={`bg-white p-4 mt-4 text-dark-blue fs-16 ${styles.containerNoEntry}`}
        >
          <p>Nie masz uprawnień do przeglądania tej strony.</p>
          <Button
            onClick={() => navigate(`/${NAV_PATH.DASHBOARD}`)}
            className={`text-white ${styles.button}`}
          >
            wróć na Dashboard
          </Button>
        </Container>
      </Container>
    );
  }

  return (
    <>
      <Container fluid className={styles.container}>
        {doctorData?.data && (
          <DoctorHeader
            data={doctorData?.data || {}}
            isFirstVisit={false}
            type={"account"}
            handleChangeAvatar={() => handleDoctorAvatarModalShow()}
            isReadOnly={isReadOnly}
          />
        )}
        <Container className={`bg-white p-4 mt-5 ${styles.containerWidget}`}>
          <WidgetCreator
            nameLabel={"Specjalizacje"}
            buttonName={"DODAJ SPECJALIZACJE"}
            buttonIcon={"pencil"}
            data={doctorData?.data || {}}
            condition={doctorData?.data?.specializations?.length > 0 || null}
            Child={DoctorSpecialization}
            reFetchCompany={refetch}
            info={doctorData?.data?.specializations}
            isReadOnly={isReadOnly}
          />
        </Container>
        <Container className={`bg-white p-4 mt-5 ${styles.containerWidget}`}>
          <WidgetCreator
            nameLabel={"Biogram"}
            buttonName={"dodaj biogram"}
            buttonIcon={"pencil"}
            data={doctorData?.data || {}}
            condition={doctorData?.data?.description}
            Child={DoctorBiogram}
            reFetchCompany={refetch}
            info={doctorData?.data?.description}
            isReadOnly={isReadOnly}
          />
        </Container>
        <Container className={`bg-white p-4 mt-5 ${styles.containerWidget}`}>
          <WidgetCreator
            nameLabel={"Miejsce pracy"}
            buttonName={"dodaj miejsce pracy"}
            buttonIcon={"plus"}
            data={doctorData?.data || {}}
            condition={doctorData?.data?.facility?.length > 0 || null}
            Child={DoctorWorkPlace}
            reFetchCompany={refetch}
            info={doctorData?.data?.facility}
            isReadOnly={isReadOnly}
          />
        </Container>
      </Container>
      {showAvatar && (
        <AvatarModal
          show={true}
          types={"user-avatar"}
          handleClose={() => setShowAvatar((prevShowAvatar) => !prevShowAvatar)}
          headerTitle="Zmień zdjęcie"
          handlePatch={handleDoctorAvatarChange}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
};

export default DoctorPage;
