import { User } from "../../redux/Slice/user-slice";

export const isAuthPermission = (module: UserAccessModules, authUser: User) => {
  const PERMISSIONS = [
    {
      module: "articles",
      permittedRoles: [
        "superadmin",
        "admin",
        "patient",
        "guardian",
        "doctor",
        "sponsor",
        "noRole",
      ],
    },
    {
      module: "loyalty-program",
      permittedRoles: ["superadmin", "admin", "patient", "guardian", "sponsor"],
    },
    {
      module: "chat",
      permittedRoles: [
        "superadmin",
        "admin",
        "patient",
        "guardian",
        "doctor",
        "sponsor",
        "noRole",
      ],
    },
    {
      module: "events",
      permittedRoles: [
        "superadmin",
        "admin",
        "patient",
        "guardian",
        "sponsor",
        "noRole",
      ],
    },
    {
      module: "feed",
      permittedRoles: ["superadmin", "admin", "patient", "guardian"],
    },
  ];

  const modulePermissions = PERMISSIONS?.filter(
    (permission) => permission?.module === module
  );

  return authUser?.roles?.some((role) =>
    modulePermissions?.[0]?.permittedRoles?.includes(role?.role?.role)
  );
};
