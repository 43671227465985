import {apiSlice} from "./apiSlice";
import {searchResults} from "../../endpoints/endpoints";

interface SearchResultsData {
    searchText?: string;
    module?: string;
    offset?: string;
    limit?: string;
}

interface PostData {
    data: PostSearchResultsData;
}

interface PostSearchResultsData {
    searchText: string,
    url: string,
    module: string,
}

const apiWithTag = apiSlice.enhanceEndpoints({
    addTagTypes: ["SearchResults"],
});

export const searchResultsApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getSearchResults: builder.query({
            query: ({
                        searchText,
                        module,
                        offset,
                        limit,
                    }: SearchResultsData) => {
                const params = new URLSearchParams({
                    ...(searchText && {searchText}),
                    ...(module && {module}),
                    ...(offset && {offset}),
                    ...(limit && {limit}),
                });

                return {
                    url: `${searchResults}?${params.toString()}`,
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
            },
            providesTags: ["SearchResults"],
        }),

        postSearchResults: builder.mutation({
            query: ({data}: PostData) => {

                return {
                    url: `${searchResults}`,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: data,
                };
            },
            invalidatesTags: ["SearchResults"],
        }),
    }),
});

export const {useGetSearchResultsQuery, usePostSearchResultsMutation} = searchResultsApi;