import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./TableRowLoyaltyProgramServiceCategoryWithCollapse.module.scss";
import { LoyaltyProgramServiceCategoryData } from "../Tables/LoyaltyProgramServiceCategoryTable/LoyaltyProgramServiceCategoryTable";
import { EditObj } from "../../templates/LoyaltyProgramCategoryTemplate/LoyaltyProgramCategoryTemplate";

interface Props {
  data: LoyaltyProgramServiceCategoryData;
  handleEdit: (data: EditObj, now: boolean) => void;
}

export const TableRowLoyaltyProgramServiceCategoryWithCollapse = ({
  data,
  handleEdit,
}: Props) => {
  const breadcrumbData = [
    {
      name: "Edytuj",
      action: () => {
        handleEdit(data, false);
      },
    },
    {
      name: data.status ? "Dezaktywuj" : "Aktywuj",
      action: () => {
        handleEdit({ ...data, status: data.status === 0 ? 1 : 0 }, true);
      },
    },
  ];

  return (
    <>
      <tr aria-controls="example-collapse-text " className={styles.mainTr}>
        <td className={"text-dark-blue"}>{data.name}</td>
        <td className={"text-dark-blue"}>
          {data.status ? "Aktywny" : "Nieaktywny"}
        </td>
        <td className={"text-dark-blue"}>
          {data.priority === 10 ? "Premium" : "Normalny"}
        </td>
        <td className={"text-dark-blue"}>
          {data.show === 1 ? "Widoczna" : "Ukryta"}
        </td>
      </tr>
      <tr className={styles.hoverTr}>
        <td colSpan={4}>
          <Breadcrumb data={breadcrumbData} />
        </td>
      </tr>
    </>
  );
};
