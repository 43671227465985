import { getDateRange } from "./getDateRange";
import { format } from "date-fns";
import { EventData } from "../components/organisms/AddEventModal/types";
import { formatDateToGMT } from "./formatDateToGMT";

const formatEventDateForRange = (
  event: EventData,
  includeHourInSingleDate?: boolean
) => {
  const startingDate = new Date(event?.startingDate);
  const endingDate = new Date(event?.endingDate);

  const startingDateGMT = formatDateToGMT(startingDate);
  const endingDateGMT = formatDateToGMT(endingDate);

  const dateRange = getDateRange({
    startingDate: new Date(startingDateGMT),
    endingDate: new Date(endingDateGMT),
  });

  const isDateRange = (dateRange && dateRange?.length > 1) ?? false;

  if (isDateRange) {
    return `${format(new Date(startingDateGMT), "dd.MM.yyyy HH:mm")} - ${format(
      new Date(endingDateGMT),
      "dd.MM.yyyy HH:mm"
    )}`;
  }
  return format(
    new Date(startingDateGMT),
    `dd.MM.yyyy${includeHourInSingleDate ? " HH:mm" : ""}`
  );
};

export default formatEventDateForRange;