export const isOneOfObjectValuesEmpty = (
  object: Record<string, any>,
): boolean => {
  for (const key in object) {
    if (!object[key]) {
      return true;
    }
  }
  return false;
};
