export function transformJSONStringToArrayObjects(
  input: string | undefined,
): ValueField[] | null {
  if (typeof input !== "string") {
    return null;
  }
  const parsedInput = JSON.parse(input);

  return parsedInput.map((item: string) => ({
    value: item,
  }));
}
