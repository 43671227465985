import { enums } from "Helpers/enums";
import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./TableRowCategoryWithCollapse.module.scss";
import { useDeletePagesCategoryMutation } from "redux/services/pagesCategoryApi";

const TableRowCategoryWithCollapse = ({
  data,
  CollapseItem,
  child,
  innerChild,
}: any) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deletePagesCategory] = useDeletePagesCategoryMutation();

  const breadcrumbData = [
    {
      name: "Edytuj",
      action: () => {
        searchParams.set("edit", data.id);
        setSearchParams(searchParams);
      },
    },
    {
      name: "Szybka edycja",
      action: () => setOpen(!open),
    },
    {
      name: "Kosz",
      action: () => {
        deletePagesCategory(data.id);
      },
    },
  ];

  return (
    <Fragment>
      <tr
        aria-controls="example-collapse-text "
        aria-expanded={open}
        className={styles.mainTr}
      >
        <td>{data.title}</td>
        <td>
          {enums.categoryType[data.type as keyof typeof enums.categoryType]}
        </td>
        <td>{data.url}</td>
      </tr>
      {!open && (
        <tr className={styles.hoverTr}>
          <td colSpan={4}>
            <Breadcrumb data={breadcrumbData} />
          </td>
        </tr>
      )}

      {open && (
        <tr className={styles.collapseTr} data-active={open}>
          <td colSpan={4}>
            <CollapseItem open={open} data={data} setOpen={setOpen} />
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default TableRowCategoryWithCollapse;
