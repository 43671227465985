import { Accordion, Button, Stack } from "react-bootstrap";
import styles from "./EventsCollapse.module.scss";
import { FilterPanel } from "components/molecules/FilterPanel/FilterPanel";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/index";
import { UserRole } from "redux/Slice/user-slice";
import { AUTH_DATA } from "Helpers/Roles/authData";
interface EventsCollapseProps {
  provinces: ReactSelectOption[];
  categories: ReactSelectOption[];
  cities: ReactSelectOption[];
  types: ReactSelectOption[];
  topics: ReactSelectOption[];
  selectedTypes: string[];
  selectedCity: string[];
  selectedProvince: string[];
  selectedTopic: string[];
  selectedCategories: string[];

  onTypeChange: (value?: string) => void;
  onTopicChange: (value?: string) => void;
  onCategoryChange: (value?: string) => void;
  onCityChange: (value?: string) => void;
  onProvinceChange: (value?: string) => void;
  onFiltersReset: () => void;
  onApplyFilters: () => void;
}

const EventsCollapse = ({
  types,
  provinces,
  cities,
  topics,
  categories,
  selectedTypes,
  selectedTopic,
  selectedCategories,
  onTypeChange,
  onTopicChange,
  onCategoryChange,
  onFiltersReset,
  selectedCity,
  selectedProvince,
  onCityChange,
  onProvinceChange,
  onApplyFilters,
}: EventsCollapseProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const userRolesArray = authUser.roles.map(
    (userRole: UserRole) => userRole.role.role
  );
  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );

  return (
    <Accordion className={styles.accordion}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`${styles.header}`}>
          Filtruj nadchodzące wydarzenia
        </Accordion.Header>
        <Accordion.Body className="">
          <Stack gap={4}>
            <div className="col-12 row">
              <div className="col-12">
                <p className="fw-600 fs-12 text-grey-2 mb-4">
                  Lokalizacja wydarzeń
                </p>
              </div>
              <div className="col-lg-4 col-12 mb-4 mb-md-0">
                <FilterPanel
                  label="Województwo"
                  categories={provinces}
                  selectedCategories={selectedProvince}
                  onCategoryClick={onProvinceChange}
                  type="select"
                />
              </div>
              <div className="col-lg-4 col-12">
                <FilterPanel
                  label="Miasto"
                  categories={cities}
                  selectedCategories={selectedCity}
                  onCategoryClick={onCityChange}
                  type="select"
                />
              </div>
            </div>
            <FilterPanel
              label="Typy wydarzeń"
              categories={types}
              selectedCategories={selectedTypes}
              onCategoryClick={onTypeChange}
              labelType="gray"
            />
            {isAdmin && (
              <>
                <FilterPanel
                  label="Kategoria wydarzenia"
                  categories={categories}
                  selectedCategories={selectedCategories}
                  onCategoryClick={onCategoryChange}
                  labelType="gray"
                  type="multi-select"
                />
                <FilterPanel
                  label="Tematyka artykułu wg nowotworu"
                  categories={topics}
                  selectedCategories={selectedTopic}
                  onCategoryClick={onTopicChange}
                  labelType="gray"
                  type="select"
                />
              </>
            )}
          </Stack>
          <div className="mt-4 d-lg-flex justify-content-lg-end">
            <Button
              variant="outline-light"
              className={`me-3 mb-3 mb-lg-0 text-dark-blue ${styles.resetButton}`}
              onClick={onFiltersReset}
            >
              RESETUJ FILTRY
            </Button>
            <Button
              className={`text-white fw-500 fs-16 ${styles.submitButton}`}
              variant="primary"
              type="submit"
              value="Submit"
              onClick={onApplyFilters}
            >
              Zastosuj filtry
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EventsCollapse;
