import { Company } from "components/molecules/LoyaltyProgramServiceArticle/types";
import { companies } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Companies"],
});

export interface GetCompaniesProps {
  nameLike?: string;
  changes?: number;
  sortBy?: string;
  limit?: number;
  offset?: string;
  isSearch?: number;
  skipUser?: boolean;
}

interface PostCompanyProps {
  data: {
    name: string;
    fullName: string;
    nipProvider: string;
  };
}

export interface PatchCompanyProps {
  id?: number | string;
  data: {
    name?: string;
    fullName?: string;
    nipProvider?: string;
    socialAddresses?: string | null;
    imageId?: string;
    description?: string;
    changes?: string;
    status?: number;
    deleted?: number;
  };
}

interface DeleteCompanyProps {
  id?: string;
}

interface DeleteCompanyUserProps {
  companyId?: string;
  userId: number;
}

export interface CompaniesData {
  data: Company[];
}

export const companyApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: (id: string | undefined) => {
        return {
          url: `${companies}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Companies"],
    }),

    getCompanyCheck: builder.query({
      query: (id: string | undefined) => {
        return {
          url: `${companies}/check/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Companies"],
    }),

    getCompanies: builder.query({
      query: ({
        nameLike,
        changes,
        limit,
        offset,
        sortBy,
        isSearch,
        skipUser,
      }: GetCompaniesProps) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(nameLike && { nameLike }),
          ...(changes && { changes: String(changes) }),
          ...(sortArray && { sortBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(limit && { limit: String(limit) }),
          ...(offset && { offset }),
          ...(isSearch && { isSearch: String(isSearch) }),
          ...(skipUser && { skipUser: "1" }),
        });

        return {
          url: `${companies}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Companies"],
    }),

    postCompany: builder.mutation({
      query: ({ data }: PostCompanyProps) => ({
        url: `${companies}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),

    patchCompany: builder.mutation({
      query: ({ id, data }: PatchCompanyProps) => ({
        url: `${companies}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Companies"],
    }),

    deleteCompany: builder.mutation({
      query: ({ id }: DeleteCompanyProps) => ({
        url: `${companies}/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Companies"],
    }),

    deleteHardCompany: builder.mutation({
      query: ({ id }: DeleteCompanyProps) => ({
        url: `${companies}/${id}/hard`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Companies"],
    }),

    deleteCompanyUser: builder.mutation({
      query: ({ companyId, userId }: DeleteCompanyUserProps) => ({
        url: `${companies}/${companyId}/user/${userId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useGetCompanyCheckQuery,
  useGetCompaniesQuery,
  usePostCompanyMutation,
  usePatchCompanyMutation,
  useDeleteCompanyMutation,
  useDeleteCompanyUserMutation,
  useDeleteHardCompanyMutation,
} = companyApi;
