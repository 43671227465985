import { LoyaltyProgramServiceData } from "components/molecules/LoyaltyProgramServiceArticle/types";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { usePatchLoyaltyProgramServiceMutation } from "redux/services/loyaltyProgramApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { useNavigate } from "react-router-dom";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  data: LoyaltyProgramServiceData;
}

const DeleteServiceButton = ({ data }: Props) => {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [patchService] = usePatchLoyaltyProgramServiceMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const modalDeleteHandler = async () => {
    const hasBeenDelete = await patchService({
      id: data?.id?.toString(),
      data: {
        deleted: 1,
      },
    });

    if ("data" in hasBeenDelete) {
      toast.success(t("toast_deleteServiceButton_success"));
    } else if ("error" in hasBeenDelete) {
      toast.error(t("toast_deleteServiceButton_error"));
    }

    setShowModalDelete(false);
    navigate(`/${NAV_PATH.ADMIN}/${NAV_PATH.COMPANIES}`);
  };

  return (
    <>
      <Button
        className="btn-secondary"
        onClick={() => setShowModalDelete(true)}
      >
        Usuń benefit
      </Button>
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz usunąć benefit?`}
        smallText={`benefit: ${data?.name}`}
        type="confirm"
        show={showModalDelete}
        setShow={() => setShowModalDelete(false)}
        confirmAction={() => modalDeleteHandler()}
      />
    </>
  );
};

export default DeleteServiceButton;
