import { ReactComponent as Remove } from "../../../../assets/Icons/DeleteIcon.svg";
import { ReactComponent as Send } from "../../../../assets/Icons/send.svg";
import { ReactComponent as AddButton } from "../../../../assets/Icons/AddButton.svg";
import styles from "./ChatInput.module.scss";
import { Form } from "react-bootstrap";
import React, { RefObject, ChangeEvent, KeyboardEvent, FormEvent } from "react";
import { UseFormRegister } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { setForceSend } from "redux/Slice/messages-slice";
import { RootState } from "redux/store/index";
import { useGetMeQuery } from "redux/services/meApi";

interface ChatInputProps {
  sendMessage: (data: SentMessage) => Promise<void>;
  fileInputRef: RefObject<HTMLInputElement>;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFiles: { name: string }[];
  removeFile: (index: number) => void;
  handleKeyPress: (e: KeyboardEvent) => void;
  onInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  message: string;
  handleSubmit: (
    callback: (data: SentMessage) => Promise<void>
  ) => (e: FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<any>;
  isDisabled: boolean;
}

export default function ChatInput({
  sendMessage,
  fileInputRef,
  handleFileChange,
  isFocused,
  setIsFocused,
  selectedFiles,
  removeFile,
  handleKeyPress,
  onInput,
  message,
  handleSubmit,
  register,
  isDisabled,
}: ChatInputProps) {
  const forceSend = useSelector((state: RootState) => state.messages.forceSend);
  const dispatch = useDispatch();
  const { data: userData } = useGetMeQuery({});
  const userId = userData?.data?.id;

  function truncateFilename(filename: string, maxCharacters = 15): string {
    const fileExtension = filename.split(".").pop();
    if (filename.length <= maxCharacters || !fileExtension) {
      return filename;
    }

    const prefix = filename.substr(0, maxCharacters);
    return `${prefix}...${fileExtension}`;
  }

  const hasSentMessage = useRef(false);

  useEffect(() => {
    async function handleForceSend() {
      if (forceSend && !hasSentMessage.current && message !== "") {
        const data: SentMessage = {
          message,
          topicId: 6,
          senderUser: userId,
          hasAttachments: 0,
        };
        await sendMessage(data);
        dispatch(setForceSend(false));
        hasSentMessage.current = true;
      }
    }

    handleForceSend();
    // eslint-disable-next-line
  }, [forceSend, dispatch, userId, message]);

  return (
    <form
      onSubmit={handleSubmit(sendMessage)}
      className={`m-0 py-4 px-3 p-md-4 d-flex ${styles.chatInput}`}
    >
      <div>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          accept="application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/heic"
        />
        <button
          type="button"
          className={`p-0 ${styles.addBtn}`}
          onClick={() => fileInputRef.current?.click()}
        >
          <AddButton />
        </button>
      </div>
      <div
        className={`w-100 ${
          isFocused ? styles.inputAreaActive : styles.inputArea
        }`}
      >
        {selectedFiles.length > 0 && (
          <Form.Label className={`w-100 m-0 ${styles.attachmentsDisplay}`}>
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className={`d-flex justify-content-between mb-2`}
              >
                {truncateFilename(file?.name)}
                <Remove
                  className={`${styles.close}`}
                  onClick={() => removeFile(index)}
                />
              </div>
            ))}
          </Form.Label>
        )}
        <Form.Control
          as="textarea"
          {...register("message")}
          className={`form-control fs-16 ${styles.textarea} ${
            selectedFiles.length > 0
              ? styles["textarea--selectedFiles"]
              : styles["textarea--noSelectedFiles"]
          }`}
          placeholder=""
          onInput={onInput}
          onKeyPress={(e) => {
            handleKeyPress(e);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={isDisabled}
        />
      </div>
      {(message !== "" || selectedFiles.length > 0) && (
        <button type="submit" className={`ms-12 ${styles.sendBtn}`}>
          <Send />
        </button>
      )}
    </form>
  );
}
