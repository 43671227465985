import { ReactNode } from "react";
import styles from "./ArticleCard.module.scss";
import PlaceholderImg from "../../../assets/images/AliviaPlaceholder.png";
import { Badge, Card } from "react-bootstrap";
import { BookmarkIcon } from "components/atoms/BookmarkIcon/BookmarkIcon";
import { capitalizeString } from "Helpers/capitalizeString";
import { getTruncateDescription } from "Helpers/getTruncateDescription";

interface ArticleCardProps {
  imgSrc?: ReactNode;
  articleSrc?: string;
  categories?: string | string[];
  isArticleSaved?: boolean;
  onIconClick: React.MouseEventHandler<SVGSVGElement>;
  link: string;
  title: string;
  description: string;
  isPending: boolean;
}

export const ArticleCard = ({
  imgSrc,
  articleSrc,
  categories,
  isArticleSaved,
  onIconClick,
  link,
  title,
  description,
  isPending,
}: ArticleCardProps) => {
  const displayedCategories = !categories
    ? undefined
    : typeof categories === "string"
    ? capitalizeString(categories)
    : categories
        .map((category: string) => capitalizeString(category?.trim()))
        .slice(0, 3)
        .join(", ");

  const truncatedDescription = getTruncateDescription(description, 97);

  return (
    <Card as="article" className={`border-2 border ${styles.card}`}>
      <div className="position-relative">
        <Card.Img
          className={`border-2 border-bottom  ${styles.img}`}
          variant="top"
          src={imgSrc || PlaceholderImg}
        />
        <Badge
          className={`position-absolute top-0 start-0 mt-12 ms-2 fs-12 fw-semibold lh-130 text-white bg-dark-blue py-0 px-2`}
        >
          {articleSrc ?? ""}
        </Badge>
      </div>
      <Card.Body className={`mx-2`}>
        <Card.Subtitle
          as="p"
          className="d-flex justify-content-between align-items-center mb-12 fs-14 lh-130 text-grey"
        >
          <span>{displayedCategories}</span>
          <BookmarkIcon
            isFill={isArticleSaved || false}
            onClick={onIconClick}
            isPending={isPending}
          />
        </Card.Subtitle>
        <Card.Title
          as="h3"
          className={`mb-5 fs-19 fw-semibold lh-130 ls-3 text-dark-blue text-wrap ${styles.titleAfter}`}
        >
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Card.Title>
        <Card.Text
          as="p"
          className={`fs-14 fw-400 lh-130 text-dark-blue text-wrap mb-3`}
        >
          {truncatedDescription}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
