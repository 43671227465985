import { useState } from "react";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./ArticleTableRow.module.scss";
import { NAV_PATH } from "../Navigation/navigationData";

interface ArticleTableRowProps {
  data: ArticleRss;
  QuickEditCollapse: React.FC<{
    open: boolean;
    data: ArticleRss;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>;
}

const ArticleTableRow = ({ data, QuickEditCollapse }: ArticleTableRowProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const breadcrumbData = [
    {
      name: "Edytuj",
      path: `/${NAV_PATH.ADMIN}/${NAV_PATH.ARTICLES}/${data.id}/edit`,
    },
    {
      name: "Szybka edycja",
      action: () => setOpen(!open),
    },
    {
      name: "Kosz",
      action: () => {},
    },
    {
      name: "Zobacz",
      action: () => {},
    },
  ];

  return (
    <>
      <tr
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className={styles.mainTr}
      >
        <td>{data.name}</td>
        <td>{data.url}</td>
        <td>{data.type}</td>
        <td>{data.categoryNames}</td>
      </tr>
      {!open && (
        <tr className={styles.hoverTr}>
          <td colSpan={4}>
            <Breadcrumb data={breadcrumbData} />
          </td>
        </tr>
      )}

      {open && (
        <tr className={styles.collapseTr} data-active={open}>
          <td colSpan={4}>
            <QuickEditCollapse open={open} data={data} setOpen={setOpen} />
          </td>
        </tr>
      )}
    </>
  );
};

export default ArticleTableRow;
