import { useGetUsersQuery, UsersProps } from "../../../redux/services/usersApi";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import SectionTitle from "../../atoms/SectionTitle/SectionTitle";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import { UsersTable } from "../../organisms/Tables/UsersTable/UsersTable";
import Select, { SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { useGetUserRolesQuery } from "../../../redux/services/userRolesApi";
import SortButton from "../../organisms/SortButton/SortButton";
import { USER_DATA } from "Helpers/usersData";

const INITIAL_SEARCH_PARAMS: InitialSearchParamsUser = {
  PAGE: 1,
  LIMIT: 20,
  OFFSET: "0",
  TOTAL_RESULTS: 0,
  SORT_BY: "createdAt,DESC",
};

const data: SortInterface[] = [
  { type: "name", label: "Nazwa użytkownika" },
  { type: "email", label: "E-mail" },
  { type: "createdAt", label: "Data utworzenia" },
];

export const UsersTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.PAGE
  );

  const { data: rolesData, isLoading: rolesIsLoading } = useGetUserRolesQuery(
    {}
  );

  const { data: usersData, isLoading } = useGetUsersQuery({
    limit: INITIAL_SEARCH_PARAMS.LIMIT.toString(),
    offset: currPage
      ? ((currPage - 1) * INITIAL_SEARCH_PARAMS.LIMIT).toString()
      : "0",
    nameLike: searchParams.get("nameLike") || "",
    status: searchParams.get("status") || "",
    deleted: searchParams.get("deleted") || "",
    enabled: searchParams.get("enabled") || "",
    roleId: searchParams.get("roleId") || "",
    sortBy: searchParams.get("sortBy") || INITIAL_SEARCH_PARAMS.SORT_BY,
  });

  useEffect(() => {
    setValue("nameLike", searchParams.get("nameLike") || "");
    setValue("status", searchParams.get("status") || "");
    setValue("deleted", searchParams.get("deleted") || "");
    setValue("enabled", searchParams.get("enabled") || "");
    setValue("roleId", searchParams.get("roleId") || "");
    setValue(
      "sortBy",
      searchParams.get("sortBy") || INITIAL_SEARCH_PARAMS.SORT_BY
    );
    // eslint-disable-next-line
  }, [searchParams]);

  const { register, watch, setValue, handleSubmit, control } =
    useForm<UsersProps>({
      defaultValues: {
        nameLike: "",
        status: "",
        deleted: "",
        enabled: "",
        roleId: "",
        sortBy: INITIAL_SEARCH_PARAMS.SORT_BY,
      },
    });

  if (isLoading || rolesIsLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const rolesOptions: ReactSelectOption[] = rolesData?.userRoles.map(
    (roleOption: Role): ReactSelectOption => ({
      value: roleOption.id.toString(),
      label: roleOption.name,
    })
  );

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setCurrPage(page);
  };

  const handleSort = (orderBy: OrderByChanges) => {
    const sortBy = watch("sortBy") || "";
    const [order, sort] = sortBy.split(",");

    if (order === orderBy) {
      setValue("sortBy", sort === "ASC" ? `${orderBy},DESC` : `${orderBy},ASC`);
    } else {
      setValue("sortBy", `${orderBy},ASC`);
    }
  };

  const handleFilters = (filterData: UsersProps) => {
    setCurrPage(1);

    searchParams.set("nameLike", filterData.nameLike || "");
    searchParams.set("status", filterData.status || "");
    searchParams.set("deleted", filterData.deleted || "");
    searchParams.set("enabled", filterData.enabled || "");
    searchParams.set("roleId", filterData.roleId || "");
    searchParams.set("sortBy", filterData.sortBy || "");

    setSearchParams(searchParams);
  };
  
  const handleClear = () => {
    searchParams.delete("nameLike");
    searchParams.delete("status");
    searchParams.delete("deleted");
    searchParams.delete("enabled");
    searchParams.delete("roleId");
    searchParams.delete("sortBy");
    setSearchParams(searchParams);
  };

  const labelStyles = "mb-1 fs-14 lh-base text-dark-blue";
  return (
    <Container as="section" fluid className="px-4 pt-2 bg-light">
      <div className="d-flex align-items-center">
        <SectionTitle className="me-4">Użytkownicy</SectionTitle>
      </div>
      <Form
        className="row justify-content-between align-items-end g-3 mb-lg-5"
        onSubmit={handleSubmit(handleFilters)}
      >
        <Col xs={12}>
          <Form.Group>
            <Form.Label className={labelStyles} htmlFor="pollsTitleFilter">
              Wyszukaj
            </Form.Label>
            <Form.Control
              className={"text-dark-blue"}
              type="text"
              id="nameLike"
              {...register("nameLike")}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={3}>
          <Form.Label className={labelStyles} htmlFor="pollsTitleFilter">
            Rola
          </Form.Label>
          <Controller
            name={"roleId"}
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={rolesOptions.find(
                  (selectOption: ReactSelectOption) =>
                    selectOption.value === value
                )}
                classNamePrefix="select"
                placeholder="Wszystkie"
                isClearable={true}
                isSearchable={true}
                id="roleId"
                options={rolesOptions}
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value)
                }
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            )}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Form.Label className={labelStyles} htmlFor="status">
            Status
          </Form.Label>
          <Controller
            name={"status"}
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={
                  USER_DATA.STATUSES.find(
                    (booleanOption: ReactSelectOption) =>
                      booleanOption.value === value
                  ) ?? null
                }
                classNamePrefix="select"
                placeholder="Wszystkie"
                isClearable={true}
                isSearchable={true}
                id="status"
                options={USER_DATA.STATUSES}
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value)
                }
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            )}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Form.Label className={labelStyles} htmlFor="deleted">
            Konto
          </Form.Label>
          <Controller
            name={"deleted"}
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={
                  USER_DATA.ACCOUNT_STATUSES.find(
                    (booleanOption: ReactSelectOption) =>
                      booleanOption.value === value
                  ) ?? null
                }
                classNamePrefix="select"
                placeholder="Wszystkie"
                isClearable={true}
                isSearchable={true}
                id="deleted"
                options={USER_DATA.ACCOUNT_STATUSES}
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value)
                }
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            )}
          />
        </Col>
        <Col xs={12} lg={3}>
          <Form.Label className={labelStyles} htmlFor="enabled">
            Status konta
          </Form.Label>
          <Controller
            name={"enabled"}
            control={control}
            rules={{
              required: false,
            }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={
                  USER_DATA.KEYCLOAK_STATUSES.find(
                    (booleanOption: ReactSelectOption) =>
                      booleanOption.value === value
                  ) ?? null
                }
                classNamePrefix="select"
                placeholder="Wszystkie"
                isClearable={true}
                isSearchable={true}
                id="enabled"
                options={USER_DATA.KEYCLOAK_STATUSES}
                onChange={(option: SingleValue<ReactSelectOption>) =>
                  onChange(option?.value)
                }
                styles={customStyles(false)}
                components={{ DropdownIndicator }}
              />
            )}
          />
        </Col>
        <div
          className={
            "d-flex flex-column flex-lg-row flex-wrap justify-content-start"
          }
        >
          {data.map((value: SortInterface, index: number) => {
            const sortBy = watch("sortBy") || "";
            const sort = sortBy.split(",");

            return (
              <SortButton
                key={index}
                label={value.label}
                onClick={() => handleSort(value.type)}
                isArrowRotated={sort[0] === value.type && sort[1] === "ASC"}
                className={`pt-3 pb-3 pb-lg-0 pe-3 fs-16 ${
                  sort[0] === value.type ? "text-dark-blue" : ""
                }`}
              />
            );
          })}
        </div>
        <Col className="d-flex justify-content-end mb-3 mb-lg-0 gap-3">
          <Button className="btn-outline-blue" onClick={handleClear}>
            wyczyść
          </Button>
          <Button className="btn-outline-blue" type="submit" value="Submit">
            wyszukaj
          </Button>
        </Col>
      </Form>

      <UsersTable data={usersData.data} />

      <CustomPagination
        totalCount={
          usersData?.meta?.total || INITIAL_SEARCH_PARAMS.TOTAL_RESULTS
        }
        pageSize={INITIAL_SEARCH_PARAMS.LIMIT}
        currentPage={currPage}
        onPageChange={handlePageChange}
        paginationClassName="text-nowrap"
      />
    </Container>
  );
};
