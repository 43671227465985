import { RSS_TYPES, STATUSES } from "Helpers/articlesEnumData";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetCountriesQuery } from "redux/services/countriesApi";
import ArticleRssForm from "../ArticleRssForm/ArticleRssForm";

interface ArticleFormCardProps {
  data: ArticleData;
}

const ArticleFormCard = ({ data }: ArticleFormCardProps) => {
  const linkParams = useParams();
  const action = linkParams?.id ? "PATCH" : "POST";

  const { data: countries } = useGetCountriesQuery({});

  const activeCountries: Country[] = countries?.data?.filter(
    (c: Country) => c.active
  );

  const header = action === "PATCH" ? "Edytuj kanał RSS" : "Dodaj kanał RSS";

  return (
    <Card>
      <Card.Header>
        <h3 className="m-0 fs-4 fw-semibold lh-130 ls-3 text-dark-blue">
          {header}
        </h3>
      </Card.Header>
      <Card.Body>
        <ArticleRssForm
          data={data}
          action={action}
          rssTypes={RSS_TYPES}
          countries={activeCountries}
          statuses={STATUSES}
        />
      </Card.Body>
    </Card>
  );
};

export default ArticleFormCard;
