import { Badge, Button, Form, Image, Row } from "react-bootstrap";
import React, { useState } from "react";
import styles from "./DoctorReview.module.scss";
import Avatar from "../../../assets/Icons/Avatar.svg";
import { ReactComponent as Clock } from "../../../assets/Icons/Clock.svg";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import { ReplyIcon } from "../../atoms/ReplyIcon/ReplyIcon";
import { changeDateFormatNames } from "../../../Helpers/changeDateFormatNames";
import { RejectModal } from "../../organisms/RejectModal/RejectModal";
import {
  PostDoctorReviewProps,
  PatchDoctorReviewProps,
  usePatchDoctorReviewMutation,
  usePostDoctorReviewAbuseMutation,
  usePostDoctorReviewMutation,
} from "../../../redux/services/doctorApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import PopupModal from "../PopupModal/PopupModal";
import { SubmitHandler, useForm } from "react-hook-form";
import { DoctorReviewData } from "./types";
import RatingBadge from "../RatingBadge/RatingBadge";
import StarRating from "../../atoms/StarRating/StarRating";
import { useTranslation } from "react-i18next";

interface Props {
  data: DoctorReviewData;
  refetch: any;
  parent: boolean;
  profileAccept: boolean;
}

export const DoctorReview = ({
  data,
  refetch,
  parent,
  profileAccept,
}: Props) => {
  const { t } = useTranslation();
  const { authUser } = useSelector((state: RootState) => state.user);
  const [replyStatus, setReplyStatus] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showDeletedModal, setShowDeletedModal] = useState<boolean>(false);
  const [postDoctorReviewAbuse] = usePostDoctorReviewAbuseMutation();
  const [patchDoctorReview] = usePatchDoctorReviewMutation();
  const [postDoctorReview] = usePostDoctorReviewMutation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<PostDoctorReviewProps>({
    defaultValues: {
      content: "",
      addedByUserId: authUser.id,
      doctorId: data.doctor.id,
      systemId: data.system.id,
      deleted: 0,
      status: "AWAITING_REVIEW",
      parentId: data.id,
    },
  });

  const {
    handleSubmit: patchHandleSubmit,
    setValue: patchSetValue,
    register: patchRegister,
    formState: { errors: patchErrors },
  } = useForm<PatchDoctorReviewProps>({
    defaultValues: {
      content: data.content,
    },
  });

  const handleDeleted = async () => {
    const result = await patchDoctorReview({
      id: data.id,
      data: {
        deleted: 1,
      },
    });
    setShowDeletedModal(false);

    if ("data" in result) {
      toast.success(t("toast_doctorReview_delete_success"));
      refetch();
    } else if ("error" in result) {
      const error = result?.error as TranslationSubmitError;

      switch (error.data.statusCode) {
        case 500:
          toast.error(t("toast_doctorReview_delete_error_500"));
          break;
        case 400:
          toast.error(t("toast_doctorReview_delete_error_400"));
          break;
        default:
          toast.error(t("toast_doctorReview_delete_error"));
          break;
      }
    }
  };

  const handleComments = async (content: string) => {
    const result = await postDoctorReviewAbuse({
      data: {
        reviewId: data.id,
        content,
      },
    });
    if ("data" in result) {
      toast.success(t("toast_doctorReview_report_success"));
      refetch();
    } else if ("error" in result) {
      const error = result?.error as TranslationSubmitError;

      switch (error.data.statusCode) {
        case 500:
          toast.error(t("toast_doctorReview_report_error_500"));
          break;
        case 400:
          toast.error(t("toast_doctorReview_report_error_400"));
          break;
        default:
          toast.error(t("toast_doctorReview_report_error"));
          break;
      }
    }
  };

  const submitHandler: SubmitHandler<PostDoctorReviewProps> = async (data) => {
    if (data.content) {
      const result = await postDoctorReview({ data });
      if ("data" in result) {
        toast.success(t("toast_doctorReview_verification_success"));
        setValue("content", "");
        refetch();
      } else if ("error" in result) {
        const error = result?.error as TranslationSubmitError;

        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_doctorReview_verification_error_500"));
            break;
          case 400:
            toast.error(t("toast_doctorReview_verification_error_400"));
            break;
          default:
            toast.error(t("toast_doctorReview_verification_error"));
            break;
        }
      }
      setReplyStatus(false);
    } else {
      toast.error(t("toast_doctorReview_description_error"));
    }
  };

  const submitHandlerPatch: SubmitHandler<PatchDoctorReviewProps> = async (
    dataPatch
  ) => {
    if (data.content) {
      const result = await patchDoctorReview({ id: data.id, data: dataPatch });
      if ("data" in result) {
        toast.success(t("toast_doctorReview_corrected_success"));
        setValue("content", "");
        refetch();
      } else if ("error" in result) {
        const error = result?.error as TranslationSubmitError;

        switch (error.data.statusCode) {
          case 500:
            toast.error(t("toast_doctorReview_corrected_error_500"));
            break;
          case 400:
            toast.error(t("toast_doctorReview_corrected_error_400"));
            break;
          default:
            toast.error(t("toast_doctorReview_corrected_error"));
            break;
        }
      }
      setIsEdit(false);
      refetch();
    } else {
      toast.error(t("toast_doctorReview_description_error"));
    }
  };

  return (
    <div className={"d-flex text-dark-blue"}>
      <Image
        roundedCircle={true}
        className={`border border-1 border-gray bg-light my-3 d-none d-lg-inline-block ${styles.image}`}
        src={data.addedByUser?.avatar?.accessUrl || Avatar}
      />
      <div className={"ms-3 w-100 "}>
        <div
          className={`bg-light p-3 border border-1 border-gray alivia-radius mb-3 mt-2`}
        >
          <div className="d-lg-flex justify-content-between mb-2">
            <div className={"d-lg-flex align-items-center gap-2"}>
              <div className={"fw-semibold fs-14"}>
                {data.name || data.addedByUser?.name || "Anonim"}
              </div>
              <div className="d-flex align-items-center text-grey-2 fs-12 mt-2 mt-lg-0">
                <Clock />
                <p className="d-flex ms-1">
                  {changeDateFormatNames(data?.createdAt)}
                  <span className={"ps-1"}>{t("widget_doctor_review_ago_span")}</span>
                </p>
              </div>
            </div>
            {authUser.id === data.addedByUser?.id ? (
              <div
                className={`d-flex align-items-center justify-content-end gap-3`}
              >
                <div
                  className={`d-lg-flex d-column align-items-center cursor-pointer ${styles.dangerHover}`}
                  onClick={() => setIsEdit((prev) => !prev)}
                >
                  <Pencil className={styles.info} />
                  <span className={"d-none d-lg-block fs-12 ms-1"}>
                    {t("widget_doctor_review_edit_button")}
                  </span>
                </div>
                <div
                  className={`d-flex align-items-center cursor-pointer ${styles.dangerHover}`}
                  onClick={() => setShowDeletedModal((prev) => !prev)}
                >
                  <TrashBlank className={styles.info} />
                  <span className={"d-none d-lg-block fs-12 ms-1"}>
                    {t("widget_doctor_review_delete_button")}
                  </span>
                  <PopupModal
                    text={t("modal_doctor_review_confirm_delete_text")}
                    type="confirm"
                    show={showDeletedModal}
                    setShow={setShowDeletedModal}
                    confirmAction={handleDeleted}
                  />
                </div>
              </div>
            ) : (
              <div className={`d-flex align-items-center `}>
                {data.status !== "ABUSED" && data.deleted === 0 && (
                  <>
                    <div
                      className={`d-flex align-items-center ${styles.dangerHover}`}
                    >
                      {data.rating && (
                        <div className="d-flex align-items-center mb-md-1 mt-2 mt-lg-0">
                          <RatingBadge
                            rating={Number(
                              parseFloat(data.rating ?? "0.0").toFixed(1)
                            )}
                          />

                          <div
                            className={`border-end pe-3 ${styles.ratingBorder}`}
                          >
                            <StarRating rating={Number(data.rating)} />
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          "d-lg-flex d-none align-items-center cursor-pointer ps-3"
                        }
                        onClick={() => setShowReject((prev) => !prev)}
                      >
                        <Info className={styles.info} />
                        <span className={"d-none d-lg-block ps-1 fs-12"}>
                          {t("widget_doctor_review_report_button")}
                        </span>
                      </div>
                    </div>
                    <RejectModal
                      label={t("modal_doctor_review_confirm_report_label")}
                      desc={t("modal_doctor_review_confirm_report_desc")}
                      show={showReject}
                      handleClose={() => setShowReject((prev) => !prev)}
                      handleComments={handleComments}
                      comments={""}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          {isEdit ? (
            <Form
              className={"d-flex flex-column w-100"}
              onSubmit={patchHandleSubmit(submitHandlerPatch)}
            >
              <Form.Control
                as="textarea"
                className={"text-dark-blue"}
                rows={5}
                {...patchRegister("content", {
                  required: { value: true, message: (t("widget_doctor_review_form_control_message1")) },
                })}
                isInvalid={Boolean(errors.content)}
              />
              {patchErrors.content && (
                <Form.Control.Feedback type={"invalid"}>
                  {patchErrors.content.message}
                </Form.Control.Feedback>
              )}
              <div className="d-flex gap-2 mt-4 justify-content-end">
                <Button
                  className="btn-outline-dark-blue"
                  onClick={() => patchSetValue("content", data.content)}
                >
                  {t("form_doctor_review_undo_button")}
                </Button>
                <Button
                  className="btn-outline-dark-blue"
                  onClick={() => setIsEdit((prevState) => !prevState)}
                >
                  {t("form_doctor_review_cancel_button")}
                </Button>
                <Button type="submit" value="Submit">
                  {t("form_doctor_review_save_button")}
                </Button>
              </div>
            </Form>
          ) : (
            <>
              {data.deleted === 1 ? (
                <Badge className="fs-10 fw-semibold lh-130 ls-3 text-white bg-secondary mb-3 me-3">
                  {t("status_deleted")}
                </Badge>
              ) : (
                <>
                  <Badge
                    className={`fs-10 fw-semibold lh-130 ls-3 text-white ${
                      data.status === "ACCEPTED" ? "bg-success" : "bg-secondary"
                    } mb-3`}
                  >
                    {t(`status_${data.status}`).toLowerCase()}
                  </Badge>
                </>
              )}
              <div className={"mb-3"}>{data.content}</div>
            </>
          )}
          {parent && profileAccept && (
            <>
              <div
                className={`col-10 col-md-11 mx-0 px-0`}
                onClick={() => setReplyStatus(!replyStatus)}
              >
                <div
                  className={`${
                    replyStatus && "bg-primary"
                  } cursor-pointer d-flex flex-wrap align-items-center rounded-1 justify-content-start mx-0 p-1 col-auto ${
                    styles.commentReplyAction
                  }`}
                >
                  <span className="d-flex justify-content-start px-0 mx-0">
                    <ReplyIcon fill={!replyStatus ? "#6C757D" : "#fff"} />
                  </span>
                  <span
                    className="px-0 mx-2 fw-500 fs-12"
                    style={
                      !replyStatus ? { color: "#6C757D" } : { color: "#fff" }
                    }
                  >
                    {t("widget_doctor_review_reply_button")}
                  </span>
                </div>
              </div>
              {replyStatus && (
                <Row className="my-3 d-flex justify-content-end col-12 px-0 mx-0">
                  <Form
                    className={"d-flex flex-column w-100"}
                    onSubmit={handleSubmit(submitHandler)}
                  >
                    <Form.Control
                      as="textarea"
                      className={"text-dark-blue"}
                      rows={5}
                      {...register("content", {
                        required: { value: true, message: (t("widget_doctor_review_form_control_message1")) },
                      })}
                      isInvalid={Boolean(errors.content)}
                    />
                    {errors.content && (
                      <Form.Control.Feedback type={"invalid"}>
                        {errors.content.message}
                      </Form.Control.Feedback>
                    )}
                    <div className="d-flex gap-2 mt-4 justify-content-end">
                      <Button
                        className="btn-outline-dark-blue"
                        onClick={() =>
                          setReplyStatus((prevState) => !prevState)
                        }
                      >
                        {t("form_doctor_review_cancel_button")}
                      </Button>
                      <Button type="submit" value="Submit">
                        {t("widget_doctor_review_send_button")}
                      </Button>
                    </div>
                  </Form>
                </Row>
              )}
            </>
          )}
        </div>
        {parent &&
          data.child?.map((reviewData: DoctorReviewData) => (
            <DoctorReview
              data={reviewData}
              refetch={refetch}
              parent={false}
              profileAccept={profileAccept}
            />
          ))}
      </div>
    </div>
  );
};
