import { today } from "../../../Helpers/dateHelpers";
import styles from "./DateTimePicker.module.scss";

const DateTimePicker = ({
  value,
  defaultValue,
  disabled,
  setValue,
  name,
  label,
  className,
  type,
}: DateTimePickerProps) => {
  const handleCategoryInputChange = (
    newValue: React.ChangeEvent<HTMLInputElement>
  ) => {
    return setValue(name, newValue.target.value);
  };

  return (
    <label
      className={`form-check-label col-auto d-flex ${
        type === "EventInput"
          ? " fs-16 fw-400 mt-3 flex-column"
          : "align-items-center"
      } ${className}`}
    >
      {label && (
        <span
          className="me-3 mb-1 text-dark-blue"
          style={type === "EventInput" ? { width: "100%" } : { width: 120 }}
        >
          {label}
        </span>
      )}
      <input
        className={`form-control text-dark-blue ${styles.dateTimePicker}`}
        type="datetime-local"
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        min={new Date(today).toISOString().slice(0, 16)}
        onChange={handleCategoryInputChange}
      />
    </label>
  );
};

export default DateTimePicker;
