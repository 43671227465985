import styles from "./EventCard.module.scss";
import PlaceholderImg from "../../../assets/images/Alivia_EventCoverPlaceholder.svg";
import { Button, Card, Row } from "react-bootstrap";
import { capitalizeString } from "Helpers/capitalizeString";
import { useNavigate } from "react-router";
import { EventCardProps } from "./types";
import { EventAdress } from "components/organisms/AddEventModal/types";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { Link } from "react-router-dom";
import { convertHtmlToText } from "Helpers/convertHtmlToText";
import formatEventDateForRange from "../../../Helpers/formatEventDateForRange";

export const EventCard = ({ data, noShadow }: EventCardProps) => {
  const navigate = useNavigate();
  
  const categoriesNames = data?.categories?.map((category: EventCategory) =>
    capitalizeString(category?.title)
  );

  const addressObject: EventAdress = 
    data?.address?.startsWith("{") ? JSON.parse(data?.address) : undefined;

  const formattedAddress = addressObject
    ? `${addressObject?.street || ""} ${addressObject?.houseNumber || ""}${
        addressObject?.apartmentNumber
          ? " / " + addressObject?.apartmentNumber
          : ""
      }`
    : data?.address;

  const eventInfo = [
    formatEventDateForRange(data, true),
    ...categoriesNames,
    data?.province?.name,
    data?.city?.name,
    formattedAddress,
  ].filter(Boolean);

  const eventInfoLastIndex = eventInfo.length - 1;

  const openEventPage = () => {
    window.scrollTo(0, 0);
    navigate(`/${NAV_PATH.EVENTS}/${data?.id}`);
  };

  const renderEventInfo = (info: string | undefined, index: number) => {
    if (!info) return null;
    const isLast = index === eventInfoLastIndex;
    return (
      <span
        key={index}
        className={`${styles.EventInfo} ${index === 0 ? styles.first : ""} ${
          isLast ? styles.last : ""
        } ${index === 0 && isLast ? "border-0 p-0" : ""}`}
      >
        {info}
      </span>
    );
  };

  const renderEventInfoForMobile = (
    info: string | undefined,
    index: number,
    array: (string | undefined)[]
  ) => {
    if (!info) return null;
    
    let label = "";
    switch (index) {
      case 0:
        label = "Data";
        break;
      case 1:
        label = "Kategoria";
        break;
      case 2:
        label = "Miejsce";
        break;
      default:
        label = "";
        break;
    }

    const isLastElement = index === array.length - 1;
    const borderClass = isLastElement ? "" : "border-bottom";

    return (
      <p
        key={index}
        className={`py-2 d-flex justify-content-between align-items-center ${borderClass} m-0`}
      >
        <span className="fs-14 fw-300 text-grey lh-130">{label}</span>
        <span className="fs-14 fw-400 lh-130 text-dark-blue">{info}</span>
      </p>
    );
  };

  const eventDescription = convertHtmlToText(data?.description);
  const truncatedDescription =
    eventDescription.length > 208
      ? `${eventDescription.substring(0, 205)}...`
      : eventDescription;

  return (
    <Card className={!noShadow ? `border-2 border ${styles.card}` : `border-1 border`}>
      <Row>
        <div className="col-md-3">
          <Card.Img
            className={styles.img}
            variant="top"
            src={data?.cover?.accessUrl || PlaceholderImg}
          />
        </div>
        <div className="col-md-9 position-relative">
          <Card.Body>
            <Card.Title className={`mb-4 mt-1 fs-19 fw-semibold lh-130 ls-3 text-dark-blue ${styles.titleAfter}`}>
              <Link to={`/${NAV_PATH.EVENTS}/${data?.id}`}>{data?.name}</Link>
            </Card.Title>
            <Card.Subtitle className="mt-1 mb-4 d-none d-md-inline">
              {eventInfo.map(renderEventInfo)}
            </Card.Subtitle>
            <Card.Subtitle className="mt-1 mb-4 d-inline d-md-none">
              {eventInfo.map(renderEventInfoForMobile)}
            </Card.Subtitle>
            <Card.Text as="p" className="d-none d-md-block mt-4 mb-3 text-dark-blue">
              {truncatedDescription}
            </Card.Text>
            <Button
              variant="outline-primary"
              onClick={openEventPage}
              className={`mt-3 ${styles.ReadMoreButton}`}
            >
              CZYTAJ WIĘCEJ
            </Button>
          </Card.Body>
        </div>
      </Row>
    </Card>
  );
};