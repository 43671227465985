import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

export default function RangeInput({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}: InputComponentProps) {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
    watch,
  } = useFormContext();

  const watchedValue = watch(question?.fieldName, "");

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const validationValues = validationMeta
    ? JSON.parse(validationMeta.value)
    : null;
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const validation = {
    min: validationValues ? Number(validationValues.min) : 0,
    max: validationValues ? Number(validationValues.max) : 0,
    step: validationValues ? Number(validationValues.step) : 1,
    isRequired: validationValues ? validationValues.isRequired : false,
    requiredMessage: validationValues ? validationValues.requiredMessage : "",
  };
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <Form.Range
        {...register(question?.fieldName, {
          required: requiredMessage,
        })}
        className={`form-range ${
          errors[question?.fieldName] ? "input-error" : ""
        }`}
        value={watchedValue || ""}
        min={validation.min}
        max={validation.max}
        step={validation.step}
        disabled={readOnly}
      />
      <div className="mt-1">Twoja odpowiedź: {watchedValue}</div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
}
