import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

const TelInput: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}) => {
  const {
    register,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
  } = useFormContext();

  const validationMeta = question?.meta?.find((m) => m.key === "validation");

  let validationOptions = {};
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  if (validationMeta) {
    const parsedValidationMetaValue = JSON.parse(validationMeta.value);
    let patternString = "";

    if (parsedValidationMetaValue && parsedValidationMetaValue.pattern) {
      patternString = parsedValidationMetaValue.pattern.slice(1, -1);
      patternString = patternString.replace(/d/g, "\\d").replace(/\+/g, "\\+");
    }

    const pattern = new RegExp(patternString);
    validationOptions = {
      required: parsedValidationMetaValue.requiredMessage,
      pattern: {
        value: pattern,
        message: parsedValidationMetaValue.patternMessage,
      },
    };
  }

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  useEffect(() => {
    const value = getValues(question?.fieldName);
    setValue(question?.fieldName, value, { shouldValidate: true });
  }, [getValues, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <Form.Control
        {...register(question?.fieldName, validationOptions)}
        type="tel"
        defaultValue={getValues(question?.fieldName)}
        isInvalid={isSubmitted && !!errors[question?.fieldName]}
        disabled={readOnly}
      />
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default TelInput;
