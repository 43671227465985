import React, { useState, useEffect } from "react";
import styles from "./BookmarkIcon.module.scss";

interface BookmarkIconProps {
  fill?: string;
  isFill: boolean;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  id?: string;
  stroke?: string;
  fillToggle?: boolean;
  isFeedType?: boolean;
  isPending?: boolean;
  isMeFetching?: boolean;
}

export const BookmarkIcon = ({
  fill,
  isFill,
  onClick,
  id,
  stroke,
  fillToggle,
  isFeedType,
  isPending,
  isMeFetching,
  ...props
}: BookmarkIconProps) => {
  const [isFilled, setIsFilled] = useState<boolean>(isFill);
  const fillColor = fill ?? "#11C0F2";
  const fillValue = isFilled ? fillColor : "none";
  const strokeColor = stroke ? stroke : "#11C0F2";

  useEffect(() => {
    isFill ? setIsFilled(isFill) : setIsFilled(false);
  }, [isFill]);

  const handleClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    onClick && onClick(e);
  };
  if (isFeedType) {
    return (
      <div
        className={`p-1 rounded-2 ${isFilled ? "bg-dark-blue" : ""} ${
          styles.feedBookmarkSaved
        }`}
      >
        <svg
          width={32}
          height={32}
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClick}
          id={id}
          {...props}
          style={{ cursor: "pointer" }}
          className={isPending ? styles.pendingBookmark : ""}
        >
          <path
            d="M9 10.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C10.52 7 11.08 7 12.2 7h7.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C23 8.52 23 9.08 23 10.2v11.69c0 1.005 0 1.508-.208 1.778a1 1 0 0 1-.755.388c-.34.013-.75-.28-1.567-.863L16 20l-4.47 3.193c-.817.584-1.226.876-1.567.863a1 1 0 0 1-.755-.388C9 23.398 9 22.895 9 21.89V10.2Z"
            fill={fillValue}
            stroke={isFilled ? "white" : strokeColor}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            id={id}
            {...props}
          />
        </svg>
      </div>
    );
  } else {
    return (
      <svg
        width={32}
        height={32}
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClick}
        id={id}
        {...props}
        style={{ cursor: "pointer" }}
        className={isPending ? styles.pendingBookmark : ""}
      >
        <path
          d="M9 10.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C10.52 7 11.08 7 12.2 7h7.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C23 8.52 23 9.08 23 10.2v11.69c0 1.005 0 1.508-.208 1.778a1 1 0 0 1-.755.388c-.34.013-.75-.28-1.567-.863L16 20l-4.47 3.193c-.817.584-1.226.876-1.567.863a1 1 0 0 1-.755-.388C9 23.398 9 22.895 9 21.89V10.2Z"
          fill={fillValue}
          stroke={strokeColor}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          id={id}
          {...props}
        />
      </svg>
    );
  }
};
