import Badge from "react-bootstrap/Badge";
import styles from "./CustomBadge.module.scss";

interface CustomBadgeProps {
    variant?: string;
    bg?: string;
    fw?: string;
    desc: string;
}

const CustomBadge = ({
                         variant = "h1",
                         bg = "",
                         fw = "medium",
                         desc = "badge"
                     }: CustomBadgeProps) => {
    const CustomTag = `${variant}` as keyof JSX.IntrinsicElements;
    const bgClassName = `bg-${bg}`;
    const fwClassName = `fw-${fw}`;

    return (
        <CustomTag as="h1" className="m-0 ms-md-2 me-2 px-0">
            <Badge className={`${bgClassName} ${fwClassName} ${styles.badge}`}>{desc}</Badge>
        </CustomTag>
    );
};

export default CustomBadge;