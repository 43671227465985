import { capitalizeString } from "Helpers/capitalizeString";
import { Button } from "react-bootstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import { EventInfoProps } from "../EventInfo/types";
import { Link } from "react-router-dom";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

export const EventHeader = ({
  eventData,
  isPrivate,
  editEvent,
  deleteEvent,
  isAdmin,
}: EventInfoProps) => {
  return (
    <div className="mb-5 p-0">
      <Link
        className="fs-16 text-primary fw-500 py-2 mb-4 d-flex flex-row align-items-center"
        to={`/${NAV_PATH.EVENTS}`}
      >
        <ChevronLeft className="me-3" />
        WRÓĆ DO KALENDARZA
      </Link>
      <h1 className="fw-600 text-dark-blue mb-2">{eventData?.name}</h1>
      <h3 className="mt-1 text-dark-blue fw-600 fs-14 mb-4">
        {eventData?.categories?.map(
          (category: EventCategory, index: number) =>
            capitalizeString(category?.title) +
            `${eventData?.categories.length - 1 === index ? "" : ", "}`
        )}
      </h3>
      {(isPrivate || isAdmin) && (
        <div className="d-flex flex-row align-items-center">
          <Button
            variant="outline-primary"
            className="btn-alivia w-auto me-4"
            onClick={editEvent}
          >
            Edytuj wydarzenie
          </Button>
          <Button
            variant="outline-primary"
            className="btn-alivia w-auto"
            onClick={deleteEvent}
          >
            Usuń wydarzenie
          </Button>
        </div>
      )}
    </div>
  );
};
