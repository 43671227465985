import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import Yes from "../../../../assets/Icons/yes.svg";
import YesClicked from "../../../../assets/Icons/yesClicked.svg";
import YesFocus from "../../../../assets/Icons/yesFocus.svg";
import YesFocusClicked from "../../../../assets/Icons/yesFocusClicked.svg";
import No from "../../../../assets/Icons/no.svg";
import NoClicked from "../../../../assets/Icons/noClicked.svg";
import NoFocus from "../../../../assets/Icons/noFocus.svg";
import NoFocusClicked from "../../../../assets/Icons/noFocusClicked.svg";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

enum Answer {
  Yes = "yes",
  No = "no",
}

const BooleanRadio: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}) => {
  const {
    register,
    formState: { errors, isSubmitted },
    watch,
    setValue,
  } = useFormContext();

  const validationMeta = question?.meta?.find((m) => m.key === "validation");

  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  const selectedAnswer = watch(question?.fieldName);
  const [isFocused, setIsFocused] = useState<{ [k in Answer]?: boolean }>({});

  const getIcon = (ans: Answer) => {
    const focusState = isFocused[ans];
    if (selectedAnswer === undefined) {
      return ans === Answer.Yes ? Yes : No;
    }
    switch (ans) {
      case Answer.Yes:
        return selectedAnswer === Answer.Yes
          ? focusState
            ? YesFocusClicked
            : YesClicked
          : focusState
          ? YesFocus
          : Yes;
      case Answer.No:
        return selectedAnswer === Answer.No
          ? focusState
            ? NoFocusClicked
            : NoClicked
          : focusState
          ? NoFocus
          : No;
    }
  };

  const options = [
    { value: Answer.Yes, icon: getIcon(Answer.Yes), alt: "Yes", label: "Tak" },
    { value: Answer.No, icon: getIcon(Answer.No), alt: "No", label: "Nie" },
  ];

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <div className="d-flex gap-5">
        {options.map(({ value, icon, alt, label }) => (
          <div key={label}>
            <Form.Control
              {...register(question?.fieldName, {
                required: requiredMessage,
              })}
              type="radio"
              id={`${question?.fieldName}-${value}`}
              name={question?.fieldName}
              value={value}
              style={{ display: "none", appearance: "none" }}
              onFocus={() => setIsFocused({ ...isFocused, [value]: true })}
              onBlur={() => setIsFocused({ ...isFocused, [value]: false })}
              isInvalid={!!errors[question?.fieldName]}
              disabled={readOnly}
            />
            <Form.Label
              className="d-flex align-items-center justify-content-center cursor-pointer"
              htmlFor={`${question?.fieldName}-${value}`}
            >
              <img src={icon} alt={alt} className="me-2" />
              {label}
            </Form.Label>
          </div>
        ))}
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default BooleanRadio;
