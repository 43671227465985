import React, { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone, { useDropzone } from "react-dropzone";
import FormRange from "react-bootstrap/FormRange";
import { ReactComponent as Search } from "assets/Icons/Search.svg";
import { ReactComponent as ArrowNarrowUpMove } from "assets/Icons/ArrowNarrowUpMove.svg";
import { ReactComponent as ImageSquare } from "assets/Icons/ImageSquare.svg";
import { ReactComponent as TrashBlank } from "assets/Icons/TrashBlank.svg";
import styles from "./LogoCreator.module.scss";
import { Button } from "react-bootstrap";

interface Props {
  handlePicture: (value: File) => void;
  cropRef: any;
}

export function bytesToMegaOrKiloBytes(bytes: number): string {
  const kilo = bytes / 1024;
  if (kilo < 1024) {
    return `${kilo.toFixed(2)} KB`;
  } else {
    return `${(kilo / 1024).toFixed(2)} MB`;
  }
}

export const LogoCreator = (props: Props) => {
  const [image, setImage] = useState<string | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    setImage(image);
  }, [image]);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageDataUrl = reader.result as string;
      setImage(imageDataUrl);
    };

    reader.readAsDataURL(file);
  };

  const {
    acceptedFiles,
    isDragActive,
    open,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    maxSize: 5242880,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/tiff": [".tif", ".tiff"],
    },
    onDrop,
  });

  useEffect(() => {
    setFile(acceptedFiles[0] || null);
    props.handlePicture(acceptedFiles[0] || null);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  const removeImage = () => {
    setImage(null);
    acceptedFiles.splice(0, acceptedFiles.length);
    fileRejections.splice(0, fileRejections.length);
    getRootProps({ onClick: (event) => event.stopPropagation() });
    getInputProps();
  };

  const handleSlider = (value: any) => {
    setZoom(Number(value.target.value));
  };

  return (
    <div className={"w-100"}>
      {image && file ? (
        <div className={"d-flex flex-column"}>
          <div className={`${styles.labelAvatar}`}>
            <Dropzone
              onDrop={(dropped: any[]) => setImage(dropped[0])}
              noClick
              noKeyboard
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className={"d-flex justify-content-center"}
                  {...getRootProps()}
                >
                  <AvatarEditor
                    ref={props.cropRef}
                    width={160}
                    height={160}
                    image={image}
                    borderRadius={160 / 2}
                    scale={zoom}
                    color={[233, 236, 239, 0.8]}
                    backgroundColor={"#ffffff"}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <div className={"d-flex align-items-center py-3"}>
            <div className={"pe-3 d-flex align-items-center"}>
              <Search />
            </div>
            <FormRange
              className={styles.slider}
              aria-valuenow={zoom}
              value={zoom}
              step={0.1}
              min={0.5}
              max={2}
              onChange={handleSlider}
            />
          </div>
          <div className={`${styles.labelFile} p-3 text-dark-blue`}>
            <div className={"d-flex align-items-center"}>
              <ImageSquare />
              <div className={"d-flex flex-column ps-2 w-100"}>
                <div className={`fs-12 ${styles.breakLine}`}>
                  {file.name || ""}
                </div>
                <div className={"fs-10 text-grey-2"}>
                  {bytesToMegaOrKiloBytes(file.size) || ""}
                </div>
              </div>
            </div>
            <Button
              className="d-flex justify-content-center align-items-center p-2 m-0 text-dark-blue"
              variant={"transparent"}
              onClick={removeImage}
            >
              <TrashBlank />
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${styles.labelAvatar} d-flex flex-column p-4`}
            {...getRootProps()}
            data-dragactive={isDragActive}
          >
            <Button
              className="btn-primary d-flex justify-content-center align-items-center mb-2 px-4"
              onClick={open}
            >
              <ArrowNarrowUpMove />
              <span className={"ps-2 fs-16"}>PRZEGLĄDAJ PLIKI</span>
            </Button>
            <span>lub przeciągnij plik na pole</span>
            <input
              {...getInputProps()}
              accept="image/jpeg, image/png, image/tiff"
            />
          </div>
          <span className={"fs-12 text-grey"}>
            Akceptowane formaty: jpg, png
          </span>
        </>
      )}
    </div>
  );
};
