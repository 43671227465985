import { Company } from "components/molecules/LoyaltyProgramServiceArticle/types";
import CompanyViewTableRow from "components/organisms/CompanyViewTableRow/CompanyViewTableRow";
import { Col, Row, Table } from "react-bootstrap";

interface Props {
  data?: Company[];
}

const CompaniesTable = ({ data }: Props) => {
  const thStyles = "container-fluid pb-4 border border-0 text-dark-blue";

  return (
    <Table>
      <thead>
        <tr>
          <th className={thStyles}>
            <Row>
              <Col xs={2}>ID</Col>
              <Col xs={3}>Nazwa</Col>
              <Col xs={4}>NIP</Col>
              <Col xs={2}>Status firmy</Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((company) => (
          <CompanyViewTableRow key={company?.id} data={company} />
        ))}
      </tbody>
    </Table>
  );
};

export default CompaniesTable;
