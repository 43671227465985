import React, { useEffect, useState } from "react";
import { Container, Badge, ProgressBar, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./DoctorRatings.module.scss";
import StarRating from "../../atoms/StarRating/StarRating";
import { useGetSystemSettingsQuery } from "../../../redux/services/systemSettingsApi";
import { useGetDoctorReviewStatsQuery } from "redux/services/statsApi";
import { Link } from "react-router-dom";
import { NAV_PATH } from "../Navigation/navigationData";

interface DoctorRatingsProps {
  data?: DoctorRatingData;
  doctorId?: number | undefined;
}

interface DoctorRatingData {
  doctorId?: number;
  totalRatings?: number;
  avgRating?: number;
  positiveCount?: string;
  percent?: number;
}

const DoctorRatings = ({ data, doctorId }: DoctorRatingsProps): JSX.Element => {
  const { t } = useTranslation();
  const { data: settingsData } = useGetSystemSettingsQuery({});
  let doctorReviewDaysFromSettings = "";
  if (settingsData && settingsData.data) {
    const objWithId33 = settingsData.data.find(
      (item: { id: number }) => item.id === 33
    );
    if (objWithId33) {
      doctorReviewDaysFromSettings = objWithId33.value.trim();
    }
  }

  const [days, setDays] = useState<number | null>(null);
  const [todayDate, setTodayDate] = useState<string | null>(null);

  useEffect(() => {
    const todayDateWithoutISO = new Date();
    const todayDateString = todayDateWithoutISO.toISOString();
    setTodayDate(todayDateString);

    let doctorReviewDaysFromSettingsNumber = Number(
      doctorReviewDaysFromSettings
    );
    const threeMonthsAgo = new Date(todayDateWithoutISO);
    threeMonthsAgo.setMonth(
      threeMonthsAgo.getMonth() - doctorReviewDaysFromSettingsNumber
    );

    if (isNaN(doctorReviewDaysFromSettingsNumber)) {
      doctorReviewDaysFromSettingsNumber = 0;
    }

    const differenceInMillis = Math.abs(
      todayDateWithoutISO.getTime() - threeMonthsAgo.getTime()
    );
    const seconds = differenceInMillis / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = Math.floor(hours / 24);

    setDays(days);
  }, [doctorReviewDaysFromSettings]);

  const { data: doctorReviewStats } = useGetDoctorReviewStatsQuery({
    doctorId: doctorId ? doctorId.toString() : "",
    days: days ? days.toString() : "0",
    dateTo: todayDate ?? "",
  });
  const monthsDoctorReviewStats = doctorReviewStats?.data;

  const getBadgeClass = (avgRating: number) => {
    if (avgRating <= 5.0 && avgRating >= 4.0) {
      return `bg-success justify-content-center align-items-center  ${styles.badge}`;
    } else if (avgRating < 4.0 && avgRating >= 2.5) {
      return `bg-warning justify-content-center align-items-center ${styles.badge}`;
    } else if (avgRating < 2.5 && avgRating !== 0.0) {
      return `bg-secondary justify-content-center align-items-center ${styles.badge}`;
    }

    return `bg-light justify-content-center ${styles.badge}`;
  };

  const getPercentClass = (percent: number) => {
    if (percent <= 100 && percent >= 75) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-success`;
    } else if (percent < 75 && percent >= 50) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-warning`;
    } else if (percent < 50 && percent !== 0) {
      return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-secondary`;
    }

    return `${styles.PercentIndicatorUpper} fs-16 fw-600 bg-light`;
  };

  const avgRating = data?.avgRating ?? 0.0;
  const badgeClass = getBadgeClass(avgRating);

  const avgRatingInThreeMonths = monthsDoctorReviewStats?.avgRating ?? 0.0;
  const badgeClassInThreeMonths = getBadgeClass(avgRatingInThreeMonths);

  const percent = data?.percent ?? 0;
  const percentClass = getPercentClass(percent);

  const percentThreeMonths = monthsDoctorReviewStats?.percent ?? 0;
  const percentClassThreeMonths = getPercentClass(percentThreeMonths);
  const percentValue = isNaN(monthsDoctorReviewStats?.percent)
    ? 0
    : monthsDoctorReviewStats?.percent ?? 0;
  const firstPercentValue = isNaN(Number(data?.percent))
    ? 0
    : data?.percent ?? 0;

  return (
    <>
      <Container
        fluid
        className={`p-4 containerWithShadow`}
        key={data?.doctorId}
      >
        <Col className={"d-flex"}>
          <h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>
            {t("widget_doctor_ratings_title")}
          </h2>
          <Link
            to={`/${NAV_PATH.DOCTOR}/${doctorId}`}
            target={"_blank"}
            className="px-1 ms-2 mt-1 text-primary d-none d-lg-block fs-16 fw-400"
          >
            {t("widget_doctor_ratings_link")}
          </Link>
        </Col>
        <p className={`m-0 fs-12 fw-600 pb-3 ${styles.paragraph}`}>
          {t("widget_doctor_ratings_p")}
        </p>
        {data?.avgRating ? (
          <Container fluid className={`p-4 my-2 ${styles.wrapper}`}>
            <div className={`d-flex ${styles.containerRatingAndOpinions}`}>
              <div className={`d-flex align-items-center mb-lg-0 mb-3`}>
                <Badge className={`d-none d-lg-flex ${badgeClass}`}>
                  {Number(data?.avgRating).toFixed(1)}
                </Badge>
                <div
                  className={`d-flex flex-column px-lg-4 ${styles.starsContainer}`}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center`}
                  >
                    <Badge
                      className={`d-lg-none me-2 me-lg-0 pb-1 ${badgeClass}`}
                    >
                      {Number(data?.avgRating).toFixed(1)}
                    </Badge>
                    <StarRating rating={data?.avgRating} />
                  </div>
                  <p
                    className={`fw-400 fs-12 text-left text-lg-center p-0 m-1 mt-lg-2 mt-1 ${
                      data?.totalRatings === undefined ||
                      data?.totalRatings === 0
                        ? "text-grey-2"
                        : "text-dark-blue"
                    }`}
                  >
                    {t("widget_doctor_ratings_source_p")}
                    <span className={`ps-1`}>{data?.totalRatings} </span>
                    {data?.totalRatings === 1
                      ? t("widget_doctor_ratings_source_single_span")
                      : t("widget_doctor_ratings_source_plural_span")}
                    )
                  </p>
                </div>
              </div>
              <div
                className={`position-relative w-100 m-0 px-lg-4 mx-lg-4 pe-2 ${styles.OpinionsCounter}`}
              >
                <p className={`fw-400 fs-12 text-dark-blue mt-3 mt-lg-0`}>
                  {t("widget_doctor_ratings_source_p2")}
                  <span className={`ps-lg-1 px-4 ${styles.totalRatings}`}>
                    {data?.positiveCount} / {data?.totalRatings}
                  </span>
                </p>
                <ProgressBar
                  variant={`dark`}
                  now={data?.percent}
                  className={`${styles.progressBar}`}
                  label={
                    <div
                      style={{ left: `${data?.percent}%`, color: "#fff" }}
                      className={percentClass}
                    >
                      {`${firstPercentValue}%`}
                    </div>
                  }
                />
              </div>
            </div>
          </Container>
        ) : (
          <Container fluid className={`p-4 my-2 ${styles.wrapper}`}>
            <p
              className={`fw-400 fs-16 text-dark-blue p-0 m-0 ${styles.paragraph}`}
            >
              {t("widget_doctor_ratings_source_no_ratings_p")}
            </p>
          </Container>
        )}
        <p className={`fs-12 fw-600 pt-4 pb-3 ${styles.paragraph}`}>
          {t("widget_doctor_ratings_last_p_part1")}{" "}
          {doctorReviewDaysFromSettings}{" "}
          {t("widget_doctor_ratings_last_p_part2")}
        </p>
        {monthsDoctorReviewStats ? (
          <Container fluid className={`p-4 my-2 ${styles.wrapper}`}>
            <div className={`d-flex ${styles.containerRatingAndOpinions}`}>
              <div className={`d-flex align-items-center mb-lg-0 mb-3`}>
                <Badge
                  className={`d-none d-lg-flex ${badgeClassInThreeMonths}`}
                >
                  {Number(monthsDoctorReviewStats?.avgRating).toFixed(1)}
                </Badge>
                <div
                  className={`d-flex flex-column px-lg-4 ${styles.starsContainer}`}
                >
                  <div
                    className={`d-flex justify-content-center align-items-center`}
                  >
                    <Badge
                      className={`d-lg-none me-2 me-lg-0 pb-1 ${badgeClassInThreeMonths}`}
                    >
                      {Number(monthsDoctorReviewStats?.avgRating).toFixed(1)}
                    </Badge>
                    <StarRating rating={monthsDoctorReviewStats?.avgRating} />
                  </div>
                  <p
                    className={`fw-400 fs-12 text-left text-lg-center p-0 m-1 mt-lg-2 mt-1 ${
                      monthsDoctorReviewStats?.totalRatings === undefined ||
                      monthsDoctorReviewStats?.totalRatings === 0
                        ? "text-grey-2"
                        : "text-dark-blue"
                    }`}
                  >
                    {t("widget_doctor_ratings_source_p")}
                    <span className={`ps-1`}>
                      {monthsDoctorReviewStats?.totalRatings}{" "}
                    </span>
                    {monthsDoctorReviewStats?.totalRatings === "1"
                      ? t("widget_doctor_ratings_source_single_span")
                      : t("widget_doctor_ratings_source_plural_span")}
                    )
                  </p>
                </div>
              </div>
              <div
                className={`position-relative w-100 m-0 px-4 mx-4 ${styles.OpinionsCounter}`}
              >
                <p className={`fw-400 fs-12 text-dark-blue mt-3 mt-lg-0`}>
                  {t("widget_doctor_ratings_source_p2")}
                  <span className={`ps-lg-1 px-4 ${styles.totalRatings}`}>
                    {monthsDoctorReviewStats?.positiveCount} /{" "}
                    {monthsDoctorReviewStats?.totalRatings}
                  </span>
                </p>
                <ProgressBar
                  variant={`dark`}
                  now={percentValue}
                  className={`w-100 ${styles.progressBar}`}
                  label={
                    <div
                      style={{ left: `${percentValue}%`, color: "#fff" }}
                      className={percentClassThreeMonths}
                    >
                      {`${percentValue}%`}
                    </div>
                  }
                />
              </div>
            </div>
          </Container>
        ) : (
          <Container fluid className={`p-4 my-2 ${styles.wrapper}`}>
            <p
              className={`fw-400 fs-16 text-dark-blue p-0 m-0 ${styles.paragraph}`}
            >
              {t("widget_doctor_ratings_last_source_no_ratings_p_part1")}{" "}
              {doctorReviewDaysFromSettings}{" "}
              {t("widget_doctor_ratings_last_source_no_ratings_p_part2")}
            </p>
          </Container>
        )}
      </Container>
    </>
  );
};

export default DoctorRatings;
