import { getResponse } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Get Response"],
});

interface GetGetResponseSearchParams {
  campaignId: string;
  emailLike: string;
}

interface GetWebinarsResponseSearchParams {
  status: string;
  page: string;
  perPage: string;
}

interface DeleteWebinarSearchParams {
  id: string;
}

export interface PostGetResponsePayload {
  data: {
    name: string;
    surname: string;
    email: string;
    campaignId: string;
  };
}

export const getResponseApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    postGetResponse: builder.mutation({
      query: ({ data }: PostGetResponsePayload) => ({
        url: `${getResponse}/contacts`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Get Response"],
    }),

    getGetResponse: builder.query({
      query: ({ emailLike, campaignId }: GetGetResponseSearchParams) => {
        const params = new URLSearchParams({
          ...(emailLike && { emailLike }),
          ...(campaignId && { campaignId }),
        });

        return {
          url: `${getResponse}/contacts?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Get Response"],
    }),

    getWebinars: builder.query({
      query: ({ status, page, perPage }: GetWebinarsResponseSearchParams) => {
        const params = new URLSearchParams({
          ...(status && { status }),
          ...(page && { page }),
          ...(perPage && { perPage }),
        });

        return {
          url: `${getResponse}/webinars?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Get Response"],
    }),

    deleteContact: builder.mutation({
      query: ({ id }: DeleteWebinarSearchParams) => {
        return {
          url: `${getResponse}/contacts/${id}`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Get Response"],
    }),
  }),
});

export const {
  usePostGetResponseMutation,
  useGetGetResponseQuery,
  useGetWebinarsQuery,
  useDeleteContactMutation,
} = getResponseApi;
