import styles from "./LandingHeader.module.scss";
import logo from "../../../assets/images/AliviaPlaceholderLogo2.png";
import krs from "../../../assets/images/krs.png";

export default function LandingHeader() {
  return (
    <div className={`${styles.head}`}>
      <div
        className={`ps-4 pe-4 pt-4 pb-4 d-flex justify-content-between justify-content-md-start align-items-center  ${styles.container}`}
      >
        <img
          src={logo}
          className={`${styles.logo} me-md-5`}
          alt="Alivia Logo"
        />
        <a href="https://alivia.org.pl/wspieraj/1procent/">
          <img
            src={krs}
            className={`${styles.logo} me-0 me-md-2`}
            alt="KRS Logo"
          />
        </a>
        <a
          href="https://alivia.org.pl/wspieraj/1procent/"
          className={`d-none d-md-inline ${styles.krs} me-lg-5 fw-500 fs-14`}
        >
          KRS 0000358654
        </a>
      </div>
    </div>
  );
}
