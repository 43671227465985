import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import { Button, Form, Row } from "react-bootstrap";
import styles from "../CompanySocialMediaEntity/CompanySocialMediaEntity.module.scss";
import Select, { SingleValue } from "react-select";
import { ListComponent } from "../ListAddedAndDelete/ListAddedAndDelete";
import React, { useEffect, useState } from "react";
import { useGetProvincesQuery } from "../../../redux/services/provincesApi";
import { useGetCitiesQuery } from "../../../redux/services/citiesApi";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import {
  usePatchCompanyDepartmentMutation,
  usePostCompanyDepartmentMutation,
} from "../../../redux/services/companyDepartmentApi";
import { toast } from "react-toastify";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import TooltipIcon from "../../atoms/TooltipIcon";
import { CompanyDepartment } from "../LoyaltyProgramServiceArticle/types";
import { transformJSONStringToArrayObjects } from "../../../Helpers/TransformObjects/transformJSONStringToArrayObjects";
import { transformJSONArrayObjectsToString } from "../../../Helpers/TransformObjects/transformJSONArrayObjectsToString";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

interface Props {
  reFetchCompany: () =>
    | QueryActionCreatorResult<
        QueryDefinition<
          string | undefined,
          BaseQueryFn<
            string | FetchArgs,
            unknown,
            FetchBaseQueryError,
            {},
            FetchBaseQueryMeta
          >,
          "Companies",
          any,
          "api"
        >
      >
    | false;
  disabled: boolean;
  data: CompanyDepartment | null;
  companyId: number | null;
  isFirst: boolean;
  handleEdit?: () => void;
  steps?: { step: number; maxStep: number };
  handleCloseAndRestart?: () => void;
  handleClose?: () => void;
  handleNextStep?: () => void;
}

export const CompanyLocalizationForm = (props: Props) => {
  const [valueCity, setValueCity] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [valueProvince, setValueProvince] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const { t } = useTranslation();

  const [
    patchCompanyDepartment,
    { isSuccess: patchIsSuccess, error: patchError },
  ] = usePatchCompanyDepartmentMutation();
  const [
    postCompanyDepartment,
    { isSuccess: postIsSuccess, error: postError },
  ] = usePostCompanyDepartmentMutation();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<CompanyDepartmentFormData>({
    defaultValues: {
      provinceId: props.data?.province.id
        ? String(props.data?.province.id)
        : "",
      cityId: props.data?.city.id ? String(props.data?.city.id) : "",
      name: props.data?.name || props.isFirst ? "Siedziba firmy" : "",
      teryt: props.data?.teryt || "",
      street: props.data?.street || "",
      apartmentNumber: props.data?.apartmentNumber || null,
      houseNumber: props.data?.houseNumber || "",
      additionalDescription: props.data?.additionalDescription || null,
      phoneNumbers: props.data?.phoneNumbers || '[""]',
      phNumbers: transformJSONStringToArrayObjects(
        props.data?.phoneNumbers,
      ) || [{ value: "" }],
      emailAddresses: props.data?.emailAddresses || '[""]',
      emAddresses: transformJSONStringToArrayObjects(
        props.data?.emailAddresses,
      ) || [{ value: "" }],
      status: props.data?.status || 0,
      companyId: props.companyId || "",
      zipCode: props.data?.zipCode || "",
    },
  });

  const {
    fields: phoneFields,
    append: phoneAppend,
    remove: phoneRemove,
  } = useFieldArray({
    name: "phNumbers",
    control,
  });

  const {
    fields: emailFields,
    append: emailAppend,
    remove: emailRemove,
  } = useFieldArray({
    name: "emAddresses",
    control,
  });

  useEffect(() => {
    if (props.data) {
      setValue("provinceId", String(props.data.province.id));
      setValue("cityId", String(props.data.city.id));
      setValue("name", props.data.name);
      setValue("teryt", props.data.teryt);
      setValue("street", props.data.street);
      setValue("apartmentNumber", props.data.apartmentNumber || null);
      setValue("houseNumber", props.data.houseNumber);
      setValue("additionalDescription", props.data.additionalDescription);
      setValue("phoneNumbers", props.data.phoneNumbers);
      setValue("emailAddresses", props.data.emailAddresses);
      setValue("status", props.data.status);
      setValue("zipCode", props.data.zipCode);
      setValue("companyId", props.companyId || "");
      setValue(
        "phNumbers",
        transformJSONStringToArrayObjects(props.data?.phoneNumbers) || [
          { value: "" },
        ],
      );
      setValue(
        "emAddresses",
        transformJSONStringToArrayObjects(props.data?.emailAddresses) || [
          { value: "" },
        ],
      );
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    if (postIsSuccess || patchIsSuccess) {
      if (props.reFetchCompany) {
        props.reFetchCompany();
      }
      if (props.handleEdit) {
        props.handleEdit();
      }
      if (props.handleClose) {
        props.handleClose();
      }
    }

    if (patchIsSuccess) {
      toast.success(t("toast_companyLocalizationForm_update_success"));
    } else if (postIsSuccess) {
      toast.success(t("toast_companyLocalizationForm_add_success"));
    } else if (patchError || postError) {
      toast.error(t("toast_companyLocalizationForm_error"));
    }
    // eslint-disable-next-line
  }, [postIsSuccess, postError, patchIsSuccess, patchError]);

  const { data: provincesData } = useGetProvincesQuery({});
  const { data: citiesData } = useGetCitiesQuery({
    provinceId: String(watch("provinceId")),
  });

  const provincesOptions = provincesData?.data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  }));

  const citiesOptions = citiesData?.data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  }));

  useEffect(() => {
    if (citiesOptions && getValues().cityId) {
      setValueCity(
        citiesOptions.find(
          (city) => String(city.value) === String(getValues().cityId),
        ) ?? null,
      );
    } else {
      setValueCity(null);
    }
    // eslint-disable-next-line
  }, [watch("cityId"), citiesData]);

  useEffect(() => {
    if (provincesOptions && getValues().provinceId) {
      setValueProvince(
        provincesOptions.find(
          (program) => String(program.value) === String(getValues().provinceId),
        ) ?? null,
      );
    } else {
      setValueProvince(null);
    }
    // eslint-disable-next-line
  }, [watch("provinceId"), provincesData]);

  const handleProvinceChange = (
    newProvince: SingleValue<{
      value: string;
      label: string;
    } | null>,
  ) => {
    setValue("provinceId", newProvince?.value || "");
    setValue("cityId", "");
    setValueCity(null);
    if (newProvince?.value) {
      clearErrors(["provinceId"]);
    }
  };
  const handleCityChange = (
    newCity: SingleValue<{
      value: string;
      label: string;
    } | null>,
  ) => {
    setValue("cityId", newCity?.value || "");
    setValueCity(newCity);
    if (newCity?.value) {
      clearErrors(["cityId"]);
    }
  };

  const handleContactList = (
    contactName: "mail" | "number",
    contactList: string[],
  ) => {
    JSON.stringify(contactList);
    if (contactName === "mail") {
      setValue("emailAddresses", JSON.stringify(contactList));
    } else if (contactName === "number") {
      setValue("phoneNumbers", JSON.stringify(contactList));
    }
  };

  const submitHandler: SubmitHandler<CompanyDepartmentFormData> = async (
    data,
  ) => {
    const newData: CompanyDepartmentFormData = {
      ...data,
      cityId: Number(data.cityId),
      provinceId: Number(data.provinceId),
      additionalDescription: data.additionalDescription || null,
      apartmentNumber: Number(data.apartmentNumber) || null,
      phoneNumbers: transformJSONArrayObjectsToString(
        data.phNumbers as ValueField[],
      ),
      emailAddresses: transformJSONArrayObjectsToString(
        data.emAddresses as ValueField[],
      ),
    };

    if (props.data === null) {
      await postCompanyDepartment({ data: newData });
    } else {
      await patchCompanyDepartment({ id: props.data.id, data: newData });
    }

    if (props.handleNextStep) {
      props.handleNextStep();
    }
  };

  return (
    <Form
      className={"d-flex flex-column w-100 "}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Row className={""}>
        {!(props.disabled || props.isFirst || props.data?.isMain) && (
          <>
            <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6 "}>
              Dodawanie lokalizacji
            </span>
            <ComponentWithDescription
              label={"Nazwa lokalizacji*"}
              labelFs={`fs-16 ${errors.name ? "text-danger" : ""}`}
              desc={
                "Nazwa pomoże Ci w zidentyfikowaniu swoich lokali na liście"
              }
              descFs={"fs-12 text-grey"}
              className="my-2 pb-3  text-dark-blue"
            >
              <Form.Control
                disabled={props.disabled}
                type="text"
                id="name"
                aria-describedby="name"
                maxLength={100}
                className={`w-100 text-dark-blue ${styles.input} `}
                {...register("name", {
                  required: { value: true, message: "Pole wymagane" },
                })}
                isInvalid={Boolean(errors.name)}
              />
              {errors.name && (
                <Form.Control.Feedback type={"invalid"}>
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </ComponentWithDescription>
          </>
        )}

        <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Dane adresowe
        </span>
        <ComponentWithDescription className="my-2">
          <Row className={""}>
            {provincesOptions && (
              <div className={"col-12 col-lg-5"}>
                <label
                  className={`fs-16 pb-1 ${
                    errors.provinceId ? "text-danger" : "text-dark-blue"
                  }`}
                >
                  Województwo*
                </label>
                <Controller
                  name={"provinceId"}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Pole wymagane",
                    },
                  }}
                  render={() => (
                    <Select
                      value={valueProvince}
                      isDisabled={props.disabled}
                      classNamePrefix="select"
                      placeholder="Wszystkie"
                      isClearable={false}
                      isSearchable={true}
                      id="provinceId"
                      options={provincesOptions}
                      onChange={handleProvinceChange}
                      styles={customStyles(Boolean(errors.provinceId))}
                      components={{ DropdownIndicator }}
                    />
                  )}
                />
                {errors.provinceId && (
                  <div className={"text-danger"}>
                    {errors.provinceId.message}
                  </div>
                )}
              </div>
            )}
            {provincesOptions && (
              <div className={"col-12 col-lg-4"}>
                <label
                  className={`fs-16 pb-1 ${
                    errors.cityId ? "text-danger" : "text-dark-blue"
                  }`}
                >
                  Miasto*
                </label>

                {watch("provinceId") ? (
                  <Controller
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Pole wymagane",
                      },
                    }}
                    render={() => (
                      <Select
                        isDisabled={props.disabled}
                        classNamePrefix="select"
                        value={valueCity}
                        placeholder={"Wszystkie"}
                        isClearable={false}
                        isSearchable={watch("provinceId") !== ""}
                        id="cityId"
                        options={
                          watch("provinceId") === ""
                            ? [{ value: "", label: "" }]
                            : citiesOptions
                        }
                        onChange={handleCityChange}
                        styles={customStyles(Boolean(errors.cityId))}
                        components={{ DropdownIndicator }}
                      />
                    )}
                    name={"cityId"}
                  />
                ) : (
                  <TooltipIcon desc="Proszę wybrać województwo">
                    <Form.Control
                      disabled={true}
                      type="text"
                      className={"bg-white"}
                    />
                  </TooltipIcon>
                )}

                {errors.cityId && (
                  <div className={"text-danger"}>{errors.cityId.message}</div>
                )}
              </div>
            )}
            <ComponentWithDescription
              label={"Kod pocztowy*"}
              labelFs={`fs-16 text-nowrap ${
                errors.zipCode ? "text-danger" : "text-dark-blue"
              }`}
              className="pb-1 col-12 col-lg-3"
            >
              <Form.Control
                disabled={props.disabled}
                type="text"
                id="zipCode"
                maxLength={6}
                aria-describedby="zipCode"
                className={`w-100 mt-1 text-dark-blue ${styles.input}`}
                {...register("zipCode", {
                  required: { value: true, message: "Pole wymagane" },
                  pattern: {
                    value: /^\d{2}-\d{3}$/,
                    message: "Format XX-XXX, tylko cyfry",
                  },
                })}
                isInvalid={Boolean(errors.zipCode)}
              />
              {errors.zipCode && (
                <Form.Control.Feedback type={"invalid"}>
                  {errors.zipCode.message}
                </Form.Control.Feedback>
              )}
            </ComponentWithDescription>
          </Row>
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Ulica*"}
          labelFs={`fs-16 ${errors.street ? "text-danger" : "text-dark-blue"}`}
          className="my-2 pb-3 col-12 col-lg-5"
        >
          <Form.Control
            disabled={props.disabled}
            type="text"
            id="street"
            aria-describedby="street"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("street", {
              required: { value: true, message: "Pole wymagane" },
              pattern: {
                value: /^[a-zżźćńółęąśŻŹĆĄŚĘŁÓŃA-Z0-9 _.-]*$/,
                message: "Tylko cyfry i litery",
              },
            })}
            isInvalid={Boolean(errors.street)}
          />
          {errors.street && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.street.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Nr budynku*"}
          labelFs={`fs-16 ${
            errors.houseNumber ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 pb-3 col-6 col-lg-4"
        >
          <Form.Control
            disabled={props.disabled}
            type="text"
            id="houseNumber"
            aria-describedby="houseNumber"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("houseNumber", {
              required: { value: true, message: "Pole wymagane" },
              pattern: {
                value: /^[a-zA-Z0-9_.-/]*$/,
                message: "Tylko cyfry i litery",
              },
            })}
            isInvalid={Boolean(errors.houseNumber)}
          />
          {errors.houseNumber && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.houseNumber.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Nr lokalu"}
          labelFs={`fs-16 ${
            errors.apartmentNumber ? "text-danger" : "text-dark-blue"
          }`}
          className="my-2 pb-3 col-6 col-lg-3"
        >
          <Form.Control
            disabled={props.disabled}
            type="text"
            min={1}
            id="apartmentNumber"
            aria-describedby="apartmentNumber"
            className={`w-100 text-dark-blue ${styles.input} `}
            {...register("apartmentNumber", {
              required: false,
              pattern: {
                value: /^\d+$/,
                message: "Tylko cyfry",
              },
            })}
            isInvalid={Boolean(errors.apartmentNumber)}
          />
          {errors.apartmentNumber && (
            <Form.Control.Feedback type={"invalid"}>
              {errors.apartmentNumber.message}
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        {/*<ComponentWithDescription*/}
        {/*    label={"Dodatkowe informacje"}*/}
        {/*    labelFs={"fs-16 text-dark-blue"}*/}
        {/*    className="my-2 w-100 pb-3"*/}
        {/*    desc="Maks 500 znaków"*/}
        {/*>*/}
        {/*    {(props.disabled && props.data) ?*/}
        {/*        <div className={styles.disabledDiv}>*/}
        {/*            <div dangerouslySetInnerHTML={{__html: props.data.additionalDescription}}/>*/}
        {/*        </div>*/}
        {/*        : <TinyEditor*/}
        {/*            setValue={setValue}*/}
        {/*            name="additionalDescription"*/}
        {/*            type={"small"}*/}
        {/*            data={getValues().additionalDescription}*/}
        {/*        />}*/}
        {/*</ComponentWithDescription>*/}
        <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
          Dane Kontaktowe
        </span>
        <ComponentWithDescription
          label={"Numer telefonu*"}
          labelFs={`fs-16 ${
            Object.keys(errors.phNumbers || {}).some(
              (index: any) => !!errors.phNumbers?.[index],
            )
              ? "text-danger"
              : "text-dark-blue"
          }`}
          className="my-2 col-12 col-lg-6 pb-3"
        >
          <ListComponent
            required={true}
            buttonLabel={"DODAJ KOLEJNY NR"}
            handleContactList={handleContactList}
            contactName={"number"}
            isDisabled={props.disabled}
            pattern={{
              value: "^(?=(\\D*\\d){9})([0-9 ]+)$",
              message: "Wprowadź liczby",
            }}
            errors={errors}
            name={"phNumbers"}
            fields={phoneFields}
            append={phoneAppend}
            remove={phoneRemove}
            register={register}
          />
          {errors.phoneNumbers && (
            <Form.Control.Feedback type={"invalid"}>
              Pole wymagane
            </Form.Control.Feedback>
          )}
        </ComponentWithDescription>
        <ComponentWithDescription
          label={"Adres e-mail"}
          labelFs={`fs-16 ${
            Object.keys(errors.emAddresses || {}).some(
              (index: any) => !!errors.emAddresses?.[index],
            )
              ? "text-danger"
              : "text-dark-blue"
          }`}
          className="my-2 col-12 col-lg-6 pb-3"
        >
          <ListComponent
            required={false}
            buttonLabel={"DODAJ KOLEJNY ADRES E-MAIL"}
            handleContactList={handleContactList}
            contactName={"mail"}
            isDisabled={props.disabled}
            pattern={{
              value: "@",
              message: "Podaj prawidłowy adres e-mail",
            }}
            errors={errors}
            name={"emAddresses"}
            fields={emailFields}
            remove={emailRemove}
            append={emailAppend}
            register={register}
          />
        </ComponentWithDescription>
      </Row>
      {!props.disabled && (
        <div className="d-flex gap-2 mt-4 justify-content-between align-items-center">
          <div className={"text-dark-blue fs-16 fw-semibold"}>
            {props.steps && (
              <span>
                Krok {props.steps.step} z {props.steps.maxStep}
              </span>
            )}
          </div>
          <div className={"d-flex gap-2"}>
            {props.steps?.step ? (
              <>
                <Button
                  className="btn-outline-dark-blue"
                  onClick={props.handleCloseAndRestart}
                >
                  ANULUJ
                </Button>
                <Button
                  className="btn-outline-dark-blue"
                  onClick={props.handleClose}
                >
                  Dokończ później
                </Button>
              </>
            ) : (
              <Button
                className="btn-outline-dark-blue"
                onClick={props.handleClose}
              >
                ANULUJ
              </Button>
            )}

            <Button type="submit" value="Submit">
              {props.data !== null
                ? "AKTUALIZUJ"
                : props.isFirst
                ? "UTWÓRZ"
                : "DODAJ"}
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
