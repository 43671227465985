import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { RejectModal } from "../../organisms/RejectModal/RejectModal";
import ComponentWithDescription from "../ComponentWithDescription/ComponentWithDescription";
import { PatchDoctorAbusePayload } from "redux/services/doctorApi";
import { Review } from "../ChangesStatus/types";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { useTranslation } from "react-i18next";

interface Props {
  data: Review;
  type: AcceptableChanges;
  handlePatchReview: (
    type: AcceptableChanges,
    data: PatchDoctorAbusePayload,
  ) => Promise<void>;
}

export const ChangesStatusAbuseEntity = ({
  data,
  type,
  handlePatchReview,
}: Props) => {
  const { t } = useTranslation();
  const [showReject, setShowReject] = useState<boolean>(false);
  const [showAccept, setShowAccept] = useState<boolean>(false);
  const [notification] = usePostNotificationsMutation();
  const handleAccept = async (answer: string) => {
    await handlePatchReview(type, {
      id: data.id,
      data: {
        decision: 1,
        answer: answer,
      },
    });
    setShowAccept((prev: boolean) => !prev);
  };
  const handleReject = async (answer: string) => {
    await handlePatchReview(type, {
      id: data.id,
      data: {
        decision: 0,
        answer: answer,
      },
    });
    if (type === "feed" || type === "feedComments") {
      if (data.userDefendant?.authId) {
        await notification({
          data: {
            user: data.userDefendant?.authId,
            title: t(
              "notification_ChangesStatusAbuseEntity_userDefender_title",
            ),
            content: t(
              "notification_ChangesStatusAbuseEntity_userDefender_content",
            ),
            type: NotificationTypeEnum["feed"],
            viewSender: 0,
            senderUser: null,
          },
        });
      }
      if (data.user?.authId) {
        await notification({
          data: {
            user: data.user?.authId,
            title: t("notification_ChangesStatusAbuseEntity_user_title"),
            content: t("notification_ChangesStatusAbuseEntity_user_content"),
            type: NotificationTypeEnum["feed"],
            viewSender: 0,
            senderUser: null,
          },
        });
      }
    }
    setShowReject((prev: boolean) => !prev);
  };

  return (
    <div className={" w-100"}>
      <ComponentWithDescription
        label={"Powód zgłoszenia"}
        labelFs={`fs-16 text-dark-blue`}
        className="my-2"
      >
        <Form.Control
          disabled={true}
          as="textarea"
          rows={3}
          className={`w-100 text-dark-blue bg-white`}
          value={data.content}
        />
      </ComponentWithDescription>
      {data.answer && (
        <ComponentWithDescription
          label={"Uwagi do zgłoszenia"}
          labelFs={`fs-16 text-dark-blue`}
          className="my-2 pb-3"
        >
          <Form.Control
            disabled={true}
            as="textarea"
            rows={3}
            className={`w-100 text-dark-blue bg-white`}
            value={data.answer}
          />
        </ComponentWithDescription>
      )}
      {data.decision === null && (
        <div
          className={
            "d-flex justify-content-end flex-column flex-lg-row gap-2 mt-4"
          }
        >
          <Button
            className="btn-outline-dark-blue fs-16"
            onClick={() => setShowAccept((prev) => !prev)}
          >
            odrzucenie zgłoszenia
          </Button>
          <RejectModal
            show={showAccept}
            handleClose={() => setShowAccept((prev: boolean) => !prev)}
            handleComments={handleAccept}
            comments={""}
            label={"Odrzucenie zgłoszenia"}
            desc={"Podaj powód odrzucenie zgłoszenia"}
          />
          <Button
            className={"fs-16"}
            onClick={() => setShowReject((prev: boolean) => !prev)}
          >
            przyjęcie zgłoszenia
          </Button>
          <RejectModal
            show={showReject}
            handleClose={() => setShowReject((prev: boolean) => !prev)}
            handleComments={handleReject}
            comments={""}
            label={"Przyjęcie zgłoszenia"}
            desc={"Podaj powód przyjęcia zgłoszenia"}
          />
        </div>
      )}
    </div>
  );
};
