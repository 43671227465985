import { Row } from "react-bootstrap";
import { BookmarkIcon } from "../BookmarkIcon/BookmarkIcon";
import styles from "./UserAvatarAndName.module.scss";
import React, { SetStateAction, useEffect, useState } from "react";
import userAvatarImage from "../../../assets/images/userAvatarImage.png";
import { useSelector } from "react-redux";
import { formatDistanceToNow } from "date-fns";
import { RootState } from "../../../redux/store/index";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { pl } from "date-fns/locale";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import { useGetMeQuery } from "../../../redux/services/meApi";

interface UserAvatarAndNameProps {
  userName: string;
  userAvatar?: string;
  className?: string;
  isOnFeed?: boolean;
  feedIcon?: JSX.Element;
  feedWhenAdded?: string;
  feedCancerType?: string;
  id?: string;
  savedPostHandler?: React.MouseEventHandler<SVGSVGElement>;
  bookmarkToggle?: boolean;
  status?: string;
  userId?: number;
  userAuthId?: string;
  setEdit?: React.Dispatch<SetStateAction<boolean>>;
}

export const UserAvatarAndName = ({
  userName,
  userAvatar,
  className,
  isOnFeed,
  feedIcon,
  feedWhenAdded,
  feedCancerType,
  savedPostHandler,
  id,
  bookmarkToggle,
  status,
  userId,
  userAuthId,
  setEdit,
}: UserAvatarAndNameProps) => {
  const [isFill, setIsFill] = useState<boolean>(false);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState<boolean>(false);
  const { authUser } = useSelector((state: RootState) => state.user);
  const { data: meData, isFetching: isMeFetching } = useGetMeQuery({});

  const createdAt = feedWhenAdded ? new Date(feedWhenAdded) : 0;

  const transformedDate =
    feedWhenAdded &&
    formatDistanceToNow(Number(createdAt), {
      addSuffix: true,
      locale: pl,
    });

  const { t } = useTranslation();
  const STATUS_LABEL = t(`status_${status}`);

  useEffect(() => {
    setIsBookmarkLoading(isMeFetching);
    const savedPosts = meData?.data?.localSettings?.saved_postsId;
    if (savedPosts) {
      setIsFill(
        savedPosts.some((sfId: string) => {
          return parseInt(sfId) === (id && parseInt(id));
        }),
      );
    }
    // eslint-disable-next-line
  }, [id, isMeFetching, savedPostHandler]);

  return (
    <>
      <Row
        className={`d-flex flex-wrap align-items-center position-relative ${className}`}
      >
        {!isOnFeed ? (
          <>
            <div className={`${styles.userAvatar} col-2 col-md-1`}>
              <img
                src={
                  userAvatar && userAvatar?.length > 0
                    ? userAvatar
                    : userAvatarImage
                }
                alt="userAvatar"
              />
            </div>
            <span
              className={`${styles.userName} col-10 col-md-11 ps-2 px-lg-1`}
            >
              {userName}
            </span>
          </>
        ) : (
          <>
            <div className={`${styles.userAvatar} col-2 col-lg-1`}>
              <img src={userAvatar ?? userAvatarImage} alt="userAvatar"></img>
            </div>
            <div
              className={`d-sm-flex d-block flex-wrap fs-14 px-0 col-8 col-lg-9 ${styles.userName}`}
            >
              <Link
                to={`/${NAV_PATH.PROFILE}/${userAuthId}`}
                target={"_blank"}
                className="p-0 m-0 col-12 d-flex flex-wrap d-md-block"
              >
                {userName}
              </Link>
              <div className="col-12 d-flex flex-wrap">
                <span
                  className={`${
                    styles.whenAdded
                  } d-flex align-items-center fs-12 pe-2 col-11 col-sm-auto ${
                    feedCancerType && "border-md-1 border-end"
                  }`}
                >
                  <span className={`col-1 col-sm-auto pe-2 ps-0 me-2 me-md-0`}>
                    {feedIcon}
                  </span>
                  {feedWhenAdded && transformedDate}
                </span>
                <span
                  className={`${styles.cancerType} ps-sm-2 col-12 col-sm-6 fs-12 d-flex align-items-center`}
                >
                  {feedCancerType}
                </span>
              </div>
              <div className="mx-0 px-0 d-none d-sm-block">
                {status !== "ACCEPTED" && (
                  <Row className="col-12 mx-0 px-0 my-1 fs-12">
                    <span className="bg-secondary text-white rounded-2 fw-600 px-2">
                      {STATUS_LABEL}
                    </span>
                  </Row>
                )}
              </div>
            </div>
            <div
              className={`px-0 mx-0 col-2 col-lg-2 d-flex justify-content-end`}
            >
              {userId === authUser.id && setEdit ? (
                <>
                  <div
                    className="d-flex flex-wrap cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEdit(true);
                    }}
                  >
                    <span
                      className={`fs-12 fw-medium mx-0 text-gray p-2 rounded-2 ${styles.editButton}`}
                    >
                      Edytuj <Pencil className={`mx-1 ${styles.pencilIcon}`} />
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <BookmarkIcon
                    onClick={savedPostHandler}
                    id={id?.toString()}
                    stroke="#003B50"
                    fill="#003B50"
                    isFill={isFill}
                    fillToggle={bookmarkToggle && bookmarkToggle}
                    isFeedType
                    isMeFetching={isBookmarkLoading}
                    key={id}
                    isPending={isMeFetching}
                  />
                </>
              )}
            </div>
            <div className="mx-0 px-0 d-block d-sm-none">
              {status !== "ACCEPTED" && (
                <Row className="col-auto mx-0 px-0 my-1 fs-12">
                  <span className="bg-secondary text-white rounded-2 fw-600 px-2 col-auto">
                    {STATUS_LABEL}
                  </span>
                </Row>
              )}
            </div>
          </>
        )}
      </Row>
    </>
  );
};
