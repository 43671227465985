interface ReplyIconProps {
  fill: string;
}

export const ReplyIcon = ({ fill }: ReplyIconProps) => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3332 8.33073V7.53073C12.3332 6.41062 12.3332 5.85057 12.1152 5.42275C11.9234 5.04642 11.6175 4.74046 11.2412 4.54872C10.8133 4.33073 10.2533 4.33073 9.13317 4.33073H1.6665M1.6665 4.33073L4.33317 1.66406M1.6665 4.33073L4.33317 6.9974"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
