import {
  AgreementInput,
  BooleanRadio,
  CheckboxInput,
  DatalistInput,
  DateInput,
  DateTimeInput,
  EmailInput,
  NumberInput,
  RadioInput,
  RangeInput,
  RatingRadioInput,
  SelectInput,
  TelInput,
  TextareaInput,
  TextInput,
  TimeInput,
  AttachmentsInput,
  HeaderInput,
  BirthDateInput,
  FacilitiesSelect,
} from "components/molecules/PatientPollAnswerInputs";
import { SetPollDefaultValueLoadingState } from "components/organisms/DynamicPatientPoll/DynamicPatientPoll";

export interface InputComponentProps {
  question: PatientPollQuestion;
  nextPageClicked: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  setPollDefaultValueLoadingState: SetPollDefaultValueLoadingState;
}

const InputComponentMap: Record<string, React.FC<InputComponentProps>> = {
  agreement: AgreementInput,
  "boolean-radio": BooleanRadio,
  checkbox: CheckboxInput,
  datalist: DatalistInput,
  date: DateInput,
  "datetime-local": DateTimeInput,
  email: EmailInput,
  number: NumberInput,
  radio: RadioInput,
  range: RangeInput,
  "rating-radio": RatingRadioInput as unknown as React.FC<InputComponentProps>,
  select: SelectInput,
  tel: TelInput,
  textarea: TextareaInput,
  text: TextInput,
  time: TimeInput,
  attachments: AttachmentsInput,
  header: HeaderInput,
  "birth-date": BirthDateInput,
  "facilities-select": FacilitiesSelect,
};

interface RenderPollQuestionProps {
  question: PatientPollQuestion;
  nextPageClicked: boolean;
  readOnly?: boolean;
  defaultAnswer?: string;
  setPollDefaultValueLoadingState: SetPollDefaultValueLoadingState;
}

const RenderPollQuestion: React.FC<RenderPollQuestionProps> = ({
  question,
  nextPageClicked,
  readOnly,
  defaultAnswer,
  setPollDefaultValueLoadingState,
}) => {
  if (question.isActive === 0) {
    return null;
  }

  const InputComponent = InputComponentMap[question.fieldType];
  if (!InputComponent) {
    return null;
  }

  return (
    <InputComponent
      key={question.id}
      question={question}
      nextPageClicked={nextPageClicked}
      readOnly={readOnly}
      defaultValue={defaultAnswer}
      setPollDefaultValueLoadingState={setPollDefaultValueLoadingState}
    />
  );
};

export default RenderPollQuestion;
