import { Badge, Button, Container, Dropdown } from "react-bootstrap";
import styles from "./GuardiansAndPatientsListElement.module.scss";
import { Gear } from "react-bootstrap-icons";
import PopupModal from "../PopupModal/PopupModal";
import { useState } from "react";
import Avatar from "components/atoms/Avatar/Avatar";
import { useTranslation } from "react-i18next";

const GuardiansAndPatientsListElement = ({
  status,
  name,
  type,
  email,
  deleteGuardianOrPatient,
  avatarUrl,
  loginAsGuardian,
}: GuardiansAndPatientsListElementProps) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <Container
      fluid
      className={`p-lg-4 p-3 my-lg-2 my-2 d-flex flex-column flex-lg-row justify-content-between ${styles.wrapper}`}
    >
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <Avatar
            size="sm"
            isAuthenticated={false}
            className="me-md-3 me-2 d-block"
            url={avatarUrl}
          />
          <div>
            <Badge
              className={`bg-secondary text-white lh-130 d-inline-block ${styles.badge}`}
            >
              {status}
            </Badge>
            <p className="m-0 text-dark-blue fw-400 fs-19 text-wrap">
              {name ? name : email}
            </p>
          </div>
        </div>
        <div className="d-flex">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline"
              id="dropdown-basic"
              className="text-dark-blue stroke-dark-blue d-lg-none d-block"
            >
              <Gear
                size={24}
                className="text-dark-blue d-flex align-items-center"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {type === "patient" ? (
                <>
                  <Dropdown.Item onClick={deleteGuardianOrPatient}>
                    {t(
                      "widget_guardians_and_patients_panel_patient_remove_dropdown"
                    )}
                  </Dropdown.Item>
                </>
              ) : (
                <Dropdown.Item onClick={deleteGuardianOrPatient}>
                  {t(
                    "widget_guardians_and_patients_panel_guardian_remove_dropdown"
                  )}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="d-flex flex-lg-row flex-column mt-lg-0 mt-2 justify-content-center align-items-center">
        {type === "patient" && status !== "Nieaktywny" && (
          <Button
            variant="outline-dark"
            className={`me-2 ${
              status === "W trakcie potwierdzenia"
                ? "btn-outline-grey text-grey-2"
                : "btn-outline-primary"
            } ${styles.button}`}
            onClick={loginAsGuardian}
          >
            {t("widget_guardians_and_patients_panel_login_button")}
          </Button>
        )}
        <Dropdown>
          <Dropdown.Toggle
            variant="outline"
            id="dropdown-basic"
            className="text-dark-blue stroke-dark-blue d-none d-lg-block"
          >
            <Gear
              size={24}
              className="text-dark-blue d-flex align-items-center"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {type === "patient" ? (
              <>
                <Dropdown.Item onClick={deleteGuardianOrPatient}>
                  {t(
                    "widget_guardians_and_patients_panel_patient_remove_dropdown"
                  )}
                </Dropdown.Item>
              </>
            ) : (
              <Dropdown.Item onClick={deleteGuardianOrPatient}>
                {t(
                  "widget_guardians_and_patients_panel_guardian_remove_dropdown"
                )}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <PopupModal
        titleClasses="fw-600 text-center"
        text={t("modal_guardians_and_patients_panel_patient_remove_p")}
        type="confirm"
        smallText={t(
          "modal_guardians_and_patients_panel_patient_remove_smallText"
        )}
        show={show}
        setShow={setShow}
        confirmAction={setShow}
      />
    </Container>
  );
};

export default GuardiansAndPatientsListElement;
