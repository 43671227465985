import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col } from "react-bootstrap";
import { CompanyForm } from "../../molecules/CompanyForm/CompanyForm";
import { CompanyLocalizationForm } from "../../molecules/CompanyLocalizationForm/CompanyLocalizationForm";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteHardCompanyMutation,
  useGetCompanyQuery,
} from "../../../redux/services/companyApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Company } from "../../molecules/LoyaltyProgramServiceArticle/types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  handleClose: () => void;
  isFirst: boolean;
  maxSteps: number;
  dataCompany: Company | null;
}

export const CompanyCreateModal = (props: Props) => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();
  const [step, setStep] = useState(1);
  const { refetch } = useGetCompanyQuery(companyId ? companyId : skipToken);
  const [deleteCompany] = useDeleteHardCompanyMutation();

  const { t } = useTranslation();

  useEffect(() => {
    if (step > props.maxSteps) {
      setStep(1);
      props.handleClose();
    }
    // eslint-disable-next-line
  }, [step]);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleCloseAndRestart = async () => {
    setStep(1);
    props.handleClose();
    const hasBeenDelete = await deleteCompany({
      id: companyId,
    });

    if ("data" in hasBeenDelete) {
      if (hasBeenDelete.data.statusCode === 500) {
        toast.error(t("toast_companyCreateModal_error_500"));
      } else {
        navigate(-1);
      }
    } else if ("error" in hasBeenDelete) {
      const error = hasBeenDelete.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_companyCreateModal_error_500"));
      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_companyCreateModal_error_400")} ${error.data.message[0]}`,
        );
    }
  };

  return (
    <Modal show={props.show} centered size={"xl"} fullscreen={"sm-down"}>
      <Modal.Header className={"border-bottom-0 p-4"}>
        <Col>
          <Modal.Title className="fw-bold text-dark-blue">
            <span>
              {props.isFirst
                ? step === 1
                  ? "Tworzenie profilu firmy"
                  : "Tworzenie siedziby firmy"
                : "Edytowanie profilu firmy"}
            </span>
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body className={"p-4"}>
        {step === 1 && (
          <CompanyForm
            data={props.dataCompany}
            handleClose={props.handleClose}
            steps={
              props.dataCompany
                ? false
                : { step: step, maxStep: props.maxSteps }
            }
            handleNextStep={handleNextStep}
          />
        )}
        {step === 2 && props.dataCompany === null && (
          <CompanyLocalizationForm
            disabled={false}
            data={null}
            companyId={Number(companyId)}
            isFirst={true}
            steps={{ step: step, maxStep: props.maxSteps }}
            handleCloseAndRestart={handleCloseAndRestart}
            handleClose={props.handleClose}
            handleNextStep={handleNextStep}
            reFetchCompany={refetch}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
