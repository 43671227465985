import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";

interface FormSwitchWithLabelProps {
  isSwitched: boolean;
  label?: string;
  onSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormSwitchWithLabel = ({
  isSwitched,
  label,
  onSwitchChange,
}: FormSwitchWithLabelProps) => {
  return (
    <Form.Check
      type="switch"
      id="active-switch"
      checked={isSwitched}
      onChange={onSwitchChange}
      label={label}
      className={`fs-16 fw-400 text-dark-blue mb-4`}
    />
  );
};
