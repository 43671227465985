import FeedCategoryTemplate from "../../templates/FeedCategoryTemplate/FeedCategoryTemplate";
import Container from "react-bootstrap/Container";

const FeedCategoryPage = () => (
    <Container fluid>
        <FeedCategoryTemplate type={'tags'} />
        <FeedCategoryTemplate type={'topics'} />
    </Container>
)


export default FeedCategoryPage