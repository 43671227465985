import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { useGetUserRolesQuery } from "../../../redux/services/userRolesApi";
import {
  usePutUserRoleMutation,
  usePatchUserSFContactMutation,
} from "../../../redux/services/usersApi";

interface Props {
  userId: number;
}

export const AdminUserRoleForm = ({ userId }: Props) => {
  const [putUserRoleMutation] = usePutUserRoleMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserRoleForm>({
    defaultValues: {
      roleId: "",
    },
  });
  const [patchUserSF] = usePatchUserSFContactMutation();

  const { data: roleUsersData, isLoading } = useGetUserRolesQuery({});

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }
  const rolesOptions: ReactSelectOption[] = roleUsersData?.userRoles.map(
    (role: ShortUserRoles) => ({
      value: role.id.toString(),
      label: role.name,
    })
  );

  const submitHandler: SubmitHandler<UserRoleForm> = async (data) => {
    const currentRole = roleUsersData?.userRoles?.filter(
      (role: ShortUserRoles) => role?.id === +data?.roleId
    );
    const roleKey = currentRole?.[0]?.sfName;

    await putUserRoleMutation({
      data: {
        userId: userId,
        sendData: {
          roleId: String(data?.roleId),
        },
      },
    });
    await patchUserSF({
      data: {
        [roleKey]: true,
      },
      userId: userId.toString(),
    });
  };

  return (
    <Form
      className={"d-flex flex-column p-3 gap-3 w-100 border border-1 rounded-2"}
      onSubmit={handleSubmit(submitHandler)}
    >
      <Row>
        <Form.Label
          className={`mb-1 fs-14 lh-base ${
            errors.roleId?.message ? "text-danger" : "text-dark-blue"
          }`}
          htmlFor="pollsTitleFilter"
        >
          Przypisanie roli użytkownikowi
        </Form.Label>
        <Controller
          name={"roleId"}
          control={control}
          rules={{
            required: {
              value: true,
              message: "Pole wymagane",
            },
          }}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              ref={ref}
              value={rolesOptions.find(
                (selectOption: ReactSelectOption) =>
                  selectOption.value === value
              )}
              classNamePrefix="select"
              placeholder="Wybierz"
              isClearable={true}
              isSearchable={true}
              id="roleId"
              options={rolesOptions}
              onChange={(option: SingleValue<ReactSelectOption>) =>
                onChange(option?.value || "")
              }
              styles={customStyles(!!errors?.roleId?.message)}
              components={{ DropdownIndicator }}
            />
          )}
        />
        {errors?.roleId && (
          <p className="text-danger mb-0">{errors?.roleId?.message}</p>
        )}
      </Row>
      <Col>
        <div className="d-flex justify-content-end">
          <Button className={"end-0"} type="submit" value="Submit">
            dodaj
          </Button>
        </div>
      </Col>
    </Form>
  );
};
