import SectionTitle from "components/atoms/SectionTitle/SectionTitle";
import TextArea from "components/atoms/TextArea/TextArea";
import { UserAvatarAndName } from "components/atoms/UserAvatarAndName/UserAvatarAndName";
import FeedCollapse from "components/molecules/CollapseComponents/FeedCollapse/FeedCollapse";
import { ResultCounter } from "components/molecules/ResultCounter/ResultCounter";
import { AddPostModal } from "components/organisms/AddPostModal/AddPostModal";
import { FeedPost } from "components/organisms/FeedPost/FeedPost";
import { useEffect, useState, useRef, Suspense } from "react";
import { Button, Container, Row, Stack } from "react-bootstrap";
import { useGetPostsQuery } from "redux/services/feedApi";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import { ReactComponent as PenSquare } from "../../../assets/Icons/PenSquare.svg";
import { ReactComponent as Reply } from "../../../assets/Icons/Reply.svg";
import { Person } from "react-bootstrap-icons";
import styles from "./FeedTemplate.module.scss";
import { ShowOlder } from "components/atoms/ShowOlder/ShowOlder";
import userAvatarImage from "../../../assets/images/userAvatarImage.png";
import { useIntersection } from "@mantine/hooks";
import { Link, useParams } from "react-router-dom";
import React from "react";
import {
  useGetFeedCommentStatsQuery,
  useGetFeedStatsQuery,
  useGetUserStatsQuery,
} from "redux/services/statsApi";
import { Loading } from "../../atoms/Loading/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const PAGE_SIZE = 5;

export const FeedTemplate = () => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const [isOnlyMyPosts, setIsOnlyMyPosts] = useState<boolean>(false);
  const [isOnlySavedPosts, setIsOnlySavedPosts] = useState<boolean>(false);
  const [tags, setTags] = useState<ILabel[]>([]);
  const [topics, setTopics] = useState<ILabel[]>([]);
  const [triggerFilters, setTriggerFilters] = useState<boolean>(false);
  const [displayedPosts, setDisplayedPosts] = useState<FeedPostProps[]>([]);
  const [posts, setPosts] = useState<FeedPostProps[]>([]);
  const [isAddingPost, setAddingPost] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(PAGE_SIZE);
  const [savedPosts, setSavedPosts] = useState<string[]>(
    authUser?.localSettings?.saved_postsId ?? []
  );
  const [filters, setFilters] = useState<{
    userId: number;
    tagId: number[];
    topicId: number[];
    savedPostId: number[] | string[];
  } | null>();
  const [stats, setStats] = useState<{
    posts: number;
    comments: number;
    users: number;
  }>();
  const [user, setUser] = useState<IUser>({
    id: 0,
    name: "Użytkownik Alivii",
    avatar: userAvatarImage,
    authId: "",
  });

  const { feedId } = useParams();

  const { data: commentStats, isSuccess: commentStatsOk } =
    useGetFeedCommentStatsQuery({});
  const { data: postStats, isSuccess: postStatsOk } = useGetFeedStatsQuery({
    userId: filters?.userId ? filters?.userId.toString() : "",
  });
  const { data: usersStats, isSuccess: usersStatsOk } = useGetUserStatsQuery(
    {}
  );
  const { data: userMe, isSuccess: userMeOk } = useGetMeQuery({});

  const [patchMe] = usePatchMeMutation();

  const {
    data: feedPosts,
    isSuccess: postsOk,
    isFetching: isPostsLoading,
    refetch: refetchPost,
  } = useGetPostsQuery({
    sortOrder: "DESC",
    orderBy: "created_at",
    limit: limit,
    offset: "0",
    feedId: feedId || filters?.savedPostId || "",
    userId: filters?.userId ?? "",
    topicId: filters?.topicId ?? "",
    tagId: filters?.tagId ?? "",
  });

  useEffect(() => {
    if (postsOk && feedPosts && userMeOk && userMe) {
      setPosts(feedPosts?.data);
      setDisplayedPosts(feedPosts?.data);
      setUser(
        userMe && {
          id: userMe.data.id,
          name: userMe.data.name,
          avatar: userAvatarImage,
        }
      );
    }
    const savedPostsSource = authUser?.localSettings?.saved_postsId;
    if (!savedPostsSource) {
      setSavedPosts(authUser?.localSettings?.saved_postsId ?? []);
    }
    if (
      postStatsOk &&
      commentStatsOk &&
      usersStatsOk &&
      postStats &&
      commentStats &&
      usersStats
    ) {
      setStats({
        posts: postStats.data,
        comments: commentStats.data,
        users: usersStats.data,
      });
    }
    // eslint-disable-next-line
  }, [
    feedPosts,
    userMe,
    postsOk,
    userMeOk,
    postStatsOk,
    commentStatsOk,
    usersStatsOk,
    postStats,
    commentStats,
    usersStats,
  ]);

  const lastPost = useRef<HTMLDivElement>(null);
  const { ref, entry } = useIntersection({
    root: lastPost.current,
    threshold: 1,
  });
  useEffect(() => {
    if (entry?.isIntersecting) setLimit(limit + PAGE_SIZE);
    // eslint-disable-next-line
  }, [entry]);

  useEffect(() => {
    handleFilterPosts();
    // eslint-disable-next-line
  }, [triggerFilters]);

  const savedPostHandler = (e: any) => {
    if (e.target.id) {
      const postId = e.target.id;
      const newArr = savedPosts?.filter((el: string) => el !== postId);
      if (!savedPosts?.includes(postId)) {
        setSavedPosts((prevSavedPosts) => {
          const updatedSavedPosts = [...prevSavedPosts, postId];
          patchMe({
            data: {
              ...authUser,
              localSettings: {
                ...authUser.localSettings,
                saved_postsId: updatedSavedPosts,
              },
            },
          });
          return updatedSavedPosts;
        });
      } else {
        setSavedPosts(() => {
          const updatedSavedPosts = newArr;
          patchMe({
            data: {
              ...authUser,
              localSettings: {
                ...authUser.localSettings,
                saved_postsId: updatedSavedPosts,
              },
            },
          });
          return updatedSavedPosts;
        });
      }
    }
  };

  const handleFilterPosts = async () => {
    setFilters({
      userId: isOnlyMyPosts && userMe.data.id,
      tagId: tags.length > 0 ? tags?.map((tag: ILabel) => tag.id ?? 0) : [],
      topicId:
        topics.length > 0 ? topics?.map((topic: ILabel) => topic.id ?? 0) : [],
      savedPostId: isOnlySavedPosts
        ? savedPosts.length > 0
          ? savedPosts.map((savedPost) => parseInt(savedPost))
          : ["null"]
        : [],
    });
  };

  const handleClearFilters = () => {
    setTags([]);
    setTopics([]);
    setFilters(null);
    setIsOnlyMyPosts(false);
    setIsOnlySavedPosts(false);
    setDisplayedPosts(posts);
    setLimit(5);
  };

  return (
    <>
      <Container fluid className="bg-light d-flex justify-content-center">
        <section
          className={`d-flex justify-content-center ${styles.section} mx-0 px-0 col-xl-11`}
        >
          <Row className="p-0 m-0 d-flex justify-content-center col-12 col-xl-8">
            <Row className="m-0 p-0 d-block">
              <Link
                to={`/${NAV_PATH.FEED}`}
                title={"Przejdź na stronę główną Społeczności"}
              >
                <SectionTitle>Społeczność Alivii</SectionTitle>
              </Link>
              <Row
                className={`mb-5 px-0 mx-0 d-flex flex-wrap ${styles.resultsContainer}`}
              >
                <ResultCounter
                  icon={<Person stroke={"#11C0F2"} strokeWidth={1} />}
                  resultCount={stats?.users ?? 0}
                  className="pe-md-4 col-12 col-md-auto col py-2 py-md-0 border-rstCounter"
                  label="użytkowników"
                />
                <ResultCounter
                  icon={<PenSquare />}
                  resultCount={stats?.posts ?? 0}
                  className="ps-md-4 pe-md-4 col-12 col-md-auto py-2 py-md-0 border-rstCounter"
                  label="postów"
                />
                <ResultCounter
                  icon={<Reply />}
                  resultCount={stats?.comments ?? 0}
                  className="ps-md-4 col-12 col-md-auto py-2 py-md-0"
                  label="komentarzy"
                />
              </Row>
              <Row
                className={` px-2 mx-0 mb-5 containerWithShadow ${styles.feedAddPost} `}
              >
                <div className="px-2 py-2">
                  {user ? (
                    <UserAvatarAndName
                      userName={userMe?.data?.name ?? ""}
                      userAvatar={userMe?.data?.avatar?.accessUrl ?? ""}
                      className="mb-4 mx-0"
                    />
                  ) : (
                    <Loading />
                  )}
                  <Row className={"col-12 p-0 m-0"}>
                    <TextArea
                      placeholder="Napisz o swoich doświadczeniach, poradź się innych, podziel się ważną informacją…"
                      className="px-0 mx-0 col-12 pointer-none"
                      onClick={() => setAddingPost(true)}
                      readonly
                    />
                  </Row>
                </div>
              </Row>
              <Row xl={1} className="px-0 mx-0 mb-5">
                <FeedCollapse
                  showSaved={isOnlySavedPosts}
                  myPosts={isOnlyMyPosts}
                  onSwitchMyPosts={() => setIsOnlyMyPosts(!isOnlyMyPosts)}
                  onSwitchSaved={() => setIsOnlySavedPosts(!isOnlySavedPosts)}
                  resetFiltersHandler={handleClearFilters}
                  tags={tags}
                  topics={topics}
                  setTags={setTags}
                  setTopics={setTopics}
                  filterPostsHandler={handleFilterPosts}
                />
              </Row>
              {feedPosts ? (
                <Row xl={1} className={`px-0 mx-0`}>
                  <Stack gap={5}>
                    <Suspense fallback={<Loading />}>
                      {displayedPosts?.length > 0 ? (
                        displayedPosts.map(
                          (data: FeedPostProps, index: number) => {
                            if (index === displayedPosts?.length - 2) {
                              return (
                                <React.Fragment
                                  key={`targetPostWrapper${data.id}`}
                                >
                                  <FeedPost
                                    id={data.id}
                                    feedTag={data.feedTag}
                                    topic={data.topic}
                                    content={data.content}
                                    attachment={data.attachment}
                                    createdAt={data.createdAt}
                                    updatedAt={data.updatedAt}
                                    likesAmount={data.likesAmount}
                                    commentsAmount={data.commentsAmount}
                                    comment={data.comment}
                                    like={data.like}
                                    key={data.id}
                                    user={data.user}
                                    savedPostHandler={savedPostHandler}
                                    currentUser={user}
                                    status={data.status}
                                    isCommentingAllowed
                                    setTags={setTags}
                                    setFilters={setTriggerFilters}
                                    refetchPost={refetchPost}
                                  />
                                  <div ref={ref} key={"trigger" + index}></div>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <FeedPost
                                  id={data.id}
                                  feedTag={data.feedTag}
                                  topic={data.topic}
                                  content={data.content}
                                  attachment={data.attachment}
                                  createdAt={data.createdAt}
                                  updatedAt={data.updatedAt}
                                  likesAmount={data.likesAmount}
                                  commentsAmount={data.commentsAmount}
                                  comment={data.comment}
                                  like={data.like}
                                  key={data.id}
                                  user={data.user}
                                  savedPostHandler={savedPostHandler}
                                  currentUser={user}
                                  status={data.status}
                                  isCommentingAllowed
                                  setTags={setTags}
                                  setFilters={setTriggerFilters}
                                  refetchPost={refetchPost}
                                />
                              );
                            }
                          }
                        )
                      ) : (
                        <Row
                          xl={1}
                          className={`rounded-2 border border-gray d-flex justify-content-center align-items-center ${styles.rowHeight}`}
                        >
                          <div className="col-12 d-flex justify-content-center flex-wrap">
                            <p className="col-12 text-center fw-600 text-dark-blue">
                              Brak postów spełniających podane parametry lub
                              kryteria filtrów
                            </p>
                            <Button
                              className="bg-primary text-white"
                              onClick={handleClearFilters}
                            >
                              WYCZYŚĆ FILTRY
                            </Button>
                          </div>
                        </Row>
                      )}
                      {!isPostsLoading ? (
                        <ShowOlder
                          count={feedPosts?.meta?.count}
                          dataDiff={PAGE_SIZE}
                          setLimit={setLimit}
                          limit={limit}
                        />
                      ) : (
                        <Loading />
                      )}
                    </Suspense>
                  </Stack>
                </Row>
              ) : (
                <Loading />
              )}
            </Row>
          </Row>
        </section>
      </Container>
      <AddPostModal
        show={isAddingPost}
        setShow={setAddingPost}
        user={userMe?.data}
      />
    </>
  );
};
