import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as SortArrow } from "../../../../assets/Icons/SortArrow.svg";

import "../CategoryTable/CategoryTable.scss";
import FeedTableRowWithCollapse from "../../FeedTableRowWithCollapse/FeedTableRowWithCollapse";
import QuickEditFeed from "../../../molecules/QuickEditCollapse/QucikEditFeed/QuickEditFeed";

const FeedTable = ({ data, type }: FeedTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState(searchParams.get("sortBy"));

  const handleSort = (sortBy: string) => {
    searchParams.set(`${type}SortBy`, sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "name,DESC"}
              onClick={() =>
                sort === "name,ASC"
                  ? handleSort(`name,DESC`)
                  : handleSort(`name,ASC`)
              }
            >
              <span>Nazwa</span>{" "}
              <SortArrow
                data-active={sort?.includes("name")}
                className="sortArrow"
              />
            </button>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "status,DESC"}
              onClick={() =>
                sort === "delete,ASC"
                  ? handleSort(`status,DESC`)
                  : handleSort(`status,ASC`)
              }
            >
              <span>Status</span>{" "}
              <SortArrow
                data-active={sort?.includes("status")}
                className="sortArrow"
              />
            </button>
          </th>
          <th className="pb-4">
            <button
              className="stats__sortButton"
              data-desc={sort === "createdAt,DESC"}
              onClick={() =>
                sort === "createdAt,ASC"
                  ? handleSort(`createdAt,DESC`)
                  : handleSort(`createdAt,ASC`)
              }
            >
              <span>Data utworzenia</span>
              <SortArrow
                data-active={sort?.includes("createdAt")}
                className="sortArrow"
              />
            </button>
          </th>
        </tr>
      </thead>

      <tbody className="tableBody">
        {data ? (
          data?.map((item: IFiltersSource, i: number) => (
            <FeedTableRowWithCollapse
              key={i}
              data={item}
              CollapseItem={QuickEditFeed}
            />
          ))
        ) : (
          <tr>
            <td colSpan={4} className="text-center">
              Nie znaleziono żadnych filtrów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default FeedTable;
