import ArticlesSendingTemplate from "components/templates/ArticlesSendingTemplate/ArticlesSendingTemplate";
import { useParams } from "react-router-dom";
import { useGetArticleQuery } from "redux/services/articlesApi";

const ArticlesEditPage = () => {
  const linkParams = useParams();
  const { data: editedArticleData } = useGetArticleQuery(linkParams.id);

  return <ArticlesSendingTemplate data={editedArticleData?.data} />;
};

export default ArticlesEditPage;
