import { Col, Row, Table } from "react-bootstrap";
import { UserViewTableRow } from "../../UserViewTableRow/UserViewTableRow";

interface UsersTableProps {
  data: UsersData[];
}

export const UsersTable = ({ data }: UsersTableProps) => {
  const thStyles = "container-fluid pb-4 border border-0 text-dark-blue";

  return (
    <Table>
      <thead>
        <tr>
          <th className={thStyles}>
            <Row>
              <Col xs={2}>id</Col>
              <Col xs={3}>nazwa</Col>
              <Col xs={4}>e-mail</Col>
              <Col xs={2}>status konta</Col>
            </Row>
          </th>
        </tr>
      </thead>

      <tbody className="fs-13">
        {data?.map((user, idx) => <UserViewTableRow key={idx} data={user} />)}
      </tbody>
    </Table>
  );
};
