import { createSlice } from "@reduxjs/toolkit";
import { ImageIds } from "../../Helpers/convertImageIdToUrl";

export interface UserRole {
  role: Role;
  id: number;
  status: string | number;
}

export interface Avatar {
  accessUrl: string;
  alt: string | null;
  filename: string;
  id: string;
  meta: string | null;
  title: string | null;
}

export interface SFContact {
  attributes: {
    type: string;
    url: string;
  };
  Id: string;
  IsDeleted: boolean;
  MasterRecordId: null | string;
  AccountId: string;
  LastName: string;
  FirstName: string;
  Salutation: null | string;
  Name: string;
  RecordTypeId: string;
  OtherStreet: null | string;
  OtherCity: null | string;
  OtherState: null | string;
  OtherPostalCode: null | string;
  OtherCountry: null | string;
  OtherLatitude: null | string;
  OtherLongitude: null | string;
  OtherGeocodeAccuracy: null | string;
  OtherAddress: null | string;
  MailingStreet: null | string;
  MailingCity: null | string;
  MailingState: null | string;
  MailingPostalCode: null | string;
  MailingCountry: null | string;
  MailingLatitude: null | string;
  MailingLongitude: null | string;
  MailingGeocodeAccuracy: null | string;
  MailingAddress: null | string;
  Phone: null | string;
  Fax: null | string;
  MobilePhone: null | string;
  HomePhone: null | string;
  OtherPhone: null | string;
  AssistantPhone: null | string;
  ReportsToId: null | string;
  Email: string;
  Title: null | string;
  Department: null | string;
  AssistantName: null | string;
  LeadSource: null | string;
  Birthdate: null | string;
  Description: null | string;
  CurrencyIsoCode: string;
  OwnerId: string;
  HasOptedOutOfEmail: boolean;
  HasOptedOutOfFax: boolean;
  DoNotCall: boolean;
  CreatedDate: string;
  CreatedById: string;
  LastModifiedDate: string;
  LastModifiedById: string;
  SystemModstamp: string;
  LastActivityDate: null | string;
  LastCURequestDate: null | string;
  LastCUUpdateDate: null | string;
  LastViewedDate: string;
  LastReferencedDate: string;
  EmailBouncedReason: null | string;
  EmailBouncedDate: null | string;
  IsEmailBounced: boolean;
  PhotoUrl: string;
  Jigsaw: null | string;
  JigsawContactId: null | string;
  Formularze__c: number;
  Alior_Data__c: null | string;
  Darczyncy__c: number;
  Zrodlo_PZ_kontakt__c: null | string;
  Gender__c: null | string;
  GR_imi_i_nazwisko__c: string;
  Darowizny_wszystkie_data_ostatniej__c: null | string;
  sendgrid4sf__Email_Verification_Date__c: null | string;
  sendgrid4sf__Email_Verification_Status__c: null | string;
  sendgrid4sf__Invalid_SendGrid_Contact__c: boolean;
  sendgrid4sf__MassLastStayinTouchRequestDate__c: null | string;
  sendgrid4sf__MassLastStayinTouchSaveDate__c: null | string;
  sendgrid4sf__MassMailer_Email_Status_Count__c: number;
  Program_powitalny_kontakt__c: null | string;
  Wielko_miasta__c: null | string;
  Czas_od_p_atno_ci_jednorazowej__c: null | string;
  Data_edycji_rekordu__c: string;
  Kategoria_deduplikacji__c: string;
  Status_u_ytkownika__c: string;
  Zakonczono_identyfikacj_ODO__c: boolean;
  Status_PZ__c: null | string;
  sendgrid4sf__Email_Verification_substatus__c: null | string;
  Skieruj_do_TM__c: boolean;
  Data_wys_ania_pakietu_powitalnego__c: null | string;
  Nale_y_wys_a_pakiet_powiatalny__c: string;
  Pakiet_retencyjny_wysy_ka__c: null | string;
  Pakiet_retencyjny_data_wys_ania__c: string;
  Data_i_czas_powstania_kontaktu__c: string;
  Wiek__c: null | string;
  Grupa_wiekowa__c: null | string;
  Bank__c: null | string;
  Polecenie_zap_aty_rednia_kwota__c: null | string;
  Darowizny_jednorazowe_rednia__c: null | string;
  Status_kontaktu__c: string;
  Kwota_przekazana__c: number;
  Program_Middle_Donor__c: boolean;
  Wszystkie_darowizny__c: null | string;
  X1_podatku_rednia_kwota__c: null | string;
  Otrzymane_darowizny_rednia_kwota__c: null | string;
  Wszystkie_wyp_aty_rednia_kwota__c: null | string;
  Darczy_ca_cykliczny__c: boolean;
  Program_darowizn_jednorazowych__c: string;
  Cel_ostatniej_darowizny__c: null | string;
  Update__c: boolean;
  Skarbonka__c: number;
  Skarbonka_suma_p_atno_ci__c: number;
  Skarbonka_rednia_kwota__c: null | string;
  Skarbonka_data_ostatniej_p_atno_ci__c: null | string;
  Darowizny_jednorazowe__c: null | string;
  X1_podatku_data_ostatniej__c: null | string;
  Polecenie_zap_aty_data_ostatniej__c: null | string;
  Wszystkie_wyp_aty_data_ostatniej__c: null | string;
  Otrzymane_darowizny_data_ostatniej__c: null | string;
  Darowizny_cykliczne_suma__c: number;
  Darowizny_cykliczne_ilo_p_atno_ci__c: number;
  Darowizny_cykliczne_data_ostatniej__c: null | string;
  Darowizny_cykliczne_rednia_kwota__c: null | string;
  Techniczne_02__c: null | string;
  Powiadomienie_o_braku_srodk_w__c: boolean;
  Suma_darowizn__c: number;
  Wojew_dztwo_firmy__c: null | string;
  Wo_acz_formalny__c: string;
  Podopieczny__c: boolean;
  Liczba_porz_d__c: null | string;
  ID18__c: string;
  Upgrade_data_rozmowy__c: null | string;
  Maksymalna_data_PZ__c: null | string;
  Upgrade_rozmowa__c: string;
  Tekstowa_nazwa_firmy__c: null | string;
  Tytu_celownik__c: null | string;
  Tytu_biernik__c: null | string;
  Tytu_wo_acz__c: null | string;
  Wo_acz_formalny_bez_imienia__c: string;
  Cloudrop__DateTime__c: null | string;
  Cloudrop__Invalid__c: boolean;
  Cloudrop__Languages__c: null | string;
  Cloudrop__Level__c: null | string;
  Wskazuje_na_Skarbonk__c: boolean;
  Choroba__c: string;
  Data_rozpocz_cia_subskrypcji__c: null | string;
  Ilo_podpisanych_petycji__c: number;
  Wszystkie_transakcje_PZ__c: number;
  daneztransakcji__c: null | string;
  Data_podpisania_umowy__c: null | string;
  Data_wys_ania_formularza__c: null | string;
  Ilo_podstaw_prawnych__c: number;
  Wskazuje_na_Skarbonkogolnie__c: boolean;
  Mailing_pole_steruj_ce__c: boolean;
  Konto_uzytkownika__c: boolean;
  Regularni__c: boolean;
  PESEL__c: null | string;
  Program_beneficjencki__c: boolean;
  Last_DD_Cancelation_Date__c: null | string;
  CTP_rozpocz_cie_okresu__c: null | string;
  CTP_zako_czenie_okresu__c: null | string;
  CTP_ilo_p_atno_ci__c: number;
  Last_DD_Cancelation_Code__c: null | string;
  Ostateczny_kod_odrzucenia__c: null | string;
  Ostateczna_data_odrzucenia__c: null | string;
  Status_darczy_cy__c: string;
  P_atno_ci_regularne_data_ostatniej__c: null | string;
  GR_usuni_to__c: boolean;
  Przeprowad_deduplikacj__c: boolean;
  Priorytet_deduplikacji__c: string;
  Duplikat__c: boolean;
  Przeprowad_kontrol_duplikat_w__c: boolean;
  Docelowy_kontakt__c: null | string;
  Inny_priorytet_deduplikacji__c: string;
  Identyfikator_kontaktu__c: null | string;
  Docelowy__c: boolean;
  Typ_firmy__c: null | string;
  Media__c: boolean;
  Firmy__c: boolean;
  Zbiorki__c: boolean;
  Informacja_o_chorobie__c: null | string;
  user_Id__c: null | string;
  Osoba_chora__c: boolean;
  Lekarz__c: boolean;
  Partner_programu_beneficjenckiego__c: boolean;
  Opiekun__c: boolean;
  Wskazanie_na_opiekuna__c: null | any[];
  Wskazanie_podopiecznego__c: null | any[];
  Id_zbi_rki__c: null | string;
  Cel_zbiorki__c: null | string;
  Link_do_zbi_rki__c: null | string;
  Tytu_zbi_rki__c: null | string;
  Id_zbi_rki_ostatnio_wspartej__c: null | string;
  Cel_zbiorki_ostatnio_wspartej__c: null | string;
  Dost_pne_rodki__c: null | string;
  Link_do_zbiorki_ostatnio_wspartej__c: null | string;
  Tytu_zbiorki_ostatnio_wspartej__c: null | string;
  Dost_pne_rodki_ostatnio_wspartej__c: null | string;
  Wszystkie_wyp_aty_ostatnio_wspartej__c: null | string;
  Zgoda_na_profilowanie__c: boolean;
  Profilowanie__c: string;
  Etap_leczenia__c: null | string;
  agree_rodo__c: boolean;
  agree_service_alivia__c: boolean;
  agree_medical_info__c: boolean;
  agree_contact_email__c: boolean;
  agree_contact_phone__c: boolean;
  Do_usuni_cia__c: boolean;
  Ostatnie_logowanie_HUB__c: null | string;
  ID_nowotworu_HUB__c: null | string;
  pollID__c: number | null;
  Partner__c: boolean | null;
}

export interface User {
  id: number;
  name: string;
  email: string;
  avatar: Avatar | null;
  roles: UserRole[];
  authId: string;
  deleted: number;
  impersonated: boolean;
  status: number;
  role?: string;
  createdAt: string;
  updateAt: string;
  settings: SFContact;
  localSettings: Settings;
  imageIds?: ImageIds[];
  sfContact?: SFContact;
  firstName: string;
  lastName: string;
}

export interface MeUser {
  name: string;
  authId: string;
  email?: string;
  avatar: Avatar | null;
  avatarId?: string;
  roles: UserRole[];
  settings: SFContact;
  localSettings: Settings;
}

export interface PatchMeUserPayload {
  name?: string;
  email?: string;
  authId?: string;
  avatar?: Avatar | null;
  avatarId?: string;
  roles?: UserRole[];
  settings?: SFContact;
  localSettings?: Settings;
}

export interface UserState {
  isLogged: boolean;
  authUser: User;
  userRoles: Role[];
}

export interface WidgetSetting {
  name: string;
  position: number;
  isActive: boolean;
  id: number;
  forRole: ForRole[] | ForRole;
}

interface Settings {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  mobilePhone: string;
  birthdate: string;
  mailingStreet: string;
  mailingCity: string;
  mailingPostalCode: string;
  mailingCountry: string;
  mailingProvince: string;
  mailingProvinceId: number;
  sex__c: string;
  Indetyfikator_kontaktu__c: string;
  Status_u_ytkownika__c: string;
  Status_choroby__c: string;
  user_Id: number;
  facility_Id: number | null;
  cancer_Id: number;
  patient_poll_Id: number | null;
  doctor_poll_Id: number | null;
  partner_poll_Id: number | null;
  guardian_poll_Id: number | null;
  cancer_slug: string;
  agree_profiling: boolean;
  agree_rodo: boolean;
  agree_service_alivia: boolean;
  agree_medical_info: boolean;
  agree_contact_email: boolean;
  agree_contact_phone: boolean;
  Grupa_wiekowa__c: string;
  Osoba_chora__c: boolean;
  Lekarz__c: boolean;
  Partner__c: boolean;
  Opiekun__c: boolean;
  Wskazanie_na_opiekuna__c: string[];
  Wskazanie_podopiecznego__c: string[];
  Skarbonka_ID__c: string;
  Cel_zbiorki__c: string;
  OZ_link__c: string;
  Tytul_zbiorki__c: string;
  Dost_pne_rodki__c: string;
  Sum_of_Payout__c: string;
  Cel_zbiorki_ow__c: string;
  OZ_link_ow__c: string;
  Tytul_zbiorki_ow_cc: string;
  dost_pne_rodki_ostatnio_wspartej__c: string;
  wszystkie_wyp_aty_ostatnio_wspartej__c: string;
  saved_postsId: string[];
  description?: string;
  widgetsSettings: WidgetSetting[];
  pushSubscription?: boolean;
  saved_articlesId: string[];
}

export const initialState: UserState = {
  isLogged: false,
  userRoles: [],
  authUser: {
    name: "",
    email: "",
    avatar: null,
    roles: [],
    authId: "",
    id: 0,
    deleted: 0,
    status: 0,
    createdAt: "",
    updateAt: "",
    firstName: "",
    lastName: "",
    impersonated: false,
    settings: {
      attributes: {
        type: "",
        url: "",
      },
      Id: "",
      IsDeleted: false,
      MasterRecordId: null,
      AccountId: "",
      LastName: "",
      FirstName: "",
      Salutation: null,
      Name: "",
      RecordTypeId: "",
      OtherStreet: null,
      OtherCity: null,
      OtherState: null,
      OtherPostalCode: null,
      OtherCountry: null,
      OtherLatitude: null,
      OtherLongitude: null,
      OtherGeocodeAccuracy: null,
      OtherAddress: null,
      MailingStreet: null,
      MailingCity: null,
      MailingState: null,
      MailingPostalCode: null,
      MailingCountry: null,
      MailingLatitude: null,
      MailingLongitude: null,
      MailingGeocodeAccuracy: null,
      MailingAddress: null,
      Phone: null,
      Fax: null,
      MobilePhone: null,
      HomePhone: null,
      OtherPhone: null,
      AssistantPhone: null,
      ReportsToId: null,
      Email: "",
      Title: null,
      Department: null,
      AssistantName: null,
      LeadSource: null,
      Birthdate: null,
      Description: null,
      CurrencyIsoCode: "",
      OwnerId: "",
      HasOptedOutOfEmail: false,
      HasOptedOutOfFax: false,
      DoNotCall: false,
      CreatedDate: "",
      CreatedById: "",
      LastModifiedDate: "",
      LastModifiedById: "",
      SystemModstamp: "",
      LastActivityDate: null,
      LastCURequestDate: null,
      LastCUUpdateDate: null,
      LastViewedDate: "",
      LastReferencedDate: "",
      EmailBouncedReason: null,
      EmailBouncedDate: null,
      IsEmailBounced: false,
      PhotoUrl: "",
      Jigsaw: null,
      JigsawContactId: null,
      Formularze__c: 0,
      Alior_Data__c: null,
      Darczyncy__c: 0,
      Zrodlo_PZ_kontakt__c: null,
      Gender__c: null,
      GR_imi_i_nazwisko__c: "",
      Darowizny_wszystkie_data_ostatniej__c: null,
      sendgrid4sf__Email_Verification_Date__c: null,
      sendgrid4sf__Email_Verification_Status__c: null,
      sendgrid4sf__Invalid_SendGrid_Contact__c: false,
      sendgrid4sf__MassLastStayinTouchRequestDate__c: null,
      sendgrid4sf__MassLastStayinTouchSaveDate__c: null,
      sendgrid4sf__MassMailer_Email_Status_Count__c: 1,
      Program_powitalny_kontakt__c: null,
      Wielko_miasta__c: null,
      Czas_od_p_atno_ci_jednorazowej__c: null,
      Data_edycji_rekordu__c: "",
      Kategoria_deduplikacji__c: "",
      Status_u_ytkownika__c: "",
      Zakonczono_identyfikacj_ODO__c: false,
      Status_PZ__c: null,
      sendgrid4sf__Email_Verification_substatus__c: null,
      Skieruj_do_TM__c: false,
      Data_wys_ania_pakietu_powitalnego__c: null,
      Nale_y_wys_a_pakiet_powiatalny__c: "",
      Pakiet_retencyjny_wysy_ka__c: null,
      Pakiet_retencyjny_data_wys_ania__c: "",
      Data_i_czas_powstania_kontaktu__c: "",
      Wiek__c: null,
      Grupa_wiekowa__c: null,
      Bank__c: null,
      Polecenie_zap_aty_rednia_kwota__c: null,
      Darowizny_jednorazowe_rednia__c: null,
      Status_kontaktu__c: "",
      Kwota_przekazana__c: 0,
      Program_Middle_Donor__c: false,
      Wszystkie_darowizny__c: null,
      X1_podatku_rednia_kwota__c: null,
      Otrzymane_darowizny_rednia_kwota__c: null,
      Wszystkie_wyp_aty_rednia_kwota__c: null,
      Darczy_ca_cykliczny__c: false,
      Program_darowizn_jednorazowych__c: "",
      Cel_ostatniej_darowizny__c: null,
      Update__c: false,
      Skarbonka__c: 0,
      Skarbonka_suma_p_atno_ci__c: 0,
      Skarbonka_rednia_kwota__c: null,
      Skarbonka_data_ostatniej_p_atno_ci__c: null,
      Darowizny_jednorazowe__c: null,
      X1_podatku_data_ostatniej__c: null,
      Polecenie_zap_aty_data_ostatniej__c: null,
      Wszystkie_wyp_aty_data_ostatniej__c: null,
      Otrzymane_darowizny_data_ostatniej__c: null,
      Darowizny_cykliczne_suma__c: 0,
      Darowizny_cykliczne_ilo_p_atno_ci__c: 0,
      Darowizny_cykliczne_data_ostatniej__c: null,
      Darowizny_cykliczne_rednia_kwota__c: null,
      Techniczne_02__c: null,
      Powiadomienie_o_braku_srodk_w__c: false,
      Suma_darowizn__c: 0,
      Wojew_dztwo_firmy__c: null,
      Wo_acz_formalny__c: "",
      Podopieczny__c: false,
      Liczba_porz_d__c: null,
      ID18__c: "",
      Upgrade_data_rozmowy__c: null,
      Maksymalna_data_PZ__c: null,
      Upgrade_rozmowa__c: "",
      Tekstowa_nazwa_firmy__c: null,
      Tytu_celownik__c: null,
      Tytu_biernik__c: null,
      Tytu_wo_acz__c: null,
      Wo_acz_formalny_bez_imienia__c: "",
      Cloudrop__DateTime__c: null,
      Cloudrop__Invalid__c: false,
      Cloudrop__Languages__c: null,
      Cloudrop__Level__c: null,
      Wskazuje_na_Skarbonk__c: false,
      Choroba__c: "",
      Data_rozpocz_cia_subskrypcji__c: null,
      Ilo_podpisanych_petycji__c: 0,
      Wszystkie_transakcje_PZ__c: 0,
      daneztransakcji__c: null,
      Data_podpisania_umowy__c: null,
      Data_wys_ania_formularza__c: null,
      Ilo_podstaw_prawnych__c: 0,
      Wskazuje_na_Skarbonkogolnie__c: false,
      Mailing_pole_steruj_ce__c: false,
      Konto_uzytkownika__c: false,
      Regularni__c: false,
      PESEL__c: null,
      Program_beneficjencki__c: false,
      Last_DD_Cancelation_Date__c: null,
      CTP_rozpocz_cie_okresu__c: null,
      CTP_zako_czenie_okresu__c: null,
      CTP_ilo_p_atno_ci__c: 0,
      Last_DD_Cancelation_Code__c: null,
      Ostateczny_kod_odrzucenia__c: null,
      Ostateczna_data_odrzucenia__c: null,
      Status_darczy_cy__c: "",
      P_atno_ci_regularne_data_ostatniej__c: null,
      GR_usuni_to__c: false,
      Przeprowad_deduplikacj__c: false,
      Priorytet_deduplikacji__c: "",
      Duplikat__c: false,
      Przeprowad_kontrol_duplikat_w__c: false,
      Docelowy_kontakt__c: null,
      Inny_priorytet_deduplikacji__c: "",
      Identyfikator_kontaktu__c: null,
      Docelowy__c: false,
      Typ_firmy__c: null,
      Media__c: false,
      Firmy__c: false,
      Zbiorki__c: false,
      Informacja_o_chorobie__c: null,
      user_Id__c: null,
      Osoba_chora__c: false,
      Lekarz__c: false,
      Partner_programu_beneficjenckiego__c: false,
      Opiekun__c: false,
      Wskazanie_na_opiekuna__c: null,
      Wskazanie_podopiecznego__c: null,
      Id_zbi_rki__c: null,
      Cel_zbiorki__c: null,
      Link_do_zbi_rki__c: null,
      Tytu_zbi_rki__c: null,
      Id_zbi_rki_ostatnio_wspartej__c: null,
      Cel_zbiorki_ostatnio_wspartej__c: null,
      Dost_pne_rodki__c: null,
      Link_do_zbiorki_ostatnio_wspartej__c: null,
      Tytu_zbiorki_ostatnio_wspartej__c: null,
      Dost_pne_rodki_ostatnio_wspartej__c: null,
      Wszystkie_wyp_aty_ostatnio_wspartej__c: null,
      Zgoda_na_profilowanie__c: false,
      Profilowanie__c: "",
      Etap_leczenia__c: null,
      agree_rodo__c: false,
      agree_service_alivia__c: false,
      agree_medical_info__c: false,
      agree_contact_email__c: false,
      agree_contact_phone__c: false,
      Do_usuni_cia__c: false,
      Ostatnie_logowanie_HUB__c: null,
      ID_nowotworu_HUB__c: null,
      pollID__c: 0,
      Partner__c: null,
    },
    localSettings: {
      firstName: "",
      lastName: "",
      displayName: "",
      email: "",
      mobilePhone: "",
      birthdate: "",
      mailingStreet: "",
      mailingCity: "",
      mailingProvince: "",
      mailingProvinceId: 0,
      mailingPostalCode: "",
      mailingCountry: "",
      sex__c: "",
      Indetyfikator_kontaktu__c: "",
      Status_u_ytkownika__c: "",
      Status_choroby__c: "",
      user_Id: 0,
      facility_Id: 0,
      cancer_Id: 0,
      patient_poll_Id: null,
      doctor_poll_Id: null,
      partner_poll_Id: null,
      guardian_poll_Id: null,
      cancer_slug: "",
      agree_profiling: false,
      agree_rodo: false,
      agree_service_alivia: false,
      agree_medical_info: false,
      agree_contact_email: false,
      agree_contact_phone: false,
      Grupa_wiekowa__c: "",
      Osoba_chora__c: false,
      Lekarz__c: false,
      Partner__c: false,
      Opiekun__c: false,
      Wskazanie_na_opiekuna__c: [],
      Wskazanie_podopiecznego__c: [],
      Skarbonka_ID__c: "",
      Cel_zbiorki__c: "",
      OZ_link__c: "",
      Tytul_zbiorki__c: "",
      Dost_pne_rodki__c: "",
      Sum_of_Payout__c: "",
      Cel_zbiorki_ow__c: "",
      OZ_link_ow__c: "",
      Tytul_zbiorki_ow_cc: "",
      dost_pne_rodki_ostatnio_wspartej__c: "",
      wszystkie_wyp_aty_ostatnio_wspartej__c: "",
      saved_postsId: [],
      description: "",
      widgetsSettings: [],
      saved_articlesId: [],
    },
  },
};

interface SetUser {
  payload: User;
}

interface SetUserRoles {
  payload: Role[];
}

interface SetLogged {
  payload: boolean;
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogged: (state, action: SetLogged) => {
      state.isLogged = action.payload;
    },
    setUser: (state, action: SetUser) => {
      state.authUser = action.payload;
    },
    setUserRoles: (state, action: SetUserRoles) => {
      state.userRoles = action.payload;
    },
  },
});

export const { setLogged, setUser, setUserRoles } = userSlice.actions;

export default userSlice.reducer;
