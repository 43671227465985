import { useFieldArray } from "react-hook-form";
import PollQuestion from "../PollQuestion/PollQuestion";
import { Button, Container } from "react-bootstrap";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { useFormFieldsAccordionStatuses } from "Helpers/useFormFieldsAccordionStatuses";

interface PollQuestionProps {
  pageIndex: number;
}

const PollQuestions = ({ pageIndex }: PollQuestionProps) => {
  const {
    fields: questionFields,
    remove,
    append,
    move,
  } = useFieldArray({
    name: `pages[${pageIndex}].questions`,
  });

  const {
    checkAccordionActiveStatus,
    changeAccordionStatusHandler,
    accordionOpeningHandler,
    deleteAccordionStatusHandler,
    moveAccordionStatusHandler,
  } = useFormFieldsAccordionStatuses(questionFields);

  const handleDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    moveAccordionStatusHandler(source.index, destination.index);
    move(source.index, destination.index);
  };

  const buttonStyles = "lh-base text-light";

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable key={`q-${uuidv4()}`} droppableId="pollQuestions">
          {(questionsProvided) => (
            <Container
              fluid
              className="p-0"
              ref={questionsProvided.innerRef}
              {...questionsProvided.droppableProps}
            >
              {questionFields.map((q, index) => (
                <PollQuestion
                  key={q.id}
                  id={q.id}
                  pageIndex={pageIndex}
                  index={index}
                  remove={remove}
                  isAccordionActive={checkAccordionActiveStatus(index)}
                  changeAccordionStatus={() =>
                    changeAccordionStatusHandler(index)
                  }
                  openAccordion={accordionOpeningHandler}
                  deleteAccordionStatus={() =>
                    deleteAccordionStatusHandler(index)
                  }
                />
              ))}
              {questionsProvided.placeholder}
            </Container>
          )}
        </Droppable>
      </DragDropContext>

      <Container fluid className="px-3 py-2 d-flex justify-content-end">
        <Button
          className={buttonStyles}
          onClick={() =>
            append({
              fieldName: `field_${uuidv4()}`,
              isActive: 1,
            })
          }
        >
          Dodaj pytanie
        </Button>
      </Container>
    </>
  );
};

export default PollQuestions;
