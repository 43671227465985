import { Container, Spinner, Table } from "react-bootstrap";
import React from "react";
import { dateEmbellishment } from "../../../../Helpers/dateHelpers";

interface UsersTableProps {
  data: OiIndex[];
}

export const IndexTable = ({ data }: UsersTableProps) => {
  if (!data) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  const thClass = "fw-normal border-0 text-dark-blue";
  const trClass = "container-fluid border border-1";

  return (
    <Table>
      <thead>
        <tr className={trClass}>
          <th className={thClass}>Index</th>
          <th className={thClass}>status</th>
          <th className={thClass}>Kraj</th>
          <th className={thClass}>data publikacji</th>
          <th className={thClass}>data utworzenia</th>
          <th className={thClass}>data akutalizacji</th>
        </tr>
      </thead>
      <tbody className="fs-13">
        {data.map((index: OiIndex) => (
          <tr className={trClass}>
            <th className={thClass}>{index.id}</th>
            <th className={thClass}>
              {index.status ? "aktywny" : "nie aktywny"}
            </th>
            <th className={thClass}>{index.country.name}</th>
            <th className={thClass}>
              {dateEmbellishment(index.publishedAt, "-")}
            </th>
            <th className={thClass}>
              {dateEmbellishment(index.createdAt, "-")}
            </th>
            <th className={thClass}>
              {dateEmbellishment(index.updatedAt, "-")}
            </th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
