import PollSendingTemplate from "components/templates/PollSendingTemplate/PollSendingTemplate";
import { useParams } from "react-router-dom";
import { useGetPollQuery } from "redux/services/pollsApi";
import { createPollMetaFormData } from "Helpers/pollMetaHandlers";
const PollEditPage = () => {
  const linkParams = useParams();
  const { data: pollQueryData, isLoading } = useGetPollQuery(linkParams?.id);

  if (isLoading) {
    return <p>Ładowanie...</p>;
  }

  const pollData: PollEditData = pollQueryData?.data;

  const editedPollData: PollSendingData = {
    title: pollData?.title,
    description: pollData?.description,
    languageId: pollData?.language?.id,
    userRoleId: pollData?.userRole.id,
    verificationType: pollData?.verificationType,
    adminVerification: pollData?.adminVerification,
    isReusable: pollData?.isReusable,
    isActive: pollData?.isActive,
    sfConnection: pollData?.sfConnection,
    pages: pollData?.pages.map((page) => ({
      title: page?.title,
      description: page?.description,
      isActive: page?.isActive,
      questions: page?.questions.map((question) => ({
        question: question?.question,
        description: question?.description,
        sfSynchro: !!question?.sfSynchro,
        fieldName: question?.fieldName,
        fieldType: question?.fieldType,
        isActive: question?.isActive,
        type: question?.type ?? "",
        target: question?.target ?? "",
        isOutputTableName: question?.meta?.some(
          (meta) => meta.key === "output_table_name"
        ),
        areLabelsNeeded: question?.meta?.some((meta) => meta.key === "labels"),
        meta: createPollMetaFormData(question?.meta, question?.fieldType),
      })),
    })),
  };

  return <PollSendingTemplate data={editedPollData} action="PATCH" />;
};

export default PollEditPage;
