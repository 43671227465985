import { pollUsers } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["PollUsers"],
});

type PollUsersQueryParams = {
  sortOrder?: string;
  orderBy?: string;
  user?: string;
  poll?: string;
  offset?: string;
  limit?: string;
};
type PollUserByIdQueryParams = {
  id: number;
};
interface SendVerificationSmsData {
  phone: string;
}

interface SendVerificationSmsRequest {
  id: number;
  data: SendVerificationSmsData;
}

interface SendVerificationEmailData {
  email: string;
}

interface SendVerificationEmailRequest {
  id: number;
  data: SendVerificationEmailData;
}

interface CheckVerificationCodeData {
  verificationHash: string;
}

interface CheckVerificationCodeRequest {
  id?: number;
  data: CheckVerificationCodeData;
}
export const usersApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createPollUser: builder.mutation({
      query: (data) => ({
        url: `${pollUsers}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PollUsers"],
    }),

    getPollUsers: builder.query({
      query: ({
        sortOrder,
        orderBy,
        user,
        poll,
        offset,
        limit,
      }: PollUsersQueryParams) => {
        const params = new URLSearchParams({
          ...(sortOrder && { sortOrder }),
          ...(orderBy && { orderBy }),
          ...(user && { user }),
          ...(poll && { poll }),
          ...(offset !== undefined && { offset: offset.toString() }),
          ...(limit !== undefined && { limit: limit.toString() }),
        });

        return {
          url: `${pollUsers}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PollUsers"],
    }),
    getPollUserById: builder.query({
      query: ({ id }: PollUserByIdQueryParams) => {
        return {
          url: `${pollUsers}/${id.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["PollUsers"],
    }),

    sendVerificationSms: builder.mutation({
      query: ({ id, data }: SendVerificationSmsRequest) => ({
        url: `${pollUsers}/${id}/verification-sms`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PollUsers"],
    }),

    sendVerificationEmail: builder.mutation({
      query: ({ id, data }: SendVerificationEmailRequest) => ({
        url: `${pollUsers}/${id}/verification-email`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PollUsers"],
    }),

    checkVerificationCode: builder.mutation({
      query: ({ id, data }: CheckVerificationCodeRequest) => ({
        url: `${pollUsers}/${id}/check-code`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["PollUsers"],
    }),
  }),
});

export const {
  useCreatePollUserMutation,
  useGetPollUsersQuery,
  useGetPollUserByIdQuery,
  useSendVerificationSmsMutation,
  useSendVerificationEmailMutation,
  useCheckVerificationCodeMutation,
} = usersApi;
