import NewTranslationCreationTab from "components/organisms/NewTranslationCreationTab/NewTranslationCreationTab";
import TranslationCard from "components/organisms/TranslationCard/TranslationCard";

const TranslationsTemplate: React.FC = () => {
  return (
    <section className="container-fluid bg-light pt-2 px-4 pb-3">
      <h2 className="fs-1 lh-base fw-bolder text-center">Tłumaczenia</h2>
      <NewTranslationCreationTab className="mb-3" />
      <TranslationCard />
    </section>
  );
};

export default TranslationsTemplate;
