import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import styles from "./TimeInput.module.scss";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

const TimeInput: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}) => {
  const {
    register,
    setValue,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const fieldSizeMeta = question?.meta?.find((m) => m.key === "field_size");
  const fieldSize = fieldSizeMeta ? JSON.parse(fieldSizeMeta.value).lg : "12";

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(question?.fieldName, defaultValue);
    }
  }, [defaultValue, setValue, question?.fieldName]);

  return (
    <div className={`mb-2 mt-2 col-lg-${fieldSize}`}>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
        </Form.Label>
      )}
      <div className={`${styles.inputContainer}`}>
        <Form.Control
          {...register(question?.fieldName, {
            required: requiredMessage,
          })}
          type="time"
          className="form-control"
          isInvalid={!!errors[question?.fieldName]}
          disabled={readOnly}
        />
      </div>
      {(nextPageClicked || isSubmitted) && errors[question?.fieldName] && (
        <ErrorMessage
          errors={errors}
          name={question?.fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          className="mt-1"
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default TimeInput;
