import React from "react";
import { ReactComponent as PatientIcon } from "./../../assets/Icons/RoleIcons/PatientIcon.svg"
import { ReactComponent as DoctorIcon } from "./../../assets/Icons/RoleIcons/DoctorIcon.svg";
import { ReactComponent as SupporterIcon } from "./../../assets/Icons/RoleIcons/SupporterIcon.svg";
import { ReactComponent as DontKnowIcon } from "./../../assets/Icons/RoleIcons/DontKnowIcon.svg";
import { ReactComponent as PatronIcon } from "./../../assets/Icons/RoleIcons/PatronIcon.svg";

// eslint-disable-next-line array-callback-return

export const useRoleImage = (
  role: Role) => {
  //eslint-disable-next-line
  return () => {
    switch (role.role) {
      case "doctor":
        return <DoctorIcon />;
      case "guardian":
        return <PatronIcon />;
      case "patient":
        return <PatientIcon />;
      case "sponsor":
        return <SupporterIcon />;
      case "sympathizer":
        return <DontKnowIcon />;
    }
  };
};
