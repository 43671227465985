export const getTruncateDescription = (
  description: string,
  maxLength: number
) => {
  if (description.length <= maxLength) {
    return description;
  }

  const truncatedText = description.substring(0, maxLength);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  if (lastSpaceIndex !== -1) {
    return truncatedText.substring(0, lastSpaceIndex) + "...";
  } else {
    return truncatedText + "...";
  }
};
