import Modal from "react-bootstrap/Modal";
import React from "react";

interface Props {
  show: boolean;
  title: string;
  children: React.ReactNode;
  onHide: () => void;
  headerClass?: string;
  bodyClass?: string;
  size?: "sm" | "lg" | "xl";
}

export const ModalInfo = ({
  show,
  children,
  onHide,
  title,
  bodyClass,
  headerClass = "text-dark-blue",
  size,
}: Props) => {
  return (
    <Modal
      size={size ?? "xl"}
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className={"border-0"} closeButton>
        <Modal.Title className={headerClass}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClass}>{children}</Modal.Body>
    </Modal>
  );
};
