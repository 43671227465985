import { handleNewField } from "Helpers/helpers";
import AsyncSelect from "react-select/async";
import { Province } from "../LoyaltyProgramServiceArticle/types";
import { AsyncSelectLoadingProps } from "./types";
import { DropdownIndicator } from "../../../styles/reactSelectCustomStyles";

const AsyncSelectLoading = ({
  data,
  placeholder,
  setValue,
  name,
  width,
  label,
  relativeoptions,
  asyncQuery,
  value,
  menuIsOpen,
  setHandleReset,
  disabled,
  noOptionsComponent,
  onChange,
  filter,
  wrapperClasses,
  labelClasses,
  inputClasses,
  styles,
}: AsyncSelectLoadingProps) => {
  const source = data?.data;

  const filterData = (inputValue: string) => {
    return handleNewField(source).filter((i: any) => {
      return i.label.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  const promiseOptions = async (inputValue: string) => {
    if (inputValue.length > 3 && !inputValue.includes(" ")) {
      const res = await asyncQuery({
        nameLike: inputValue,
        q: inputValue,
        limit: "100",
        sortBy: "name,ASC",
        provinceId: filter,
      });
      if (res?.data?.data?.length === 0 && name === "doctorId") {
        return handleNewField([{ name: inputValue, id: inputValue }]);
      } else {
        return handleNewField(res.data.data);
      }
    }

    if (inputValue?.length > 3 && inputValue?.includes(" ")) {
      let res = await filterData(inputValue);
      if (res?.length === 0 && name === "doctorId") {
        return handleNewField([{ name: inputValue, id: inputValue }]);
      }
      return new Promise<string>((resolve) => {
        resolve(filterData(inputValue));
      });
    }
  };

  const handleCategoryInputChange = (newValue: any) => {
    setHandleReset && setHandleReset(true);
    setValue(
      name,
      name === "settings.MailingCity" ? newValue?.label : newValue?.value || ""
    );
    if (name === "settings.MailingState" && newValue != null) {
      onChange && onChange(newValue.value);
      const provinces: Province[] = source.filter(
        (province: Province) => province.id === newValue.value
      );
      setValue("localSettings.mailingProvinceId", provinces[0].id);
      setValue("settings.MailingState", provinces[0].name);
    }
    if (name === "facilityId" && newValue != null) {
      const facility = source.filter(
        (facility: FacilityData) => facility.id === newValue.value
      );
      setValue("cityId", facility[0].city.id.toString());
      setValue("provinceId", facility[0].city.provinceId.toString());
      setValue("street", facility[0].street);
    } else {
      setValue("cityId", undefined);
      setValue("provinceId", undefined);
    }
  };

  return (
    <div className={wrapperClasses}>
      <label
        className={`form-check-label col-auto d-flex fs-16 fw-400 flex-column text-dark-blue`}
      >
        {label && (
          <span className={`${labelClasses} me-3 mb-1 w-100 text-dark-blue`}>
            {label}
          </span>
        )}
        <AsyncSelect
          cacheOptions
          defaultOptions={source && handleNewField(source)}
          components={{ DropdownIndicator }}
          menuIsOpen={menuIsOpen}
          isClearable
          isSearchable
          isDisabled={disabled}
          noOptionsMessage={() =>
            !noOptionsComponent ? "Brak danych" : noOptionsComponent
          }
          placeholder={placeholder || "Wybierz"}
          loadOptions={promiseOptions}
          value={value}
          loadingMessage={() => "Ładowanie danych"}
          classNamePrefix={relativeoptions ? "relativeSelect" : "select"}
          onChange={handleCategoryInputChange}
          styles={styles}
        />
      </label>
    </div>
  );
};

export default AsyncSelectLoading;
