import { Button, Stack } from "react-bootstrap";
import { FilterPanel } from "components/molecules/FilterPanel/FilterPanel";
import { FormSwitchWithLabel } from "components/molecules/FormSwitchWithLabel/FormSwitchWithLabel";
import React from "react";
import styles from "../../RecommendedDoctorsFilter/RecommendedDoctorsFilter.module.scss";
import { useTranslation } from "react-i18next";

interface ArticlesCollapseProps {
  categories: string[];
  sources: string[];
  showSaved: boolean;
  selectedTopic: ReactSelectOption;
  selectedSources: string[];
  onSourceChange: (value: string | undefined) => void;
  onFiltersReset: () => void;
  onTopicChange: (value?: string, label?: string) => void;
  onSwitchSaved: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyFilters: () => void;
  topics: ReactSelectOption[];
  onCancerChange: (value?: string, label?: string) => void;
  cancers: ReactSelectOption[];
  selectedCancer: ReactSelectOption;
}

const ArticlesCollapse = ({
  sources,
  showSaved,
  onSwitchSaved,
  selectedTopic,
  onTopicChange,
  selectedSources,
  onSourceChange,
  onFiltersReset,
  onApplyFilters,
  topics,
  selectedCancer,
  cancers,
  onCancerChange,
}: ArticlesCollapseProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <FormSwitchWithLabel
        label={t("articles_collapse_show_saved_switch_title")}
        isSwitched={showSaved}
        onSwitchChange={onSwitchSaved}
      />
      <Stack gap={4}>
        <FilterPanel
          label={t("articles_collapse_article_source_title")}
          categories={sources}
          selectedCategories={selectedSources}
          onCategoryClick={onSourceChange}
          type="button"
        />
        <div className={"w-100"}>
          <FilterPanel
            label={t("articles_collapse_article_topic_title")}
            categories={topics}
            selectedCategories={selectedTopic}
            onCategoryClick={onTopicChange}
            type={"React-Select"}
            selectPlaceholder={"Wybierz"}
          />
        </div>
        <div className={"w-100"}>
          <FilterPanel
            label={t("articles_collapse_article_topic_by_cancer_title")}
            categories={cancers}
            selectedCategories={selectedCancer}
            onCategoryClick={onCancerChange}
            type={"React-Select"}
            selectPlaceholder={"Wybierz"}
          />
        </div>
      </Stack>
      <div className="d-lg-flex justify-content-lg-end mt-5">
        <button
          className={`me-3 mt-0 mb-3 mb-lg-0 text-dark-blue fs-16 bg-white btn ${styles.recommendedButton} ${styles.resetBtn}`}
          type="reset"
          value="reset"
          onClick={onFiltersReset}
        >
          Resetuj filtry
        </button>
        <Button
          className={`text-white fs-16 ${styles.recommendedButtonSubmit}`}
          variant="primary"
          type="submit"
          value="Submit"
          onClick={onApplyFilters}
        >
          Zastosuj filtry
        </Button>
      </div>
    </div>
  );
};

export default ArticlesCollapse;
