import React from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
import { useGetPageQuery } from "../../../redux/services/pagesApi";
import { AccordionCard } from "../../molecules/AccordionCard/AccordionCard";
import { useOpenHelpTopicMessage } from "../../../Helpers/openMessageHooks";

interface Props {
  pageId: string;
  noAnswear?: boolean;
}

export const FaqContent = (props: Props) => {
  const BENEFITS_TOPIC_ID = 7;
  const STATUS_TOPIC_ACTIVE = 1;

  const TOPIC_DATA: ChatCase = {
    id: BENEFITS_TOPIC_ID,
    case: "Benefity",
    status: STATUS_TOPIC_ACTIVE,
  };
  const { data: pagesData, isLoading } = useGetPageQuery(props.pageId);
  const handleClick = useOpenHelpTopicMessage({ topic: TOPIC_DATA });

  if (isLoading) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true}>
      <Row>
        <div className={"fs-22 fw-bolder mb-5 text-dark-blue mx-0"}>
          <span>{pagesData.data.title}</span>
        </div>
      </Row>
      {pagesData.data.childPage.map((value: any, index: number) => {
        return (
          <AccordionCard
            key={index}
            content={value.content}
            title={value.title}
          />
        );
      })}
      {props.noAnswear !== true && (
        <Container fluid={true} className={"p-0"}>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between align-items-center"
            }
          >
            <div className={"mb-2 mb-lg-auto"}>
              <span className={"text-dark-blue"}>
                Nie widzisz odpowiedzi na swoje pytanie? Napisz do nas!
              </span>
            </div>
            <div className={"col-12 col-md-auto"}>
              <Button
                className={"col-12 col-md-auto"}
                variant="outline-primary"
                onClick={handleClick}
              >
                ZADAJ PYTANIE
              </Button>
            </div>
          </div>
        </Container>
      )}
    </Container>
  );
};
