import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import { Badge, Button, Col, Form } from "react-bootstrap";
import { ReactComponent as Pencil } from "../../../assets/Icons/Pencil.svg";
import TooltipIcon from "../../atoms/TooltipIcon";
import PopupModal from "../PopupModal/PopupModal";
import { useTranslation } from "react-i18next";
import { ChangesStatusAbuse } from "../ChangesStatusAbuse/ChangesStatusAbuse";
import { DoctorShort, FacilityShort, Review } from "./types";
import { User } from "../../../redux/Slice/user-slice";
import {
  NotificationTypeEnum,
  usePostNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { domain } from "../../organisms/Landing/Landing";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface Props {
  content: string;
  data: {
    doctorReviewsAbuse?: Review[];
    review?: Review[];
    feedPostCommentAbuse?: Review[];
    id: number | string;
    status: StatusChangeType;
    content: string;
    approvedAt: string | null;
    name: string;
    facility?: FacilityShort;
    doctor?: DoctorShort;
    user?: User;
  };
  handlePatchDataDoctorReview: (
    id: number | string,
    type: DoctorReviewPatchData,
  ) => void;
  id: number;
  link?: string;
  type: AcceptableChanges;
}

export const ChangesStatus = (props: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.content);
  const [isActive, setIsActive] = useState(false);
  const [dataReview, setDataReview] = useState<Review[] | null>(null);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showAbusedModal, setShowAbusedModal] = useState(false);
  const [showRemovedModal, setShowRemovedModal] = useState(false);
  const [info, setInfo] = useState(t(`status_${props.data.status}`));

  const [notification] = usePostNotificationsMutation();

  useEffect(() => {
    switch (props.type) {
      case "doctorsReview":
        setDataReview(
          props.data?.doctorReviewsAbuse
            ? props.data?.doctorReviewsAbuse?.map((review: Review) => ({
                ...review,
                userDefendant: props.data?.user ?? null,
              }))
            : null,
        );
        break;
      case "facilityReview":
      case "feed":
        setDataReview(
          props.data?.review
            ? props.data?.review?.map((review: Review) => ({
                ...review,
                userDefendant: props.data?.user ?? null,
              }))
            : null,
        );
        break;
      case "feedComments":
        setDataReview(
          props.data?.feedPostCommentAbuse
            ? props.data?.feedPostCommentAbuse
            : null,
        );
        break;
    }
    // eslint-disable-next-line
  }, [props.type]);
  const handlePatch = (type: StatusChangeType) => {
    if (props.data.content === value) {
      props.handlePatchDataDoctorReview(props.data.id, { status: type });
    } else {
      props.handlePatchDataDoctorReview(props.data.id, {
        status: type,
        content: value,
      });
    }
    setInfo(t(`status_${type}`));
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handePage = () => {
    let id;

    switch (props.type) {
      case "doctorsReview":
        id = props.data.doctor?.id;
        break;
      case "facilityReview":
        id = props.data.facility?.id;
    }
    window.open(`${props.link}${id}`, "_blank");
  };

  const handleAccept = async () => {
    handlePatch("ACCEPTED");
    if (props.type === "doctorsReview" && props.data?.doctor?.id) {
      await notification({
        data: {
          title: t("notification_ChangesStatus_doctorsReview_accept_title"),
          content: t("notification_ChangesStatus_doctorsReview_accept_content"),
          type: NotificationTypeEnum["doctor"],
          viewSender: 0,
          senderUser: null,
          manyUser: "DOCTOR",
          url: `${domain}/${NAV_PATH.DASHBOARD}#doctorReview`,
          idMany: Number(props.data?.doctor?.id),
        },
      });
    }
  };

  return (
    <Col className={`border border-1 alivia-radius p-3`} key={props.data.id}>
      <div className={"d-flex justify-content-between mb-3"}>
        <div className={"d=flex flex-column fs-14 text-grey "}>
          {props.type === "doctorsReview" && (
            <div>
              lekarz:{" "}
              {props.data?.doctor?.degree && props.data.doctor.degree.name}
              {`${props.data.doctor?.firstName} ${props.data.doctor?.lastName}`}
            </div>
          )}
          {props.type === "facilityReview" && (
            <div>placówka: {props.data.facility?.name}</div>
          )}
          {props.data.name && <div>zgłaszający: {props.data.name}</div>}
        </div>
        <div className={"d-flex gap-3"}>
          {props.link && (
            <Button
              style={{ width: 38, height: 38 }}
              className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
              variant={"transparent"}
              onClick={handePage}
            >
              <ArrowUpRightFromSquare />
            </Button>
          )}
          <Button
            style={{ width: 38, height: 38 }}
            variant={"transparent"}
            className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue`}
            onClick={() => setIsActive((prev) => !prev)}
          >
            <Pencil />
          </Button>
        </div>
      </div>
      <div className={"d-flex justify-content-start mb-2"}>
        {info ? (
          <Badge
            className={`${
              props.data.status === "ACCEPTED" ? "bg-success" : "bg-secondary"
            } `}
          >
            {info}
          </Badge>
        ) : (
          <Badge className={`bg-secondary d-flex align-items-center`}>
            <span className={""}>DO WERYFIKACJI</span>
            {/*<Spinner className={"ms-2"} animation="grow" size="sm" style={{height:10, width:10}} />*/}
          </Badge>
        )}
      </div>
      <div className={"w-100"}>
        <Form.Control
          disabled={!isActive}
          as="textarea"
          rows={5}
          className={`w-100 text-dark-blue bg-white`}
          value={value}
          onChange={handleChange}
        />
      </div>

      {props.data.status === "ABUSED" && dataReview !== null ? (
        <>
          {isActive && (
            <div className={"d-flex justify-content-end gap-2 mt-4"}>
              <Button
                className=" btn-outline-dark-blue fs-16"
                onClick={() => setValue(props.content)}
              >
                cofnij
              </Button>
              <Button className={"fs-16"} onClick={() => handlePatch("ABUSED")}>
                AKCEPTUJ
              </Button>
            </div>
          )}
          <ChangesStatusAbuse
            data={dataReview}
            type={props.type}
            id={props.data.id}
          />
        </>
      ) : (
        <div className={"d-flex justify-content-end gap-2 mt-4"}>
          {isActive && (
            <Button
              className=" btn-outline-dark-blue fs-16"
              onClick={() => setValue(props.content)}
            >
              cofnij
            </Button>
          )}
          {props.data.status !== "ABUSED" && (
            <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
              <Button
                disabled={isActive}
                className="btn-outline-dark-blue fs-16"
                onClick={() => setShowAbusedModal(true)}
              >
                nadużycie
              </Button>
            </TooltipIcon>
          )}
          <PopupModal
            text={"Potwierdź zgłoszenie"}
            type="confirm"
            show={showAbusedModal}
            setShow={() => setShowAbusedModal(false)}
            confirmAction={() => {
              handlePatch("ABUSED");
              setShowAbusedModal(false);
            }}
          />
          {props.data.status !== "REJECTED" && (
            <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
              <Button
                disabled={isActive}
                className="btn-outline-dark-blue fs-16"
                onClick={() => setShowRejectedModal(true)}
              >
                zgłoś
              </Button>
            </TooltipIcon>
          )}
          <PopupModal
            text={"Potwierdź zgłoszenie"}
            type="confirm"
            show={showRejectedModal}
            setShow={() => setShowRejectedModal(false)}
            confirmAction={() => {
              handlePatch("REJECTED");
              setShowRejectedModal(false);
            }}
          />
          {props.data.status !== "REMOVED" && (
            <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
              <Button
                disabled={isActive}
                className="btn-outline-dark-blue fs-16"
                onClick={() => setShowRemovedModal(true)}
              >
                odrzuć
              </Button>
            </TooltipIcon>
          )}
          <PopupModal
            text={"Potwierdź odrzucenie"}
            type="confirm"
            show={showRemovedModal}
            setShow={() => setShowRemovedModal(false)}
            confirmAction={() => {
              handlePatch("REMOVED");
              setShowRemovedModal(false);
            }}
          />
          {props.data.status !== "ACCEPTED" && (
            <TooltipIcon desc={isActive ? "Zamknij edycje" : ""}>
              <Button
                disabled={isActive}
                className={"fs-16"}
                onClick={handleAccept}
              >
                AKCEPTUJ
              </Button>
            </TooltipIcon>
          )}
        </div>
      )}
    </Col>
  );
};
