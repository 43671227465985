import styles from "./PollsError.module.scss";

interface PollsErrorProps {
  errorMessage?: string;
}

const PollsError: React.FC<PollsErrorProps> = ({ errorMessage }) => {
  return (
    <div
      className={`row align-items-stretch mt-5 text-dark-blue p-4 m-0 pt-4 containerWithShadow border
    `}
    >
      <h2 className="fs-22 fw-600 mb-5 mt-1 text-dark-blue ls-3">
        Brak ankiety do wypełeninia
      </h2>
      <p className="text-dark-blue fs-16 fw-600">{errorMessage}</p>
      <p
        onClick={() => window.location.reload()}
        className={`${styles.return} text-dark-blue fs-16`}
      >
        Kliknij tutaj aby powrócić do poprzedniego ekranu
      </p>
    </div>
  );
};

export default PollsError;
