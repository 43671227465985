import { usePatchDoctorMutation } from "../../../redux/services/doctorApi";
import { useGetDoctorSpecializationsQuery } from "../../../redux/services/doctorSpecializationApi";
import { ReactComponent as Xmark } from "../../../assets/Icons/Xmark.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import {
  customStyles,
  DropdownIndicator,
} from "../../../styles/reactSelectCustomStyles";
import { Form, Badge, Button, Stack } from "react-bootstrap";
import Select from "react-select";
import { ActionMeta, SingleValue } from "react-select";
import styles from "./DoctorSpecialization.module.scss";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface DoctorSpecializationProps {
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  data: DoctorSpecializationData;
}

interface DoctorSpecializationData {
  id: number;
  specializations: DoctorSpecializationsData[];
}

interface DoctorSpecializationForm {
  specializationIds: number[];
}

interface SpecializationOption {
  id: number;
  name: string;
}

interface SelectedOption {
  label: string;
  value: number;
}

interface DoctorSpecializationUpdateError {
  data: {
    statusCode: number;
    message: string;
    error: string;
  };
}

type PatchDoctorResponse =
  | {
      data: PatchDoctorResponseData;
    }
  | {
      error: FetchBaseQueryError | SerializedError;
    };

export const DoctorSpecialization = ({
  data,
  isActive,
  isFirst,
  handleActive,
  handleFirst,
}: DoctorSpecializationProps) => {
  const selectedSpecializations = data?.specializations?.map(
    (specialization) => specialization.id
  );

  const [patchDoctor] = usePatchDoctorMutation();

  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<DoctorSpecializationForm>({
    defaultValues: {
      specializationIds:
        data?.specializations?.map((specialization) => specialization.id) ?? [],
    },
  });

  const { data: specializationData } = useGetDoctorSpecializationsQuery({});

  const options = specializationData?.data
    ?.filter(
      (specialization: SpecializationOption) =>
        !selectedSpecializations.includes(specialization.id)
    )
    ?.map((s: SpecializationOption) => ({
      value: s.id,
      label: s.name,
    }));

  const patchDoctorValidation = (patchDoctorResponse: PatchDoctorResponse) => {
    if ("data" in patchDoctorResponse) {
      toast.success(t("toast_doctorSpecialization_success"));
      reset();
    } else if ("error" in patchDoctorResponse) {
      const error =
        patchDoctorResponse.error as DoctorSpecializationUpdateError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_doctorSpecialization_error_500"));

      if (error.data.statusCode === 409)
        toast.error(t("toast_doctorSpecialization_error_409"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_doctorSpecialization_error_404"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_doctorSpecialization_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };

  const handleSpecializationsChange = (
    selectedOption: SingleValue<SelectedOption>,
    actionMeta: ActionMeta<SelectedOption>
  ) => {
    if (actionMeta.action === "clear") {
      setValue("specializationIds", selectedSpecializations);
    } else if (selectedSpecializations) {
      if (selectedOption) {
        setValue("specializationIds", [
          ...selectedSpecializations,
          selectedOption?.value,
        ]);
      }
    }
  };

  const submitHandler: SubmitHandler<DoctorSpecializationForm> = async (
    formData
  ) => {
    const payload = {
      id: data.id,
      data: { specializationIds: formData.specializationIds },
    };

    const hasBeenUpdated = await patchDoctor(payload);

    patchDoctorValidation(hasBeenUpdated);
  };

  const handleDelete = async (id: number) => {
    if (data.specializations.length > 0) {
      const updatedSpecializations = data.specializations.filter(
        (specialization) => specialization.id !== id
      );

      const updatedSpecializationIds = updatedSpecializations.map(
        (specialization) => specialization.id
      );

      const hasBeenUpdated = await patchDoctor({
        id: data.id,
        data: { specializationIds: updatedSpecializationIds },
      });

      patchDoctorValidation(hasBeenUpdated);
    }
  };

  return isActive ? (
    <Form
      className="d-flex flex-column flex-wrap w-100"
      onSubmit={handleSubmit(submitHandler)}
    >
      <p className="text-grey-2 fs-12 fw-semibold ls-6">
        Dodawanie specjalizacji
      </p>
      <Stack direction="horizontal" className="d-flex flex-wrap" gap={3}>
        {data?.specializations?.map(
          (specialization: DoctorSpecializationsData) => (
            <Badge
              bg="info"
              className={`mb-md-2 mb-1 fw-medium ${styles.badge}`}
              key={specialization.id}
            >
              {specialization.searchKeyword}
              <button
                className={`${styles.button}`}
                onClick={() => handleDelete(specialization.id)}
              >
                <Xmark className={`${styles.xMark}`} />
              </button>
            </Badge>
          )
        )}
      </Stack>

      <ComponentWithDescription
        className="my-2 w-100"
        descFs={`fs-12`}
        label={"Twoje specjalizacje"}
        labelFs={`fs-16 mb-1 ${
          errors.specializationIds ? "text-danger" : "text-dark-blue"
        }`}
      >
        <Select
          required={true}
          id="specializationIds"
          isClearable={true}
          isSearchable={true}
          options={options}
          placeholder={"Wybierz z listy"}
          onChange={handleSpecializationsChange}
          styles={customStyles(false)}
          components={{ DropdownIndicator }}
        />
        {errors?.specializationIds && (
          <Form.Control.Feedback type={"invalid"}>
            {errors?.specializationIds?.message}
          </Form.Control.Feedback>
        )}
      </ComponentWithDescription>
      <div className="d-flex gap-2 mt-4 justify-content-end">
        <Button
          className="btn-outline-dark-blue"
          onClick={() => {
            handleActive(false);
            if (data.specializations.length === 0) {
              handleFirst(true);
            }
          }}
        >
          ANULUJ
        </Button>
        <Button type="submit" value="Submit">
          {isFirst ? "AKTUALIZUJ" : "ZAPISZ"}
        </Button>
      </div>
    </Form>
  ) : (
    <>
      <div className="w-100">
        <Stack direction="horizontal" className="d-flex flex-wrap" gap={3}>
          {data.specializations?.map(
            (specialization: DoctorSpecializationsData) => (
              <Badge
                bg="info"
                className="mb-md-2 mb-1 fw-semibold bg-dark-blue"
                key={specialization.id}
              >
                {specialization.searchKeyword}
              </Badge>
            )
          )}
        </Stack>
      </div>
    </>
  );
};
