import { useEffect, useState } from "react";

export const useFormFieldsAccordionStatuses = (
  fields: Record<"id", string>[]
) => {
  const [accordionStatuses, setAccordionStatuses] = useState<AccordionStatus[]>(
    []
  );

  const checkAccordionActiveStatus = (questionIndex: number) => {
    const accordionStatus = accordionStatuses.find(
      (as) => as.questionIndex === questionIndex
    );

    return accordionStatus?.isAccordionActive ?? false;
  };

  useEffect(() => {
    const accordionStatusesData = fields.map((_, idx) => {
      const isAccordionActive = checkAccordionActiveStatus(idx);

      return {
        questionIndex: idx,
        isAccordionActive,
      };
    });

    setAccordionStatuses(accordionStatusesData);
    // eslint-disable-next-line
  }, [fields]);

  const changeAccordionStatusHandler = (questionIndex: number) => {
    const isAccordionActive = checkAccordionActiveStatus(questionIndex);

    const updatedAccordionStatuses = [...accordionStatuses];
    const existingIndex = updatedAccordionStatuses.findIndex(
      (as) => as.questionIndex === questionIndex
    );

    if (existingIndex !== -1) {
      updatedAccordionStatuses[existingIndex].isAccordionActive =
        !isAccordionActive;
    } else {
      updatedAccordionStatuses.push({
        questionIndex,
        isAccordionActive: true,
      });
    }

    setAccordionStatuses(updatedAccordionStatuses);
  };

  const accordionOpeningHandler = (questionIndex: number) => {
    const updatedAccordionStatuses = [...accordionStatuses];
    const existingIndex = updatedAccordionStatuses.findIndex(
      (as) => as.questionIndex === questionIndex
    );

    if (existingIndex !== -1) {
      updatedAccordionStatuses[existingIndex].isAccordionActive = true;
    } else {
      updatedAccordionStatuses.push({
        questionIndex,
        isAccordionActive: true,
      });
    }

    setAccordionStatuses(updatedAccordionStatuses);
  };

  const deleteAccordionStatusHandler = (questionIndex: number) => {
    const updatedAccordionStatuses = accordionStatuses
      .filter(
        (accordionStatus) => accordionStatus.questionIndex !== questionIndex
      )
      .map((accordionStatus) => {
        if (accordionStatus.questionIndex > questionIndex) {
          return {
            ...accordionStatus,
            questionIndex: accordionStatus.questionIndex - 1,
          };
        }
        return accordionStatus;
      });
    setAccordionStatuses(updatedAccordionStatuses);
  };

  const moveAccordionStatusHandler = (
    questionIndex: number,
    targetIndex: number
  ) => {
    if (
      targetIndex < 0 ||
      targetIndex > accordionStatuses.length ||
      targetIndex === questionIndex
    ) {
      return accordionStatuses;
    }

    const accordionStatusesCopy = [...accordionStatuses];
    const movedStatus = accordionStatusesCopy.splice(questionIndex, 1)[0];

    accordionStatusesCopy.splice(targetIndex, 0, movedStatus);

    const updatedAccordionStatuses = [...accordionStatusesCopy].map(
      (status, index) => ({
        ...status,
        questionIndex: index,
      })
    );

    setAccordionStatuses(updatedAccordionStatuses);
  };

  return {
    accordionStatuses,
    checkAccordionActiveStatus,
    changeAccordionStatusHandler,
    accordionOpeningHandler,
    deleteAccordionStatusHandler,
    moveAccordionStatusHandler,
  };
};
