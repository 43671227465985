import { detailedFacilities } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface DetailedFacilitiesParams {
  offset?: string;
  limit?: string;
  queueId?: string;
  serviceId?: string;
  provinceId?: string;
  cityId?: string;
  maxDaysToResults?: string;
  maxDaysUntilExamination?: string;
  rating?: string;
  sortBy?: string;
  sortOrder?: string;
  systemId?: string;
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["DetailedFacilities"],
});
export const detailedFacilitiesApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDetailedFacilities: builder.query({
      query: ({
        offset,
        limit,
        queueId,
        serviceId,
        provinceId,
        cityId,
        maxDaysToResults,
        maxDaysUntilExamination,
        rating,
        sortBy,
        sortOrder,
        systemId,
      }: DetailedFacilitiesParams) => {
        const params = new URLSearchParams({
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(provinceId && { provinceId }),
          ...(serviceId && { serviceId }),
          ...(queueId && { queueId }),
          ...(cityId && { cityId }),
          ...(maxDaysToResults && { maxDaysToResults }),
          ...(maxDaysUntilExamination && { maxDaysUntilExamination }),
          ...(rating && { rating }),
          ...(sortBy ? { sortBy } : { sortBy: "daysUntilExamination" }),
          ...(sortOrder ? { sortOrder } : { sortOrder: "ASC" }),
          ...(systemId ? { systemId } : { systemId: "1" }),
        });
        return {
          url: `${detailedFacilities}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["DetailedFacilities"],
    }),
  }),
});

export const { useGetDetailedFacilitiesQuery } = detailedFacilitiesApi;
