import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";

interface Props {
  show: boolean;
  handleClose: () => void;
  handleComments: (comment: string) => void;
  comments: string;
  label?: string;
  desc?: string;
}

export const RejectModal = (props: Props) => {
  const [comments, setComments] = useState<string>(props.comments);

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.handleComments(comments);
    props.handleClose();
  };

  return (
    <Modal
      className="results-title text-dark-blue"
      show={props.show}
      onHide={props.handleClose}
      centered
      size="lg"
    >
      <form onSubmit={submitHandler}>
        <Modal.Header
          closeButton
          className={"border-bottom-0 m-4 p-0 align-items-start"}
        >
          <Modal.Title className="fw-bold text-dark-blue ">
            {props.label ? props.label : "Odrzucenie zmian"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"mx-4 p-0"}>
          <Container className="p-0 d-flex flex-column gap-4 ">
            <ComponentWithDescription
              label={props.desc ? props.desc : "Powód odrzucenia zmiany"}
              labelFs={"fs-16 pb-2"}
            >
              <Form.Control
                as="textarea"
                className={`text-dark-blue`}
                rows={3}
                value={comments}
                onChange={handleChange}
              />
            </ComponentWithDescription>
          </Container>
        </Modal.Body>
        <Modal.Footer className={"border-top-0 m-4 p-0 pb-md-0"}>
          <Container className="p-0 d-flex w-100 gap-3 justify-content-end">
            <Button
              className={"btn-outline-dark-blue"}
              onClick={props.handleClose}
            >
              ANULUJ
            </Button>
            <Button className={"btn-primary"} type="submit">
              WYŚLIJ
            </Button>
          </Container>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
