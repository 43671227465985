export const handleNewField = (information: any) =>
  information?.map((value: any) => ({
    label:
      value?.title ||
      value?.activeSubstanceName ||
      value?.name ||
      (value?.degree?.name ? value?.degree?.name + " " : "") +
        value?.firstName +
        " " +
        value?.lastName +
        ", " +
        value?.specializations?.map(
          (spec: DoctorSpecializationsData) => spec?.searchKeyword
        ),
    value: value?.id,
  }));

export const handleActiveSubstance = (information: any) =>
  information?.map((value: any) => ({
    label: value?.activeSubstanceName,
    value: value?.activeSubstance?.id,
  }));

export const handleNewFieldLanguage = (information: any) =>
  information?.map((value: any) => ({
    label: `${value?.name} [${value?.language?.name}]`,
    value: value?.id,
    ...(value?.languageId && { languageId: value?.languageId }),
  }));

export const handleActiveSubstanceOffline = (
  information: { id: number; name: string }[]
) =>
  information?.map(({ id, name }) => ({
    label: name,
    value: id,
  }));
