import { CancelModal } from "components/molecules/CancelModal/CancelModal";
import { useLanguageById } from "Helpers/useLanguageById";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useCreateTranslationsFileMutation,
  useDownloadTranslationsFileQuery,
  useGetTranslationsQuery,
  useUpdateTranslationsMutation,
} from "redux/services/translationsApi";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import { useForm } from "react-hook-form";
import { useGetSystemsQuery } from "redux/services/systemsApi";
import { ModalIsLoading } from "../../atoms/ModalIsLoading/ModalIsLoading";
import { ModalInfo } from "../../atoms/ModalInfo/ModalInfo";
import { useTranslation } from "react-i18next";

export const INITIAL_SEARCH_PARAMS = {
  page: 1,
  limit: 10,
};

const TranslationsLanguagePage = () => {
  // States
  const translationParams = useParams<{ languageId: string }>();
  const languageId = Number(translationParams?.languageId);
  const [generateInfo, setGenerateInfo] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const sortOrder = searchParams.get("sortOrder");
  const filteredValue = searchParams.get("filter");
  const filterMode = searchParams?.get("filterMode");
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || INITIAL_SEARCH_PARAMS.page
  );
  const [currLimit, setCurrLimit] = useState(
    Number(searchParams.get("limit")) || INITIAL_SEARCH_PARAMS.limit
  );

  const { t } = useTranslation();

  useEffect(() => {
    setCurrPage(Number(searchParams.get("page")) ?? INITIAL_SEARCH_PARAMS.page);
  }, [searchParams]);

  const [createTranslations, { isLoading }] =
    useCreateTranslationsFileMutation();

  const { data: translationsData, isError: translationDataError } =
    useGetTranslationsQuery({
      limit: "0",
      language: languageId.toString(),
      type,
      sortOrder,
    });
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [filteredTranslations, setFilteredTranslations] = useState<
    Translation[]
  >([]);
  const [displayedTranslations, setDisplayedTranslations] = useState<
    Translation[]
  >([]);

  const { data: systemsData } = useGetSystemsQuery({});

  const { register, handleSubmit, reset } = useForm<TranslationSendingData>({
    values: {
      translations: [
        ...displayedTranslations.map((dt) => ({
          language: dt.language.id,
          type: dt.type,
          term: dt.term,
          value: dt.value || "",
        })),
      ],
    },
    resetOptions: { keepDefaultValues: true },
  });

  const [updateTranslations] = useUpdateTranslationsMutation();

  const languageData = useLanguageById(languageId);
  const { data: generatedFileLink } = useDownloadTranslationsFileQuery({
    languageId,
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  // Side effects
  useEffect(() => {
    if (translationsData?.data) {
      setTranslations(translationsData?.data);
    }
  }, [translationsData?.data]);

  useEffect(() => {
    if (searchParams.get("limit")) {
      setCurrLimit(Number(searchParams.get("limit")));
    }

    const filteredValues: Translation[] = filteredValue
      ? translations.filter((t: Translation) => {
          return filterMode === "value"
            ? t?.value?.toLowerCase()?.includes(filteredValue.toLowerCase()) ??
                translations
            : t.term.toLowerCase().includes(filteredValue.toLowerCase());
        })
      : translations;

    setFilteredTranslations(filteredValues);

    const indexOfLastItem = currPage * currLimit;
    const indexOfFirstItem = indexOfLastItem - currLimit;
    const translationsToDisplay = filteredValues.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    setDisplayedTranslations(translationsToDisplay);
    // eslint-disable-next-line
  }, [translations, currLimit, currPage, searchParams, filteredValue]);

  // Handlers
  const handlePageChange = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
    setCurrPage(newPage);
  };

  const onSubmit = async (data: TranslationSendingData) => {
    const payload = {
      data: data.translations.map((t) => ({
        term: t.term,
        type: t.type,
        language: t.language,
        value: t.value,
      })),
    };

    const isUpdated = await updateTranslations({ ...payload });

    if ("data" in isUpdated) {
      toast.success(t("toast_translationsLanguagePage_update_success"));
    } else if ("error" in isUpdated) {
      const error = isUpdated?.error as TranslationsSubmitError;

      if (error?.data?.statusCode === 500) {
        toast.error(t("toast_translationsLanguagePage_update_error_500"));
      }

      if (error?.data?.statusCode === 400)
        toast.error(
          `${t("toast_translationsLanguagePage_update_error_400")} ${
            error.data.message[0]
          }`
        );
    }

    setDisabled(true);
  };

  const handleEditClick = () => {
    setDisabled(false);
  };

  const generateTranslationFile = () => {
    const downloadLink = JSON.stringify(generatedFileLink);
    const blob = new Blob([downloadLink], {
      type: "application/json",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${languageData?.code}.json`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const hideModalHandler = () => setShowModal(false);
  const showModalHandler = () => setShowModal(true);

  const modalAcceptanceHandler = () => {
    reset();
    setDisabled(true);
    hideModalHandler();
  };

  const handleGenerate = async () => {
    const hasBeenGenerate = await createTranslations({});
    if ("data" in hasBeenGenerate) {
      toast.success(t("toast_translationsLanguagePage_create_success"));
      setGenerateInfo(hasBeenGenerate.data?.data?.list || []);
      setShowPopup(true);
    } else if ("error" in hasBeenGenerate) {
      const error = hasBeenGenerate.error as TranslationSubmitError;
      if (error.data.statusCode === 500)
        toast.error(t("toast_translationsLanguagePage_create_error_500"));

      if (error.data.statusCode === 400)
        toast.error(
          `${t("toast_translationsLangiagePage_create_error_400")} ${
            error.data.message[0]
          }`
        );
    }
  };

  return (
    <>
      {translationDataError ? (
        <p className="m-0 text-center">
          Wystąpił błąd podczas połączenia z serwerem.
        </p>
      ) : (
        <>
          <Form className="container-fluid" onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-none d-lg-flex justify-content-center">
              <Col className="col-lg-4">
                <h3 className="fs-16 fw-bold text-center">Klucz</h3>
              </Col>
              <Col className="col-lg-3">
                <h3 className="fs-16 fw-bold text-center">Typ</h3>
              </Col>
              <Col className="col-lg-5">
                <h3 className="fs-16 fw-bold text-center">Wartość</h3>
              </Col>
            </Row>
            {displayedTranslations.map((translation, index) => (
              <Row
                key={`${translation.term}-${translationParams.languageId}`}
                className="pb-2 justify-content-center"
              >
                <Col className="col-12 col-lg-4 d-flex justify-content-center align-items-center mb-2 mb-lg-0">
                  <p className="text-break">{translation.term}</p>
                  <Form.Control
                    className="d-none"
                    {...register(`translations.${index}.term`)}
                    defaultValue={translation.term}
                    plaintext
                    readOnly
                  />
                </Col>
                <Col className="col-12 col-lg-3 d-flex justify-content-center align-items-center mb-2 mb-lg-0">
                  <Form.Select
                    className="col-12 col-lg-4 w-100 h-100 p-1 lh-sm"
                    {...register(`translations.${index}.type`)}
                    defaultValue={translation.type}
                    disabled={disabled}
                  >
                    {systemsData?.data?.map((system: System) => (
                      <option key={system.id} value={system.slug}>
                        {system.slug}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Control
                    as="textarea"
                    className="col-12 col-lg-5 w-100 h-100 p-1 lh-sm ta-resize-none"
                    {...register(`translations.${index}.value`)}
                    defaultValue={translation.value}
                    disabled={disabled}
                    rows={4}
                  ></Form.Control>
                </Col>
              </Row>
            ))}
            <Row className="container-fluid mt-2 mx-0 mb-3 px-0 gap-3 justify-content-between justify-content-xl-end">
              {disabled ? (
                <>
                  <Col className="px-0 col-12 col-md-5 col-xl-3">
                    <Button
                      className="d-block btn-outline-blue w-100"
                      type="button"
                      onClick={handleGenerate}
                    >
                      Wygeneruj pliki
                    </Button>
                  </Col>
                  <ModalIsLoading
                    show={isLoading}
                    label={"Trwa generowanie plików na serwerze"}
                  />
                  <ModalInfo
                    show={showPopup}
                    title={"Utworzone pliki tłumaczeń"}
                    onHide={() => setShowPopup((prevState) => !prevState)}
                    bodyClass={"d-flex flex-column"}
                    headerClass={"text-dark-blue"}
                  >
                    {generateInfo.map((linkAddress: string, index: number) => (
                      <Link to={linkAddress} className={"py-1"}>
                        {index + 1}. {linkAddress}
                      </Link>
                    ))}
                  </ModalInfo>

                  <Col className="px-0 col-12 col-md-5 col-xl-3">
                    <Button
                      className="d-block btn-outline-blue w-100"
                      type="button"
                      onClick={generateTranslationFile}
                    >
                      Pobierz plik{" "}
                      <span className="text-lowercase">{`${languageData?.code}.json`}</span>
                    </Button>
                  </Col>
                  <Col className="px-0 col-12">
                    <Button
                      className="d-block btn-outline-blue col-12 col-md-5 col-xl-3 ms-md-auto"
                      type="button"
                      onClick={handleEditClick}
                    >
                      Edytuj dane
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col className="px-0 col-12 col-md-5 col-xl-3">
                    <Button
                      className="d-block btn-outline-blue w-100"
                      type="button"
                      onClick={showModalHandler}
                    >
                      Anuluj
                    </Button>
                  </Col>
                  <Col className="px-0 col-12 col-md-5 col-xl-3">
                    <Button
                      className="d-block btn-outline-blue w-100"
                      type="submit"
                    >
                      Zapisz zmiany
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form>
          <div className="container-fluid">
            <Row>
              <Col className="d-flex justify-content-center">
                <CustomPagination
                  totalCount={filteredTranslations.length}
                  pageSize={currLimit}
                  currentPage={currPage}
                  onPageChange={handlePageChange}
                />
              </Col>
            </Row>
          </div>
          <CancelModal
            title="Komunikat"
            content="Niezapisane dane zostaną utracone! Czy na pewno chcesz anulować?"
            cancelText="Nie"
            confirmText="Tak"
            show={showModal}
            onHide={hideModalHandler}
            onConfirm={modalAcceptanceHandler}
          />
        </>
      )}
    </>
  );
};

export default TranslationsLanguagePage;
