import { Badge, Button, Dropdown, Image, Row } from "react-bootstrap";
import { AvatarModal } from "../AvatarModal/AvatarModal";
import { useEffect, useState } from "react";
import { ReactComponent as Pencil } from "assets/Icons/Pencil.svg";
import styles from "./CompanyHeader.module.scss";
import BanerImg from "../../../assets/images/companyNormal.png";
import {
  useDeleteCompanyMutation,
  usePatchCompanyMutation,
} from "../../../redux/services/companyApi";
import { toast } from "react-toastify";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { CompanyCreateModal } from "../CompanyCreateModal/CompanyCreateModal";
import { useNavigate } from "react-router-dom";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { CompanySkanerQRModal } from "../CompanySkanerQRModal/CompanySkanerQRModal";
import { getWindowWidth } from "../../../Helpers/getWindowWidth";
import { CodeModal } from "../CodeModal/CodeModal";
import { ChangesSponsorCompanyTemplate } from "../../templates/ChangesSponsorCompanyTemplate/ChangesSponsorCompanyTemplate";
import { ChangesCompanyConfig } from "../../templates/ChangesCompanyTemplate/ChangesCompanyConfig";
import { useTranslation } from "react-i18next";
import { useOpenHelpTopicCustomMessage } from "../../../Helpers/openMessageHooks";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  companyId: string | undefined;
  data: any;
}

const INITIAL_SEARCH_PARAMS = {
  TOPIC_ID: 9,
};

export const CompanyHeader = (props: Props) => {
  const navigate = useNavigate();
  const [changes, setChanges] = useState<{ comments: string } | null>(null);
  const [showAvatar, setShowAvatar] = useState<boolean>(false);
  const [showCompanyEditor, setShowCompanyEditor] = useState<boolean>(false);
  const [showCompanyDelete, setShowCompanyDelete] = useState<boolean>(false);
  const [showCompanySkaner, setShowCompanySkaner] = useState<boolean>(false);
  const [showChanges, setShowChanges] = useState<boolean>(false);
  const [mainDepartment, setMainDepartment] = useState<any>(null);
  const [fontSize, setFontSize] = useState(
    getWindowWidth() <= 991 ? "fs-29" : "fs-39",
  );

  const handleClick = useOpenHelpTopicCustomMessage({
    topic: {
      id: INITIAL_SEARCH_PARAMS.TOPIC_ID,
      case: "Profil firmy",
      status: 1,
    },
    customMessage: `Chcę przywrócić firmę: ${props.companyId}`,
  });

  const [patchCompany, { isSuccess: patchIsSuccess, error: patchError }] =
    usePatchCompanyMutation();
  const [deleteCompany, { isSuccess: deleteIsSuccess, error: deleteError }] =
    useDeleteCompanyMutation();

  const { t } = useTranslation();

  useEffect(() => {
    function handleResize() {
      if (getWindowWidth() <= 991) {
        setFontSize("fs-29");
      } else {
        setFontSize("fs-39");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (props.data.changes) {
      setChanges(JSON.parse(props.data.changes));
    } else {
      setChanges(null);
    }
  }, [props.data.changes]);

  useEffect(() => {
    if (patchError) {
      toast.error(t("toast_companyHeader_add_image_error"));
    }
    // eslint-disable-next-line
  }, [patchError, patchIsSuccess]);

  useEffect(() => {
    if (deleteIsSuccess) {
      toast.success(t("toast_companyHeader_delete_company_success"));
    }
    if (deleteError) {
      toast.error(t("toast_companyHeader_delete_company_error"));
    }
    // eslint-disable-next-line
  }, [deleteError, deleteIsSuccess]);

  useEffect(() => {
    const main = props.data.companyDepartment.find(
      (value: any) => value.isMain === 1,
    );
    if (main) {
      setMainDepartment(main);
    } else {
      setMainDepartment(null);
    }
  }, [props.data.companyDepartment]);

  const handlePatch = async (id: string) => {
    await patchCompany({
      id: props.companyId,
      data: { imageId: id },
    });
  };

  const handleDelete = async () => {
    await deleteCompany({
      id: props.companyId,
    });
    navigate(`/${NAV_PATH.DASHBOARD}`);
  };

  return (
    <div className={"d-flex flex-column flex-lg-row text-dark-blue"}>
      <div
        className={
          "d-flex justify-content-center justify-content-lg-start align-items-center"
        }
      >
        <Row className={"m-0 p-0 position-relative"} style={{ width: 160 }}>
          <Image
            className={`p-0 ${styles.image}`}
            src={
              convertImageIdToUrl(props.data.imageIds, false, "normal.jpeg") ||
              BanerImg
            }
          />
          <div className={"bg-white"}>
            <Button
              className={`d-flex justify-content-center align-items-center position-absolute bottom-0 end-0 p-0 ${styles.buttonEditAvatar}`}
              variant={"outline-primary"}
              onClick={() => setShowAvatar((prev) => !prev)}
            >
              <Pencil />
            </Button>
          </div>
          <AvatarModal
            headerTitle={"Avatar firmy"}
            types={"company-logo"}
            show={showAvatar}
            handleClose={() => setShowAvatar(false)}
            handlePatch={handlePatch}
          />
        </Row>
      </div>
      <div className={"ps-0 ps-lg-5"}>
        <div
          className={
            "d-flex justify-content-center justify-content-lg-start pt-4 pt-lg-0 gap-2"
          }
        >
          <Badge
            className={`fs-12 fw-semibold lh-130 ls-6 text-white  ${
              props.data.deleted === 0 && props.data.status === 1
                ? "bg-success"
                : "bg-secondary"
            }`}
          >
            {props.data.deleted === 0 && props.data.status === 1
              ? "Firma aktywna"
              : props.data.deleted === 0
              ? "Firma nieaktywna"
              : "Firma usunięta"}
          </Badge>
          {changes && (
            <Badge
              className={`fs-12 fw-semibold lh-130 ls-6 text-white  ${
                changes.comments ? "bg-secondary" : "bg-secondary"
              }`}
            >
              {changes.comments ? changes.comments : "oczekuje na weryfikację"}
            </Badge>
          )}
        </div>
        <Row>
          <span
            className={`ls-3 fw-semibold text-center text-lg-start mb-3 ${fontSize}`}
          >
            {props.data.name}
          </span>
        </Row>
        <div
          className={`d-flex flex-column d-lg-inline-flex flex-lg-row flex-wrap p-0 m-0 me-auto`}
        >
          <div className={`d-flex gap-3 text-wrap fs-16`}>
            <div>{props.data.fullName}</div>
          </div>

          <div
            className={`d-none d-lg-block vr mx-0 mx-lg-3 my-2 my-lg-0 ${styles.hrColor}`}
          ></div>
          <hr className={`d-md-block d-lg-none mt-1 ${styles.hrColor} my-1`} />

          <div className={`d-flex gap-3 text-nowrap justify-content-between`}>
            <div className={"fw-bolder fs-16"}>NIP</div>
            <div className={"fs-16"}>{props.data.nipProvider}</div>
          </div>
          {props.data.regonProvider && (
            <>
              <div
                className={`d-none d-lg-block vr mx-0 mx-lg-3 my-2 my-lg-0 ${styles.hrColor}`}
              ></div>
              <hr className={`d-md-block d-lg-none my-1 ${styles.hrColor}`} />

              <div
                className={`d-flex gap-3 text-nowrap justify-content-between fs-16`}
              >
                <div className={"fw-bolder fs-16"}>REGON</div>
                <div className={"fs-16"}>{props.data.regonProvider}</div>
              </div>
            </>
          )}
        </div>
        {mainDepartment && (
          <div
            className={`d-flex flex-column d-lg-inline-flex flex-lg-row flex-wrap p-0 m-0 me-auto col-12`}
          >
            <hr className={`d-md-block d-lg-none my-1 ${styles.hrColor}`} />

            <div className={`d-flex gap-3 justify-content-between`}>
              <div className={"fw-bolder fs-16"}>Adres</div>
              <div className={"text-wrap text-end fs-16"}>
                {`${mainDepartment.street} ${mainDepartment.houseNumber}${
                  mainDepartment.apartmentNumber
                    ? `/${mainDepartment.apartmentNumber}`
                    : ""
                }, ${mainDepartment.zipCode} ${mainDepartment.city.name}`}
              </div>
            </div>

            <div
              className={`d-none d-lg-block vr mx-0 mx-lg-3 my-2 my-lg-0 ${styles.hrColor}`}
            ></div>
            <hr className={`d-md-block d-lg-none my-1 ${styles.hrColor}`} />

            <div
              className={`d-flex gap-3 px-0 text-nowrap justify-content-between`}
            >
              <div className={"fw-bolder fs-16"}>telefon</div>
              {mainDepartment.phoneNumbers && (
                <div className={"fs-16 text-wrap text-end"}>
                  {JSON.parse(mainDepartment.phoneNumbers).map(
                    (value: string, index: number) => {
                      if (index === 0) {
                        return <span key={index}>{value}</span>;
                      } else {
                        return <span key={index}>, {value}</span>;
                      }
                    },
                  )}
                </div>
              )}
            </div>

            <div
              className={`d-none d-lg-block vr mx-0 mx-lg-3 my-2 my-lg-0 ${styles.hrColor}`}
            ></div>
            <hr className={`d-md-block d-lg-none my-1 ${styles.hrColor}`} />

            <div className={`d-flex gap-3 text-nowrap justify-content-between`}>
              <div className={"fw-bolder fs-16"}>mail</div>
              {mainDepartment.emailAddresses && (
                <div className={"fs-16 pb-2"}>
                  {JSON.parse(mainDepartment.emailAddresses).map(
                    (value: string, index: number) => {
                      if (index === 0) {
                        return <span key={index}>{value}</span>;
                      } else {
                        return <span key={index}>, {value}</span>;
                      }
                    },
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className={`d-flex justify-content-between justify-content-lg-start flex-column flex-lg-row flex-lg-wrap gap-3 pt-3`}
        >
          <Button
            className={"text-wrap"}
            variant={"primary"}
            onClick={() => setShowCompanySkaner((prev) => !prev)}
          >
            <span>SKANUJ KOD DOTYCZĄCY PROMOCJI</span>
          </Button>
          <CompanySkanerQRModal
            show={showCompanySkaner}
            handleClose={() => setShowCompanySkaner((prev) => !prev)}
          />
          <Dropdown>
            <Dropdown.Toggle
              className={`w-100 btn-outline-primary ${styles.disableToggle}`}
              id="dropdown-basic"
              split={true}
            >
              USTAWIENIA
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setShowCompanyEditor(true);
                }}
              >
                Zmień dane firmy
              </Dropdown.Item>
              {props.data.deleted === 0 ? (
                <Dropdown.Item
                  className={props.data.deleted && "text-grey-2"}
                  onClick={() => {
                    setShowCompanyDelete(true);
                  }}
                >
                  Usuń konto firmy
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={handleClick}>
                  Przywróć konto firmy
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <CompanyCreateModal
            isFirst={false}
            show={showCompanyEditor}
            handleClose={() => setShowCompanyEditor((prev) => !prev)}
            maxSteps={1}
            dataCompany={props.data}
          />
          <PopupModal
            titleClasses="fw-600 text-center"
            text="Czy na pewno chcesz usunąć firmę?"
            type="confirm"
            show={showCompanyDelete}
            setShow={() => setShowCompanyDelete(false)}
            confirmAction={handleDelete}
          />
          {changes && (
            <>
              <Button
                className={"text-wrap"}
                variant={"outline-secondary"}
                onClick={() => setShowChanges((prev) => !prev)}
              >
                podejrzyj zmiany
              </Button>
              <CodeModal
                children={
                  <ChangesSponsorCompanyTemplate
                    changes={changes}
                    config={ChangesCompanyConfig}
                    data={props.data}
                  />
                }
                handleClose={() => setShowChanges((prev) => !prev)}
                label={"Oczekujące zmiany"}
                show={showChanges}
                size={"xl"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
