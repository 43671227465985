import { useEffect, useState } from "react";
import { OffcanvasHeader, OffcanvasBody } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { ReactComponent as Search } from "../../../assets/Icons/Search.svg";
import { ChatsHistory } from "components/molecules/ChatsHistory/ChatsHistory";
import styles from "./MessagesMainView.module.scss";
import { useGetMeQuery } from "redux/services/meApi";
import ChatSupportFormModal from "../../organisms/ChatSupportFormModal/ChatSupportFormModal";

type MessagesMainViewProps = {
  chats?: ChatData[];
};

export const MessagesMainView: React.FC<MessagesMainViewProps> = ({
  chats = [],
}) => {
  const { data: userData } = useGetMeQuery({});
  const hasChats = chats?.length > 0;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredChats, setFilteredChats] = useState<ChatData[]>([]);
  const [isChatSupportFormModalVisible, setIsChatSupportFormModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    if (lowerCaseQuery.length > 0) {
      setFilteredChats(
        chats?.filter(
          (chat) =>
            chat?.topicMessage?.some(
              (messageObj: TopicMessage) =>
                messageObj?.message?.toLowerCase().includes(lowerCaseQuery),
            ),
        ) || [],
      );
    } else {
      setFilteredChats(chats);
    }
  }, [searchQuery, chats]);

  const userRoles = userData?.data?.roles || [];

  const shouldDisplayMessage = (roles: ChatRoles[] | undefined) => {
    const hasTargetRoles = roles?.some((roleObj: ChatRoles) =>
      ["patient", "guardian"].includes(roleObj.role),
    );

    const hasOnlyTargetRoles = roles?.every((roleObj: ChatRoles) =>
      ["patient", "guardian"].includes(roleObj.role),
    );

    return hasTargetRoles && hasOnlyTargetRoles;
  };

  const displayMessage = shouldDisplayMessage(userRoles);

  const handleShowingChatSupportFormModal = () => {
    setIsChatSupportFormModalVisible(true);
  };

  return (
    <>
      <OffcanvasHeader className={`ps-4 pe-4 pb-0 d-flex flex-wrap`}>
        <Row className="m-0 p-0 col-12 text-dark">Szukaj w wiadomościach</Row>
        <Row className="p-0 m-0 col-12">
          <div
            className={`p-0 border-grey border-1 col-12 rounded-1 d-flex flex-wrap ${styles.searchInputWrapper}`}
          >
            <input
              type="text"
              className="col-11 p-2"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            <span className="col-1 d-flex align-items-center justify-content-center">
              <Search />
            </span>
          </div>
        </Row>
        <Row className="p-0 m-0 col-12 my-4">
          <button
            onClick={handleShowingChatSupportFormModal}
            className="form-control border border-2 border-primary text-primary col-12 fw-medium"
          >
            NAPISZ DO NAS
          </button>
        </Row>
      </OffcanvasHeader>
      <OffcanvasBody
        className={`ps-4 pe-4 pb-3 pt-0 m-0 d-flex flex-wrap ${
          hasChats ? "align-content-start" : ""
        } col-12 justify-content-center ${styles.container}`}
      >
        <div className={`w-100 ${styles.wrapper}`}>
          {hasChats && <ChatsHistory chats={filteredChats} />}
          {!hasChats && (
            <div
              className={`p-5 text-center d-flex flex-column items-align-center justify-content-center border fs-16 ${styles.noChats}`}
            >
              <p className="fw-600">Nie masz żadnych wiadomości.</p>

              {displayMessage && (
                <p>
                  Aby rozpocząć konwersację z innym użytkownikiem, wejdź na jego
                  profil i kliknij przycisk <i>wyślij wiadomość</i>.
                </p>
              )}
            </div>
          )}
        </div>
      </OffcanvasBody>
      <ChatSupportFormModal
        show={isChatSupportFormModalVisible}
        onHide={() => setIsChatSupportFormModalVisible(false)}
        setShow={setIsChatSupportFormModalVisible}
      />
    </>
  );
};
