import React, { ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ComponentWithDescription from "../../molecules/ComponentWithDescription/ComponentWithDescription";
import { SubmitHandler, useForm } from "react-hook-form";
import { Social } from "../../molecules/ListOfSocialPage/ListOfSocialPage";
import { CompanySocialMediaEntity } from "../../molecules/CompanySocialMediaEntity/CompanySocialMediaEntity";
import { ReactComponent as Instagram } from "../../../assets/Icons/SocialMediaIcons/Instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/Icons/SocialMediaIcons/Facebook.svg";
import { ReactComponent as Page } from "../../../assets/Icons/SocialMediaIcons/Page.svg";
import Select, { components } from "react-select";
import styles from "../../molecules/CompanySocialMediaEntity/CompanySocialMediaEntity.module.scss";
import { usePatchCompanyMutation } from "../../../redux/services/companyApi";
import { customStyles } from "../../../styles/reactSelectCustomStyles";
import TooltipIcon from "../../atoms/TooltipIcon";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
  isActive: boolean;
  isFirst: boolean;
  handleActive: (b: boolean) => void;
  handleFirst: (b: boolean) => void;
  handleCondition?: (b: boolean) => void;
  reFetchCompany: any;
}

export interface SocialSelect {
  label: string;
  value: "page" | "facebook" | "instagram";
  data: string | null;
  defaultLink?: string;
  icon: ReactNode;
}

enum httpsSocial {
  page = "https://",
  facebook = "https://www.facebook.com/",
  instagram = "https://www.instagram.com/",
}

export enum nameSocial {
  page = "Strona firmowa",
  facebook = "Facebook",
  instagram = "Instagram",
}

// export const SocialArray = (socialAddress: Social | null) => {
//
//     return [
//         {
//             label: nameSocial.page,
//             value: "page",
//             date: !!socialAddress ? socialAddress?.page : null,
//             defaultLink: httpsSocial.page,
//             icon: <Page/>,
//         },
//         {
//             label: nameSocial.facebook,
//             value: "facebook",
//             date: !!socialAddress ? socialAddress?.facebook : null,
//             defaultLink: httpsSocial.facebook,
//             icon: <Facebook/>,
//         },
//         {
//             label: nameSocial.instagram,
//             value: "instagram",
//             date: !!socialAddress ? socialAddress?.instagram : null,
//             defaultLink: httpsSocial.instagram,
//             icon: <Instagram/>,
//         }
//     ]
// }

const { Option } = components;
const customSingleValue = (options: any) => (
  <Option
    {...options}
    className="d-flex flex-container flex-row justify-content-start align-items-center"
  >
    <div
      style={{ width: 20, height: 20 }}
      className={
        "d-flex justify-content-center align-items-center p-0 me-2 text-dark-blue"
      }
    >
      {options.data.icon}
    </div>
    {/*{ options.icon && <span className="input-select__icon">{ options.icon }</span> }*/}
    <span className={"fs-16 text-nowrap"}>{options.data.label}</span>
  </Option>
);

const CustomSelectValue = ({ data }: any) => (
  <div
    className={`d-flex flex-container flex-row justify-content-start align-items-center px-1 ${styles.deleteBrother}`}
  >
    <div
      style={{ width: 20, height: 20 }}
      className={
        "d-flex justify-content-center align-items-center p-0 mx-2 text-dark-blue"
      }
    >
      {data.icon}
    </div>
    {/*{ options.icon && <span className="input-select__icon">{ options.icon }</span> }*/}
    <span className={"fs-16 text-nowrap"}>{data.label}</span>
  </div>
);

export const CompanySocialMedia = ({
  data,
  handleActive,
  handleFirst,
  isActive,
  isFirst,
  reFetchCompany,
  handleCondition,
}: Props) => {
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [socialAddress, setSocialAddress] = useState<Social | null>(null);
  const [selectList, setSelectList] = useState<SocialSelect[]>();
  const [valueSelect, setValueSelect] = useState<SocialSelect>();

  const [patchCompany, { isSuccess, error }] = usePatchCompanyMutation();

  const { t } = useTranslation();

  useEffect(() => {
    setSelectList([
      {
        label: nameSocial.page,
        value: "page",
        data: socialAddress?.page || null,
        defaultLink: httpsSocial.page,
        icon: <Page />,
      },
      {
        label: nameSocial.facebook,
        value: "facebook",
        data: socialAddress?.facebook || null,
        defaultLink: httpsSocial.facebook,
        icon: <Facebook />,
      },
      {
        label: nameSocial.instagram,
        value: "instagram",
        data: socialAddress?.instagram || null,
        defaultLink: httpsSocial.instagram,
        icon: <Instagram />,
      },
    ]);
  }, [socialAddress]);

  useEffect(() => {
    if (data?.socialAddresses) {
      setSocialAddress(JSON.parse(data?.socialAddresses) ?? null);
    } else {
      setSocialAddress(null);
    }
  }, [data]);

  const {
    handleSubmit,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<{ socialAddresses: string }>({
    defaultValues: {
      socialAddresses: data?.socialAddresses || null,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("toast_companySocialMedia_success"));
      reFetchCompany();
    } else if (error) {
      toast.error(t("toast_companySocialMedia_error"));
    }
    // eslint-disable-next-line
  }, [error, isSuccess]);

  const handleChange = async (data: SocialSelect) => {
    setValueSelect(data);
    handleActive(true);
  };
  const handleDelete = async (handleData: SocialSelect) => {
    const newObj = {
      ...socialAddress,
    };
    delete newObj[handleData.value];

    await patchCompany({
      id: data.id,
      data: {
        socialAddresses:
          Object.keys(newObj).length === 0 ? null : JSON.stringify(newObj),
      },
    });
    setSocialAddress(Object.keys(newObj).length === 0 ? null : newObj);
    setValueSelect(undefined);
    handleActive(false);
  };

  const submitHandler: SubmitHandler<{
    socialAddresses: string | null | undefined;
  }> = async () => {
    if (isAccept !== null && !!valueSelect && valueSelect.data !== null) {
      const newObj = {
        ...socialAddress,
        [valueSelect.value]: valueSelect.data,
      };

      await patchCompany({
        id: data.id,
        data: {
          socialAddresses: JSON.stringify(newObj),
        },
      });
      setSocialAddress(newObj);
      setIsAccept(false);
      handleActive(false);
    }
  };

  return (
    <Container fluid={true} className={" d-flex flex-column gap-4 p-0"}>
      {isActive && (
        <Col className={`col-12 border border-1 p-3 gap-3 ${styles.radius}`}>
          <Form
            className={"d-flex flex-column w-100 "}
            onSubmit={handleSubmit(submitHandler)}
          >
            <Row className={""}>
              <span className={"pb-3 text-grey-2 fs-12 fw-semibold ls-6"}>
                {selectList &&
                selectList.find((val) => val.value === valueSelect?.value)
                  ?.data &&
                selectList
                  ? "Edytowanie adresu"
                  : "Dodawanie serwisu"}
              </span>
              <ComponentWithDescription
                label={"Typ portalu*"}
                labelFs={`fs-16 text-dark-blue`}
                className="my-2 col-12 col-lg-4 col-xl-3 "
              >
                {selectList && (
                  <Select
                    value={valueSelect}
                    onChange={(value) => {
                      if (!!value) {
                        setValueSelect(value);
                      }
                    }}
                    options={selectList}
                    name="socialMedia"
                    placeholder={"Wybierz z listy"}
                    components={{
                      Option: customSingleValue,
                      SingleValue: CustomSelectValue,
                    }}
                    styles={customStyles(false)}
                  />
                )}
              </ComponentWithDescription>

              <ComponentWithDescription
                label={"Adres portalu*"}
                labelFs={`fs-16 ${
                  errors.socialAddresses ? "text-danger" : "text-dar-blue"
                }`}
                className="my-2 col-12 col-lg-8 col-xl-9"
              >
                {valueSelect ? (
                  <>
                    <Form.Control
                      type="text"
                      id="socialAddresses"
                      disabled={!valueSelect}
                      {...register("socialAddresses", {
                        required: {
                          value: true,
                          message: "Pole wymagane",
                        },
                      })}
                      aria-describedby="socialAddresses"
                      className={`w-100 text-dark-blue ${styles.input} `}
                      placeholder={valueSelect?.defaultLink}
                      value={valueSelect?.data || ""}
                      onChange={(e) =>
                        setValueSelect((prevState) => {
                          if (prevState !== undefined) {
                            if (e.target.value === prevState.defaultLink) {
                              setError("socialAddresses", {
                                message: "Pole wymagane",
                              });
                            } else {
                              clearErrors(["socialAddresses"]);
                            }
                            return { ...prevState, data: e.target.value };
                          }
                        })
                      }
                      isInvalid={Boolean(errors.socialAddresses)}
                    />
                    {errors.socialAddresses && (
                      <Form.Control.Feedback type={"invalid"}>
                        {errors.socialAddresses.message}
                      </Form.Control.Feedback>
                    )}
                  </>
                ) : (
                  <TooltipIcon desc="Proszę wybrać typ portalu">
                    <Form.Control
                      disabled={true}
                      type="text"
                      className={"bg-white"}
                    />
                  </TooltipIcon>
                )}
              </ComponentWithDescription>
            </Row>
            <div className="d-flex gap-2 mt-4 justify-content-end">
              <Button
                className="btn-outline-dark-blue"
                onClick={() => {
                  handleActive(false);
                  if (data?.socialAddresses === null && isFirst) {
                    handleFirst(true);
                  } else {
                    handleFirst(false);
                  }
                  setIsAccept(false);
                }}
              >
                ANULUJ
              </Button>
              <Button
                disabled={!valueSelect}
                className={valueSelect ? "btn-primary" : "btn-disabled"}
                type="submit"
                value="Submit"
                onClick={() => setIsAccept((prevState) => !prevState)}
              >
                {selectList &&
                selectList.find((val) => val.value === valueSelect?.value)
                  ?.data &&
                selectList
                  ? "AKTUALIZUJ"
                  : "DODAJ"}
              </Button>
            </div>
          </Form>
        </Col>
      )}

      {socialAddress &&
        selectList &&
        selectList?.length > 0 &&
        selectList
          .filter((value) => socialAddress[value.value])
          .map((value) => (
            <Col
              key={value.value}
              className={`col-12 border border-1 ps-3 pe-2 py-3 ${styles.radius}`}
            >
              <CompanySocialMediaEntity
                data={value}
                handleChange={handleChange}
                handleDelete={handleDelete}
              />
            </Col>
          ))}
    </Container>
  );
};
