import { Accordion, Button, Row } from "react-bootstrap";
import { useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../../../styles/reactSelectCustomStyles";
import styles from "../../../molecules/CollapseComponents/FeedCollapse/FeedCollapse.module.scss";
import { useGetCitiesQuery } from "../../../../redux/services/citiesApi";
import { useGetProvincesQuery } from "../../../../redux/services/provincesApi";
import { convertToSelectLabel } from "../../../../Helpers/convertToSelectLabel";
import { shortestQueueSelectAllOptions } from "../../../../Helpers/shortestQueueSelectAllOptions";
import { useGetMeQuery } from "redux/services/meApi";

const ShortestQueueFilters = ({
  handleFilters,
  handleResetFilters,
  selectedFilters,
  setSelectedFilters,
  setFilters,
}: ShortestQueueFiltersProps) => {
  const { data: authUser, refetch: refetchGetMe } = useGetMeQuery({});
  const currentUserMailingStateData = authUser?.data?.settings?.MailingState;
  const { data: citiesData } = useGetCitiesQuery({
    provinceId:
      (selectedFilters?.provinces?.id !== 0 &&
        selectedFilters?.provinces?.id?.toString()) ??
      "",
  });
  const { data: provinceData } = useGetProvincesQuery({});

  const citiesDataSelectOptions =
    citiesData?.data && convertToSelectLabel(citiesData?.data, "name");

  citiesDataSelectOptions?.unshift(shortestQueueSelectAllOptions());

  const provinceDataSelectOptions =
    provinceData?.data && convertToSelectLabel(provinceData?.data, "name");

  provinceDataSelectOptions?.unshift(shortestQueueSelectAllOptions("province"));

  useEffect(() => {
    if (provinceDataSelectOptions && currentUserMailingStateData) {
      const matchingProvinceOption = provinceDataSelectOptions?.find(
        (option) => option?.label === currentUserMailingStateData
      );

      if (matchingProvinceOption) {
        setSelectedFilters((prevFilters: ILabel) => ({
          ...prevFilters,
          provinces: matchingProvinceOption,
        }));

        setFilters((prevFilters: ILabel) => ({
          ...prevFilters,
          provinces: matchingProvinceOption,
        }));

        refetchGetMe();
      }
    }
    // eslint-disable-next-line
  }, [currentUserMailingStateData]);

  return (
    <Row className={"my-4"}>
      <Accordion>
        <Accordion.Item eventKey={"shortest-queue-widget"}>
          <Accordion.Header>Filtrowanie</Accordion.Header>
          <Accordion.Body>
            <Row className={"my-4"}>
              <div className={"col-12 col-xl-6"}>
                <label className={"col-12"}>
                  <div className="fw-400 fs-16 text-dark-blue my-2">
                    Województwo
                  </div>
                  <Select<ILabel>
                    value={selectedFilters?.provinces}
                    styles={customStyles(false, false)}
                    onChange={(value) => {
                      setSelectedFilters(
                        (prev: ShortestQueueSelectedFilters) => ({
                          ...prev,
                          cities: shortestQueueSelectAllOptions(),
                          provinces: value,
                        })
                      );
                    }}
                    options={provinceDataSelectOptions}
                    noOptionsMessage={() => (
                      <span>Brak świadczeń o podanej frazie</span>
                    )}
                  />
                </label>
              </div>
              <div className={"col-12 col-xl-6"}>
                <label className={"col-12"}>
                  <div className="fw-400 fs-16 text-dark-blue my-2">Miasto</div>

                  <Select<ILabel>
                    isDisabled={
                      !selectedFilters?.provinces ||
                      selectedFilters?.provinces?.id === 0
                    }
                    value={selectedFilters?.cities}
                    styles={customStyles(false, false)}
                    onChange={(value) =>
                      setSelectedFilters(
                        (prev: ShortestQueueSelectedFilters) => ({
                          ...prev,
                          cities: value,
                        })
                      )
                    }
                    options={citiesDataSelectOptions}
                    noOptionsMessage={() => (
                      <span>Brak świadczeń o podanej frazie</span>
                    )}
                  />
                </label>
              </div>
            </Row>
            <Row className="d-flex justify-content-end">
              <div className="d-flex justify-content-end flex-wrap col-12 col-xl-6">
                <Button
                  className={`${styles.filterButton} col-12 col-xl-5 mx-xl-3 my-lg-0 my-2 fw-500 btn-outline-dark-blue`}
                  onClick={handleResetFilters}
                >
                  RESETUJ FILTRY
                </Button>
                <Button
                  className={`${styles.filterButton} bg-primary col-12 col-xl-5 fw-500`}
                  onClick={handleFilters}
                >
                  ZASTOSUJ FILTRY
                </Button>
              </div>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Row>
  );
};

export default ShortestQueueFilters;
