import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Form } from "react-bootstrap";
import styles from "./AgreementInput.module.scss";
import { Tooltip, OverlayTrigger, TooltipProps } from "react-bootstrap";
import TooltipIcon from "../../../../assets/Icons/Tooltip.svg";
import { InputComponentProps } from "components/molecules/RenderPollQuestion/RenderPollQuestion";

const AgreementInput: React.FC<InputComponentProps> = ({
  question,
  nextPageClicked,
  defaultValue,
  readOnly,
}) => {
  const {
    register,
    setValue,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext();

  const fieldName = question?.fieldName;
  const agreementText =
    question?.meta?.find((m) => m.key === "answers")?.value || "";

  const validationMeta = question?.meta?.find((m) => m.key === "validation");
  const requiredMessage = validationMeta
    ? JSON.parse(validationMeta.value).requiredMessage
    : "";
  const tooltip =
    question?.meta.find((meta) => meta.key === "html_tooltip")?.value || "";

  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" {...props}>
      <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    </Tooltip>
  );

  return (
    <div>
      {question?.question && (
        <Form.Label className="fw-600 fs-16 mb-0">
          {question?.question}
          {validationMeta && JSON.parse(validationMeta.value).isRequired && (
            <span className="text-validation">*</span>
          )}
          {tooltip && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <img src={TooltipIcon} alt="tooltip" />
            </OverlayTrigger>
          )}
        </Form.Label>
      )}
      <div className="f-flex">
        <Form.Check
          type="checkbox"
          isInvalid={!!errors[fieldName]}
          {...register(fieldName, {
            required: requiredMessage,
          })}
          defaultChecked={!!defaultValue}
          onChange={(e) => {
            setValue(fieldName, e.target.checked);
            trigger(fieldName);
          }}
          label={agreementText}
          disabled={readOnly}
          className={`${errors[fieldName] ? styles.customError : ""}`}
        />
      </div>
      {(nextPageClicked || isSubmitted) && errors[fieldName] && (
        <ErrorMessage
          errors={errors}
          name={fieldName}
          render={({ message }) => (
            <div className="text-validation">{message}</div>
          )}
        />
      )}
      {question?.description && (
        <div
          dangerouslySetInnerHTML={{ __html: question?.description || "" }}
        ></div>
      )}
    </div>
  );
};

export default AgreementInput;
