import styles from "./NotificationEntity.module.scss";
import React, { useEffect, useState } from "react";
import { changeDateFormatNames } from "../../../Helpers/changeDateFormatNames";
import {
  NotificationTypeEnum,
  usePatchNotificationsMutation,
} from "../../../redux/services/notificationApi";
import { ReactComponent as Bell } from "../../../assets/Icons/bell.svg";
import { ReactComponent as Star } from "../../../assets/Icons/Show/Benefits.svg";
import { ReactComponent as Events } from "../../../assets/Icons/Show/Events.svg";
import { ReactComponent as Articles } from "../../../assets/Icons/Show/Articles.svg";
import { ReactComponent as Heart } from "../../../assets/Icons/Show/Heart.svg";
import { ReactComponent as Briefcase } from "../../../assets/Icons/Show/Briefcase.svg";
import { ReactComponent as Doctor } from "../../../assets/Icons/Show/Doctor.svg";
import { ReactComponent as Alivia } from "../../../assets/Icons/AliviaA_noCircle.svg";
import { ReactComponent as Cog } from "../../../assets/Icons/Cog.svg";
import { ReactComponent as Users } from "../../../assets/Icons/Show/Users.svg";
import { NotificationData } from "../../organisms/NotificationView/types";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import PlaceholderImg from "../../../assets/images/AliviaPlaceholderLogo.png";
import { Image } from "react-bootstrap";

interface Props {
  data: NotificationData;
  show: boolean;
}

export const NotificationEntity = (props: Props) => {
  const [patchNotifications] = usePatchNotificationsMutation();
  const [imgByType, setImgByType] = useState(<Bell />);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    switch (props.data.type) {
      case NotificationTypeEnum["loyalty-program"]:
        return setImgByType(<Star className={"text-warning"} />);
      case NotificationTypeEnum["events"]:
        return setImgByType(<Events className={"text-primary"} />);
      case NotificationTypeEnum["articles"]:
        return setImgByType(<Articles className={"text-success"} />);
      case NotificationTypeEnum["oncocollection"]:
        return setImgByType(<Heart className={"text-secondary"} />);
      case NotificationTypeEnum["company"]:
        return setImgByType(<Briefcase className={"text-primary"} />);
      case NotificationTypeEnum["doctor"]:
        return setImgByType(<Doctor className={"text-primary h-50 w-50"} />);
      case NotificationTypeEnum["admin"]:
        return setImgByType(<Alivia className={"p-1 ps-2"} />);
      case NotificationTypeEnum["user-settings"]:
        return setImgByType(<Cog className={"text-primary"} />);
      case NotificationTypeEnum["guardian-patient"]:
        return setImgByType(<Users className={"text-warning"} />);
      case NotificationTypeEnum["other"]:
      default:
        return setImgByType(<Bell className={"text-dark-blue"} />);
    }
  }, [props.data]);

  const handleRead = async () => {
    if (props.data.url) {
      openInNewTab(props.data.url as string);
    }
    if (props.data.read === 0) {
      await patchNotifications({
        id: props.data?.id,
        data: {
          read: 1,
        },
      });
    }
  };

  return (
    <div
      className={`d-flex ${!props.data.read && "bg-primary bg-opacity-10"} ${
        props.data.url && "cursor-pointer"
      } mb-4 p-2 gap-2`}
      onClick={handleRead}
    >
      <div className={"position-relative pt-1"}>
        {props.data.senderUser?.avatars?.length ? (
          <Image
            roundedCircle={true}
            className={`border border-1 border-gray bg-light my-3 mt-0 mb-auto ${styles.image}`}
            src={
              convertImageIdToUrl(
                props.data.senderUser?.avatars,
                false,
                "mini.jpeg"
              ) || PlaceholderImg
            }
          />
        ) : (
          <div
            className={`d-flex justify-content-center align-items-center border border-1 border-gray bg-light rounded-circle ${styles.image}`}
          >
            {imgByType}
          </div>
        )}
        {!props.data.read && <div className={styles.redCircle} />}
      </div>
      <div className={"w-100 overflow-hidden pe-2"}>
        <p
          className={"text-truncate fs-16 fw-semibold ls-3 m-0 text-dark-blue"}
        >
          {props.data?.title}
        </p>
        {props.data?.content && (
          <p className={"m-0 mt-1 text-dark-blue"}>{props.data?.content}</p>
        )}
        <p className={"text-truncate text-grey fs-12 ls-3 m-0 mt-1"}>
          {changeDateFormatNames(props.data.createdAt)}
        </p>
      </div>
    </div>
  );
};
