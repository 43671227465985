import { topicCase } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["TopicCase"],
});

interface GetTopicCaseProps {
  limit?: string;
  offset?: string;
}

export interface PatchTopicCaseData {
  case: string;
  status?: number;
  sfSynchro: number;
}

export interface PostTopicCaseData {
  case: string;
  sfSynchro: number;
}

interface PatchTopicCaseProps {
  id: number;
  data: PatchTopicCaseData;
}

interface PostTopicCaseProps {
  data: PostTopicCaseData;
}

export const topicCaseAPi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTopicsCase: builder.query({
      query: ({ limit, offset }: GetTopicCaseProps) => {
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
        });

        return {
          url: `${topicCase}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["TopicCase"],
    }),

    postTopicCase: builder.mutation({
      query: ({ data }: PostTopicCaseProps) => ({
        url: `${topicCase}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["TopicCase"],
    }),

    patchTopicCase: builder.mutation({
      query: ({ id, data }: PatchTopicCaseProps) => ({
        url: `${topicCase}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["TopicCase"],
    }),

    getTopicCase: builder.query({
      query: (id: number) => {
        return {
          url: `${topicCase}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["TopicCase"],
    }),
  }),
});

export const {
  useGetTopicsCaseQuery,
  useGetTopicCaseQuery,
  usePostTopicCaseMutation,
  usePatchTopicCaseMutation,
} = topicCaseAPi;
