import { facilityReviews } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

interface PostFacilityReviewAbuseData {
  reviewId: number;
  content: string;
}

export interface PostFacilityReviewPayload {
  data: PostFacilityReviewAbuseData;
}

interface PatchFacilityReviewAbuseData {
  content?: string;
  answer?: string;
  decision?: number;
}
export interface PatchFacilityReviewAbusePayload {
  id: number;
  data: PatchFacilityReviewAbuseData;
}

interface PostFacilityReviewPayloadData {
  facility: number | null;
  addedByUser?: number;
  pollUserId?: number;
  source?: number;
  content: string;
  rating: number;
  name?: string;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["FacilityReviews", "PatchFacilityReviews"],
});

export const facilityReviewsApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    postFacilityReviews: builder.mutation({
      query: (payload: PostFacilityReviewPayloadData) => ({
        url: `${facilityReviews}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: payload,
      }),
      invalidatesTags: ["FacilityReviews"],
    }),
    getFacilityReviews: builder.query({
      query: ({ status, limit, offset, sortBy, nameLike, systemId }) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(status && { status }),
          ...(nameLike && { facilityNameLike: nameLike }),
          ...(systemId && { systemId }),
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { sortBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });

        return {
          url: `${facilityReviews}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["FacilityReviews"],
    }),

    patchFacilityReviews: builder.mutation({
      query: ({ id, data }) => ({
        url: `${facilityReviews}/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PatchFacilityReviews"],
    }),

    postFacilityReviewsAbuse: builder.mutation({
      query: ({ data }: PostFacilityReviewPayload) => ({
        url: `${facilityReviews}/abuse`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PatchFacilityReviews"],
    }),

    patchFacilityReviewsAbuse: builder.mutation({
      query: ({ id, data }: PatchFacilityReviewAbusePayload) => ({
        url: `${facilityReviews}/abuse/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["PatchFacilityReviews"],
    }),
  }),
});

export const {
  usePostFacilityReviewsMutation,
  useLazyGetFacilityReviewsQuery,
  usePatchFacilityReviewsMutation,
  usePostFacilityReviewsAbuseMutation,
  usePatchFacilityReviewsAbuseMutation,
} = facilityReviewsApi;
