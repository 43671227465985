import CustomBadge from "../../atoms/CustomBadge/CustomBadge";

interface RatingBadgeProps {
    rating?: number;
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const RatingBadge = ({
                         variant = "h6",
                         rating,
                     }: RatingBadgeProps) => {

    const getBgColor = () => {
        if (!rating) {
            return "light";
        }

        if (rating >= 4.00) {
            return "success";
        }

        if (rating < 4.00 && rating >= 2.00) {
            return "warning";
        }

        if (rating < 2.00 && rating >= 1.00) {
            return "secondary";
        } else {
            return "light";
        }
    };

    return (
        <CustomBadge
            bg={getBgColor()}
            variant={variant}
            desc={`${rating}`}
        />
    );
};

export default RatingBadge;
