import { Badge, Button, Container, Dropdown, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ReactComponent as TrashBlank } from "../../../assets/Icons/TrashBlank.svg";
import { ReactComponent as ArrowUpRightFromSquare } from "../../../assets/Icons/ArrowUpRightFromSquare.svg";
import styles from "./CompanyUserEntity.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PlaceholderImg from "../../../assets/images/AliviaPlaceholderLogo.png";
import { useDeleteCompanyUserMutation } from "../../../redux/services/companyApi";
import PopupModal from "../PopupModal/PopupModal";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { ReactComponent as TripleDot } from "../../../assets/Icons/TripleDot.svg";
import { User } from "../../../redux/Slice/user-slice";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

interface CompanyUserEntityProps {
  data: User;
}

export const CompanyUserEntity = (props: CompanyUserEntityProps) => {
  const { id: companyId } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [
    deleteCompanyUser,
    { isSuccess: deleteIsSuccess, error: deleteError },
  ] = useDeleteCompanyUserMutation();

  useEffect(() => {
    if (deleteIsSuccess) {
      toast.success(t("toast_companyUserEntity_success"));
    }
    // eslint-disable-next-line
  }, [deleteIsSuccess, deleteError]);

  const hideModalHandler = () => setShowModal(false);

  const modalAcceptanceHandler = async () => {
    await deleteCompanyUser({ companyId: companyId, userId: props.data.id });
    hideModalHandler();
  };

  return (
    <Container
      fluid={true}
      key={props.data.id}
      className={"d-flex justify-content-between m-0 p-0"}
    >
      <div className={"d-flex"}>
        <Image
          fluid={true}
          className={`p-0 m-0 ${styles.imageLogo}`}
          roundedCircle
          src={
            convertImageIdToUrl(props.data?.imageIds, false, "small.jpeg") ||
            props.data?.avatar?.accessUrl ||
            PlaceholderImg
          }
        />
        <div
          className={
            "d-flex align-items-center fs-16 fw-semibold text-dark-blue ps-3"
          }
        >
          {!props.data.status && (
            <Badge className="fs-10 fw-semibold lh-130 ls-6 text-white bg-secondary">
              oczekuje na weryfikację
            </Badge>
          )}

          <span>{props.data.name}</span>
        </div>
      </div>
      <Dropdown className={"d-flex d-lg-none"}>
        <Dropdown.Toggle
          variant="transparent"
          id="dropdown-basic"
          className={styles.dropdownToggle}
        >
          <TripleDot />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => navigate(`/${NAV_PATH.PROFILE}/${props.data.id}`)}
          >
            Pokaż profil
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setShowModal((prev: boolean) => !prev)}>
            Usuń użytkownika
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className={"d-none d-lg-flex gap-3"}>
        <Button
          disabled={false}
          className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
          variant={"transparent"}
          onClick={() => navigate(`/${NAV_PATH.PROFILE}/${props.data.authId}`)}
        >
          <ArrowUpRightFromSquare />
        </Button>
        <Button
          className={`d-flex justify-content-center align-items-center p-0 m-0 text-dark-blue ${styles.buttonSize}`}
          variant={"transparent"}
          onClick={() => setShowModal((prev: boolean) => !prev)}
        >
          <TrashBlank />
        </Button>
        <PopupModal
          titleClasses="fw-600 text-center"
          text="Czy na pewno chcesz usunąć użytkownika?"
          type="confirm"
          show={showModal}
          setShow={hideModalHandler}
          confirmAction={() => modalAcceptanceHandler()}
        />
      </div>
    </Container>
  );
};
