import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./FileDropzone.module.scss";
import { Button } from "react-bootstrap";
import { usePostAttachmentsMutation } from "redux/services/attachmentsApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FileDropzone = ({
  setDropzoneIsActive,
  attachmentType,
}: FileDropzoneProps) => {
  const { acceptedFiles, isDragActive, open, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 5,
      minSize: 0,
      maxSize: 41943040,
      noClick: true,
      noKeyboard: true,
    });

  const [postAttachments, { isSuccess, error }] = usePostAttachmentsMutation();

  const { t } = useTranslation();

  // const acceptedFileItems = acceptedFiles.map((file) => file.name);

  useEffect(() => {
    if (!!acceptedFiles.length) {
      acceptedFiles.forEach((item) =>
        postAttachments({ data: item, attachmentType: attachmentType })
      );
    }
    // eslint-disable-next-line
  }, [acceptedFiles, postAttachments]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("toast_fileDropzone_success"));
    } else if (error) {
      toast.error(t("toast_fileDropzone_error"));
    }
    // eslint-disable-next-line
  }, [error, isSuccess]);

  return (
    <div className={`${styles.dropzoneWrapper}`}>
      <div
        className={`${styles.label} d-flex flex-column`}
        {...getRootProps()}
        data-dragactive={isDragActive}
      >
        <button
          type="button"
          className={`btn-close ${styles.closeButton}`}
          aria-label="Close"
          onClick={() => setDropzoneIsActive((prev: boolean) => !prev)}
        ></button>
        <span>Upuść tutaj pliki, aby je przesłać</span>
        <span className="fs-13 text-muted my-2">lub</span>
        <Button variant="outline-primary" onClick={open}>
          {/* {acceptedFileItems.length ? acceptedFileItems : "Wybierz pliki"} */}
          Wybierz plik
        </Button>
        <span className="fs-13 text-muted mt-4">
          Maksymalna wielkość dodawanych plików: 40 MB.
        </span>
        <input {...getInputProps()} multiple />
      </div>
    </div>
  );
};

export default FileDropzone;
