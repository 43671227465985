import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./PictureDropzone.module.scss";
import { Button, Image } from "react-bootstrap";
import { ReactComponent as ArrowNarrowUpMove } from "../../../assets/Icons/ArrowNarrowUpMove.svg";

const PictureDropzone = ({
  handlePicture,
  imageUrl,
  setImageUrl,
}: PictureDropzone) => {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    setImage(imageUrl);
  }, [imageUrl]);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageDataUrl = reader.result as string;
      setImage(imageDataUrl);
      setImageUrl && setImageUrl(imageDataUrl);
    };

    reader.readAsDataURL(file);
  };

  const {
    acceptedFiles,
    isDragActive,
    open,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    minSize: 0,
    maxSize: 5242880,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/tiff": [".tif", ".tiff"],
    },
    onDrop,
  });

  useEffect(() => {
    handlePicture(acceptedFiles[0] || null);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  const removeImage = () => {
    setImage(null);
    acceptedFiles.splice(0, acceptedFiles.length);
    fileRejections.splice(0, fileRejections.length);
    getRootProps({ onClick: (event) => event.stopPropagation() });
    getInputProps();
  };

  return (
    <div className={`${styles.dropzoneWrapper}`}>
      {image ? (
        <div
          className={`d-flex flex-column justify-content-center align-items-center border-1 p-3 ${styles.label}`}
        >
          <Button
            className={"ms-auto mb-3"}
            onClick={removeImage}
            variant={"primary"}
          >
            USUŃ ZDJĘCIE
          </Button>
          <Image className={`p-0 m-0 w-auto h-auto`} fluid={true} src={image} />
        </div>
      ) : (
        <div
          className={`${styles.label} d-flex flex-column`}
          {...getRootProps()}
          data-dragactive={isDragActive}
        >
          <Button
            className="btn-primary d-flex justify-content-center align-items-center mb-2 px-4"
            onClick={open}
          >
            <ArrowNarrowUpMove />
            <span className={"ps-2 fs-16"}>PRZEGLĄDAJ PLIKI</span>
          </Button>
          <span>lub przeciągnij plik na pole</span>
          <input
            {...getInputProps()}
            accept="image/jpeg, image/png, image/tiff"
          />
        </div>
      )}
    </div>
  );
};

export default PictureDropzone;
