import * as React from "react";
import styles from "./SortArrowIcon.module.scss";

interface Props {
  className?: string;
  isRotated?: boolean;
  isFocussed?: boolean;
}

const SortArrowIcon = ({ className, isRotated, isFocussed }: Props) => {
  const svgStyles = !isRotated ? styles.basic : styles.rotate;

  return (
      <svg
          className={`${svgStyles} ${className}`}
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1.5L5 5.5L9 1.5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
        />
      </svg>
  );
};

export default SortArrowIcon;
