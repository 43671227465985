import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useOpenHelpTopicCustomMessage } from "Helpers/openMessageHooks";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setForceSend } from "redux/Slice/messages-slice";
import { useGetTranslationsByLangIdQuery } from "redux/services/translationsApi";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import {
  usePatchStatusAndDeletedMutation,
  usePatchUserSFContactMutation,
} from "redux/services/usersApi";
import { useSendMailTemplateMutation } from "redux/services/emailsApi";
import { logoutHandler } from "Helpers/logoutHandler";

interface DeleteAccountModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FilteredTranslation {
  id: string;
  title: string;
}

const DeleteAccountModal = ({ show, setShow }: DeleteAccountModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      reason: "",
    },
  });
  const { data: userData } = useGetMeQuery({});
  const POLISH_LANG_ID = 1;
  const [changeAccountStatus] = usePatchStatusAndDeletedMutation();
  const dispatch = useDispatch();
  const [showDeleteContent, setShowDeleteContent] = useState<boolean>(false);
  const [patchSfAccount] = usePatchUserSFContactMutation();
  const { data: translationsData } = useGetTranslationsByLangIdQuery({
    languageId: POLISH_LANG_ID,
  });

  const [sendMailTemplate] = useSendMailTemplateMutation();
  const [patchUserSettings] = usePatchMeMutation();

  let filteredTranslations: FilteredTranslation[] = [];
  if (translationsData?.data) {
    filteredTranslations = Object.keys(translationsData.data)
      .filter((key) => /^delete_account_reason_\d+$/.test(key))
      .map((key) => ({
        title: translationsData.data[key],
        id: key,
      }));
  }

  const [selectedReasonTitle, setSelectedReasonTitle] = useState("");

  const emailDataToSend = {
    emailKey: "accountDelete",
    email: userData?.data?.email,
    sendToAlivia: false,
    languageCode: "pl",
    replaceArray: [""],
  };

  const OTHER_TOPIC_ID = 6;
  const STATUS_TOPIC_VISIBLE = 1;

  const DELETE_ACCOUNT_CASE = {
    id: OTHER_TOPIC_ID,
    case: "Inne",
    status: STATUS_TOPIC_VISIBLE,
  };

  const deleteAccount = useOpenHelpTopicCustomMessage({
    topic: DELETE_ACCOUNT_CASE,
    customMessage: selectedReasonTitle,
  });

  const ACCOUNT_TO_BE_DELETED_STATUS = 90;

  const currentUserData = userData?.data;
  const patchSettingsData = {
    settings: { ...currentUserData?.settings, Do_usuni_cia__c: true },
  };

  const onSubmit = async () => {
    await deleteAccount();
    dispatch(setForceSend(true));
    sendMailTemplate(emailDataToSend);
    await changeAccountStatus({
      data: {
        userId: userData?.data?.id,
        status: ACCOUNT_TO_BE_DELETED_STATUS,
      },
    });
    await patchSfAccount({
      userId: `${userData?.data?.id}`,
      data: {
        Do_usuni_cia__c: true,
      },
    });
    await patchUserSettings({ data: patchSettingsData });
    reset();
    setShowDeleteContent(true);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header>
        <p>Usuwanie konta</p>
      </Modal.Header>
      <Modal.Body>
        {showDeleteContent ? (
          <>
            <p>Twoje konto zostanie usunięte w przeciągu 7 dni.</p>
            <Button variant="primary" onClick={() => logoutHandler()}>
              OK
            </Button>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {filteredTranslations.map((reason) => (
              <Form.Check
                key={reason.id}
                type="radio"
                label={reason.title}
                value={reason.id}
                {...register("reason", { required: true })}
                onClick={() => setSelectedReasonTitle(reason.title)}
                className="mb-3"
              />
            ))}
            <Button
              variant="outline-dark"
              onClick={() => {
                reset();
                setShow(false);
              }}
            >
              Anuluj
            </Button>
            <Button
              disabled={!isDirty}
              variant="danger"
              type="submit"
              className="ms-3"
            >
              Usuń konto
            </Button>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAccountModal;
