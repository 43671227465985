import { useParams, useSearchParams } from "react-router-dom";
import { useGetLoyaltyProgramsServicesQuery } from "../../../redux/services/loyaltyProgramApi";
import { useGetCompanyQuery } from "../../../redux/services/companyApi";
import { Container, Row, Col, Image, Stack, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import PlaceholderImg from "assets/images/companyNormal.png";
import styles from "./LoyaltyProgramServiceCompanyContent.module.scss";
import { LoyaltyProgramServiceSearch } from "../LoyaltyProgramServiceSearch/LoyaltyProgramServiceSearch";
import ListOfSocialPage from "../../molecules/ListOfSocialPage/ListOfSocialPage";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { ViewNotAuthorized } from "../../atoms/ViewNotAuthorized/ViewNotAuthorized";
import { useTranslation } from "react-i18next";

const LIMIT = 9;

export const LoyaltyProgramServiceCompanyContent = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1,
  );
  const { id: companyId } = useParams();
  const [sort, setSort] = useState<string | null>(searchParams.get("sortBy"));
  const { data: loyaltyProgramsServicesData, isLoading } =
    useGetLoyaltyProgramsServicesQuery({
      companyId: companyId,
      offset: currPage ? ((currPage - 1) * LIMIT).toString() : "0",
      categoryId: searchParams.get("category"),
      provinceId: searchParams.get("province"),
      cityId: searchParams.get("city"),
      limit: LIMIT,
      sortBy: searchParams.get("sortBy"),
    });
  const { data: loyaltyProgramsCompanyData, isLoading: isLoadingCompany } =
    useGetCompanyQuery(companyId);

  if (isLoading || isLoadingCompany) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (!loyaltyProgramsCompanyData || !loyaltyProgramsCompanyData.data) {
    return (
      <ViewNotAuthorized
        value={t("company_not_view_header")}
        info={t("company_not_view_info")}
      />
    );
  }

  const handlePageChange = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
    setCurrPage(newPage);
  };

  const handleSort = (sortBy: string) => {
    searchParams.set("sortBy", sortBy);
    setSearchParams(searchParams);
    setSort(sortBy);
  };

  const newsSortHandler = () =>
    sort === "news,ASC" ? handleSort("news,DESC") : handleSort("news,ASC");

  const popularSortHandler = () =>
    sort === "popular,ASC"
      ? handleSort("popular,DESC")
      : handleSort("popular,ASC");

  return (
    <Container
      fluid={true}
      className="bg-light"
      key={loyaltyProgramsCompanyData.data.id}
    >
      <section className={styles.section}>
        <Stack gap={5}>
          <Col className={"d-block d-md-flex justify-content-start"}>
            <div className={"d-flex justify-content-center"}>
              <Image
                className={`p-0 m-0 ${styles.imageLogo}`}
                roundedCircle
                src={
                  convertImageIdToUrl(
                    loyaltyProgramsCompanyData.data.imageIds,
                    false,
                    "normal.jpeg",
                  ) || PlaceholderImg
                }
              />
            </div>
            <div
              className={
                "d-flex align-items-center justify-content-center fs-14 fw-semibold text-dark-blue ps-0 ps-md-5 pt-3 pt-md-0"
              }
            >
              <p className={"d-flex flex-column m-0 p-0"}>
                <span
                  className={
                    "text-dark-blue fs-14 fw-normal text-center text-md-start"
                  }
                >
                  Więcej benefitów od
                </span>
                <span
                  className={"text-dark-blue fs-39 text-center text-md-start"}
                >
                  {loyaltyProgramsCompanyData.data.name}
                </span>
              </p>
            </div>
          </Col>

          <Row className={`${styles.wrapper}`}>
            <LoyaltyProgramServiceSearch
              data={loyaltyProgramsServicesData}
              label={t("label_LoyaltyProgramServiceCompanyContent")}
              limit={LIMIT}
              currPage={currPage}
              sort={sort}
              handlePageChange={handlePageChange}
              newsSortHandler={newsSortHandler}
              popularSortHandler={popularSortHandler}
            />
          </Row>
          <Row className={`${styles.wrapper}`}>
            <Container fluid={true}>
              <Row>
                <p className={"fs-19 fw-bolder mb-4 text-dark-blue mx-0"}>
                  O firmie
                </p>
                <p className={"fs-16 mb-4 text-dark-blue mx-0"}>
                  {/*<div dangerouslySetInnerHTML={{__html: loyaltyProgramsCompanyData.data.description}}/>*/}
                  {loyaltyProgramsCompanyData.data.description}
                </p>
              </Row>
              {loyaltyProgramsCompanyData.data.socialAddresses && (
                <Row>
                  <span
                    className={"fw-bolder text-dark-blue fs-19 col-12 pb-3"}
                  >
                    Serwisy internetowe
                  </span>
                  <ListOfSocialPage
                    social={
                      loyaltyProgramsCompanyData.data.socialAddresses || ""
                    }
                    size={40}
                  />
                </Row>
              )}
              {/*{loyaltyProgramsCompanyData.data.companyDepartment.length > 0 &&*/}
              {/*    < Col>*/}
              {/*        < CompanyAccordion*/}
              {/*            data={loyaltyProgramsCompanyData.data.companyDepartment}*/}
              {/*            defaultActiveKey={null}*/}
              {/*        />*/}
              {/*    </Col>}*/}
            </Container>
          </Row>
        </Stack>
      </section>
    </Container>
  );
};
