import { useState } from "react";
import { ReactComponent as AliviaLogo } from "../../../assets/Icons/Alivia_logo.svg";
import { ReactComponent as Bell } from "../../../assets/Icons/bell.svg";
import { ReactComponent as Message } from "../../../assets/Icons/message.svg";
import { ReactComponent as Person } from "../../../assets/Icons/person.svg";
import { Messages } from "../Messages/Messages";
import "./NavBar.css";
import { useDispatch, useSelector } from "react-redux";
import { setShowMessages, setView, View } from "redux/Slice/messages-slice";
import { RootState } from "redux/store/index";
import { Button, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import style from "./NavBar.module.scss";
import { useNavigate } from "react-router";
import { BoxArrowLeft, Gear, QuestionCircle } from "react-bootstrap-icons";
import { logoutHandler } from "Helpers/logoutHandler";
import { useMediaQuery } from "@mantine/hooks";
import SearchBar from "../SearchBar/SearchBar";
import { NotificationView } from "../NotificationView/NotificationView";
import { useTranslation } from "react-i18next";
import { NAV_PATH } from "../Navigation/navigationData";

const NavBar = ({ setShow }: any) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 768px)`);
  const navigate = useNavigate();
  const [isUnreadNotification, setIsUnreadNotification] =
    useState<boolean>(false);
  const showMessages = useSelector(
    (state: RootState) => state.messages.showMessages
  );
  const isUnread = useSelector(
    (state: RootState) => state.messages.unreadMessages
  );
  const { authUser } = useSelector((state: RootState) => state.user);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChangeSetShowNotification = () => {
    setShowNotification((prevState: boolean) => !prevState);
    setIsUnreadNotification(false);
  };

  const handleChangeSetUnreadNotification = (unread: boolean) => {
    setIsUnreadNotification(unread);
  };

  const isImpersonated = !!authUser?.impersonated;

  const disableFuncionalityWhenAccountIsDeleted = [0, 90, 100, 200].includes(
    authUser.status
  );

  return (
    <>
      <Navbar
        bg="white"
        className={`${style.navBar} m-0 p-0 w-100 d-flex justify-content-between align-items-center`}
      >
        <Container fluid>
          <button
            className="navbar-toggler d-block d-sm-none me-2 border-0"
            type="button"
            onClick={() => setShow((prevShow: boolean) => !prevShow)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Navbar.Brand
            href={`/${NAV_PATH.DASHBOARD}`}
            className={style.navBrand}
          >
            <AliviaLogo width={isMobile ? 150 : 192} />
          </Navbar.Brand>
          <div
            className={`${style.navBarSearch} d-none d-md-flex justify-content-center align-items-center`}
          >
            <SearchBar isDisabled={disableFuncionalityWhenAccountIsDeleted} />
          </div>
          <Nav className="justify-content-end flex-grow-1 gap-lg-1">
            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia `}
              variant="transparent"
              onClick={() => {
                dispatch(setShowMessages(!showMessages));
                dispatch(setView(View.MAIN));
              }}
              disabled={disableFuncionalityWhenAccountIsDeleted}
            >
              {isUnread && <span className={`${style.redDotMessage}`} />}
              <Message />
            </Button>

            <Button
              className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                showNotification
                  ? "text-light"
                  : "text-dark-blue bg-transparent border-0"
              }`}
              variant={showNotification ? "dark" : "transparent"}
              onClick={handleChangeSetShowNotification}
              disabled={disableFuncionalityWhenAccountIsDeleted}
            >
              {isUnreadNotification && (
                <span className={`${style.redDotBell}`}></span>
              )}
              <Bell />
            </Button>
            <Nav.Item className="p-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="transparent"
                  id="dropdown-basic"
                  className={`btn-square-alivia d-flex justify-content-center align-items-center p-0 m-0 d-flex justify-content-center align-items-center ${style.dropdownButton}`}
                >
                  <Person color="white" />
                </Dropdown.Toggle>

                <Dropdown.Menu className={style.dropdownRight}>
                  {!disableFuncionalityWhenAccountIsDeleted && (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate(`/${NAV_PATH.PROFILE}`)}
                        className={`py-3 px-4 ${style.dItemFirst}`}
                      >
                        <div className="my-3 me-2 ms-0 d-inline">
                          <Person />
                        </div>
                        {t("navbar_user_dropdown_profile")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(`/${NAV_PATH.SETTINGS}`)}
                        className={`py-3 px-4 ${style.dItem}`}
                      >
                        <div className="my-3 me-2 ms-0 d-inline">
                          <Gear size={24} />
                        </div>
                        {t("navbar_user_dropdown_settings")}
                      </Dropdown.Item>
                    </>
                  )}

                  <Dropdown.Item
                    className={`py-3 px-4 ${style.dItem}`}
                    onClick={() => navigate(`/${NAV_PATH.HELP}`)}
                  >
                    <div className="my-3 me-2 ms-0 d-inline">
                      <QuestionCircle size={24} />
                    </div>
                    {t("navbar_user_dropdown_help")}
                  </Dropdown.Item>
                  {!isImpersonated && (
                    <Dropdown.Item
                      className={`py-3 px-4 ${style.dItem}`}
                      onClick={logoutHandler}
                    >
                      <div className="my-3 me-2 ms-0 d-inline">
                        <BoxArrowLeft size={24} />
                      </div>
                      {t("navbar_user_dropdown_sign_out")}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      <div
        className={`${style.navBarSearchSmaller} d-md-none bg-white d-flex justify-content align-items-center pb-4 px-3`}
      >
        <SearchBar />
      </div>
      <Messages />
      <NotificationView
        show={showNotification}
        handleClose={handleChangeSetShowNotification}
        handleChangeSetUnreadNotification={handleChangeSetUnreadNotification}
      />
    </>
  );
};

export default NavBar;
