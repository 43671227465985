import { Button, Container } from "react-bootstrap";
import styles from "./WidgetWithButton.module.scss"

const WidgetWithButton = ({buttonText, buttonVariant, text, title, buttonAction} : WidgetWithButtonProps) => {
    return (
<Container fluid className={`p-4 ${styles.myCancerContainer}`}>
<h2 className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}>{title}</h2>
<p className="fw-400 fs-16 text-dark-blue">{text}</p>
<Button variant={buttonVariant} onClick={buttonAction} className="w-100 text-white">{buttonText}</Button>
</Container>);}

export default WidgetWithButton;