export const FILTER_CARD_CODES = {
  ASSIGNED_TYPES: [
    {
      name: "used_codes",
      label: "Użyte kody",
      value: 1,
    },
    {
      name: "no_used_codes",
      label: "Nieużyte kody",
      value: 2,
    },
    {
      name: "assigned_user",
      label: "Przypisani użytkownicy",
      value: 3,
    },
    {
      name: "no_assigned_user",
      label: "Nieprzypisani użytkownicy",
      value: 4,
    },
  ],
};
