import { Button, Container, Row, Spinner } from "react-bootstrap";
import { useGetCompaniesQuery } from "../../../redux/services/companyApi";
import { Company } from "../../molecules/LoyaltyProgramServiceArticle/types";
import { ReactComponent as PlusIcon } from "../../../assets/Icons/LargePlus.svg";
import { useTranslation } from "react-i18next";
import { CompanyEntity } from "../../molecules/CompanyEntity/CompanyEntity";
import style from "./LoyaltyProgramServiceMyCompany.module.scss";
import { useDispatch } from "react-redux";
import { openModal } from "redux/Slice/company-modal-slice";

interface Props {
  name: string;
}

export const LoyaltyProgramServiceMyCompany = ({ name }: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    data: companiesData,
    isLoading: companiesIsLoading,
    refetch: companiesRefetch,
  } = useGetCompaniesQuery({});

  return (
    <Container fluid={true} className={`p-4 containerWithShadow `}>
      <Row>
        <div
          className={`fs-22 fw-600 mb-4 text-dark-blue d-md-flex justify-content-between align-items-center ${style.title}`}
        >
          <span>{name}</span>
          {!!companiesData?.data?.length && (
            <Button
              variant={"transparent"}
              className={
                "d-flex justify-content-center align-items-center btn-square-alivia p-0 m-0 text-dark-blue"
              }
              onClick={() => dispatch(openModal())}
            >
              <PlusIcon />
            </Button>
          )}
        </div>
        {companiesIsLoading ? (
          <Container className={"d-flex justify-content-center"}>
            <Spinner animation="border" variant="primary" />
          </Container>
        ) : !!companiesData?.data?.length ? (
          <div className={"d-flex flex-column gap-4"}>
            {companiesData?.data?.map((loyaltyProgramCompany: Company) => (
              <CompanyEntity
                key={loyaltyProgramCompany.id}
                data={loyaltyProgramCompany}
                reFetchCompany={companiesRefetch}
              />
            ))}
          </div>
        ) : (
          <div className={"mt-3"}>
            <p className={"text-dark-blue fs-16 fw-normal"}>
              {t("widget_your_company_p")}
            </p>

            <Button
              className={"w-100 mt-2"}
              onClick={() => dispatch(openModal())}
            >
              {t("widget_loyalty_your_company_add_button")}
            </Button>
          </div>
        )}
      </Row>
    </Container>
  );
};
