import { useGetLoyaltyProgramsServiceQuery } from "../../../redux/services/loyaltyProgramApi";
import { Container, Row, Spinner, Form } from "react-bootstrap";
import { format } from "date-fns";
import { ReactComponent as Info } from "../../../assets/Icons/Info.svg";
import styles from "./CompanyServiceInfo.module.scss";
import TooltipIcon from "../../atoms/TooltipIcon";
import {
  CompanyDepartment,
  LoyaltyProgramServiceDepartment,
} from "../LoyaltyProgramServiceArticle/types";
import PartnerCodesUpload from "components/organisms/PartnerCodesUpload/PartnerCodesUpload";
import LoyaltyProgramServiceCodes from "components/organisms/LoyaltyProgramServiceCodes/LoyaltyProgramServiceCodes";

interface Props {
  id: number;
}

export const CompanyServiceInfo = (props: Props) => {
  const {
    data: loyaltyProgramsServiceCompanyData,
    isLoading: isLoadingService,
    fulfilledTimeStamp,
  } = useGetLoyaltyProgramsServiceQuery({ id: props.id, status: 1 });

  if (isLoadingService) {
    return (
      <Container className={"d-flex justify-content-center"}>
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  return (
    <Container fluid={true} className={""} key={props.id}>
      <Row sm={1} xl={2} className={"g-5 mt-0 pt-0"}>
        <div className={"mt-2 pt-0"}>
          <p className={"fs-12 text-grey-2 fw-semibold py-3 m-0"}>
            Okres trwania rabatu
          </p>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between fs-16 border-bottom pb-2"
            }
          >
            <p className={"m-0"}>Data rozpoczęcia promocji</p>
            <p className={"fw-bold m-0"}>
              {format(
                new Date(loyaltyProgramsServiceCompanyData?.data?.startAt),
                "dd.MM.yyyy"
              )}
            </p>
          </div>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between fs-16 border-bottom py-2"
            }
          >
            <p className={"m-0"}>Data zakończenia promocji</p>

            {loyaltyProgramsServiceCompanyData?.data?.endAt ? (
              <p className={"fw-bold m-0"}>
                {format(
                  new Date(loyaltyProgramsServiceCompanyData?.data?.endAt),
                  "dd.MM.yyyy"
                )}
              </p>
            ) : (
              <p className={"fw-bold m-0"}>Bez ograniczeń</p>
            )}
          </div>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between fs-16 py-2"
            }
          >
            <p className={"m-0"}>Data ważności kodów*</p>

            {loyaltyProgramsServiceCompanyData?.data?.promoCodesEndAt ? (
              <p className={"fw-bold m-0"}>
                {format(
                  new Date(
                    loyaltyProgramsServiceCompanyData?.data?.promoCodesEndAt
                  ),
                  "dd.MM.yyyy"
                )}
              </p>
            ) : (
              <p className={"fw-bold m-0"}>Bez ograniczeń</p>
            )}
          </div>
          <p className={"fs-12 text-grey-2 fw-semibold py-3 m-0"}>
            Lokalizacje realizujące rabat
          </p>
          {loyaltyProgramsServiceCompanyData?.data?.company?.companyDepartment
            ?.filter((value: CompanyDepartment) => value.deleted === 0)
            .map((value: CompanyDepartment) => (
              <div key={value.id} className="mb-1">
                <Form.Check>
                  <Form.Check.Input
                    type={"checkbox"}
                    disabled={true}
                    checked={
                      !!loyaltyProgramsServiceCompanyData?.data?.loyaltyProgramServiceDepartment?.find(
                        (valueDep: LoyaltyProgramServiceDepartment) =>
                          valueDep.status === 1 && valueDep.id === value.id
                      )
                    }
                    className={styles.inputCheckBox}
                  />
                  <Form.Check.Label>{value.name}</Form.Check.Label>
                </Form.Check>
              </div>
            ))}
        </div>
        <div className={"mt-2 pt-0"}>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between pb-2  py-lg-3"
            }
          >
            <p className={"fs-12 text-grey-2 fw-semibold m-0"}>
              Analityka strony rabatu
            </p>
            <p className={"fs-12 text-grey-2 fw-semibold m-0"}>
              Status na:{" "}
              {fulfilledTimeStamp &&
                format(new Date(fulfilledTimeStamp), "HH:mm, dd.MM.yyyy")}
            </p>
          </div>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between fs-16 border-bottom pb-2"
            }
          >
            <p className={"m-0"}>Wszystkie wejścia</p>
            <p className={"fw-bold m-0"}>
              {loyaltyProgramsServiceCompanyData?.data?.views}
            </p>
          </div>
          <div
            className={
              "d-flex flex-column flex-lg-row justify-content-between fs-16 py-2"
            }
          >
            <div className={"d-flex align-items-center "}>
              <p className={"m-0"}>Konwersja</p>
              <div className={"d-flex align-items-center text-primary ps-2"}>
                <TooltipIcon desc="Ilość rabatów zrealizowanych i w czasie realizacji względem ilości wyświetleń">
                  <Info />
                </TooltipIcon>
              </div>
            </div>
            <div className={"fw-bold"}>
              {loyaltyProgramsServiceCompanyData?.data?.views ? (
                <p className={"m-0"}>
                  {(
                    (loyaltyProgramsServiceCompanyData?.data?.codeUsageCount /
                      loyaltyProgramsServiceCompanyData?.data?.views) *
                    100
                  ).toFixed(2)}
                  %
                </p>
              ) : (
                <p className={"m-0"}>Brak danych</p>
              )}
            </div>
          </div>
        </div>
      </Row>
      {!loyaltyProgramsServiceCompanyData?.data?.multipleUseCodesAllowed && (
        <>
          <Row>
            <PartnerCodesUpload
              serviceId={loyaltyProgramsServiceCompanyData?.data?.id}
            />
          </Row>

          <Row className={styles.wrapper}>
            <LoyaltyProgramServiceCodes
              serviceId={loyaltyProgramsServiceCompanyData?.data?.id}
            />
          </Row>
        </>
      )}
    </Container>
  );
};
