import { EventData } from "../components/organisms/AddEventModal/types";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { getDateRange } from "./getDateRange";
import sortByEventTime from "./sortByEventTime";

interface FetchedEvents {
  events: EventData[];
}
export const getEventsForDay = (value: Value, fetchedEvents: FetchedEvents) => {
  const filteredEvents =
    value &&
    fetchedEvents?.events?.filter((event: EventData) => {
      const dateRange = getDateRange({
        startingDate: new Date(event?.startingDate),
        endingDate: new Date(event?.endingDate),
      });

      return dateRange?.some(
        (eventDate) =>
          new Date(eventDate?.toString())?.toLocaleDateString() ===
          new Date(value?.toString())?.toLocaleDateString(),
      );
    });

  if (filteredEvents) {
    return sortByEventTime(filteredEvents);
  }

  return;
};