import React, { useState } from "react";
import styles from "./AgreementLoyaltyProgramTableRow.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { ReactComponent as Arrow } from "../../../assets/Icons/Arrow.svg";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import PopupModal from "../../molecules/PopupModal/PopupModal";
import { usePatchLoyaltyProgramAgreementMutation } from "redux/services/loyaltyProgramApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface Props {
  data: LoyaltyProgramsAgreementAllData;
  handleEditData: (data: LoyaltyProgramsAgreementAllData) => void;
}

export const AgreementLoyaltyProgramTableRow = ({
  data,
  handleEditData,
}: Props) => {
  const { t } = useTranslation();
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [patchPatchLoyaltyProgramAgreement] =
    usePatchLoyaltyProgramAgreementMutation();

  const breadcrumbData = [
    {
      name: data.status ? "Utwórz nowy na podstawie" : "Edytuj",
      action: () => handleEditData(data),
    },
  ];
  if (data.status === 1) {
    breadcrumbData.push({
      name: data.status === 1 ? "Dezaktywuj" : "Aktywuj",
      action: () => setShowModalStatus(true),
    });
  }

  const modalStatusHandler = async () => {
    const result = await patchPatchLoyaltyProgramAgreement({
      agreementId: data.id.toString(),
      data: {
        status: data.status === 1 ? 0 : 1,
        version: data.version,
      },
    });

    if ("data" in result) {
      toast.success(t("toast_agreementLoyaltyProgramTableRow_success"));
    } else if ("error" in result) {
      const error = result?.error as TranslationSubmitError;

      if (error.data.statusCode === 500)
        toast.error(t("toast_agreementLoyaltyProgramTableRow_error_500"));

      if (error.data.statusCode === 404)
        toast.error(t("toast_agreementLoyaltyProgramTableRow_error_404"));

      if (error.data.statusCode === 400) toast.error(error.data.message);
      toast.error(t("toast_agreementLoyaltyProgramTableRow_error"));
    }
    setShowModalStatus(false);
  };

  return (
    <>
      <PopupModal
        titleClasses="fw-600 text-center text-dark-blue"
        text={`Czy na pewno chcesz ${
          data.status === 1 ? "dezaktywować" : "aktywować"
        } regulamin?`}
        smallText={`Program beneficjenta: ${data.loyaltyProgramName}`}
        type="confirm"
        show={showModalStatus}
        setShow={() => setShowModalStatus(false)}
        confirmAction={() => modalStatusHandler()}
      />
      <tr aria-controls="example-collapse-text" className={styles.mainTr}>
        <td className={`container-fluid ${styles.mainTd}`} colSpan={4}>
          <Row className="pb-2 bg-transpatent">
            <Col xs={1} className="text-dark-blue text-break">
              {data.id}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {t(data.loyaltyProgramName)}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {data.type}
            </Col>
            <Col xs={1} className="text-dark-blue text-break">
              {data.version}
            </Col>
            <Col xs={3} className="text-dark-blue text-break">
              {data.name}
            </Col>

            <Col className="d-flex justify-content-end align-content-start gap-3 text-dark-blue">
              <Button
                variant={isActive ? "dark" : "transparent"}
                className={`d-flex justify-content-center align-items-center p-0 m-0 btn-square-alivia ${
                  isActive
                    ? "text-light bg-dark"
                    : "text-dark-blue bg-transparent border-0"
                }`}
                onClick={() => setIsActive((prevState) => !prevState)}
              >
                <Arrow
                  className={isActive ? styles.arrowUp : styles.arrowDown}
                />
              </Button>
            </Col>
          </Row>
          {isActive && (
            <Row>
              <Col xs={12} className={"text-dark-blue"}>
                <span>Wersja: </span>
                <span className={"fw-semibold"}>{data.version}</span>
              </Col>
              <Col xs={12} className={"text-dark-blue"}>
                <span>Status: </span>
                <span className={"fw-semibold"}>{data.status}</span>
              </Col>
              <Col xs={12} className={"mb-2 text-dark-blue"}>
                <span>Regulamin: </span>
                <div
                  className={`fs-16 text-dark-blue border border-1 border-grey my-2 p-0`}
                  dangerouslySetInnerHTML={{ __html: data.description || "" }}
                />
              </Col>
            </Row>
          )}
          <Row className={`bg-transpatent ${styles.hoverRow}`}>
            <Col>
              <Breadcrumb data={breadcrumbData} />
            </Col>
          </Row>
        </td>
      </tr>
    </>
  );
};
