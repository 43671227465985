import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import React from "react";

interface Props {
  show: boolean;
  label: string;
}

export const ModalIsLoading = ({ show, label }: Props) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body className="d-flex flex-column align-items-center">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Wgrywanie...</span>
        </Spinner>
        <p className="mt-3">{label}</p>
      </Modal.Body>
    </Modal>
  );
};
