import PageContainer from "components/atoms/PageContainer/PageContainer";
import { Button, Container, Image } from "react-bootstrap";
// import { Bell } from "react-bootstrap-icons";
import styles from "./EventTemplate.module.scss";
import DefaultCover from "../../../assets/images/Alivia_EventCoverPlaceholder.svg";
import { CalendarEventCard } from "components/molecules/CalendarEventCard/CalendarEventCard";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteEventMutation,
  useEditEventMutation,
  useGetEventQuery,
  useLazyGetEventsQuery,
} from "redux/services/eventsApi";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store/index";
import { EventInfo } from "components/molecules/EventInfo/EventInfo";
import { EventHeader } from "components/molecules/EventHeader/EventHeader";
import {
  PostGetResponsePayload,
  useDeleteContactMutation,
  useGetGetResponseQuery,
  usePostGetResponseMutation,
} from "../../../redux/services/getResponseApi";
import getDaysFromDate from "Helpers/getDaysFromDate";
import DynamicPatientPoll from "components/organisms/DynamicPatientPoll/DynamicPatientPoll";
import { toast } from "react-toastify";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { useOpenHelpMessage } from "Helpers/openMessageHooks";
import { UserRole } from "redux/Slice/user-slice";
import { AUTH_DATA } from "Helpers/Roles/authData";
import {
  EventAdress,
  EventData,
} from "components/organisms/AddEventModal/types";
import EditEventModal from "components/organisms/EditEventModal/EditEventModal";
import { getEventTypeName } from "Helpers/getEventTypeName";
import { useGetEventTypesQuery } from "redux/services/eventTypesApi";
import { useGetSystemSettingsQuery } from "redux/services/systemSettingsApi";
import { useDispatch } from "react-redux";
import { clearPollId } from "redux/Slice/event-poll-slice";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";
import formatEventDateForRange from "../../../Helpers/formatEventDateForRange";

export const EventTemplate = () => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const userRolesArray = authUser.roles.map(
    (userRole: UserRole) => userRole.role.role
  );
  const dispatch = useDispatch();
  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );
  const { data: systemSettings } = useGetSystemSettingsQuery({});
  const { pollId } = useSelector((state: RootState) => state.eventPoll);
  const medicalVisitPollId = parseInt(
    systemSettings?.data?.find(
      (setting: SystemSettingItem) => setting?.context === "medicalVisit"
    )?.value || ""
  );

  const { id: eventId } = useParams();
  const openHelpMessage = useOpenHelpMessage();
  const navigate = useNavigate();
  const { data: eventData } = useGetEventQuery({ id: eventId || "" });
  const { data: eventTypesData } = useGetEventTypesQuery({});
  const [getSimillarEvents] = useLazyGetEventsQuery();
  const [isPollSended, setIsPollSended] = useState<boolean>(false);
  const [simillarEvents, setSimillarEvents] = useState<EventData[]>();
  // const [showNotifications, setShowNotifications] = useState(false);
  const [popup, setPopup] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [popupModalText, setPopupModalText] = useState(
    "Czy na pewno chcesz usunąć wydarzenie?"
  );
  const [popupModalType, setPopupModalType] = useState<"info" | "confirm">(
    "confirm"
  );
  const [isUserSignedUp, setIsUserSignedUp] = useState<boolean>(false);
  const [softDeleteEvent] = useDeleteEventMutation();
  const [patchEvent] = useEditEventMutation();

  const isMobile = useMediaQuery(`(max-width: 1200px)`);
  const isEnded = Boolean(
    getDaysFromDate(eventData?.data?.startingDate) > 0 &&
      new Date() > new Date(eventData?.data?.startingDate)
  );
  const isPrivate =
    eventData?.data?.user?.id === authUser.id &&
    (eventData?.data?.type === "medical" ||
      eventData?.data?.type === "consulting");
  const { data: getResponseContactsData, isFetching: isGetResponseFetching } =
    useGetGetResponseQuery({
      emailLike: authUser?.email,
      campaignId: eventData?.data?.eventUrl,
    });

  const [postGetResponse, { isSuccess: postGetResponseSuccess }] =
    usePostGetResponseMutation();
  const [deleteContact, { isSuccess: deleteContactSuccess }] =
    useDeleteContactMutation();

  const postGetResponseData: PostGetResponsePayload = {
    data: {
      email: authUser?.settings?.Email,
      name: authUser?.settings?.FirstName,
      surname: authUser?.settings?.LastName,
      campaignId: eventData?.data?.eventUrl || "",
    },
  };

  const handlePollSended = async () => {
    const result = await patchEvent({
      id: eventData?.data?.id,
      data: { ...eventData?.data, poll: medicalVisitPollId, pollUser: pollId },
    });
    if ("data" in result) {
      setIsPollSended(true);
      dispatch(clearPollId());
      toast.success("Ankieta przesłana poprawnie");
    } else {
      toast.error("Coś poszło nie tak");
      dispatch(clearPollId());
    }
  };

  const handleDeleteEvent = async () => {
    setPopup(false);
    const result = await softDeleteEvent({
      id: eventData?.data?.id,
    });
    if ("data" in result) {
      setPopupModalText("Wydarzenie usunięto poprawnie");
      setPopupModalType("info");
      setPopup(true);
    } else {
      toast.error("Coś poszło nie tak");
    }
  };

  const clickDeleteEvent = () => {
    setPopup(true);
  };

  const clickEditEvent = () => {
    setShowEditModal(true);
  };

  const handleEventSignUp = async () => {
    if (!isUserSignedUp) {
      await postGetResponse({ ...postGetResponseData });
      if (postGetResponseSuccess) {
        setIsUserSignedUp(true);
      }
    } else {
      await deleteContact({ id: getResponseContactsData[0]?.contactId });
      if (deleteContactSuccess) {
        setIsUserSignedUp(false);
      }
    }
  };

  const showPoll =
    Boolean(medicalVisitPollId) && isPrivate && isEnded && isPollSended;

  useEffect(() => {
    setIsUserSignedUp(getResponseContactsData?.length > 0);
  }, [getResponseContactsData]);
  useEffect(() => {
    (async () => {
      if (eventData?.data?.poll) {
        setIsPollSended(true);
      }
      if (eventData?.data) {
        const result = await getSimillarEvents({
          type: [eventData?.data?.type],
          dateFrom: new Date().toString(),
        });
        if ("data" in result) {
          setSimillarEvents(
            result?.data?.events?.filter(
              (event: EventData) => event.id !== eventData?.data?.id
            )
          );
        }
      }
    })();
    //eslint-disable-next-line
  }, [eventData?.data]);

  if (eventData) {
    if (
      eventData?.data?.type === "medical" &&
      eventData?.data?.user?.id !== authUser?.id
    ) {
      return (
        <PageContainer>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h1>To wydarzenie jest prywatne</h1>
            <Button
              variant="outline-primary"
              className="btn-alivia w-auto mt-5"
              onClick={() => navigate(`/${NAV_PATH.EVENTS}`)}
            >
              Powrót do kalendarza
            </Button>
          </div>
        </PageContainer>
      );
    }
    if (eventData?.statusCode === 404) {
      return (
        <PageContainer>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h1>To wydarzenie zostało usunięte</h1>
            <Button
              variant="outline-primary"
              className="btn-alivia w-auto mt-5"
              onClick={() => navigate(`/${NAV_PATH.EVENTS}`)}
            >
              Powrót do kalendarza
            </Button>
          </div>
        </PageContainer>
      );
    }
    if (isMobile) {
      return (
        <PageContainer className={`pb-0 px-0`}>
          <div className="px-3">
            <EventHeader
              eventData={eventData?.data}
              isPrivate={isPrivate}
              deleteEvent={clickDeleteEvent}
              editEvent={clickEditEvent}
              isAdmin={isAdmin}
            />
            <div className="col-12 m-0">
              {showPoll && (
                <div className={`${styles.pollMarginRemoval} my-5`}>
                  <DynamicPatientPoll
                    pollId={medicalVisitPollId}
                    setIsPollSend={handlePollSended}
                    doctorIdProp={eventData?.data?.doctor?.id}
                    isEvent={true}
                  />
                </div>
              )}
              {!isPrivate && (
                <div className="position-relative mb-5">
                  <Image
                    className="object-fit-cover"
                    width={"100%"}
                    height={"auto"}
                    src={
                      eventData?.data?.cover?.accessUrl
                        ? eventData?.data?.cover?.accessUrl
                        : DefaultCover
                    }
                  />
                  {/* Ukrycie przycisku powiadomien  */}
                  {/* <div
                    className="position-absolute"
                    style={{ top: "24px", left: "24px" }}
                  >
                    <Button
                      variant={!showNotifications ? "light" : "dark"}
                      className="align-items-center justify-content-center d-flex"
                      onClick={() => setShowNotifications(!showNotifications)}
                    >
                      <Bell size={16} />
                      <span className="fw-500 fs-6 ms-2">
                        {!showNotifications ? "Włącz" : "Wyłącz"} powiadomienia
                      </span>
                    </Button>
                  </div> */}
                </div>
              )}
              <EventInfo
                eventData={eventData?.data}
                isPrivate={isPrivate}
                isGetResponseFetching={isGetResponseFetching}
                isMobile={isMobile}
                isUserSignedUp={isUserSignedUp}
                signUpHandler={handleEventSignUp}
              />
              <Container className="containerWithShadow me-2 p-4 mt-5 mb-5">
                <h3 className="mb-4 fs-5 fw-600 mb-5 text-dark-blue">
                  Opis wydarzenia
                </h3>
                <p
                  className="m-0 text-dark-blue"
                  dangerouslySetInnerHTML={{
                    __html: eventData?.data?.description,
                  }}
                ></p>
              </Container>
              {!isPrivate && simillarEvents && simillarEvents.length !== 0 && (
                <Container className="containerWithShadow me-2 p-4 mt-5 mb-5">
                  <h3 className="my-1 fw-600 text-dark-blue fs-5 mb-5">
                    Podobne wydarzenia
                  </h3>
                  {
                    simillarEvents?.map((event: EventData) => {
                      const adressObject: EventAdress =
                        event?.address?.length > 0 && event?.address[0] === "{"
                          ? JSON.parse(event?.address)
                          : undefined;
                      return (
                        <CalendarEventCard
                          id={event?.id}
                          type={getEventTypeName(
                            event?.type,
                            eventTypesData?.eventCategories
                          )}
                          title={event?.name}
                          location={
                            event?.city
                              ? [
                                  event?.province ? event?.province?.name : "",
                                  event?.city ? event?.city?.name : "",
                                  adressObject
                                    ? `${
                                        (adressObject?.street
                                          ? adressObject?.street + " "
                                          : "") +
                                        (adressObject?.apartmentNumber
                                          ? adressObject?.houseNumber +
                                            " / " +
                                            adressObject?.apartmentNumber
                                          : adressObject?.houseNumber)
                                      }`
                                    : eventData?.address,
                                ]
                              : undefined
                          }
                          date={formatEventDateForRange(event)}
                        />
                      );
                    })[0]
                  }
                </Container>
              )}
            </div>
          </div>
          <PopupModal
            show={popup}
            setShow={setPopup}
            text={popupModalText}
            type={popupModalType}
            confirmAction={handleDeleteEvent}
          />
          <EditEventModal
            show={showEditModal}
            setShow={setShowEditModal}
            editedEventData={eventData?.data}
          />
        </PageContainer>
      );
    } else {
      return (
        <PageContainer className={styles.eventPagePadding}>
          <EventHeader
            eventData={eventData?.data}
            isPrivate={isPrivate}
            deleteEvent={clickDeleteEvent}
            editEvent={clickEditEvent}
            isAdmin={isAdmin}
          />
          <div className="col-12 row m-0">
            <div className="col-xl-8 col-12 mb-5 mb-xl-0 ps-xl-0">
              {showPoll && (
                <div className={`${styles.pollMarginRemoval} mb-5`}>
                  <DynamicPatientPoll
                    pollId={medicalVisitPollId}
                    setIsPollSend={handlePollSended}
                    doctorIdProp={eventData?.data?.doctor?.id}
                    isEvent={true}
                  />
                </div>
              )}
              <Container className="containerWithShadow ms-0 p-0">
                {!isPrivate && (
                  <div className="position-relative">
                    <Image
                      className="object-fit-cover"
                      width={"100%"}
                      height={400}
                      src={
                        eventData?.data?.cover?.accessUrl
                          ? eventData?.data?.cover?.accessUrl
                          : DefaultCover
                      }
                    />
                    {/* Ukrycie przycisku powiadomien  */}
                    {/* <div
                      className="position-absolute"
                      style={{ top: "24px", left: "24px" }}
                    >
                      <Button
                        variant={!showNotifications ? "light" : "dark"}
                        className="align-items-center justify-content-center d-flex"
                        onClick={() => setShowNotifications(!showNotifications)}
                      >
                        <Bell size={16} />
                        <span className="fw-500 fs-6 ms-2">
                          {!showNotifications ? "Włącz" : "Wyłącz"}{" "}
                          powiadomienia
                        </span>
                      </Button>
                    </div> */}
                  </div>
                )}
                <div className="p-4">
                  <h3 className="mb-4 fs-5 fw-600 mb-5 text-dark-blue">
                    Opis wydarzenia
                  </h3>
                  <p
                    className="m-0 text-dark-blue"
                    dangerouslySetInnerHTML={{
                      __html: eventData?.data?.description,
                    }}
                  ></p>
                  {!isPrivate && (
                    <div className="mt-5">
                      <Button
                        variant="outline-primary"
                        className="btn-alivia w-auto"
                        onClick={openHelpMessage}
                      >
                        Napisz wiadomość
                      </Button>
                    </div>
                  )}
                </div>
              </Container>
            </div>
            <div className="col-xl-4 col-12">
              <EventInfo
                eventData={eventData?.data}
                isPrivate={isPrivate}
                isGetResponseFetching={isGetResponseFetching}
                isMobile={isMobile}
                isUserSignedUp={isUserSignedUp}
                signUpHandler={handleEventSignUp}
              />
              {!isPrivate && simillarEvents && simillarEvents.length !== 0 && (
                <Container className="containerWithShadow me-2 p-4 mt-5">
                  <h3 className="my-1 fw-600 text-dark-blue fs-5 mb-5">
                    Podobne wydarzenia
                  </h3>
                  {
                    simillarEvents?.map((event: EventData) => {
                      const adressObject: EventAdress =
                        event?.address?.length > 0 && event?.address[0] === "{"
                          ? JSON.parse(event?.address)
                          : undefined;
                      return (
                        <CalendarEventCard
                          id={event?.id}
                          type={getEventTypeName(
                            event?.type,
                            eventTypesData?.eventCategories
                          )}
                          title={event?.name}
                          location={
                            event?.city
                              ? [
                                  event?.province ? event?.province?.name : "",
                                  event?.city ? event?.city?.name : "",
                                  adressObject
                                    ? `${
                                        (adressObject?.street
                                          ? adressObject?.street + " "
                                          : "") +
                                        (adressObject?.apartmentNumber
                                          ? adressObject?.houseNumber +
                                            " / " +
                                            adressObject?.apartmentNumber
                                          : adressObject?.houseNumber)
                                      }`
                                    : eventData?.address,
                                ]
                              : undefined
                          }
                          date={formatEventDateForRange(event)}
                        />
                      );
                    })[0]
                  }
                </Container>
              )}
            </div>
          </div>
          <PopupModal
            show={popup}
            setShow={setPopup}
            text={popupModalText}
            type={popupModalType}
            confirmAction={handleDeleteEvent}
          />
          <EditEventModal
            show={showEditModal}
            setShow={setShowEditModal}
            editedEventData={eventData?.data}
          />
        </PageContainer>
      );
    }
  } else {
    return (
      <PageContainer className="d-flex justify-content-center align-items-center">
        <div
          className={`col-12 d-flex justify-content-center ${styles.loading}`}
        ></div>
      </PageContainer>
    );
  }
};