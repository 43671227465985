export interface DateRange {
    startingDate: Date;
    endingDate: Date;
  }
  
  export const getDateRange = (date: DateRange): Date[] | null => {
    if (date && !!Object.keys(date)) {
      const startingDate = new Date(date.startingDate);
      const endingDate = new Date(date.endingDate);
  
      const dates = [];
  
      for (let i = startingDate.getDate(); i <= endingDate.getDate(); i++) {
        dates.push(new Date(startingDate.setDate(i)));
      }
  
      return dates;
    }
  
    return null;
  };