import { CardCodes } from "redux/services/cardCodesApi";
import { Table } from "react-bootstrap";
import { HEADER_TABLE_NAMES } from "./CardCodesTable.consts";
import CardCodesTableRowWithCollapse from "../CardCodesTableRowWithCollapse/CardCodesTableRowWithCollapse";

interface CardCodesProps {
  data?: CardCodes[];
}

const CardCodesTable = ({ data }: CardCodesProps) => (
  <Table>
    <thead>
      <tr>
        {HEADER_TABLE_NAMES.map((name) => (
          <th key={name} className="pb-4">
            <span>{name}</span>
          </th>
        ))}
      </tr>
    </thead>

    <tbody className="tableBody">
      {data ? (
        data?.map((cardCode: CardCodes) => (
          <CardCodesTableRowWithCollapse
            cardCode={cardCode}
            key={cardCode?.id}
          />
        ))
      ) : (
        <tr>
          <td colSpan={4} className="text-center">
            Nie znaleziono żadnych filtrów.
          </td>
        </tr>
      )}
    </tbody>
  </Table>
);

export default CardCodesTable;
