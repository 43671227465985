import { replacePolishLetters } from "Helpers/replacePolishLetters";
import { Button, Collapse } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { enums } from "../../../../Helpers/enums";
import { handleNewField } from "../../../../Helpers/helpers";
import {
  useGetPagesQuery,
  usePatchPageUpdateMutation,
} from "../../../../redux/services/pagesApi";
import RadioInput from "../../../atoms/RadioInput/RadioInput";
import TextInput from "../../../atoms/TextInput/TextInput";
import CollapseWithIcon from "../../../organisms/CollapseWithIcon/CollapseWithIcon";
import MultiSelect from "../../MultiSelect/MultiSelect";
import { useTranslation } from "react-i18next";
import { useGetUsersQuery } from "redux/services/usersApi";
import Select, { SingleValue } from "react-select";
import {
  customStyles,
  DropdownIndicator,
} from "styles/reactSelectCustomStyles";
import { User } from "redux/Slice/user-slice";

const QuickEditPage = ({ data, open, setOpen }: any) => {
  const { data: usersData } = useGetUsersQuery({
    limit: "0",
    deleted: "0",
    roleId: "1",
  });
  const authorsArray: ReactSelectOption[] = usersData?.data?.map(
    (user: User) => ({
      value: user?.id?.toString(),
      label: user?.name,
    })
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<PageDataPost>({
    defaultValues: {
      ...data,
      parentIds: data?.parent?.map((item: PageDataTypes) => item.id),
      title: data.title,
      url: data.url,
      metaTitle: data.metaTitle,
      language:
        typeof data?.language === "number"
          ? data?.language
          : data?.language?.id,
      country:
        typeof data?.country === "number" ? data?.country : data?.country?.id,
      metaDescription: data.metaDescription,
      content: data.content,
      categoryIds: data.category?.map((item: PageDataTypes) => item.id),
      type: data.type.toString(),
      sortOrder: data?.sortOrder,
      slug: data.slug,
      lead: data.lead,
      password: data.password,
      updatedBy:
        typeof data?.updatedBy === "number"
          ? data?.updatedBy
          : data?.updatedBy?.id,
      createdBy:
        typeof data?.createdBy === "number"
          ? data?.createdBy
          : data?.createdBy?.id,
      substanceIds: data.activeSubstance?.map((item: PageDataTypes) => item.id),
    },
  });

  const { data: pagesData } = useGetPagesQuery({ limit: "0" }, { skip: !open });

  const { t } = useTranslation();

  const [patchPageUpdate] = usePatchPageUpdateMutation();

  const pageStatus = [
    { name: "draft", value: "0" },

    { name: "Publiczne", value: "1" },
    {
      name: "Prywatne",
      value: "3",
    },
    {
      name: "Zabezpieczono hasłem",
      value: "2",
    },
  ];

  return (
    <Collapse in={open}>
      <>
        <span className="fw-600 fs-22 text-dark-blue modal-title h4 mb-3">
          Szybka edycja
        </span>
        <form
          className="mt-3"
          onSubmit={handleSubmit(async (submitData) => {
            const postData = {
              ...submitData,
              parentIds: submitData.parentIds,
              title: submitData.title,
              url: submitData.url,
              metaTitle: submitData.metaTitle,
              metaDescription: submitData.metaDescription,
              content: submitData.content,
              slug: submitData.slug,
              lead: submitData.lead,
              password: submitData.type === 2 ? submitData.password : "",
              categoryIds: submitData.categoryIds,
              sortOrder: Number(submitData.sortOrder),
              type: Number(submitData.type),
              updatedBy:
                typeof submitData?.createdBy === "number"
                  ? submitData?.createdBy
                  : parseInt(submitData?.createdBy ?? ""),
              substanceIds: submitData.substanceIds,
            };

            const pageUpdate = await patchPageUpdate({
              id: data.id,
              data: postData,
            });
            setOpen(false);

            if ("data" in pageUpdate) {
              toast.success(t("toast_quickEditPage_success"));
            } else if ("error" in pageUpdate) {
              toast.error(t("toast_quickEditPage_error"));
            }
          })}
        >
          <div className="d-flex w-100 gap-5">
            <div className="w-100 d-flex flex-column gap-2">
              <TextInput
                label={"Tytuł"}
                register={register("title", {
                  onChange: (e) => {
                    setValue("url", replacePolishLetters(e.target.value));
                  },
                })}
                inputWrapperClassname="w-100"
                inputClassName="text-dark-blue"
                labelClassName="text-dark-blue fs-16"
                className="fs-16 fw-400 align-items-center"
              />
              <TextInput
                label={"Uproszczona"}
                register={register("url", {
                  onChange: (e) => {
                    setValue("url", replacePolishLetters(e.target.value));
                  },
                })}
                className="align-items-center"
                inputWrapperClassname="w-100"
                labelClassName="text-dark-blue fs-16"
              />
              <label className="form-check-label col-auto d-flex align-items-center">
                <div className="d-flex flex-row">
                  <span
                    className="me-2 text-dark-blue fs-16"
                    style={{ width: 120 }}
                  >
                    Autor
                  </span>
                </div>
                <Controller
                  name={"createdBy"}
                  control={control}
                  rules={{
                    onChange: () => clearErrors("createdBy"),
                  }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      ref={ref}
                      value={authorsArray?.find(
                        (selectOption: ReactSelectOption) =>
                          selectOption.value === value?.toString()
                      )}
                      classNamePrefix="select"
                      className="w-100 fs-16"
                      placeholder="Wybierz z listy"
                      isClearable={false}
                      isSearchable={true}
                      name="createdBy"
                      options={authorsArray}
                      onChange={(option: SingleValue<ReactSelectOption>) =>
                        onChange(option?.value)
                      }
                      components={{ DropdownIndicator }}
                      styles={customStyles(!!errors?.createdBy?.message)}
                    />
                  )}
                />
                {errors?.createdBy && (
                  <div className={"text-danger fs-12 fw-400 mt-1"}>
                    {errors?.createdBy?.message}
                  </div>
                )}
              </label>
            </div>
            <div className="w-100 d-flex flex-column  gap-2">
              <CollapseWithIcon
                title="Element nadrzędny"
                label="Wybierz element nadrzędny"
                labelClassName="text-dark-blue fs-16"
                CollapseComponent={() => (
                  <>
                    <span className="text-muted">
                      Kontroluj sposób wyświetlania wpisu.
                    </span>
                    <MultiSelect
                      menuIsOpen
                      data={pagesData?.data}
                      setValue={setValue}
                      name="parentIds"
                      value={handleNewField(data.parent)}
                      relativeoptions
                    />
                  </>
                )}
              />
              <CollapseWithIcon
                title="Widoczność"
                labelClassName="text-dark-blue fs-16"
                label={
                  enums.pagesStatusType[
                    Number(watch("type")) as keyof typeof enums.pagesStatusType
                  ]
                }
                CollapseComponent={() => (
                  <>
                    <span className="text-muted">
                      Kontroluj sposób wyświetlania wpisu.
                    </span>
                    {pageStatus.map((status) => {
                      return (
                        <RadioInput
                          key={status.value}
                          register={register("type")}
                          label={status.name}
                          value={status.value}
                          checked={watch("type") === parseInt(status.value)}
                        />
                      );
                    })}

                    {watch("type") === 2 && (
                      <TextInput
                        style={{ width: "1500px" }}
                        className="mb-4 align-items-center"
                        labelClassName="text-dark-blue fs-16"
                        placeholder="Wprowadź bezpieczne hasło"
                        register={register("password", {
                          required: watch("type") === 2,
                        })}
                      />
                    )}
                  </>
                )}
              />

              <TextInput
                labelClassName="text-dark-blue fs-16"
                label={"Kolejność"}
                className="align-items-center"
                register={register("sortOrder")}
                inputWrapperClassname="w-100"
              />
            </div>
          </div>
          <div className="d-flex gap-2 mt-4">
            <Button type="submit" value="Submit">
              Aktualizuj
            </Button>
            <Button
              className="btn btn-outline-light"
              onClick={() => setOpen(false)}
            >
              Anuluj
            </Button>
          </div>
        </form>
      </>
    </Collapse>
  );
};

export default QuickEditPage;
