import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { LogoCreator } from "../../molecules/LogoCreator/LogoCreator";
import { usePostAttachmentsMutation } from "../../../redux/services/attachmentsApi";
import { toast } from "react-toastify";
import styles from "./AvatarModal.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  handleClose: () => void;
  handlePatch: (id: string) => void;
  types: "company-logo" | "user-avatar";
  headerTitle: string;
  isReadOnly?: boolean;
  isActiveToast?: boolean;
}

export const AvatarModal = ({
  show,
  handleClose,
  handlePatch,
  types,
  headerTitle,
  isActiveToast = true,
}: Props) => {
  const cropRef = useRef<any | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const { t } = useTranslation();

  const [
    postAttachments,
    { isSuccess: isSuccessPicture, error: errorPicture, data: AttachmentsData },
  ] = usePostAttachmentsMutation();

  useEffect(() => {
    if (isSuccessPicture) {
      handlePatch(AttachmentsData.data.id);
      if (isActiveToast) {
        types === "user-avatar"
          ? toast.success(t("toast_avatarModal_update_avatar_success"))
          : toast.success(t("toast_avatarModal_update_companyImage_success"));
      }
      if (errorPicture) {
        types === "user-avatar"
          ? toast.error(t("toast_avatarModal_update_avatar_error"))
          : toast.error(t("toast_avatarModal_update_companyImage_error"));
      }
    }
    handleClose();
    // eslint-disable-next-line
  }, [errorPicture, isSuccessPicture]);

  const handleSave = async () => {
    if (cropRef) {
      const image = new Image();
      image.src = cropRef.current.getImage().toDataURL();

      image.onload = async () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const newWidth = 160;
        const newHeight = 160;

        canvas.width = newWidth;
        canvas.height = newHeight;

        if (context) {
          context.fillStyle = "#ffffff";
          context.drawImage(image, 0, 0, newWidth, newHeight);
        }

        const modifiedData = canvas.toDataURL("image/png");

        const byteString = atob(modifiedData.split(",")[1]);
        const mimeString = modifiedData
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });
        const fileBlob = new File([blob], file?.name || "error.png", {
          type: "image/png",
        });

        await postAttachments({ data: fileBlob, attachmentType: types });
      };
    }
  };

  const handlePicture = async (value: File) => {
    setFile(value);
  };

  return (
    <Modal
      className={`p-0 ${styles.modalBody}`}
      show={show}
      onHide={handleClose}
      centered
      fullscreen={"sm-down"}
    >
      <Modal.Header closeButton className={"border-bottom-0 "}>
        <Col>
          <Modal.Title className="fw-bold text-dark-blue">
            <span>{headerTitle}</span>
          </Modal.Title>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <div className={"d-flex justify-content-center"}>
          <LogoCreator cropRef={cropRef} handlePicture={handlePicture} />
        </div>
      </Modal.Body>
      <Modal.Footer className={"border-top-0 "}>
        <Container className="d-flex p-0">
          <Button
            className="btn-outline-dark-blue me-2"
            type="submit"
            value="Submit"
            onClick={handleClose}
          >
            ANULUJ
          </Button>
          <Button
            className={`flex-fill ${file ? "btn-primary" : "btn-disabled"}`}
            disabled={file === null}
            type="submit"
            value="Submit"
            onClick={handleSave}
          >
            AKTUALIZUJ
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};
