interface ShowOlderProps {
  count: number;
  dataDiff: number;
  limit: number;
  setLimit: (value: number | ((prevState: number) => number)) => void;
}

export const ShowOlder = ({
  count,
  dataDiff,
  setLimit,
  limit,
}: ShowOlderProps) => {
  const handleUpdateLimit = async () => {
    setLimit(limit + dataDiff);
  };
  return (
    <>
      {limit < count && (
        <div className={`col-12 d-flex justify-content-center text-dark-blue`}>
          <span
            className="cursor-pointer text-underline"
            onClick={handleUpdateLimit}
          >
            Pokaż starsze
          </span>
        </div>
      )}
    </>
  );
};
