import FileDropzone from "components/atoms/FileDropzone/FileDropzone";
import SearchForm from "components/molecules/SearchForm/SearchForm";
import CustomPagination from "components/organisms/CustomPagination/CustomPagination";
import MediaGallery from "components/organisms/MediaGallery/MediaGallery";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useGetAttachmentsQuery } from "redux/services/attachmentsApi";

const MediaTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1
  );

  const [dropzoneIsActive, setDropzoneIsActive] = useState(false);
  const { data: attachment } = useGetAttachmentsQuery({
    offset: currPage ? ((currPage - 1) * 18).toString() : "0",
    limit: "18",
    nameLike: searchParams.get("nameLike"),
  });

  const handlePageChange = (e: number) => {
    searchParams.set("page", e.toString());

    setSearchParams(searchParams);
    setCurrPage(e);
  };

  return (
    <div className="mt-2 mx-4 w-100">
      <div className=" d-flex my-3">
        <h1 className="pe-3 fs-2 ">Biblioteka mediów</h1>
        <Button
          className="btn-outline-blue"
          onClick={() => setDropzoneIsActive((prev) => !prev)}
        >
          Dodaj
        </Button>
      </div>

      {dropzoneIsActive && (
        <FileDropzone setDropzoneIsActive={setDropzoneIsActive}  attachmentType={"pages"}/>
      )}
      <div className="d-flex justify-content-end mb-4">
        <SearchForm setCurrPage={setCurrPage} />
      </div>
      <MediaGallery data={attachment?.data} />
      <CustomPagination
        totalCount={(attachment && attachment.meta.totalResults) || 0}
        pageSize={18}
        currentPage={currPage}
        onPageChange={(e: number) => handlePageChange(e)}
      />
    </div>
  );
};

export default MediaTemplate;
