import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "../Slice/user-slice";
import { systemSettingsSlice } from "../Slice/system-settings-slice";
import messagesSlice from "redux/Slice/messages-slice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { webSocketMiddleware } from "Helpers/webSocketMiddleware";
import websocketSlice from "redux/Slice/websocket-slice";
import { tokenSlice } from "redux/Slice/token-slice";
import { apiSlice } from "../services/apiSlice";
import selectSlice from "redux/Slice/select-slice";
import companyModalSlice from "redux/Slice/company-modal-slice";
import eventPollSlice from "redux/Slice/event-poll-slice";
import targetTreatmentWidgetSlice from "redux/Slice/targeted-treatment-widget-slice";

const persistConfig = {
  key: "user",
  storage,
};

const persistConfigSystemSettings = {
  key: "system-settings",
  storage,
};

const persistConfigMessages = {
  key: "messages",
  storage,
};

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);
const persistedReducerSystemSettings = persistReducer(
  persistConfigSystemSettings,
  systemSettingsSlice.reducer
);
const persistedReducerMessages = persistReducer(
  persistConfigMessages,
  messagesSlice
);
const persistConfigCompanyModal = {
  key: "company-modal",
  storage,
};
const persistedReducerCompanyModal = persistReducer(
  persistConfigCompanyModal,
  companyModalSlice.reducer
);

const persistConfigEventPoll = {
  key: "event-poll",
  storage,
};

const persistedReducerEventPoll = persistReducer(
  persistConfigEventPoll,
  eventPollSlice.reducer
);

export const store = configureStore({
  reducer: {
    user: persistedReducer,
    systemSettings: persistedReducerSystemSettings,
    messages: persistedReducerMessages,
    [apiSlice.reducerPath]: apiSlice.reducer,
    webSocket: websocketSlice,
    token: tokenSlice.reducer,
    select: selectSlice,
    companyModal: persistedReducerCompanyModal,
    eventPoll: persistedReducerEventPoll,
    targetTreatmentWidget: targetTreatmentWidgetSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, webSocketMiddleware),
});

export const Persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
