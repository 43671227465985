import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { usePostCardCodesMutation } from "redux/services/cardCodesApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface CardCodesFormData {
  number: string;
}

const CreateCardCodesManually = () => {
  const [postCardCodes] = usePostCardCodesMutation();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<CardCodesFormData>({
    defaultValues: {
      number: "",
    },
  });

  const labelStyles = "mb-1 fs-14 lh-base";

  const onSubmit = async (formData: CardCodesFormData) => {
    try {
      await postCardCodes({ data: formData });
      toast.success(t("toast_createCardCodes_success"));
    } catch (error) {
      toast.error(t("toast_createCardCodes_error"));
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex gap-4 align-items-end"
    >
      <Form.Group>
        <Form.Label className={labelStyles} htmlFor="createCardCodesManually">
          Wpisz kod do stworzenia
        </Form.Label>
        <Form.Control
          type="text"
          id="createCardCodesManually"
          {...register("number")}
        />
      </Form.Group>
      <Button
        className="btn-outline-blue mt-2 h-50"
        type="submit"
        value="Submit"
      >
        Stwórz kody
      </Button>
    </Form>
  );
};

export default CreateCardCodesManually;
