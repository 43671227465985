import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePostCommentsMutation } from "redux/services/feedApi";
import styles from "./CommentInput.module.scss";
import { toast } from "react-toastify";
import userAvatarImage from "../../../assets/images/userAvatarImage.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import { useTranslation } from "react-i18next";
import { usePostNotificationsMutation } from "../../../redux/services/notificationApi";
import { domain } from "../../organisms/Landing/Landing";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const MAX_COMMENT_LENGTH = 700;
export const CommentInput = ({
  postId,
  placeholder,
  className,
  reply,
  rerenderer,
  commentId,
  isInputVisible,
  author,
}: CommentInputProps) => {
  const [isInputFocused, setInputFocused] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [addComment] = usePostCommentsMutation();
  const { authUser } = useSelector((state: RootState) => state.user);

  const [notification] = usePostNotificationsMutation();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm<{
    commentInput: string;
  }>({
    defaultValues: {
      commentInput: "",
    },
  });

  const onSubmit: SubmitHandler<{ commentInput: string }> = async (data) => {
    resetField("commentInput");
    isInputVisible && isInputVisible(false);
    const payload = {
      data: {
        feedPostId: postId,
        parentId: reply ? commentId : 0,
        isMain: 0,
        status: "ACCEPTED",
        userId: authUser.id,
        content: data.commentInput,
      },
    };
    const addCommentAction = await addComment(payload);
    rerenderer && (await rerenderer());

    if ("data" in addCommentAction) {
      if (!addCommentAction?.data?.statusCode) {
        toast.success(
          reply
            ? t("toast_commentInput_reply_success")
            : t("toast_commentInput_comment_success")
        );
        await notification({
          data: {
            user: author?.authId,
            title: `${
              !reply
                ? t("notification_CommentInput_comments_title")
                : t("notification_CommentInput_relay_title")
            }`,
            viewSender: 1,
            type: 3,
            content: data?.commentInput,
            url: `${domain}/${NAV_PATH.FEED}/${postId ?? ""}/${
              NAV_PATH.COMMENT
            }/${addCommentAction?.data?.data?.id}`,
          },
        });
      } else {
        switch (addCommentAction?.data?.statusCode) {
          case 500:
            toast.error(t("toast_addPostModal_post_error_500"));
            break;
          case 429:
            toast.error(
              reply
                ? t("toast_commentInput_reply_error_429")
                : t("toast_commentInput_comment_error_429")
            );
            break;
          case 400:
            toast.error(t("toast_addPostModal_post_error_400"));
            break;
          default:
            toast.error(
              reply
                ? t("toast_commentInput_reply_error")
                : t("toast_commentInput_comment_error")
            );
            break;
        }
      }
    }
  };

  const handleCancelAction = () => {
    setPopup(false);
    resetField("commentInput");
    setInputFocused(false);
  };

  const handleCancelActionEvent = () => {
    getValues("commentInput") && getValues("commentInput").length > 0
      ? setPopup(true)
      : setInputFocused(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`d-flex flex-wrap px-0 mx-0 ${className}`}
      >
        <div className={`p-0 m-0 ${styles.commentInputAvatar} col-2 col-sm-1`}>
          <img
            src={authUser?.avatar?.accessUrl ?? userAvatarImage}
            alt="current-user-icon"
          />
        </div>
        <div
          className={`${styles.commentInput} mx-0 px-0 d-flex justify-content-center flex-wrap align-items-center col-10 col-sm-11`}
        >
          <textarea
            className="form-control col-12 mx-0 border-gray mx-0"
            placeholder={placeholder}
            onFocus={() => setInputFocused(true)}
            {...register("commentInput", {
              pattern: {
                value: /^(?![\s\n]).*/,
                message:
                  "Tekst nie może również zaczynać się od spacji lub nowej linii.",
              },
              maxLength: {
                value: MAX_COMMENT_LENGTH,
                message: `Tekst przekracza maksymalną dozwoloną liczbę znaków (${MAX_COMMENT_LENGTH})`,
              },
            })}
          />
          {errors?.commentInput && (
            <p className={`col-12 p-0 m-0 fs-12 ${styles.controlError}`}>
              {errors?.commentInput?.message}
            </p>
          )}
        </div>
        {isInputFocused && (
          <Row className="mt-2 d-flex justify-content-end px-0 mx-0 w-100">
            <Button
              className="col-auto px-2 mx-2 py-1 fs-14 fw-600 btn-outline-dark-blue "
              variant="light"
              onClick={handleCancelActionEvent}
            >
              ANULUJ
            </Button>
            <Button className="col-auto px-2 py-1 fs-14 fw-600" type="submit">
              SKOMENTUJ
            </Button>
          </Row>
        )}
      </form>
      <PopupModal
        show={popup}
        setShow={setPopup}
        text={`Czy na pewno chcesz anulować i zarazem usunąć ${
          reply ? "Twoją odpowiedź" : "Twój komentarz"
        }?`}
        type="confirm"
        confirmAction={handleCancelAction}
      />
    </>
  );
};
