import AsyncSelectLoading from "components/molecules/AsyncSelect/AsyncSelectLoading";
import { useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useGetPagesQuery } from "../../../redux/services/pagesApi";
import {
  useGetPagesCategoryQuery,
  useLazyGetPagesCategoryQuery,
} from "../../../redux/services/pagesCategoryApi";
import CustomPagination from "../../organisms/CustomPagination/CustomPagination";
import PagesTable from "../../organisms/Tables/PagesTable/PagesTable";
import { Form } from "react-bootstrap";
import { NAV_PATH } from "components/organisms/Navigation/navigationData";

const PagesTemplate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [searchMode, setSearchMode] = useState<boolean>(true);

  const { data: pagesData, refetch } = useGetPagesQuery({
    offset: currPage ? ((currPage - 1) * 5).toString() : "0",
    limit: "5",
    titleLike: searchParams.get("titleLike"),
    categoryIds: searchParams.get("category"),
    sortBy: searchParams.get("sortBy"),
    parentNotAssigned: searchMode ? 1 : 0,
  });

  const { data: pagesCategoryData } = useGetPagesCategoryQuery({
    type: "page",
  });

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchPages: "",
      category: "",
    },
  });

  const handlePageChange = (e: number) => {
    searchParams.set("page", e.toString());
    setSearchParams(searchParams);
    setCurrPage(e);
  };

  return (
    <div className="mt-2 mx-4">
      <div className="d-flex my-3">
        <h1 className="pe-3 fs-2">Strony</h1>
        <Link to={`/${NAV_PATH.ADMIN}/${NAV_PATH.PAGES}/new`}>
          <Button
            className="d-flex align-items-center"
            variant="outline-primary"
          >
            Dodaj nową
          </Button>
        </Link>
      </div>
      <div className="row justify-content-between g-3">
        <form
          className="col col-lg-6 d-flex gap-3"
          onSubmit={handleSubmit((data) => {
            searchParams.set("category", data.category);
            setSearchParams(searchParams);
          })}
        >
          <AsyncSelectLoading
            data={pagesCategoryData}
            placeholder="Wszystkie kategorie"
            setValue={setValue}
            name="category"
            width={250}
            asyncQuery={useLazyGetPagesCategoryQuery}
          />

          <div className="col-auto">
            <Button variant="outline-primary" type="submit" value="Submit">
              Przefiltruj
            </Button>
          </div>
        </form>
        <form
          className="col col-lg-6 d-flex gap-3 flex-end justify-content-lg-end"
          onSubmit={handleSubmit((data) => {
            if (data.searchPages) {
              searchParams.set("page", "1");
              setCurrPage(1);
            }
            searchParams.set("titleLike", data.searchPages);
            searchParams.set("parentNotAssigned", searchMode ? "1" : "0");
            setSearchParams(searchParams);
            refetch();
          })}
        >
          <div className="col-auto">
            <input
              type="text"
              className="form-control"
              {...register("searchPages")}
              style={{ minWidth: 250, width: "100%" }}
            />
            <div className="d-flex align-items-center">
              {" "}
              <span className="me-2">Wyszukuj w całej bazie</span>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={searchMode}
                onChange={(e) => setSearchMode(e.target.checked)}
              />
              <span className="me-2">Wyszukuj w głównych treściach</span>
            </div>
          </div>
          <div className="col-auto">
            <Button variant="outline-primary" type="submit" value="Submit">
              Szukaj
            </Button>
          </div>
        </form>
      </div>

      <PagesTable data={pagesData?.data} />
      <CustomPagination
        totalCount={(!!pagesData && pagesData.meta.totalResults) || 0}
        pageSize={5}
        currentPage={currPage}
        onPageChange={(e: number) => handlePageChange(e)}
      />
    </div>
  );
};

export default PagesTemplate;
