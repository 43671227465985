import { doctor } from "../../endpoints/endpoints";
import { apiSlice } from "./apiSlice";

export const ONKO_URL =
process.env?.REACT_APP_EXTERNAL_LINK_ONKOMAPA ??
(process.env?.REACT_APP_MODE === "dev"
  ? "https://dev.onkomapa.pl"
  : "https://onkomapa.pl");
export const ONKO_URL_DOCTOR = `${ONKO_URL}/szukaj/lekarz/`;

interface DoctorData {
  firstName: string;
  lastName: string;
  description?: string;
  phone?: string;
  email?: string;
  specializationIds?: number[];
  avatarId?: string;
  degreeId?: number;
  userId?: number | null;
  status?: StatusChangeType;
}

interface PostDoctorPayload {
  data: DoctorData;
}

interface GetDoctorsSearchParams {
  orderBy?: string;
  limit?: string;
  offset?: string;
  sortBy?: string;
  nameLike?: string;
  specializationNameLike?: string;
  specializationIds?: string;
  rating?: string;
  provinceId?: string;
  status?: StatusChangeType;
  systemId?: string;
}

interface PatchDoctorPayload {
  id: number;
  data: Partial<DoctorData>;
}

interface AssignDoctorPayload {
  id: number;
}

interface PatchDoctorFacility {
  id: number;
  facilityId: string;
  data: {
    status: number;
  };
}

interface PostDoctorFacilityData {
  facilityId: number;
  hash?: string;
}

interface PostDoctorFacility {
  id: number;
  data: PostDoctorFacilityData;
}

interface GetDoctorFacilitiesPayload {
  id: number;
  status?: string;
}

interface DeleteDoctorFacilityData {
  id: number;
  facilityId: number;
}

interface DoctorReviewData {
  content?: string;
  name?: string;
  description?: string;
  rating?: number;
  added_by_user_id?: number;
  deleted: number;
  status: string;
  doctorId: number;
  systemId: number;
}

interface PostDoctorReviewPayload {
  data: DoctorReviewData;
}

interface GetDoctorReviewSearchParams {
  limit?: string;
  offset?: string;
  sortBy?: string;
  userId?: string;
  context?: string;
  status?: string;
}

interface PostDoctorAbuseData {
  reviewId: number;
  content: string;
}

export interface PostDoctorAbusePayload {
  data: PostDoctorAbuseData;
}

export interface PostDoctorReviewProps {
  parentId?: number;
  content?: string;
  rating?: number;
  name?: string;
  addedByUserId?: number | null;
  doctorId: number;
  systemId: number;
  deleted: number;
  status: StatusChangeType;
}

export interface PatchDoctorReviewProps {
  content?: string;
  rating?: number;
  addedByUserId?: number;
  deleted?: number;
  status?: StatusChangeType;
  name?: string;
  doctorId?: number;
  systemId?: number;
}

interface PatchDoctorReview {
  id: number | string;
  data: PatchDoctorReviewProps;
}

interface PatchDoctorAbuseData {
  content?: string;
  answer?: string;
  decision?: number;
}

export interface PatchDoctorAbusePayload {
  id: number;
  data: PatchDoctorAbuseData;
}

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: ["Doctors", "DoctorReviews"],
});

export type Context = "POSITIVE" | "NEGATIVE";

interface GetDoctorReviewsProps {
  doctorId: string;
  all?: string;
  limit?: string;
  offset?: string;
  userId?: string;
  context?: Context;
  status?: StatusChangeType;
  sortBy?: string;
}

export const doctorApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    postDoctor: builder.mutation({
      query: ({ data }: PostDoctorPayload) => ({
        url: `${doctor}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Doctors"],
    }),

    getDoctors: builder.query({
      query: ({
        orderBy,
        offset,
        limit,
        sortBy,
        nameLike,
        specializationNameLike,
        specializationIds,
        rating,
        provinceId,
        status,
        systemId,
      }: GetDoctorsSearchParams) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(nameLike && { nameLike }),
          ...(status && { status }),
          ...(specializationNameLike && { specializationNameLike }),
          ...(specializationIds && { specializationIds }),
          ...(rating && { rating }),
          ...(provinceId && { provinceId }),
          ...(systemId && { systemId }),
        });

        return {
          url: `${doctor}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Doctors"],
    }),

    getDoctor: builder.query({
      query: (id: number) => {
        return {
          url: `${doctor}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Doctors"],
    }),

    getCurrentDoctor: builder.query({
      query: () => {
        return {
          url: `${doctor}/me`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Doctors"],
    }),

    patchDoctor: builder.mutation({
      query: ({ id, data }: PatchDoctorPayload) => ({
        url: `${doctor}/${id}/update`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Doctors"],
    }),

    assignDoctor: builder.mutation({
      query: ({ id }: AssignDoctorPayload) => ({
        url: `${doctor}/${id}/assign`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Doctors"],
    }),

    unassignDoctor: builder.mutation({
      query: ({ id }: AssignDoctorPayload) => ({
        url: `${doctor}/${id}/unassign`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Doctors"],
    }),

    postDoctorFacility: builder.mutation({
      query: ({ id, data }: PostDoctorFacility) => ({
        url: `${doctor}/${id}/facility`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Doctors"],
    }),

    getDoctorFacilities: builder.query({
      query: ({ id, status }: GetDoctorFacilitiesPayload) => {
        const params = new URLSearchParams({
          ...(status && { status }),
        });

        return {
          url: `${doctor}/${id}/facility?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Doctors"],
    }),

    patchDoctorFacility: builder.mutation({
      query: ({ id, facilityId }: PatchDoctorFacility) => {
        const params = new URLSearchParams({
          ...(facilityId && { facilityId }),
        });

        return {
          url: `${doctor}/${id}/facility?${params.toString()}`,
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Doctors"],
    }),

    deleteDoctorFacility: builder.mutation({
      query: ({ id, facilityId }: DeleteDoctorFacilityData) => ({
        url: `${doctor}/${id}/facility/${facilityId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Doctors"],
    }),

    postDoctorReview: builder.mutation({
      query: ({ data }: PostDoctorReviewPayload) => ({
        url: `${doctor}/create-review`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["DoctorReviews"],
    }),

    getDoctorsReviews: builder.query({
      query: ({
        limit,
        offset,
        sortBy,
        userId,
        context,
        status,
      }: GetDoctorReviewSearchParams) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(userId && { userId }),
          ...(context && { context }),
          ...(status && { status }),
        });

        return {
          url: `${doctor}/reviews?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["DoctorReviews"],
    }),

    getDoctorReviews: builder.query({
      query: ({
        doctorId,
        limit,
        offset,
        userId,
        context,
        status,
        sortBy,
        all,
      }: GetDoctorReviewsProps) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(all && { all }),
          ...(status && { status }),
          ...(userId && { userId }),
          ...(context && { context }),
          ...(limit && { limit }),
          ...(offset && { offset }),
          ...(sortArray && { orderBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
        });

        return {
          url: `${doctor}/${doctorId}/reviews?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Doctors"],
    }),

    patchDoctorReview: builder.mutation({
      query: ({ id, data }: PatchDoctorReview) => ({
        url: `${doctor}/${id}/update-review`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["DoctorReviews"],
    }),

    postDoctorReviewAbuse: builder.mutation({
      query: ({ data }: PostDoctorAbusePayload) => ({
        url: `${doctor}/reviews_abuse`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["DoctorReviews"],
    }),

    patchDoctorReviewAbuse: builder.mutation({
      query: ({ id, data }: PatchDoctorAbusePayload) => ({
        url: `${doctor}/reviews_abuse/${id}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["DoctorReviews"],
    }),
  }),
});

export const {
  usePostDoctorMutation,
  useGetDoctorsQuery,
  useGetCurrentDoctorQuery,
  useLazyGetDoctorsQuery,
  useGetDoctorReviewsQuery,
  usePostDoctorReviewMutation,
  useGetDoctorQuery,
  usePatchDoctorMutation,
  usePostDoctorFacilityMutation,
  useGetDoctorFacilitiesQuery,
  usePatchDoctorFacilityMutation,
  useDeleteDoctorFacilityMutation,
  useLazyGetDoctorsReviewsQuery,
  usePatchDoctorReviewMutation,
  usePostDoctorReviewAbuseMutation,
  usePatchDoctorReviewAbuseMutation,
  useAssignDoctorMutation,
  useUnassignDoctorMutation,
  useLazyGetDoctorQuery,
} = doctorApi;
