import styles from "./LoyaltyProgramContent.module.scss";
import { Button, Col, Image, Row } from "react-bootstrap";
import BanerImg from "../../../assets/images/AliviaBaner.png";
import { Link } from "react-router-dom";
import { LoyaltyProgramDataInterface } from "../../templates/LoyaltyProgramServiceTemplate/LoyaltyProgramTemplate";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImagePencil } from "../../../assets/Icons/ImagePencil.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/index";
import { AUTH_DATA } from "../../../Helpers/Roles/authData";
import { UserRole } from "../../../redux/Slice/user-slice";
import { LoyaltyProgramImage } from "../LoyaltyProgramImage/LoyaltyProgramImage";
import { convertImageIdToUrl } from "../../../Helpers/convertImageIdToUrl";
import { NAV_PATH } from "../Navigation/navigationData";

interface Props {
  data: LoyaltyProgramDataInterface;
}

export const LoyaltyProgramContent = ({ data }: Props) => {
  const { t } = useTranslation();
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const { authUser } = useSelector((state: RootState) => state.user);
  const userRolesArray = authUser.roles.map(
    (userRole: UserRole) => userRole.role.role
  );

  const isAdmin: boolean = AUTH_DATA.ADMIN_ROLES.some((adminRole: string) =>
    userRolesArray.includes(adminRole)
  );

  return (
    <>
      <Row className={"fs-22 fw-bolder mb-4 text-dark-blue mx-0"}>
        <div className="d-flex justify-content-between">
          {t(`loyalty_about_id_${data.id}`)}
          {isAdmin && (
            <>
              <Button
                className={`d-flex justify-content-center btn-transparent btn-square-alivia align-items-center p-0 m-0 text-dark-blue`}
                onClick={() => setShowImageModal(true)}
              >
                <ImagePencil />
              </Button>
              <LoyaltyProgramImage
                loyaltyProgramId={data?.id}
                show={showImageModal}
                handleClose={() => setShowImageModal(false)}
                headerTitle={t("loyalty_cover_header")}
                imageUrl={convertImageIdToUrl(data?.imageIds, true) || ""}
              />
            </>
          )}
        </div>
      </Row>
      <Link
        className={"w-100"}
        to={`/${NAV_PATH.LOYALTY_PROGRAM}/${NAV_PATH.SERVICE}?program=${data.id}`}
      >
        <Row className={"mb-4 mx-0 px-2"}>
          <Image
            className={`p-0 ${styles.image}`}
            fluid={true}
            src={convertImageIdToUrl(data?.imageIds, true) || BanerImg}
          />
        </Row>
        <Row className={"fs-19 fw-bolder mb-4 text-dark-blue mx-0"}>
          {t("loyalty_how_start")}
        </Row>
        <Row className={"fs-16 mb-4 text-dark-blue mx-0"}>
          {t(data.description)}
        </Row>

        <Row className={`text-dark-blue mt-3 mx-0 ${styles.article}`}>
          <Col className={`col-12 col-lg-4 py-3 py-lg-0`}>
            <p className={"fs-12 pb-2 text-grey-2 m-0"}>
              Liczba aktywnych benefitów
            </p>
            <p className={"fs-25 pb-2 fw-bolder m-0"}>{data.otherCodes}</p>
          </Col>
          <Col className={`col-12 col-lg-4 py-3 py-lg-0`}>
            <p className={"fs-12 pb-2 text-grey-2 m-0"}>Wszystkie benefity</p>
            <p className={"fs-25 pb-2 fw-bolder m-0"}>{data.countAllCode}</p>
          </Col>
          <Col className={`col-12 col-lg-4 pt-3 pt-lg-0`}>
            <p className={"fs-12 pb-2 text-grey-2 m-0"}>Liczba partnerów</p>
            <p className={"fs-25 pb-2 fw-bolder m-0"}>{data.countAllService}</p>
          </Col>
        </Row>
      </Link>
    </>
  );
};
