import styles from "./BreadcrumbLink.module.scss";
import { Link } from "react-router-dom";

interface BreadcrumbLinkProps {
  children?: string;
  to: string;
}

const BreadcrumbLink = ({ children, to }: BreadcrumbLinkProps) => {
  return (
    <Link to={to} className={styles.breadcrumbLink}>
      {children}
    </Link>
  );
};

export default BreadcrumbLink;
