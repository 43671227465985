import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import styles from "./TableRowTopicCaseWithCollapse.module.scss";
import { TopicCaseData } from "../Tables/TopicCaseTable/TopicCaseTable";
import { EditObj } from "../../templates/MessagesTemplate/MessagesTemplate";

interface Props {
  data: TopicCaseData;
  handleEdit: (data: EditObj, now: boolean) => void;
}

export const TableRowTopicCaseWithCollapse = ({ data, handleEdit }: Props) => {
  const breadcrumbData = [
    {
      name: "Edytuj",
      action: () => {
        handleEdit(data, false);
      },
    },
    {
      name: data.status ? "Dezaktywuj" : "Aktywuj",
      action: () => {
        handleEdit({ ...data, status: data.status === 0 ? 1 : 0 }, true);
      },
    },
  ];

  return (
    <>
      <tr aria-controls="example-collapse-text " className={styles.mainTr}>
        <td className={"text-dark-blue"}>{data.case}</td>
        <td className={"text-dark-blue"}>
          {data.status ? "Aktywny" : "Nie aktywny"}
        </td>
        <td className={"text-dark-blue"}>
          {data.sfSynchro ? "Aktywny" : "Nie aktywny"}
        </td>
      </tr>
      <tr className={styles.hoverTr}>
        <td colSpan={4}>
          <Breadcrumb data={breadcrumbData} />
        </td>
      </tr>
    </>
  );
};
