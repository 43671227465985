import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useGetPollQuestionAnswersQuery } from "redux/services/pollAnswerApi";
import DynamicPatientPoll from "../DynamicPatientPoll/DynamicPatientPoll";

interface PollAnswerModalProps {
  show: boolean;
  onHide: () => void;
  user: PollDisplayData | null;
}

const PollAnswerModal: React.FC<PollAnswerModalProps> = ({
  show,
  onHide,
  user,
}) => {
  const pollUserID = user?.id;

  const { data: answersData, isLoading } = useGetPollQuestionAnswersQuery({
    pollUser: `${pollUserID}`,
  });

  const [answers, setAnswers] = useState<Answer[]>([]);

  useEffect(() => {
    if (answersData?.pollQuestionAnswers) {
      setAnswers(answersData.pollQuestionAnswers);
    }
  }, [answersData]);

  const convertDefaultAnswers = (
    answers: Answer[],
  ): { [key: string]: string } => {
    const defaultValues: { [key: string]: string } = {};
    if (!!answers) {
      answers?.forEach((item) => {
        if (!item?.pollPageQuestion?.fieldName) {
          return;
        }
        defaultValues[item?.pollPageQuestion?.fieldName] = item.answer;
      });
    }
    return defaultValues;
  };

  const defaultValues = answers ? convertDefaultAnswers(answers) : {};

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Wynik ankiety</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" /> Wczytuje...
          </div>
        ) : answers.length > 0 ? (
          <DynamicPatientPoll
            pollId={user?.poll?.id}
            defaultAnswers={defaultValues}
            readOnly={true}
          />
        ) : (
          <div className="text-center text-danger">
            Coś poszło nie tak, ta ankieta nie została poprawnie wypełniona
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PollAnswerModal;
